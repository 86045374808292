import React from 'react';

import Svg from '../Svg/Svg';

import './Trash.scss';

const IconTrash = (clipUniqueId) => (
    <Svg className={`IconTrash`}>
        <g clipPath={`url(#${clipUniqueId})`}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.534 3.59h-2.872V1.682A.675.675 0 0014.984 1c-.037 0-.061.012-.073.025A.092.092 0 0014.862 1H8.016a.665.665 0 00-.666.682V3.59H4.466C3.642 3.59 3 4.247 3 5.09v1.453a1 1 0 001 1h.272v13.969c0 .843.63 1.487 1.454 1.487h11.548c.824 0 1.466-.644 1.466-1.487V7.544H19a1 1 0 001-1V5.091c0-.844-.642-1.5-1.466-1.5zM8.683 2.363h5.634V3.59H8.683V2.363zm8.712 19.15c0 .087-.037.124-.121.124H5.726c-.084 0-.12-.037-.12-.124V7.544h11.789v13.969zm1.26-15.332H4.345v-1.09c0-.087.036-.137.121-.137h14.068c.085 0 .121.05.121.136v1.091zM14.557 19.5v-5a.5.5 0 10-1 0v5a.5.5 0 001 0zm-5-9v9a.5.5 0 01-1 0v-9a.5.5 0 011 0z"
            />
        </g>
        <defs>
            <clipPath id={`${clipUniqueId}`}>
                <path d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </Svg>
);

export default IconTrash;
