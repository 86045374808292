import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';

const initialState = {
    loading: false,
    loadingForm: false,
    loadingQuestions: false,
    editedBuilding: null,
    editedBuildingId: null,
    editedBuildingTab: 'settings',
    eventMatching: [],
    error: false,
    errorForm: null,
    errorQuestions: null
};

const reducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.SET_EDITED_BUILDING:
                draft.editedBuilding = action.payload;
                break;

            case actionTypes.SET_EDITED_BUILDING_ID:
                draft.editedBuildingId = action.payload;
                break;

            case actionTypes.SET_EDITED_BUILDING_TAB:
                draft.editedBuildingTab = action.payload;
                break;

            // MATCHING
            case actionTypes.GET_QUESTIONNAIRES_START:
            case actionTypes.CREATE_QUESTIONNAIRES_START:
            case actionTypes.UPDATE_QUESTIONNAIRES_START:
            case actionTypes.DELETE_QUESTIONNAIRES_START:
                draft.loadingForm = true;
                draft.errorForm = null;
                break;

            case actionTypes.GET_QUESTIONNAIRES_SUCCESS:
            case actionTypes.CREATE_QUESTIONNAIRES_SUCCESS:
            case actionTypes.UPDATE_QUESTIONNAIRES_SUCCESS:
                draft.loadingForm = false;
                draft.errorForm = null;
                draft.eventMatching = action.payload;
                break;

            case actionTypes.GET_QUESTIONNAIRES_FAIL:
                draft.loadingForm = false;
                draft.eventMatching = [];
                draft.errorForm = action.payload;
                break;

            case actionTypes.CREATE_QUESTIONNAIRES_FAIL:
            case actionTypes.UPDATE_QUESTIONNAIRES_FAIL:
            case actionTypes.DELETE_QUESTIONNAIRES_FAIL:
                draft.loadingForm = false;
                draft.errorForm = action.payload;
                break;

            case actionTypes.DELETE_QUESTIONNAIRES_SUCCESS:
                break;

            case actionTypes.ADD_QUESTION_START:
            case actionTypes.UPDATE_QUESTION_START:
            case actionTypes.DELETE_QUESTION_START:
                draft.loadingQuestions = true;
                draft.errorQuestions = null;
                break;

            case actionTypes.ADD_QUESTION_SUCCESS:
                draft.loadingQuestions = false;
                draft.errorQuestions = null;
                draft.eventMatching.questions.push(action.payload);
                break;

            case actionTypes.ADD_QUESTION_FAIL:
            case actionTypes.UPDATE_QUESTION_FAIL:
            case actionTypes.DELETE_QUESTION_FAIL:
                draft.loadingQuestions = false;
                draft.errorQuestions = action.payload;
                break;

            case actionTypes.UPDATE_QUESTION_SUCCESS:
                const {questionIndex, question} = action.payload;
                draft.loadingQuestions = false;
                draft.errorQuestions = null;
                draft.eventMatching.questions[questionIndex] = question;
                break;

            case actionTypes.DELETE_QUESTION_SUCCESS:
                draft.loadingQuestions = false;
                draft.errorQuestions = null;
                draft.eventMatching.questions = draft.eventMatching.questions.filter(
                    (question) => question._id !== action.payload
                );
                break;

            case actionTypes.REORDER_QUESTIONS_SUCCESS:
                const updatedQuestionsArray = action.payload.map(question => {
                    return draft.eventMatching.questions.find(questionElement => questionElement._id === question.questionId)
                })
                draft.eventMatching.questions = updatedQuestionsArray

                break;

            default:
                break;
        }
    })



export default reducer;
