import React from 'react';
import './EventWelcomeScreen.scss'
import * as actions from "../../store/actions";
import {isMobile, isSafari} from 'react-device-detect';

import DialogContent from '@material-ui/core/DialogContent';
import ReactPlayer from 'react-player';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import FastForwardIcon from '@material-ui/icons/FastForward';
import {connect} from 'react-redux';
import {getVideoIdFromYoutubeUrl, preventDefaultDrag} from '../../Utils/utils';
import RefreshIcon from '@material-ui/icons/Refresh';


class WelcomeVideo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullscreen: false,
            muteVideo: false,
            loaded: false,
            isVideoPlaying: false,
            progressTime: 0,
            thumbnailImage: '',
            showReplayOverlay: false
        };
        this.youtubePlayer = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false);
        this.setState({loaded: true});
        this.setThumbnailImage();
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false);
    }

    setThumbnailImage = () => {
        const {eventWelcomeScreen} = this.props;
        let videoId = getVideoIdFromYoutubeUrl(eventWelcomeScreen?.data?.videoUrl || '');
        this.setState({
            thumbnailImage: `https://img.youtube.com/vi/${videoId}/0.jpg`,
        });
    };

    handleVideoReady = () => {
        this.youtubePlayer.current.getInternalPlayer().playVideo();
    };

    isPlayingError = (error) => {
    }

    handleVideoWhenIsStartPlaying = () => {

    }

    handleOnPlaying = () => {
        this.setState({
            isVideoPlaying: true,
            showReplayOverlay: false
        })
    }

    videoHasEnded = () => {
        this.setState({
            isVideoPlaying: false,
            showReplayOverlay: true
        })
    }

    escFunction = (e) => {
        if (e.key === 'Escape') {
            this.setState({fullscreen: false});
        }
    };

    handleProgressChange = (e, value) => {
        e.preventDefault();
        this.youtubePlayer.current.seekTo(value / 100.5, 'fraction');
    };

    handleProgressBar = (progress) => {
        this.setState({
            progressTime: parseFloat((progress?.played * 100).toFixed(1)),
        });
    };

    toggleMute = () => this.setState({muteVideo: !this.state.muteVideo});

    // toggleFullscreen = () => this.setState({fullscreen: !this.state.fullscreen});

    toggleFullscreen = () => {
        const isFullscreenActive = document.fullscreenElement !== null;
        if(isFullscreenActive){
            this.exitFullscreen()
        } else{
            this.startFullscreen()
        }
    };

    startFullscreen = () => {
        const video = document.querySelector('#welcome-screen-video');
        if(video.requestFullScreen){
            video.requestFullScreen();
        } else if(video.webkitRequestFullScreen){
            video.webkitRequestFullScreen();
        } else if(video.mozRequestFullScreen){
            video.mozRequestFullScreen();
        }
    }

    exitFullscreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen()
        } else {
            document.documentElement.requestFullscreen();
        }
    }

    toggleRewind = () =>
        this.youtubePlayer.current.seekTo(Math.floor(this.youtubePlayer.current.getCurrentTime()) - 30, 'seconds');

    toggleForward = () =>
        this.youtubePlayer.current.seekTo(Math.floor(this.youtubePlayer.current.getCurrentTime()) + 30, 'seconds');

    togglePause = () => {
        this.setState({isVideoPlaying: !this.state.isVideoPlaying}, () => {
            if (this.state.isVideoPlaying) {
                this.youtubePlayer.current.getInternalPlayer().playVideo();
            } else {
                this.youtubePlayer.current.getInternalPlayer().pauseVideo();
            }
        });
    }




    render() {
        const {fullscreen, muteVideo, isVideoPlaying, thumbnailImage, showReplayOverlay} = this.state;
        const {eventWelcomeScreen} = this.props;
        const videoUrl = eventWelcomeScreen?.data?.videoUrl;
        return (
            <DialogContent id='welcome-screen-video'>
                <div onDragStart={preventDefaultDrag} className="booth-video-container" >
                    <div className="media-container">
                        <img
                            src={thumbnailImage}
                            alt="Video thumbnail"
                            className={`booth-thumbnail ${!isVideoPlaying && 'show'}`}
                        />
                        <ReactPlayer
                            ref={this.youtubePlayer}
                            url={videoUrl}
                            onReady={this.handleVideoReady}
                            onProgress={this.handleProgressBar}
                            onEnded={this.videoHasEnded}
                            onError={this.isPlayingError}
                            onStart={this.handleVideoWhenIsStartPlaying}
                            onPlay={this.handleOnPlaying}
                            playsinline={isMobile}
                            playIcon={<></>}
                            controls={false}
                            width="100%"
                            height="100%"
                            volume={muteVideo ? 0 : 1}
                            className="react-player"
                            playing={isVideoPlaying}
                        />
                        <div onDragStart={preventDefaultDrag} className="auditoriumOverlayPlayer"/>

                        {showReplayOverlay &&
                            <div onDragStart={preventDefaultDrag} className="refresh-overlay">
                                <RefreshIcon fontSize={'inherit'} onClick={this.togglePause}/>
                            </div>
                        }
                    </div>
                </div>
                <div onDragStart={preventDefaultDrag} className="booth-video-actions-container">
                    {!isSafari &&
                        <button onClick={this.toggleFullscreen} className="fullscreen-button">
                            {fullscreen ? (
                                <FullscreenExitIcon fontSize="inherit" />
                            ) : (
                                <FullscreenIcon fontSize="inherit" />
                            )}
                        </button>
                    }
                    <button onClick={this.toggleRewind} className="fullscreen-button mute-button">
                        <FastRewindIcon fontSize="inherit" />
                    </button>
                    <button onClick={this.togglePause} className="fullscreen-button mute-button">
                        {isVideoPlaying ? (
                            <PauseIcon fontSize="inherit" />
                        ) : (
                            <PlayArrowIcon fontSize="inherit" />
                        )}
                    </button>
                    <button onClick={this.toggleForward} className="fullscreen-button mute-button">
                        <FastForwardIcon fontSize="inherit" />
                    </button>
                    <button onClick={this.toggleMute} className="fullscreen-button mute-button">
                        {muteVideo ? <VolumeOffIcon fontSize="inherit" /> : <VolumeUpIcon fontSize="inherit" />}
                    </button>
                </div>
            </DialogContent>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        eventWelcomeScreen: state.eventWelcomeScreen,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventWelcomeScreen: (eventId) => dispatch(actions.getEventWelcomeScreen(eventId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeVideo);

