import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';


const initialState = {
    loading: false,
    eventId: null,
    eventSlug: null,
    eventNotFound: false,
    data: null,
    eventInfo: null,
    branding: {
        data: null,
        loading: false,
        fetched: false,
        error: null,
    },
    brandingTranslations: {
        data: null,
        loading: false,
        fetched: false,
        error: null,
    },
    registrationFields: {
        data: {},
        loading: false,
        fetched: false,
        error: null,
    },
    sideNavigationDetails: {
        hasVideoWall: false,
        activeWallId: null,
        currentTimeSlotVideoWallId: null,
        highlightPostsForUsers: [],
        currentTimeSlotVideoWallSpeakers: [],
    },
    snackbar: {
        open: false,
        text: '',
    },
    suggestionsLoading: false,
    suggestions: [],
    myAgendaLoading: false,
    myAgenda: [],
    pastSessions: false,
    myProgrammeLoading: false,
    myProgramme: [],
    programmeSearch: {},
};

const reducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {

            case actionTypes.SET_EVENT_ID:
                draft.eventId = action.payload;
                break;

            case actionTypes.SET_EVENT_SLUG:
                draft.eventSlug = action.payload;
                break;

            case actionTypes.CREATE_EVENT_START:
            case actionTypes.GET_EVENT_START:
            case actionTypes.GET_EVENT_INFO_START:
            case actionTypes.ADD_ACCES_PACKAGE_START:
            case actionTypes.ADD_EXHIBITOR_ACCES_PACKAGE_START:
            case actionTypes.UPDATE_ACCES_PACKAGE_START:
            case actionTypes.UPDATE_EXHIBITOR_ACCES_PACKAGE_START:
            case actionTypes.DELETE_ACCES_PACKAGE_START:
            case actionTypes.DELETE_EXHIBITOR_ACCES_PACKAGE_START:
            case actionTypes.ADD_SPEAKER_START:
            case actionTypes.REMOVE_SPEAKER_START:
                draft.loading = true;
                break;

            case actionTypes.CREATE_EVENT_FAIL:
            case actionTypes.SET_EVENT_ID_BY_SLUG_FAIL:
            case actionTypes.GET_EVENT_FAIL:
            case actionTypes.GET_EVENT_INFO_FAIL:
            case actionTypes.ADD_ACCES_PACKAGE_FAIL:
            case actionTypes.ADD_EXHIBITOR_ACCES_PACKAGE_FAIL:
            case actionTypes.UPDATE_ACCES_PACKAGE_FAIL:
            case actionTypes.UPDATE_EXHIBITOR_ACCES_PACKAGE_FAIL:
            case actionTypes.DELETE_ACCES_PACKAGE_FAIL:
            case actionTypes.DELETE_EXHIBITOR_ACCES_PACKAGE_FAIL:
            case actionTypes.ADD_SPEAKER_FAIL:
            case actionTypes.REMOVE_SPEAKER_FAIL:
                draft.loading = false;
                break;

            case actionTypes.CREATE_EVENT_SUCCESS:
                draft.loading = false;
                draft.eventId = action.payload._id;
                draft.eventSlug = action.payload.slug;
                break;

            case actionTypes.SET_EVENT_ID_BY_SLUG_START:
                draft.loading = true;
                draft.eventNotFound = false;
                break;

            case actionTypes.SET_EVENT_ID_BY_SLUG_SUCCESS:
                draft.loading = false;
                draft.eventId = action.payload;
                break;

            case actionTypes.SET_EVENT_NOT_FOUND:
                draft.eventNotFound = true;
                break;

            case actionTypes.GET_EVENT_SUCCESS:
                // reset the eventInfo data, because if you change the event
                // you want to be able to make a new api call to get the new eventInfo data
                draft.loading = false;
                draft.eventInfo = null;
                draft.data = action.payload;
                draft.data.matchingDialog = false;
                draft.data.requestCloseBanner = false;
                break;

            case actionTypes.GET_EVENT_RESET:
                draft.loading = false;
                draft.data = null;
                break;

            case actionTypes.GET_EVENT_INFO_SUCCESS:
                draft.loading = false;
                draft.eventInfo = action.payload;
                draft.eventId = action.payload._id;
                break;

            case actionTypes.EVENT_SETTING_UPDATED:
                draft.data[action.payload.name] = action.payload.value;
                break;

            case actionTypes.ADD_SPEAKER_SUCCESS:
                draft.loading = false;
                draft.data.speakers = action.payload;
                break;

            case actionTypes.REMOVE_SPEAKER_SUCCESS:
                draft.loading = false;
                draft.data.speakers = state.data.speakers.filter((speaker) => speaker !== action.payload);
                break;

            case actionTypes.ADD_ACCES_PACKAGE_SUCCESS:
                draft.data.accessPackages.push(action.payload);
                draft.loading = false;
                break;

            case actionTypes.ADD_EXHIBITOR_ACCES_PACKAGE_SUCCESS:
                draft.data.exhibitorAccessPackages.push(action.payload);
                draft.loading = false;
                break;

            case actionTypes.UPDATE_ACCES_PACKAGE_SUCCESS:
                let updatedPackageIndex = draft.data.accessPackages.findIndex(
                    (accessPackage) => accessPackage._id === action.payload._id
                );
                if (updatedPackageIndex !== -1) {
                    draft.data.accessPackages[updatedPackageIndex] = action.payload;
                }
                draft.loading = false;
                break;

            case actionTypes.UPDATE_EXHIBITOR_ACCES_PACKAGE_SUCCESS:
                let updatedPackageExhibitorIndex = draft.data.exhibitorAccessPackages.findIndex(
                    (accessPackage) => accessPackage._id === action.payload._id
                );
                if (updatedPackageExhibitorIndex !== -1) {
                    draft.data.exhibitorAccessPackages[updatedPackageExhibitorIndex] = action.payload;
                }
                draft.loading = false;
                break;

            case actionTypes.DELETE_ACCES_PACKAGE_SUCCESS:
                draft.data.accessPackages = draft.data.accessPackages.filter(
                    (accessPackage) => accessPackage._id !== action.payload
                );
                draft.loading = false;
                break;

            case actionTypes.DELETE_EXHIBITOR_ACCES_PACKAGE_SUCCESS:
                draft.data.exhibitorAccessPackages = draft.data.exhibitorAccessPackages.filter(
                    (accessPackage) => accessPackage._id !== action.payload
                );
                draft.loading = false;
                break;

            case actionTypes.SET_ACTIVE_WALL:
                draft.sideNavigationDetails.activeWallId = action.payload.wallId;
                draft.sideNavigationDetails.highlightPostsForUsers = action.payload.highlightUsers;
                draft.sideNavigationDetails.canModerateChat = action.payload.canModerateChat;
                break;

            case actionTypes.SIDE_NAV_HAS_VIDEO_WALL:
                draft.sideNavigationDetails.hasVideoWall = action.payload;
                break;

            case actionTypes.UPDATE_AUDITORIUM_RESOURCES:
                const { auditoriumId, dateProgramId, programId, links, documents, description } = action.payload;
                const auditoriumToUpdateIndex = state.data.auditoriums.findIndex(
                    (auditorium) => auditorium._id === auditoriumId,
                );
                const dailyProgramToUpdateIndex = state.data.auditoriums[auditoriumToUpdateIndex].dailyProgram.findIndex(
                    (dailyProgram) => dailyProgram._id === dateProgramId,
                );
                const programToUpdateIndex = state.data
                    .auditoriums[auditoriumToUpdateIndex]
                    .dailyProgram[dailyProgramToUpdateIndex]
                    .program.findIndex(
                        (program) => program._id === programId,
                    );

                draft.data.auditoriums[auditoriumToUpdateIndex].dailyProgram[dailyProgramToUpdateIndex].program[programToUpdateIndex].links = links;
                draft.data.auditoriums[auditoriumToUpdateIndex].dailyProgram[dailyProgramToUpdateIndex].program[programToUpdateIndex].documents = documents;
                draft.data.auditoriums[auditoriumToUpdateIndex].dailyProgram[dailyProgramToUpdateIndex].program[programToUpdateIndex].description = description;
                break;

            case actionTypes.SET_TIME_SLOT_VIDEO_WALL_ID:
                draft.sideNavigationDetails.currentTimeSlotVideoWallId = action.payload.wallId;
                draft.sideNavigationDetails.currentTimeSlotVideoWallSpeakers = action.payload.highlightUsers;
                break;

            case actionTypes.NEW_FLASH_NOTIFICATION:
                draft.snackbar.open = true;
                draft.snackbar.text = action.payload.notification.text;
                break;

            case actionTypes.CLOSE_FLASH_NOTIFICATION:
                draft.snackbar.open = false;
                draft.snackbar.text = '';
                break;

            case actionTypes.UPDATE_SINGLE_AUDITORIUM_SUCCESS:
                const updatedAuditorium = action.payload;
                let updatedAuditoriumIndex = draft.data.auditoriums.findIndex(
                    (auditorium) => auditorium._id === updatedAuditorium._id
                );
                if (updatedAuditoriumIndex !== -1) {
                    draft.data.auditoriums[updatedAuditoriumIndex] = updatedAuditorium;
                }
                break;

            case actionTypes.UPDATE_TIMESLOT_SESSION:
                draft.data.auditoriums.forEach((auditorium, auditoriumIndex) => {
                    auditorium.dailyProgram.forEach((dayProgram, dayProgramIndex) => {
                        dayProgram.program.forEach((timeslot, timeslotIndex) => {
                            if (timeslot?.session?._id === action.payload.sessionId) {

                                draft.data.auditoriums[auditoriumIndex].dailyProgram[dayProgramIndex].program[
                                    timeslotIndex
                                ].session.isActive = action.payload.isActive;

                                draft.data.auditoriums[auditoriumIndex].dailyProgram[dayProgramIndex].program[
                                    timeslotIndex
                                ].session.records = [...action.payload.records];

                                draft.data.auditoriums[auditoriumIndex].dailyProgram[dayProgramIndex].program[
                                    timeslotIndex
                                ].session.canRequestMic = action.payload.canRequestMic;
                            }
                        });
                    });
                });
                break;

            case actionTypes.SET_ENABLE_MATCHING:
                draft.data.enableMatching = action.payload;
                break;

            case actionTypes.TOGGLE_MATCHING_DIALOG:
                draft.data.matchingDialog = action.payload;
                break;

            case actionTypes.CLOSE_MATCHING_BANNER:
                draft.data.requestCloseBanner = action.payload;
                break;

            case actionTypes.GET_EVENT_REGISTRATION_FIELDS_START:
                draft.registrationFields.loading = true;
                break;

            case actionTypes.GET_EVENT_REGISTRATION_FIELDS_SUCCESS:
                // action.payload is an object that contains properties of registration fields
                // ex: participantPredefinedRegistrationFields is an object that contains other objects (each object contains data to display an "input")
                // ex: participantRegistrationFields is an array of objects (each object contains data to display an "input")
                // const registrationFields = action.payload;
                const registrationFields = action.payload;
                Object.entries(registrationFields).forEach(registrationFieldProperty => {
                    const [registrationFieldsName, registrationFieldsData] = registrationFieldProperty;
                    // this will update the event.registrationFields.participantRegistrationFields = {company:{...}, first: {...}} etc
                    draft.registrationFields.data[registrationFieldsName] = registrationFieldsData;
                    draft.registrationFields.loading = false;
                    draft.registrationFields.fetched = true;
                    draft.registrationFields.error = null;
                });
                break;

            case actionTypes.GET_EVENT_REGISTRATION_FIELDS_FAIL:
                draft.registrationFields.loading = false;
                draft.registrationFields.fetched = true;
                draft.registrationFields.error = action.payload;
                break;

            case actionTypes.GET_EVENT_BRANDING_START:
                draft.branding.loading = true;
                break;

            case actionTypes.GET_EVENT_BRANDING_SUCCESS:
                draft.branding.data = action.payload;
                draft.branding.loading = false;
                draft.branding.fetched = true;
                draft.branding.error = null;
                break;

            case actionTypes.GET_EVENT_BRANDING_FAIL:
                draft.branding.loading = false;
                draft.branding.fetched = true;
                draft.branding.error = action.payload;
                break;

            case actionTypes.GET_EVENT_BRANDING_TRANSLATIONS_START:
                draft.brandingTranslations.loading = true;
                break;

            case actionTypes.GET_EVENT_BRANDING_TRANSLATIONS_SUCCESS:
                draft.brandingTranslations.data = action.payload;
                draft.brandingTranslations.loading = false;
                draft.brandingTranslations.fetched = true;
                draft.brandingTranslations.error = null;
                break;

            case actionTypes.GET_EVENT_BRANDING_TRANSLATIONS_FAIL:
                draft.brandingTranslations.loading = false;
                draft.brandingTranslations.fetched = true;
                draft.brandingTranslations.error = action.payload;
                break;

            case actionTypes.USER_NAVIGATED_TO_OTHER_EVENT:
                draft.branding.fetched = false;
                draft.brandingTranslations.fetched = false;
                draft.registrationFields.fetched = false;
                break;

            case actionTypes.GET_SUGGESTIONS_START:
                draft.suggestionsLoading = true;
                break;
            case actionTypes.GET_SUGGESTIONS_SUCCESS:
                draft.suggestionsLoading = false;
                draft.suggestions = action.payload;
                break;
            case actionTypes.GET_SUGGESTIONS_FAIL:
                draft.suggestionsLoading = false;
                break;

            case actionTypes.GET_MY_AGENDA_START:
                draft.myAgendaLoading = true;
                break;
            case actionTypes.GET_MY_AGENDA_SUCCESS:
                draft.myAgendaLoading = false;
                draft.myAgenda = action.payload;
                break;
            case actionTypes.GET_MY_AGENDA_FAIL:
                draft.myAgendaLoading = false;
                break;

            case actionTypes.ADD_SESSION_AGENDA:
                const addSessionId = action.payload;
                const sessionIndex = draft.myAgenda.findIndex((session) => session._id === addSessionId);
                if (sessionIndex === -1) {
                    draft.suggestions.forEach((suggestion) => {
                        if (suggestion._id === addSessionId) {
                            suggestion.isAddedToAgenda = true;
                            draft.myAgenda.push(suggestion);
                            draft.myAgenda.sort((x, y) => {
                                let xx = new Date(x.startTimestamp);
                                let yy = new Date(y.startTimestamp);
                                return xx - yy;
                            });
                        }
                    });

                    const newSessionIndex = draft.myAgenda.findIndex((session) => session._id === addSessionId);
                    draft.myProgramme.forEach((suggestion) => {
                        if (suggestion._id === addSessionId) {
                            suggestion.isAddedToAgenda = true;
                            if (newSessionIndex === -1) {
                                draft.myAgenda.push(suggestion);
                                draft.myAgenda.sort((x, y) => {
                                    let xx = new Date(x.startTimestamp);
                                    let yy = new Date(y.startTimestamp);
                                    return xx - yy;
                                });
                            }
                        }
                    });
                }
                break;

            case actionTypes.REMOVE_SESSION_AGENDA:
                const sessionId = action.payload;
                draft.myAgenda = state.myAgenda.filter((session) => session._id !== sessionId);
                draft.suggestions.forEach((suggestion) => {
                    if (suggestion._id === sessionId) {
                        suggestion.isAddedToAgenda = false;
                    }
                });
                draft.myProgramme.forEach((session) => {
                    if (session._id === sessionId) {
                        session.isAddedToAgenda = false;
                    }
                });
                break;

            case actionTypes.SHOW_PAST_SESSIONS:
                draft.pastSessions = !state.pastSessions;
                break;

            case actionTypes.GET_PROGRAMME_START:
                draft.myProgrammeLoading = true;
                break;

            case actionTypes.GET_PROGRAMME_SUCCESS:
                draft.myProgrammeLoading = false;
                draft.myProgramme = action.payload;
                break;

            case actionTypes.GET_PROGRAMME_FAIL:
                draft.myProgrammeLoading = false;
                break;

            case actionTypes.SET_PROGRAMME_SEARCH:
                draft.programmeSearch = action.payload;
                break;
            default:
                break;
        }
    })


export default reducer;
