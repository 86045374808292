import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import axios from '../../../../store/axios-instance';
import moment from 'moment';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Confirm from '../../../../Dialogs/Confirm';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import {preventDefaultDrag} from '../../../../Utils/utils';

class MeetingBanner extends Component {
    state = {
        meeting: '',
        meetingStatus: '',
        meetingLocalTime: '',
        meetingBannerText: '',
        openConfirmCancelMeeting: false,
    };

    componentDidMount() {
        this.setMeetingData();
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutId)
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.activeChatRoomId !== prevProps.activeChatRoomId ||
            this.props.user.meetings !== prevProps.user.meetings
        ) {
            this.setMeetingData();
        }
    }

    setMeetingData = () => {
        const {meetingId, user} = this.props;
        const meetingData = user.meetings.find((meeting) => meeting._id === meetingId);

        this.getMeetingFormattedDate(meetingData.start);
        this.getMeetingStatus(meetingData);

        const secondsTillMeeting = this.getSecondsTillMeeting(meetingData);
        if (secondsTillMeeting > 0) {
            if (secondsTillMeeting > 0) {
                this.timeoutId = setTimeout(() => {
                    this.setMeetingStatusProgress();
                }, secondsTillMeeting * 1000);
            }
        }
        this.setState({meeting: meetingData});
    };

    setMeetingStatusProgress = () => {
        this.setState({
            meetingStatus: 'progress',
            bannerText:
                this.props.languages.translations[this.props.languages.platformLanguage]?.meetings.banner.progress ||
                this.props.languages.translations['en']?.meetings.banner.progress,
        });
    };

    getSecondsTillMeeting = (meetingData) => {
        if (meetingData.status === 'accepted') {
            const now = new Date().getTime();
            const start = new Date(meetingData.start).getTime();
            if (now > start) {
                return null;
            } else {
                return Math.floor((start - now) / 1000 + 1);
            }
        } else {
            return null;
        }
    };

    getMeetingFormattedDate = (date) => {
        const d = new Date(date);
        const dh = moment(d).format('hh:mm A');
        const dd = moment(d).format('DD.MM.YYYY');

        // benji...
        let timezoneText = d.toTimeString().slice(9).split('(')[0].trim();
        if (timezoneText.charAt(4) === '0' && timezoneText.charAt(6) === '0' && timezoneText.charAt(7) === '0') {
            timezoneText = timezoneText.slice(0, 6);
            timezoneText = timezoneText.slice(0, 4) + timezoneText.slice(5);
        } else if (timezoneText.charAt(6) === '0' && timezoneText.charAt(7) === '0') {
            timezoneText = timezoneText.slice(0, 6);
        }

        this.setState({meetingLocalTime: `${dh}, ${timezoneText}, ${dd}`});
    };

    getMeetingStatus = (meetingData) => {
        const {user, translation, defaultTranslation} = this.props;

        const now = new Date().getTime();
        const start = new Date(meetingData.start).getTime();
        const end = new Date(meetingData.end).getTime();

        let status = '';
        let bannerText = '';

        // TODO: switch
        if (now > end) {
            status = 'completed';
        } else if (now < end && now > start && meetingData.status === 'accepted') {
            status = 'progress';
            bannerText = translation?.meetings.banner.progress || defaultTranslation?.meetings.banner.progress;
        } else if (meetingData.status === 'accepted') {
            status = meetingData.status;
            bannerText = translation?.meetings.banner.accepted || defaultTranslation?.meetings.banner.accepted;
        } else {
            status = meetingData.status;
            if (meetingData.owner.user._id === user._id) {
                bannerText =
                    translation?.meetings.banner.pendingOwner || defaultTranslation?.meetings.banner.pendingOwner;
            } else {
                bannerText = translation?.meetings.banner.pending || defaultTranslation?.meetings.banner.pending;
            }
        }

        this.setState({meetingStatus: status, meetingBannerText: bannerText});
    };

    handleAcceptMeeting = () => {
        let data = {
            meetingId: this.state.meeting._id,
            firstUserId: this.state.meeting.partner.user._id,
            secondUserId: this.state.meeting.owner.user._id,
        };

        axios({method: 'post', url: `/users/accept-meeting-request`, data: data})
            .then(() => {
                this.props.onRefreshUserData().then(() => {
                    this.setMeetingData();
                });
            })
            .catch((error) => {});
    };

    handleCancelMeeting = () => {
        this.setState({
            openConfirmCancelMeeting: true,
        });
    };

    handleClose = () => {
        this.setState({
            openConfirmCancelMeeting: false,
        });
    };

    cancelMeeting = () => {
        let data = {
            meetingId: this.state.meeting._id,
            firstUserId: this.state.meeting.partner.user._id,
            secondUserId: this.state.meeting.owner.user._id,
        };

        axios({method: 'post', url: `/users/cancel-meeting-request`, data: data})
            .then(() => {
                this.props.onRefreshUserData().then(() => {
                    this.setMeetingData();
                });
            })
            .catch((error) => {});
    };

    render() {
        const {user, translation, defaultTranslation, lastChatMessage} = this.props;
        const {meeting, meetingStatus, meetingLocalTime, meetingBannerText, openConfirmCancelMeeting} = this.state;

        if (!meeting || meetingStatus === 'completed' || meetingStatus === 'canceled') {
            return null;
        }

        let videoChatButtonText =
            translation?.meetings.banner.startCall || defaultTranslation?.meetings.banner.startCall;
        let startCall = true;
        if (meeting && meetingStatus === 'progress') {
            if (lastChatMessage === 'I&#x27;ve set up a Video Meeting room. Please click here to join it.') {
                videoChatButtonText =
                    translation?.meetings.banner.joinCall || defaultTranslation?.meetings.banner.joinCall;
                startCall = false;
            }
        }

        return (
            <div onDragStart={preventDefaultDrag} className="chat-banner">
                <div onDragStart={preventDefaultDrag} className="chat-banner-info-container">
                    {/* TODO : remove join video call check by the last message */}
                    {startCall ? (
                        <React.Fragment>
                            <p>{meetingBannerText}</p>
                            <p onDragStart={preventDefaultDrag} className="time">
                                {meetingLocalTime}
                            </p>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div onDragStart={preventDefaultDrag} className="video-started-container">
                                {translation?.meetings.banner.videoCallStarted ||
                                    defaultTranslation?.meetings.banner.videoCallStarted}
                                <Tooltip
                                    id="tooltip-video-started"
                                    title={
                                        translation?.meetings.banner.videoCallStartedInfo ||
                                        defaultTranslation?.meetings.banner.videoCallStartedInfo
                                    }
                                >
                                    <InfoIcon color="secondary" />
                                </Tooltip>
                            </div>
                        </React.Fragment>
                    )}
                </div>
                <div onDragStart={preventDefaultDrag} className="chat-banner-actions-container">
                    {meetingStatus === 'progress' ? (
                        <div onDragStart={preventDefaultDrag} onClick={this.props.startConference} className="button">
                            {videoChatButtonText}
                        </div>
                    ) : (
                        <React.Fragment key={meeting._id}>
                            {meeting.owner.user._id !== user._id && meeting.status !== 'accepted' && (
                                <div
                                    onDragStart={preventDefaultDrag}
                                    className="button"
                                    onClick={this.handleCancelMeeting}
                                >
                                    <CancelIcon fontSize={'inherit'} />
                                </div>
                            )}
                            {meeting.owner.user._id !== user._id && meeting.status !== 'accepted' && (
                                <div
                                    onDragStart={preventDefaultDrag}
                                    className="button"
                                    onClick={this.handleAcceptMeeting}
                                >
                                    <CheckCircleIcon fontSize={'inherit'} />
                                </div>
                            )}
                        </React.Fragment>
                    )}
                </div>
                {openConfirmCancelMeeting && (
                    <Confirm
                        open={openConfirmCancelMeeting}
                        closeConfirm={this.handleClose}
                        dialogTitle={
                            translation?.meetings.banner.cancelTitle || defaultTranslation?.meetings.banner.cancelTitle
                        }
                        dialogDescription={
                            translation?.meetings.banner.cancelDescription ||
                            defaultTranslation?.meetings.banner.cancelDescription
                        }
                        handleConfirm={this.cancelMeeting}
                        dialogCancelButtonLabel={
                            translation?.generalText.close || defaultTranslation?.generalText.close
                        }
                        dialogConfirmButtonLabel={
                            translation?.generalText.confirm || defaultTranslation?.generalText.confirm
                        }
                        classList={['preserveHeaderDropdown']}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        activeChatRoomId: state.user.topNavigation.activeChatRoomId,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        event: state.event.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRefreshUserData: () => dispatch(actions.refreshUserData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingBanner);
