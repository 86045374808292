import React, {useState, useRef, useEffect, useCallback, useMemo} from 'react';
import {Utils} from 'avcore/client';
import useForceUpdate from 'use-force-update';
import classNames from 'classnames';
import xorBy from 'lodash/xorBy';

import StreamControls from '../../../../Components/VideoStream/StreamControls';
import VideoPlayerSelfVideo from './components/VideoPlayerSelfVideo/VideoPlayerSelfVideo';
import VideoPlayerVideoPlates from './components/VideoPlayerVideoPlates';
import VideoPlayerButtons from './components/VideoPlayerButtons/VideoPlayerButtons';
import VideoPlayerSpinner from './components/VideoPlayerSpinner/VideoPlayerSpinner';
import VideoPlayerFullScreenButton from './components/VideoPlayerFullScreenButton/VideoPlayerFullScreenButton';

import {PARTICIPANT_TYPES, MEDIA_TYPES} from '../../../../Utils/constants/organizer/sessions';
import {DEFAULT_MEDIA_KINDS} from '../../../../Utils/constants/shared';

import {startCapture} from '../../../../Utils/videoConference';
import {useChangePermission} from '../../../../services/hooks/sessions/useChangePermission';

export function VideoModule({
    videoConference,
    userProfile,
    users,
    changeSessionPlaybacks,
    toggleSessionScreen,
    toggleSessionShareScreen,
    toggleSessionFullScreen,
    videoConferenceReady,
    routeParams: {sessionId},
}) {
    const {capture, playbacks, selfPlayback, isActive} = videoConference;
    const forceUpdate = useForceUpdate();

    const getCurrentUser = useMemo(() => users.find(({userId}) => userId === userProfile.userId) || {}, [
        userProfile.userId,
        users,
    ]);

    const [callMembers, setCallMembers] = useState(users);
    const [isVideoLoading, setIsVideoLoading] = useState(false);
    const [soundEnabled, setSoundEnabled] = useState(false);
    const [videoEnabled, setVideoEnabled] = useState(false);
    const [screenShareEnabled, setScreenShareEnabled] = useState(false);
    const [isMediaSelfEnabled, setMediaSelfEnabled] = useState(null);
    const [fullScreenVideoConference, setFullScreenVideoConference] = useState(false);

    const vidRefs = useRef([]);
    const selfVidRef = useRef(null);

    const {handleToggleAudio, handleToggleVideo, handleToggleScreenShare} = useChangePermission({
        sessionId,
        user: getCurrentUser,
    });

    const removeVideoTracks = useCallback(
        () =>
            capture?.mediaStream.getVideoTracks().forEach((track) => {
                track.stop();
                capture.removeTrack(track);
            }),
        [capture]
    );

    const startVideo = useCallback(
        async (videoRef, playback) => {
            try {
                const mediaStream = await playback.subscribe();

                videoRef.current.srcObject = mediaStream;
                if (Utils.isSafari) {
                    const onStreamChange = () => {
                        try {
                            if (!videoRef || !videoRef.current) return;
                            const _mediaStream = new MediaStream(mediaStream.getTracks());
                            videoRef.current.srcObject = _mediaStream;
                            videoRef.current.play();
                            forceUpdate();
                        } catch (err) {}
                    };
                    playback.on('addtrack', onStreamChange).on('removetrack', onStreamChange);
                } else if (Utils.isFirefox) {
                    const onPause = () => {
                        if (videoRef && videoRef.current) videoRef.current.play();
                    };
                    videoRef.current.addEventListener('pause', onPause);
                }

                await videoRef.current.play();
            } catch (err) {}
        },
        [forceUpdate]
    );

    const toggleSound = useCallback(() => {
        // make sure that the speaker can share/stream this type of resource
        // the organizer gave access to him to share this resources
        if(!getCurrentUser.allowAudio && getCurrentUser.role === PARTICIPANT_TYPES.speaker){
            return
        }
        handleToggleAudio();
        setSoundEnabled(!soundEnabled);
    }, [handleToggleAudio, soundEnabled]);

    const enableVideo = useCallback(async () => await startVideo(selfVidRef, selfPlayback), [selfPlayback, startVideo]);

    const toggleVideo = useCallback(
        async (remoteControl = false) => {
            // make sure that the speaker can share/stream this type of resource
            // the organizer gave access to him to share this resources
            if(!getCurrentUser.allowVideo && getCurrentUser.role === PARTICIPANT_TYPES.speaker){
                return
            }
            setIsVideoLoading(true);
            if (!capture?.configs?.kinds.includes('video')) {
                const result = await startCapture(userProfile.userId, DEFAULT_MEDIA_KINDS, false);
                videoConferenceReady({capture: result});
                enableVideo();
            } else {
                if (!videoEnabled) {
                    toggleSessionScreen(false);
                } else removeVideoTracks();
            }

            setVideoEnabled(!videoEnabled);
            if (!remoteControl) handleToggleVideo();
            setIsVideoLoading(false);
        },

        [
            capture,
            enableVideo,
            handleToggleVideo,
            removeVideoTracks,
            toggleSessionScreen,
            userProfile.userId,
            videoConferenceReady,
            videoEnabled,
        ]
    );

    const toggleFullScreen = useCallback(
        (value) => {
            setFullScreenVideoConference(value);
            toggleSessionFullScreen();
        },
        [toggleSessionFullScreen]
    );

    const toggleShareScreen = useCallback(
        (remoteControl = false) => {
            // make sure that the speaker can share/stream this type of resource
            // the organizer gave access to him to share this resources
            if(!getCurrentUser.allowScreenSharing && getCurrentUser.role === PARTICIPANT_TYPES.speaker){
                return
            }
            if (!screenShareEnabled) {
                toggleSessionScreen(true);
            } else {
                removeVideoTracks();
                toggleSessionShareScreen({capture, isScreenShared: !screenShareEnabled});
                if (videoEnabled) toggleSessionScreen(false);
            }

            if (!remoteControl) handleToggleScreenShare();

            setScreenShareEnabled(!screenShareEnabled);
        },
        [
            capture,
            handleToggleScreenShare,
            removeVideoTracks,
            screenShareEnabled,
            toggleSessionScreen,
            toggleSessionShareScreen,
            videoEnabled,
        ]
    );

    const watchRemoteMediaControl = useCallback(async () => {
        if (soundEnabled !== getCurrentUser.audio) {
            setSoundEnabled(getCurrentUser.audio);
        } else if (
            (!isMediaSelfEnabled || isMediaSelfEnabled !== MEDIA_TYPES.video) &&
            videoEnabled !== getCurrentUser.video
        ) {
            if (isVideoLoading) return;

            await toggleVideo(true);
        }
    }, [
        getCurrentUser.audio,
        getCurrentUser.video,
        isMediaSelfEnabled,
        isVideoLoading,
        soundEnabled,
        toggleVideo,
        videoEnabled,
    ]);

    useEffect(() => {
        if (isActive && users.length !== callMembers.length) {
            const diff = xorBy(callMembers, users, '_id');

            changeSessionPlaybacks(diff);
            setCallMembers(users);
        }
    }, [callMembers, changeSessionPlaybacks, isActive, users]);

    useEffect(() => {
        if (capture) {
            capture.mediaStream.getAudioTracks().forEach((track) => (track.enabled = getCurrentUser.audio));

            watchRemoteMediaControl();
        }
    }, [capture, getCurrentUser.audio, watchRemoteMediaControl]);

    useEffect(() => {
        if (
            (!isMediaSelfEnabled || isMediaSelfEnabled !== MEDIA_TYPES.shareScreen) &&
            screenShareEnabled !== getCurrentUser.screenSharing
        ) {
            toggleShareScreen(true);
        }
    }, [getCurrentUser.screenSharing, isMediaSelfEnabled, screenShareEnabled, toggleShareScreen]);

    const activeStreamsCount = playbacks.reduce(
        (count, pb) => (pb.mediaStream && pb.mediaStream.active ? ++count : count),
        0
    );

    if (videoConference.isLoading) return <VideoPlayerSpinner fixedHeight="350px" />;

    return (
        <>
            <div
                className={classNames('module -video', {
                    '-fullScreen': fullScreenVideoConference,
                })}
            >
                {!!selfPlayback && (
                    <VideoPlayerSelfVideo
                        activeStreamsCount={activeStreamsCount}
                        selfVidRef={selfVidRef}
                        selfPlayback={selfPlayback}
                        videoConference={videoConference}
                        user={userProfile}
                        startVideo={startVideo}
                    />
                )}
                <VideoPlayerVideoPlates
                    vidRefs={vidRefs}
                    activeStreamsCount={activeStreamsCount}
                    startVideo={startVideo}
                    forceUpdate={forceUpdate}
                />
                <VideoPlayerButtons
                    soundEnabled={soundEnabled}
                    videoEnabled={videoEnabled}
                    toggleSound={toggleSound}
                    toggleVideo={toggleVideo}
                    toggleScreen={toggleShareScreen}
                    setMediaSelfEnabled={setMediaSelfEnabled}
                    videoConference={videoConference}
                    withCallEndIcon={false}
                    startVideo={startVideo}
                />
                <VideoPlayerFullScreenButton
                    fullScreenVideoConference={fullScreenVideoConference}
                    setFullScreenVideoConference={toggleFullScreen}
                />
            </div>
            {userProfile.role !== PARTICIPANT_TYPES.speaker && <StreamControls user={userProfile.userId} />}
        </>
    );
}
