const no = {
	generalText: {
		upload: "Laste opp",
		save: "Lagre",
		add: "Legg til",
		remove: "Fjern",
		close: "Lukk",
		update: "Oppdater",
		cancel: "Avbryt",
		confirm: "Bekreft",
		download: "Last ned",
		refresh: "Oppdater",
		back: "Tilbake",
		submit: "Send inn",
		send: "Send",
		leave: "Forlat",
		step1: "Steg 1",
		step2: "Steg 2",
		or: "eller",
		delete: "Slett",
		please: "Vær så snill",
		before: "før",
		apply: "",
		approve: "Aksepter",
		decline: "Avslå",
		filters: "Filter",
		resetFilter: "Nullstill Filter",
		selectAll: "Velg Alle",
		start: "Start",
		stop: "Stopp",
		unsavedChangesText: "Du har ulagrede endringer. Vil du lagre dem?",
		unsavedChangesTitle: "Ulagrede endringer",
		unselectAll: "Klarere Alle",
	},
	auditorium: {
		camera: "Kamera",
		cancelDescription:
			"Ved å forlate vil du ikke lenger være en foredragsholde. Ønsker du å fortsette?",
		leavePopupDescription:
			"Ved å forlate auditoriumet vil du ble fjernet fra denne sesjonen",
		leavePopupTitle: "Forlate scenen",
		leaveSession: "Forlat scene",
		microphone: "Mikrofon",
		willStart: "Neste økt starter om ...",
		noAccess:
			"Din tilgangspakke inkluderer ikke tilgang til dette auditoriumet.",
		noVideos:
			"Det er ikke planlagt noen videoavspillinger i dette auditoriumet.",
		stageTab: "SCENE",
		videoWallButton: "VIDEOVEGG",
		resourcesButton: "RESSURSER",
		slotEndsTextFirst: "Dette programmet slutter om",
		slotEndsTextSecondUpdated:
			"Presentasjonen vil være tilgjengelig on demand.",
		slotTitleWith: "med",
		allAuditoriums: "Alle auditorium",
		onDemandButton: "ON-DEMAND",
		hide: "Skjul",
		settings: "Innstillinger",
		minimize: "Minimer",
		allFloors: "ALLE PLANTEGNINGER",
	},
	eventMenu: {
		coOrganizerButton: "TILPASS",
		organizerButton: "ARRANGØR",
		exhibitorButton: "UTSTILLER",
		posterStudyButton: "AKADEMISK UTSTILLER",
		boothButton: "STAND",
		boothsButton: "Stands",
		homeButton: "HJEM",
		contactsButton: "KONTAKTER",
		meetingsButton: "MØTER",
		chatsButton: "CHATTER",
		programButton: "PROGRAM",
		archiveButton: "ARKIV",
		myAccountButton: "MIN KONTO",
		lobbyButton: "Lobby",
		roomsButton: "ROM",
		connectionProblemTextFirst:
			"Det ser ut til å være et tilkoblingsproblem. Vennligst klikk",
		connectionProblemButton: "HER",
		connectionProblemTextSecond: "eller oppdater siden.",
		sessionProblemTextFirst: "Økten din har utløpt. Vennligst klikk",
		sessionProblemButton: "HER",
		sessionProblemTextSecond: "for å logge inn igjen.",
		metaverse: "METAVERSE",
		floorPlan: "PLANTEGNING",
	},
	exhibitorDashboard: {
		avatarsTab: {
			mainRepresentatives: {
				addDialog: {
					titleScholar: "Legg til forfatter",
					title: "Legg til avatar",
					editAvatarsTitle: "Rediger avatar",
					editAuthorTitle: "Rediger forfatter",
					contentLine1: "Velg en avatar fra galleriet nedenfor",
					contentLine2: "Last opp din personaliserte avatar.",
					contentLine3: "Trenger du hjelp? Ta kontakt med",
					noFile: "Velg fil:",
					assignButtonText: "Tildel",
					assignText:
						"Vennligst velg en arrangementdeltaker som skal knyttes til representanten ovenfor.",
					assignErrorBooth: "Brukeren er allerede en utstillerrepresentant",
					assignErrorAdditional:
						"Brukeren er allerede lagt til som ytterlig representant",
					input: "Søk etter navn",
				},
				title: "Utstillerrepresentanter",
				subtitle: "(kun én representant er obligatorisk)",
				leftRepresentative: "Venstre representant",
				rightRepresentative: "Høyre representant",
			},
			additionalRepresentatives: {
				title: "Flere representanter",
				subtitle: "(valgfritt, vil bli vist med profilbilde)",
				addDialog: {
					title: "Velg flere representanter",
					titleScholar: "Velg medforfatter",
					assignText:
						"Vennligst begynn å søke etter arrangementsdeltaker for å legge ham/henne til som en representant.",
					assignError: "Brukeren er allerede en utstillerrepresentant!",
				},
			},
			author: {
				title: "Hovedforfatter",
				subtitle:
					"Kun legg til en forfatter som er tilknyttet arrangementet. Du kan legge til én forfatter.",
				mainRepresentative: "Hovedrepresentant",
			},
			coAuthors: {
				title: "Medforfatter",
				singularTitle: "Medforfatter",
				subtitle:
					"Kun legg til medforfattere som er registrert til arrangementet. Du kan legge til opptil 4 medforfattere.",
			},
		},
		posterStudyTab: {
			highlightAbstractError: "Du har nådd maksimum antall tegn! (500 tegn)",
			posterStudyTitle: "Tittel",
			studyTitleInputLabel: "Akademisk utstiller tittel",
			highlightAbstractTitle: "Høydepunkter/abstrakter",
			studyHighlightsInputLabel: "Høydepunkter/akstrakter",
			authorNameLabel: "Forfatternavn",
			authorAffiliationLabel: "Tilhørighet",
		},
		event: "Arrangement",
		title: "Administrer din utstillerplass",
		visitorsButton: "Besøkende",
		goToBoothPreview: "Stand forhåndsvisning",
		previewButton: "Forhåndsvisning",
		tabStyle: "Utforming",
		tabLogo: "Logo",
		tabStudyDetails: "Akademisk utstiller",
		tabFiles: "Filer",
		tabStudyFile: "Fil",
		tabLinks: "Lenker",
		tabAvatars: "Avatarer",
		tabStudyAuthors: "Forfattere",
		tabVideo: "Video",
		boothStyleTab: {
			optionOne: "Valg 1 - Skandinavisk",
			optionTwo: "Valg 2 - Moderne",
			optionThree: "Valg 3 - Dynamisk",
			optionFour: "Valg 4 - Egentilpasset",
			optionScholarOne: "Valg 1 - Einstein",
			optionScholarTwo: "Valg 2 - Curie",
			optionScholarThree: "Valg 3 - da Vinci",
		},
		logoTab: {
			fileUploadButton: "Trykk her for å laste opp",
			noFile: "Ingen fil valg",
		},
		filesTab: {
			title: "Dokumenter:",
			fileNameInput: "Dokumentnavn (synlig for alle deltakere)",
			chooseFileButton: "Velg fil",
			noDocAdded: "Ingen dokumenter lagt til",
			fileError: "Vennligst legg til et dokumentnavn",
		},
		fileStudyTab: {
			title: "Last opp pdf-fil",
			description:
				"Last opp en plakat: oppgaven eller presentasjonen vil bli tilgjengelig på din tavle (20Mb. er er filgrense)",
			uploadPdfInputLabel: "Last opp pdf-fil",
			uploadNewPdfInputLabel: "Last opp en ny PDF-fil",
			uploadedFile: "Opplastet",
			dragContainerTitle: "Dra filen hit",
			dragContainerNewTitle: "Dra den nye filen hit",
		},
		linksTab: {
			social: {
				title: "Sosiale media lenker",
				subtitle: "(synlig for alle deltakere)",
				facebookInput: "Legg til Facebook lenken din",
				linkedinInput: "Legg til LinkedIn lenken din",
				twitterInput: "Legg til Twitter lenken din",
				googleScholarInput: "Legg til Google Scholar din",
				researchGateInput: "Legg til Research Gate din",
			},
			other: {
				linkInput: "Lenke",
				linkLabel: "Navngi lenken",
			},
		},
		linksStudyTab: {
			authorProfiles: "Forfatterprofil",
			additionalLinks: "Flere lenker",
		},
		videoTab: {
			video: {
				title: "Stand video",
				subtitle: "(Kun Youtube video link)",
				input: "Skriv inn Youtube URL",
			},
			image: {
				subtitle:
					"(Bildet vises på standen din i tilfelle du ikke har lastet opp en video link i seksjonen over)",
			},
		},
	},
	gdpr: {
		changeYourConsent: "Endre samtykke",
		updateCookieConsent: "Oppdater informasjonskapsel samtykke",
		withdrawSnackbarText: "Innformasjonskapsel samtykke har blitt fjernet",
		withdrawYourConsent: "Fjerne samtykke",
		disclaimerTextFirst:
			"Dette dokumentet ble oversatt av kunstig intelligens. Skulle dette være inkonsekvens, vennligst les den engelske versonen av dokumentet",
		disclaimerTextSecond: "HER",
		metaTitlePrivacyPolicy: "Personvernerklæring",
		metaTitleCookiesPolicy: "Informasjonskapsler",
		metaTitleTerms: 0,
	},
	time: {
		justNow: "akkurat nå",
		minute: "minutt",
		minutes: "minutter",
		hour: "time",
		hours: "timer",
		day: "dag",
		days: "dager",
		month: "måned",
		months: "måneder",
		yesterday: "i går",
		today: "i dag",
		tomorrow: "i morgen",
		monday: "mandag",
		tuesday: "tirsdag",
		wednesday: "onsdag",
		thursday: "torsdag",
		friday: "fredag",
		saturday: "lørdag",
		sunday: "søndag",
		january: "januar",
		february: "februar",
		march: "mars",
		april: "april",
		may: "mai",
		june: "juni",
		july: "juli",
		august: "august",
		september: "september",
		october: "oktober",
		november: "november",
		december: "desember",
		januaryShort: "jan",
		februaryShort: "feb",
		marchShort: "mars",
		aprilShort: "april",
		mayShort: "mai",
		juneShort: "jun",
		julyShort: "jul",
		augustShort: "aug",
		septemberShort: "sept",
		octoberShort: "okt",
		novemberShort: "nov",
		decemberShort: "des",
	},
	errors: {
		noFile: "Ingen fil valg",
		required: "Feltet er påkrevet",
		emailNotValid: "e-posten er ikke gyldig",
		passwordMinLength: "Passordet må ha min 8 karakterer",
		passwordMismatch: "Passordene stemmer ikke overens",
		passwordIsSame: "Ditt nye passord må være ulikt",
		noInvitationCode: "Vennligst skriv inn invitasjonskoden din",
		imageFileNotSupported:
			"Filtypen støttes ikke. Vennligst bruk en av de følgende:",
		phoneInvalid:
			"Vennligst angi et gyldig telefonnummer. F.eks.: +447517663925",
		linkInvalid: "Vennligst angi en gyldig link (https://site.com)",
		facebookLinkInvalid:
			"Vennligst angi en gyldig link (https://facebook.com/)",
		linkedinLinkInvalid:
			"Vennligst angi en gyldig link (https://linkedin.com/)",
		twitterLinkInvalid: "Vennligst angi en gyldig link (https://twitter.com/)",
		youtubeLinkInvalid:
			"Vennligst anni en gyldig link (https://www.youtube.com/)",
		googleScholarLinkInvalid:
			"Vennligst angi en gyldig link (https://scholar.google.com/)",
		researchGateLinkInvalid:
			"Vennligst angi en gyldig link (https://www.researchgate.net/)",
		connectionProblemTextFirst:
			"Det er nye oppdateringer tilgjengelig. Vennligst klikk",
		connectionProblemButton: "KLIKK",
		connectionProblemTextSecond: "eller oppdater siden.",
		fileTooLarge: "Filen er for stor. Maksimalt tillatt størrelse er 2MB.",
		fileTooLargeTextFirst: "Filen er for stor.",
		fileTooLargeTextSecond: "maks. filstørrelse.",
		maxWallInputLength: "Du nådde maksimalt 1200 tegn.",
	},
	footer: {
		cookiesText:
			"Dette nettstedet bruker essensielle informasjonskapsler som er nødvendige for at nettsidene skal fungere. Det brukes også tredjeparts informasjonskapsler, som er nødvendige for at enkelte av funksjonene på nettstedet vårt skal fungere. Hvis du vil lære mer eller trekke tilbake samtykket til bruken av enkelte informasjonskapsler, kan du se våre",
		cookiesLink: "retningslinjer for informasjonskapsler",
		cookiesButton: "Godta",
		privacyPolicy: "Personvernerklæring",
		cookies: "Informasjonskapsler",
		termsAndConditions: "Vilkår og betingelser",
		socialLinksTitle: "Følg oss:",
	},
	menu: {
		logIn: "Logg inn",
		logOut: "Logg ut",
		scholarRegistration: "Påmelding akademisk utstiller",
		exhibitorRegistration: "Påmelding utstiller",
		participantRegistration: "Påmelding deltaker",
		createEvent: "Organiser arrangement",
	},
	externalIdTranslations: {
		headline: "Du er registrert",
		subHeadlineOne: "Økten den ",
		subHeadlineTwo: "er nå i din Nevrale Nettverkskalender!",
		button: "VIS PROGRAM",
		checkCalendar:
			'Sjekk "<strong>My Calendar</strong>" under "<strong>Program</strong>"-fanen for kommende økter, og utforsk hundrevis av tidligere økter ved å bruke søkefiltrene. Og gå ikke glipp av "<strong>Smart Suggestions</strong>" for innhold kuratert bare for deg!',
	},

	homepage: {
		organizedBy: "ORGANISERT AV",
		date: "DATO",
		location: "DOMENE OG STED",
		price: "PRIS",
		tabIntro: "INTRO",
		tabProgram: "PROGRAM",
		tabSponsors: "SPONSORER",
		tabPartners: "PARTNERE",
		tabExhibitors: "UTSTILLERE",
		previousEventsSectionTitle: "TIDLIGERE ARRANGEMENT",
	},
	socialButtons: {
		linkedinButton: "Logg inn med LinkedIn",
		loginTabLinkedinButton: "med LinkedIn",
		facebookButton: "Logg inn med Facebook",
		loginTabFacebookButton: "med Facebook",
		googleButton: "Logg inn med Google",
		loginTabGoogleButton: "med Google",
		twitterButton: "Logg inn med Twitter",
		loginTabTwitterButton: "med Twitter",
		socialButtonsLogInTitle: "Registrer deg med din sosiale medieprofil",
		socialButtonsSignUpTitle: "Registrer deg med din sosiale medieprofil",
	},
	login: {
		inputEmail: "E-post",
		inputPassword: "Passord",
		rememberMeButton: "Husk meg",
		forgotPasswordButton: "Glemt passord?",
		loginButton: "Logg inn",
		loginTitleLowerCase: "logg inn",
		recoverPasswordDialogTitle: "GJENOPPRETT PASSORD",
		recoverPasswordDialogRecoverButton: "GJENOPPRETT",
		errorIncorrectDates: "Feil e-post eller passord",
		orLogInWithEmail: "eller logg inn med e-post",
		logInAsParticipantSecondText: "som deltaker",
		logInAsExhibitorSecondText: "som utstiller",
		logInAsPosterSecondText: "som akademisk utstiller",
		errorAccountNotFound: "Vi kunne ikke finne din Neural Network-profil",
	},
	register: {
		inputFirstName: "Fornavn",
		inputLastName: "Etternavn",
		inputEmail: "E-post",
		inputPassword: "Passord",
		inputConfirmPassword: "Bekreft passordet",
		gdprTextFirst: "Ved å fortsette, godtar du våre",
		gdprTextTerms: "Vilkår for bruk",
		gdprTextSecond: "og bekrefter at du har lest våre",
		gdprTextCookies: "Informasjonskapsler",
		gdprTextThird: "og",
		gdprTextPrivacy: "Personvernerklæring",
		gdprTextAgree: "Vennligst godta personvernreglene",
		registerButton: "Registrer deg",
		signUpTitleLowerCase: "Registrer deg",
		invitationCode: "Invitasjonskode",
		registerNotAllowed: "Registreringen er stengt",
		logInTitleFirst: "Bli med",
		logInTitleSecond: "arrangement",
		signUpTitleTextFirst: "Første gang hos",
		signUpTitleTextSecond: "Neural Network",
		orFillInTheForm: "eller fyll inn skjemaet under",
		uploadProfilePicture: "Last opp profilbilde",
		editProfilePicture: "Rediger profilbildet",
		errorAlreadyHaveMOAccount: "Du har allerede en Neural Network-konto",
		step1From2Text: "trinn 1/2",
		step2From2Text: "trinn 2/2",
		metaTitleScholarRegistration: "Akademisk utstiller registrering",
	},
	participantRegistration: {
		metaTitle: "Deltakerregistrering",
		choosePackageTitle: "VELG TILGANGSPAKKE",
		noPackageText: "Vennligst velg en pakke",
		gdprTextFirst: "Ved å fortsette, godtar du våre",
		gdprTextPrivacy: "Personvernregler for arrangementet",
		gdprTextSecond: "og",
		gdprTextTerms: "vilkår og betingelser for arrangementet",
		gdprTextAgree: "Vennligst godta personvernreglene",
		registerButtonFree: "Fullfør registreringen",
		registerButtonPay: "Fortsett til betaling",
	},
	exhibitorRegistration: {
		metaTitle: "Utstiller registrering",
		choosePackageTitle: "VELG TILGANGSPAKKE",
		gdprTextFirst: "Ved å fortsette, godtar du våre",
		gdprTextPrivacy: "personvernregler for arrangementet",
		gdprTextSecond: "og",
		gdprTextTerms: "vilkår og betingelser for arrangementet",
		gdprTextAgree: "Vennligst godta personvernreglene",
		registerButtonFree: "Fullfør registreringen",
		registerButtonPay: "Fortsett til betaling",
	},
	stripePayment: {
		cardNumber: "Kortnummer",
		expirationDate: "Utløpsdato (MM/ÅÅ)",
		cvc: "CVC",
		disclaimerText:
			"Denne betalingsløsningen er kryptert fra ende til ende. Sikkerhet og personvern garanteres av Stripe Services.",
		disclaimerTextLink: "Trykk her for å lære mer.",
		cancelButton: "Avbryt",
		payButton: "Betal",
	},
	recoverPassword: {
		recoverTextSend: "E-post for gjenoppretting av passord er sendt.",
	},
	unsubscribe: {
		unsubscribeTitle: "Avslutte abonnementet?",
		unsubscribeHomepage: "Gå til hjemmesiden",
	},
	notAvailable: {
		fullOverlayEvents: "Sjekk ut dine andre arrangementer",
		fullOverlayNoEvents: "Ingen andre arrangement funnet",
		description:
			"Arrangementet du ser etter er utilgjengelig. Det har enten blitt lukket av arrangøren eller deaktivert av en administrator",
		title: "Arrangementet er utilgjengelig",
	},
	notFound: {
		notFoundDescription: "Ops ... Ingen arrangementer å finne her!",
		notFoundHomepage: "Gå til hjemmesiden",
	},
	myEventsDropdown: {
		title: "MINE ARRANGEMENTER",
		createButtonDesktop: "Opprett arrangement",
		createButtonMobile: "Opprett",
	},
	myBoothsDropdown: {
		title: "MINE STANDS",
		emptyStudyTitle: "Akademisk utstillertavle",
	},
	contactsDropdown: {
		title: "MINE KONTAKTER",
		requestsTitle: "Kontaktforespørsler",
		rejectButton: "Avvis",
		acceptButton: "Godta",
		pendingButton: "Avventer",
		addButton: "Legg til",
		noContacts:
			"Du har for øyeblikket ingen kontakter. Vennligst besøk nettverkseksjonen for å legge til en ny kontakt",
		noScannedContacts: "Du har ingen skannede kontakter ennå.",
	},
	meetingsDropdown: {
		meetingWith: "Møte med",
		pending: "Venter",
		accepted: "Godtatt",
		canceled: "Kansellert",
		noMeetings: "Ingen møter er planlagt for øyeblikket",
		finished: "Ferdig",
	},
	programDropdown: {
		with: "med",
		noProgram: "Ingen programmer er tilgjengelige for øyeblikket",
	},
	program: {
		inPerson: "Personlig",
		online: "På nett",
		searchWrapperTitle: "SØK GJENNOM ALLE SESJONER",
		searchWrapperTitlePast: "SØK GJENNOM ALLE TIDLIGERE SESJONER",
		searchWrapperTitleUpcoming: "SØK GJENNOM ALLE KOMMENDE SESJONER",
		searchWrapperTitleInterval: "SØK GJENNOM SESJONER",
	},
	notificationsDropdown: {
		title: "VARSLER",
		contactRequestReceived: "sendte deg en kontaktforespørsel. Ta en titt!",
		contactRequestAccepted: "godtok din kontaktforespørsel. Start en samtale!",
		meetingRequestReceived: "sendte deg en møteforespørsel. Ta en titt!",
		meetingRequestAccepted: "godtok din møteforespørsel. Start en samtale!",
		meetingRequestCanceled: "kansellerte en møteforespørsel. Ta en titt!",
		meetingStarting: "Et møte starter snart. Ta en titt!",
		newBoothPost: "har publisert noe på utstillerveggen din. Sjekk det ut!",
		noNotifications: "Du har ingen varsler for øyeblikket.",
		seeAll: "SE ALLE",
		hideAll: "SKJUL ALLE",
		meetingWillStartTextFirst: "Et møte med",
		meetingWillStartTextSecond: "vil begynne snart. Ta en titt!",
	},
	chatsDropdown: {
		title: "CHATTER",
		newChatProvideText:
			"Oppgi et navn på chatten din. Du vil kunne redigere navnet etterpå:",
		noChats: "Opprett minst én chat for å snakke med andre deltakere",
		members: "Medlemmer",
		noOpenedChatText: "Trykk på chat for å fortsette samtalen.",
		noMessagesInChatTextFirst: "Bryt isen.",
		noMessagesInChatTextSecond: "Vær den som starter denne samtalen.",
		newChatNameInput: "Chat navn (valgfritt)",
		newChatAddMembers:
			"Legg til kontakter og andre deltakere til denne chatten:",
		editChatAddMembers:
			"Vennligst søk etter brukere fra dette arrangementet som du ønsker å legge til i gruppechatten.",
		newChatSearchInput: "Søk etter brukere",
		newChatNoUsersMessage: "Ingen brukere funnet",
		newChatRemoveUserButton: "FJERN",
		newChatCancelButton: "LUKK",
		newChatCreateButton: "OPPRETT",
		membersLeaveButton: "FORLAT",
		membersUpdateButton: "OPPDATER",
		membersChatButton: "CHAT",
		chatSettingsButton: "Innstillinger",
		newMessageInput: "Skriv melding her",
		newVideoCallTooltipText: "Klikk her for å starte en videosamtale",
		leaveVideoMeetingTitle: "Forlate videomøtet",
		leaveVideoMeetingDescription:
			"Du er i ferd med å forlate videomøtet. Er du sikker på at du vil fortsette?",
	},
	myAccountDropdown: {
		title: "MIN KONTO",
		viewProfileButton: "Se profil",
		logoutButton: "Logg ut",
	},
	myAccountPage: {
		changePasswordButton: "ENDRE PASSORD",
		deleteAccountButton: "SLETT KONTOEN MIN",
		platformLanguage: "Plattformspråk:",
		privacyPolicyLink: "Personvernerklæring",
		cookiesLink: "informasjonskapsler",
		termsAndConditionsLink: "Vilkår og betingelser",
		eventProfileSectionTitle: "Arrangementprofil",
		eventPackage: "Arrangementpakke",
		unregisterFromEventButton: "AVREGISTRER DEG FRA ARRANGEMENTET",
		unregisterCurrentEventButton: "Avregistrer deg fra gjeldende arrangement",
		unregisterCurrentEventConfirmation:
			"Vennligst bekreft at du ønsker å avregistrere fra arrangementet",
		enableParticipantProfile: "Aktiver deltakerprofil",
		editUserDialogTitle: "Rediger brukeropplysninger",
		editExhibitorDialogTitle: "Rediger selskapets kontaktinformasjon",
		editScholarDialogTitle: "Rediger akademisk utstillers kontaktinformasjon",
		editParticipantDialogTitle: "Rediger deltakeropplysninger",
		firstName: "Fornavn",
		lastName: "Etternavn",
		uploadImage: "Last opp bilde",
		removeImage: "Fjern bilde",
		deleteTitle: "SLETT KONTOEN MIN",
		deleteConfirm: "Bekreft sletting av min konto",
		deleteError:
			"Du kan ikke slette kontoen din. Vennligst slett alle arrangementene du har opprettet for å kunne slette kontoen din.",
		deleteSuccess: "Kontoen din har blitt slettet",
		reviewEventPrivacyPolicy: "Se gjennom vår arrangement-personvernerklæring",
		title: "Tittel",
		company: "Selskap",
		phone: "Telefon",
		platformProfileSectionTitleTextSecond: "profil",
		reviewEventConditions: "Les våre arrangementbetingelser",
		reviewPlatformConditions: "Les våre plattformbetingelser",
	},
	changePassword: {
		title: "ENDRE PASSORD",
		oldPassword: "Gammelt passord",
		oldPasswordError: "Gammelt passord samsvarer ikke!",
		inputPassword: "Nytt Passord",
		inputConfirmPassword: "Bekreft passordet",
	},
	roomsDropdown: {
		title: "ROM MENY",
		auditoriumButton: "Auditorium",
		archiveButton: "ARKIV",
		onDemandButton: "ON-DEMAND",
		metaverse: "METAVERSE",
		floorPlan: "PLANTEGNING",
	},
	sideMenu: {
		liveWall: "LIVE VEGG",
		networking: "Nettverk",
		audience: "DELTAKER",
		info: "INFO",
		videoWall: "VIDEOVEGG",
		booth: "STAND",
		boothWall: "Utstillervegg",
		networkingTooltip:
			"Du har ikke tilgang til alle nettverksbygging-funksjonene.",
		attendees: "Deltaker",
	},
	wall: {
		sortBy: "Sorter etter:",
		popularity: "Populæritet",
		time: "TID",
		emptyWallText: "Vær den første til å legge ut et innlegg her.",
		newPostInput: "Hva tenker du på?",
		newCommentInput: "Kommentar",
		resourcesVideoDescription: "VIDEOBESKRIVELSE",
		resourcesLinks: "Lenker",
		resourcesFiles: "Filer",
		pinTitle: "Feste",
		unPinTitle: "Løsne",
		deleteDialogTitle: "Slette innlegg?",
		pinDialogDescription:
			"Det er allerede festet et innlegg. Kun ett innlegg kan være festet av gangen.",
		edited: "Rediger",
		escapeToCancel: "klikk escape for å avbryte",
		enterToSave: "klikk enter for å lagre",
		editingState: "Rediger melding",
		approvePost: "Godkjenn post",
		approvePostComment: "Godkjenn kommentar",
		bannerModeration:
			"Poster og kommentarer på denne veggen blir moderert for å sikre kvalitetsinnhold",
		commentsPendingApproval: "Kommentarer avventer godkjenning",
		pendingApproval: "Avventer godkjenning",
	},
	networking: {
		searchInput: "Søk etter brukeren",
		emptyNetworkingSearchResults:
			"Personen du leter etter har ikke registrert seg til dette arrangementet",
		emptyAuditoriumNetworkingSearchResults:
			"Personen du leter etter har enda ikke deltatt på presentasjonen",
		alphabetical: "alfabetisk",
		online: "digitalt",
		chat: "Chat",
		applyFilterTooltip: "For å slå på filter velg minst 1 alternativ",
		matching: "matcher",
		matchingAlgo:
			"Matching algoritmen jobber. Gi oss et minutt mens vi finner dine beste matcher!",
		searchFilters: "Søke Filter",
		regenerateMatches: "regenerere fyrstikker",
	},
	hubDropdownTitle: "HUB MENY",
	infoMessages: {
		imageExplain420x200:
			"Anbefalt bildeforhold 1.8:1 (landskap, f.eks. 420 x 200 piksler)",
		imageExplainTB230x320:
			"Anbefalt bildeforhold 1:1.4 (portrett, f.eks. 230 x 320 piksler) med gjennomsiktig bakgrunn",
		imageExplainTB230x875:
			"Anbefalt bildeforhold 1:3.8 (portrett, f.eks. 230 x 875 piksler) med gjennomsiktig bakgrunn",
	},
	closedAuditoriumDialog: {
		title: "Auditoriumsinformasjon",
		contentPackage:
			"Din tilgangspakke inkluderer ikke tilgang til noen auditoriumer.",
	},
	closedShowfloorDialog: {
		title: "Informasjon for utstillerområdet",
		contentPackage:
			"Din tilgangspakke inkluderer ikke tilgang til utstillerområdet.",
		button: "OK",
	},
	marketplace: {
		addNew: "LEGG TIL NY",
		addNewProduct: "Legg til nytt produkt",
		descriptionHint: "Beskrivelse er begrenset til 300 tegn",
		editProduct: "Endre produkt",
		label: "PRODUKTER",
		noProducts: "Ingen produkter enda",
		productDescription: "Produkt beskrivelse",
		productImage: "PRODUKT BILDE",
		productLink: "Produkt lenke",
		productTitle: "Produkt tittel",
		publish: "PUBLISER",
		title: {
			default: "Markedsplass",
			uppercased: "MARKEDSPLASS",
		},
		uploadHint:
			"Last opp et bilde som vil være tilgjengelig i produkt forhåndsvisningen. Vi foreslår 600*600 px resolusjon for å ha bedre bilde kvalitet",
		uploadImage: "LAST OPP BILDE FOR Å ØKE PRODUKTETS VERDI",
		uploaded: "Opplastet",
	},
	auditoriumArchive: {
		title: "Auditorium arkiv",
		titleEvent: "Arrangementsarkiv",
		with: "med",
		noVideosAvailable: "Alle økter er ferdige",
	},
	videoPlayer: {
		videoNotPlayingError:
			"Har du problemer med videostrømmen? Vennligst last inn på nytt.",
		videoEndedTextFirst: "Videoen er ferdig. Takk for at du så på!",
		videoEndedTextSecond: "Videoveggen er åpen for spørsmål og svar.",
		tooltipPlay: "Spill av",
		tooltipPause: "Pause",
		tooltipMinimize: "Minimer",
		tooltipFullscreen: "Fullskjerm",
		tooltipMute: "Slå på lyd",
		tooltipUnmute: "Slå av lyd",
		videoStreamNotStarted: "Vi går live snart. Følg med!",
	},
	showfloor: {
		allExhibitorsButton: "ALLE UTSTILLERE",
		featuredExhibitorsText: "UTVALGTE UTSTILLERE",
		boothLinksDialogTitle: "UTSTILLERLENKER",
		boothFilesDialogTitle: "UTSTILLERFILER",
		boothVideoDialogTitle: "VIDEO",
		mobileTabChat: "CHAT",
		mobileTabPoster: "PLAKAT",
		mobileTabFiles: "FILER",
		mobileTabLinks: "LENKER",
		mobileTabVideo: "VIDEO",
		noExhibitorsTextFirst: "UTSTILLERNE JOBBER MED STANDENE SINE",
		noPresentersTextFirst: "Utstillerene jobber med standene sine",
		noExhibitorsTextSecond: "FØLG MED",
		showShowfloorsButton: "ALLE UTSTILLEROMRÅDER",
		showShowfloorsPosterButton: "ALLE AKADEMISKE UTSTILLERE",
		showShowfloorsExhibitorButton: "ALLE UTSTILLERE",
		searchExhibitors: "Søk etter utstillere",
		searchPosters: "Søk etter tittel eller forfatterens navn",
		searchExhibitorsMultiple: "Søk etter utstillere i denne utstillerhallen",
		searchPostersMultiple:
			"Søk etter tittel eller forfatterens navn i denne utstillerhallen",
		showfloorsTitle: "Utstillerområde",
		collapseList: "Skjul liste",
		seeFullList: "Se full liste",
		emptyExhibitorsResults:
			"Utstilleren du søkte etter er ikke tilstede. Men vi vil invitere dem neste gang",
		emptyPostersResults:
			"Den akademiske utstilleren du søkte etter ikke tilstede. Men vi vil invitere dem neste gang",
		posters: "plakat",
		allPostersStudiesTitle: "Alle akademiske utstillere",
		authors: "forfattere",
		titleNotAvailable: "Tittel er ikke tilgjengelig",
		abstractNotAvailable: "Abstrakt ikke tilgjengelig",
		authorsNotAvailable: "Forfatteren er ikke tilgjenglig",
		openPosterFile: "Åpne plakat-fil",
		fileIsNotAvailable: "Filen er ikke tilgjengelig",
		posterBooth: {
			study: "Akademisk utstiller",
			authors: "Forfattere",
			highlights: "Abstrakt",
			scrollInfoText: "SKROLL NEDOVER FOR Å LESE DET FULLE ABSTRAKTET",
		},
	},
	eventArchive: {
		title: "Videoarkiv",
		searchText: "Trykk for å søke",
		noArchivedEvents: "Det er for øyeblikket ingen arkiverte arrangementer.",
	},
	boothDashboard: {
		event: "Arrangement",
		title: "Din stand",
		manage: "Administrer",
		statistics: "Statistikk",
	},
	polls: {
		polls: "MENINGSMÅLING",
		noPolls: "Ingen meningsmålinger har blitt lagt til for øyeblikket.",
		pollProgress: "Meningsmåling pågår",
		pollClosed: "Meningsmåling stengt",
		pollVoted: "av detalkerne stemte",
	},
	onDemandVideos: {
		sectionTitle: "ON-DEMAND",
		noOnDemandVideos: "Ingen on demand-videoer har blitt lagt til enda.",
	},
	meetings: {
		meeting: "MØTE",
		createDialog: {
			createTitle: "Be om møte med",
			inputDate: "Dato",
			inputStart: "Starter",
			inputEnd: "Slutt",
			inputTimezone: "Tidssone",
			inputNote:
				"Merk: Kun ett møte kan planlegges om gangen med samme person.",
			meetingError:
				"Møtedatoen eller tidspunkt er ugyldig. Vennligst sjekk detaljene!",
			buttonDisabled: "Du kan planlegge et møte etter at du starter samtalen!",
		},
		banner: {
			pending: "Møteforespørsel",
			accepted: "Møte planlagt for",
			progress: "Møte pågår",
			cancelTitle: "Kansellere møtet?",
			cancelDescription: "Er du sikker på at du vil kansellere dette møtet?",
			startCall: "START SAMTALE",
			joinCall: "BLI MED",
			pendingOwner: "Avventende møteforespørsel for",
			videoCallStarted: "Videomøtet har startet",
			videoCallStartedInfo: "Videmøtet vil fungere best med opptil 10 personer",
		},
		title: "Dine møter",
		meetingsInfo:
			"Møter kan planlegges fra chat. Start en prat med brukeren du vil planlegge et møte med.",
	},
	socialMediaShare: {
		event: "arrangement",
		shareLabel: "Dele",
		dialogTitleGeneral: "Del med ditt nettverk",
		dialogTitlePost: "Del ditt innlegg",
		linkedinLabel: "LinkedIn",
		facebookLabel: "Facebook",
		twitterLabel: "Twitter",
		emailLabel: "E-post",
		clipboardLabel: "Utklippstavle",
		clipboardSnackbarMessage: "Venstre representant",
		linkedinButtonTitle: "Del på LinkedIn",
		facebookButtonTitle: "Del på Facebook",
		twitterButtonTitle: "Del på Twitter",
		emailButtonTitle: "Del via e-post",
		clipboardButtonTitle: "Kopier til utklippstavlen",
		imAttending: 0,
		interestedClick: "Interessert i å delta? Klikk her",
	},
	matching: {
		btwResultsResponsive:
			"Forresten, du kan finne alle matchene dine i NETTVERK",
		clickStartChat: "Klikk chat for å starte en samtale",
		closeMatchingTextA:
			"Hvis du lukker dette vinduet før du har fullført vil vi ikke lagre noen av svarene dine og Smart Matching resultatene vil ikke være tilgjengelig",
		closeMatchingTextB:
			"Din profil vil ikke være en del av Smart Matching prosessen og noen vil bli matchet med deg.",
		closeMatchingTitle: "Lukke matching spørsmålene?",
		doItLaterBtn: "FULLFØR SENERE",
		highMatch: "Høy Match",
		infoTooltipClickToSee2: "for å se hvem du bør snakke med!",
		infoTooltipJump: "Klikk her for å se dine matcher",
		loadingAlgoText:
			"Matching algoritmen jobber. Gi oss et minutt mens vi finner dine beste matcher!",
		matchesLabel: "Matcher",
		matchingBannerClosed:
			"Du kan alltid fylle ut Smart Matching spørsmålene i Nettverk seksjonen under Matches",
		matchingDetailsFormTitle: "Smart matching detaljer",
		matchingQuestionnaireIntro:
			"La oss hjelpe deg å finne relevante kontakter for deg! Utrolige ting skjer når sammenfallende sinn treffes, fullfør spørsmålene for å komme i gang",
		matchingQuestionnaireIntroSkip:
			"Ved å hoppe over spørsmålene vil vi ikke kunne matche deg med resten av deltakerne. Du kan alltid fullføre spørsmålene senere i Nettverk seksjonen. Alle svarene kan endres fra Min Konto.",
		matchingQuestionnaireTitle: "Matching spørsmål",
		matchingRequiredError: "Du har fullført alle obligatoriske spørsmål",
		matchingTooltip: "Klikk på Match for å se hvem du bør snakke med!",
		matchLabel: "Match",
		mediumMatch: "Medium Match",
		multiSelect: "Flervalg",
		myAccountMatchingSectionTitle: "Smart matching detaljer",
		nextBtn: "Neste",
		noMoreMatches:
			"Uff da! Vi kan ikke finne flere matcher for deg for tiden. Vennligst kom tilbake senere når flere deltakere har blitt med.",
		noResults:
			"Takk for at du prøver Smart Matching funksjonen! Vi leter etter dine beste matcher så fort nye deltakere blir med. Du vil finne matchene dine under Match fanen i Nettverk",
		noResultsTitle:
			"Gratulerer, du gjorde det! Vi leter etter de beste matchene for deg!",
		optionalQuestion: "Frivillig",
		rejectMatchingBtn: "AVSLÅ MATCHING DELTAKELSE",
		rejectMatchingText:
			"Dine svar vil bli slettet og du vil ikke bli matchet med andre brukere. Du kan fullføre Matching spørsmålene på nytt, når som helst.",
		rejectMatchingTitle: "Avslå Matching deltakelse",
		requiredQuestion: "Obligatorisk",
		resultsNoteLabel: "OBS:",
		resultsNoteMyAccount: "All svarene kan endres i Min Konto",
		resultsNoteText:
			"Du kan alltid fylle ut Smart Matching spørsmålene i Nettverk seksjonen",
		resultsTitle: "Her er dine topp matcher",
		seeMatchesBtn: "SE ALLE MATCHER",
		singleSelect: "Velg én",
		tryMatchingBanner:
			"Møt noen meningsfulle kontakter, prøv vår Smart Matching funksjon!",
		tryMatchingBtn: "PRØV SMART MATCHING",
		backBtn: "Tilbake",
		finishBtn: "Ferdig",
		infoTooltipClickToSee1: "Klikk",
	},
	videoConferences: {
		fullScreen: "ÅPNE FULLSKJERM",
		minimizeScreen: "MINIMER SKJERM",
		startedVideoChat: "start en videosamtale",
		timeAgo: "siden",
		videoCallStarted: "Videosamtalen har startet",
	},
	sessions: {
		edit: "REDIGER",
		video: "VIDEO",
		sessionChat: "CHAT",
		acceptInvitationButton: "Aksepter invitasjonen",
		acceptInvitationSubTitle:
			"Moderatoren har invitert deg til å snakke. Hva sier du?",
		acceptInvitationTitle: "Du har fått invitasjonen",
		allFilter: "alle",
		anotherUserIsScreenSharing: "Noen andre deler allerede skjerm",
		approvedSubTitle:
			"Denne sesjonen blir filmet. Ved å bli med godtar du prosessering av dine personlige dataer.",
		approvedTitle: "Vennligs sjekk mikrofonen og videoen og klikk Jeg Er Klar",
		cancelRequest: "Kansellere forespørsel",
		clickToUpload: "Klikk her for å laste opp",
		connectedToTheBackstage: "Du er nå live og koblet til backstage",
		copyUrl: "kopier URL",
		declinedInvitation: "avslå invitasjon til å snakke",
		disabledByModerator: "Slått av av moderator",
		disabledScreenSharing: "Slå av skjermdeling før du starter video",
		disabledUntilReady: "Slått av inntil klar",
		disabledVideoSharing: "Slå av video før du deler skjerm",
		fromAuditorium: "fra Auditoriumet",
		gettingReady: "Bli klar",
		hasLeftTheSession: "har forlatt sesjonen",
		imReady: "Jeg er klar",
		invitationAccepted: "Aksepter invitasjonen",
		inviteToSpeak: "Inviter til å snakke",
		isReady: "Er Klar",
		isTryingToRegister: "prøver å registrere som",
		mic: "Mikrofon",
		moderators: "MODERATOR",
		moderatorSingular: "moderator",
		moderatorUrl: "Moderator URL",
		noAvailableChat:
			"Ingen chat tilgjengelig. For å se chatten, vennligst koble denne sesjonen til tidsprogrammet",
		noSpeakersJoined: "Ingen foredragsholdere har blitt med enda",
		rec: "Filme",
		removedFromTheSession:
			"Du har blitt tatt ut av sesjonen, vennligst ta kontakt med en moderator",
		removeRoleDescription: "Er du sikker på at du vil fjerne",
		requestMic: "BE OM MIKROFONEN",
		requestPending: "Forespørsel venter",
		requestPendingSubTitle:
			"Vennligst vent til moderatoren slipper deg inn på strømmingen",
		requestPendingTitle: "Forespørsel venter...",
		screen: "Skjerm",
		sessionHasEnded: "Skjerm",
		sessionLinks: "Sesjonen er avsluttet",
		sessionSettings: "SESJONSLENKER",
		shareScreen: "Dele skjerm",
		speakers: "FOREDRAGSHOLDER",
		speakerSingular: "foredragsholder",
		speakerUrl: "Foredragsholder URL",
		startLiveSessionDescription:
			"Opptaket av sesjonen vil automatisk starte når du starter strømmingen",
		startLiveSessionStreamTitle: "Start Live Sesjon Strømming",
		startStream: "START STRØMMING",
		startVideo: "Start Video",
		stopLiveSessionStreamDescription:
			"Opptak av sesjonen vil automatisk stoppe når du stopper strømmingen. Opptaket vil være tilgjengelig snart",
		stopLiveSessionStreamTitle: "Stopp Live Sesjon Strømming",
		stopShareScreen: "Stop skjermdeling",
		stopStream: "STOP STRØMMING",
		stopVideo: "Stopp Video",
		thumbnailImage: "Forhåndsbilde",
		uploadThumbnail: "Last opp forhåndsbilde",
		waitingRoom: "Venterom",
	},
	welcomeScreen: {
		goToEvent: "Gå til arrangement",
	},
};

export default no;
