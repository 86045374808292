import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {
    connectToSessionWall,
    disconnectFromSessionWall,
    sessionWallPostReceived,
    sessionWallPostUpdated,
    sessionWallPostDeleted,
    sessionWallCommentReceived,
    sessionWallCommentUpdated,
    sessionWallCommentDeleted,
    sessionWallPostLikeReceived,
    sessionWallPostUnlikeReceived,
    sessionWallPinPost,
} from '../../Api/socketApi';
import connect from 'react-redux/es/connect/connect';
import {withRouter} from 'react-router-dom';
import PostsList from './PostsList';
import Spinner from '../../SmallLayoutComponents/Spinner';
import {Scrollbars} from 'react-custom-scrollbars';
import {preventDefaultDrag} from '../../Utils/utils';
import '../../CSS/wall.scss';
import colors from '../../CSS/_variables.module.scss';
import EmptyWall from '../../Images/svg/EmptyWall';
import * as actions from '../../store/actions';
import axios from "../../store/axios-instance";


class Wall extends React.Component {
    constructor(props) {
        super(props);
        this.wallScrollbar = React.createRef();

        this.state = {
            timestamp: 'no timestamp yet',
            posts: [],
            sortedPosts: [],
            showSortedPosts: false,
            loadingWallPosts: false,
            showingPosts: 20,
        };
    }

    componentDidMount() {
        connectToSessionWall(this.props.liveSession.videoWall)
        this.onGetWallPosts();

        sessionWallPostReceived((err, data) => {
            let updatedPosts = cloneDeep(this.state.posts);
            updatedPosts.push(data.post);
            this.setState({posts: updatedPosts});

            let showingPosts = this.state.showingPosts + 1;
            this.setState({posts: updatedPosts, showingPosts: showingPosts});
            if (this.state.showSortedPosts) {
                this.sortPosts();
            }
        });

        sessionWallPostDeleted((err, data) => {
            let updatedPosts = cloneDeep(this.state.posts);
            updatedPosts = updatedPosts.filter((post) => post._id !== data.postId);
            let showingPosts = this.state.showingPosts - 1;

            this.setState({posts: updatedPosts, showingPosts: showingPosts});
            if (this.state.showSortedPosts) {
                this.sortPosts();
            }
        });

        sessionWallCommentReceived((err, data) => {
            let updatedPosts = cloneDeep(this.state.posts);
            let indexOfCommentedPost = updatedPosts.findIndex((post) => post._id === data.postId);

            if (indexOfCommentedPost !== -1) {
                updatedPosts[indexOfCommentedPost].comments.push(data.comment);
                this.setState({posts: updatedPosts});
                if (this.state.showSortedPosts) {
                    this.sortPosts();
                }
            }
        });

        sessionWallCommentDeleted((err, data) => {
            let updatedPosts = cloneDeep(this.state.posts);
            let indexOfPost = updatedPosts.findIndex((post) => post._id === data.postId);

            if (indexOfPost !== -1) {
                let updatedComments = updatedPosts[indexOfPost].comments.filter(
                    (comment) => comment._id !== data.commentId
                );
                updatedPosts[indexOfPost].comments = updatedComments;
                this.setState({posts: updatedPosts});
                if (this.state.showSortedPosts) {
                    this.sortPosts();
                }
            }
        });

        sessionWallPostLikeReceived((err, data) => {
            let updatedPosts = cloneDeep(this.state.posts);
            let indexOfCommentedPost = updatedPosts.findIndex((post) => post._id === data.postId);
            if (indexOfCommentedPost !== -1) {
                updatedPosts[indexOfCommentedPost].likes.push(data.like);
                this.setState({posts: updatedPosts});
                if (this.state.showSortedPosts) {
                    this.sortPosts();
                }
            }
        });

        sessionWallPostUnlikeReceived((err, data) => {
            let updatedPosts = cloneDeep(this.state.posts);
            let indexOfCommentedPost = updatedPosts.findIndex((post) => post._id === data.postId);
            if (indexOfCommentedPost !== -1) {
                let indexOfUnlikedPost = updatedPosts[indexOfCommentedPost].likes.findIndex(
                    (like) => like.user._id === data.userId
                );
                updatedPosts[indexOfCommentedPost].likes.splice(indexOfUnlikedPost, 1);
                this.setState({
                    posts: updatedPosts,
                });
                if (this.state.showSortedPosts) {
                    this.sortPosts();
                }
            }
        });

        sessionWallPinPost((err, data) => {
            let updatedPosts = cloneDeep(this.state.posts);
            let alreadyPinned = updatedPosts.filter((post) => post.isPinned === true);
            if (alreadyPinned.length > 0) {
                alreadyPinned[0].isPinned = false;
            }
            if (data.postId !== null) {
                let selectedElementIndex = updatedPosts.findIndex((post) => post._id === data.postId);
                let pinnedPost = updatedPosts[selectedElementIndex];
                if (selectedElementIndex !== -1) {
                    pinnedPost.isPinned = true;
                }
            }
            this.setState({
                posts: updatedPosts,
            });
        });


        // get edited post from socket, map through current posts and update the array
        // with the newly edited post
        //
        // added the updatedAt key w/ a random value (doesn't matter, I'm just looking for the key to be there)
        // in order to display the • Edited status right away
        // without needing to re-fetch all posts
        sessionWallPostUpdated((err, data) => {
            let updatedPosts = cloneDeep(this.state.posts);
            let updatedNewPosts = updatedPosts.map((x) =>
                x._id === data.postId ? {...x, text: data.post, updatedAt: 'instantEdited'} : x
            );
            this.setState({posts: updatedNewPosts});
        });

        // get edited comment from socket, extract index of post,
        // map through current posts and update the comments array of the post
        // with the newly edited comment
        //
        // added the updatedAt key w/ a random value (doesn't matter, I'm just looking for the key to be there)
        // in order to display the • Edited status right away
        // without needing to re-fetch all posts
        sessionWallCommentUpdated((err, data) => {
            let updatedPosts = cloneDeep(this.state.posts);
            let indexOfPost = updatedPosts.findIndex((post) => post._id === data.postId);
            if (indexOfPost !== -1) {
                let updatedComments = updatedPosts[indexOfPost].comments.map((x) =>
                    x._id === data.commentId ? {...x, text: data.comment, updatedAt: 'instantEdited'} : x
                );
                updatedPosts[indexOfPost].comments = updatedComments;
                this.setState({posts: updatedPosts});
            }
        });

        this.interval = setInterval(() => this.forceUpdate(), 60000);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.liveSession.videoWall !== this.props.liveSession.videoWall) {
            // get the Wall Posts
            this.onGetWallPosts();
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        const {liveSession} = this.props;
        const wallId = liveSession.videoWall;
        disconnectFromSessionWall(wallId);

        const eventPage = document.getElementsByClassName('event-page hide');
        if (eventPage.length !== 0) {
            eventPage[0].classList.remove('hide');
        }
    }

    onGetWallPosts = () => {
        const {liveSession} = this.props;
        const wallId = liveSession.videoWall
        this.setState({loadingWallPosts: true});
        axios({method: 'get', url: `/sessions/${liveSession._id}/live-wall/${wallId}`}).then((response) => {
            let wallPosts = response.data.wall.posts;
            this.setState({
                posts: wallPosts,
                loadingWallPosts: false,
                showSortedPosts: false,
            });
        });
    };

    sortPosts = () => {
        // we apply slice method so that we can make a copy of the original array without mutating it
        let sortedPosts = [...this.state.posts];
        sortedPosts.sort(function (a, b) {
            let scoreA = a.comments.length + a.likes.length * 2;
            let scoreB = b.comments.length + b.likes.length * 2;
            return scoreA > scoreB ? -1 : 1;
        });
        this.setState({showSortedPosts: true, sortedPosts: sortedPosts});
    };

    handleLoadMorePosts = () => {
        let showingPosts = this.state.showingPosts + 10;
        this.setState({showingPosts: showingPosts});
    };

    handleUpdate = (values) => {
        if (this.state.showingPosts < this.state.posts.length) {
            const {scrollTop, scrollHeight, clientHeight} = values;
            const pad = 150; // 100px of the bottom
            // t will be greater than 1 if we are about to reach the bottom
            const t = (scrollTop + pad) / (scrollHeight - clientHeight);
            if (t > 1) this.handleLoadMorePosts();
        }
    };

    renderView = ({style, ...props}) => {
        let viewStyle = {
            padding: 10,
        };
        if (this.props.isRtlLanguage) {
            viewStyle = {...viewStyle, marginLeft: style.marginRight, marginRight: 0};
        }
        return <div onDragStart={preventDefaultDrag} className="box" style={{...style, ...viewStyle}} {...props} />;
    };

    renderThumb = ({style, ...props}) => {
        const thumbStyle = {
            backgroundColor: `#777081`,
            left: 0,
            border: `1px solid #FFFFFF`,
            boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.25)`,
            borderRadius: `8px`
        };
        return (
            <div
                onDragStart={preventDefaultDrag}
                className="grey-scroll"
                style={{...style, ...thumbStyle}}
                {...props}
            />
        );
    };

    getAllPinnedPosts = () => {
        const {posts} = this.state;
        return posts.filter((post) => post.isPinned);
    };

    getAllUnpinnedPosts = () => {
        const {posts, showSortedPosts, sortedPosts} = this.state;
        let allPosts = showSortedPosts ? sortedPosts : posts.slice(0).reverse();
        let newPostsArray = [...allPosts].slice(0, this.state.showingPosts);
        return newPostsArray.filter((post) => !post.isPinned);
    };


    render() {
        const {posts, showSortedPosts, loadingWallPosts} = this.state;
        const {defaultTranslation} = this.props;

        const pinnedPosts = this.getAllPinnedPosts();
        const unPinnedPosts = this.getAllUnpinnedPosts();

        return (
            <div onDragStart={preventDefaultDrag} className={`wall-container closed-wall`}>
                <div onDragStart={preventDefaultDrag} className="sort-container">
                    <span>{defaultTranslation?.wall.sortBy}</span>
                    <div onDragStart={preventDefaultDrag} className="sorting-buttons">
                        <span
                            onClick={this.sortPosts}
                            className={'button ' + (showSortedPosts ? 'active' : '')}
                        >
                            {defaultTranslation?.wall.popularity}
                        </span>
                        <span
                            onClick={() => this.setState({showSortedPosts: false})}
                            className={'button ' + (!showSortedPosts ? 'active' : '')}
                        >
                            {defaultTranslation?.wall.time}
                        </span>
                    </div>
                </div>
                <div>
                    <div
                        onDragStart={preventDefaultDrag}
                        className={`custom-scroll-container`}
                    >
                        {loadingWallPosts ? (
                            <Spinner />
                        ) : (
                            <>
                                {posts.length > 0 ? (
                                    <Scrollbars
                                        ref={this.wallScrollbar}
                                        className="scrollbar"
                                        renderView={this.renderView}
                                        renderThumbVertical={this.renderThumb}
                                        onUpdate={this.handleUpdate}
                                    >
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className="wall"
                                            data-empty={posts.length === 0 ? 'true' : 'false'}
                                        >
                                            <PostsList
                                                posts={pinnedPosts}
                                            />
                                            <PostsList
                                                posts={unPinnedPosts}
                                            />
                                        </div>
                                    </Scrollbars>
                                ) : (
                                    <div onDragStart={preventDefaultDrag} className="wall" data-empty={'true'}>
                                        <div className="empty-wall">
                                            <EmptyWall strokefill={colors.primary} fillPrimary={colors.primary} />
                                            <p>{defaultTranslation?.wall.emptyWallText}</p>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        defaultTranslation: state.languages.translations['en'],
        liveSession: state.liveSession.liveSession,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveWall: (wallData) => dispatch(actions.setActiveWall(wallData)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Wall));
