import React from 'react';
import {TextValidator} from 'react-material-ui-form-validator';
import {ValidatorComponent} from 'react-material-ui-form-validator';

class TextField extends ValidatorComponent {
    handleChange = (e) => {
        this.props.handleChange(e.target.name, e.target.value);
    };

    renderValidatorComponent() {
        const {field, onBlur, onFocus} = this.props;
        return (
            <TextValidator
                key={field.name}
                label={field.label}
                type={field.type}
                name={field.name}
                value={field.value}
                variant="outlined"
                onChange={this.handleChange}
                validators={field.validators}
                errorMessages={field.errorMessages}
                multiline={field.multiline > 0}
                rows={field.multiline}
                fullWidth={true}
                margin="normal"
                className="field-container"
                onBlur={onBlur}
                onFocus={onFocus}
            />
        );
    }
}

export default TextField;
