import React from "react";
import "./qrdialog.scss";

import {
	Button,
	Dialog,
	DialogAction,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import { ReactComponent as QRIcon } from "../Images/svg/qr_code.svg";
import { ReactComponent as ScanIcon } from "../Images/svg/center_focus_weak.svg";
import { sendMessageToFlutter } from "Utils/utils";

const QRDialog = ({ open, onOpenQR, onClose, onScanQR }) => {
	return (
		<Dialog
			open={open}
			aria-labelledby="form-dialog-title"
			PaperProps={{ classes: { root: "reverse-rtl" } }}
			id="qr-dialog-container"
		>
			<DialogTitle id="qr-dialog-title">
				Neural Network QR Code{" "}
				<IconButton
					aria-label="close"
					style={{ position: "absolute", top: 0, right: 0 }}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="qr-dialog-secondary">
					Select what would you like to do
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					classes={{ label: `dialog-btn-uppercase` }}
					id="qr-show-button"
					type="button"
					onClick={onOpenQR}
				>
					<QRIcon />
					SHOW QR
				</Button>

				<Button
					classes={{ label: `dialog-btn-uppercase` }}
					id="qr-show-button"
					type="button"
					onClick={() => {
						sendMessageToFlutter(localStorage.getItem("token"));
						onScanQR();
					}}
				>
					<ScanIcon />
					SCAN QR
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default QRDialog;
