import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Confirm from '../../../../Dialogs/Confirm';
import { ReactComponent as UploadIcon } from '../../../../Images/svg/upload-file.svg';
import { deleteSessionThumbnail, updateSessionThumbnail } from '../../../../store/actions';
import './thumbnailImage.scss';

const ThumbnailImage = () => {
    const dispatch = useDispatch();
    const [newImageFile, setNewImageFile] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [imageError, setImageError] = useState('');
    const [openConfirmUnsavedChanges, setOpenConfirmUnsavedChanges] = useState(false);

    const session = useSelector((state) => state.sessions.zoomSessionDashboard.session);
    const routeParams = useParams();
    const {sessionId} = routeParams;

    const handleUserClick = (e) => {
        const parentElementHasPreventConfirmSaveChangesPopupClass = e.target.closest('.preventConfirmSaveChangesPopup');
        if (!parentElementHasPreventConfirmSaveChangesPopupClass) {
            if (newImageFile && !imageError) {
                setOpenConfirmUnsavedChanges(true);
            }
        }
    };

    const closeClickOutside = () => {
        setOpenConfirmUnsavedChanges(false);
    };

    useEffect(() => {
        window.addEventListener('mousedown', handleUserClick);
        return () => {
            window.removeEventListener('mousedown', handleUserClick);
        };
    });

    useEffect(() => {
        if (session?.thumbnail) {
            setImagePreviewUrl(`${process.env.REACT_APP_SESSION_FOLDER}${session.thumbnail}`);
            setNewImageFile('');
        } else {
            setImagePreviewUrl('');
            setNewImageFile('');
        }
    }, [session]);

    const handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let isValid = true;
        if (!file) {
            return;
        }

        isValid = file.size < 2 * 1024 * 1024 && isValid;
        if (file) {
            if (!isValid) {
                setImagePreviewUrl(null);
                setImageError('File too large. 2Mb max file size.');
            }
            if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg' && file.type !== 'image/gif') {
                setImagePreviewUrl(null);
                setImageError('File type not supported. Please use one of the following: jpeg, jpg, jfif, gif or png.');
            }
            isValid = (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/gif') && isValid;

            reader.onloadend = () => {
                if (isValid) {
                    setNewImageFile(file);
                    setImagePreviewUrl(reader.result);
                    setImageError('');
                }
            };

            reader.readAsDataURL(file);
        }
        // Reset input otherwise second upload of the SAME IMAGE won't trigger input onChange event
        e.target.value = '';
    };

    const handleSetImage = async () => {
        await dispatch(updateSessionThumbnail(session._id, newImageFile));
        setOpenConfirmUnsavedChanges(false);
    };

    const handleDeleteImage = async () => {
        await dispatch(deleteSessionThumbnail(session._id));
    };

    return (
        <div className="module session-thumbnail-image">
            <div className="module-container">
                <div className="module-header">
                    <span>THUMBNAIL IMAGE</span>
                </div>
                <div className="module-body">
                    <input
                        id={`thumbnailImage${sessionId}`}
                        type="file"
                        className="upload-image-button d-none"
                        onChange={handleImageChange}
                    />
                    <div className="image-container">
                        {!imagePreviewUrl && (
                            <label className="upload-label" htmlFor={`thumbnailImage${sessionId}`}>
                                <UploadIcon />
                                <span>Click here to upload</span>
                            </label>
                        )}
                        {imagePreviewUrl && (
                            <img
                                draggable="false"
                                onDragStart={(e) => e.preventDefault()}
                                src={imagePreviewUrl}
                                alt="preview"
                            />
                        )}
                    </div>

                    <div className="buttons-container">
                        {session?.thumbnail && !newImageFile && (
                            <Button
                                type="button"
                                classes={{label: 'dialog-btn-uppercase'}}
                                onClick={handleDeleteImage}
                                disabled={!session?.thumbnail ? true : false}
                            >
                                <span>Remove image</span>
                            </Button>
                        )}
                        {newImageFile && (
                            <Button
                                type="button"
                                classes={{label: 'dialog-btn-uppercase'}}
                                onClick={handleSetImage}
                                disabled={!imagePreviewUrl || imageError ? true : false}
                            >
                                <span>Save image</span>
                            </Button>
                        )}
                        {imagePreviewUrl && (
                            <Button type="button" classes={{label: 'dialog-btn-uppercase'}}>
                                <label className="upload-label" htmlFor={`thumbnailImage${sessionId}`}>
                                    <span>Edit</span>
                                </label>
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            {openConfirmUnsavedChanges && (
                <Confirm
                    open={openConfirmUnsavedChanges}
                    closeConfirm={closeClickOutside}
                    dialogTitle={'Unsaved changes'}
                    dialogDescription={'You have unsaved changes. Please save them to continue.'}
                    dialogConfirmButtonLabel={'Save'}
                    dialogCancelButtonLabel={'Cancel'}
                    handleConfirm={handleSetImage}
                />
            )}
        </div>
    );
};

export default ThumbnailImage;
