import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import variables from '../../../CSS/_variables.module.scss';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const NetworkFiltersAccordion = withStyles({
    root: {
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
    },
})((props) => <Accordion {...props} />);

export const NetworkFiltersAccordionSummary = withStyles({
    root: {
        padding: '0px 0px 0px 12px',
        '& .MuiAccordionSummary-root': {
            '&:focus': {
                background: variables.primary,
                boxShadow: `0px 4px 10px ${variables.secondary}`,
            },
        },
        '& .Mui-focusVisible': {
            background: variables.primary,
            boxShadow: `0px 4px 10px ${variables.secondary}`,
            '&:active': {
                background: `linear-gradient(137.73deg, ${variables.gradientMenu1} -15.66%, ${variables.gradientMenu2} 43.77%, ${variables.gradientMenu3} 100.28%)`,
                boxShadow: 'none',
            },
            '&:focus': {
                background: variables.primary,
                boxShadow: `0px 4px 10px ${variables.secondary}`,
            },
        },
    },
})((props) => <AccordionSummary {...props} />);

export const NetworkFiltersAccordionDetails = withStyles({
    root: {
        padding: '0px 12px 0px 12px',
    },
})((props) => <AccordionDetails {...props} />);

const networkFiltersSharedButtonStyles = {
    padding: '12px 16px',
    fontWeight: 600,
    fontFamily: `${variables.fontFamily}, sans-serif`,
    fontSize: '14px',
    lineHeight: '16px',
    borderRadius: '6px',
    boxShadow: 'none',
    textTransform: 'uppercase',
    '& .MuiButton-label': {
        fontWeight: 600,
    },
};

// NOTE: do we need to make this button generic and shared?
export const NetworkFilterApplyButton = withStyles({
    root: {
        transition: 'none',
        '&:disabled': {
            backgroundColor: variables.greyVariant,
            color: variables.white,
        },
        '&:hover': {
            background: `linear-gradient(137.73deg, ${variables.gradientMenu1} -15.66%, ${variables.gradientMenu2} 43.77%, ${variables.gradientMenu3} 100.28%)`,
            boxShadow: 'none',
        },
        '&:active': {
            background: `linear-gradient(137.73deg, ${variables.gradientMenu1} -15.66%, ${variables.gradientMenu2} 43.77%, ${variables.gradientMenu3} 100.28%)`,
            boxShadow: 'none',
        },
        '&:focus': {
            background: variables.primary,
            boxShadow: `0px 4px 10px ${variables.secondary}`,
        },

        ...networkFiltersSharedButtonStyles,
    },
})((props) => <Button {...props} />);
export const NetworkFilterResetButton = withStyles({
    root: {
        '&:disabled': {
            color: variables.greyVariant,
        },
        '&:hover': {
            backgroundColor: variables.disabledText,
            boxShadow: 'none',
        },
        '&:focus': {
            background: variables.white,
            boxShadow: `0px 4px 10px ${variables.secondary}`,
        },
        ...networkFiltersSharedButtonStyles,
    },
})((props) => <Button {...props} />);
export const NetworkFilterCloseButton = withStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        ...networkFiltersSharedButtonStyles,
        color: variables.dark,
        '& svg': {
            marginRight: '8px',
        },
        '&:active': {
            background: `linear-gradient(137.73deg, ${variables.gradientMenu1} -15.66%, ${variables.gradientMenu2} 43.77%, ${variables.gradientMenu3} 100.28%)`,
            boxShadow: 'none',
        },
        '&:focus': {
            background: variables.white,
            boxShadow: `0px 4px 10px ${variables.secondary}`,
        },
        padding: '12px 8px 12px 8px',
    },
})((props) => <Button {...props} />);

export const NetworkFilterTooltip = withStyles({
    tooltip: {
        border: `1px solid ${variables.lightGrey}`,
        borderRadius: '12px',
        fontFamily: `${variables.fontFamily}, sans-serif`,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        top: '14px',
        left: ' -2px',
        lineHeight: '20px',
        color: variables.dark,
        padding: '15px 12px 15px 48px',
    },
})((props) => <Tooltip {...props} />);
export const NetworkFilterFormControlLabel = withStyles({
    root: {
        color: variables.dark,
        '& .MuiTypography-body1': {
            fontFamily: `${variables.fontFamily}, sans-serif`,
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '-0.04em',
            textAlign: 'left',
        },
    },
})((props) => <FormControlLabel {...props} />);
