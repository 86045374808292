export const languagesArray = [
    {label: 'Arabic', value: 'ar'},
    {label: 'Chinese', value: 'zh'},
    {label: 'English', value: 'en'},
    {label: 'French', value: 'fr'},
    {label: 'German', value: 'de'},
    {label: 'Norwegian', value: 'no'},
    {label: 'Portuguese', value: 'pt'},
    {label: 'Russian', value: 'ru'},
    {label: 'Spanish', value: 'es'},
    {label: 'Turkish', value: 'tr'},
];
