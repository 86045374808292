import * as actionTypes from '../../../actions/actionTypes';
import {initialState} from '../initialState';
import {updateObject} from '../../../utilityStateFunction';

export default (state, action) => {
    if (state === undefined) {
        return initialState.sessionsList;
    }

    switch (action.type) {
        case actionTypes.GET_SESSIONS_START:
        case actionTypes.CREATE_SESSIONS_START:
        case actionTypes.UPDATE_SESSIONS_START:
        case actionTypes.DELETE_SESSIONS_START:
            return updateObject(state.sessionsList, {
                isLoading: true,
            });
        case actionTypes.GET_SESSIONS_SUCCESS:
            return updateObject(state.sessionsList, {
                sessions: action.sessions,
                isLoading: false,
            });
        case actionTypes.GET_SESSIONS_FAIL:
        case actionTypes.CREATE_SESSIONS_SUCCESS:
        case actionTypes.CREATE_SESSIONS_FAIL:
        case actionTypes.UPDATE_SESSIONS_SUCCESS:
        case actionTypes.UPDATE_SESSIONS_FAIL:
        case actionTypes.DELETE_SESSIONS_SUCCESS:
        case actionTypes.DELETE_SESSIONS_FAIL:
            return updateObject(state.sessionsList, {
                isLoading: false,
            });
        default:
            return state.sessionsList;
    }
};
