import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {ValidatorComponent} from 'react-material-ui-form-validator';
import {TextValidator} from 'react-material-ui-form-validator';

class RadioField extends ValidatorComponent {
    handleChange = (e) => {
        this.props.handleChange(e.target.name, e.target.value);
    };

    renderValidatorComponent() {
        const {field, withLabel} = this.props;
        return (
            <>
                <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">{field.label}</FormLabel>
                    <RadioGroup
                        aria-label={field.label}
                        name={field.name}
                        value={field.value}
                        onChange={this.handleChange}
                        className="registration-radio-group"
                    >
                        {field.options.map((option) => {
                            if (withLabel) {
                                return <FormControlLabel
                                    value={option.value}
                                    control={<Radio />}
                                    label={option.label}
                                    key={option.value} />;
                            }
                            return <FormControlLabel
                                value={option}
                                control={<Radio />}
                                label={option}
                                key={option} />;
                        })}
                    </RadioGroup>
                </FormControl>
                <TextValidator
                    key={field.name}
                    label={field.label}
                    type="text"
                    name={field.name}
                    value={field.value}
                    variant="outlined"
                    onChange={this.handleChange}
                    validators={field.validators}
                    errorMessages={field.errorMessages}
                    multiline={field.multiline > 0}
                    rows={field.multiline}
                    fullWidth={true}
                    margin="normal"
                    className="field-container hidden"
                />
            </>
        );
    }
}

export default RadioField;
