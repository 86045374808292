import * as actionTypes from './actionTypes';
import axios from '../axios-instance';
import {updateSingleArchivedEventDetails} from './eventArchiveActions';
import {eventSettingUpdated, updateSingleAuditorium} from './eventActions';
// import {getOnlineUsers} from './eventUsersActions';

export const addSpeaker = (eventId, participantId) => {
    return (dispatch) => {
        dispatch(addSpeakerStart());

        axios({method: 'post', url: `/event/${eventId}/add-speaker`, data: {participantId: participantId}})
            .then((response) => {
                dispatch(addSpeakerSuccess(participantId));
            })
            .catch((error) => {
                dispatch(addSpeakerFail(error));
            });
    };
};

export const addSpeakerStart = () => {
    return {
        type: actionTypes.ADD_SPEAKER_START,
    };
};
export const addSpeakerSuccess = (speaker) => {
    return {
        type: actionTypes.ADD_SPEAKER_SUCCESS,
        payload: speaker,
    };
};
export const addSpeakerFail = () => {
    return {
        type: actionTypes.ADD_SPEAKER_FAIL,
    };
};

export const removeSpeaker = (eventId, participantId) => {
    return (dispatch) => {
        dispatch(removeSpeakerStart());

        axios({method: 'delete', url: `/event/${eventId}/remove-speaker`, data: {participantId: participantId}})
            .then((response) => {
                dispatch(removeSpeakerSuccess(participantId));
            })
            .catch((error) => {
                dispatch(removeSpeakerFail(error));
            });
    };
};

export const removeSpeakerStart = () => {
    return {
        type: actionTypes.REMOVE_SPEAKER_START,
    };
};
export const removeSpeakerSuccess = (speaker) => {
    return {
        type: actionTypes.REMOVE_SPEAKER_SUCCESS,
        payload: speaker,
    };
};
export const removeSpeakerFail = () => {
    return {
        type: actionTypes.REMOVE_SPEAKER_FAIL,
    };
};

export const addTimeSlot = (auditoriumId, timeSlot) => {
    return (dispatch) => {
        dispatch(addTimeSlotStart());

        return axios({method: 'post', url: '/auditoriums/' + auditoriumId + '/program', data: timeSlot})
            .then((response) => {
                let data = response.data;
                dispatch(addTimeSlotSuccess(data));
            })
            .catch((error) => {
                dispatch(addTimeSlotFail(error));
            });
    };
};

export const addTimeSlotStart = () => {
    return {
        type: actionTypes.ADD_TIME_SLOT_START,
    };
};
export const addTimeSlotSuccess = (timeSlotData) => {
    return {
        type: actionTypes.ADD_TIME_SLOT_SUCCESS,
        payload: timeSlotData,
    };
};
export const addTimeSlotFail = () => {
    return {
        type: actionTypes.ADD_TIME_SLOT_FAIL,
    };
};

export const addAccessPackage = (eventId, accessPackage) => {
    return (dispatch) => {
        dispatch(addAccessPackageStart());

        return axios({method: 'post', url: `/event/${eventId}/access-packages`, data: accessPackage})
            .then((response) => {
                let data = response.data.accessPackage;
                dispatch(addAccessPackageSuccess(data));
            })
            .catch((error) => {
                dispatch(addAccessPackageFail(error));
            });
    };
};

export const addAccessPackageStart = () => {
    return {
        type: actionTypes.ADD_ACCES_PACKAGE_START,
    };
};
export const addAccessPackageSuccess = (accessPackage) => {
    return {
        type: actionTypes.ADD_ACCES_PACKAGE_SUCCESS,
        payload: accessPackage,
    };
};
export const addAccessPackageFail = () => {
    return {
        type: actionTypes.ADD_ACCES_PACKAGE_FAIL,
    };
};

export const addExhibitorAccessPackage = (eventId, accessPackage) => {
    return (dispatch) => {
        dispatch(addExhibitorAccessPackageStart());

        return axios({method: 'post', url: `/event/${eventId}/exhibitor-access-packages`, data: accessPackage})
            .then((response) => {
                let data = response.data.exhibitorAccessPackages;
                dispatch(addExhibitorAccessPackageSuccess(data));
            })
            .catch((error) => {
                dispatch(addExhibitorAccessPackageFail(error));
            });
    };
};

export const addExhibitorAccessPackageStart = () => {
    return {
        type: actionTypes.ADD_EXHIBITOR_ACCES_PACKAGE_START,
    };
};
export const addExhibitorAccessPackageSuccess = (accessPackage) => {
    return {
        type: actionTypes.ADD_EXHIBITOR_ACCES_PACKAGE_SUCCESS,
        payload: accessPackage,
    };
};
export const addExhibitorAccessPackageFail = () => {
    return {
        type: actionTypes.ADD_EXHIBITOR_ACCES_PACKAGE_FAIL,
    };
};

export const updateAccessPackage = (eventId, packageId, accessPackage) => {
    return (dispatch) => {
        dispatch(updateAccessPackageStart());
        return axios({method: 'put', url: `/event/${eventId}/access-packages/${packageId}`, data: accessPackage})
            .then((response) => {
                let data = response.data.accessPackage;
                dispatch(updateAccessPackageSuccess(data));
                // dispatch( getEvent(eventId) );
            })
            .catch((error) => {
                dispatch(updateAccessPackageFail(error));
            });
    };
};

export const updateAccessPackageStart = () => {
    return {
        type: actionTypes.UPDATE_ACCES_PACKAGE_START,
    };
};
export const updateAccessPackageSuccess = (accessPackage) => {
    return {
        type: actionTypes.UPDATE_ACCES_PACKAGE_SUCCESS,
        payload: accessPackage,
    };
};
export const updateAccessPackageFail = () => {
    return {
        type: actionTypes.UPDATE_ACCES_PACKAGE_FAIL,
    };
};

export const updateExhibitorAccessPackage = (eventId, packageId, accessPackage) => {
    return (dispatch) => {
        dispatch(updateExhibitorAccessPackageStart());
        return axios({
            method: 'put',
            url: `/event/${eventId}/exhibitor-access-packages/${packageId}`,
            data: accessPackage,
        })
            .then((response) => {
                let data = response.data.exhibitorAccessPackage;
                dispatch(updateExhibitorAccessPackageSuccess(data));
                // dispatch( getEvent(eventId) );
            })
            .catch((error) => {
                dispatch(updateExhibitorAccessPackageFail(error));
            });
    };
};

export const updateExhibitorAccessPackageStart = () => {
    return {
        type: actionTypes.UPDATE_EXHIBITOR_ACCES_PACKAGE_START,
    };
};
export const updateExhibitorAccessPackageSuccess = (accessPackage) => {
    return {
        type: actionTypes.UPDATE_EXHIBITOR_ACCES_PACKAGE_SUCCESS,
        payload: accessPackage,
    };
};
export const updateExhibitorAccessPackageFail = () => {
    return {
        type: actionTypes.UPDATE_EXHIBITOR_ACCES_PACKAGE_FAIL,
    };
};

export const deleteAccessPackage = (eventId, packageId) => {
    return (dispatch) => {
        dispatch(deleteAccessPackageStart());

        return axios({method: 'delete', url: `/event/${eventId}/access-packages/${packageId}`})
            .then((response) => {
                dispatch(deleteAccessPackageSuccess(packageId));
            })
            .catch((error) => {
                dispatch(deleteAccessPackageFail(error));
            });
    };
};

export const deleteAccessPackageStart = () => {
    return {
        type: actionTypes.DELETE_ACCES_PACKAGE_START,
    };
};
export const deleteAccessPackageSuccess = (packageId) => {
    return {
        type: actionTypes.DELETE_ACCES_PACKAGE_SUCCESS,
        payload: packageId,
    };
};
export const deleteAccessPackageFail = () => {
    return {
        type: actionTypes.DELETE_ACCES_PACKAGE_FAIL,
    };
};

export const deleteExhibitorAccessPackage = (eventId, packageId) => {
    return (dispatch) => {
        dispatch(deleteExhibitorAccessPackageStart());

        return axios({method: 'delete', url: `/event/${eventId}/exhibitor-access-packages/${packageId}`})
            .then((response) => {
                dispatch(deleteExhibitorAccessPackageSuccess(packageId));
            })
            .catch((error) => {
                dispatch(deleteExhibitorAccessPackageFail(error));
            });
    };
};

export const deleteExhibitorAccessPackageStart = () => {
    return {
        type: actionTypes.DELETE_EXHIBITOR_ACCES_PACKAGE_START,
    };
};
export const deleteExhibitorAccessPackageSuccess = (packageId) => {
    return {
        type: actionTypes.DELETE_EXHIBITOR_ACCES_PACKAGE_SUCCESS,
        payload: packageId,
    };
};
export const deleteExhibitorAccessPackageFail = () => {
    return {
        type: actionTypes.DELETE_EXHIBITOR_ACCES_PACKAGE_FAIL,
    };
};

export const setOrganizerBrandingLanguage = (language) => {
    return {
        type: actionTypes.SET_ORGANIZER_BRANDING_LANGUAGE,
        payload: language,
    };
};

export const setEditedBuilding = (building) => {
    return {
        type: actionTypes.SET_EDITED_BUILDING,
        payload: building,
    };
};

export const setEditedBuildingId = (buildingId) => {
    return {
        type: actionTypes.SET_EDITED_BUILDING_ID,
        payload: buildingId,
    };
};

export const setEditedBuildingTab = (buildingTab) => {
    return {
        type: actionTypes.SET_EDITED_BUILDING_TAB,
        payload: buildingTab,
    };
};

export const updateArchivedEventDetails = (eventId, archivedEventId, data) => {
    return (dispatch) => {
        return axios({
            method: 'put',
            url: `/event/${eventId}/archived-events/${archivedEventId}`,
            data: data,
        })
            .then((response) => {
                const updatedArchivedEvent = response.data.archivedEvent;
                dispatch(updateSingleArchivedEventDetails(updatedArchivedEvent));
            })
            .catch((error) => {});
    };
};

export const updateArchivedEventTimeslot = (eventId, archivedEventId, timeslotId, data) => {
    return (dispatch) => {
        return axios({
            method: 'put',
            url: `/event/${eventId}/archived-events/${archivedEventId}/videos/${timeslotId}`,
            data: data,
        })
            .then((response) => {
                const updatedArchivedEvent = response.data.archivedEvent;
                dispatch(updateSingleArchivedEventDetails(updatedArchivedEvent));
            })
            .catch((error) => {});
    };
};

export const addArchivedEventTimeslotDocument = (eventId, archivedEventId, timeslotId, data) => {
    return (dispatch) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios({
            method: 'post',
            url: `/event/${eventId}/archived-events/${archivedEventId}/videos/${timeslotId}/document`,
            data: data,
            config,
        })
            .then((response) => {
                const updatedArchivedEvent = response.data.archivedEvent;
                dispatch(updateSingleArchivedEventDetails(updatedArchivedEvent));
            })
            .catch((error) => {});
    };
};

export const removeArchivedEventTimeslotDocument = (eventId, archivedEventId, timeslotId, documentId) => {
    return (dispatch) => {
        let data = {};
        data.documentId = documentId;
        return axios({
            method: 'post',
            url: `/event/${eventId}/archived-events/${archivedEventId}/videos/${timeslotId}/remove-document/${documentId}`,
            data: data,
        })
            .then((response) => {
                const updatedArchivedEvent = response.data.archivedEvent;
                dispatch(updateSingleArchivedEventDetails(updatedArchivedEvent));
            })
            .catch((error) => {});
    };
};

export const updateArchivedEventTimeslotLinks = (eventId, archivedEventId, timeslotId, data) => {
    return (dispatch) => {
        return axios({
            method: 'post',
            url: `/event/${eventId}/archived-events/${archivedEventId}/videos/${timeslotId}/links`,
            data: data,
        })
            .then((response) => {
                const updatedArchivedEvent = response.data.archivedEvent;
                dispatch(updateSingleArchivedEventDetails(updatedArchivedEvent));
            })
            .catch((error) => {});
    };
};

export const updateAuditoriumDetails = (eventId, auditoriumId, data) => {
    return (dispatch) => {
        return axios({
            method: 'put',
            url: `/event/${eventId}/auditoriums/${auditoriumId}`,
            data: data,
        })
            .then((response) => {
                const updatedAuditorium = response.data.auditorium;
                dispatch(updateSingleAuditorium(updatedAuditorium));
            })
            .catch((error) => {});
    };
};

export const updateAuditoriumImage = (eventId, auditoriumId, data) => {
    return (dispatch) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios({
            method: 'post',
            url: `/event/${eventId}/auditoriums/${auditoriumId}/image`,
            data: data,
            config,
        })
            .then((response) => {
                const updatedAuditorium = response.data.auditorium;
                dispatch(updateSingleAuditorium(updatedAuditorium));
            })
            .catch((error) => {});
    };
};

export const removeAuditoriumImage = (eventId, auditoriumId, data) => {
    return (dispatch) => {
        return axios({
            method: 'delete',
            url: `/event/${eventId}/auditoriums/${auditoriumId}/image`,
        })
            .then((response) => {
                const updatedAuditorium = response.data.auditorium;
                dispatch(updateSingleAuditorium(updatedAuditorium));
            })
            .catch((error) => {});
    };
};

export const updateAuditoriumTimeslot = (eventId, auditoriumId, timeslotId, data) => {
    return (dispatch) => {
        return axios({
            method: 'put',
            url: `/event/${eventId}/auditoriums/${auditoriumId}/program/${timeslotId}`,
            data: data,
        })
            .then((response) => {
                const updatedAuditorium = response.data.auditorium;
                dispatch(updateSingleAuditorium(updatedAuditorium));
            })
            .catch((error) => {});
    };
};

export const addAuditoriumTimeslotDocument = (eventId, auditoriumId, timeslotId, data) => {
    return (dispatch) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios({
            method: 'post',
            url: `/event/${eventId}/auditoriums/${auditoriumId}/program/${timeslotId}/document`,
            data: data,
            config,
        })
            .then((response) => {
                const updatedAuditorium = response.data.auditorium;
                dispatch(updateSingleAuditorium(updatedAuditorium));
            })
            .catch((error) => {});
    };
};

export const removeAuditoriumTimeslotDocument = (eventId, auditoriumId, timeslotId, documentId, data) => {
    return (dispatch) => {
        return axios({
            method: 'delete',
            url: `/event/${eventId}/auditoriums/${auditoriumId}/program/${timeslotId}/document/${documentId}`,
            data: data,
        })
            .then((response) => {
                const updatedAuditorium = response.data.auditorium;
                dispatch(updateSingleAuditorium(updatedAuditorium));
            })
            .catch((error) => {});
    };
};

export const updateAuditoriumTimeslotImage = (eventId, auditoriumId, timeslotId, data) => {
    return (dispatch) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios({
            method: 'post',
            url: `/event/${eventId}/auditoriums/${auditoriumId}/program/${timeslotId}/image`,
            data: data,
            config,
        })
            .then((response) => {
                const updatedAuditorium = response.data.auditorium;
                dispatch(updateSingleAuditorium(updatedAuditorium));
            })
            .catch((error) => {});
    };
};

export const removeAuditoriumTimeslotImage = (eventId, auditoriumId, timeslotId, data) => {
    return (dispatch) => {
        return axios({
            method: 'delete',
            url: `/event/${eventId}/auditoriums/${auditoriumId}/program/${timeslotId}/image`,
            data: data,
        })
            .then((response) => {
                const updatedAuditorium = response.data.auditorium;
                dispatch(updateSingleAuditorium(updatedAuditorium));
            })
            .catch((error) => {});
    };
};

export const updateAuditoriumTimeslotLinks = (eventId, auditoriumId, timeslotId, data) => {
    return (dispatch) => {
        return axios({
            method: 'post',
            url: `/event/${eventId}/auditoriums/${auditoriumId}/program/${timeslotId}/links`,
            data: data,
        })
            .then((response) => {
                const updatedAuditorium = response.data.auditorium;
                dispatch(updateSingleAuditorium(updatedAuditorium));
            })
            .catch((error) => {});
    };
};

export const updateAuditoriumTimeslotSpeakers = (eventId, auditoriumId, timeslotId, data) => {
    return (dispatch) => {
        return axios({
            method: 'post',
            url: `/event/${eventId}/auditoriums/${auditoriumId}/program/${timeslotId}/links`,
            data: data,
        })
            .then((response) => {
                const updatedAuditorium = response.data.auditorium;
                dispatch(updateSingleAuditorium(updatedAuditorium));
            })
            .catch((error) => {});
    };
};

export const acceptCoOrganizerTerms = (eventId) => {
    return (dispatch) => {
        return axios({
            method: 'post',
            url: `/event/${eventId}/accept-co-organizer-terms`,
        });
    };
};

export const addCoOrganizer = (eventId, userId) => {
    return (dispatch) => {
        return axios({
            method: 'post',
            url: `/event/${eventId}/access-management/${userId}/co-organizer`,
            data: {eventId, userId},
        });
    };
};

export const deleteCoOrganizer = (eventId, userId) => {
    return (dispatch) => {
        return axios({
            method: 'delete',
            url: `/event/${eventId}/access-management/${userId}/co-organizer`,
            data: {eventId, userId},
        });
    };
};

export const changeAccessRights = (eventId, userId, data) => {
    return (dispatch) => {
        return axios({
            method: 'put',
            url: `/event/${eventId}/access-management/${userId}/access-rights`,
            data,
        });
    };
};

export const manageMatching = (eventId) => {
    return (dispatch) => {
        return axios({
            method: 'post',
            url: `/event/${eventId}/manage-matching`,
        });
    };
};

export const getQuestionnairesStart = () => {
    return {
        type: actionTypes.GET_QUESTIONNAIRES_START,
    };
};

export const getQuestionnairesSuccess = (data) => {
    return {
        type: actionTypes.GET_QUESTIONNAIRES_SUCCESS,
        payload: data,
    };
};

export const getQuestionnairesFail = (err) => {
    return {
        type: actionTypes.GET_QUESTIONNAIRES_FAIL,
        payload: err,
    };
};

export const getQuestionnaires = (eventId) => {
    return (dispatch) => {
        dispatch(getQuestionnairesStart());
        return axios({
            method: 'get',
            url: `/event/v2/${eventId}/questionnaires`,
        })
            .then((response) => dispatch(getQuestionnairesSuccess(response.data.questionnaire)))
            .catch((error) => {
                let errorMessage = 'Error. Please try again.';
                if (error.response) {
                    errorMessage = error.response.data.error;
                }
                dispatch(getQuestionnairesFail({status: error.response.status, message: errorMessage}));
            });
    };
};

export const createQuestionnaireStart = () => {
    return {
        type: actionTypes.CREATE_QUESTIONNAIRES_START,
    };
};

export const createQuestionnaireSuccess = (data) => {
    return {
        type: actionTypes.CREATE_QUESTIONNAIRES_SUCCESS,
        payload: data,
    };
};

export const createQuestionnaireFail = (err) => {
    return {
        type: actionTypes.CREATE_QUESTIONNAIRES_FAIL,
        payload: err,
    };
};

export const createQuestionnaire = (eventId, data) => {
    return (dispatch) => {
        dispatch(createQuestionnaireStart());
        return axios({
            method: 'post',
            url: `/event/v2/${eventId}/create-questionnaire`,
            data,
        })
            .then((response) => dispatch(createQuestionnaireSuccess(response.data.questionnaire)))
            .catch((error) => {
                let errorMessage = 'Error. Please try again.';
                if (error.response) {
                    errorMessage = error.response.data.error;
                }
                dispatch(createQuestionnaireFail({status: error.response.status, message: errorMessage}));
            });
    };
};

export const updateQuestionnaireStart = () => {
    return {
        type: actionTypes.UPDATE_QUESTIONNAIRES_START,
    };
};

export const updateQuestionnaireSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_QUESTIONNAIRES_SUCCESS,
        payload: data,
    };
};

export const updateQuestionnaireFail = (err) => {
    return {
        type: actionTypes.UPDATE_QUESTIONNAIRES_FAIL,
        payload: err,
    };
};

export const updateQuestionnaire = (eventId, questionnaireId, data) => {
    return (dispatch) => {
        dispatch(updateQuestionnaireStart());
        return axios({
            method: 'put',
            url: `/event/v2/${eventId}/questionnaires/${questionnaireId}`,
            data,
        })
            .then((response) => dispatch(updateQuestionnaireSuccess(response.data.questionnaire)))
            .catch((error) => {
                let errorMessage = 'Error. Please try again.';
                if (error.response) {
                    errorMessage = error.response.data.error;
                }
                dispatch(updateQuestionnaireFail({status: error.response.status, message: errorMessage}));
            });
    };
};

export const deleteQuestionnaireStart = () => {
    return {
        type: actionTypes.DELETE_QUESTIONNAIRES_START,
    };
};

export const deleteQuestionnaireSuccess = (data) => {
    return {
        type: actionTypes.DELETE_QUESTIONNAIRES_SUCCESS,
        payload: data,
    };
};

export const deleteQuestionnaireFail = (err) => {
    return {
        type: actionTypes.DELETE_QUESTIONNAIRES_FAIL,
        payload: err,
    };
};

export const deleteQuestionnaire = (eventId, questionnaireId) => {
    return (dispatch) => {
        dispatch(deleteQuestionnaireStart());
        return axios({
            method: 'delete',
            url: `/event/v2/${eventId}/questionnaires/${questionnaireId}`,
        })
            .then(() => dispatch(deleteQuestionnaireSuccess(questionnaireId)))
            .catch((error) => {
                let errorMessage = 'Error. Please try again.';
                if (error.response) {
                    errorMessage = error.response.data.error;
                }
                dispatch(deleteQuestionnaireFail({status: error.response.status, message: errorMessage}));
            });
    };
};

export const addQuestionStart = () => {
    return {
        type: actionTypes.ADD_QUESTION_START,
    };
};

export const addQuestionSuccess = (data) => {
    return {
        type: actionTypes.ADD_QUESTION_SUCCESS,
        payload: data,
    };
};

export const addQuestionFail = (err) => {
    return {
        type: actionTypes.ADD_QUESTION_FAIL,
        payload: err,
    };
};

export const addQuestion = (eventId, questionnaireId, data) => {
    return (dispatch) => {
        dispatch(addQuestionStart());
        return axios({
            method: 'post',
            url: `/event/v2/${eventId}/questionnaires/${questionnaireId}/add-question`,
            data,
        })
            .then((response) => dispatch(addQuestionSuccess(response.data.question)))
            .catch((error) => {
                let errorMessage = 'Error. Please try again.';
                if (error.response) {
                    errorMessage = error.response.data.error;
                }
                dispatch(addQuestionFail({status: error.response.status, message: errorMessage}));
            });
    };
};

export const updateQuestionStart = () => {
    return {
        type: actionTypes.UPDATE_QUESTION_START,
    };
};

export const updateQuestionSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_QUESTION_SUCCESS,
        payload: data,
    };
};

export const updateQuestionFail = (err) => {
    return {
        type: actionTypes.UPDATE_QUESTION_FAIL,
        payload: err,
    };
};

export const updateQuestion = (eventId, questionnaireId, questionIndex, questionId, data) => {
    return (dispatch) => {
        dispatch(updateQuestionStart());
        return axios({
            method: 'put',
            url: `/event/v2/${eventId}/questionnaires/${questionnaireId}/question/${questionId}`,
            data,
        })
            .then((response) => dispatch(updateQuestionSuccess({questionIndex, question: response.data.question})))
            .catch((error) => {
                let errorMessage = 'Error. Please try again.';
                if (error.response) {
                    errorMessage = error.response.data.error;
                }
                dispatch(updateQuestionFail({status: error.response.status, message: errorMessage}));
            });
    };
};

export const deleteQuestionStart = () => {
    return {
        type: actionTypes.DELETE_QUESTION_START,
    };
};

export const deleteQuestionSuccess = (data) => {
    return {
        type: actionTypes.DELETE_QUESTION_SUCCESS,
        payload: data,
    };
};

export const deleteQuestionFail = (err) => {
    return {
        type: actionTypes.DELETE_QUESTION_FAIL,
        payload: err,
    };
};

export const deleteQuestion = (eventId, questionnaireId, questionId) => {
    return (dispatch) => {
        dispatch(deleteQuestionStart());
        return axios({
            method: 'delete',
            url: `/event/v2/${eventId}/questionnaires/${questionnaireId}/question/${questionId}`,
        })
            .then(() => dispatch(deleteQuestionSuccess(questionId)))
            .catch((error) => {
                let errorMessage = 'Error. Please try again.';
                if (error.response) {
                    errorMessage = error.response.data.error;
                }
                dispatch(deleteQuestionFail({status: error.response.status, message: errorMessage}));
            });
    };
};

export const updateQuestionsOrder = (eventId, questionnaireId, data) => {
    return (dispatch) => {
        return axios({
            method: 'post',
            url: `/event/v2/${eventId}/questionnaires/${questionnaireId}/update-question-order`,
            data,
        })
            .then(() => {})
            .catch(() => {});
    };
};

export const changeEventOptions = (eventId, data) => {
    return (dispatch) => {
        return axios({method: 'post', url: `/event/${eventId}`, data});
    };
};

export const toggleEnableWelcomeScreen = (eventId, hasWelcomeScreen) => {
    return (dispatch) => {
        return axios({method: 'post', url: `/event/${eventId}`, data: {hasWelcomeScreen: hasWelcomeScreen}})
            .then(() => {
                // we update the event data automatically because we receive through socket a
                // notification type: "reloadEventData"
                // we still need to refresh the eventData so that the success response is visible immediately to the organizer/coorganizer
                dispatch(
                    eventSettingUpdated({
                        name: 'hasWelcomeScreen',
                        value: hasWelcomeScreen,
                    })
                );
            })
            .catch(() => {});
    };
};
