import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import MatchingParticipantQuestion from "./MatchingParticipantQuestion";
import NewUserCard from "./NewUserCard";
import MatchingQuestionsCounter from "./MatchingQuestionsCounter";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import colors from "../../../CSS/_variables.module.scss";
import { Close } from "@material-ui/icons";
import { ReactComponent as EmptyResults } from "../../../Images/svg/search_results.svg";
import { ReactComponent as StartChatArrow } from "../../../Images/svg/dir-curve2.svg";
import { isMobileSafari } from "react-device-detect";
import { ReactComponent as MatchingLoader } from "../../../Images/svg/matching-loader.svg";
import { cloneDeep } from "lodash";
import { sortABC, sortByIsFeatured, sortFn } from "../../../Utils/utils";

class MatchingDialog extends React.Component {
	state = {
		answers: [],
		showFinished: false,
		answered: false,
		startedAnswering: false,
		currentIndex: 0,
	};

	componentDidMount() {
		this.setState({ startedAnswering: false });
	}

	handleStartAnswering = () => {
		const { eventMatching } = this.props;
		this.setState({
			startedAnswering: true,
			// if there's only 1 question show Finish button
			showFinished: eventMatching?.questions?.length === 1,
		});
	};

	handleSubmitAnswers = () => {
		const {
			eventId,
			user,
			eventMatching,
			onSaveUpdateUserAnswers,
			onSetMatchingFormSeen,
			onGetUserEventProfile,
			closeBannerOnFinish,
		} = this.props;
		this.props.onGetUsers(eventId);
		const { answers } = this.state;
		let answersClone = JSON.parse(JSON.stringify(answers));
		let data = {};
		// filters out empty answers (questions that have empty answerIds arrays)
		data.answers = answersClone.filter(
			(answer) => answer.answerIds.length !== 0,
		);
		onSaveUpdateUserAnswers(eventId, eventMatching?._id, data)
			.then(() =>
				onSetMatchingFormSeen(eventId).then(() => {
					onGetUserEventProfile(eventId, user._id);
					closeBannerOnFinish();
					this.handleHideMatchingTooltip();
				}),
			)
			.catch(() => {});
	};

	handleHideMatchingTooltip = () => {
		const { eventSlug, user } = this.props;
		let dataToStorage = [];
		const setData = {
			event: eventSlug,
			userId: user?._id,
			tooltipDisplayed: true,
		};
		const matchingTooltipData = localStorage.getItem("matchingTooltipData");
		if (
			matchingTooltipData !== null &&
			JSON.parse(matchingTooltipData).length > 0
		) {
			const matchingTooltipDataArray = JSON.parse(matchingTooltipData);
			let spreadArray = matchingTooltipDataArray;
			spreadArray.push(setData);
			dataToStorage = spreadArray;
		} else {
			dataToStorage.push(setData);
		}
		localStorage.setItem("matchingTooltipData", JSON.stringify(dataToStorage));
	};

	onNextClicked = (questionId) => () => {
		const { eventMatching } = this.props;
		const { answers, currentIndex } = this.state;
		let updatedAnswers = [...answers];
		// handle keep answers object in state
		let questionIndex = updatedAnswers.findIndex(
			(answer) => answer.questionId === questionId,
		);
		let qIdx = eventMatching?.questions?.findIndex(
			(question) => question._id === questionId,
		);
		let data = {};
		data.questionId = questionId;
		data.answerIds = [];
		// add question with empty answers to state if skipped
		// and doesn't already exist
		if (questionIndex < 0) updatedAnswers.splice(qIdx, 0, data);
		this.setState({
			// if there's only 1 question don't add to the currentIndex, otherwise you'll
			// get undefined question
			currentIndex:
				eventMatching?.questions?.length === 1
					? currentIndex
					: currentIndex + 1,
			showFinished:
				eventMatching?.questions?.length === 1
					? currentIndex
					: currentIndex + 1 === eventMatching?.questions?.length - 1,
			// add question with empty answers to state if skipped
			answers: updatedAnswers,
		});
	};

	checkRequiredQuestion = (questionId, isRequired) => {
		const { answers } = this.state;
		const questionIdxInAnswers = answers
			.map((el) => el.questionId)
			.indexOf(questionId);
		if (
			isRequired &&
			(questionIdxInAnswers < 0 ||
				answers[questionIdxInAnswers].answerIds.length <= 0)
		)
			return true;
		return false;
	};

	goBackOneQuestion = () => {
		const { currentIndex, showFinished } = this.state;
		if (currentIndex - 1 < 0) return;
		if (showFinished) this.setState({ showFinished: false });
		this.setState({ currentIndex: currentIndex - 1 });
	};

	handleAddChangeAnswers = (questionId, type) => (e) => {
		const { eventMatching } = this.props;
		const { answers } = this.state;
		let updatedAnswers = [...answers];
		// handle keep answers object in state
		let questionIndex = updatedAnswers.findIndex(
			(answer) => answer.questionId === questionId,
		);
		let qIdx = eventMatching?.questions?.findIndex(
			(question) => question._id === questionId,
		);
		let aIdx = eventMatching?.questions[qIdx]?.answers?.findIndex(
			(answer) => answer.title === e.target.value,
		);
		let answerId = eventMatching?.questions[qIdx]?.answers[aIdx]?._id;
		let data = {};
		data.questionId = questionId;
		data.answerIds = [];
		if (type === "checkbox") {
			// if question answer is ALREADY in state then check if option was unchecked and remove
			// from answerIds array, else push the newly checked answer
			if (questionIndex !== -1) {
				let answerIndex = updatedAnswers[questionIndex].answerIds.findIndex(
					(aId) => aId === answerId,
				);
				if (answerIndex !== -1)
					updatedAnswers[questionIndex].answerIds.splice(answerIndex, 1);
				if (answerIndex === -1)
					updatedAnswers[questionIndex].answerIds.push(answerId);
			} else {
				data.answerIds.push(answerId);
				updatedAnswers.splice(qIdx, 0, data);
			}
		} else {
			// if question answer is ALREADY in state then check if option was unchecked and remove
			// from answerIds array, else push the newly checked answer
			if (questionIndex !== -1) {
				let answerIndex = updatedAnswers[questionIndex].answerIds.findIndex(
					(aId) => aId === answerId,
				);
				if (answerIndex !== -1) updatedAnswers[questionIndex].answerIds = [];
				if (answerIndex === -1)
					updatedAnswers[questionIndex].answerIds = [answerId];
			} else {
				data.answerIds.push(answerId);
				updatedAnswers.splice(qIdx, 0, data);
			}
		}

		this.setState({ answers: updatedAnswers });
	};

	handleSeeNetworking = () => {
		const { closeNoPrompt, handleSeeNetworking } = this.props;
		handleSeeNetworking();
		closeNoPrompt();
	};

	getUserData = (userId) => {
		const { eventUsers } = this.props;
		const userData = eventUsers.allEventUsers.find(
			(user) => user.user === userId,
		);
		return userData;
	};

	checkIfUserExistsInArray = (arr1, arr2) => {
		if (!arr1.length) return arr2;
		let arrayOfUsers = [];
		for (let user of arr1) {
			for (let usr of arr2) {
				if (String(user.user) !== String(usr.user)) {
					arrayOfUsers.push(usr);
				}
			}
		}
		return arrayOfUsers;
	};

	addMatchingLevelToFeaturedUsers = (allUsers, featuredUsers) => {
		if (!featuredUsers.length) return;
		let users = cloneDeep(featuredUsers).map((featUser, usrIndex) => {
			allUsers.forEach((user) => {
				if (featUser.user === user.user) {
					featUser.matchingLevel = user.matchingLevel;
				}
			});
			return featUser;
		});

		return (
			users.sort((a, b) => {
				return sortFn(Math.floor(b.matchingLevel), Math.floor(a.matchingLevel));
			}) || []
		);
	};

	addFirstAndLastToMatchingUsers = (allEventUsers, matchingUsers) => {
		if (!matchingUsers) return;
		let users = cloneDeep(matchingUsers).map((matchUser) => {
			allEventUsers.forEach((user) => {
				if (matchUser.user === user.user) {
					matchUser.first = user.first;
					matchUser.last = user.last;
				}
			});
			return matchUser;
		});

		return (
			users.sort((a, b) => {
				return (
					sortFn(Math.floor(b.matchingLevel), Math.floor(a.matchingLevel)) ||
					sortABC(a, b)
				);
			}) || []
		);
	};

	render() {
		const { answers, currentIndex, showFinished, startedAnswering } =
			this.state;
		const {
			open,
			close,
			closeNoPrompt,
			translation,
			defaultTranslation,
			user,
			eventMatching,
			loadingGetMatches,
			noOpenNetworking,
			loadingGetQuestionnaire,
			featuredUsersFormatted,
		} = this.props;

		const currentUserId = user._id;
		const question =
			!!eventMatching.questions && eventMatching?.questions[currentIndex];
		const nextQuestionDisabled = this.checkRequiredQuestion(
			question._id,
			question.isRequired,
		);
		// sort users by Highest, featured, medium, low
		let highMatchingUsers = [];
		let mediumMatchUsers = [];
		let lowMatchingUsers = [];

		let matchingUsersFormatted = this.addFirstAndLastToMatchingUsers(
			this.props.eventUsers?.allEventUsers,
			user?.matchingUsers,
		);

		matchingUsersFormatted?.forEach((matchingUser) => {
			if (!matchingUser) return;
			if (matchingUser.matchingLevel > 0 && matchingUser.matchingLevel <= 29)
				return lowMatchingUsers.push(matchingUser);
			if (matchingUser.matchingLevel <= 79)
				return mediumMatchUsers.push(matchingUser);
			return highMatchingUsers.push(matchingUser);
		});

		// let highMatchingUsers = this.addFirstAndLastToMatchingUsers(this.props.eventUsers?.allEventUsers, user?.matchingUsers)?.filter((user) => user.matchingLevel >= 80 && user.matchingLevel <= 100) || [];
		// let mediumMatchUsers = this.addFirstAndLastToMatchingUsers(this.props.eventUsers?.allEventUsers, user?.matchingUsers)?.filter((user) => user.matchingLevel >= 30 && user.matchingLevel <= 79) || [];
		// let lowMatchingUsers = this.addFirstAndLastToMatchingUsers(this.props.eventUsers?.allEventUsers, user?.matchingUsers)?.filter((user) => user.matchingLevel > 0 && user.matchingLevel <= 29) || [];

		let allUsers = highMatchingUsers.concat(mediumMatchUsers, lowMatchingUsers);

		let usersToBeDisplayed = [...highMatchingUsers].slice(0, 3);

		if (usersToBeDisplayed.length < 3) {
			let arrayWithUniqueUsers = this.checkIfUserExistsInArray(
				usersToBeDisplayed,
				featuredUsersFormatted,
			).filter((user) => user.user !== currentUserId);
			let featuredUsers = arrayWithUniqueUsers?.sort((a, b) => {
				return sortABC(a, b);
			});
			featuredUsers =
				this.addMatchingLevelToFeaturedUsers(allUsers, featuredUsers)?.slice(
					0,
					3 - usersToBeDisplayed.length,
				) || [];
			usersToBeDisplayed.push(...featuredUsers);
		}

		if (usersToBeDisplayed.length < 3) {
			let arrayWithUniqueUsers = this.checkIfUserExistsInArray(
				usersToBeDisplayed,
				mediumMatchUsers,
			);
			let mediumUsers =
				arrayWithUniqueUsers.slice(0, 3 - usersToBeDisplayed.length) || [];
			usersToBeDisplayed.push(...mediumUsers);
		}

		if (usersToBeDisplayed.length < 3) {
			let arrayWithUniqueUsers = this.checkIfUserExistsInArray(
				usersToBeDisplayed,
				lowMatchingUsers,
			);
			let lowUsers =
				arrayWithUniqueUsers.slice(0, 3 - usersToBeDisplayed.length) || [];
			usersToBeDisplayed.push(...lowUsers);
		}

		// const filterSliceSortMatches = user?.matchingUsers
		//     ?.filter((user) => user.matchingLevel !== 'normal')
		//     .sort((a, b) => sortFn(Math.floor(b.score), Math.floor(a.score)))
		//     .slice(0, 3);

		const filterSliceSortMatches = usersToBeDisplayed;

		return (
			<Dialog
				open={open}
				aria-labelledby="form-dialog-title"
				PaperProps={{ classes: { root: "matching-dialog-paper reverse-rtl" } }}
				// prevents click outside modal close
				onClose={(e, reason) => reason !== "backdropClick" && close}
				// prevents Escape key modal close
				disableEscapeKeyDown
			>
				<ValidatorForm
					ref="form"
					className="matching-form-wrapper"
					onSubmit={() => {}}
				>
					{loadingGetQuestionnaire && (
						<div className="loading-container">
							<MatchingLoader />
						</div>
					)}
					<DialogTitle
						id="form-dialog-title"
						classes={{
							root: `${startedAnswering ? "question-answering" : ""} ${
								!!user.matchingUsers ? "received-response" : ""
							} ${isMobileSafari ? "mobile-safari" : ""}`,
						}}
					>
						{!!user.matchingUsers ? (
							filterSliceSortMatches?.length === 0 ? (
								<div className="noResultsTitle">
									{translation?.matching?.noResultsTitle ||
										defaultTranslation?.matching?.noResultsTitle}
								</div>
							) : (
								translation?.matching?.resultsTitle ||
								defaultTranslation?.matching?.resultsTitle
							)
						) : !!eventMatching ? (
							eventMatching?.title
						) : (
							"Matching questionnaire"
						)}
						<Button
							classes={{
								root: `matching-remove ${
									filterSliceSortMatches?.length === 0 ? "noResultsCross" : ""
								}`,
							}}
							// check if user started answering questions in order to display Dialog close prompt
							// if user startedAnswering but pressed the Finish (answered last question)
							// and is on the results page, close Dialog without prompt
							onClick={
								startedAnswering && filterSliceSortMatches?.length === undefined
									? close
									: closeNoPrompt
							}
						>
							<Close
								classes={{
									root: "grey-color",
								}}
							/>
						</Button>
						{startedAnswering && !!!user.matchingUsers && (
							<>
								<MatchingQuestionsCounter
									len={eventMatching?.questions?.length}
									activeIndex={currentIndex + 1}
								/>
								<div className="required-label">
									{question?.isRequired ? "Required" : "Optional"}
								</div>
							</>
						)}
					</DialogTitle>
					<DialogContent
						id="form-dialog-content"
						classes={{
							root: `${startedAnswering ? "content-answering" : ""} ${
								loadingGetMatches ? "is-loading" : ""
							} ${!!user.matchingUsers ? "show-results" : ""}`,
						}}
					>
						{loadingGetMatches ? (
							<MatchingLoader />
						) : !!user.matchingUsers ? (
							<>
								<div
									className={`matches-square ${
										filterSliceSortMatches?.length === 0 ? "centered" : ""
									}`}
								>
									{filterSliceSortMatches.map((match, index) => {
										return (
											<NewUserCard
												dialogCard
												key={match.user}
												userId={match.user}
												matchingLevel={match.matchingLevel}
												dialogClose={closeNoPrompt}
												userData={this.getUserData(match.user)}
											/>
										);
									})}
									{filterSliceSortMatches?.length === 2 && (
										<div className="one-empty">
											{translation?.matching?.noMoreMatches ||
												defaultTranslation?.matching?.noMoreMatches}
										</div>
									)}
									{filterSliceSortMatches?.length === 1 && (
										<div className="two-empty">
											{translation?.matching?.noMoreMatches ||
												defaultTranslation?.matching?.noMoreMatches}
										</div>
									)}
									{filterSliceSortMatches?.length === 0 && (
										<>
											<EmptyResults fill={colors.primary} />
											<div className="all-empty">
												{translation?.matching?.noResults ||
													defaultTranslation?.matching?.noResults}
											</div>
										</>
									)}
									{filterSliceSortMatches?.length > 0 && (
										<div className="tablet-start-chat">
											<StartChatArrow />
											{translation?.matching?.clickStartChat ||
												defaultTranslation?.matching?.clickStartChat}
										</div>
									)}
								</div>
								{filterSliceSortMatches?.length !== 0 && (
									<div className="btw-responsive-text">
										{translation?.matching?.btwResultsResponsive ||
											defaultTranslation?.matching?.btwResultsResponsive}
									</div>
								)}
							</>
						) : !startedAnswering && !!eventMatching?.questions?.length ? (
							<>
								<div className="matching-intro-header">
									{!!eventMatching
										? eventMatching?.description
										: translation?.matching?.matchingQuestionnaireIntro ||
										  defaultTranslation?.matching?.matchingQuestionnaireIntro}
								</div>
								<div className="matching-user-cards">
									<NewUserCard displayOnly userId={user?._id} />
									<span className="user-empty-card" />
									<span className="user-empty-card" />
								</div>
								<div className="matching-intro-skip">
									{translation?.matching?.matchingQuestionnaireIntroSkip ||
										defaultTranslation?.matching
											?.matchingQuestionnaireIntroSkip}
								</div>
							</>
						) : (
							<MatchingParticipantQuestion
								answers={answers}
								question={question}
								questionIndex={currentIndex}
								handleChange={this.handleAddChangeAnswers}
							/>
						)}
					</DialogContent>
					<DialogActions
						id="form-dialog-actions"
						classes={{
							root: `${loadingGetMatches ? "is-loading" : ""} ${
								!!user.matchingUsers ? "show-results" : ""
							}`,
						}}
					>
						{loadingGetMatches ? null : !!user.matchingUsers ? (
							<>
								<Button
									variant="outlined"
									onClick={
										noOpenNetworking ? closeNoPrompt : this.handleSeeNetworking
									}
									type="button"
									classes={{ root: "iam-in-btn" }}
								>
									{translation?.matching?.seeMatchesBtn ||
										defaultTranslation?.matching?.seeMatchesBtn}
								</Button>
								<span className="matching-note">
									<span className="note-secondary-bold">
										{translation?.matching?.resultsNoteLabel ||
											defaultTranslation?.matching?.resultsNoteLabel}
									</span>
									&nbsp;
									{translation?.matching?.resultsNoteText ||
										defaultTranslation?.matching?.resultsNoteText}
									<br />
									{translation?.matching?.resultsNoteMyAccount ||
										defaultTranslation?.matching?.resultsNoteMyAccount}
								</span>
							</>
						) : !startedAnswering ? (
							<>
								<Button
									variant="outlined"
									onClick={closeNoPrompt}
									type="button"
									classes={{ root: "doit-later-btn" }}
								>
									{translation?.matching?.doItLaterBtn ||
										defaultTranslation?.matching?.doItLaterBtn}
								</Button>
								<Button
									type="button"
									classes={{ root: "iam-in-btn" }}
									onClick={this.handleStartAnswering}
								>
									{translation?.generalText?.start ||
										defaultTranslation?.generalText?.start}
								</Button>
							</>
						) : (
							<>
								{currentIndex !== 0 && (
									<Button
										variant="outlined"
										onClick={this.goBackOneQuestion}
										type="button"
										classes={{ root: "go-back-btn" }}
									>
										{translation?.matching?.backBtn ||
											defaultTranslation?.matching?.backBtn}
									</Button>
								)}
								{showFinished ? (
									<Button
										type="button"
										classes={{ root: "iam-in-btn" }}
										onClick={this.handleSubmitAnswers}
										disabled={nextQuestionDisabled}
									>
										{translation?.matching?.finishBtn ||
											defaultTranslation?.matching?.finishBtn}
									</Button>
								) : (
									<Button
										type="button"
										disabled={nextQuestionDisabled}
										classes={{ root: "iam-in-btn" }}
										onClick={this.onNextClicked(question._id)}
									>
										{translation?.matching?.nextBtn ||
											defaultTranslation?.matching?.nextBtn}
									</Button>
								)}
							</>
						)}
					</DialogActions>
				</ValidatorForm>
			</Dialog>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user.data,
		event: state.event.data,
		eventId: state.event.eventId,
		eventSlug: state.event.eventSlug,
		eventUsers: state.eventUsers,
		loadingGetQuestionnaire: state.organizer.loadingForm,
		eventMatching: state.organizer.eventMatching,
		loadingGetMatches: state.user.loadingGetMatches,
		featuredUsersFormatted: state.eventUsers.featuredUsersFormatted,
		translation: state.languages.translations[state.languages.platformLanguage],
		defaultTranslation: state.languages.translations["en"],
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onGetUsers: (eventId) => dispatch(actions.getUsers(eventId)),
		onSetMatchingFormSeen: (eventId) =>
			dispatch(actions.setMatchingFormSeen(eventId)),
		onSaveUpdateUserAnswers: (eventId, questionnaireId, data) =>
			dispatch(actions.saveUpdateUserAnswers(eventId, questionnaireId, data)),
		onGetUserEventProfile: (eventId, userId) =>
			dispatch(actions.getUserEventProfile(eventId, userId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchingDialog);
