import React, {Component} from 'react';

export default class SelectAllCheckboxIcon extends Component {
    render() {
        return (
            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                <path
                    d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10H7v-2h10v2z"
                    fill="#808080"
                ></path>
            </svg>
        );
    }
}
