import React, {PureComponent} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import InformDialog from '../../Dialogs/InformDialog';
import {preventDefaultDrag} from '../../Utils/utils';

class AuditoriumHeaderLink extends PureComponent {
    state = {
        showNoAuditoriumDialog: false,
        showNoAuditoriumAccessDialog: false,
    };

    handleOpenNoAuditoriumDialog = () => this.setState({showNoAuditoriumDialog: true});

    handleOpenNoAuditoriumAccess = () => this.setState({showNoAuditoriumAccessDialog: true});

    closeDialog = () =>
        this.setState({
            showNoAuditoriumDialog: false,
            showNoAuditoriumAccessDialog: false,
        });

    render() {
        const {showNoAuditoriumDialog, showNoAuditoriumAccessDialog} = this.state;
        const {
            event,
            eventSlug,
            eventRoles,
            resourcesAccess,
            translation,
            defaultTranslation,
            eventHasEnded,
            activeAuditoriumArchive,
            location,
        } = this.props;

        let activeAuditorium = location.pathname.indexOf(`/${eventSlug}/auditorium`) > -1;
        activeAuditorium = activeAuditorium && !activeAuditoriumArchive;

        let hasAccessToAuditoriums = true;
        if (event.hasAccessManagement && eventRoles.isParticipant && resourcesAccess) {
            if (!resourcesAccess.auditoriums.length) {
                hasAccessToAuditoriums = false;
            }
        }

        if (
            (event.exhibitorHasAccessManagement || event.scholarHasAccessManagement) &&
            eventRoles.isExhibitor &&
            resourcesAccess
        ) {
            if (!resourcesAccess.auditoriums.length) {
                hasAccessToAuditoriums = false;
            }
        }

        return (
            <>
                {event.restrictAuditoriumAccess ? (
                    <div onDragStart={preventDefaultDrag} className="button-link">
                        <p
                            onDragStart={preventDefaultDrag}
                            onClick={this.handleOpenNoAuditoriumDialog}
                            className="button-link-label"
                        >
                            {translation?.roomsDropdown.stages ||
                            defaultTranslation?.roomsDropdown.stages}
                        </p>
                    </div>
                ) : (
                    <>
                        {!hasAccessToAuditoriums ? (
                            <div onDragStart={preventDefaultDrag} className="button-link">
                                <p
                                    onDragStart={preventDefaultDrag}
                                    onClick={this.handleOpenNoAuditoriumAccess}
                                    className="button-link-label"
                                >
                                    {translation?.roomsDropdown.stages ||
                                    defaultTranslation?.roomsDropdown.stages}
                                </p>
                            </div>
                        ) : (
                            <>
                                {eventHasEnded ? (
                                    <Link
                                        tabIndex="0"
                                        className={`button-link ${activeAuditoriumArchive ? 'active' : ''}`}
                                        to={{pathname: `/event/${eventSlug}/auditorium-archive/`}}
                                    >
                                        <p className="button-link-label">
                                            {translation?.roomsDropdown.stages ||
                                            defaultTranslation?.roomsDropdown.stages}
                                        </p>
                                    </Link>
                                ) : (
                                    <Link
                                        tabIndex="0"
                                        className={`button-link ${activeAuditorium ? 'active' : ''}`}
                                        to={{pathname: `/event/${eventSlug}/auditorium/`}}
                                    >
                                        <p className="button-link-label">
                                            {translation?.roomsDropdown.stages ||
                                            defaultTranslation?.roomsDropdown.stages}
                                        </p>
                                    </Link>
                                )}
                            </>
                        )}
                    </>
                )}
                {showNoAuditoriumDialog && (
                    <InformDialog
                        open={showNoAuditoriumDialog}
                        onClose={this.closeDialog}
                        dialogClasses={'closed-doors'}
                        dialogTitle={event.brandingData.closedAuditoriumTitle}
                        dialogDescription={event.brandingData.closedAuditoriumText}
                        dialogButtonLabel={`${translation?.closedShowfloorDialog.button}`}
                    />
                )}
                {showNoAuditoriumAccessDialog && (
                    <InformDialog
                        open={showNoAuditoriumAccessDialog}
                        onClose={this.closeDialog}
                        dialogTitle={`${translation?.closedAuditoriumDialog.title}`}
                        dialogDescription={`${translation?.closedAuditoriumDialog.contentPackage}`}
                        dialogButtonLabel={`${translation?.closedShowfloorDialog.button}`}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventSlug: state.event.eventSlug,
        eventRoles: state.user.eventRoles,
        resourcesAccess: state.user.resourcesAccess,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default withRouter(connect(mapStateToProps)(AuditoriumHeaderLink));
