import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from 'react-redux';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as ArrowLeft} from '../../Images/svg/arrow_left.svg';
import SamplePdf from '../../assets/samplePdf2.pdf';
// import { Document, Page } from 'react-pdf';

// fix for no Worker
import {Document, Page, pdfjs} from 'react-pdf';
import {preventDefaultDrag} from '../../Utils/utils';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class BoothPdfPreview extends React.Component {
    state = {
        totalPages: 1,
        pagesArray: [1],
    };

    componentDidMount() {}

    getPdfUrl = () => {
        const {exhibitor} = this.props;
        if (window.location.hostname === 'localhost') {
            return SamplePdf;
        } else {
            return `${exhibitor.filesUrl}${exhibitor.booth.pdfPresentation}`;
        }
    };

    getPdfFileWidth = () => {
        let width, boothRenderWidth;
        let windowWidth = window.innerWidth;
        boothRenderWidth = windowWidth - 200;
        if (windowWidth < 700) {
            boothRenderWidth = windowWidth - 210;
        }
        width = boothRenderWidth * 0.75;
        return width;
    };

    onDocumentLoadSuccess = ({numPages}) => {
        let pagesArray = [];
        for (var i = 1; i <= numPages; i++) {
            pagesArray.push(i);
        }
        this.setState({
            totalPages: numPages,
            pagesArray: pagesArray,
        });
    };

    render() {
        const {pagesArray} = this.state;
        const {defaultTranslation, translation, exhibitor} = this.props;
        const width = this.getPdfFileWidth();

        return (
            <Dialog
                open={this.props.opened}
                onClose={this.props.closeDialog}
                aria-labelledby="form-dialog-title"
                className="booth-pdf-dialog"
                maxWidth={false}
            >
                <DialogContent className="booth-dialog-content">
                    <DialogTitle id="form-dialog-title">
                        <span onDragStart={preventDefaultDrag} draggable="false" onClick={this.props.closeDialog}>
                            <ArrowLeft fill={colors.white} />
                        </span>
                        {exhibitor.type === 'scholar' ? translation?.showfloor.posterBooth?.study || defaultTranslation?.showfloor.posterBooth?.study :
                            translation?.showfloor.exhibitBooth || defaultTranslation?.showfloor.exhibitBooth
                        }
                    </DialogTitle>
                    <div onDragStart={preventDefaultDrag} className="content-container">
                        <Document
                            file={this.getPdfUrl()}
                            onLoadError={console.error}
                            onLoadSuccess={this.onDocumentLoadSuccess}
                        >
                            {pagesArray.map((page) => (
                                <Page pageNumber={page} width={width} key={`page${page}`} />
                            ))}
                        </Document>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button type="button" classes={{label: 'booth-cancel-button'}} onClick={this.props.closeDialog}>
                        {translation?.generalText.close}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        exhibitor: state.exhibitor.data,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BoothPdfPreview);
