import React from "react";
import ReactPlayer from "react-player";
import Wall from "../../HOC/Wall/Wall";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import AuditoriumBackgroundImage from "../../SmallLayoutComponents/AuditoriumBackgroundImage";
import { isMobileSafari, isSafari, isOpera } from "react-device-detect";
import "../../CSS/auditorium.scss";
import { checkIfEventHasEnded, preventDefaultDrag } from "../../Utils/utils";
import Tooltip from "@material-ui/core/es/Tooltip/Tooltip";
import AuditoriumBanners from "../Auditorium/AuditoriumBanners";
import { AuditorumPollsOutput } from "../../Components/AuditorumPollsOutput";
import BreadcrumbsNavigation from "../../Components/BreadcrumbsNavigation";
import NoBuildingAccess from "./NoBuildingAccess";
import axios from "../../store/axios-instance";
import compact from "lodash/compact";

import VolumeMuteIcon from "@material-ui/icons/VolumeMute";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import FastForwardIcon from "@material-ui/icons/FastForward";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import { isWebview } from "../../Utils/is-webview";

class BuildingAuditoriumArchiveSingleVideo extends React.Component {
	fullscreenTimerId = 0;

	state = {
		timeSlotId: null,
		timeSlot: null,
		timeSlotIndex: null,
		auditorumID: null,
		fullscreen: false,
		playing: true,
		muteVideo: false,
		currentMobileTab: "video",
		currentTab: "wall",
		avcoreRecords: [],
	};

	componentDidMount() {
		this.props.onSetEventProtectedMenu();

		const { timeslotId } = this.props.match.params;
		const { event, building, buildingId } = this.props;

		this.setState({
			timeSlotId: timeslotId,
		});

		this.loadCurrentTimeSlot(timeslotId);

		let eventHasEnded = checkIfEventHasEnded(building, event.timezoneValue);
		if (!eventHasEnded)
			this.props.history.push(
				`/event/${event.slug}/village/building/${buildingId}/auditorium`,
			);

		document.addEventListener(
			"webkitfullscreenchange",
			this.toggleFullScreenEscKey,
			false,
		);
		document.addEventListener(
			"mozfullscreenchange",
			this.toggleFullScreenEscKey,
			false,
		);
		document.addEventListener(
			"fullscreenchange",
			this.toggleFullScreenEscKey,
			false,
		);
		document.addEventListener(
			"MSFullscreenChange",
			this.toggleFullScreenEscKey,
			false,
		);
	}

	componentWillUnmount() {
		this.props.closeSidebar();
		if (document.fullscreenElement) {
			document.exitFullscreen().then(this.exitFullscreen());
		}
		document.removeEventListener(
			"webkitfullscreenchange",
			this.toggleFullScreenEscKey,
			false,
		);
		document.removeEventListener(
			"mozfullscreenchange",
			this.toggleFullScreenEscKey,
			false,
		);
		document.removeEventListener(
			"fullscreenchange",
			this.toggleFullScreenEscKey,
			false,
		);
		document.removeEventListener(
			"MSFullscreenChange",
			this.toggleFullScreenEscKey,
			false,
		);
	}

	componentDidUpdate() {
		const { timeslotId } = this.props.match.params;
		if (this.state.timeSlotId !== timeslotId) {
			this.setState({
				timeSlotId: timeslotId,
			});
			this.loadCurrentTimeSlot(timeslotId);
		}
	}

	toggleFullScreenEscKey = () => {
		let fullscreenElement =
			document.fullscreenElement ||
			document.webkitFullscreenElement ||
			document.mozFullscreenElement ||
			document.msFullscreenElement;
		if (!fullscreenElement) {
			this.exitFullscreen();
		}
	};

	toggleFullScreen = () => {
		if (document.fullscreenElement) {
			document
				.exitFullscreen()
				.then(() => this.exitFullscreen())
				.catch((err) => console.error(err));
		} else {
			document.documentElement
				.requestFullscreen()
				.then(() => this.startFullscreen());
		}
	};

	startFullscreen = () => {
		this.setState({ fullscreen: true });
		this.hideVideoButtons();

		document.body.classList.add("fullscreen-video");
		document.getElementById("js-auditorium-page").classList.add("fullscreen");
		window.addEventListener("mousemove", this.mouseMove);
	};

	exitFullscreen = () => {
		this.setState({ fullscreen: false });
		this.showVideoButtons();
		clearTimeout(this.fullscreenTimerId);

		document.body.classList.remove("fullscreen-video");
		document
			.getElementById("js-auditorium-page")
			.classList.remove("fullscreen");
		window.removeEventListener("mousemove", this.mouseMove);
	};

	handleChangeTab = (tabName) => {
		this.setState({
			currentTab: tabName,
		});
	};

	loadCurrentTimeSlot = (timeslotId) => {
		const { building } = this.props;
		if (building) {
			let auditorumID = null;

			const allAuditoriumsProgram = building.auditoriums.reduce(
				(acc, auditorium) => {
					const audtorumPrograms = auditorium.dailyProgram.reduce(
						(acc, dayProgram) => {
							dayProgram.program.forEach((item) => {
								if (item._id === timeslotId) auditorumID = auditorium._id;
							});
							return [...acc, ...dayProgram.program];
						},
						[],
					);
					return [...acc, ...audtorumPrograms];
				},
				[],
			);

			let currentTimeSlot = allAuditoriumsProgram.find(
				(timeSlot) => timeSlot._id === timeslotId,
			);
			let currentTimeSlotIndex = allAuditoriumsProgram.findIndex(
				(timeSlot) => timeSlot._id === timeslotId,
			);
			if (currentTimeSlot.session) {
				// setTimeout(async () => {
				//     const result = await axios.get(`/sessions/${currentTimeSlot.session._id}/records`);
				//     const records = result.data.recordings.map((r) => r.url);
				//     this.setState({avcoreRecords: records});
				// });
				this.loadSessionRecords(currentTimeSlot.session._id);
			}
			this.setState({
				timeSlot: currentTimeSlot,
				timeSlotIndex: currentTimeSlotIndex,
				auditorumID,
				avcoreRecords: [],
			});

			// we set up the sideMenu to have videoWall
			this.props.setHasVideoWall(true);

			// we set up the current time slot video Id
			const activeVideoWallId = currentTimeSlot.videoWall;

			let highlightUsers = [];
			currentTimeSlot.speakers.forEach((speaker) => {
				highlightUsers.push(speaker.user._id);
			});

			// we set up the current wall Id to be the video Wall Id
			const wallData = {
				wallId: activeVideoWallId,
				highlightUsers,
			};
			this.props.setTimeSlotVideoWall(wallData);
			this.props.setActiveWall(wallData);

			// we open the sideBar to the videoWall
			this.props.seeVideoWall();
		}
	};

	loadSessionRecords = async (sessionId) => {
		const result = await axios.get(`/sessions/${sessionId}/records`);
		const records = result.data.recordings.map((r) => r.url);
		this.setState({ avcoreRecords: records });
	};

	videoHasEnded = () => {
		this.setState({ playing: false });
		this.exitFullscreen();
	};

	escFunction = (e) => {
		if (e.key === "Escape") this.exitFullscreen();
	};

	mouseMove = () => {
		clearTimeout(this.fullscreenTimerId);
		this.showVideoButtons();
		this.fullscreenTimerId = setTimeout(this.hideVideoButtons, 3000);
	};

	showVideoButtons = () => {
		if (document.getElementById("videoButtons")) {
			document.getElementById("videoButtons").classList.remove("hide");
		}
	};

	hideVideoButtons = () => {
		if (document.getElementById("videoButtons")) {
			document.getElementById("videoButtons").classList.add("hide");
		}
	};

	skipForward = () => {
		this.player.seekTo(
			Math.floor(this.player.getCurrentTime()) + 30,
			"seconds",
		);
	};

	skipBackward = () => {
		this.player.seekTo(
			Math.floor(this.player.getCurrentTime()) - 30,
			"seconds",
		);
	};

	ref = (player) => {
		this.player = player;
	};

	handleChangeMobileTab = (tabName) => {
		this.setState({
			currentMobileTab: tabName,
		});
	};

	render() {
		const { event, isLargeScreen, building, eventRoles, resourcesAccess } =
			this.props;
		const { currentMobileTab, currentTab, timeSlot } = this.state;

		if (
			event.hasAccessManagement &&
			eventRoles.isParticipant &&
			resourcesAccess !== null &&
			!resourcesAccess.buildings.includes(building._id)
		) {
			return <NoBuildingAccess />;
		}

		const hasVideoWall = timeSlot && timeSlot.videoWall;
		const showWall =
			(hasVideoWall && isLargeScreen) ||
			(hasVideoWall && !isLargeScreen && currentMobileTab === "wall");
		const hideVideo = !isLargeScreen && currentMobileTab === "wall";

		// 31.08.2020 currently the fullscreen api is not supported on Safari iPhone
		const hideFullscreenButton = isMobileSafari || isSafari || isOpera;

		const records = this.state.timeSlot
			? compact([
					this.state.timeSlot.video || null,
					...this.state.avcoreRecords,
			  ])
			: [];

		return (
			<>
				<div onDragStart={preventDefaultDrag} className="position-background">
					<BreadcrumbsNavigation className="white" />
					{this.state.timeSlotId ? (
						<>
							<div
								onDragStart={preventDefaultDrag}
								id="js-auditorium-page"
								className="auditorium-page mobile-page-container"
							>
								{!isLargeScreen && (
									<div onDragStart={preventDefaultDrag} className="page-title">
										Auditorium Archive
									</div>
								)}
								{isLargeScreen && (
									<div
										onDragStart={preventDefaultDrag}
										className={`slot-title ${
											this.state.timeSlot ? "" : "d-none"
										}`}
									>
										<p onDragStart={preventDefaultDrag} className={"title "}>
											<span>
												{this.state.timeSlot.speakersExtended.length ? (
													<>
														{this.state.timeSlot.speakersExtended.map(
															(speaker, index) => {
																return (
																	<span
																		onDragStart={preventDefaultDrag}
																		draggable="false"
																		key={speaker._id}
																	>
																		{speaker.fullName}
																		{index ===
																		this.state.timeSlot.speakersExtended
																			.length -
																			1 ? (
																			<span
																				onDragStart={preventDefaultDrag}
																				draggable="false"
																				className="subtitle"
																			>
																				{" "}
																				with{" "}
																			</span>
																		) : (
																			", "
																		)}
																	</span>
																);
															},
														)}
													</>
												) : null}
												{this.state.timeSlot.title}
											</span>
										</p>
									</div>
								)}
								{!isLargeScreen && (
									<div
										onDragStart={preventDefaultDrag}
										className="tabs-container"
									>
										<div
											className={`button-tab tab ${
												currentMobileTab === "video" ? "active" : ""
											}`}
											onClick={() => this.handleChangeMobileTab("video")}
										>
											STAGE
										</div>
										{hasVideoWall && (
											<>
												<div
													className={`button-tab tab ${
														currentMobileTab === "wall" && currentTab === "wall"
															? "active"
															: ""
													}`}
													onClick={() => {
														this.handleChangeMobileTab("wall");
														this.handleChangeTab("wall");
													}}
												>
													VIDEO WALL
												</div>
												<div
													className={`button-tab tab ${
														currentMobileTab === "wall" &&
														currentTab === "polls"
															? "active"
															: ""
													}`}
													onClick={() => {
														this.handleChangeMobileTab("wall");
														this.handleChangeTab("polls");
													}}
												>
													POLLS
												</div>
											</>
										)}
									</div>
								)}
								<div
									onDragStart={preventDefaultDrag}
									className={`centerOfPage ${hideVideo ? "hide" : ""}`}
								>
									<div
										onDragStart={preventDefaultDrag}
										className="relativeCenter"
									>
										{isLargeScreen && (
											<AuditoriumBanners
												filesUrl={event.brandingData.filesUrl}
												brandingData={event.brandingData}
											/>
										)}
										<div
											onDragStart={preventDefaultDrag}
											className={
												"videoWrapper " +
												(this.state.fullscreen ? "fullscreen" : "")
											}
										>
											<div
												onDragStart={preventDefaultDrag}
												className="video-container"
											>
												{!!records.length && (
													<ReactPlayer
														ref={this.ref}
														url={records}
														playing={this.state.playing}
														className="react-player"
														controls={false}
														width="100%"
														height="100%"
														volume={this.state.muteVideo ? 0 : 1}
														onEnded={this.videoHasEnded}
														onContextMenu={(e) => e.preventDefault()}
													/>
												)}

												<div
													onDragStart={preventDefaultDrag}
													className="auditoriumOverlayPlayer"
												></div>
											</div>
											<div
												className="video-actions-container"
												id="videoButtons"
												style={this.state.auditoriumButtonsStyle}
											>
												{!isWebview() && (
													<button
														onClick={this.toggleFullScreen}
														className={`${
															hideFullscreenButton ? "d-none" : ""
														}`}
													>
														{this.state.fullscreen ? (
															<Tooltip title="Minimize">
																<FullscreenExitIcon fontSize="inherit" />
															</Tooltip>
														) : (
															<Tooltip title="Fullscreen">
																<FullscreenIcon fontSize="inherit" />
															</Tooltip>
														)}
													</button>
												)}

												<Tooltip title="-30 sec">
													<button onClick={() => this.skipBackward()}>
														<FastRewindIcon fontSize="inherit" />
													</button>
												</Tooltip>
												<button
													onClick={() =>
														this.setState({ playing: !this.state.playing })
													}
												>
													{this.state.playing ? (
														<Tooltip title="Pause">
															<PauseIcon fontSize="inherit" />
														</Tooltip>
													) : (
														<Tooltip title="Play">
															<PlayArrowIcon fontSize="inherit" />
														</Tooltip>
													)}
												</button>
												<Tooltip title="+30 sec">
													<button onClick={() => this.skipForward()}>
														<FastForwardIcon fontSize="inherit" />
													</button>
												</Tooltip>
												<button
													onClick={() =>
														this.setState({ muteVideo: !this.state.muteVideo })
													}
												>
													{this.state.muteVideo ? (
														<Tooltip title="Unmute">
															<VolumeUpIcon fontSize="inherit" />
														</Tooltip>
													) : (
														<Tooltip title="Mute">
															<VolumeMuteIcon fontSize="inherit" />
														</Tooltip>
													)}
												</button>
											</div>
										</div>
										{!isLargeScreen && (
											<div
												onDragStart={preventDefaultDrag}
												className={`slot-title`}
											>
												<p onDragStart={preventDefaultDrag} className="title ">
													{timeSlot.title}
												</p>
												<p
													onDragStart={preventDefaultDrag}
													className="subtitle"
												>
													Subtitle
												</p>
											</div>
										)}
									</div>
								</div>
								{showWall && (
									<div
										onDragStart={preventDefaultDrag}
										className="booth-wall-container"
									>
										<div
											onDragStart={preventDefaultDrag}
											className="booth-wall-header"
										>
											<span
												onClick={() => {
													this.handleChangeTab("wall");
												}}
												className={currentTab === "wall" ? "-is-active" : ""}
											>
												Video Wall
											</span>
											<span
												onClick={() => {
													this.handleChangeTab("polls");
												}}
												className={currentTab === "polls" ? "-is-active" : ""}
											>
												polls
											</span>
										</div>
										{currentTab === "wall" && <Wall isVideoWall />}
										{currentTab === "polls" && (
											<AuditorumPollsOutput
												auditoriumId={this.state.auditorumID}
												programId={this.state.timeSlotId}
											/>
										)}
									</div>
								)}
							</div>
						</>
					) : null}
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		event: state.event.data,
		user: state.user.data,
		isLargeScreen: state.layout.isLargeScreen,
		buildingId: state.building.buildingId,
		building: state.building.data,
		eventRoles: state.user.eventRoles,
		resourcesAccess: state.user.resourcesAccess,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeSidebar: () => dispatch(actions.sideNavClose()),
		setHasVideoWall: (booleanValue) =>
			dispatch(actions.sideNavHasVideoWall(booleanValue)),
		setTimeSlotVideoWall: (wallData) =>
			dispatch(actions.setTimeSlotVideoWall(wallData)),
		setActiveWall: (wallData) => dispatch(actions.setActiveWall(wallData)),
		seeVideoWall: () => dispatch(actions.sideNavSeeVideoWall()),
		onSetEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(BuildingAuditoriumArchiveSingleVideo);
