import React from 'react';
import {TextValidator, ValidatorComponent} from 'react-material-ui-form-validator';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class CustomSDGFields extends ValidatorComponent{
    handleChange = (e) => {
        const {field, eventInfo} = this.props;
        let updatedOptions = [...field.value];
        let selectedSDGs = [];
        if (e.target.checked) {
            updatedOptions.push(e.target.value);
            eventInfo?.sdgs?.forEach((eventSDG) => {
                updatedOptions.forEach((elem) => {
                    if (eventSDG._id === elem) {
                        selectedSDGs.push(elem);
                    }
                });
            });
        } else {
            selectedSDGs = updatedOptions.filter((option) => option !== e.target.value);
        }
        this.props.handleChange(field.name, selectedSDGs);
    };

    renderValidatorComponent() {
        const {field, eventInfo, fieldValues} = this.props;
        return (
            <>
                <div className="sdgs-wrapper">
                    <FormControl component="fieldset" fullWidth={true}>
                        <FormLabel component="legend">{field.label}</FormLabel>
                        <FormGroup
                            aria-label={field.label}
                            name={field.name}
                            value={field.value}
                            className="registration-radio-group"
                        >
                            {eventInfo?.sdgs?.map((sdg, sdgIndex) => {
                                let checked = false;
                                if (fieldValues.includes(sdg._id)) {
                                    checked = true;
                                }
                                return (
                                    <FormControlLabel
                                        value={sdg._id}
                                        control={<Checkbox onChange={this.handleChange} />}
                                        label={`Goal ${sdgIndex + 1}: ${sdg.title}`}
                                        key={sdg._id}
                                        checked={checked}
                                    />
                                );
                            })}
                        </FormGroup>
                    </FormControl>
                </div>
                <TextValidator
                    key={field.name}
                    label={field.label}
                    type="text"
                    name={field.name}
                    value={field.value}
                    variant="outlined"
                    onChange={this.handleChange}
                    validators={field.validators}
                    errorMessages={field.errorMessages}
                    multiline={field.multiline > 0}
                    rows={field.multiline}
                    fullWidth={true}
                    margin="normal"
                    className="field-container hidden sdg-label"
                />
            </>
        );
    }
}

export default CustomSDGFields;