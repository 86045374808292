import React, {PureComponent} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SMPopup from '../../Dialogs/SMPopup';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as PinIcon} from '../../Images/svg/pin.svg';
import {ReactComponent as UnPinIcon} from '../../Images/svg/unpin.svg';
import {ReactComponent as DeleteIcon} from '../../Images/svg/rubbish-bin.svg';
import {ReactComponent as Share} from '../../Images/svg/sm-share-full.svg';
import {ReactComponent as EditPostIcon} from '../../Images/svg/edit-post.svg';
import {preventDefaultDrag} from '../../Utils/utils';

class MenuActionsPost extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            sharedPost: '',
            sharePopup: false,
        };
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        const {handleCloseActions} = this.props;
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            handleCloseActions();
        }
    }

    openSharePopup = (post) => () => {
        const {text} = post;
        this.setState({sharePopup: true, sharedPost: text});
    };

    closeSharePopup = () => this.setState({sharePopup: false});

    characterLimiter = (platform, limit, text) => {
        switch (platform) {
            // Facebook quote limit (02.04.2021): 280
            case 'facebook':
                return `"${text && text.trim().length > limit ? text.slice(0, limit - 100) + '...' : text}"`;
            // Twitter message limit (02.04.2021): 500
            case 'twitter':
                return `"${text && text.trim().length > limit ? text.slice(0, limit - 100) + '...' : text}"`;
            // Email message limit (02.04.2021): 1200 (myOnvent comment limit)
            case 'email':
                return `"${text && text.trim().length > limit ? text.slice(0, limit - 100) + '...' : text}"`;
            default:
                return text;
        }
    };

    decodeHtml = (html) => {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    };

    handleEnterKey = (itemId, type, data) => (e) => {
        if (e.key === 'Enter') {
            if (type === 'post-pin') return this.props.onPinPost(itemId)();
            if (type === 'post-unpin') return this.props.onUnpinPost(itemId)();
            if (type === 'post-share') return this.openSharePopup(data)();
            if (type === 'post-edit') return this.props.enterPostEditMode(itemId, data)();
            if (type === 'post-delete') return this.props.deletePost(itemId)();
            if (this.props.showActions === itemId) return this.props.handleCloseActions();
            if (this.props.showActions !== itemId) return this.props.handleOpenActions(itemId)();
        }
        if (e.key === 'Escape') {
            if (this.props.showActions === itemId) return this.props.handleCloseActions();
        }
    };

    render() {
        const {
            user,
            event,
            translation,
            defaultTranslation,
            deletePost,
            onPinPost,
            onUnpinPost,
            isPinned,
            postId,
            showActions,
            handleOpenActions,
            isPostOwner,
            currentPost,
            enterPostEditMode,
            postEditMode,
        } = this.props;
        const eventURL = `${window.location.origin}${event.slug ? '/event/' + event.slug : ''}`;
        const {text} = currentPost;

        return (
            <>
                <div onDragStart={preventDefaultDrag} className="menu-actions">
                    <button
                        tabIndex="0"
                        onDragStart={preventDefaultDrag}
                        className="wall-action more-button"
                        onClick={handleOpenActions(postId)}
                        onKeyDown={this.handleEnterKey(postId)}
                    >
                        {isPinned ? <PinIcon className="pin-icon active" /> : <MoreVertIcon />}
                    </button>
                    {showActions === postId ? (
                        <div onDragStart={preventDefaultDrag} className="actions-list" ref={this.setWrapperRef}>
                            <ul>
                                {isPinned ? (
                                    <li
                                        tabIndex="0"
                                        onClick={onUnpinPost(postId)}
                                        onKeyDown={this.handleEnterKey(postId, 'post-unpin')}
                                    >
                                        <UnPinIcon fill={colors.error} className="un-pin" />
                                        <span>
                                            {translation?.wall.unPinTitle || defaultTranslation?.wall.unPinTitle}
                                        </span>
                                    </li>
                                ) : (
                                    <li
                                        tabIndex="0"
                                        onClick={onPinPost(postId)}
                                        onKeyDown={this.handleEnterKey(postId, 'post-pin')}
                                    >
                                        <PinIcon fill={colors.primary} />
                                        <span>{translation?.wall.pinTitle || defaultTranslation?.wall.pinTitle}</span>
                                    </li>
                                )}
                                {isPostOwner ? (
                                    <>
                                        {user._id === currentPost.user && process.env.REACT_APP_SM_SHARE === 'true' && (
                                            <li
                                                tabIndex="0"
                                                onClick={this.openSharePopup(currentPost)}
                                                onKeyDown={this.handleEnterKey(postId, 'post-share', currentPost)}
                                            >
                                                <Share fill={colors.dark} width="18" height="18" />
                                                <span>
                                                    {translation?.socialMediaShare?.shareLabel ||
                                                        defaultTranslation?.socialMediaShare?.shareLabel}
                                                </span>
                                            </li>
                                        )}
                                        {user._id === currentPost.user && (
                                            <li
                                                tabIndex="0"
                                                className={`${postEditMode ? 'edit-disabled' : ''}`}
                                                onClick={enterPostEditMode(postId, this.decodeHtml(text))}
                                                onKeyDown={this.handleEnterKey(
                                                    postId,
                                                    'post-edit',
                                                    this.decodeHtml(text)
                                                )}
                                            >
                                                <EditPostIcon fill={colors.dark} width="18" height="18" />
                                                <span>
                                                    {translation?.sessions?.edit || defaultTranslation?.sessions?.edit}
                                                </span>
                                            </li>
                                        )}
                                        <li
                                            tabIndex="0"
                                            onClick={deletePost(postId)}
                                            onKeyDown={this.handleEnterKey(postId, 'post-delete')}
                                        >
                                            <DeleteIcon width="18" height="18" />
                                            <span>
                                                {translation?.generalText.delete ||
                                                    defaultTranslation?.generalText.delete}
                                            </span>
                                        </li>
                                    </>
                                ) : null}
                            </ul>
                        </div>
                    ) : null}
                </div>
                <SMPopup
                    open={this.state.sharePopup}
                    closeConfirm={this.closeSharePopup}
                    eventName={event.name}
                    dialogTitle={
                        translation?.socialMediaShare?.dialogTitlePost ||
                        defaultTranslation?.socialMediaShare?.dialogTitlePost
                    }
                    popupLanguageLevel="platform"
                    clipboardMessage={this.characterLimiter(null, null, this.state.sharedPost)}
                    facebookQuote={`${this.characterLimiter('facebook', 500, this.state.sharedPost)} - ${
                        translation?.socialMediaShare?.imAttending || defaultTranslation?.socialMediaShare?.imAttending
                    } ${event.name ? `"${event.name}"` : ''} ${
                        translation?.socialMediaShare?.event || defaultTranslation?.socialMediaShare?.event
                    }. ${
                        translation?.socialMediaShare?.interestedClick ||
                        defaultTranslation?.socialMediaShare?.interestedClick
                    }`}
                    twitterMessage={`${this.characterLimiter('twitter', 280, this.state.sharedPost)} - ${
                        translation?.socialMediaShare?.imAttending || defaultTranslation?.socialMediaShare?.imAttending
                    } ${event.name ? `"${event.name}"` : ''} ${
                        translation?.socialMediaShare?.event || defaultTranslation?.socialMediaShare?.event
                    }. ${
                        translation?.socialMediaShare?.interestedClick ||
                        defaultTranslation?.socialMediaShare?.interestedClick
                    }`}
                    emailSubject={`${process.env.REACT_APP_PLATFORM_NAME} - ${event.name}`}
                    emailBody={`${this.characterLimiter('email', 1200, this.state.sharedPost)} - ${
                        translation?.socialMediaShare?.imAttending || defaultTranslation?.socialMediaShare?.imAttending
                    } ${event.name ? `"${event.name}"` : ''} ${
                        translation?.socialMediaShare?.event || defaultTranslation?.socialMediaShare?.event
                    }. ${
                        translation?.socialMediaShare?.interestedClick ||
                        defaultTranslation?.socialMediaShare?.interestedClick
                    } ${eventURL}`}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        event: state.event.data,
        sideNavigation: state.user.sideNavigation,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default withRouter(connect(mapStateToProps)(MenuActionsPost));
