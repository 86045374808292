import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import NotFound from '../../Pages/NotFound';
import Spinner from '../../SmallLayoutComponents/FullPageSpinner';
import EventPrivateComponent from './EventPrivateComponent';
import {isMobile} from 'react-device-detect';

const ExhibitorRoute = ({component: Component, loggedIn, user, loadingUser, ...rest}) => (
    <Route
        {...rest}
        render={(props) => {
            const eventSlug = props.match.params.eventSlug;

            if (!loggedIn && loadingUser) {
                return <Spinner />;
            } else if (loggedIn && user) {
                const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === eventSlug);

                // if the user is exhibitor or an exhibitorRep
                // an exhibitor should have access to all the exhibitorRep routes
                const userIsExhibitor =
                    userRolesInEvent &&
                    (userRolesInEvent.roles.includes('exhibitorRep') || userRolesInEvent.roles.includes('exhibitor'));
                if (userIsExhibitor) {
                    if (isMobile) {
                        return (
                            <Redirect
                                to={{
                                    pathname: `/event/${eventSlug}/lobby`,
                                }}
                            />
                        );
                    } else {
                        return (
                            <EventPrivateComponent {...props}>
                                <Component {...props} />
                            </EventPrivateComponent>
                        );
                    }
                } else {
                    return <NotFound />;
                }
            } else if (!loggedIn && !loadingUser) {
                return (
                    <Route path='/' component={() => {
                        window.location.href = `${process.env.REACT_APP_AIFG_WP_LINK}`;
                        return null;
                    }}/>
                );
            } else {
                return <NotFound />;
            }
        }}
    />
);

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        user: state.user.data,
        loadingUser: state.user.loading,
    };
};

export default connect(mapStateToProps)(ExhibitorRoute);
