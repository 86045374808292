import ReactPlayer from "react-player";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import FastForwardIcon from "@material-ui/icons/FastForward";
import { preventDefaultDrag } from "../../../Utils/utils";

const LobbyVideoEmbed = (props) => {
	const {
		videoUrl,
		thumbnailImage,
		fullscreen,
		isVideoPlaying,
		muteVideo,
		youtubePlayer,
		handleVideoReady,
		enterFullscreen,
		toggleRewind,
		togglePause,
		toggleForward,
		toggleMute,
	} = props;
	return (
		<div className={`lobby-video-container ${fullscreen ? "fullscreen" : ""}`}>
			<div onDragStart={preventDefaultDrag} className="lobby-video-player">
				<img
					src={thumbnailImage}
					alt="Video thumbnail"
					className={`video-thumbnail ${!isVideoPlaying && "show"}`}
				/>
				<ReactPlayer
					ref={youtubePlayer}
					url={videoUrl}
					onReady={handleVideoReady}
					playing={isVideoPlaying}
					loop
					playsinline
					playIcon={<></>}
					controls={false}
					width="100%"
					height="100%"
					volume={muteVideo ? 0 : 1}
					className="react-player"
				/>
				<div
					onDragStart={preventDefaultDrag}
					className="lobby-video-actions-container"
				>
					<button onClick={enterFullscreen} className="fullscreen-button">
						<FullscreenIcon fontSize="inherit" />
					</button>
					<button
						onClick={toggleRewind}
						className="fullscreen-button mute-button"
					>
						<FastRewindIcon fontSize="inherit" />
					</button>
					<button
						onClick={togglePause}
						className="fullscreen-button mute-button"
					>
						{isVideoPlaying ? (
							<PauseIcon fontSize="inherit" />
						) : (
							<PlayArrowIcon fontSize="inherit" />
						)}
					</button>
					<button
						onClick={toggleForward}
						className="fullscreen-button mute-button"
					>
						<FastForwardIcon fontSize="inherit" />
					</button>
					<button
						onClick={toggleMute}
						className="fullscreen-button mute-button"
					>
						{muteVideo ? (
							<VolumeOffIcon fontSize="inherit" />
						) : (
							<VolumeUpIcon fontSize="inherit" />
						)}
					</button>
				</div>
			</div>
		</div>
	);
};

export default LobbyVideoEmbed;
