import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from 'react-redux';
import {preventDefaultDrag} from '../../Utils/utils';

class BoothLinks extends React.Component {
    state = {};

    componentDidMount() {}

    render() {
        const {translation} = this.props;
        return (
            <Dialog
                open={this.props.opened}
                onClose={this.props.closeDialog}
                aria-labelledby="form-dialog-title"
                className="booth-links-dialog-container booth-dialog"
            >
                <DialogContent className="booth-dialog-content">
                    <DialogTitle id="form-dialog-title">{translation?.showfloor.boothLinksDialogTitle}</DialogTitle>
                    <div onDragStart={preventDefaultDrag} className="content-container">
                        {this.props.socialLinks.map((link) => (
                            <p
                                onDragStart={preventDefaultDrag}
                                key={link.platform}
                                onClick={this.props.onClickOnLink(link._id, link.platform)}
                            >
                                <a href={link.link} target="_blank" rel="noopener noreferrer">
                                    <span
                                        onDragStart={preventDefaultDrag}
                                        draggable="false"
                                        className="text-capitalize"
                                    ></span>
                                    <span onDragStart={preventDefaultDrag} draggable="false" className="link">
                                        {link.platform}
                                    </span>
                                </a>
                            </p>
                        ))}
                        {this.props.links.map((link) => (
                            <p
                                onDragStart={preventDefaultDrag}
                                key={link.label}
                                onClick={this.props.onClickOnLink(link._id, link.label)}
                            >
                                <a href={link.link} target="_blank" rel="noopener noreferrer">
                                    <span onDragStart={preventDefaultDrag} draggable="false" className="link">
                                        {link.label}
                                    </span>
                                </a>
                            </p>
                        ))}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button type="button" classes={{label: 'booth-cancel-button'}} onClick={this.props.closeDialog}>
                        {translation?.generalText.close}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BoothLinks);
