import React from 'react';
import {connect} from 'react-redux';
import {preventDefaultDrag} from '../../../../Utils/utils';
import SingleMeetingItem from './SingleMeetingItem';
import SingleSmartSuggestion from '../SmartSuggestions/SingleSmartSuggestion';
import Switch from '@material-ui/core/Switch';
import {ReactComponent as EmptyIcon} from '../../../../Images/svg/empty-sessions.svg';
import * as actions from '../../../../store/actions';
import Spinner from '../../../../SmallLayoutComponents/Spinner';
import moment from 'moment';

class MyAgenda extends React.Component {
    state = {
        expanded: null,
        agendaSessions: [],
    };

    componentDidMount() {
        const {isLargeScreen} = this.props;

        this.handleFilterMyAgenda();

        if (!isLargeScreen) {
            const tabs = document.querySelector('.tabs-wrapper');
            const title = document.querySelector('.my-agenda > div:first-of-type');
            let elementsHeight = tabs.clientHeight + title?.clientHeight;
            document.documentElement.style.setProperty('--agenda-height', `${elementsHeight}px`);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isLargeScreen} = this.props;

        if (prevProps.myAgenda !== this.props.myAgenda || prevProps.pastSessions !== this.props.pastSessions) {
            this.handleFilterMyAgenda();
        }

        if (!isLargeScreen) {
            const tabs = document.querySelector('.tabs-wrapper');
            const title = document.querySelector('.my-agenda > div:first-of-type');
            let elementsHeight = tabs.clientHeight + title?.clientHeight;
            document.documentElement.style.setProperty('--agenda-height', `${elementsHeight}px`);
        }
    }

    componentWillUnmount() {
        const {isLargeScreen} = this.props;
        if (!isLargeScreen) {
            document.documentElement.style.removeProperty('--agenda-height');
        }
    }

    handleFilterMyAgenda = () => {
        const {myAgenda, pastSessions} = this.props;
        if (pastSessions) {
            this.setState({
                agendaSessions: myAgenda,
            });
        } else {
            // hide past sessions
            const now = moment();
            let sessions = [];
            myAgenda.forEach((sessionAgenda) => {
                if (sessionAgenda?.type === 'meeting') {
                   // check if meeting is older than current time
                    if (now.isBefore(moment(sessionAgenda.endTimestamp))) {
                        sessions.push(sessionAgenda);
                    }
                } else {
                    if (sessionAgenda?.status !== 'past event') {
                        sessions.push(sessionAgenda);
                    }
                }
            });
            this.setState({
                agendaSessions: sessions,
            })
        }
    };

    toggleExpandedSuggestion = (id) => (e) => {
        e.preventDefault();
        this.setState(prevState => ({
            expanded: prevState.expanded === id ? null : id
        }));
    };

    handleShowPastSessions = () => {
        this.props.showPastSessions();
    };

    render() {
        const {expanded, agendaSessions} = this.state;
        const {
            event,
            eventId,
            myAgendaLoading,
            pastSessions,
            topNavigation,
            isLargeScreen,
            languages,
            translation,
            defaultTranslation
        } = this.props;

        return (
            <div className="my-agenda">
                {(myAgendaLoading && !topNavigation.isOpenedFromNotifications) && <Spinner/>}
                <div className="agenda-title-wrapper">
                    <p>
                        {translation?.program?.scheduledSessions ||
                        defaultTranslation?.program?.scheduledSessions}
                    </p>
                    <div>
                        <p>
                            {isLargeScreen
                            ?
                                translation?.program?.showPastSessions ||
                                defaultTranslation?.program?.showPastSessions
                            :
                                translation?.program?.pastSessions ||
                                defaultTranslation?.program?.pastSessions
                            }
                        </p>
                        <Switch
                            tabIndex="-1"
                            color="secondary"
                            name="showpastsessions"
                            onChange={this.handleShowPastSessions}
                            checked={pastSessions}
                        />
                    </div>
                </div>
                {agendaSessions?.length > 0
                ?
                    <div onDragStart={preventDefaultDrag} className="agenda-wrapper">
                        <div className="scroll-left-container">
                            <div>
                                {agendaSessions?.map((session, sessionIdx) => {
                                    if (session.type === 'meeting') {
                                        return (
                                            <SingleMeetingItem
                                                key={sessionIdx}
                                                meetingId={session}
                                                event={event}
                                                handleFilterMyAgenda={this.handleFilterMyAgenda}
                                                agendaSessions={agendaSessions}
                                                showAddToExternalCalendarDialog={this.props.showAddToExternalCalendarDialog}
                                        />
                                        );
                                    } else {
                                        return <SingleSmartSuggestion
                                            key={session._id}
                                            suggestion={session}
                                            event={event}
                                            eventId={eventId}
                                            expanded={expanded}
                                            toggleDescription={this.toggleExpandedSuggestion(session._id)}
                                            isLargeScreen={isLargeScreen}
                                            languages={languages}
                                            translation={translation}
                                            defaultTranslation={defaultTranslation}
                                            agenda
                                            removeSessionAgenda={this.props.removeSessionAgenda}
                                            showAddToExternalCalendarButton={true}
                                            showAddToExternalCalendarDialog={this.props.showAddToExternalCalendarDialog}                                        />
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                :
                    <div onDragStart={preventDefaultDrag} className="agenda-wrapper empty-data">
                        <div>
                            <EmptyIcon/>
                            <p>
                                {translation?.program?.emptyAgenda ||
                                defaultTranslation?.program?.emptyAgenda}
                            </p>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        event: state.event.data,
        myAgenda: state.event.myAgenda,
        myAgendaLoading: state.event.myAgendaLoading,
        pastSessions: state.event.pastSessions,
        topNavigation: state.user.topNavigation,
        isLargeScreen: state.layout.isLargeScreen,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        removeSessionAgenda: (sessionId) => dispatch(actions.removeSessionFromAgenda(sessionId)),
        showPastSessions: () => dispatch(actions.showPastSessionsAgenda()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAgenda);
