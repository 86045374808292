import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from 'react-redux';
import {preventDefaultDrag} from '../../Utils/utils';

class BoothDocuments extends React.Component {
    state = {};

    componentDidMount() {}

    render() {
        const {translation} = this.props;
        return (
            <Dialog
                open={this.props.opened}
                onClose={this.props.closeDialog}
                aria-labelledby="form-dialog-title"
                id="booth-files-dialog"
                className="booth-documents-dialog-container booth-dialog"
            >
                <DialogContent className="booth-dialog-content">
                    <DialogTitle id="form-dialog-title">{translation?.showfloor.boothFilesDialogTitle}</DialogTitle>
                    <div onDragStart={preventDefaultDrag} className="content-container">
                        <p onDragStart={preventDefaultDrag} className="subtitle">
                            {translation?.generalText.download}
                        </p>
                        {this.props.documents.map((document) => (
                            <p
                                onDragStart={preventDefaultDrag}
                                key={document._id}
                                onClick={this.props.onClickOnDocument(document._id, document.displayName)}
                            >
                                <a href={this.props.filesUrl + document.file} target="_blank" rel="noopener noreferrer">
                                    <span onDragStart={preventDefaultDrag} draggable="false" className="link">
                                        {document.displayName}
                                    </span>
                                </a>
                            </p>
                        ))}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button type="button" classes={{label: 'booth-cancel-button'}} onClick={this.props.closeDialog}>
                        {translation?.generalText.close}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
        defaultTranslation: state.languages.translations['en'],
        translation: state.languages.translations[state.languages.platformLanguage],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BoothDocuments);
