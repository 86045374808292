import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Routes from './HOC/Routes/Routes';
import './App.scss';
import {isIE, isYandex} from 'react-device-detect';
import PlatformNotAvailable from './SmallLayoutComponents/PlatformNotAvailable';
import * as actions from './store/actions';
import {connect} from 'react-redux';
import throttle from 'lodash/throttle';
import RingContainer from './Components/RingContainer/RingContainer';
import {preventDefaultDrag} from './Utils/utils';
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

class App extends Component {
    componentDidMount() {
        this._checkIfIsMobile();
        window.addEventListener('resize', this.handleResize);
        const query = new URLSearchParams(this.props.location.search);

        const externalId = query.get('externalId');
        const localExternalId = localStorage.getItem('externalId');
        const token = localStorage.getItem('token');

        let url_string = window.location.href;
        let url = new URL(url_string);

        let isOnlyParticipant = url_string.search('registerAsExhibitor=true' || 'registerAsScholar=true');

        if (isOnlyParticipant !== -1) {
            localStorage.removeItem('externalId');
        }

        if (externalId) {
            localStorage.setItem('externalId', externalId || url.searchParams.get('externalId'));
            localStorage.removeItem('intendedPage');
        } else {
            if (localExternalId && !externalId && !token) {
                localStorage.removeItem('externalId');
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = throttle(() => {
        this._checkIfIsMobile();
    }, 500);

    _checkIfIsMobile = () => {
        if (window.innerWidth <= 1024) {
            let vhc = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vhc}px`);
            if (window.innerWidth <= 767) {
                this.props.onSetIsMobile();
            } else {
                this.props.onSetIsTablet();
            }
        } else {
            this.props.onSetIsLargeScreen();
        }
    };

    render() {
        // set item in local storage to know where to redirect the user after successfull login as participant
        // we do this only if he is on a event page (lobby, auditorium, exhibitor etc.)
        const userToken = localStorage.getItem('token');
        const externalId = localStorage.getItem('externalId');
        const intendedPage = localStorage.getItem('intendedPage');

        if (
            window.location.pathname === `/event/${this.props.eventSlug}/` ||
            window.location.pathname === `/event/${this.props.eventSlug}`
        ) {
            localStorage.removeItem('intendedPage');
        }

        if (this.props.eventSlug) {
            if (intendedPage && !intendedPage.includes(this.props.eventSlug)) {
                localStorage.removeItem('intendedPage');
            }
        }

        if (externalId) {
            localStorage.removeItem('intendedPage');
        }
        if (!userToken) {
            localStorage.setItem('anyLogin', false);
        }

        if (this.props.location.pathname.includes('event/')) {
            localStorage.setItem('landingPage', this.props.location.pathname);
        } else {
            localStorage.setItem('landingPage', 'lobby');
        }
        if (isIE || isYandex) {
            return (
                <PlatformNotAvailable
                    message={`${process.env.REACT_APP_PLATFORM_NAME} is not currently optimized for the browser you are using. Please access the platform using Google Chrome, Firefox, Safari or Microsoft Edge.`}
                />
            );
        }

        return (
            <div onDragStart={preventDefaultDrag} className="App" id="app-container">
                <NotificationContainer style={{zIndex: 9999999}} />
                <Routes />
                <RingContainer />
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        eventSlug: state.event.eventSlug,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onSetIsMobile: () => dispatch(actions.setIsMobile()),
        onSetIsTablet: () => dispatch(actions.setIsTablet()),
        onSetIsLargeScreen: () => dispatch(actions.setIsLargeScreen()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
