import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Radio} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import colors from '../../CSS/_variables.module.scss';
import {preventDefaultDrag} from '../../Utils/utils';

const CustomRadio = withStyles({
    root: {
        color: '#666666',
        '&$checked': {
            color: colors.secondary,
        },
    },
    checked: {},
})((props) => <Radio {...props} />);

const SinglePollOption = ({
    option,
    handleSelect,
    userVoted,
    selectedOption,
    finished,
    voteResults,
    index,
    translation,
}) => {
    return (
        <div
            className={`poll_option ${option._id === userVoted ? '-is-checked' : ''}`}
            onClick={() => handleSelect(option._id)}
        >
            <div onDragStart={preventDefaultDrag} className="poll_option-container">
                <div>
                    <h1 className="poll_option-title">{option.text}</h1>
                    <p onDragStart={preventDefaultDrag} className="poll_option-text">
                        {voteResults &&
                            `${voteResults[index]}% ${translation?.polls?.pollVoted || 'participants voted'}`}
                    </p>
                </div>
                {!userVoted && !finished && (
                    <CustomRadio className="poll_option-icon" checked={option._id === selectedOption} />
                )}
                {option._id === userVoted && <CheckCircleIcon className="poll_option-icon -colored" />}
            </div>
            <div onDragStart={preventDefaultDrag} className="poll_option-progress-wrapper">
                <div
                    className="poll_option-progress"
                    style={{
                        transform: `translateX(-${100 - (voteResults ? voteResults[index] : 0)}%)`,
                    }}
                ></div>
            </div>
        </div>
    );
};

export default SinglePollOption;
