import React from "react";
import { connect } from "react-redux";
import { getVideoIdFromYoutubeUrl } from "../../../Utils/utils";
import "./LobbyVideo.scss";
import LobbyVideoFullscreen from "./LobbyVideoFullscreen.js";
import LobbyVideoEmbed from "./LobbyVideoEmbed";

class LobbyVideo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fullscreen: false,
			muteVideo: true,
			loaded: false,
			isVideoPlaying: true,
			progressTime: 0,
			thumbnailImage: "",
		};
		this.youtubePlayer = React.createRef();
	}

	componentDidMount() {
		document.addEventListener("keydown", this.escFunction, false);
		this.setState({ loaded: true });
		this.setThumbnailImage();
	}

	componentDidUpdate(prevProps) {
		// update thumbnail everytime the exhibitor changes the videoUrl
		if (prevProps.videoUrl !== this.props.videoUrl) {
			this.setState(
				{
					progressTime: 0,
				},
				() => {
					this.setThumbnailImage();
					this.youtubePlayer.current &&
						this.youtubePlayer.current.seekTo(0, "seconds");
				},
			);
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
	}

	handleVideoReady = () => {
		this.youtubePlayer.current.getInternalPlayer().setLoop(true);
	};

	escFunction = (e) => {
		if (e.key === "Escape") {
			this.setState({ fullscreen: false });
			this.setState({ muteVideo: true });
			this.setState({ isVideoPlaying: true });
		}
	};

	handleProgressChange = (e, value) => {
		e.preventDefault();
		this.youtubePlayer.current.seekTo(value / 100.5, "fraction");
	};

	handleProgressBar = (progress) => {
		this.setState({
			progressTime: parseFloat((progress?.played * 100).toFixed(1)),
		});
	};

	setThumbnailImage = () => {
		let videoId = getVideoIdFromYoutubeUrl(this.props.videoUrl || "");
		this.setState({
			thumbnailImage: `https://img.youtube.com/vi/${videoId}/0.jpg`,
		});
	};

	toggleMute = () => this.setState({ muteVideo: !this.state.muteVideo });

	enterFullscreen = () => {
		this.setState({ fullscreen: true });
		this.setState({ muteVideo: false });
		this.setState({ isVideoPlaying: true });
	};

	toggleRewind = () =>
		this.youtubePlayer.current.seekTo(
			Math.floor(this.youtubePlayer.current.getCurrentTime()) - 30,
			"seconds",
		);

	toggleForward = () =>
		this.youtubePlayer.current.seekTo(
			Math.floor(this.youtubePlayer.current.getCurrentTime()) + 30,
			"seconds",
		);

	togglePause = () =>
		this.setState({ isVideoPlaying: !this.state.isVideoPlaying }, () => {
			if (this.state.isVideoPlaying) {
				this.youtubePlayer.current.getInternalPlayer().playVideo();
			} else {
				this.youtubePlayer.current.getInternalPlayer().pauseVideo();
			}
		});

	exitFullscreen = () => {
		this.setState({ fullscreen: false });
		this.setState({ muteVideo: true });
		this.setState({ isVideoPlaying: true });
	};

	render() {
		const {
			fullscreen,
			loaded,
			muteVideo,
			isVideoPlaying,
			thumbnailImage,
			progressTime,
		} = this.state;
		const { classes, videoUrl, translation } = this.props;

		if (!loaded) return null;

		const videoProps = {
			videoUrl,
			thumbnailImage,
			classes,
			fullscreen,
			isVideoPlaying,
			muteVideo,
			progressTime,
			youtubePlayer: this.youtubePlayer,
			handleVideoReady: this.handleVideoReady,
			handleProgressBar: this.handleProgressBar,
			handleProgressChange: this.handleProgressChange,
			toggleRewind: this.toggleRewind,
			togglePause: this.togglePause,
			toggleForward: this.toggleForward,
			toggleMute: this.toggleMute,
		};
		return fullscreen ? (
			<LobbyVideoFullscreen
				{...videoProps}
				exitFullscreen={this.exitFullscreen}
				translation={translation}
			/>
		) : (
			<LobbyVideoEmbed {...videoProps} enterFullscreen={this.enterFullscreen} />
		);
	}
}

const mapStateToProps = (state) => {
	return {
		languages: state.languages,
		translation: state.languages.translations[state.languages.platformLanguage],
		defaultTranslation: state.languages.translations["en"],
	};
};

export default connect(mapStateToProps)(LobbyVideo);
