import React from 'react';
import Spinner from '../../SmallLayoutComponents/Spinner';
import FullPageSpinner from '../../SmallLayoutComponents/FullPageSpinner';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import SocialNetworkButtons from '../../Components/SocialNetworkButtons';
import RegisterDescriptionSectionMobile from './RegisterDescriptionSectionMobile';
import RegisterMenuLinks from '../../Components/RegisterMenuLinks';
import LoginEventForm from './LoginEventForm';
import {preventDefaultDrag} from '../../Utils/utils';

class LoginEvent extends React.Component {
    state = {
        brandingTranslation: null,
    };

    componentDidMount() {
        this.props.onSetEventPublicMenu();
        this.setLanguagesData();
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.eventLanguage !== this.props.languages.eventLanguage) {
            this.setLanguagesData();
        }
    }

    // if we had an error from server on login attempt we clear that error
    componentWillUnmount() {
        this.props.onClearError();
    }

    setLanguagesData = () => {
        const brandingTranslation = this.props.eventInfo.brandingTranslations.find(
            (translation) => translation.language === this.props.languages.eventLanguage
        );
        this.setState({
            brandingTranslation: brandingTranslation,
        });
    };

    render() {
        const {brandingTranslation} = this.state;
        let {
            eventInfo,
            loadingUser,
            loadingEvent,
            classes,
            isMobile,
            registerAsExhibitor,
            registerAsScholar,
            registerAsParticipant,
            translation,
            defaultTranslation,
        } = this.props;

        if (loadingEvent && !eventInfo) {
            return (
                <div onDragStart={preventDefaultDrag} className="login-page-spinner-container">
                    <FullPageSpinner />
                </div>
            );
        }
        let overlayColor = {};
        if (eventInfo.brandingData.overlayColorLogin) {
            overlayColor.background = eventInfo.brandingData.overlayColorLogin;
        }

        return (
            <div onDragStart={preventDefaultDrag} className={classes}>
                {isMobile && (
                    <RegisterDescriptionSectionMobile
                        registerAsExhibitor={registerAsExhibitor}
                        registerAsScholar={registerAsScholar}
                        registerAsParticipant={registerAsParticipant}
                        brandingTranslation={brandingTranslation}
                        translation={translation}
                        defaultTranslation={defaultTranslation}
                    />
                )}
                <div onDragStart={preventDefaultDrag} className="login-page">
                    {eventInfo ? (
                        <div onDragStart={preventDefaultDrag} className="login-container">
                            <div>
                                <div>
                                    <SocialNetworkButtons
                                        logInTab
                                        registerAsExhibitor={registerAsExhibitor}
                                        registerAsScholar={registerAsScholar}
                                        registerAsParticipant={registerAsParticipant}
                                    />
                                </div>
                                <LoginEventForm translation={translation} defaultTranslation={defaultTranslation} />
                            </div>
                            {(loadingUser || (loadingEvent && eventInfo)) && <Spinner />}
                            {isMobile && <RegisterMenuLinks />}
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        loadingUser: state.user.loading,
        user: state.user.data,
        eventInfo: state.event.eventInfo,
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        loadingEvent: state.event.loading,
        isMobile: state.layout.isMobile,
        languages: state.languages,
        translation: state.languages.translations[state.languages.eventLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onClearError: () => dispatch(actions.clearError()),
        onSetEventPublicMenu: () => dispatch(actions.setEventPublicMenu()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginEvent);
