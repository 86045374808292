import React from 'react';
import * as actions from '../../../store/actions';
import {connect} from 'react-redux';
import {
    checkBoothOwnerOrRepresentative,
    getParticipantData,
    getPrivateChatId,
    preventDefaultDrag
} from '../../../Utils/utils';
import OpenChatIcon from '../../../Images/svg/OpenChat';
import moment from "moment";
import axios from "../../../store/axios-instance";
import colors from '../../../CSS/_variables.module.scss';
class Representatives extends React.Component {
    // handleOpenPrivateChat = (userId) => (e) => {
    //     const privateChatId = getPrivateChatId(this.props.privateChats, userId);
    //
    //     if (privateChatId) {
    //         this.props.onOpenPrivateChat(privateChatId);
    //     } else {
    //         if (userId !== this.props.userLoggedIn._id && !this.props.loading) {
    //             this.props.onCreatePrivateChat(userId);
    //         }
    //     }
    // };

    handleOpenPrivateChat = (userId) => (e) => {
        const privateChatId = getPrivateChatId(this.props.privateChats, userId);

        if (privateChatId) {
            this.props.onOpenPrivateChat(privateChatId);
        } else {
            if (userId !== this.props.userLoggedIn._id && !this.props.loading) {
                this.props.onCreatePrivateChat(userId);
            }
        }

        // booth statistics
        const actionType = 'boothRepresentatives';
        const isRepresentative = checkBoothOwnerOrRepresentative(this.props.exhibitor, this.props.userLoggedIn._id);
        let trackBoothStatistics = !isRepresentative;

        const {event, userLoggedIn} = this.props;
        const {owner} = event;
        if (owner._id === userLoggedIn._id) {
            trackBoothStatistics = false;
            let findEventRoles = userLoggedIn.eventRoles.find((el) => el?.event?._id === event?._id);
            if (findEventRoles) {
                if (findEventRoles?.roles?.includes('participant')) {
                    trackBoothStatistics = true;
                }
            }
        }

        if (userId && trackBoothStatistics) {
            const {eventUsers} = this.props;
            const {allEventUsers} = eventUsers;
            let userData = getParticipantData(allEventUsers, userId);
            let fullName = '';
            if (userData) {
                fullName = `${userData.first} ${userData.last}`;
            }
            if (fullName !== '') {
                const data = {
                    userId: this.props.userLoggedIn._id,
                    representativeId: userId,
                    representativeName: fullName,
                    actionType: actionType,
                    timestamp: moment.utc(),
                };
                axios({
                    method: 'post',
                    url: '/exhibitor/' + this.props.exhibitor._id + '/booth-statistics',
                    data: data,
                })
                    .then((res) => {})
                    .catch((err) => {});
            }
        }
    };

    checkIfIsOnline = (eventUser) => {
        return this.props.onlineUsersObject[eventUser.user._id];
    };

    render() {
        const {exhibitor, exhibitorRepresentatives, userLoggedIn, translation, defaultTranslation} = this.props;

        return (
            <ul className="exhibitor-representatives">
                {exhibitorRepresentatives.map((representative, index) => {
                    const isOnline = this.checkIfIsOnline(representative);
                    return (
                        <li key={representative.user._id}>
                            <div
                                onDragStart={preventDefaultDrag}
                                className={'user-container ' + (isOnline ? 'is-online' : 'is-offline')}
                            >
                                <div
                                    className={`participant-avatar ${exhibitor.type === 'scholar' ? 'scholar' : ''}`}
                                    style={{
                                        backgroundImage: `url('${
                                            representative?.deskAvatarUrl
                                                ? representative?.deskAvatarUrl
                                                : `${process.env.REACT_APP_AVATAR_FOLDER}${representative?.user?.avatarSmall}`
                                        }')`,
                                    }}
                                />
                                <div onDragStart={preventDefaultDrag} className="user-details">
                                    <div>
                                        <div onDragStart={preventDefaultDrag} className="user-name">
                                            <p onDragStart={preventDefaultDrag} className="user-name-top">
                                                {representative.user.first} {representative.user.last}
                                            </p>
                                            {exhibitor.type === 'scholar' ? (
                                                <>
                                                    {index === 0 && representative.deskAvatar ? (
                                                        <p>
                                                            {translation?.exhibitorDashboard.avatarsTab.author.title ||
                                                                defaultTranslation?.exhibitorDashboard.avatarsTab.author
                                                                    .title}
                                                        </p>
                                                    ) : (
                                                        <p>
                                                            {translation?.exhibitorDashboard.avatarsTab.coAuthors
                                                                .singularTitle ||
                                                                defaultTranslation?.exhibitorDashboard.avatarsTab
                                                                    .coAuthors.singularTitle}
                                                        </p>
                                                    )}
                                                </>
                                            ) : (
                                                <p
                                                    onDragStart={preventDefaultDrag}
                                                    className={`${exhibitor.company ? '' : 'no-title'}`}
                                                >
                                                    {exhibitor.company} representative
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            className={
                                                'connection ' +
                                                (this.props.friendConnectionReceived ? null : 'no-action')
                                            }
                                        >
                                            {representative.user._id !== userLoggedIn._id && (
                                                <button
                                                    className="participant-button open-chat"
                                                    onClick={this.handleOpenPrivateChat(representative.user._id)}
                                                    disabled={this.props.loading}
                                                >
                                                    <div>
                                                        <OpenChatIcon
                                                            primary={colors.primary}
                                                            secondary={colors.primary}
                                                        />
                                                        <span>{defaultTranslation?.networking.chat}</span>
                                                    </div>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        onlineUsersObject: state.onlineUsers.onlineUsersObject,
        privateChats: state.user.data.privateChats,
        loading: state.user.loading,
        userLoggedIn: state.user.data,
        eventRoles: state.user.eventRoles,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        exhibitor: state.exhibitor.data,
        eventUsers: state.eventUsers,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onOpenPrivateChat: (privateChatId) => dispatch(actions.openPrivateChat(privateChatId)),
        onCreatePrivateChat: (targetUserId) => dispatch(actions.createPrivateChat(targetUserId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Representatives);
