import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import colors from '../../../CSS/_variables.module.scss';
import {ReactComponent as ArrowCollapse} from '../../../Images/svg/arrow-collapse.svg';
import {preventDefaultDrag} from '../../../Utils/utils';
import cloneDeep from 'lodash/cloneDeep';

class ShowfloorsList extends React.Component {
    componentDidMount() {
        const {eventId} = this.props;
        this.props.onGetShowfloors(eventId);
    }

    state = {
        isCollapsed: false,
    };

    handleToggleShowfloorsList = () => {
        this.setState((prevState) => ({
            isCollapsed: !prevState.isCollapsed,
        }));
    };


    sortShowfloors = () => {
        let orderedShowfloors = [];
        const sorted = cloneDeep(this.props.showfloors)?.sort(function (a, b) {
            return a.position - b.position;
        });
        orderedShowfloors = sorted;
        return orderedShowfloors;
    };

    render() {
        const {isCollapsed} = this.state;
        const {showfloors, activeShowfloorId, resourcesAccess, eventId, translation, defaultTranslation} = this.props;

        return (
            <div className={`showfloors-container ${isCollapsed ? 'collapsed' : ''}`} data-content={showfloors.length}>
                {showfloors.length > 5 ? (
                    <div
                        onDragStart={preventDefaultDrag}
                        className={`title-wrapper-collapsed ${isCollapsed ? 'collapsed' : ''}`}
                    >
                        <h4 className="title">
                            {translation?.showfloor.showfloorsTitle}
                            <span>
                                {showfloors.length}
                                {translation?.showfloor.showfloorsTitle.toLowerCase() ||
                                    defaultTranslation?.showfloor.showfloorsTitle.toLowerCase()}
                            </span>
                        </h4>
                        <div onDragStart={preventDefaultDrag} onClick={this.handleToggleShowfloorsList}>
                            <div>
                                <span onDragStart={preventDefaultDrag} draggable="false" className="see-list">
                                    {translation?.showfloor.seeFullList || defaultTranslation?.showfloor.seeFullList}
                                </span>
                                <span onDragStart={preventDefaultDrag} draggable="false" className="collapse-list">
                                    {translation?.showfloor.collapseList || defaultTranslation?.showfloor.collapseList}
                                </span>
                            </div>
                            <ArrowCollapse fill={colors.secondary} />
                        </div>
                    </div>
                ) : (
                    <h4 className="title">
                        {translation?.showfloor.showfloorsTitle || defaultTranslation?.showfloor.showfloorsTitle}
                    </h4>
                )}
                <div onDragStart={preventDefaultDrag} className="showfloors-list">
                    {this.sortShowfloors().map((showfloor) => {
                        const isActive = activeShowfloorId === showfloor._id;
                        let isRestricted = false;
                        if (resourcesAccess) {
                            isRestricted = !resourcesAccess?.showfloors.includes(showfloor._id);
                        }

                        return (
                            <div
                                className={`single-showfloor ${isActive ? 'active' : 'inactive'} ${
                                    isRestricted ? 'restricted' : ''
                                } ${showfloor.image ? 'with-image' : ''}`}
                                key={showfloor._id}
                            >
                                <div
                                    className="showfloor-container"
                                    onClick={this.props.handleSetActiveShowfloor(showfloor._id, isRestricted)}
                                >
                                    {isRestricted && (
                                        <LockOutlinedIcon color={'inherit'} className={'restricted-icon'} />
                                    )}
                                    {showfloor.image && (
                                        <div onDragStart={preventDefaultDrag} className="image-container">
                                            <img
                                                src={`${process.env.REACT_APP_EVENT_FOLDER}${eventId}/${showfloor.image}`}
                                                alt="logo"
                                            />
                                        </div>
                                    )}
                                    <div onDragStart={preventDefaultDrag} className="text-container">
                                        <p>{showfloor.name}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // event: state.event.data,
        eventId: state.event.eventId,
        languages: state.languages,
        showfloors: state.showfloors.showfloors,
        activeShowfloorId: state.showfloors.activeShowfloorId,
        resourcesAccess: state.user.resourcesAccess,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetActiveShowfloorId: (showfloorId) => dispatch(actions.setActiveShowfloorId(showfloorId)),
        onGetShowfloors: (eventId) => dispatch(actions.getShowfloors(eventId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowfloorsList);
