import React from 'react';
import {connect} from 'react-redux';
import colors from '../CSS/_variables.module.scss';
import EmptyBoxIcon from '../Images/svg/EmptyBoxIcon';
import {preventDefaultDrag} from '../Utils/utils';

class ShowfloorNoScholars extends React.Component {
    render() {
        const {isLargeScreen, translation, defaultTranslation} = this.props;
        let heightResolution = window.screen.height * window.devicePixelRatio;
        let V3boothFront;
        switch (true) {
            case heightResolution < 769:
                V3boothFront = `${process.env.REACT_APP_RENDERS_FOLDER}showfloor/boothFront-s1_768.jpg`;
                break;
            case heightResolution < 901:
                V3boothFront = `${process.env.REACT_APP_RENDERS_FOLDER}showfloor/boothFront-s1_900.jpg`;
                break;
            case heightResolution < 1081:
                V3boothFront = `${process.env.REACT_APP_RENDERS_FOLDER}showfloor/boothFront-s1_1080.jpg`;
                break;
            case heightResolution < 1201:
                V3boothFront = `${process.env.REACT_APP_RENDERS_FOLDER}showfloor/boothFront-s1_1200.jpg`;
                break;
            case heightResolution < 1441:
                V3boothFront = `${process.env.REACT_APP_RENDERS_FOLDER}showfloor/boothFront-s1_1440.jpg`;
                break;
            default:
                V3boothFront = `${process.env.REACT_APP_RENDERS_FOLDER}showfloor/boothFront-s1.jpg`;
        }

        return (
            <div onDragStart={preventDefaultDrag} className="no-exhibitors-wrapper">
                {isLargeScreen ? (
                    <>
                        <div onDragStart={preventDefaultDrag} className="booth-preview-container no-exhibitors">
                            <img
                                draggable="false"
                                onDragStart={preventDefaultDrag}
                                src={V3boothFront}
                                alt="Booth version 3"
                            />
                        </div>
                        <p onDragStart={preventDefaultDrag} className="no-exhibitors-text">
                            <span>
                                {translation?.showfloor.noPresentersTextFirst ||
                                    defaultTranslation?.showfloor.noPresentersTextFirst}
                            </span>
                            <span>
                                {translation?.showfloor.noExhibitorsTextSecond ||
                                    defaultTranslation?.showfloor.noExhibitorsTextSecond}
                            </span>
                        </p>
                    </>
                ) : (
                    <>
                        <EmptyBoxIcon
                            fillPrimary={colors.primary}
                            fillPrimaryDark={colors.primaryDark}
                            fillGreyLight={colors.lightGrey}
                            fillGrey={colors.grey}
                            fillShadow={colors.backgroundPages}
                        />
                        <p>{`${
                            translation?.showfloor.noPresentersTextFirst ||
                            defaultTranslation?.showfloor.noPresentersTextFirst
                        }. ${
                            translation?.showfloor.noExhibitorsTextSecond ||
                            defaultTranslation?.showfloor.noExhibitorsTextSecond
                        }`}</p>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLargeScreen: state.layout.isLargeScreen,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps)(ShowfloorNoScholars);
