import produce, { current } from 'immer'
import {
  GET_EVENT_FLOORS_REQUEST,
  GET_EVENT_FLOORS_SUCCESS,
  GET_EVENT_FLOORS_FAIL,
  GET_EVENT_FLOORS_RESET,
  ADD_EVENT_FLOOR_REQUEST,
  ADD_EVENT_FLOOR_SUCCESS,
  ADD_EVENT_FLOOR_FAIL,
  DELETE_EVENT_FLOOR_REQUEST,
  DELETE_EVENT_FLOOR_SUCCESS,
  DELETE_EVENT_FLOOR_FAIL,
  UPDATE_EVENT_FLOOR_REQUEST,
  UPDATE_EVENT_FLOOR_SUCCESS,
  UPDATE_EVENT_FLOOR_FAIL,
  UPDATE_EVENT_FLOOR_STATUS_REQUEST,
  UPDATE_EVENT_FLOOR_STATUS_SUCCESS,
  UPDATE_EVENT_FLOOR_STATUS_FAIL,
  UPDATE_EVENT_FLOOR_ORDER_REQUEST,
  UPDATE_EVENT_FLOOR_ORDER_SUCCESS,
  UPDATE_EVENT_FLOOR_ORDER_FAIL,
} from '../actions/actionTypes';

const initialState = {
  eventFloors: {},
  addedFloor: null,
  floorUpdated: null,
  deleteSuccess: false,
  successFloorUpdate: false,
  data: null,
  loading: false,
  error: false,
  getEventFloorsStatus: GET_EVENT_FLOORS_RESET,
}

export const eventFloorsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_EVENT_FLOORS_REQUEST:
        draft.loading = true;
        draft.getEventFloorsStatus = GET_EVENT_FLOORS_REQUEST;
        break;

      case GET_EVENT_FLOORS_SUCCESS:
        draft.loading = false;
        draft.eventFloors = action.payload;
        draft.getEventFloorsStatus = GET_EVENT_FLOORS_SUCCESS;
        break;

      case GET_EVENT_FLOORS_FAIL:
        draft.loading = false;
        draft.error = action.payload
        draft.getEventFloorsStatus = GET_EVENT_FLOORS_FAIL;
        break;

      case GET_EVENT_FLOORS_RESET:
        draft.getEventFloorsStatus = GET_EVENT_FLOORS_RESET;
        break;

      //add new floor
      case ADD_EVENT_FLOOR_REQUEST:
        draft.loading = true;
        break;

      case ADD_EVENT_FLOOR_SUCCESS:
        draft.loading = false;
        draft.addedFloor = action.payload.data.data;
        draft.floorUpdated = null;
        draft.eventFloors.data.push(action.payload.data.data)
        break;

      case ADD_EVENT_FLOOR_FAIL:
        draft.loading = false;
        draft.error = action.payload;
        break;

      // DELETE FLOOR
      case DELETE_EVENT_FLOOR_REQUEST:
        draft.loading = true;
        break;

      case DELETE_EVENT_FLOOR_SUCCESS:
        draft.loading = false;
        draft.deleteSuccess = true;
        draft.eventFloors.data = draft.eventFloors.data.filter((floor) => floor._id !== action.payload);
        break;

      case DELETE_EVENT_FLOOR_FAIL:
        draft.loading = false;
        draft.error = action.payload;
        break;

      //UPDATE FLOOR
      case UPDATE_EVENT_FLOOR_REQUEST:
        draft.loading = true;
        break;

      case UPDATE_EVENT_FLOOR_SUCCESS:
        const updatedUserState = draft.eventFloors.data.map((floor) => {
          return floor._id === action.payload.data._id ? action.payload.data : floor;
        });
        draft.loading = false;
        draft.successSpeakerUpdate = true;
        draft.floorUpdated = action.payload.data;
        draft.addedFloor = null;
        draft.eventFloors.data = updatedUserState;
        break;

      case UPDATE_EVENT_FLOOR_FAIL:
        draft.loading = false;
        draft.error = action.payload;
        break;

      case UPDATE_EVENT_FLOOR_STATUS_REQUEST:
        draft.loading = true;
        break;

      case UPDATE_EVENT_FLOOR_STATUS_SUCCESS: {
        const updatedUserState = draft.eventFloors.data.map((floor) => {
          return floor._id === action.payload.data._id ? action.payload.data : floor;
        });

        const updatedIndex = draft.eventFloors.data.find(floor => floor._id === action.payload.data._id);

        draft.loading = false;
        draft.successSpeakerUpdate = true;
        draft.floorUpdated = action.payload.data;
        draft.addedFloor = null;
        draft.eventFloors.data[updatedIndex] = action.payload.data;
        break;

      }
      case UPDATE_EVENT_FLOOR_STATUS_FAIL:
        draft.loading = false;
        draft.error = action.payload;
        break;

      /* case UPDATE_EVENT_FLOOR_ORDER_REQUEST:
        draft.loading = true;
        break;

      case UPDATE_EVENT_FLOOR_ORDER_SUCCESS: {
        const updatedUserState = draft.eventFloors.data.map((floor) => {
          return floor._id === action.payload.data._id ? action.payload.data : floor;
        });
        draft.loading = false;
        draft.successSpeakerUpdate = true;
        draft.floorUpdated = action.payload.data;
        draft.addedFloor = null;
        draft.eventFloors.data = updatedUserState;
        break;

      }
      case UPDATE_EVENT_FLOOR_ORDER_FAIL:
        draft.loading = false;
        draft.error = action.payload;
        break; */

      default:
        break;
    }
  })