import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {preventDefaultDrag} from '../Utils/utils';
import '../CSS/headerMenu.scss';
import {ReactComponent as LogoImage} from '../Images/svg/logo.svg';
import {ReactComponent as LogoutIcon} from "../Images/svg/log-out.svg";
import colors from "../CSS/_variables.module.scss";
import ConfirmDialog from "../Dialogs/Confirm";
import * as actions from "../store/actions";
import axios from '../store/axios-instance';


class SessionGuestMenu extends React.Component {
    state = {
        confirmLeaveDialog: false
    };

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
    }

    refreshPage = () => window.location.reload(true);

    showConfirmLeaveDialog = () => {
        this.setState({
            confirmLeaveDialog: true
        })
    }

    closeDialog = () => {
        this.setState({
            confirmLeaveDialog: false
        })
    }

    leaveSession = () => {
        // api call to set the user as kicked (himself)
        axios.delete('/sessions/leave-session');

        localStorage.removeItem('sessionToken');
        this.props.getLiveSessionSuccess(null);
        const redirectLink = window.location.href.split('/live-session')[0];
        window.location.href = redirectLink;
    }

    render() {
        const {confirmLeaveDialog} = this.state;
        const {
            translation,
            serverDown,
            sessionExpired,
        } = this.props;

        return (
            <div
                onDragStart={preventDefaultDrag}
                className={`top-container`}
            >
                {serverDown && (
                    <div onDragStart={preventDefaultDrag} className="server-down-info-container">
                        <p>
                            {translation?.eventMenu.connectionProblemTextFirst}
                            <span onDragStart={preventDefaultDrag} draggable="false" onClick={this.refreshPage}>
                                {translation?.eventMenu.connectionProblemButton}
                            </span>
                            {translation?.eventMenu.connectionProblemTextSecond}
                        </p>
                    </div>
                )}
                {sessionExpired && (
                    <div onDragStart={preventDefaultDrag} className="server-down-info-container">
                        <p>
                            {translation?.eventMenu.sessionProblemTextFirst}
                            <span onDragStart={preventDefaultDrag} draggable="false" onClick={this.refreshPage}>
                                {translation?.eventMenu.sessionProblemButton}
                            </span>
                            {translation?.eventMenu.sessionProblemTextSecond}
                        </p>
                    </div>
                )}
                <header id="header" className="header">
                    <div onDragStart={preventDefaultDrag} className="main-menu-container">
                        <div onDragStart={preventDefaultDrag} className="actions-container">
                            <div onDragStart={preventDefaultDrag} className="left-header-wrapper">
                                <LogoImage />
                            </div>
                            <div className="main-header-wrapper"></div>
                            <div className="right-header-wrapper">
                                <div className="leave-session-button" onClick={this.showConfirmLeaveDialog}>
                                    <LogoutIcon fill={colors.grey} width="24" height="24" />{' '}
                                    <p>LEAVE SESSION</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {confirmLeaveDialog && (
                    <ConfirmDialog
                        open={confirmLeaveDialog}
                        closeConfirm={this.closeDialog}
                        dialogTitle={'Leave Session'}
                        dialogDescription={'Are you sure you want to leave this Session?'}
                        handleConfirm={this.leaveSession}
                        buttonColor={'primary'}
                        dialogConfirmButtonLabel={'Leave'}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        topNavigation: state.user.topNavigation,
        serverDown: state.user.serverDown,
        sessionExpired: state.user.sessionExpired,
        translation: state.languages.translations['en'],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLiveSessionSuccess: (session) => dispatch(actions.getLiveSessionSuccess(session)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SessionGuestMenu));
