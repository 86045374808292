import React, {Component} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {startCapture} from "../../Utils/videoConference";
import {
    StopScreenShare as StopScreenShareIcon,
    ScreenShare as ScreenShareIcon,
    Mic as MicIcon,
    MicOff as MicOffIcon,
    Videocam as VideocamIcon,
    VideocamOff as VideocamOffIcon,
} from '@material-ui/icons';
import {Tooltip} from "@material-ui/core";
import { ReactComponent as PresentToAll } from '../../Images/svg/presentToAll.svg';


class PersonalStreamControls extends Component {

    componentDidMount() {
    }

    componentWillUnmount() {
        // whenever the user leaves the page, make sure you stop his stream and also his playback
        if (this.props.userMediaStreamCapture) {
            this.stopSelfCapture();
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        // a moderator disabled the speaker ability to stream video
        // and he has video streaming
        // DOESN'T WORK BECAUSE this.props.liveSessionUser.video is already false when I get the allowVideo notification
        if (prevProps.liveSessionUser.allowVideo === true && !this.props.liveSessionUser.allowVideo && this.props.liveSessionUser.video) {
            this.toggleVideo();
        }

        // a moderator disabled the speaker ability to stream audio
        // and he has audio streaming
        // DOESN'T WORK BECAUSE this.props.liveSessionUser.audio is already false when I get the allowAudio notification
        if (prevProps.liveSessionUser.allowAudio === true && !this.props.liveSessionUser.allowAudio && this.props.liveSessionUser.audio) {
            this.toggleAudio();
        }

        // a moderator disabled the speaker ability to stream screenShare
        // and he has screenShare streaming
        // DOESN'T WORK BECAUSE this.props.liveSessionUser.screenSharing is already false when I get the allowScreenSharing notification
        if (prevProps.liveSessionUser.allowScreenSharing === true && !this.props.liveSessionUser.allowScreenSharing && this.props.liveSessionUser.screenSharing) {
            this.toggleScreenShare();
        }

        if(prevProps.userMediaStreamSelectedAudioDeviceId !== this.props.userMediaStreamSelectedAudioDeviceId){
            this.startAudio()
        }

        if(prevProps.userMediaStreamSelectedVideoDeviceId !== this.props.userMediaStreamSelectedVideoDeviceId){
            this.startVideo()
        }
    }


    startSelfCapture = async (kinds, isScreen= false) => {
        const {liveSessionUser, userMediaStreamCapture, userMediaStreamSelectedAudioDeviceId, userMediaStreamSelectedVideoDeviceId} = this.props;

        try {
            let constraints = {};

            if(kinds.includes('video') && !isScreen){
                constraints.video = true;
            }
            if(kinds.includes('audio')){
                constraints.audio = true;
            }
            //const stream = await navigator.mediaDevices.getUserMedia(constraints);
        } catch (e) {
            if(e.message === 'Permission denied'){
                alert(`Your ${kinds.includes('video') ? 'camera' : 'microphone'} is blocked by the browser. You can update the ${kinds.includes('video') ? 'camera' : 'microphone'} access from the browser settings`)
            }
        }

        if (liveSessionUser){
            // capure + stream my webcam video data
            const selfSessionCapture = await startCapture(liveSessionUser.userId, kinds, isScreen, userMediaStreamCapture, userMediaStreamSelectedAudioDeviceId, userMediaStreamSelectedVideoDeviceId);

            // listen for the event where the user stops the "Share screen" from the browser button
            // only listen to the close videoStream if is the screenSharing type
            if(isScreen && selfSessionCapture){
                selfSessionCapture.mediaStream.getVideoTracks()[0].onended = this.toggleScreenShare;
            }

            this.props.setUserMediaStreamCapture(selfSessionCapture);
            return selfSessionCapture;
        }

        return null
    }

    stopSelfCapture = async () => {
        if(this.props.userMediaStreamCapture){
            const selfSessionCaptureClosed = await this.props.userMediaStreamCapture.close();
            this.props.setUserMediaStreamCapture(null);
        }
    }


    toggleVideo = e => {
        const {liveSessionUser} = this.props;

        if (!liveSessionUser.video){
            // we will always have video enabled (the user toggled it to true)
            this.startVideo();
        } else {
            this.stopVideo();
        }
    }

    startVideo = () => {
        const {liveSession, liveSessionUser} = this.props;
        const updatedKinds = ['audio', 'video'];

        this.startSelfCapture(updatedKinds, liveSessionUser.screenSharing)
            .then((result)=>{
                // if the user didn't have video to true make the api call to also update in the backend
                // this check is because you can also trigger startVideo from changing the input video deviceId
                if(result && !liveSessionUser.video) {
                    const streamType = 'enable-video';
                    this.props.changeLiveSessionUserStream(liveSession._id, streamType, liveSessionUser.userId);
                }
            })
            .catch(err => {console.log(err)})
    }

    stopVideo = () => {
        const {liveSession, liveSessionUser, userMediaStreamCapture} = this.props;

        const streamType = 'disable-video';
        this.props.changeLiveSessionUserStream(liveSession._id, streamType, liveSessionUser.userId);

        userMediaStreamCapture?.mediaStream.getVideoTracks().forEach((track) => {
            track.stop();
            userMediaStreamCapture.removeTrack(track);
        });
    }

    toggleAudio = e => {
        // if we don't want to always stop the stream on audio, we should go for enableTrack implementation
        // capture?.mediaStream.getAudioTracks().forEach((track) => (track.enabled = !soundEnabled));
        const {liveSessionUser} = this.props;
        if (!liveSessionUser.audio){
            this.startAudio();
        } else {
            this.stopAudio();
        }
    }

    startAudio = () => {
        const {liveSession, liveSessionUser, userMediaStreamCapture} = this.props;
        const updatedKinds = ['audio'];
        if(liveSessionUser.video || liveSessionUser.screenSharing){updatedKinds.push('video')};

        if(userMediaStreamCapture?.mediaStream.getAudioTracks().length){
            userMediaStreamCapture.mediaStream.getAudioTracks().forEach((track) => {
                // track.stop();
                // selfSessionCapture.removeTrack(track);
                track.enabled = true;
            });
            const streamType = 'unmute';
            this.props.changeLiveSessionUserStream(liveSession._id, streamType, liveSessionUser.userId);
        } else {
            this.startSelfCapture(updatedKinds, liveSessionUser.screenSharing).then((result) => {
                // if the user didn't have video to true make the api call to also update in the backend
                // this check is because you can also trigger startVideo from changing the input video deviceId
                if (result && !liveSessionUser.audio) {
                    const streamType = 'unmute';
                    this.props.changeLiveSessionUserStream(liveSession._id, streamType, liveSessionUser.userId);
                }
            })
        }
    }

    stopAudio = () => {
        const {liveSession, liveSessionUser, userMediaStreamCapture} = this.props;
        const streamType = 'mute';
        this.props.changeLiveSessionUserStream(liveSession._id, streamType, liveSessionUser.userId);

        const updatedKinds = ['audio'];
        if(liveSessionUser.video || liveSessionUser.screenSharing){updatedKinds.push('video')}

        userMediaStreamCapture?.mediaStream.getAudioTracks().forEach((track) => {
            track.enabled = false;
        });
    }

    toggleScreenShare = e => {
        const {liveSession, liveSessionUser, userMediaStreamCapture} = this.props;

        if (!liveSessionUser.screenSharing){
            // we will always have isScreen enabled (the user toggled it to true)
            const updatedKinds = ['video', 'audio'];

            this.startSelfCapture(updatedKinds, true)
                .then((result)=>{
                    if(result){
                        const streamType = 'enable-sharing';
                        this.props.changeLiveSessionUserStream(liveSession._id, streamType, liveSessionUser.userId);
                    }
                })
        } else {
            const streamType = 'disable-sharing';
            this.props.changeLiveSessionUserStream(liveSession._id, streamType, liveSessionUser.userId);

            userMediaStreamCapture?.mediaStream.getVideoTracks().forEach((track) => {
                track.stop();
                userMediaStreamCapture.removeTrack(track);
            });
        }
    }

    checkAnotherUserIsScreenSharing = () => {
        const{liveSessionActiveUsers, liveSessionUser} = this.props;
        const anotherUserIsScreenSharing = liveSessionActiveUsers?.some(user => user.screenSharing && user.userId !== liveSessionUser.userId);
        return anotherUserIsScreenSharing

    }

    render() {
        const {liveSessionUser, translation, defaultTranslation, controlsLocation} = this.props;
        const anotherUserIsScreenSharing = this.checkAnotherUserIsScreenSharing();

        return (
            <div className={`personal-stream-controls`}>
                <button className={`button audio ${liveSessionUser.audio ? 'is-sharing' : ''}`}
                     onClick={this.toggleAudio}
                     // disabled={!liveSessionUser.allowAudio || !liveSessionUser.isReady}
                     disabled={!liveSessionUser.allowAudio}
                >
                    {liveSessionUser.audio
                        ? <div>
                            <MicIcon/>
                            <span>
                                {translation?.videoPlayer.tooltipMute || defaultTranslation?.videoPlayer.tooltipMute}
                            </span>
                        </div>
                        : <Tooltip title={`${!liveSessionUser.allowAudio 
                            ? `${translation?.sessions.disabledByModerator || defaultTranslation?.sessions.disabledByModerator}`
                            : ``}`}
                        >
                            <div>
                                <MicOffIcon/>
                                <span>
                                    {translation?.videoPlayer.tooltipUnmute || defaultTranslation?.videoPlayer.tooltipUnmute}
                                </span>
                            </div>
                        </Tooltip>
                    }
                </button>
                <button className={`button video ${(liveSessionUser.video && !liveSessionUser.screenSharing) ? 'is-sharing' : ''}`}
                        onClick={this.toggleVideo}
                        // disabled={!liveSessionUser.allowVideo || liveSessionUser.screenSharing || !liveSessionUser.isReady}
                        disabled={!liveSessionUser.allowVideo || liveSessionUser.screenSharing}
                >
                    {(liveSessionUser.video && !liveSessionUser.screenSharing)
                        ? <div>
                            <VideocamIcon/>
                            <span>
                                {translation?.sessions.stopVideo || defaultTranslation?.sessions.stopVideo}
                            </span>
                        </div>
                        : <Tooltip title={`${liveSessionUser.screenSharing ? 
                            `${translation?.sessions.disabledScreenSharing || defaultTranslation?.sessions.disabledScreenSharing}`
                            : `${!liveSessionUser.allowVideo ? `${translation?.sessions.disabledByModerator || defaultTranslation?.sessions.disabledByModerator}`
                                : ``}`}`}
                        >
                            <div>
                                <VideocamOffIcon/>
                                <span>
                                    {translation?.sessions.startVideo || defaultTranslation?.sessions.startVideo}
                                </span>
                            </div>
                        </Tooltip>
                    }
                </button>
                <button className={`button screen-share ${liveSessionUser.screenSharing ? 'is-sharing' : ''}`}
                        onClick={this.toggleScreenShare}
                        // disabled={!liveSessionUser.allowScreenSharing || liveSessionUser.video || anotherUserIsScreenSharing || !liveSessionUser.isReady}
                        disabled={!liveSessionUser.allowScreenSharing || liveSessionUser.video || anotherUserIsScreenSharing}
                >
                    {(liveSessionUser.screenSharing && !liveSessionUser.video)
                        ? <div>
                            {controlsLocation === "auditorium"
                                ?<PresentToAll/>
                                :<ScreenShareIcon/>
                            }
                            <span>
                                {translation?.sessions.stopShareScreen || defaultTranslation?.sessions.stopShareScreen}
                            </span>
                        </div>
                        : <Tooltip title={`${liveSessionUser.video ? 
                            `${translation?.sessions.disabledVideoSharing || defaultTranslation?.sessions.disabledVideoSharing}`
                            : `${!liveSessionUser.allowScreenSharing ?
                                `${translation?.sessions.disabledByModerator || defaultTranslation?.sessions.disabledByModerator}`
                                : `${anotherUserIsScreenSharing ? 
                                    `${translation?.sessions.anotherUserIsScreenSharing || defaultTranslation?.sessions.anotherUserIsScreenSharing}` 
                                    : `${!liveSessionUser.isReady ? `${translation?.sessions.disabledUntilReady || defaultTranslation?.sessions.disabledUntilReady}` : ''}`}`}`}`}>
                            <div>
                                {controlsLocation === "auditorium"
                                    ?<PresentToAll/>
                                    :<StopScreenShareIcon/>
                                }
                                <span>
                                    {translation?.sessions.shareScreen || defaultTranslation?.sessions.shareScreen}
                                </span>
                            </div>
                        </Tooltip>
                    }
                </button>
            </div>

        )
    }
}


const mapStateToProps = (state) => {
    return {
        liveSession: state.liveSession.liveSession,
        liveSessionUser: state.liveSession.liveSessionUser,
        liveSessionActiveUsers: state.liveSession.liveSessionActiveUsers,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        userMediaStreamCapture: state.userMediaStream.capture,
        userMediaStreamSelectedAudioDeviceId: state.userMediaStream.selectedAudioDeviceId,
        userMediaStreamSelectedVideoDeviceId: state.userMediaStream.selectedVideoDeviceId,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeLiveSessionUserStream: (sessionId, permissionType, userId) => dispatch(actions.changeLiveSessionUserStream(sessionId, permissionType, userId)),
        setUserMediaStreamCapture: (capture) => dispatch(actions.setUserMediaStreamCapture(capture)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalStreamControls)

