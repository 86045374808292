import React from 'react';
import {preventDefaultDrag} from '../../Utils/utils';
import NextVideoTimer from './NextVideoTimer';

class AuditoriumNextVideoTimer extends React.Component {
    render() {
        const {
            timeSlot,
            translation,
            auditoriumTimeslots,
            currentTimeSlotIndex,
            auditoriumProgramHasEnded,
            auditoriumHasVideos,
            totalSecondsTillNextVideo,
            nextTimeslot,
            currentAuditorium,
        } = this.props;

        // we need to check if nextTimeslot has a match in the displayProgram array
        const getDisplayProgramTimeslotMatch = () => {
            if (!nextTimeslot) return null;

            let displayProgramTimeslotMatch = null;
            let matchFound = false;

            currentAuditorium.displayProgram.forEach((displayProgram) => {
                if (!matchFound) {
                    displayProgram?.program?.forEach((displayProgramTimeslot) => {
                        if (!matchFound && displayProgramTimeslot?.auditoriumProgram?.timeslotId === nextTimeslot._id) {
                            displayProgramTimeslotMatch = displayProgramTimeslot;
                            matchFound = true;
                        }
                    });
                }
            });

            return displayProgramTimeslotMatch;
        };

        const isLastTimeSlot = currentTimeSlotIndex === auditoriumTimeslots.length - 1;

        return (
            <div onDragStart={preventDefaultDrag} className="next-video">
                <div onDragStart={preventDefaultDrag} className="next-video-container">
                    {timeSlot?.imageUrl ? (
                        <div onDragStart={preventDefaultDrag} className="timeslot-background-image">
                            <img
                                draggable="false"
                                onDragStart={preventDefaultDrag}
                                src={timeSlot.imageUrl}
                                alt="current program"
                            />
                        </div>
                    ) : (
                        <>
                            {auditoriumProgramHasEnded ? (
                                <p onDragStart={preventDefaultDrag} className="auditorium-program-ended-text">
                                    {translation?.auditorium.noVideos}
                                </p>
                            ) : (
                                <>
                                    {auditoriumTimeslots.length > 0 && auditoriumHasVideos ? (
                                        <p className={'next-video-timer'}>
                                            {isLastTimeSlot ? (
                                                <span> {translation?.auditorium.noVideos}</span>
                                            ) : (
                                                <NextVideoTimer
                                                    totalSecondsTillNextVideo={totalSecondsTillNextVideo}
                                                    loadCurrentTimeSlot={this.props.loadCurrentTimeSlot}
                                                    displayProgramTimeslotMatch={getDisplayProgramTimeslotMatch()}
                                                />
                                            )}
                                        </p>
                                    ) : (
                                        <p>
                                            <span>{translation?.auditorium.noVideos}</span>
                                        </p>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default AuditoriumNextVideoTimer;
