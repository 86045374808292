import React, {PureComponent} from 'react';
import Button from '@material-ui/core/Button';
import DeleteMyAccount from '../../Dialogs/MyAccount/DeleteMyAccount';
import {IconTrash} from '../../Components/Icons';

class DeleteAccount extends PureComponent {
    state = {
        deleteMyAccountDialog: false,
    };

    handleDeleteUser = () => {
        this.setState({deleteMyAccountDialog: true});
    };

    handleCloseDialog = () => {
        this.setState({deleteMyAccountDialog: false});
    };

    render() {
        const {deleteMyAccountDialog} = this.state;
        const {translation, defaultTranslation} = this.props;
        return (
            <>
                <Button
                    onClick={this.handleDeleteUser}
                    type="button"
                    className="change-password-btn"
                >
                    <IconTrash/>
                    <span>
                        {translation?.myAccountPage.deleteAccount ||
                        defaultTranslation?.myAccountPage.deleteAccount}
                    </span>
                </Button>
                {this.state.deleteMyAccountDialog && (
                    <DeleteMyAccount opened={deleteMyAccountDialog} closeDialog={this.handleCloseDialog} />
                )}
            </>
        );
    }
}

export default DeleteAccount;
