import React, {Component} from 'react';
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import axios from '../../store/axios-instance';


import {
    connectToSession,
    disconnectFromSession,
    sessionUserCreated,
    sessionUserApproved,
    sessionUserKicked
} from '../../Api/socketApi'
import {Button, Dialog, DialogActions, DialogContent} from "@material-ui/core";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Spinner from "../../SmallLayoutComponents/Spinner";
import {Link, withRouter} from 'react-router-dom';
// import CookieBanner from "../../SmallLayoutComponents/CookieBanner";
import {isMobile} from "react-device-detect";
import PlatformNotAvailable from '../../SmallLayoutComponents/PlatformNotAvailable';
import {preventDefaultDrag} from '../../Utils/utils';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InfoIcon from '@material-ui/icons/Info';

class LiveSessionRegistration extends Component {
    state={
        name: '',
        eventSlug: this.props.match.params.eventSlug,
        sessionId: this.props.match.params.sessionId,
        role: this.props.match.params.role,
        waitingApproval: false,
        userDeniedAccess: false,
        agreeToTermsPlatform: false,
        agreeToTermsEvent: false,
    };

    componentDidMount() {
        const {sessionId, eventSlug} = this.state;

        this.props.setNoMenu();

        // if the user was guest to another Session, make sure we remove his old Token for this new one
        // also, if he is a participant or something like that, "logout him"
        localStorage.removeItem('token');
        localStorage.removeItem('sessionToken');

        sessionUserCreated((data) => {

        });

        // when the organizer approves a user, check if the current one was the one approved and
        // redirect him to liveSession dashboard
        sessionUserApproved((data) => {
            // if the current user was approved, we can redirect him to the liveSessionDashboard
            if(this.props.liveSessionUser.userId === data.userId){
                const redirectLink = `${window.location.origin}/event/${eventSlug}/live-session/${sessionId}`;
                window.location.href = redirectLink;
                // this.props.history.push(`/event/${eventSlug}/live-session/${sessionId}`)
            }
        });

        sessionUserKicked((data) => {
            // if the current user was denied access,
            // we can show him an overlay where we inform that he as not approved
            if(this.props.liveSessionUser.userId === data.userId){
                this.setState({
                    userDeniedAccess: true,
                    waitingApproval: false,
                })
            }
        });
    }

    componentWillUnmount() {
        const {sessionId} = this.state;
        disconnectFromSession(sessionId);
    }

    onChangeName = e => {
        this.setState({
            name: e.target.value
        })
    };

    handleCheckbox = (name) => (event) => {
        this.setState({
            [name]: event.target.checked,
        });
    };

    handleRegister = () => {
        const {sessionId, role, name} = this.state;

        const payload = {
            name: name,
            role: role,
            isGuest: false,
            isReady: true
        };

        if (!localStorage.getItem('sessionToken')) {
            axios
                .post(`/sessions/${sessionId}/register`, payload)
                .then((response) => {
                    const sessionUser = response.data.user;
                    const sessionToken = response.data.sessionToken;

                    // because we redirect the user using reactRouter,
                    // we must also update the axiosSession authorization token
                    // because it will not be triggered automatically because the app.js and React has allready been loaded / mounted
                    // COMMENTED OUT BECAUSE I MOVED ALL THE LOGIC DIRECT TO axios-instance.js
                    // axiosSession.defaults.headers.common['Authorization'] = 'Bearer ' + sessionToken;


                    localStorage.setItem('sessionToken', sessionToken);

                    connectToSession(sessionId, sessionToken);

                    this.props.getLiveSessionUserSuccess(sessionUser);

                    // we added the registration Request
                    // we need to wait to be approved to enter the sessionDashboard
                    this.setState({
                        waitingApproval: true
                    })

                })
        }
    };

    handleRedirect = () => {
        const {eventSlug} = this.state;
        // redirect the user to the eventHomepage
        const redirectLink = `${window.location.origin}/event/${eventSlug}`;
        window.location.href = redirectLink;
    }

    render() {
        const {role, name, eventSlug, waitingApproval, userDeniedAccess, agreeToTermsPlatform, agreeToTermsEvent} = this.state;

        // display a message if the user is trying to access the sessionDashboard from mobile
        if (isMobile) {
            return <PlatformNotAvailable
                classes={'private-page'}
                message={`The ${process.env.REACT_APP_PLATFORM_NAME} Live Session Backstage is not currently optimized for the device you are using. Please access the Live Session Backstage from a Computer`}/>
        }

        if(userDeniedAccess){
            return (
                <Dialog open={true}>
                    <DialogContent>
                        <p>You're access has been denied.</p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleRedirect}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
        )}
        return (
            <div>
                <Dialog open={true}>
                    <ValidatorForm onSubmit={this.handleRegister}>
                        <DialogContent className="session-register">
                            <h3>Join as a <span>{role}</span></h3>
                            <p>
                                You’ve been invited as a {role} to session. Input your name (it will be visible to session’s
                                participants).
                            </p>
                            <p className="info-text">
                                <InfoIcon classes={{root: 'primary-color'}} />
                                <span>This session is being recorded. By entering this session you agree on the processing of your personal data</span>
                            </p>
                            <TextValidator
                                className="setting-input"
                                type="text"
                                fullWidth={true}
                                margin="normal"
                                variant="outlined"
                                placeholder="Your name"
                                onChange={this.onChangeName}
                                value={name}
                            />
                            <div onDragStart={preventDefaultDrag} className="agree-terms">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            classes={{checked: 'primary-color'}}
                                            checked={agreeToTermsPlatform}
                                            onChange={this.handleCheckbox('agreeToTermsPlatform')}
                                            value="agreeToTermsPlatform"
                                        />
                                    }
                                    label={
                                        <span onDragStart={preventDefaultDrag} draggable="false" className="agree-terms-message">
                                            By proceeding, you agree to our
                                            <Link
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                to={{pathname: '/terms-and-conditions/'}}
                                            >
                                                <span> Platform Terms of Use </span>
                                            </Link>
                                            and confirm you have read our
                                            <Link target="_blank" rel="noopener noreferrer" to={{pathname: '/privacy-policy/'}}>
                                                <span> Privacy Policy</span>
                                            </Link>
                                            .
                                        </span>
                                    }
                                />
                            </div>
                            <div onDragStart={preventDefaultDrag} className="agree-terms">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            classes={{checked: 'primary-color'}}
                                            checked={agreeToTermsEvent}
                                            onChange={this.handleCheckbox('agreeToTermsEvent')}
                                            value="agreeToTermsEvent"
                                        />
                                    }
                                    label={
                                        <span onDragStart={preventDefaultDrag} draggable="false" className="agree-terms-message">
                                            By proceeding, you agree to our
                                            <Link
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                to={{
                                                    pathname: `/event/${eventSlug}/privacy-policy/`,
                                                    search: `?lang=en`,
                                                }}
                                            >
                                                <span> Event Privacy Policy </span>
                                            </Link>
                                            and
                                            <Link
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                to={{
                                                    pathname: `/event/${eventSlug}/terms-and-conditions/`,
                                                    search: `?lang=en`,
                                                }}
                                            >
                                                <span> Event Terms and Conditions</span>
                                            </Link>
                                            .
                                        </span>
                                    }
                                />
                            </div>
                            <DialogActions>
                                <button
                                    className="primary-button"
                                    disabled={name.length < 2 || name.length > 80 || !agreeToTermsPlatform || !agreeToTermsEvent}
                                    type={'submit'}
                                >
                                    Enter
                                </button>
                            </DialogActions>
                        </DialogContent>
                    </ValidatorForm>
                </Dialog>
                {waitingApproval && <Spinner message={`Please wait for approval...`}/>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        liveSession: state.liveSession.liveSession,
        liveSessionUser: state.liveSession.liveSessionUser
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLiveSessionUserSuccess: (user) => dispatch(actions.getLiveSessionUserSuccess(user)),
        setNoMenu: () => dispatch(actions.setNoMenu()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LiveSessionRegistration));