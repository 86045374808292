import React from 'react';
import DownloadScannedContacts from './DownloadScannedContacts';
import ColoredScrollbars from '../../../SmallLayoutComponents/ColoredScrollbars';
import SingleUserContact from '../../../HOC/SingleUserContact';
import Spinner from '../../../SmallLayoutComponents/Spinner';
import CloseTopNavButton from '../../../SmallLayoutComponents/CloseTopNavButton';
import './Contacts.scss';
import { connect } from 'react-redux';
import colors from '../../../CSS/_variables.module.scss';
import { ReactComponent as ContactsGreenIcon } from '../../../Images/svg/phone-book.svg';
import { preventDefaultDrag } from '../../../Utils/utils';
import * as actions from '../../../store/actions';
import axios from '../../../store/axios-instance';

class Contacts extends React.Component {
	constructor(props) {
		super(props);

		const { user } = this.props;
		let pendingRequestsArray = user.receivedRequests.filter(
			(request) => request.status === 'pending'
		);

		this.state = {
			pendingRequests: pendingRequestsArray,
			pendingRequestsLength: pendingRequestsArray?.length,
			isAllContactsTab: true,
			scannedContactsIsLoading: false,
			scannedContacts: [],
		};
	}

	componentDidMount() {
		const { mobile, getContacts } = this.props;
		getContacts();
		if (mobile) {
			this.setContactsWrapperHeight();
		}
	}

	componentDidUpdate(prevProps) {
		const { mobile } = this.props;

		if (
			prevProps?.user?.receivedRequests !== this.props?.user?.receivedRequests
		) {
			const { user } = this.props;
			let pendingRequestsArray = user.receivedRequests.filter(
				(request) => request.status === 'pending'
			);
			this.setState({
				pendingRequests: pendingRequestsArray,
				pendingRequestsLength: pendingRequestsArray?.length,
			});
		}
		if (mobile) {
			this.setContactsWrapperHeight();
		}
	}

	setContactsWrapperHeight = () => {
		const receivedRequestsWrapper = document.querySelector(
			'.received-requests-wrapper'
		);
		const alreadyContactsWrapper = document.querySelector(
			'.already-contacts-wrapper'
		);

		if (!alreadyContactsWrapper && !receivedRequestsWrapper) return;

		const heightReceivedRequests = `${
			receivedRequestsWrapper?.clientHeight || 0
		}px`;
		const menuHeight = `160px`;

		const alreadyContactsWrapperHeight = `calc(100vh - ${menuHeight} - ${heightReceivedRequests}) !important`;

		if (alreadyContactsWrapper)
			return alreadyContactsWrapper.setAttribute(
				'style',
				`min-height: ${alreadyContactsWrapperHeight}`
			);
	};

	checkIfIsOnline = (userId) => {
		return this.props.onlineUsersObject[userId];
	};

	handleScannedContactsTabClick = async () => {
		try {
			this.setState({ scannedContactsIsLoading: true });

			const response = await axios.get('/users/me/scanned-contacts');

			this.setState({
				scannedContacts: response.data.scannedContacts,
				isAllContactsTab: false,
			});
		} catch (error) {
			console.error('Error fetching scanned contacts:', error);
		} finally {
			this.setState({ scannedContactsIsLoading: false });
		}
	};

	render() {
		const { pendingRequests, pendingRequestsLength, scannedContactsIsLoading } =
			this.state;
		const { mobile, user, translation } = this.props;
		let orderedContacts = [];
		if (user) {
			// order the contacts alphabetically
			orderedContacts = [...this.props.user.contacts];
			orderedContacts.sort(function (x, y) {
				if (x.user.first === y.user.first) {
					return x.user.last < y.user.last ? -1 : 1;
				}
				return x.user.first < y.user.first ? -1 : 1;
			});
		}

		return (
			<div
				onDragStart={preventDefaultDrag}
				className={`contacts-page dropdown-component ${
					mobile ? 'contacts-mobile' : ''
				}`}
			>
				<CloseTopNavButton />
				{/* <div onDragStart={preventDefaultDrag} className="page-title">
                    <h3>{translation?.eventMenu.contactsButton}</h3>
                </div> */}
				<div
					className="tabs-container"
					style={{
						height: 48,
						backgroundColor: 'white',
						borderBottomWidth: 1,
						borderbottomColor: '#CACBD0',
						borderBottomStyle: 'solid',
					}}
				>
					<div
						className={
							'button-tab tab active'
							// (this.props.sideNavigation.seeNetworking ? 'active' : '')
						}
						style={{
							height: '100%',
							backgroundColor: this.state.isAllContactsTab ? '#FBD207' : '',
						}}
						onClick={() => this.setState({ isAllContactsTab: true })}
					>
						ALL CONTACTS
					</div>
					<div
						className={
							'button-tab tab active'
							// (this.props.sideNavigation.seeNetworking ? 'active' : '')
						}
						style={{
							height: '100%',
							backgroundColor: this.state.isAllContactsTab ? '' : '#FBD207',
						}}
						onClick={this.handleScannedContactsTabClick}
					>
						SCANNED CONTACTS
					</div>
				</div>

				{pendingRequestsLength + orderedContacts.length > 0 ? (
					<div onDragStart={preventDefaultDrag} className="networking-wrapper">
						<div className="scroll-left-container">
							{scannedContactsIsLoading && <Spinner />}
							{pendingRequestsLength > 0 && this.state.isAllContactsTab ? (
								<div
									className="received-requests-wrapper"
									data-height={pendingRequestsLength > 3 ? 'true' : 'false'}
								>
									{pendingRequestsLength > 3 ? (
										<ColoredScrollbars>
											{(pendingRequests || []).map((request) => {
												const isOnline = this.checkIfIsOnline(request.user._id);
												return (
													<div
														onDragStart={preventDefaultDrag}
														className="received-request-container"
														key={request._id}
													>
														<SingleUserContact
															key={request._id}
															id={request._id}
															user={request.user}
															friendConnectionReceived={true}
															requestId={request._id}
															isOnline={isOnline}
														/>
													</div>
												);
											})}
										</ColoredScrollbars>
									) : (
										<div>
											{(pendingRequests || []).map((request) => {
												const isOnline = this.checkIfIsOnline(request.user._id);
												return (
													<div
														onDragStart={preventDefaultDrag}
														className="received-request-container"
														key={request._id}
													>
														<SingleUserContact
															key={request._id}
															id={request._id}
															user={request.user}
															friendConnectionReceived={true}
															requestId={request._id}
															isOnline={isOnline}
														/>
													</div>
												);
											})}
										</div>
									)}
								</div>
							) : null}

							<div
								onDragStart={preventDefaultDrag}
								className={`already-contacts-wrapper ${
									pendingRequestsLength === 0 ? 'full-height' : ''
								}`}
							>
								{this.state.isAllContactsTab ? (
									<>
										{orderedContacts?.map((contact) => {
											const isOnline = this.checkIfIsOnline(contact.user._id);
											return (
												<SingleUserContact
													key={contact._id}
													id={contact._id}
													user={contact.user}
													isContact={true}
													isOnline={isOnline}
												/>
											);
										})}
									</>
								) : (
									<>
										{orderedContacts?.filter(
											(contact) => contact.createdWithQrCode
										).length > 0 ? (
											<>
												{orderedContacts
													?.filter((contact) => contact.createdWithQrCode)
													.map((contact) => {
														const isOnline = this.checkIfIsOnline(
															contact.user._id
														);
														return (
															<SingleUserContact
																key={contact._id}
																id={contact._id}
																user={contact.user}
																isContact={true}
																isOnline={isOnline}
															/>
														);
													})}
											</>
										) : (
											<div
												onDragStart={preventDefaultDrag}
												className="empty-contacts"
											>
												<p>{translation?.contactsDropdown.noScannedContacts}</p>
												<ContactsGreenIcon
													stroke={colors.primary}
													width="48"
													height="48"
													alt="Contacts"
												/>
											</div>
										)}
										<DownloadScannedContacts
											scannedContacts={this.state.scannedContacts}
										/>
									</>
								)}
							</div>
						</div>
					</div>
				) : (
					<div onDragStart={preventDefaultDrag} className="empty-contacts">
						<p>{translation?.contactsDropdown.noContacts}</p>
						<ContactsGreenIcon
							stroke={colors.primary}
							width="48"
							height="48"
							alt="Contacts"
						/>
					</div>
				)}
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getContacts: () => dispatch(actions.getContacts()),
	};
};

const mapStateToProps = (state) => {
	return {
		eventId: state.event.eventId,
		event: state.event.data,
		user: state.user.data,
		onlineUsersObject: state.onlineUsers.onlineUsersObject,
		languages: state.languages,
		translation: state.languages.translations[state.languages.platformLanguage],
		defaultTranslation: state.languages.translations['en'],
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
