import React, {PureComponent} from 'react';
import Radio from '@material-ui/core/Radio';
import {getCurrencySymbol, preventDefaultDrag} from '../../Utils/utils';

class AccessPackage extends PureComponent {
    render() {
        const {
            translation,
            eventInfo,
            accessPackages,
            hasAccessManagement,
            paidEvent,
            preselectedAccessPackageId,
            selectedPackageId,
            selectPackage,
            errorAccessPackageNotSelected,
        } = this.props;
        return (
            <>
                {eventInfo[hasAccessManagement] && !preselectedAccessPackageId && (
                    <div onDragStart={preventDefaultDrag} className="choose-package">
                        <p onDragStart={preventDefaultDrag} className="title">
                            {translation?.participantRegistration.choosePackageTitle}
                        </p>
                        {accessPackages.map((accessPackage) => {
                            // don't display disabled or hidden accessPackages
                            if (!accessPackage.isActive || !accessPackage.showOnRegistration) {
                                return null;
                            }

                            const isSelected = accessPackage._id === selectedPackageId;

                            return (
                                <div onDragStart={preventDefaultDrag} key={accessPackage._id}>
                                    <div
                                        className={'package ' + (isSelected ? 'selected' : '')}
                                        key={accessPackage._id}
                                        onClick={selectPackage(accessPackage._id)}
                                    >
                                        <span
                                            onDragStart={preventDefaultDrag}
                                            draggable="false"
                                            className="name-container"
                                        >
                                            <Radio checked={isSelected} color={'primary'} />
                                            <span onDragStart={preventDefaultDrag} draggable="false" className="name">
                                                {accessPackage.name}
                                            </span>
                                        </span>
                                        {eventInfo[paidEvent] && (
                                            <span onDragStart={preventDefaultDrag} draggable="false" className="price">
                                                {accessPackage.isFree ? (
                                                    'FREE'
                                                ) : (
                                                    <>
                                                        {`${getCurrencySymbol(eventInfo.currency)} ${
                                                            accessPackage.price
                                                        }`}
                                                    </>
                                                )}
                                            </span>
                                        )}
                                    </div>
                                    {isSelected && (
                                        <p onDragStart={preventDefaultDrag} className="package-description">
                                            {accessPackage.description}
                                        </p>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
                {errorAccessPackageNotSelected && (
                    <p onDragStart={preventDefaultDrag} className="error-text">
                        {translation?.participantRegistration.noPackageText}
                    </p>
                )}
            </>
        );
    }
}

export default AccessPackage;
