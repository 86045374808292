import React from 'react';
import {connect} from 'react-redux';
import NextVideoTimerDetails from "./NextVideoTimerDetails";

class NextVideoTimer extends React.Component {
    timerId = null;

    state = {
        totalSecondsTillNextVideo: null,
        timerSecondsTillNextVideo: 0,
        timerMinutesTillNextVideo: 0,
        timerHoursTillNextVideo: 0,
        showTimer: false,
    };

    componentDidMount() {
        if (this.props.totalSecondsTillNextVideo) {
            this.clearCountdown();
            this.startCountdown();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.totalSecondsTillNextVideo !== this.props.totalSecondsTillNextVideo) {
            this.clearCountdown();
            this.startCountdown();
        }
    }

    componentWillUnmount() {
        this.clearCountdown();
    }

    startCountdown = () => {
        this.setState(
            {
                totalSecondsTillNextVideo: this.props.totalSecondsTillNextVideo,
            },
            () => {
                this.timerId = setInterval(this.countdown, 1000);
            }
        );
        this.timeoutId = setTimeout(() => {
            this.setState({
                showTimer: true,
            })
        }, 1000);
    };

    countdown = () => {
        let hoursRemaining = Math.floor(this.state.totalSecondsTillNextVideo / 3600);
        let minutesRemaining = Math.floor((this.state.totalSecondsTillNextVideo - hoursRemaining * 3600) / 60);
        let secondsRemaining = Math.floor(
            this.state.totalSecondsTillNextVideo - hoursRemaining * 3600 - minutesRemaining * 60
        );

        if (this.state.totalSecondsTillNextVideo !== 0) {
            this.setState({
                totalSecondsTillNextVideo: this.state.totalSecondsTillNextVideo - 1,
                timerSecondsTillNextVideo: secondsRemaining,
                timerMinutesTillNextVideo: minutesRemaining,
                timerHoursTillNextVideo: hoursRemaining,
            });
        } else {
            this.setState({
                totalSecondsTillNextVideo: null,
                timerSecondsTillNextVideo: 0,
                timerMinutesTillNextVideo: 0,
                timerHoursTillNextVideo: 0,
            });

            this.clearCountdown();
            this.props.loadCurrentTimeSlot();
        }
    };

    clearCountdown = () => {
        clearTimeout(this.timeoutId);
        if (this.timerId) {
            clearInterval(this.timerId);
            this.setState({
                showTimer: false,
            });
        }
    };

    render() {
        const {timerHoursTillNextVideo, timerMinutesTillNextVideo, timerSecondsTillNextVideo, showTimer} = this.state;
        const {translation, displayProgramTimeslotMatch} = this.props;

        return (
            <>
                <span>{translation?.auditorium.willStart}</span>
                <span
                    className={`timer-text-clock ${
                        (timerHoursTillNextVideo !== 0 ||
                        timerMinutesTillNextVideo !== 0 ||
                        timerSecondsTillNextVideo !== 0) &&
                        showTimer
                            ? 'show'
                            : 'hide'
                    }`}
                >
                    {timerHoursTillNextVideo > 24 ? (
                        <>
                            {Math.floor(timerHoursTillNextVideo / 24)}
                            {timerHoursTillNextVideo < 48 ? ` ${translation?.time.day}` : ` ${translation?.time.days}`}
                        </>
                    ) : (
                        <>
                            {timerHoursTillNextVideo < 10
                                ? '0' + timerHoursTillNextVideo + ' : '
                                : timerHoursTillNextVideo + ' : '}
                            {timerMinutesTillNextVideo < 10
                                ? '0' + timerMinutesTillNextVideo + ' : '
                                : timerMinutesTillNextVideo + ' : '}
                            {timerSecondsTillNextVideo < 10
                                ? '0' + timerSecondsTillNextVideo
                                : timerSecondsTillNextVideo}
                        </>
                    )}
                </span>
                {displayProgramTimeslotMatch && (
                    <NextVideoTimerDetails displayProgramTimeslotMatch={displayProgramTimeslotMatch} />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps)(NextVideoTimer);
