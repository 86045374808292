import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import axios from '../../store/axios-instance';
import ColoredScrollbars from '../../SmallLayoutComponents/ColoredScrollbars';
import {getTimeAgoString, preventDefaultDrag} from '../../Utils/utils';
import CloseTopNavButton from '../../SmallLayoutComponents/CloseTopNavButton';
import {withRouter} from 'react-router-dom';
import {ReactComponent as EmptyNotificationsIcon} from '../../Images/svg/notifications.svg';
import moment from 'moment';
import './NotificationsStyles.scss';
import colors from '../../CSS/_variables.module.scss';

class Notifications extends React.Component {
    state = {
        isActive: false,
    };

    componentDidMount() {
        axios({method: 'post', url: '/users/me/see-all-notifications'})
            .then(() => this.props.onGetNotifications())
            .catch(() => {});
    }

    addActiveClass = () =>
        this.setState({
            isActive: !this.state.isActive,
        });

    sortNotifications = () => {
        let orderedNotifications = this.props.notifications.slice(0).reverse();
        let filteredNotifications = orderedNotifications.filter(
            (notification) =>
                notification.type !== 'newPrivateChatMessage' && notification.type !== 'newGroupChatMessage'
        );

        // TODO: remove meetingStartingSoon from 'new-notifications' and DB user model
        // this filter is 'mizerie' and unnecessary

        // remove meetingStartingSoon notification older than one hour
        if (filteredNotifications.filter((el) => el.type === 'meetingStartingSoon').length > 0) {
            let indexesToRemove = [];
            filteredNotifications.forEach((notEl, index) => {
                if (notEl.type === 'meetingStartingSoon') {
                    const now = new Date().getTime();
                    const notificationTime = new Date(notEl.createdAt).getTime();
                    if (now - notificationTime > 60 * 60 * 1000) {
                        indexesToRemove.push(index);
                    }
                }
            });
            if (indexesToRemove.length) {
                filteredNotifications = filteredNotifications.filter(
                    (el, index) => indexesToRemove.indexOf(index) === -1
                );
            }
        }

        return filteredNotifications;
    };

    getNotificationText = (notificationType) => {
        let notificationText;
        switch (notificationType) {
            case 'contactRequestReceived':
                notificationText =
                    this.props.languages.translations[this.props.languages.platformLanguage]?.notificationsDropdown
                        .contactRequestReceived ||
                    this.props.languages.translations['en']?.notificationsDropdown.contactRequestReceived;
                break;
            case 'contactRequestAccepted':
                notificationText =
                    this.props.languages.translations[this.props.languages.platformLanguage]?.notificationsDropdown
                        .contactRequestAccepted ||
                    this.props.languages.translations['en']?.notificationsDropdown.contactRequestAccepted;
                break;
            case 'meetingRequestReceived':
                notificationText =
                    this.props.languages.translations[this.props.languages.platformLanguage]?.notificationsDropdown
                        .meetingRequestReceived ||
                    this.props.languages.translations['en']?.notificationsDropdown.meetingRequestReceived;
                break;
            case 'meetingRequestAccepted':
                notificationText =
                    this.props.languages.translations[this.props.languages.platformLanguage]?.notificationsDropdown
                        .meetingRequestAccepted ||
                    this.props.languages.translations['en']?.notificationsDropdown.meetingRequestAccepted;
                break;
            case 'meetingRequestCanceled':
                notificationText =
                    this.props.languages.translations[this.props.languages.platformLanguage]?.notificationsDropdown
                        .meetingRequestCanceled ||
                    this.props.languages.translations['en']?.notificationsDropdown.meetingRequestCanceled;
                break;
            case 'meetingStartingSoon':
                notificationText =
                    this.props.languages.translations[this.props.languages.platformLanguage]?.notificationsDropdown
                        .meetingStarting ||
                    this.props.languages.translations['en']?.notificationsDropdown.meetingStarting;
                break;
            case 'newBoothPost':
                notificationText =
                    this.props.languages.translations[this.props.languages.platformLanguage]?.notificationsDropdown
                        .newBoothPost || this.props.languages.translations['en']?.notificationsDropdown.newBoothPost;
                break;
            case 'whenIsCreated':
                notificationText = 'whenIsCreated';
                break;
            case 'oneWeekBefore':
                notificationText = 'oneWeekBefore';
                break;
            case 'oneHourBefore':
                notificationText = 'oneHourBefore';
                break;
            case 'oneDayBefore':
                notificationText = 'oneDayBefore';
                break;
            case 'onDemandAvailable':
                notificationText = 'onDemandAvailable';
                break;
            case 'whenAddedByURL':
                notificationText = 'whenAddedByURL';
                break;
            default:
        }
        return notificationText;
    };

    getPartnerName = (meetingId) => {
        const {user} = this.props;
        const meetingData = user.meetings.find((el) => el._id === meetingId);

        // check deleted users...
        if (meetingData && meetingData.owner.user !== null && meetingData.partner.user !== null) {
            if (meetingData && meetingData.owner.user._id === user._id) {
                return meetingData.partner.user.first + ' ' + meetingData.partner.user.last;
            } else if (meetingData) {
                return meetingData.owner.user.first + ' ' + meetingData.owner.user.last;
            }
        } else {
            return null;
        }
    };

    handleNotificationClick = (notification) => (e) => {
        const {mobile} = this.props;
        let notificationType = notification.type;
        switch (notificationType) {
            case 'newBoothPost':
                this.props.history.push(
                    `/event/${notification?.eventSlug}/exhibitor-booth-view/${notification.objectId}`
                );
                break;
            case 'contactRequestReceived':
            case 'contactRequestAccepted':
                if (mobile) {
                    this.props.seeContactsMobile();
                } else {
                    this.props.seeContacts();
                }
                break;
            case 'meetingRequestReceived':
            case 'meetingRequestAccepted':
            case 'meetingRequestCanceled':
                if (mobile) {
                    this.props.seeProgramFromNotificationsMobile();
                } else {
                    this.props.seeProgramFromNotifications();
                }
                break;
            case 'meetingStartingSoon':
                this.props.onOpenPrivateChat(notification.privateChat);
                break;
            default:
        }
    };

    handleEnterKey = (e) => {
        e.stopPropagation();
        if (e.key === 'Enter') return this.addActiveClass();
    };

    render() {
        let notifications = this.sortNotifications();
        notifications = notifications.filter((el) => el.user !== null);
        const {mobile, translation, defaultTranslation} = this.props;

        return (
            <div
                onDragStart={preventDefaultDrag}
                className={`notifications-page dropdown-component ${mobile ? 'notifications-mobile' : ''}`}
            >
                <CloseTopNavButton />
                <div onDragStart={preventDefaultDrag} className="page-title">
                    <h3>{translation?.notificationsDropdown.title}</h3>
                </div>
                {notifications.length > 0 ? (
                    <div
                        className={`notifications-container ${this.state.isActive ? 'active' : ''}`}
                        style={this.state.isActive ? {height: notifications.length * 43 + 20} : {height: 260}}
                    >
                        {this.state.isActive ? (
                            <ColoredScrollbars>
                                <ul
                                    className={`notifications-list active-scrollbar ${
                                        this.state.isActive ? 'active' : ''
                                    }`}
                                >
                                    {notifications.map((notification) => {
                                        const timeAgoString = getTimeAgoString(
                                            notification.createdAt,
                                            this.props.languages.platformLanguage
                                        );
                                        const notificationText = this.getNotificationText(notification.type);
                                        const onDemandUrl = notification?.onDemandLink?.replace(/^.*\/\/[^\/]+/, '');

                                        let meetingPartnerName = '';
                                        if (notification.type === 'meetingStartingSoon') {
                                            meetingPartnerName = this.getPartnerName(notification.meeting);
                                        }
                                        // check deleted users...
                                        if (notification.type === 'meetingStartingSoon' && !meetingPartnerName) {
                                            return null;
                                        }

                                        return (
                                            <li key={notification._id}>
                                                <div
                                                    className="single-notification-text"
                                                    
                                                >
                                                    <div onDragStart={preventDefaultDrag} className="contacts-wrapper">
                                                        {notification.user &&
                                                        notification.type !== 'whenIsCreated' &&
                                                        notification.type !== 'oneDayBefore' &&
                                                        notification.type !== 'oneWeekBefore' &&
                                                        notification.type !== 'onDemandAvailable' &&
                                                        notification.type !== 'oneHourBefore' &&
                                                        notification.type !== 'whenAddedByURL' ? (
                                                            <span
                                                                onDragStart={preventDefaultDrag}
                                                                draggable="false"
                                                                className="user-name"
                                                            >
                                                                {notification.user.first} {notification.user.last}
                                                            </span>
                                                        ) : null}
                                                        {notification.type !== 'meetingStartingSoon' &&
                                                            notification.type !== 'whenIsCreated' &&
                                                            notification.type !== 'oneDayBefore' &&
                                                            notification.type !== 'oneWeekBefore' &&
                                                            notification.type !== 'onDemandAvailable' &&
                                                            notification.type !== 'oneHourBefore' &&
                                                            notification.type !== 'whenAddedByURL' && (
                                                                <span>{notificationText}</span>
                                                            )}
                                                        {(notification.type === 'whenIsCreated' ||
                                                            notification.type === 'oneDayBefore' ||
                                                            notification.type === 'oneWeekBefore') && (
                                                            <>
                                                                Make sure to read more about the session on{' '}
                                                                <span
                                                                    onDragStart={preventDefaultDrag}
                                                                    draggable="false"
                                                                    className="user-name platform-notf-helper"
                                                                >
                                                                    {notification.timeslotTitle}
                                                                </span>{' '}
                                                                taking place on{' '}
                                                                {moment(notification.timeslotDate).format(
                                                                    'MMMM Do, YYYY'
                                                                )}
                                                            </>
                                                        )}
                                                        {notification.type === 'oneHourBefore' && (
                                                            <>
                                                                <>
                                                                    Make sure to check out the session on{' '}
                                                                    <span
                                                                        onDragStart={preventDefaultDrag}
                                                                        draggable="false"
                                                                        className="user-name platform-notf-helper"
                                                                    >
                                                                        {notification.timeslotTitle}
                                                                    </span>{' '}
                                                                    taking place at {notification?.timeslotStartTime} (
                                                                    {notification?.timezone})
                                                                </>
                                                            </>
                                                        )}
                                                        {notification.type === 'onDemandAvailable' && (
                                                            <>
                                                                The session on{' '}
                                                                <span
                                                                    onDragStart={preventDefaultDrag}
                                                                    draggable="false"
                                                                    className="user-name platform-notf-helper"
                                                                >
                                                                    {notification.timeslotTitle}
                                                                </span>{' '}
                                                                is now available to watch on-demand{' '}
                                                            </>
                                                        )}
                                                        {notification.type === 'whenAddedByURL' && (
                                                            <>
                                                                The session on{' '}
                                                                <span
                                                                    onDragStart={preventDefaultDrag}
                                                                    draggable="false"
                                                                    className="user-name platform-notf-helper"
                                                                >
                                                                    {notification.timeslotTitle}
                                                                </span>{' '}
                                                                has been added to your calendar successfully. Take a
                                                                look at it.
                                                            </>
                                                        )}
                                                        {notification.type === 'meetingStartingSoon' && (
                                                            <>
                                                                {translation?.notificationsDropdown
                                                                    .meetingWillStartTextFirst ||
                                                                    defaultTranslation?.notificationsDropdown
                                                                        .meetingWillStartTextFirst}{' '}
                                                                <span
                                                                    onDragStart={preventDefaultDrag}
                                                                    draggable="false"
                                                                    className="user-name platform-notf-helper"
                                                                >
                                                                    {meetingPartnerName}{' '}
                                                                </span>
                                                                {translation?.notificationsDropdown
                                                                    .meetingWillStartTextSecond ||
                                                                    defaultTranslation?.notificationsDropdown
                                                                        .meetingWillStartTextSecond}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div onDragStart={preventDefaultDrag} className="date-time">
                                                        <span>
                                                            {
                                                                (
                                                                    notification.type === 'onDemandAvailable' && (
                                                                        <a className="go-onDemand" href={onDemandUrl}>
                                                                            OPEN
                                                                        </a>
                                                                    ) ||
                                                                    (['contactRequestReceived', 'contactRequestAccepted', 'meetingRequestReceived', 'meetingRequestAccepted', 'meetingStartingSoon', 'newBoothPost'].includes(notification.type)) && (
                                                                        <div 
                                                                            className="go-onDemand"
                                                                            onClick={this.handleNotificationClick(notification)}
                                                                        >
                                                                            OPEN
                                                                        </div>
                                                                    )
                                                                ) || (timeAgoString )
                                                            }

                                                        </span>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </ColoredScrollbars>
                        ) : (
                            <ul className="notifications-list">
                                {notifications.map((notification) => {
                                    const timeAgoString = getTimeAgoString(
                                        notification.createdAt,
                                        this.props.languages.platformLanguage
                                    );
                                    const onDemandUrl = notification?.onDemandLink?.replace(/^.*\/\/[^\/]+/, '');

                                    const notificationText = this.getNotificationText(notification.type);

                                    let meetingPartnerName = '';
                                    if (notification.type === 'meetingStartingSoon') {
                                        meetingPartnerName = this.getPartnerName(notification.meeting);
                                    }
                                    // check deleted users...
                                    if (notification.type === 'meetingStartingSoon' && !meetingPartnerName) {
                                        return null;
                                    }

                                    return (
                                        <li key={notification._id}>
                                            <div
                                                className="single-notification-text"
                                                onClick={this.handleNotificationClick(notification)}
                                            >
                                                <div onDragStart={preventDefaultDrag} className="contacts-wrapper">
                                                    {notification.user &&
                                                    notification.type !== 'whenIsCreated' &&
                                                    notification.type !== 'oneDayBefore' &&
                                                    notification.type !== 'oneWeekBefore' &&
                                                    notification.type !== 'onDemandAvailable' &&
                                                    notification.type !== 'oneHourBefore' &&
                                                    notification.type !== 'whenAddedByURL' ? (
                                                        <span
                                                            onDragStart={preventDefaultDrag}
                                                            draggable="false"
                                                            className="user-name"
                                                        >
                                                            {notification.user.first} {notification.user.last}
                                                        </span>
                                                    ) : null}
                                                    {notification.type !== 'meetingStartingSoon' &&
                                                        notification.type !== 'whenIsCreated' &&
                                                        notification.type !== 'oneDayBefore' &&
                                                        notification.type !== 'oneWeekBefore' &&
                                                        notification.type !== 'onDemandAvailable' &&
                                                        notification.type !== 'oneHourBefore' &&
                                                        notification.type !== 'whenAddedByURL' && (
                                                            <span>{notificationText}</span>
                                                        )}
                                                    {(notification.type === 'whenIsCreated' ||
                                                        notification.type === 'oneDayBefore' ||
                                                        notification.type === 'oneWeekBefore') && (
                                                        <>
                                                            Make sure to read more about the session on{' '}
                                                            <span
                                                                onDragStart={preventDefaultDrag}
                                                                draggable="false"
                                                                className="user-name platform-notf-helper"
                                                            >
                                                                {notification.timeslotTitle}
                                                            </span>{' '}
                                                            taking place on{' '}
                                                            {moment(notification.timeslotDate).format('MMMM Do, YYYY')}
                                                        </>
                                                    )}
                                                    {notification.type === 'oneHourBefore' && (
                                                        <>
                                                            <>
                                                                Make sure to check out the session on{' '}
                                                                <span
                                                                    onDragStart={preventDefaultDrag}
                                                                    draggable="false"
                                                                    className="user-name platform-notf-helper"
                                                                >
                                                                    {notification.timeslotTitle}
                                                                </span>{' '}
                                                                taking place at {notification?.timeslotStartTime} (
                                                                {notification?.timezone})
                                                            </>
                                                        </>
                                                    )}
                                                    {notification.type === 'onDemandAvailable' && (
                                                        <>
                                                            The session on{' '}
                                                            <span
                                                                onDragStart={preventDefaultDrag}
                                                                draggable="false"
                                                                className="user-name platform-notf-helper"
                                                            >
                                                                {notification.timeslotTitle}
                                                            </span>{' '}
                                                            is now available to watch on-demand
                                                        </>
                                                    )}
                                                    {notification.type === 'whenAddedByURL' && (
                                                        <>
                                                            The session on{' '}
                                                            <span
                                                                onDragStart={preventDefaultDrag}
                                                                draggable="false"
                                                                className="user-name platform-notf-helper"
                                                            >
                                                                {notification.timeslotTitle}
                                                            </span>{' '}
                                                            has been added to your calendar successfully. Take a look at
                                                            it.
                                                        </>
                                                    )}
                                                    {notification.type === 'meetingStartingSoon' && (
                                                        <>
                                                            {translation?.notificationsDropdown
                                                                .meetingWillStartTextFirst ||
                                                                defaultTranslation?.notificationsDropdown
                                                                    .meetingWillStartTextFirst}{' '}
                                                            <span
                                                                onDragStart={preventDefaultDrag}
                                                                draggable="false"
                                                                className="user-name platform-notf-helper"
                                                            >
                                                                {meetingPartnerName}
                                                            </span>{' '}
                                                            {translation?.notificationsDropdown
                                                                .meetingWillStartTextSecond ||
                                                                defaultTranslation?.notificationsDropdown
                                                                    .meetingWillStartTextSecond}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div onDragStart={preventDefaultDrag} className="date-time">
                                            <span>
                                                            {
                                                                (
                                                                    notification.type === 'onDemandAvailable' && (
                                                                        <a className="go-onDemand" href={onDemandUrl}>
                                                                            OPEN
                                                                        </a>
                                                                    ) ||
                                                                    (['contactRequestReceived', 'contactRequestAccepted', 'meetingRequestReceived', 'meetingRequestAccepted', 'meetingStartingSoon', 'newBoothPost'].includes(notification.type)) && (
                                                                        <div 
                                                                            className="go-onDemand"
                                                                            onClick={this.handleNotificationClick(notification)}
                                                                        >
                                                                            OPEN
                                                                        </div>
                                                                    )
                                                                ) || (timeAgoString )
                                                            }

                                                        </span>
                                                </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </div>
                ) : (
                    <div onDragStart={preventDefaultDrag} className="empty-notifications">
                        <p>
                            {translation?.notificationsDropdown.noNotifications ||
                                defaultTranslation?.notificationsDropdown.noNotifications}
                        </p>
                        <EmptyNotificationsIcon fill={colors.primary} width="48" height="48" />
                    </div>
                )}

                {notifications.length > 6 ? (
                    <div
                        tabIndex="0"
                        onKeyDown={this.handleEnterKey}
                        onDragStart={preventDefaultDrag}
                        className="see-all-notifications"
                    >
                        <p onDragStart={preventDefaultDrag} onClick={this.addActiveClass}>
                            {this.state.isActive
                                ? translation?.notificationsDropdown.hideAll ||
                                  defaultTranslation?.notificationsDropdown.hideAll
                                : translation?.notificationsDropdown.seeAll ||
                                  defaultTranslation?.notificationsDropdown.seeAll}
                        </p>
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        notifications: state.user.data.notifications,
        user: state.user.data,
        event: state.event.data,
        eventSlug: state.event.eventSlug,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetNotifications: () => dispatch(actions.getNotifications()),
        seeContacts: () => dispatch(actions.topNavSeeContacts()),
        seeProgramFromNotifications: () => dispatch(actions.topNavSeeProgramFromNotifications()),
        seeProgramFromNotificationsMobile: () => dispatch(actions.topNavSeeProgramFromNotificationsMobile()),
        seeContactsMobile: () => dispatch(actions.topNavSeeContactsMobile()),
        onOpenPrivateChat: (privateChatId) => dispatch(actions.openPrivateChat(privateChatId)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications));
