import React, {PureComponent} from 'react';
import {getPrivateChatId, preventDefaultDrag} from '../Utils/utils';
import {connect} from 'react-redux';
import * as actions from '../store/actions/index';
import OpenChatIcon from '../Images/svg/OpenChat';
import colors from '../CSS/_variables.module.scss';

class SingleRepresentative extends PureComponent {
    handleOpenPrivateChat = () => {
        const targetUserId = this.props.user.user._id;
        const privateChatId = getPrivateChatId(this.props.privateChats, targetUserId);

        if (privateChatId) {
            this.props.onOpenPrivateChat(privateChatId);
        } else {
            if (targetUserId !== this.props.userLoggedIn._id && !this.props.loading) {
                this.props.onCreatePrivateChat(targetUserId);
            }
        }
    };

    render() {
        const {user, translation, defaultTranslation} = this.props;
        let userLoggedIn = this.props.userLoggedIn;
        return (
            <li>
                <div
                    onDragStart={preventDefaultDrag}
                    className={'user-container ' + (this.props.isOnline ? 'is-online' : 'is-offline')}
                >
                    <div
                        className="participant-avatar"
                        style={{
                            backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${user.user.avatarSmall}')`,
                        }}
                    />
                    <div onDragStart={preventDefaultDrag} className="user-details">
                        <div>
                            <div onDragStart={preventDefaultDrag} className="user-name">
                                <p onDragStart={preventDefaultDrag} className="user-name-top">
                                    {user.user.first} {user.user.last}
                                </p>
                                <p>
                                    {user.title} {user.title && (user.company || user.affiliation) ? 'at' : null}{' '}
                                    {user.company || user.affiliation}
                                </p>
                            </div>
                        </div>
                        <div>
                            <div
                                onDragStart={preventDefaultDrag}
                                className={'connection ' + (this.props.friendConnectionReceived ? null : 'no-action')}
                            >
                                {user.user._id !== userLoggedIn._id && (
                                    <button
                                        className="participant-button open-chat"
                                        onClick={this.handleOpenPrivateChat}
                                        disabled={this.props.loading}
                                    >
                                        <div>
                                            <OpenChatIcon
                                                primary={colors.primary}
                                                secondary={colors.primary}
                                            />
                                            <span>{defaultTranslation?.networking.chat}</span>
                                        </div>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        privateChats: state.user.data.privateChats,
        loading: state.user.loading,
        userLoggedIn: state.user.data,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onOpenPrivateChat: (privateChatId) => dispatch(actions.openPrivateChat(privateChatId)),
        onCreatePrivateChat: (targetUserId) => dispatch(actions.createPrivateChat(targetUserId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleRepresentative);
