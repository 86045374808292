import React, { PureComponent } from "react";
import Spinner from "../../../../SmallLayoutComponents/Spinner";
import { connect } from "react-redux";
import { ReactComponent as EmptyIcon } from "../../../../Images/svg/empty-sessions.svg";
import SmartSuggestionsList from "./VirtualizedSuggestions/SmartSuggestionsList";
import * as actions from "../../../../store/actions";
import moment from "moment";
import {
	getTimeslotEndTimestamp,
	getTimeslotStartTimestamp,
	getUtcTimestamp,
} from "Utils/utils";
import { cloneDeep } from "lodash";
import SelectTimezone from "Components/SelectTimezone";

class SmartSuggestions extends PureComponent {
	state = {
		expandedItems: [],
	};

	componentDidMount() {
		const { isLargeScreen } = this.props;
		if (!isLargeScreen) {
			const tabs = document.querySelector(".tabs-wrapper");
			const title = document.querySelector(".smart-suggestions > p");
			let elementsHeight = tabs.clientHeight + title?.clientHeight;
			document.documentElement.style.setProperty(
				"--smart-height",
				`${elementsHeight}px`,
			);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { isLargeScreen } = this.props;
		if (!isLargeScreen) {
			const tabs = document.querySelector(".tabs-wrapper");
			const title = document.querySelector(".smart-suggestions > p");
			let elementsHeight = tabs.clientHeight + title?.clientHeight;
			document.documentElement.style.setProperty(
				"--smart-height",
				`${elementsHeight}px`,
			);
		}
	}

	componentWillUnmount() {
		const { isLargeScreen } = this.props;
		if (!isLargeScreen) {
			document.documentElement.style.removeProperty("--smart-height");
		}
	}

	toggleExpandedSuggestion = (id) => (e) => {
		const { expandedItems } = this.state;
		let items = [...expandedItems];
		if (items.includes(id)) {
			items = items.filter((item) => item !== id);
		} else {
			items.push(id);
		}
		this.setState({
			expandedItems: items,
		});
	};

	addSessionToAgenda = (sessionId) => {
		this.props.addSessionAgenda(sessionId);
		this.createExternalCalendarData(sessionId);
	};

	createExternalCalendarData = (sessionId) => {
		const { suggestions } = this.props;
		const timeslotData = suggestions.find(
			(suggestion) => suggestion._id === sessionId,
		);
		const eventCalendarData = {
			title: timeslotData.title,
			description: timeslotData.description,
			location: window.location.origin,
			start: timeslotData.startTimestamp,
			end: timeslotData.endTimestamp,
		};
		const calendarEventType = "session";
		this.props.showAddToExternalCalendarDialog(
			eventCalendarData,
			calendarEventType,
		);
	};

	calculateTimezoneDifference = () => {
		const { event, user } = this.props;

		const userTimezoneValue = user?.timezoneValue;
		let timezoneDifference = 0;
		if (userTimezoneValue || userTimezoneValue === 0) {
			timezoneDifference = userTimezoneValue - event?.timezoneValue;
		}
		return timezoneDifference;
	};

	recalculateTimeslotByTimezone = (timeslot, timezoneDifference) => {
		const { event } = this.props;

		let startTimestampWithoutLocalTimezone = moment
			.utc(timeslot.startTimestamp)
			.format("l LT");
		let endTimestampWithoutLocalTimezone = moment
			.utc(timeslot.endTimestamp)
			.format("l LT");
		let timezoneValueToBeAdded =
			Number(event?.timezoneValue) + timezoneDifference;

		timeslot.startTimestamp = moment(startTimestampWithoutLocalTimezone).add(
			timezoneValueToBeAdded,
			"hours",
		);
		timeslot.endTimestamp = moment(endTimestampWithoutLocalTimezone).add(
			timezoneValueToBeAdded,
			"hours",
		);

		timeslot.start = moment(timeslot.startTimestamp).format("HH:mm");
		timeslot.end = moment(timeslot.endTimestamp).format("HH:mm");

		return timeslot;
	};

	checkIfTimeslotIsLive = (timeslot, eventTimezoneValue) => {
		let currentTime = new Date();
		let currentTimeStamp = getUtcTimestamp(currentTime);

		let timeslotDate = timeslot.startTimestamp.split("T")[0];

		let timeSlotStartTimestamp = getTimeslotStartTimestamp(
			timeslotDate,
			timeslot,
			eventTimezoneValue,
		);
		let timeSlotEndTimestamp = getTimeslotEndTimestamp(
			timeslotDate,
			timeslot,
			eventTimezoneValue,
		);

		return (
			currentTimeStamp >= timeSlotStartTimestamp &&
			currentTimeStamp < timeSlotEndTimestamp
		);
	};

	render() {
		const { expandedItems } = this.state;
		const {
			event,
			eventId,
			suggestions,
			suggestionsLoading,
			showProgramTooltip,
			hideTooltip,
			stopTooltip,
			isLargeScreen,
			isRtlLanguage,
			languages,
			translation,
			defaultTranslation,
		} = this.props;

		const notAddedSuggestion = suggestions.find(
			(element) => !element.isAddedToAgenda,
		)?._id;

		const displaySearchResults = (timeslotsResults) => {
			let timezoneDifference = this.calculateTimezoneDifference();

			// if timezone difference is 0
			// we set the api call response as the timeslots
			// if(timezoneDifference === 0) {
			//     return setTimeslots(timeslotsResults)
			// }

			// update timeslot properties (recalculate based on timezone difference)
			let formattedTimeslots = [];

			// biome-ignore lint/complexity/noForEach: <explanation>
			suggestions.forEach((timeslotResult) => {
				let newTimeslot = cloneDeep(timeslotResult);
				newTimeslot = this.recalculateTimeslotByTimezone(
					newTimeslot,
					timezoneDifference,
				);
				newTimeslot.isLive = this.checkIfTimeslotIsLive(
					timeslotResult,
					event.timezoneValue,
				);
				formattedTimeslots.push(newTimeslot);
			});

			return formattedTimeslots;
		};

		return (
			<div className="program-body smart-suggestions">
				{suggestionsLoading && <Spinner />}
				{suggestions?.length > 0 ? (
					<>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								paddingLeft: 16,
								paddingRight: 16,
							}}
						>
							<p>
								{translation?.program?.smartListTitle ||
									defaultTranslation?.program?.smartListTitle}
							</p>
							<SelectTimezone />
						</div>
						<div className="smart-wrapper">
							<SmartSuggestionsList
								suggestions={displaySearchResults()}
								event={event}
								eventId={eventId}
								expanded={expandedItems}
								isLargeScreen={isLargeScreen}
								languages={languages}
								translation={translation}
								defaultTranslation={defaultTranslation}
								isRtlLanguage={isRtlLanguage}
								toggleDescription={this.toggleExpandedSuggestion}
								addSessionAgenda={this.addSessionToAgenda}
								removeSessionFromAgenda={this.props.removeSessionAgenda}
								suggestionTooltip={notAddedSuggestion}
								showProgramTooltip={showProgramTooltip}
								hideProgramTooltip={hideTooltip}
								stopTooltipTime={stopTooltip}
								userTimezone={this.props.user.timezoneValue}
							/>
						</div>
					</>
				) : (
					<>
						<p>
							{translation?.program?.smartListTitle ||
								defaultTranslation?.program?.smartListTitle}
						</p>
						<div className="smart-wrapper empty-data">
							<div>
								<EmptyIcon />
								<p>
									{translation?.program?.emptySessions ||
										defaultTranslation?.program?.emptySessions}
								</p>
							</div>
						</div>
					</>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		event: state.event.data,
		eventId: state.event.eventId,
		suggestions: state.event.suggestions,
		user: state.user.data,
		suggestionsLoading: state.event.suggestionsLoading,
		showProgramTooltip: state.layout.showProgramTooltip,
		isLargeScreen: state.layout.isLargeScreen,
		isRtlLanguage: state.languages.isRtlLanguage,
		languages: state.languages,
		translation: state.languages.translations[state.languages.platformLanguage],
		defaultTranslation: state.languages.translations["en"],
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addSessionAgenda: (sessionId) =>
			dispatch(actions.addSessionToAgenda(sessionId)),
		removeSessionAgenda: (sessionId) =>
			dispatch(actions.removeSessionFromAgenda(sessionId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartSuggestions);
