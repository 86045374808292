import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';
import ar from '../../Utils/Translations/ar';
import zh from '../../Utils/Translations/zh'; // chinesse
import de from '../../Utils/Translations/de';
import en from '../../Utils/Translations/en';
import es from '../../Utils/Translations/es';
import fr from '../../Utils/Translations/fr';
import no from '../../Utils/Translations/no';
import pt from '../../Utils/Translations/pt';
import ru from '../../Utils/Translations/ru';
import tr from '../../Utils/Translations/tr';
import {languagesArray} from '../../Utils/Translations/availableLanguages';

// eventLanguage is set up on getEventInfo and can be changed by the user
// platformLanguage is set up after getUser(register, Login, autoLogin etc)
// organizerBrandingLanguage is set up on Organizer => Event Info and is the one that controls the organizerDashboard Event Info languages
const initialState = {
    eventLanguage: 'en', // event public pages (homepage, login, register etc.)
    platformLanguage: 'en', // event private pages (lobby, auditorium, chats, myAccount etc),
    platformLanguages: languagesArray,
    organizerBrandingLanguage: null,
    isRtlLanguage: false,
    translations: {
        ar: ar,
        zh: zh,
        de: de,
        en: en,
        es: es,
        fr: fr,
        no: no,
        pt: pt,
        ru: ru,
        tr: tr,
    },
};

const reducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.SET_EVENT_LANGUAGE:
                draft.eventLanguage = action.payload;
                draft.isRtlLanguage = action.payload === 'ar';
                break;

            case actionTypes.SET_PLATFORM_LANGUAGE:
                draft.platformLanguage = action.payload;
                draft.isRtlLanguage = action.payload === 'ar';
                break;

            case actionTypes.SET_ORGANIZER_BRANDING_LANGUAGE:
                draft.organizerBrandingLanguage = action.payload;
                break;

            default:
                break;
        }
    });

export default reducer;
