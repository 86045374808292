import React from 'react';
import * as actions from '../../store/actions/index';
import {
    getPrivateChatId,
    getUserIdFromExhibitorId,
    checkBoothOwnerOrRepresentative,
    getParticipantData,
    preventDefaultDrag,
} from '../../Utils/utils';
import {connect} from 'react-redux';
import {getUserIdFromParticipantId, getVideoIdFromYoutubeUrl} from '../../Utils/utils';
import {connectToBooth, disconnectFromBooth} from '../../Api/socketApi';
import axios from '../../store/axios-instance';
import '../../CSS/boothParticipantView.scss';
import NotificationPresentationStarts from '../../Components/NotificationPresentationStarts';
import Spinner from '../../SmallLayoutComponents/Spinner';
import Representatives from './Mobile/Representatives';
import Files from './Mobile/Files';
import Links from './Mobile/Links';
import Video from './Mobile/Video';
import Poster from './Mobile/Poster';
import PdfPreview from './Booth/PdfPreview';
import moment from 'moment';
import {isWebview} from '../../Utils/is-webview';

class ExhibitorBoothMobile extends React.Component {
    initialShow = {
        links: false,
        documents: false,
        video: false,
    };

    state = {
        currentTab: 'chat',
        exhibitorVideoId: '',
        hasFacebookSocialLink: false,
        hasLinkedinSocialLink: false,
        hasTwitterSocialLink: false,
        show: {...this.initialShow},
        connectTimestamp: null,
    };

    componentDidMount() {
        // emit socket event to let know that we have a visitor on booth
        if (this.props.activeExhibitorId) {
            this.setState({connectTimestamp: moment.utc()});
            connectToBooth(this.props.activeExhibitorId, this.props.user._id);
        }

        this.setupBeforeUnloadListener();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loadingExhibitor === true && this.props.loadingExhibitor === false) {
            // we got the exhibitor
            // we can now set up the booth Data
            this.handleSetUpBoothData();
        }

        if (prevProps.activeExhibitorId !== this.props.activeExhibitorId) {
            const {connectTimestamp} = this.state;
            let connectTimestampOrNow = connectTimestamp;
            if (!connectTimestampOrNow) {
                connectTimestampOrNow = moment.utc();
            }

            if (prevProps.activeExhibitorId) {
                const isRepresentative = checkBoothOwnerOrRepresentative(this.props.exhibitor, this.props.user._id);
                let trackBoothStatistics = !isRepresentative;

                const {event, user} = this.props;
                const {owner} = event;
                if (owner._id === user._id) {
                    trackBoothStatistics = false;
                    let findEventRoles = user.eventRoles.find((el) => el?.event?._id === event?._id);
                    if (findEventRoles) {
                        if (findEventRoles?.roles?.includes('participant')) {
                            trackBoothStatistics = true;
                        }
                    }
                }

                disconnectFromBooth(
                    prevProps.activeExhibitorId,
                    this.props.user._id,
                    connectTimestampOrNow,
                    moment.utc(),
                    trackBoothStatistics
                );
            }
            if (this.props.activeExhibitorId) {
                this.setState({connectTimestamp: moment.utc()});
                connectToBooth(this.props.activeExhibitorId, this.props.user._id);
            }
            this.handleSetUpBoothData();
            this.setState({
                currentTab: 'chat',
            });
        }
    }

    componentWillUnmount() {
        const {connectTimestamp} = this.state;
        let connectTimestampOrNow = connectTimestamp;
        if (!connectTimestampOrNow) {
            connectTimestampOrNow = moment.utc();
        }

        // emit socket event to let know that a visitor has left the booth
        if (this.props.activeExhibitorId) {
            const isRepresentative = checkBoothOwnerOrRepresentative(this.props.exhibitor, this.props.user._id);
            let trackBoothStatistics = !isRepresentative;

            const {event, user} = this.props;
            const {owner} = event;
            if (owner._id === user._id) {
                trackBoothStatistics = false;
                let findEventRoles = user.eventRoles.find((el) => el?.event?._id === event?._id);
                if (findEventRoles) {
                    if (findEventRoles?.roles?.includes('participant')) {
                        trackBoothStatistics = true;
                    }
                }
            }

            disconnectFromBooth(
                this.props.activeExhibitorId,
                this.props.user._id,
                connectTimestampOrNow,
                moment.utc(),
                trackBoothStatistics
            );
        }
        window.removeEventListener('resize', this.setActiveElementsPositions);
    }

    setupBeforeUnloadListener = () => {
        const {activeExhibitorId} = this.props;
        const {connectTimestamp} = this.state;
        let connectTimestampOrNow = connectTimestamp;
        if (!connectTimestampOrNow) {
            connectTimestampOrNow = moment.utc();
        }

        window.addEventListener('beforeunload', (ev) => {
            ev.preventDefault();
            if (activeExhibitorId) {
                const isRepresentative = checkBoothOwnerOrRepresentative(this.props.exhibitor, this.props.user._id);
                let trackBoothStatistics = !isRepresentative;

                const {event, user} = this.props;
                const {owner} = event;
                if (owner._id === user._id) {
                    trackBoothStatistics = false;
                    let findEventRoles = user.eventRoles.find((el) => el?.event?._id === event?._id);
                    if (findEventRoles) {
                        if (findEventRoles?.roles?.includes('participant')) {
                            trackBoothStatistics = true;
                        }
                    }
                }

                disconnectFromBooth(
                    this.state.exhibitor._id,
                    this.props.user._id,
                    connectTimestampOrNow,
                    moment.utc(),
                    trackBoothStatistics
                );
            }
        });
    };

    handleSetUpBoothData = () => {
        const {allEventUsers} = this.props;
        // get the exhibitor data from the event
        const exhibitorData = this.props.exhibitor;
        const boothData = exhibitorData.booth;

        let exhibitorRepresentatives = [];

        // set the active Wall to the booth Wall of this exhibitor
        // set up the array of users that we want to highlight the posts for
        // in the booth wall, we need to highlight the representatives posts

        let highlightUsers = [];
        let canModerateChat = [];

        // add booth owner to overseer users
        if (exhibitorData.user) {
            canModerateChat.push(exhibitorData.user);
        }

        boothData.medalionRepresentatives.forEach((representative) => {
            let userId = getUserIdFromParticipantId(allEventUsers, representative);
            if (!userId) {
                userId = getUserIdFromExhibitorId(allEventUsers, representative);
            }
            if (userId) {
                highlightUsers.push(userId);
            }
            exhibitorRepresentatives.push(representative);

            // add additional representatives to overseer users
            canModerateChat.push(representative.user);
        });

        if (boothData.presentingRepresentative.user) {
            highlightUsers.push(boothData.presentingRepresentative.user._id);
            canModerateChat.push(boothData.presentingRepresentative.user._id);
        }

        if (boothData.deskRepresentativeLeft.representative) {
            let userId = getUserIdFromParticipantId(allEventUsers, boothData.deskRepresentativeLeft.representative);
            if (!userId) {
                userId = getUserIdFromExhibitorId(allEventUsers, boothData.deskRepresentativeLeft.representative);
            }
            if (userId) {
                highlightUsers.push(userId);
            }
        }

        if (boothData.deskRepresentativeRight.representative) {
            let userId = getUserIdFromParticipantId(allEventUsers, boothData.deskRepresentativeRight.representative);
            if (!userId) {
                userId = getUserIdFromExhibitorId(allEventUsers, boothData.deskRepresentativeRight.representative);
            }
            if (userId) {
                highlightUsers.push(userId);
            }
        }

        const wallData = {
            wallId: exhibitorData.booth.boothWall,
            highlightUsers: highlightUsers,
            canModerateChat: canModerateChat,
        };

        this.props.setActiveWall(wallData);

        let hasFacebookLink =
            exhibitorData.booth.socialMediaLinks.findIndex((socialLink) => socialLink.platform === 'Facebook') !== -1;
        let hasLinkedinLink =
            exhibitorData.booth.socialMediaLinks.findIndex((socialLink) => socialLink.platform === 'LinkedIn') !== -1;
        let hasTwitterLink =
            exhibitorData.booth.socialMediaLinks.findIndex((socialLink) => socialLink.platform === 'Twitter') !== -1;

        let exhibitorVideoId = getVideoIdFromYoutubeUrl(exhibitorData.booth.video);

        // save in state the exhibitor data (including booth data)
        this.setState({
            exhibitor: exhibitorData,
            exhibitorVideoId: exhibitorVideoId,
            hasFacebookSocialLink: hasFacebookLink,
            hasLinkedinSocialLink: hasLinkedinLink,
            hasTwitterSocialLink: hasTwitterLink,
        });
    };

    showLinks = () => {
        this.setState({show: {...this.initialShow, links: true}});
    };

    showDocuments = () => {
        this.setState({show: {...this.initialShow, documents: true}});
    };

    showVideo = () => {
        this.setState({show: {...this.initialShow, video: true}});
        // this.sendStatistics('videoViews');
    };

    closeDialog = () => {
        this.setState({show: {...this.initialShow}});
    };

    handleClickOnLink = (linkId, linkName) => (e) => {
        const actionType = 'boothLinks';
        const isRepresentative = checkBoothOwnerOrRepresentative(this.props.exhibitor, this.props.user._id);
        let trackBoothStatistics = !isRepresentative;

        const {event, user} = this.props;
        const {owner} = event;
        if (owner._id === user._id) {
            trackBoothStatistics = false;
            let findEventRoles = user.eventRoles.find((el) => el?.event?._id === event?._id);
            if (findEventRoles) {
                if (findEventRoles?.roles?.includes('participant')) {
                    trackBoothStatistics = true;
                }
            }
        }

        if (linkId && linkName && trackBoothStatistics) {
            const data = {
                userId: this.props.user._id,
                linkId: linkId,
                linkName: linkName,
                actionType: actionType,
                timestamp: moment.utc(),
            };
            axios({
                method: 'post',
                url: '/exhibitor/' + this.state.exhibitor._id + '/booth-statistics',
                data: data,
            })
                .then((res) => {})
                .catch((err) => {});
        }
        // this.sendStatistics('linkClicks');
    };

    handleClickOnDocument = (documentId, documentName) => (e) => {
        const actionType = 'boothDocuments';
        const isRepresentative = checkBoothOwnerOrRepresentative(this.props.exhibitor, this.props.user._id);
        let trackBoothStatistics = !isRepresentative;

        const {event, user} = this.props;
        const {owner} = event;
        if (owner._id === user._id) {
            trackBoothStatistics = false;
            let findEventRoles = user.eventRoles.find((el) => el?.event?._id === event?._id);
            if (findEventRoles) {
                if (findEventRoles?.roles?.includes('participant')) {
                    trackBoothStatistics = true;
                }
            }
        }

        if (documentId && documentName && trackBoothStatistics) {
            const data = {
                userId: this.props.user._id,
                documentId: documentId,
                documentName: documentName,
                actionType: actionType,
                timestamp: moment.utc(),
            };
            axios({
                method: 'post',
                url: '/exhibitor/' + this.state.exhibitor._id + '/booth-statistics',
                data: data,
            })
                .then((res) => {})
                .catch((err) => {});
        }
        // this.sendStatistics('documentClicks');
    };

    handleVideoExit = (connectTimestamp) => {
        const actionType = 'boothVideos';
        const isRepresentative = checkBoothOwnerOrRepresentative(this.props.exhibitor, this.props.user._id);
        let trackBoothStatistics = !isRepresentative;

        const {event, user} = this.props;
        const {owner} = event;
        if (owner._id === user._id) {
            trackBoothStatistics = false;
            let findEventRoles = user.eventRoles.find((el) => el?.event?._id === event?._id);
            if (findEventRoles) {
                if (findEventRoles?.roles?.includes('participant')) {
                    trackBoothStatistics = true;
                }
            }
        }

        if (connectTimestamp && trackBoothStatistics) {
            const data = {
                userId: this.props.user._id,
                connectTimestamp: connectTimestamp,
                disconnectTimestamp: moment.utc(),
                actionType: actionType,
            };
            axios({
                method: 'post',
                url: '/exhibitor/' + this.state.exhibitor._id + '/booth-statistics',
                data: data,
            })
                .then((res) => {})
                .catch((err) => {});
        }
        // this.sendStatistics('videoViews');
    };

    handleOpenChatWithRepresentative = (userId) => (e) => {
        const privateChatId = getPrivateChatId(this.props.user.privateChats, userId);

        // do not open chat with myself
        if (this.props.user._id !== userId) {
            if (privateChatId) {
                this.props.onOpenPrivateChat(privateChatId);
            } else {
                if (userId !== this.props.user._id && !this.props.loading) {
                    if (userId !== this.props.user._id) {
                        this.props.onCreatePrivateChat(userId);
                    }
                }
            }

            // booth statistics
            const actionType = 'boothRepresentatives';
            const isRepresentative = checkBoothOwnerOrRepresentative(this.props.exhibitor, this.props.user._id);
            let trackBoothStatistics = !isRepresentative;

            const {event, user} = this.props;
            const {owner} = event;
            if (owner._id === user._id) {
                trackBoothStatistics = false;
                let findEventRoles = user.eventRoles.find((el) => el?.event?._id === event?._id);
                if (findEventRoles) {
                    if (findEventRoles?.roles?.includes('participant')) {
                        trackBoothStatistics = true;
                    }
                }
            }

            if (userId && trackBoothStatistics) {
                const {allEventUsers} = this.props;
                let userData = getParticipantData(allEventUsers, userId);
                let fullName = '';
                if (userData) {
                    fullName = `${userData.user.first} ${userData.user.last}`;
                }
                const data = {
                    userId: this.props.user._id,
                    representativeId: userId,
                    representativeName: fullName,
                    actionType: actionType,
                    timestamp: moment.utc(),
                };
                axios({
                    method: 'post',
                    url: '/exhibitor/' + this.state.exhibitor._id + '/booth-statistics',
                    data: data,
                })
                    .then((res) => {})
                    .catch((err) => {});
            }
        }
    };

    handleChangeTab = (tabName) => (e) => {
        this.setState({
            currentTab: tabName,
        });
    };

    getCurrentExhibitor = () => {
        const {exhibitors, activeExhibitorId} = this.props;
        const exhibitor = exhibitors.find((exhibitor) => exhibitor._id === activeExhibitorId);
        return exhibitor;
    };

    render() {
        const {translation, defaultTranslation, exhibitor} = this.props;
        const {currentTab} = this.state;

        let exhibitorRepresentatives = [];
        if (exhibitor) {
            if (exhibitor.type === 'scholar' && exhibitor.booth.presentingRepresentative.user) {
                exhibitorRepresentatives = [
                    exhibitor.booth.presentingRepresentative,
                    ...exhibitor.booth.medalionRepresentatives,
                ];
            } else {
                exhibitorRepresentatives = [...exhibitor.booth.medalionRepresentatives];
            }
            if (exhibitor.booth.deskRepresentativeLeft.user) {
                exhibitorRepresentatives.push(exhibitor.booth.deskRepresentativeLeft);
            }
            if (exhibitor.booth.deskRepresentativeRight.user) {
                exhibitorRepresentatives.push(exhibitor.booth.deskRepresentativeRight);
            }
        } else {
            return <Spinner />;
        }

        return (
            <div
                className={`exhibitor-booth-mobile ${
                    exhibitorRepresentatives.length +
                        exhibitor.booth.pdfPresentation +
                        exhibitor.booth.documents.length +
                        exhibitor.booth.socialMediaLinks.length +
                        exhibitor.booth.links.length +
                        exhibitor.booth.video.length ===
                    0
                        ? 'hide'
                        : ''
                }`}
            >
                <div onDragStart={preventDefaultDrag} className={`exhibitor-mobile-header scholar`}>
                    {exhibitor?.type === 'scholar' ? (
                        <PdfPreview translation={translation} defaultTranslation={defaultTranslation} />
                    ) : (
                        <>
                            {exhibitor.booth.logo ? (
                                <div onDragStart={preventDefaultDrag} className="booth-logo-container">
                                    <img
                                        className="d-flex m-auto"
                                        draggable="false"
                                        src={`${exhibitor.filesUrl}${exhibitor.booth.logo}`}
                                        alt=""
                                    />
                                </div>
                            ) : (
                                <div onDragStart={preventDefaultDrag} className="exhibitor-company">
                                    <span>{exhibitor.company}</span>
                                </div>
                            )}
                            {exhibitor?.type === 'company' && (
                                <PdfPreview translation={translation} defaultTranslation={defaultTranslation} />
                            )}
                        </>
                    )}
                </div>
                <div onDragStart={preventDefaultDrag} className="tabs-container">
                    {exhibitorRepresentatives.length > 0 ? (
                        <div
                            className={`button-tab tab ${currentTab === 'chat' ? 'active' : ''}`}
                            onClick={this.handleChangeTab('chat')}
                        >
                            {translation?.showfloor.mobileTabChat || defaultTranslation?.showfloor.mobileTabChat}
                        </div>
                    ) : null}
                    {exhibitor.type === 'scholar' ? (
                        <div
                            className={`button-tab tab ${currentTab === 'poster' ? 'active' : ''}`}
                            onClick={this.handleChangeTab('poster')}
                        >
                            {translation?.showfloor.mobileTabPoster || defaultTranslation?.showfloor.mobileTabPoster}
                        </div>
                    ) : null}
                    {!isWebview() ? (
                        exhibitor.booth.documents.length > 0 ? (
                            <div
                                className={`button-tab tab ${currentTab === 'files' ? 'active' : ''}`}
                                onClick={this.handleChangeTab('files')}
                            >
                                {translation?.showfloor.mobileTabFiles || defaultTranslation?.showfloor.mobileTabFiles}
                            </div>
                        ) : null
                    ) : null}
                    {exhibitor.booth.socialMediaLinks.length + exhibitor.booth.links.length > 0 ? (
                        <div
                            className={`button-tab tab ${currentTab === 'links' ? 'active' : ''}`}
                            onClick={this.handleChangeTab('links')}
                        >
                            {translation?.showfloor.mobileTabLinks || defaultTranslation?.showfloor.mobileTabLinks}
                        </div>
                    ) : null}
                    {exhibitor.booth.video && (
                        <div
                            className={`button-tab tab ${currentTab === 'video' ? 'active' : ''}`}
                            onClick={this.handleChangeTab('video')}
                        >
                            {translation?.showfloor.mobileTabVideo || defaultTranslation?.showfloor.mobileTabVideo}
                        </div>
                    )}
                </div>
                {currentTab === 'chat' && (
                    <Representatives
                        exhibitor={exhibitor}
                        exhibitorRepresentatives={exhibitorRepresentatives}
                        translation={translation}
                        defaultTranslation={defaultTranslation}
                    />
                )}
                {currentTab === 'poster' && <Poster translation={translation} exhibitor={exhibitor} />}
                {currentTab === 'files' && <Files exhibitor={exhibitor} />}
                {currentTab === 'links' && <Links exhibitor={exhibitor} />}
                {currentTab === 'video' && <Video exhibitor={exhibitor} />}
                <NotificationPresentationStarts />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        allEventUsers: state.eventUsers.allEventUsers,
        user: state.user.data,
        loading: state.user.loading,
        activeExhibitorId: state.exhibitors.activeExhibitorId,
        exhibitor: state.exhibitor.data,
        exhibitors: state.exhibitors.approvedExhibitors,
        eventRoles: state.user.eventRoles,
        loadingExhibitor: state.exhibitor.loading,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveWall: (wallData) => dispatch(actions.setActiveWall(wallData)),
        onOpenPrivateChat: (privateChatId) => dispatch(actions.openPrivateChat(privateChatId)),
        onCreatePrivateChat: (targetUserId) => dispatch(actions.createPrivateChat(targetUserId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitorBoothMobile);
