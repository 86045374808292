import React, { Component } from 'react';
import {connect} from 'react-redux';
import {
    Checkbox,
    Radio,
    RadioGroup,
    FormGroup,
    FormControl,
    FormLabel,
    FormControlLabel,
} from '@material-ui/core';

class MatchingParticipantQuestion extends Component {
    state = {
        answered: false,
        selectedOption: {},
    };

    isCorrect = (option) => option === this.state.question.answer;
    onOptionClicked = (option) => () => this.setState({answered: true, selectedOption: option});
    resetQuestion = () =>
        this.setState({answered: false, selectedOption: {}}, () => this.onNextClicked(this.state.selectedOption));

    render() {
        const {
            answers,
            question,
            handleChange,
            translation,
            defaultTranslation,
            isMyAccount,
            questionIndex,
            isLargeScreen
        } = this.props;

        return (
            <div className="question">
                {isMyAccount && !isLargeScreen && (
                    <p className="question-requirement mobile">
                        {question?.isRequired
                            ? translation?.matching?.requiredQuestion || defaultTranslation?.matching?.requiredQuestion
                            : translation?.matching?.optionalQuestion || defaultTranslation?.matching?.optionalQuestion}
                    </p>
                )}
                <div className="question-title">{question?.title}</div>
                <div className="question-wrapper">
                    <section>
                        {question?.type === 'checkbox' ? (
                            <FormControl classes={{root: 'form-control-outlined'}} component="fieldset" fullWidth>
                                <FormLabel component="legend">
                                    {translation?.matching?.multiSelect || defaultTranslation?.matching?.multiSelect}
                                </FormLabel>
                                <FormGroup aria-label={question?.title} name={question?.title} value={question?.title}>
                                    {question?.answers?.map((answer, answerIndex) => {
                                        return (
                                            <FormControlLabel
                                                value={answer.title}
                                                control={
                                                    <Checkbox
                                                        onChange={handleChange(question._id, 'checkbox')}
                                                    />
                                                }
                                                label={answer.title}
                                                key={answerIndex}
                                                checked={answers[questionIndex]?.answerIds?.includes(answer._id) || false}
                                            />
                                        );
                                    })}
                                </FormGroup>
                            </FormControl>
                        ) : (
                            <FormControl classes={{root: 'form-control-outlined'}} component="fieldset" fullWidth>
                                <FormLabel component="legend">
                                    {translation?.matching?.singleSelect || defaultTranslation?.matching?.singleSelect}
                                </FormLabel>
                                <FormGroup aria-label={question?.title} name={question?.title} value={question?.title}>
                                    <RadioGroup
                                        name={question?.title}
                                        value={question?.title}
                                        onChange={handleChange(question._id, 'radio')}
                                    >
                                        {question?.answers?.map((answer, answerIndex) => {
                                            return (
                                                <FormControlLabel
                                                    classes={{root: 'radio-fix'}}
                                                    key={answerIndex}
                                                    data-checked
                                                    value={answer.title}
                                                    control={
                                                        <Radio
                                                            disableFocusRipple
                                                            disableTouchRipple
                                                            disableRipple
                                                            checked={
                                                                answers[questionIndex]?.answerIds?.includes(answer._id) ||
                                                                false
                                                            }
                                                        />
                                                    }
                                                    label={answer.title}
                                                    labelPlacement="end"
                                                />
                                            );
                                        })}
                                    </RadioGroup>
                                </FormGroup>
                            </FormControl>
                        )}
                    </section>
                    {isMyAccount && isLargeScreen && (
                        <p className="question-requirement">
                            {question?.isRequired
                                ? translation?.matching?.requiredQuestion || defaultTranslation?.matching?.requiredQuestion
                                : translation?.matching?.optionalQuestion || defaultTranslation?.matching?.optionalQuestion}
                        </p>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        event: state.event.data,
        eventId: state.event.eventId,
        eventMatching: state.organizer.eventMatching,
        isLargeScreen: state.layout.isLargeScreen,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchingParticipantQuestion);
