import React from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import ColoredScrollbars from "../../SmallLayoutComponents/ColoredScrollbars";
import OnDemandVideosAuditorium from "../../HOC/Wall/OnDemandVideosAuditorium";
import { checkIfEventHasEnded, preventDefaultDrag } from "../../Utils/utils";
import "../../CSS/auditorium.scss";
import "../../CSS/auditoriumArchive.scss";
import colors from "../../CSS/_variables.module.scss";
import { ReactComponent as Search } from "../../Images/svg/search-icon.svg";
import AuditoriumBackgroundImage from "../../SmallLayoutComponents/AuditoriumBackgroundImage";
import AuditoriumBanners from "../Auditorium/AuditoriumBanners";
import Spinner from "../../SmallLayoutComponents/Spinner";
import LeftSideMenu from "../../Components/LeftSideMenu";

class AuditoriumArhive extends React.Component {
	state = {
		search: "",
	};

	componentDidMount() {
		const { eventId } = this.props;
		this.props.onGetOnDemandVideos(eventId);
		this.props.onSetEventProtectedMenu();

		let eventHasEnded = checkIfEventHasEnded(
			this.props.event,
			this.props.event.timezoneValue,
		);
		if (!eventHasEnded) {
			// this.props.history.push('/event/' + this.props.event.slug + '/auditorium');
		}
		this.props.setHasVideoWall(false);
		this.props.closeTopNavigation();
	}

	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	renderNoOnDemandVideos = () => {
		const { isLargeScreen, event, eventId, translation, defaultTranslation } =
			this.props;
		let auditorium = event.auditoriums[0];
		return (
			<div onDragStart={preventDefaultDrag} className="position-background">
				<div
					onDragStart={preventDefaultDrag}
					className={`auditorium-page mobile-page-container no-videos`}
				>
					{isLargeScreen ? (
						<>
							<div onDragStart={preventDefaultDrag} className={`centerOfPage`}>
								<div
									onDragStart={preventDefaultDrag}
									className="relativeCenter"
								>
									<AuditoriumBanners
										filesUrl={
											auditorium?.stageBanners
												? `${process.env.REACT_APP_EVENT_FOLDER}${eventId}/`
												: event.brandingData.filesUrl
										}
										image={
											auditorium?.stageBanners
												? auditorium.stageBanners
												: event.brandingData.lobbyBanner
										}
									/>
									<div
										onDragStart={preventDefaultDrag}
										className="videoWrapper"
									>
										<div
											onDragStart={preventDefaultDrag}
											className="video-container d-flex align-items-center justify-content-center"
										>
											<div
												onDragStart={preventDefaultDrag}
												className="no-videos-message-container"
											>
												{translation?.auditoriumArchive?.noVideosAvailable ||
													defaultTranslation?.auditoriumArchive
														?.noVideosAvailable}
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					) : (
						<div
							onDragStart={preventDefaultDrag}
							className="no-videos-message-container"
						>
							{translation?.auditoriumArchive?.noVideosAvailable ||
								defaultTranslation?.auditoriumArchive?.noVideosAvailable}
						</div>
					)}
				</div>
			</div>
		);
	};

	render() {
		const { onDemandVideos } = this.props;
		const hasOnDemandVideos = onDemandVideos?.auditoriums.length > 0;

		return (
			<div
				onDragStart={preventDefaultDrag}
				className="auditorium-archive-page test"
			>
				{onDemandVideos?.loading && <Spinner />}
				<LeftSideMenu />
				{!hasOnDemandVideos && !onDemandVideos?.loading ? (
					this.renderNoOnDemandVideos()
				) : (
					<div onDragStart={preventDefaultDrag} className="program-dashboard">
						<div onDragStart={preventDefaultDrag} className="program-section">
							<div onDragStart={preventDefaultDrag} className="smallContainer">
								<div onDragStart={preventDefaultDrag} className="page-title">
									<span
										onDragStart={preventDefaultDrag}
										draggable="false"
										className="name"
									>
										{this.props.event && this.props.event.name}
									</span>
									<div
										onDragStart={preventDefaultDrag}
										className="search-container position-relative d-none"
									>
										<input
											id="search"
											placeholder="Tap to search"
											value={this.state.search}
											onChange={this.handleChange("search")}
										/>
										<Search fill={colors.grey} />
									</div>
								</div>
								{this.props.event ? (
									<div
										onDragStart={preventDefaultDrag}
										className="auditoriums-archive-container"
									>
										<ColoredScrollbars>
											<div
												onDragStart={preventDefaultDrag}
												className="on-demand-videos-container"
											>
												{onDemandVideos.auditoriums.length === 0 ? (
													<div
														onDragStart={preventDefaultDrag}
														className="no-videos"
													>
														No videos
													</div>
												) : (
													<div
														onDragStart={preventDefaultDrag}
														className="on-demand-videos-list"
													>
														{onDemandVideos.auditoriums.map((auditorium) => {
															return (
																<OnDemandVideosAuditorium
																	auditorium={auditorium}
																	key={auditorium._id}
																/>
															);
														})}
													</div>
												)}
											</div>
										</ColoredScrollbars>
									</div>
								) : null}
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		eventId: state.event.eventId,
		event: state.event.data,
		user: state.user.data,
		languages: state.languages,
		translation: state.languages.translations[state.languages.platformLanguage],
		defaultTranslation: state.languages.translations["en"],
		onDemandVideos: state.onDemandVideos,
		isLargeScreen: state.layout.isLargeScreen,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeTopNavigation: () => dispatch(actions.topNavClose()),
		setHasVideoWall: (booleanValue) =>
			dispatch(actions.sideNavHasVideoWall(booleanValue)),
		onSetEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
		onGetOnDemandVideos: (eventId) =>
			dispatch(actions.getOnDemandVideos(eventId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditoriumArhive);
