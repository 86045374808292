import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

export default function QRCodeConsentDialog({
	open,
	user,
	eventId,
	closeDialog,
	handleUpdateUserInformation,
}) {
	if (!user.qrCodeConsentTimestamp) {
		return (
			<Dialog
				id="my-events-dialog"
				open={open}
				onClose={closeDialog}
				aria-labelledby="form-dialog-title"
			>
				<DialogContent>
					<div className="my-events-mobile">
						By enabling your QR code, you consent to sharing your Name, Email,
						and registration details with any user you connect with through the
						QR code functionality.
					</div>
				</DialogContent>
				<DialogActions>
					<Button type="button" onClick={closeDialog}>
						Cancel
					</Button>
					<Button type="submit" onClick={handleUpdateUserInformation}>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}
