import * as actionTypes from './actionTypes';

export const setPlatformMenu = () => {
    return {
        type: actionTypes.SET_PLATFORM_MENU,
    };
};

export const setEventPublicMenu = () => {
    return {
        type: actionTypes.SET_EVENT_PUBLIC_MENU,
    };
};

export const setEventProtectedMenu = () => {
    return {
        type: actionTypes.SET_EVENT_PROTECTED_MENU,
    };
};

export const setNoMenu = () => {
    return {
        type: actionTypes.SET_NO_MENU,
    };
};

export const setAdminMenu = () => {
    return {
        type: actionTypes.SET_ADMIN_MENU,
    };
};

export const setSessionGuestMenu = () => {
    return {
        type: actionTypes.SET_SESSION_GUEST_MENU,
    };
};

export const setIsMobile = () => {
    return {
        type: actionTypes.SET_IS_MOBILE,
    };
};

export const setIsTablet = () => {
    return {
        type: actionTypes.SET_IS_TABLET,
    };
};

export const setIsLargeScreen = () => {
    return {
        type: actionTypes.SET_IS_LARGE_SCREEN,
    };
};

export const showSmartTooltip = (show) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SEE_PROGRAM_TOOLTIP,
            payload: show,
        });
    };
};
