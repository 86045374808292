import Checkbox from '@material-ui/core/Checkbox';
import {Tooltip} from '@material-ui/core';
import {useSelector} from 'react-redux';

import FormControlLabel from '@material-ui/core/FormControlLabel';

const NotificationList = ({title, notificationList, handleChangeNotification, notificationsName}) => {
    const platformLanguage = useSelector((state) => state.languages.platformLanguage);

    return (
        <div className={`notification-list ${platformLanguage === 'ar' ? 'margin-left-auto' : ''}`}>
            <h5 className={`list-title ${platformLanguage === 'ar' ? 'list-title-ar-helper' : ''}`}>{title}</h5>

            <ul className={`checkbox-list ${platformLanguage === 'ar' ? 'ar-helper' : ''}`}>
                {notificationList.map((notification, index) => (
                    <li>
                        {notification.shortName === 'platformPrivacyPolicy' ? (
                            <Tooltip
                                placement="bottom-start"
                                classes={{
                                    popper: `popper-helper  ${platformLanguage === 'ar' ? 'ar-helper' : ''}`,
                                }}
                                arrow
                                tabIndex="0"
                                title={
                                    <span style={{fontSize: '14px', lineHeight: '20px', fontWeight: '500'}}>
                                        Due to legal reasons the check-box for Platform Privacy Policy and Terms &
                                        Conditions updates cannot be disabled
                                    </span>
                                }
                            >
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={notification.value}
                                            onChange={handleChangeNotification(notificationsName)}
                                            value={notification.shortName}
                                            disabled={notification.shortName === 'platformPrivacyPolicy' ? true : false}
                                        />
                                    }
                                    label={notification.name}
                                />
                            </Tooltip>
                        ) : (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        checked={notification.value}
                                        onChange={handleChangeNotification(notificationsName)}
                                        value={notification.shortName}
                                        disabled={notification.shortName === 'platformPrivacyPolicy' ? true : false}
                                    />
                                }
                                label={notification.name}
                            />
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default NotificationList;
