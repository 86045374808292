import React, {PureComponent} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import InformDialog from '../../../Dialogs/InformDialog';
import {preventDefaultDrag} from '../../../Utils/utils';

class ShowfloorHeaderLink extends PureComponent {
    state = {
        showNoShowfloorDialog: false,
        showNoShowfloorAccessDialog: false,
    };

    handleOpenNoShowfloorDialog = () => this.setState({showNoShowfloorDialog: true});
    handleOpenNoShowfloorAccess = () => this.setState({showNoShowfloorAccessDialog: true});

    closeDialog = () =>
        this.setState({
            showNoShowfloorDialog: false,
            showNoShowfloorAccessDialog: false,
        });

    render() {
        const {showNoShowfloorDialog, showNoShowfloorAccessDialog} = this.state;
        const {
            translation,
            event,
            eventSlug,
            location,
            resourcesAccess,
            eventRoles,
            defaultTranslation,
        } = this.props;

        const activeShowfloor = location.pathname.indexOf(`/${eventSlug}/showfloor`) > -1;

        let hasAccessToShowfloor = true;
        if (event.hasAccessManagement && eventRoles.isParticipant && resourcesAccess) {
            if (!resourcesAccess.showfloors?.length) {
                hasAccessToShowfloor = false;
            }
        }

        if (
            (event.exhibitorHasAccessManagement || event.scholarHasAccessManagement) &&
            eventRoles.isExhibitor &&
            resourcesAccess
        ) {
            if (!resourcesAccess.showfloors?.length) {
                hasAccessToShowfloor = false;
            }
        }

        return (
            <>
                {event.restrictShowfloorAccess ? (
                    <div onDragStart={preventDefaultDrag} className="button-link">
                        <p onDragStart={preventDefaultDrag} onClick={this.handleOpenNoShowfloorDialog} className="button-link-label">
                            {translation?.showfloor.exhibits ||
                            defaultTranslation?.showfloor.exhibits}
                        </p>
                    </div>
                ) : (
                    <>
                        {!hasAccessToShowfloor ? (
                            <div onDragStart={preventDefaultDrag} className="button-link">
                                <p onDragStart={preventDefaultDrag} onClick={this.handleOpenNoShowfloorAccess} className="button-link-label">
                                    {translation?.showfloor.exhibits ||
                                    defaultTranslation?.showfloor.exhibits}
                                </p>
                            </div>
                        ) : (
                            <Link
                                tabIndex="0"
                                className={`button-link ${activeShowfloor ? 'active' : ''}`}
                                to={{pathname: `/event/${eventSlug}/showfloor/`}}
                            >
                                <p className="button-link-label">
                                    {translation?.showfloor.exhibits ||
                                    defaultTranslation?.showfloor.exhibits}
                                </p>
                            </Link>
                        )}
                    </>
                )}
                {showNoShowfloorDialog && (
                    <InformDialog
                        open={showNoShowfloorDialog}
                        onClose={this.closeDialog}
                        dialogClasses={'closed-doors'}
                        dialogTitle={event.brandingData.closedShowfloorTitle}
                        dialogDescription={event.brandingData.closedShowfloorText}
                        dialogButtonLabel={`${translation?.closedShowfloorDialog.button}`}
                    />
                )}
                {showNoShowfloorAccessDialog && (
                    <InformDialog
                        open={showNoShowfloorAccessDialog}
                        onClose={this.closeDialog}
                        dialogTitle={`${translation?.closedShowfloorDialog.title}`}
                        dialogDescription={`${translation?.closedShowfloorDialog.contentPackage}`}
                        dialogButtonLabel={`${translation?.closedShowfloorDialog.button}`}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventSlug: state.event.eventSlug,
        eventRoles: state.user.eventRoles,
        resourcesAccess: state.user.resourcesAccess,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default withRouter(connect(mapStateToProps)(ShowfloorHeaderLink));
