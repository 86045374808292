import React from 'react';
import Button from '@material-ui/core/Button';
import * as actions from '../store/actions';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {preventDefaultDrag} from '../Utils/utils';

class FullOverlay extends React.Component {
    redirectToEvent = (eventSlug, eventId) => (e) => {
        if (eventSlug !== this.props.eventSlug) {
            const {user} = this.props;
            const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === eventSlug);
            if (userRolesInEvent.roles.includes('organizer')) {
                this.props.history.push(`/event/${eventSlug}/dashboard/settings`);
            } else if (userRolesInEvent.roles.includes('exhibitor')) {
                this.props.history.push(`/event/${eventSlug}/exhibitor-dashboard`);
            } else if (userRolesInEvent.roles.includes('exhibitorRep')) {
                // this.props.history.push(`/event/${eventSlug}/exhibitor-booth-view`);
                this.props.history.push(`/event/${eventSlug}/lobby`);
            } else {
                this.props.history.push(`/event/${eventSlug}/lobby`);
            }
            this.props.setEventSlug(eventSlug);
            this.props.setEventId(eventId);

            // close modal when organizer unregisters user
            if (this.props.closeOnRedirect) {
                this.props.handleClose();
            }
        }
    };

    redirectToCreateEvent = () => {
        // close modal when organizer unregisters user
        if (this.props.closeOnRedirect) {
            this.props.handleClose();
        }

        this.props.history.push(`/create-event`);
    };

    render() {
        const {user, eventId, translation, defaultTranslation} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} className="full-overlay">
                <div onDragStart={preventDefaultDrag} className="content">
                    <h3>{this.props.title}</h3>
                    <p>{this.props.text}</p>
                    {this.props.loggedIn && user.eventRoles.length > 0 && (
                        <>
                            {user.eventRoles.length > 1
                            ?
                                    <div onDragStart={preventDefaultDrag} className="my-events-dialog">
                                        <p>
                                            {translation?.notAvailable.fullOverlayEvents ||
                                              defaultTranslation?.notAvailable.fullOverlayEvents}
                                        </p>
                                        <div className="my-events-container">
                                            <ul className="my-events-list">
                                                {user.eventRoles
                                                    .slice(0)
                                                    .reverse()
                                                    .map((eventRole) => {
                                                        if (eventId === eventRole.event._id) {
                                                            return null;
                                                        }
                                                        return (
                                                            <li
                                                                className={'row ' + (eventId === eventRole.event._id ? 'active' : '')}
                                                                key={eventRole.event._id}
                                                                onClick={this.redirectToEvent(
                                                                    eventRole.event.slug,
                                                                    eventRole.event._id
                                                                )}
                                                            >
                                                                <span>{eventRole.event.name}</span>
                                                            </li>
                                                        );
                                                    })}
                                                {/*<li className="row" onClick={this.redirectToCreateEvent}>*/}
                                                {/*    /!*<span>+ Create event</span>*!/*/}
                                                {/*    <span>{`+ ${*/}
                                                {/*        translation?.myEventsDropdown.createButtonDesktop ||*/}
                                                {/*        defaultTranslation?.myEventsDropdown.createButtonDesktop*/}
                                                {/*    }`}</span>*/}
                                                {/*</li>*/}
                                            </ul>
                                        </div>
                                    </div>
                            : null}
                        </>
                    )}
                    <Button variant="text" onClick={this.props.handleClick}>
                        {this.props.buttonText}
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        loggedIn: state.user.loggedIn,
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setEventId: (eventId) => dispatch(actions.setEventId(eventId)),
        setEventSlug: (eventSlug) => dispatch(actions.setEventSlug(eventSlug)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FullOverlay));
