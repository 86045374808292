import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import BackupIcon from '@material-ui/icons/Backup';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactComponent as UploadIcon } from '../../../Images/svg/upload-file.svg';
import { deleteLiveSessionThumbnail, updateLiveSessionThumbnail } from '../../../store/actions';
import './thumbnailImage.scss';

const ThumbnailImage = (props) => {
    const dispatch = useDispatch();
    const [newImageFile, setNewImageFile] = useState();
    const [imagePreviewUrl, setImagePreviewUrl] = useState();
    const [imageError, setImageError] = useState();
    const [thumbnailDialogOpened, setThumbnailDialogOpened] = useState(false);
    const translation = props.translation;
    const defaultTranslation = props.defaultTranslation;

    const session = useSelector((state) => state.liveSession.liveSession);
    const routeParams = useParams();
    const {sessionId} = routeParams;

    useEffect(() => {
        if (session?.thumbnail) {
            setImagePreviewUrl(`${process.env.REACT_APP_SESSION_FOLDER}${session.thumbnail}`);
            setNewImageFile('');
        } else {
            setImagePreviewUrl('');
            setNewImageFile('');
        }
    }, [session]);

    const handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let isValid = true;
        if (!file) {
            return;
        }

        isValid = file.size < 2 * 1024 * 1024 && isValid;
        if (file) {
            if (!isValid) {
                setImagePreviewUrl(null);
                setImageError('File too large. 2Mb max file size.');
            }
            if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg' && file.type !== 'image/gif') {
                setImagePreviewUrl(null);
                setImageError('File type not supported. Please use one of the following: jpeg, jpg, jfif, gif or png.');
            }
            isValid = (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/gif') && isValid;

            reader.onloadend = () => {
                if (isValid) {
                    setNewImageFile(file);
                    setImagePreviewUrl(reader.result);
                    setImageError('');
                }
            };

            reader.readAsDataURL(file);
        }
        // Reset input otherwise second upload of the SAME IMAGE won't trigger input onChange event
        e.target.value = '';
    };

    const handleSetImage = async () => {
        await dispatch(updateLiveSessionThumbnail(session._id, newImageFile));
    };

    const handleDeleteImage = async () => {
        await dispatch(deleteLiveSessionThumbnail(session._id));
    };

    const openUploadThumbnailDialog = () => {
        setThumbnailDialogOpened(true);
    }
    const closeUploadThumbnailDialog = () => {
        setThumbnailDialogOpened(false);
    }

    return (
        <div className="module-body">
            <div className="module-link_blocks">
                <div className="module-link_block" onClick={openUploadThumbnailDialog}>
                    <span>
                        {translation?.sessions.thumbnailImage || defaultTranslation?.sessions.thumbnailImage}
                    </span>
                    <BackupIcon className="button_icon" />
                </div>
            </div>
            <Dialog
                open={thumbnailDialogOpened}
                onClose={closeUploadThumbnailDialog}
                className={'session-thumbnail-dialog'}
            >
                <DialogTitle>
                    {translation?.sessions.uploadThumbnail || defaultTranslation?.sessions.uploadThumbnail}
                    <div className={'close-dialog-button'}>
                        <CloseIcon onClick={closeUploadThumbnailDialog}/>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <input
                        id={`thumbnailImage${sessionId}`}
                        type="file"
                        className="upload-image-button d-none"
                        onChange={handleImageChange}
                    />
                    <div className="image-container">
                        {!imagePreviewUrl && (
                            <label className="upload-label" htmlFor={`thumbnailImage${sessionId}`}>
                                <UploadIcon/>
                                <span>
                                    {translation?.sessions.clickToUpload || defaultTranslation?.sessions.clickToUpload}
                                </span>
                            </label>
                        )}
                        {imagePreviewUrl && (
                            <label htmlFor={`thumbnailImage${sessionId}`}>
                                <img
                                    draggable="false"
                                    onDragStart={(e) => e.preventDefault()}
                                    src={imagePreviewUrl}
                                    alt="preview"
                                />
                            </label>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className="buttons-container">
                        {session?.thumbnail && !newImageFile
                            ?<Button
                                type="button"
                                className={'remove'}
                                classes={{label: 'dialog-btn-uppercase'}}
                                onClick={handleDeleteImage}
                                disabled={!session?.thumbnail ? true : false}
                            >
                                <span>Remove</span>
                            </Button>
                            :<Button
                                type="button"
                                classes={{label: 'dialog-btn-uppercase'}}
                                onClick={handleSetImage}
                                disabled={!imagePreviewUrl || imageError ? true : false}
                            >
                                <span>Save</span>
                            </Button>
                        }
                        {imageError && <p className='error-text'>{imageError}</p>}
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ThumbnailImage;
