import * as actionTypes from '../../../actions/actionTypes';

import {initialState} from '../initialState';
import {updateObject} from '../../../utilityStateFunction';

export default (state, {type, payload}) => {
    if (state === undefined) {
        return initialState.zoomSessionDashboard;
    }

    switch (type) {
        case actionTypes.GET_PROFILE_DATA_START:
        case actionTypes.ZOOM_SESSION_STREAM_START_REQUEST:
            return updateObject(state.zoomSessionDashboard, {
                isLoading: true,
            });
        case actionTypes.GET_PROFILE_DATA_SUCCESS:
            return updateObject(state.zoomSessionDashboard, {
                isLoading: false,
                profile: payload,
            });
        case actionTypes.GET_PROFILE_DATA_FAIL:
        case actionTypes.ZOOM_SESSION_STREAM_START_FAIL:
            return updateObject(state.zoomSessionDashboard, {
                isLoading: false,
            });
        case actionTypes.GET_SESSION_DATA_SUCCESS: {
            const {session} = payload;
            const getSession = session || payload;

            if (getSession.type !== 'zoom') return state.zoomSessionDashboard;

            return updateObject(state.zoomSessionDashboard, {
                session: getSession,
            });
        }
        case actionTypes.ZOOM_SESSION_STREAM_START_SUCCESS:
        case actionTypes.ZOOM_SESSION_STREAM_STOP_SUCCESS:
        case actionTypes.ZOOM_SESSION_STREAM_START_RECORD_SUCCESS:
        case actionTypes.ZOOM_SESSION_STREAM_STOP_RECORD_SUCCESS: {
            return updateObject(state.zoomSessionDashboard, {
                isLoading: false,
                session: {
                    ...state.zoomSessionDashboard.session,
                    ...payload,
                },
            });
        }
        default:
            return state.zoomSessionDashboard;
    }
};
