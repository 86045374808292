/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import moment from "moment";
import FullPageSpinner from "../../SmallLayoutComponents/FullPageSpinner";
import { connect } from "react-redux";
import Slider from "react-slick";
import * as actions from "../../store/actions/index";
import { listSpeakers } from "../../store/actions/eventSpeakersActions";
import {
	getEventDaysArray,
	getVideoIdFromYoutubeUrl,
	preventDefaultDrag,
	urlifyString,
} from "../../Utils/utils";
import VideoDialog from "../../Dialogs/VideoDialog";
import PartnersSlider from "../../SmallLayoutComponents/PartnersSlider";
import Footer from "../../SmallLayoutComponents/Footer";
import EventProgram from "../../SmallLayoutComponents/EventHomepage/EventProgram";

import "../../CSS/organizerDashboard.scss";
import "../../CSS/landing-page.scss";
import "../../CSS/staticPages.scss";
import "../../CSS/homepage.scss";
import "react-tabs/style/react-tabs.css";

import { ReactComponent as PlayIcon } from "../../Images/svg/play.svg";
import HomepageBackgroundImage from "./HomepageBackgroundImage";

import { Helmet } from "react-helmet";
import HomepageSpeakers from "./Speakers/HomepageSpeakers";

import variables from "../../CSS/_variables.module.scss";
import cloneDeep from "lodash/cloneDeep";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import { isAndroid, isMobile } from "react-device-detect";
import "./HomepageEventStyles.scss";

class HomepageEvent extends React.Component {
	constructor(props) {
		super(props);
		this.tabsHeader = React.createRef();

		this.state = {
			eventDays: [],
			breakpoint: "desktop",
			showVideo: false,
			registerAsParticipant: false,
			registerAsExhibitor: false,
			activeProgramTabIndex: 0,
			activePartnersTabIndex: 0,
			eventNameFontSize: 67,
			brandingTranslation: null,
			organizerIsPreviewingPage: false,
			showPwaPopup: false,
		};
	}

	componentDidMount() {
		const { eventInfo } = this.props;
		this.props.onSetEventPublicMenu();
		this.props.speakerList(this.props.eventId, false);
		window.scrollTo(0, 0);

		this.setBreakpoints();
		this.setEventNameFontSize();
		window.addEventListener("resize", this.onResize);

		let eventDays = getEventDaysArray(
			eventInfo.eventStartDate,
			eventInfo.eventEndDate,
		);
		this.setState({
			eventDays: eventDays,
		});
		if (window.innerWidth <= 480) {
			if (eventInfo.brandingData.partners.length === 0) {
				this.setState({ activePartnersTabIndex: 2 });
			} else {
				this.setState({ activePartnersTabIndex: 1 });
			}
		} else {
			if (
				eventInfo.brandingData.sponsors?.length === 0 ||
				eventInfo.brandingData.sponsors?.length === undefined
			) {
				this.setState({ activePartnersTabIndex: 1 });
				if (eventInfo.brandingData.partners.length === 0) {
					this.setState({ activePartnersTabIndex: 2 });
				}
			} else {
				this.setState({ activePartnersTabIndex: 0 });
			}
		}
		this.setLanguagesData();
		this.checkCanPreviewPage();
		/*        this.pwaDetect();*/
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.onResize);
	}

	componentDidUpdate(prevProps) {
		const { languages, userLoading } = this.props;
		if (prevProps.languages.eventLanguage !== languages.eventLanguage) {
			this.setLanguagesData();
		}
		if (prevProps.userLoading !== userLoading) {
			this.checkCanPreviewPage();
		}
	}

	pwaClose = () => {
		localStorage.setItem("pwaVisited", "true");
		this.setState({ showPwaPopup: false });
	};
	pwaDetect = () => {
		let isVisited = localStorage.getItem("pwaVisited");
		let isPWAPage = window.location.pathname === "/event/pwa" || "/event/pwa/";
		isPWAPage && !isVisited && this.setState({ showPwaPopup: isPWAPage });
	};

	setLanguagesData = () => {
		const { languages, eventInfo, location } = this.props;
		let translation = languages.translations[languages.eventLanguage];
		let brandingTranslation = eventInfo.brandingTranslations.find(
			(translation) => translation.language === languages.eventLanguage,
		);

		// organizer preview
		const isPreviewUrl =
			location.search.includes("preview=true") &&
			location.search.includes("lang=");
		if (isPreviewUrl) {
			const langCode = location.search.split("=").pop();
			const brandingTranslationAvailable = eventInfo.brandingTranslations.find(
				(translation) => translation.language === langCode,
			);
			if (languages.translations[langCode] && brandingTranslationAvailable) {
				translation = languages.translations[langCode];
				brandingTranslation = eventInfo.brandingTranslations.find(
					(translation) => translation.language === langCode,
				);
			}
		}

		this.setState({
			translation: translation,
			brandingTranslation: brandingTranslation,
		});

		document
			.querySelector('meta[property="og:title"]')
			.setAttribute(
				"content",
				`${process.env.REACT_APP_PLATFORM_NAME} - ${brandingTranslation?.homepageEventName}`,
			);
	};

	checkCanPreviewPage = () => {
		const { location, user, userLoading, history, eventSlug } = this.props;
		const isPreviewUrl = location.search.includes("preview=true");
		const userRolesInEvent = user?.eventRoles?.find(
			(role) => role.event.slug === eventSlug,
		);
		const userIsCoOrganizer =
			userRolesInEvent && userRolesInEvent.roles.includes("coOrganizer");
		if (
			isPreviewUrl &&
			!user?.isOrganizer &&
			!userIsCoOrganizer &&
			!userLoading
		) {
			history.push(`/event/${eventSlug}`);
		}
	};

	onResize = () => {
		this.setBreakpoints();
		this.setEventNameFontSize();
	};

	setBreakpoints = () => {
		if (window.innerWidth > 1199.9) {
			this.setState({ breakpoint: "desktop" });
		} else if (window.innerWidth > 767.9) {
			this.setState({ breakpoint: "tablet" });
		} else {
			this.setState({ breakpoint: "phone" });
		}
	};

	handleOpenHomepageVideo = () => {
		this.setState({ showVideo: true });
	};

	closeDialog = () => {
		this.setState({ showVideo: false });
	};

	setActiveProgramTabIndex = (index, automaticScroll) => () => {
		this.setState({ activeProgramTabIndex: index });
		if (automaticScroll) {
			this.tabsHeader.current.scrollIntoView();
			window.scrollBy(0, -130);
		}
	};

	setActivePartnersTabIndex = (index) => () => {
		this.setState({ activePartnersTabIndex: index });
	};

	getTextWidth = (text, font) => {
		// re-use canvas object for better performance
		let canvas =
			this.getTextWidth.canvas ||
			(this.getTextWidth.canvas = document.createElement("canvas"));
		let context = canvas.getContext("2d");
		context.font = `${font}px ${variables.fontFamily}`;
		context.textAlign = "left";
		let metrics = context.measureText(text);
		return metrics.width;
	};

	setEventNameFontSize = () => {
		const { eventNameFontSize } = this.state;
		const { eventInfo } = this.props;
		const eventName = eventInfo.name;
		let fontSize = eventNameFontSize;
		const minFontSize = 29;
		const maxFontSize = 67;

		let eventNameContainerWidth = window.innerWidth - 60;

		// Sometimes we get a TypeError: Cannot read property 'offsetWidth' of null
		// So I added a check
		if (document.getElementById("eventNameContainer")) {
			eventNameContainerWidth =
				document.getElementById("eventNameContainer").offsetWidth;
		}

		if (eventName.length > 4) {
			const sortedWords = eventName
				.split(" ")
				.sort((a, b) => b.length - a.length);
			const longestWord = sortedWords[0];
			for (let i = maxFontSize; i >= minFontSize; i--) {
				let longestWordWidth = this.getTextWidth(longestWord, i);
				if (longestWordWidth < eventNameContainerWidth) {
					break;
				}

				// bug fix, althought the calculated word width on canvas is smaller than the eventNameContainerWidth
				// the word still got on the next row, so I forced it to make it 1px smaller than the calculated one
				fontSize = i - 1;
			}
		}

		this.setState({ eventNameFontSize: fontSize });
	};

	bannerSorting = () => {
		const { eventInfo } = this.props;
		let bannerList;
		if (eventInfo.bannersRandomOrder) {
			bannerList = cloneDeep(eventInfo?.brandingData?.banners).sort(
				() => Math.random() - 0.5,
			);
			return bannerList;
		} else {
			bannerList = eventInfo.brandingData.banners.sort(function (a, b) {
				return a.position - b.position;
			});
			return bannerList;
		}
	};

	getMonthString = (date) => {
		const { translation } = this.props;
		let months = [
			translation?.time.januaryShort,
			translation?.time.februaryShort,
			translation?.time.marchShort,
			translation?.time.aprilShort,
			translation?.time.mayShort,
			translation?.time.juneShort,
			translation?.time.julyShort,
			translation?.time.augustShort,
			translation?.time.septemberShort,
			translation?.time.octoberShort,
			translation?.time.novemberShort,
			translation?.time.decemberShort,
		];
		let currentDate = moment(date).startOf("day");
		return months[currentDate.month()];
	};

	render() {
		const {
			translation,
			brandingTranslation,
			eventNameFontSize,
			activeProgramTabIndex,
			activePartnersTabIndex,
			breakpoint,
			showVideo,
		} = this.state;
		const { eventInfo, userLoading, loadingEvent, defaultTranslation } =
			this.props;
		const sliderSettings = {
			dots: false,
			arrows: false,
			autoplay: true,
			draggable: false,
			pauseOnHover: false,
			swipe: false,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			edgeFriction: 200,
		};
		let homepageVideoId = getVideoIdFromYoutubeUrl(
			eventInfo.brandingData.homepageVideo,
		);

		if (userLoading || loadingEvent) {
			return (
				<div
					onDragStart={preventDefaultDrag}
					className="homepage-spinner-container"
				>
					<FullPageSpinner />
				</div>
			);
		}

		//pwa trigger
		let deferredPrompt;
		window.addEventListener("beforeinstallprompt", (e) => {
			e.preventDefault();
			deferredPrompt = e;
		});
		let installButton = document.getElementById("installButton");
		installButton?.addEventListener("click", async () => {
			deferredPrompt?.prompt();
			console.log(deferredPrompt);
			const { outcome } = await deferredPrompt?.userChoice;
			deferredPrompt = null;
			if (outcome === "accepted") {
				console.log("User accepted the install prompt.");
			} else if (outcome === "dismissed") {
				console.log("User dismissed the install prompt");
			}
		});

		document.addEventListener("scroll", () => {
			let pwaBanner = document.getElementById("pwa-banner");
			if (window.scrollY && pwaBanner && pwaBanner.offsetTop > 0) {
				pwaBanner.style.cssText = "top: 0";
			} else {
				pwaBanner.style.cssText = "";
			}
		});

		//pwa trigger end
		return (
			<div
				onDragStart={preventDefaultDrag}
				className={userLoading || loadingEvent ? "loading" : null}
			>
				<Helmet>
					<title>{`${process.env.REACT_APP_PLATFORM_NAME} - ${
						brandingTranslation?.homepageEventName
							? brandingTranslation?.homepageEventName
							: eventInfo.title
					}`}</title>
				</Helmet>
				{eventInfo ? (
					<div onDragStart={preventDefaultDrag} className="homepage">
						<section className="top-section">
							{isAndroid && isMobile && (
								<div className="pwa-banner" id="pwa-banner">
									<p>Download AIFG prograssive web app</p>
									<button id="installButton">INSTALL</button>
								</div>
							)}

							<HomepageBackgroundImage />
							<div
								onDragStart={preventDefaultDrag}
								className="homepage-container"
							>
								<div
									onDragStart={preventDefaultDrag}
									className="event-details-container"
								>
									<div
										onDragStart={preventDefaultDrag}
										className="main-details"
									>
										<div
											onDragStart={preventDefaultDrag}
											className="details-information"
											id="eventNameContainer"
										>
											{brandingTranslation?.homepageEventName && (
												<h1 style={{ fontSize: `${eventNameFontSize}px` }}>
													{brandingTranslation?.homepageEventName}
												</h1>
											)}
											{brandingTranslation?.homepageOrganizerName && (
												<p>
													{`${translation?.homepage.organizedBy}: `}
													<span
														onDragStart={preventDefaultDrag}
														draggable="false"
														className="organizer-name"
													>
														{brandingTranslation?.homepageOrganizerName}
													</span>
												</p>
											)}
										</div>
									</div>
									{(brandingTranslation?.homepageDateText ||
										brandingTranslation?.homepageLocationText ||
										brandingTranslation?.homepagePriceText) && (
										<div
											onDragStart={preventDefaultDrag}
											className="extra-details-wrapper"
										>
											<div
												onDragStart={preventDefaultDrag}
												className="extra-details"
											>
												{brandingTranslation?.homepageDateText && (
													<div
														onDragStart={preventDefaultDrag}
														className="single-detail-container"
													>
														<p
															onDragStart={preventDefaultDrag}
															className="label"
														>{`${translation?.homepage.date}: `}</p>
														<p
															onDragStart={preventDefaultDrag}
															className="information"
														>
															{brandingTranslation?.homepageDateText}
														</p>
													</div>
												)}
												{brandingTranslation?.homepageLocationText && (
													<div
														onDragStart={preventDefaultDrag}
														className="single-detail-container"
													>
														<p
															onDragStart={preventDefaultDrag}
															className="label"
														>{`${translation?.homepage.location}: `}</p>
														<p
															onDragStart={preventDefaultDrag}
															className="information"
														>
															{brandingTranslation?.homepageLocationText.substring(
																0,
																8,
															) === "https://"
																? brandingTranslation?.homepageLocationText
																		.split("\n")
																		.map((item, key) => {
																			let itemWithLink = urlifyString(item);
																			return (
																				<span
																					onDragStart={(e) =>
																						e.preventDefault()
																					}
																					draggable="false"
																					key={key}
																				>
																					<span>{itemWithLink}</span>
																					<br />
																				</span>
																			);
																		})
																: brandingTranslation?.homepageLocationText}
														</p>
													</div>
												)}
												{brandingTranslation?.homepagePriceText && (
													<div
														onDragStart={preventDefaultDrag}
														className="single-detail-container"
													>
														<p
															onDragStart={preventDefaultDrag}
															className="label"
														>{`${translation?.homepage.price}: `}</p>
														<p
															onDragStart={preventDefaultDrag}
															className="information"
														>
															{brandingTranslation?.homepagePriceText}
														</p>
													</div>
												)}
											</div>
										</div>
									)}
								</div>
							</div>
						</section>
						<section className="program-section">
							<div
								className={
									"tabs-header " +
									(activeProgramTabIndex === 1 ? "secondary-colors" : "")
								}
								ref={this.tabsHeader}
							>
								<div
									onDragStart={preventDefaultDrag}
									className="homepage-container"
								>
									{breakpoint === "desktop" && (
										<div
											onDragStart={preventDefaultDrag}
											className="tabs-name-container"
										>
											<div
												className={
													"tab-name " +
													(activeProgramTabIndex === 0 ? "active" : "")
												}
												onClick={this.setActiveProgramTabIndex(0)}
											>
												<span>{translation?.homepage.tabIntro}</span>
											</div>
											{eventInfo.showProgram && (
												<div
													className={
														"tab-name " +
														(activeProgramTabIndex === 1 ? "active" : "")
													}
													onClick={this.setActiveProgramTabIndex(1)}
												>
													<span>{translation?.homepage.tabProgram}</span>
												</div>
											)}

											{this.props.speakers.eventSpeakers &&
												this.props.speakers.eventSpeakers.data &&
												this.props.speakers.eventSpeakers.data.length > 0 && (
													<div
														className={
															"tab-name " +
															(activeProgramTabIndex === 3 ? "active" : "")
														}
														onClick={this.setActiveProgramTabIndex(3)}
													>
														<span>SPEAKERS</span>
													</div>
												)}
										</div>
									)}
								</div>
							</div>
							<div onDragStart={preventDefaultDrag} className="tabs-content">
								{(activeProgramTabIndex === 0 || breakpoint !== "desktop") && (
									<div
										onDragStart={preventDefaultDrag}
										className="homepage-container"
									>
										<div
											onDragStart={preventDefaultDrag}
											className="event-description-container"
										>
											{breakpoint !== "desktop" && (
												<p
													onDragStart={preventDefaultDrag}
													className="event-description-container-title"
												>
													{translation?.homepage.tabIntro}
												</p>
											)}
											<div
												onDragStart={preventDefaultDrag}
												className="description"
											>
												<span
													onDragStart={preventDefaultDrag}
													draggable="false"
													dangerouslySetInnerHTML={{
														__html: brandingTranslation?.homepageDescription,
													}}
												/>
												<br />
											</div>
											<div
												onDragStart={preventDefaultDrag}
												className="media-container"
											>
												{eventInfo?.brandingData?.homepageVideo && (
													<div
														className="video-container"
														onClick={this.handleOpenHomepageVideo}
													>
														<img
															src={
																"https://img.youtube.com/vi/" +
																homepageVideoId +
																"/0.jpg"
															}
															className="background"
															alt="video background"
														/>
														<div
															onDragStart={preventDefaultDrag}
															className="video-overlay"
														>
															<PlayIcon />
														</div>
													</div>
												)}
												{eventInfo.brandingData.banners.length > 0 ? (
													<div
														onDragStart={preventDefaultDrag}
														className="random-banner"
													>
														<Slider {...sliderSettings}>
															{eventInfo && eventInfo.bannersRandomOrder
																? this.bannerSorting().map(
																		(banner, bannerIndex) => {
																			return banner.website !== "" ? (
																				<a
																					key={bannerIndex}
																					href={banner.website}
																					target="_blank"
																					rel="noopener noreferrer"
																				>
																					<img
																						className="background"
																						src={
																							eventInfo.brandingData.filesUrl +
																							banner.image
																						}
																						alt="partner logo"
																					/>
																				</a>
																			) : (
																				<a key={bannerIndex}>
																					<img
																						className="background"
																						src={
																							eventInfo.brandingData.filesUrl +
																							banner.image
																						}
																						alt="partner logo"
																					/>
																				</a>
																			);
																		},
																  )
																: eventInfo.brandingData.banners.map(
																		(banner, bannerIndex) => {
																			return banner.website !== "" ? (
																				<a
																					key={bannerIndex}
																					href={banner.website}
																					target="_blank"
																					rel="noopener noreferrer"
																				>
																					<img
																						className="background"
																						src={
																							eventInfo.brandingData.filesUrl +
																							banner.image
																						}
																						alt="partner logo"
																					/>
																				</a>
																			) : (
																				<a key={bannerIndex}>
																					<img
																						className="background"
																						src={
																							eventInfo.brandingData.filesUrl +
																							banner.image
																						}
																						alt="partner logo"
																					/>
																				</a>
																			);
																		},
																  )}
														</Slider>
													</div>
												) : null}
											</div>
											{eventInfo.brandingData.homepageVideo && (
												<div
													onDragStart={preventDefaultDrag}
													className="homepage"
												>
													<VideoDialog
														opened={showVideo}
														closeDialog={this.closeDialog}
														videoUrl={eventInfo.brandingData.homepageVideo}
														classes={"homepage"}
													/>
												</div>
											)}
										</div>
										{breakpoint === "desktop" && eventInfo.showProgram && (
											<div
												className="go-to-tab-button"
												onClick={this.setActiveProgramTabIndex(1, true)}
												tabIndex={0}
											>
												<span>{translation?.homepage.tabProgram}</span>
											</div>
										)}
									</div>
								)}
								{eventInfo.showProgram && breakpoint !== "desktop" && (
									<div
										onDragStart={preventDefaultDrag}
										className="tabs-header secondary-colors"
									/>
								)}
								{(activeProgramTabIndex === 1 || breakpoint !== "desktop") && (
									<EventProgram
										breakpoint={breakpoint}
										setActiveProgramTabIndex={this.setActiveProgramTabIndex}
										translation={translation}
										brandingTranslation={brandingTranslation}
									/>
								)}{" "}
								{(activeProgramTabIndex === 3 || breakpoint !== "desktop") && (
									<div
										onDragStart={preventDefaultDrag}
										className="tabs-header speaker-tab"
									/>
								)}
								{(activeProgramTabIndex === 3 || breakpoint !== "desktop") && (
									<HomepageSpeakers eventId={this.props.eventId} />
								)}
							</div>
						</section>
						{eventInfo.brandingData?.sponsors?.length > 0 && (
							<section className="partners-section sponsors-mobile">
								<div className="tabs-header">
									<div
										onDragStart={preventDefaultDrag}
										className="homepage-container"
									>
										<div
											onDragStart={preventDefaultDrag}
											className="tabs-name-container"
										>
											{eventInfo.brandingData.sponsors.length > 0 && (
												<div className="tab-name active">
													<span>
														{translation?.homepage.tabSponsors ||
															defaultTranslation?.homepage.tabSponsors}
													</span>
												</div>
											)}
										</div>
									</div>
								</div>
								<div onDragStart={preventDefaultDrag} className="tabs-content">
									{eventInfo.brandingData?.sponsors?.length > 0 && (
										<PartnersSlider
											slides={eventInfo.brandingData?.sponsors}
											className="sponsors"
										/>
									)}
								</div>
							</section>
						)}
						{(eventInfo.brandingData?.partners?.length > 0 ||
							eventInfo.brandingData?.exhibitors?.length > 0 ||
							eventInfo.brandingData?.sponsors?.length > 0) && (
							<section className="partners-section">
								<div
									className={
										"tabs-header " +
										(activePartnersTabIndex === 1 ? "secondary-colors" : null)
									}
								>
									<div
										onDragStart={preventDefaultDrag}
										className="homepage-container"
									>
										<div
											onDragStart={preventDefaultDrag}
											className="tabs-name-container"
										>
											{eventInfo.brandingData?.sponsors?.length > 0 && (
												<div
													className={
														"tab-name sponsors " +
														(activePartnersTabIndex === 0 ? "active" : null)
													}
													onClick={this.setActivePartnersTabIndex(0)}
												>
													<span>
														{translation?.homepage.tabSponsors ||
															defaultTranslation?.homepage.tabSponsors}
													</span>
												</div>
											)}
											{eventInfo.brandingData?.partners?.length > 0 && (
												<div
													className={
														"tab-name " +
														(activePartnersTabIndex === 1 ? "active" : null)
													}
													onClick={this.setActivePartnersTabIndex(1)}
												>
													<span>{translation?.homepage.tabPartners}</span>
												</div>
											)}
											{eventInfo.brandingData?.exhibitors?.length > 0 && (
												<div
													className={
														"tab-name " +
														(activePartnersTabIndex === 2 ? "active" : null)
													}
													onClick={this.setActivePartnersTabIndex(2)}
												>
													<span>{translation?.homepage.tabExhibitors}</span>
												</div>
											)}
										</div>
									</div>
								</div>
								<div onDragStart={preventDefaultDrag} className="tabs-content">
									{activePartnersTabIndex === 0 &&
										eventInfo.brandingData?.sponsors?.length > 0 && (
											<PartnersSlider
												slides={eventInfo.brandingData?.sponsors}
												className="sponsors"
											/>
										)}
									{activePartnersTabIndex === 1 &&
										eventInfo.brandingData?.partners?.length > 0 && (
											<PartnersSlider
												slides={eventInfo.brandingData?.partners}
												className="partners"
											/>
										)}
									{activePartnersTabIndex === 2 &&
										eventInfo.brandingData?.exhibitors?.length > 0 && (
											<PartnersSlider
												slides={eventInfo.brandingData?.exhibitors}
												className="exhibitors"
											/>
										)}
								</div>
							</section>
						)}
						{eventInfo.brandingData.homepagePreviousEvents.length > 0 && (
							<section className="previous-events-section">
								<div
									onDragStart={preventDefaultDrag}
									className="homepage-container"
								>
									<h3>{translation?.homepage.previousEventsSectionTitle}</h3>
									<div
										onDragStart={preventDefaultDrag}
										className="links-container"
									>
										{eventInfo.brandingData.homepagePreviousEvents.map(
											(previousEvent, eventIndex) => {
												return (
													<div
														onDragStart={preventDefaultDrag}
														className="link-container"
														key={eventIndex}
													>
														<a
															href={previousEvent.url}
															target="_blank"
															rel="noopener noreferrer"
														>
															{previousEvent.label}
														</a>
													</div>
												);
											},
										)}
									</div>
								</div>
							</section>
						)}
					</div>
				) : null}
				<Footer previewTranslation={translation} />

				{/*pwa popup*/}
				<Dialog open={this.state.showPwaPopup}>
					<DialogTitle>PWA Title</DialogTitle>
					<DialogContent>PWA Content</DialogContent>
					<DialogActions>
						<Button onClick={this.pwaClose}>OK</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		eventInfo: state.event.eventInfo,
		loadingEvent: state.event.loading,
		eventId: state.event.eventId,
		user: state.user.data,
		speakers: state.speakers,
		userLoading: state.user.loading,
		languages: state.languages,
		translation: state.languages.translations[state.languages.eventLanguage],
		defaultTranslation: state.languages.translations["en"],
		eventSlug: state.event.eventSlug,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSetEventPublicMenu: () => dispatch(actions.setEventPublicMenu()),
		speakerList: (eventId, hidden) => dispatch(listSpeakers(eventId, hidden)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HomepageEvent);
