import React, {useCallback, useEffect, useState} from 'react';
import moment from 'moment';
import classNames from 'classnames';
import {useParams} from 'react-router-dom';

import Wall from '../../HOC/Wall/Wall';
import SessionWall from '../../HOC/SessionWall/SessionWall';
import VideoModule from './components/VideoModule';
import LinksModule from './components/LinksModule/LinksModule';
import ChatModule from './components/ChatModule/ChatModule';
import PermissionsModule from './components/PermissionsModule/PermissionsModule';
import RegisterSessionMember from './components/RegisterSessionMember/RegisterSessionMember';
import KickPopup from './components/KickPopup/KickPopup';
import WaitForApprovePopup from './components/WaitForApprovePopup/WaitForApprovePopup';
import ThumbnailImage from './components/ThumbnailImage/ThumbnailImage';

import {useNotificationOnSpeakerConnect} from '../../services/hooks/sessions/useNotificationOnSpeakerConnect';
import {useSessionDashboard} from '../../services/hooks/sessions/useSessionDashboard';
import {PARTICIPANT_TYPES} from '../../Utils/constants/organizer/sessions';

import './SessionDashboard.scss';

export function SessionDashboard({profile, sessionData, isFullScreen, userIsLoggedIn, eventData, sendChatMessage, closeSessionConference}) {
    const routeParams = useParams();
    const {sessionId, invitation} = routeParams;

    const [timeWarn, setTimeWarn] = useState(466);
    const [timeToEndMessage, setTimeToEndMessage] = useState(0);

    const {invitationType} = useSessionDashboard();

    const isModerType = profile?.role === PARTICIPANT_TYPES.organizer || profile?.role === PARTICIPANT_TYPES.moderator;

    useNotificationOnSpeakerConnect(isModerType);

    const showTimeToEndMessage = useCallback(
        (timeLeft) => {
            setTimeToEndMessage(timeLeft);
            let timeoutId = setTimeout(() => {
                if (timeToEndMessage) setTimeToEndMessage(0);
            }, 10000);

            return () => {
                clearTimeout(timeoutId)
            }
        },
        [timeToEndMessage]
    );

    const checkTimeToEnd = useCallback(() => {
        if (!sessionData || !timeWarn) return;

        const curTime = moment().valueOf();
        const endTime = moment(sessionData.endTime).valueOf();
        const timeLeft = Math.trunc((endTime - curTime) / 1000 / 60);
        if (timeLeft <= timeWarn) {
            showTimeToEndMessage(timeLeft);
            setTimeWarn(timeWarn - 2 > 0 ? timeWarn - 2 : 0);
        }
    }, [sessionData, showTimeToEndMessage, timeWarn]);

    useEffect(() => {
        const interval = setInterval(() => {
            checkTimeToEnd();
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, [checkTimeToEnd]);

    const sendMessage = useCallback((text) => sendChatMessage({sessionId, text}), [sendChatMessage, sessionId]);
    return (
        <div className={classNames('session_dashboard_page', {isFullScreen})}>
            <div className="session_dashboard_page-container">
                <div className="session_dashboard_page-column">
                    {sessionData?.type === 'live' && <VideoModule routeParams={routeParams} />}
                    {sessionData?.type === 'live' && (
                        <PermissionsModule currentUser={profile} routeParams={routeParams} />
                    )}
                    {sessionData?.type === 'recorded' && <ThumbnailImage session={sessionData} />}
                </div>
                <div className="session_dashboard_page-column">
                    {isModerType && <LinksModule routeParams={routeParams} />}
                    <ChatModule
                        messages={sessionData?.chat?.messages}
                        users={sessionData?.users}
                        sendMessage={sendMessage}
                    />
                </div>
                {isModerType &&
                    <div className="session_dashboard_page-column session-thumbnail-column">
                        <ThumbnailImage session={sessionData}/>
                    </div>
                }
            </div>

            {invitationType && (
                <RegisterSessionMember
                    invitationType={invitationType}
                    sessionId={sessionId}
                    routeParams={routeParams}
                />
            )}

            {profile?.userId && (
                <>
                    <KickPopup currentUser={profile} invitation={invitation} />
                    <WaitForApprovePopup currentUser={profile} />
                </>
            )}

            {sessionData?.videoWall && (
                <div className="booth-wall-container">
                    <div className="booth-wall-header">
                        <span>Video Wall</span>
                    </div>
                    {userIsLoggedIn
                        ?<>{eventData ? <Wall /> : 'Loading Wall...'}</>
                        :<SessionWall/>
                    }
                </div>
            )}

            {profile?.userId && profile?.screenSharing && <div className="screen_is_recrding" />}
        </div>
    );
}
