const tr = {
	generalText: {
		upload: "Yükle",
		save: "Kaydet",
		add: "Ekle",
		select: "Seçin",
		remove: "Kaldır",
		close: "KAPAT",
		update: "GÜNCELLE",
		cancel: "İPTAL ET",
		confirm: "Onayla",
		download: "İndir",
		refresh: "Yenile",
		manage: "Yönet",
		back: "Geri",
		submit: "Gönder",
		send: "Gönder",
		eventMO: "onvent",
		eventETX: "etkinlik",
		eventsMO: "onventler",
		eventsETX: "etkinlikler",
		eventUpperMO: "Onvent",
		eventUpperETX: "Etkinlik",
		hide: "HIDE", //new translation: HIDE
	},
	time: {
		justNow: "şu anda",
		minute: "dakika",
		minutes: "dakika",
		hour: "saat",
		hours: "saat",
		day: "gün",
		days: "gün",
		month: "ay",
		months: "ay",
		yesterday: "dün",
		today: "bugün",
		tomorrow: "yarın",
		monday: "pazartesi",
		tuesday: "salı",
		wednesday: "çarşamba",
		thursday: "perşembe",
		friday: "cuma",
		saturday: "cumartesi",
		sunday: "pazar",
		mondayShort: "pzt",
		tuesdayShort: "sal",
		wednesdayShort: "çrş",
		thursdayShort: "per",
		fridayShort: "cum",
		saturdayShort: "cmt",
		sundayShort: "paz",
		january: "ocak",
		february: "şubat",
		march: "mart",
		april: "nisan",
		may: "may",
		june: "haziran",
		july: "temmuz",
		august: "ağustos",
		september: "eylül",
		october: "ekim",
		november: "kasım",
		december: "aralık",
		januaryShort: "oca",
		februaryShort: "şub",
		marchShort: "mar",
		aprilShort: "nis",
		mayShort: "may",
		juneShort: "haz",
		julyShort: "tem",
		augustShort: "ağu",
		septemberShort: "eyl",
		octoberShort: "eki",
		novemberShort: "kas",
		decemberShort: "ara",
	},
	errors: {
		noFile: "Dosya seçilmedi",
		required: "Alanın doldurulması gerekir",
		emailNotValid: "Eposta geçersiz",
		passwordMinLength: "Şifre en az 8 karakterden oluşmalıdır",
		passwordMismatch: "Şifre eşleşmiyor",
		passwordIsSame: "Your new password must be different", //new translation
		fileToLarge: "Dosya çok büyük. Maksimum dosya boyutu 2MB'dir.",
		fileToLargeTextFirst: "Dosya çok büyük.",
		fileToLargeTextSecond: "maks. dosya boyutu",
		agreeToGDPR: "Lütfen gizlilik politikasını onaylayın",
		noInvitationCode: "Lütfen davet kodunuzu girin",
		invitationCodeInvalid: "Davet kodu geçersiz.",
		imageFileNotSupported:
			"Dosya türü desteklenmiyor. Lütfen aşağıdakilerden birini deneyin:",
		phoneInvalid:
			"Lütfen geçerli bir telefon numarası girin. Örneğin: +447517663928",
		linkInvalid: "Lütfen geçerli bir link girin (https://site.com/)",
		facebookLinkInvalid:
			"Lütfen geçerli bir link girin (https://facebook.com/)",
		linkedinLinkInvalid:
			"Lütfen geçerli bir link girin (https://linkedin.com/)",
		twitterLinkInvalid: "Lütfen geçerli bir link girin (https://twitter.com/)",
		youtubeLinkInvalid:
			"Lütfen geçerli bir Youtube linki girin (https://www.youtube.com)",
		connectionProblemTextFirst:
			"Bir bağlantı sorunu varmış gibi gözüküyor. Lütfen",
		connectionProblemButton: "TIKLAYIN",
		connectionProblemTextSecond: "veya sayfayı yenileyin.",
		fileTooLarge: "Dosya çok büyük. Maks. dosya boyutu 2Mb. ",
		fileTooLargeTextFirst: "Dosya çok büyük.",
		fileTooLargeTextSecond: "Maks. dosya boyutu",
	},
	footer: {
		cookiesText:
			"Bu web sitesi, çalışması için gerekli olan temel çerezleri kullanmaktadır. Ayrıca, web sitemizin bazı özelliklerinin çalışması için gerekli olan üçüncü taraf çerezlerini kullanmaktadır. Daha fazla bilgi edinmek veya bazı çerezlere olan onayınızı geri çekmek istiyorsanız, lütfen şuraya bakın",
		cookiesLink: "çerezler politikası",
		cookiesButton: "KABUL ET",
		privacyPolicy: "Gizlilik Politikası",
		cookies: "Çerezler",
		termsAndConditions: "Hükümler ve Koşullar",
		socialLinksTitle: "Bizi Takip Edin:",
	},
	menu: {
		logIn: "GİRİŞ YAP",
		logOut: "ÇIKIŞ YAP",
		scholarRegistration: "Poster / Study Registration", // new translation
		exhibitorRegistration: "Katılımcı Olun", // new translation modification (Be Exhibitor => Exhibitor Registration)
		participantRegistration: "KAYIT", // new translation modification (Register => Participant Registration)
		createEvent: "Etkinlik düzenleyin",
		launchText: "Eylül 2020'yi başlatın - erken erişime açık",
	},
	externalIdTranslations: {
		headline: "Kayıt Oldunuz",
		subHeadlineOne: "Oturum ",
		subHeadlineTwo: "artık Nöral Ağ takviminizde!",
		button: "PROGRAMI GÖRÜNTÜLE",
		checkCalendar:
			'"<strong>My Calendar</strong>" bölümünü "<strong>Program</strong>" sekmesinde kontrol edin, yaklaşan oturumlar için ve arama filtrelerini kullanarak yüzlerce geçmiş oturumu keşfedin. Ve size özel olarak hazırlanan içerikler için "<strong>Smart Suggestions</strong>"ı kaçırmayın!',
	},

	homepage: {
		organizedBy: "DÜZENLEYEN",
		date: "TARİH",
		location: "ALAN VE KONUM",
		price: "FİYAT",
		tabIntro: "GİRİŞ",
		tabIntroProgramButton: "PROGRAM",
		tabProgram: "PROGRAM",
		tabProgramIntroButton: "GİRİŞ",
		tabPartners: "ORTAKLAR",
		tabExhibitors: "SERGİCİLER",
		previousEventsSectionTitle: "ÖNCEKİ ETKİNLİKLER",
		tabSocialMedia: "SOSYAL MEDYA",
	},
	socialButtons: {
		linkedinButton: "LinkedIn ile Giriş Yapın",
		facebookButton: "Facebook ile Giriş Yapın",
		googleButton: "Google ile Giriş Yapın",
		twitterButton: "Twitter ile Giriş Yapın",
	},
	login: {
		orText: "ya da ",
		inputEmail: "Eposta",
		inputPassword: "Şifre",
		rememberMeButton: "Beni hatırla",
		forgotPasswordButton: "Şifrenizi mi unuttunuz?",
		loginButton: "GİRİŞ YAP",
		noAccountText: "Hesabınız yok mu?",
		registerButton: "Buradan kaydolun",
		recoverPasswordDialogTitle: "ŞİFRE KURTAR",
		recoverPasswordDialogInputEmail: "Eposta",
		recoverPasswordDialogCloseButton: "KAPAT",
		recoverPasswordDialogRecoverButton: "KURTAR",
		errorNotRegistered: "Kayıtlı değil",
		errorUnableToLogin: "Giriş yapılamadı",
	},
	register: {
		formTitle: "Ya da kendi onvent profilinizi oluşturun",
		inputFirstName: "İsim",
		inputLastName: "Soyisim",
		inputEmail: "Eposta",
		inputPassword: "Şifre",
		inputConfirmPassword: "Şifreyi Onayla",
		uploadImageText: "Profil Fotosu Yükleyin (2MB):",
		editImageText: "Profil Resmini Düzenle (2MB):",
		gdprTextFirst: "Devam ederek, aşağıdakileri kabul etmiş olursunuz",
		gdprTextTerms: "Kullanım Koşulları",
		gdprTextSecond: "ve çerez politikamızı",
		gdprTextCookies: "okuduğunuzu onaylayın",
		gdprTextThird: "ve",
		gdprTextPrivacy: "Gizlilik Politikası",
		gdprTextAgree: "Lütfen gizlilik politikasını onaylayın",
		registerButton: "Kaydol",
		haveAccountText: "Zaten bir hesabınız var mı?",
		haveAccountLoginButton: "Giriş Yapın",
		errorAlreadyHaveAccount: "önceden kaydolunmuş.",
		formTitleTextFirst: "ya da kendi",
		formTitleTextSecond: "profilinizi oluşturun",
		invitationCode: "Davet kodu",
	},
	participantRegistration: {
		choosePackageTitle: "ERİŞİM PAKETİNİ SEÇİN",
		noPackageText: "Lütfen bir paket seçin",
		gdprTextFirst: "Devam ederek, aşağıdakileri kabul etmiş olursunuz",
		gdprTextPrivacy: "Gizlilik Politikası",
		gdprTextSecond: "ve",
		gdprTextTerms: "Etkinlik Hüküm ve Koşulları",
		gdprTextAgree: "Lütfen gizlilik politikasını onaylayın",
		registerButtonFree: "Kaydı Tamamla",
		registerButtonPay: "Ödemeye devam edin",
	},
	exhibitorRegistration: {
		choosePackageTitle: "ERİŞİM PAKETİNİ SEÇİN",
		gdprTextFirst: "Devam ederek, aşağıdakileri kabul etmiş olursunuz",
		gdprTextPrivacy: "Etkinlik Gizlilik Politikası",
		gdprTextSecond: "ve",
		gdprTextTerms: "Etkinlik Hüküm ve Koşulları",
		gdprTextAgree: "Lütfen gizlilik politikasını onaylayın",
		registerButtonFree: "Kaydı Tamamla",
		registerButtonPay: "Ödemeye devam edin",
	},
	stripePayment: {
		cardNumber: "Kart Numarası",
		expirationDate: "Son Kullanma Tarihi (AA / YY)",
		cvc: "CVC",
		disclaimerText:
			"Bu ödeme işlemi uçtan uca şifrelenmiştir. Güvenlik ve gizlilik, Stripe Services tarafından garanti edilmektedir.",
		disclaimerTextLink: "Daha fazlasını öğrenmek için tıklayınız.",
		cancelButton: "İPTAL ET",
		payButton: "Öde",
	},
	recoverPassword: {
		recoverTitle: "ŞİFRE KURTAR",
		cancelButton: "KAPAT",
		recoverButton: "KURTAR",
		recoverTextSend: "Şifre kurtama epostası başarılı bir şekilde gönderildi.",
	},
	unsubscribe: {
		unsubscribeTitle: "Abonelik iptal edilsin mi?",
		unsubscribeHomepage: "Ana sayfaya git",
	},
	notAvailable: {
		notAvailableTitle: "Onvent mevcut değil",
		notAvailableSubtitle:
			"Aradığınız Onvent mevcut değildir. Ya organizatör tarafından kapatılmış ya da yönetici tarafından devre dışı bırakılmıştır.",
		notAvailableTitleTextSecond: "mevcut değil",
		notAvailableSubtitleTextFirst: "O",
		notAvailableSubtitleTextSecond:
			"Arıyor olduğunuz mevcut değil. Ya organizatör tarafından kapatıldı ya da admin tarafından devre dışı bırakıldı.",
		closedTitle: "şu anda kaplaı",
		closedSubtitleTextFirst: "bunun için başarılı bir şekilde kaydoldunuz",
		closedSubtitleTextSecond: "şu anda kapalı",
	},
	notFound: {
		notFoundDescription: "Hay aksi Burada bulunacak etkinlik yok!",
		notFoundHomepage: "Ana sayfaya git",
	},
	eventMenu: {
		organizerButton: "DÜZENLEYEN",
		exhibitorButton: "SERGİCİ",
		boothButton: "STANT",
		boothsButton: "STANTLAR",
		homeButton: "ANA SAYFA",
		contactsButton: "İRTİBAT KİŞİLERİ",
		meetingsButton: "TOPLANTILAR",
		chatsButton: "SOHBETLER",
		programButton: "PROGRAM",
		archiveButton: "ARŞİV",
		myAccountButton: "HESABIM",
		lobbyButton: "Lobi",
		roomsButton: "ODALAR",
		connectionProblemTextFirst: "Yeni güncellemeler mevcut. Lütfen buraya",
		connectionProblemButton: "TIKLAYINIZ",
		connectionProblemTextSecond: "veya sayfayı yenileyin.",
		sessionProblemTextFirst: "Oturumunuz sona erdi. Lütfen buraya",
		sessionProblemButton: "TIKLAYINIZ",
		sessionProblemTextSecond: "oturum açmak için.",
		backButton: "GERİ",
		hubButton: "MERKEZ",
		metaverse: "METAVERSE",
		floorPlan: "KAT PLANI",
	},
	myEventsDropdown: {
		title: "ETKİNLİKLERİM",
		createButtonDesktop: "Etkinlik oluştur",
		createButtonMobile: "OLUŞTUR",
	},
	myBoothsDropdown: {
		title: "STANTLARIM",
	},
	contactsDropdown: {
		title: "İRTİBAT KİŞİLERİNİZ",
		requestsTitle: "İrtibat Talepleri",
		rejectButton: "REDDET",
		acceptButton: "KABUL ET",
		pendingButton: "Bekliyor",
		addButton: "Ekle",
		noContacts:
			"Henüz irtibat kişiniz yok. Kişi listenizi oluşturmaya başlamak için lütfen ağ oluşturma bölümünü ziyaret edin.",
		noScannedContacts: "Henüz taranmış hiçbir bağlantınız yok.",
	},
	meetingsDropdown: {
		meetingWith: "İle Toplantı",
		pending: "Bekliyor",
		accepted: "Kabul edildi",
		canceled: "İptal edildi",
		noMeetings: "Henüz hiçbir toplantı programlanmadı",
		finished: "Bitti",
	},
	programDropdown: {
		timeZone: "Zaman dilimi:",
		with: "ile",
		noProgram: "",
	},
	notificationsDropdown: {
		title: "Bildirimler",
		contactRequestReceived: "size bir iletişim isteği gönderdi. Bir göz atın!",
		contactRequestAccepted:
			"iletişim talebinizi kabul etti. Sohbet etmeye başlayın!",
		meetingRequestReceived: "size bir toplantı isteği gönderdi. Bir göz atın!",
		meetingRequestAccepted:
			"sizin toplantı isteğinizi kabul etti. Bir göz atın!",
		meetingRequestCanceled: "bir toplantı isteğini iptal etti. Bir göz atın!",
		meetingStarting: "yakında bir toplantı başlayacak. Bir göz atın!",
		newBoothPost: "standınızın duvarına gönderimde bulundu. Bir göz atın!",
		noNotifications: "Henüz hiçbir bildiriminiz yok.",
		seeAll: "HEPSİNİ GÖR",
		hideAll: "HEPSİNİ SAKLA",
		agendaItemUpdated:
			"Kaydedilen program öğesi etkinlik organizatörü tarafından güncellendi",
		agendaItemDelted:
			"Kaydedilen program öğesi etkinlik organizatörü tarafından silindi",
		newMeetingRequest: "Yeni bir toplantı talebiniz var",
		meetingAccepted: "Toplantı talebiniz kabul edildi",
		meetingCancelled: "Toplantınız iptal edildi",
		meetingWillStartTextFirst: "İle bir toplantı",
		meetingWillStartTextSecond: "çok yakında başlayacak. Göz atın!",
	},
	chatsDropdown: {
		title: "SOHBETLER",
		newChatButton: "Yeni sohbet",
		newChatStart: "Yeni sohbet başlatın",
		newChatProvideText:
			"Lütfen sohbetiniz için bir isim girin. Bu ismi daha sonrasında tekrar düzenleyebileceksiniz:",
		noChats:
			"Etkinlik katılımcılarıyla konuşmak için en az bir sohbet oluşturun",
		member: "üye",
		members: "Üyeler",
		noOpenedChatText: "Sohbete devam etmek için bir sohbete tıklayın.",
		noMessagesInChatTextFirst: "Buzları eritin.",
		noMessagesInChatTextSecond: "Bu sohbeti başlatan kişi olun.",
		newChatNameInput: "Sohbet ismi (Opsiyonel)",
		newChatAddMembers:
			"Bu sohbete irtibat kişisi ya da diğer katılımcıları ekleyin:",
		editChatAddMembers:
			"Lütfen grup sohbetine eklemek istediğiniz bu etkinlikten kullanıcıları arayın.",
		newChatSearchInput: "Kullanıcı ara",
		newChatNoUsersMessage: "Kullanıcı Bulunamadı",
		newChatAddUserButton: "Ekle",
		newChatRemoveUserButton: "Kaldır",
		newChatCancelButton: "KAPAT",
		newChatCreateButton: "OLUŞTUR",
		membersLeaveButton: "AYRIL",
		membersUpdateButton: "GÜNCELLE",
		membersChatButton: "Sohbet",
		chatSettingsButton: "Ayarlar",
		newMessageInput: "Mesajınızı buraya yazın",
		newVideoCallTooltipText: "Görüntülü sohbet başlatmak için burayı tıklayın",
		fullscreenButton: "Tam ekranı açın",
		minimizeButton: "Ekranı küçültün",
	},
	myAccountDropdown: {
		title: "HESABIM",
		viewProfileButton: "Profili Görüntüleyin",
		logoutButton: "ÇIKIŞ YAP",
	},
	myAccountPage: {
		platformProfileSectionTitle: "Neural Network profilim",
		changePasswordButton: "ŞİFREYİ DEĞİŞTİR",
		deleteAccountButton: "HESABIMI SİL",
		platformLanguage: "Platform dili:",
		reviewPlatformGdprText: "Onvent koşullarımıza göz atın:",
		privacyPolicyLink: "Gizlilik bildirimi / politikası",
		cookiesLink: "Çerezler",
		termsAndConditionsLink: "Hükümler ve Koşullar",
		eventProfileSectionTitle: "Etkinlik profili",
		eventPackage: "Etkinlik Paketi",
		reviewEventGdprText: "Etkinlik koşullarımıza göz atın:",
		unregisterFromEventButton: "ETKİNLİKTEN KAYDINIZI SİLİN",
		unregisterCurrentEventButton: "Mevcut etkinlikten kaydınızı silin",
		unregisterCurrentEventConfirmation:
			"Lütfen etkinlik kaydını iptal etmek istediğinizi onaylayın",
		enableParticipantProfile: "Katılımcı Profilini Etkinleştir",
		editUserDialogTitle: "Kullanıcı Bilgilerini Düzenle",
		editExhibitorDialogTitle: "Şirket İlgili Kişi Bilgilerini Düzenleyin",
		editParticipantDialogTitle: "Katılımcı Bilgilerini Düzenle",
		firstName: "İsim",
		lastName: "Soyisim",
		uploadImage: "Resim yükleyin",
		removeImage: "Görseli kaldırın",
		deleteTitle: "HESABIMI SİL",
		deleteConfirm: "Hesabımı silmeyi onayla",
		deleteError:
			"Hesabınızı silemezsiniz. Lütfen hesabınızı silebilmek için oluşturulan tüm etkinlikleri silin.",
		deleteSuccess: "Hesabınız silindi.",
		email: "e-posta",
		reviewEventPrivacyPolicy: "Etkinlik Gizlilik Politikamızı gözden geçirin:",
		title: "Ünvan",
		company: "Şirket",
		phone: "Telefon",
		platformProfileSectionTitleTextSecond: "profil",
		reviewPlatformGdprTextFirst: "koşullarımızı",
		reviewPlatformGdprTextSecond: "gözden geçirin",
	},
	changePassword: {
		title: "ŞİFREYİ DEĞİŞTİR",
		oldPassword: "Eski Şifre",
		oldPasswordError: "Eski şifre eşleşmiyor!",
		inputPassword: "Yeni Şifre",
		inputConfirmPassword: "Şifreyi Onayla",
		changePasswordButton: "ŞİFREYİ DEĞİŞTİR",
	},
	roomsDropdown: {
		title: "ODALAR MENÜSÜ",
		auditoriumButton: "Konferans Salonu",
		showfloorButton: "Gösteri Katı",
		archiveButton: "ARŞİV",
		onDemandButton: "ON-DEMAND", // new translation
		metaverse: "METAVERSE",
		floorPlan: "KAT PLANI",
	},
	sideMenu: {
		liveWall: "Canlı Duvar",
		networking: "Ağ Kurma",
		info: "BİLGİ",
		videoWall: "Video Duvarı",
		booth: "STANT",
		boothWall: "Stant Duvarı",
		resources: "KAYNAKLAR",
		networkingTooltip: "Tüm ağ işlevlerine erişiminiz bulunmamaktadır.",
		polls: "ANKETLER",
	},
	wall: {
		sortBy: "Sırala:",
		popularity: "POPULERLİK",
		time: "ZAMAN",
		emptyWallText: "Buraya ilk gönderi ekleyen siz olun",
		newPostInput: "Aklınızdan ne geçiyor?",
		newCommentInput: "Yorum",
		resourcesVideoDescription: "VİDEOLU AÇIKLAMA",
		resourcesLinks: "Linkler",
		resourcesFiles: "Dosyalar",
		whatIsOnYourMind: "Aklınızdan ne geçiyor?",
	},
	networking: {
		searchInput: "Kullanıcı ara",
		at: "konumu:",
		regenerateMatches: "eşleşmeleri yeniden oluştur",
	},
	closedAuditoriumDialog: {
		title: "Konferans Salonu Bilgileri",
		content: "Konferans Salonuna erişim kısıtlanmıştır.",
		contentPackage:
			"Erişim paketinizin herhangi bir konferans salonuna erişimi yoktur.",
		button: "TAMAM",
	},
	closedShowfloorDialog: {
		title: "Gösteri Katı Bilgileri",
		content: "Gösteri Katına erişim kısıtlanmıştır.",
		contentPackage: "Erişim paketinizin Gösteri Katına erişimi yoktur.", //New translation: Your access package doesn't have access to any showfloors.
		button: "TAMAM",
	},
	auditorium: {
		willStart: "Sonraki oturum ... içinde başlayacak",
		noAccess: "Erişim paketinizin bu konferans salonuna erişimi yoktur.",
		noVideos: "Bu Konferans Salonunda oynatılması planlanan video yoktur.",
		stageTab: "AŞAMA",
		stagesButton: "AŞAMALAR",
		videoWallButton: "Video Duvarı",
		resourcesButton: "KAYNAKLAR",
		slotEndsTextFirst: "Bu program slotu içinde sona erecektir",
		slotEndsTextSecond: "Sunum, programın sonunda talep üzerine yapılacaktır.",
		slotTitleWith: "ile",
		allRooms: "TÜM ODALAR",
		onDemandButton: "ON-DEMAND", // new translation
		allFloors: "TÜM KAT PLANLARI",
		hide: "GİZLE",
	},
	auditoriumArchive: {
		title: "Konferans Salonu Arşivi",
		titleEvent: "Etkinlik Arşivi",
		with: "ile",
		stage: "AŞAMA",
		videoWall: "Video Duvarı",
		resources: "KAYNAKLAR",
		tooltipMinimize: "Simge durumuna küçült",
		tooltipFullscreen: "Tam ekran",
		tooltipPause: "Duraklat",
		tooltipPlay: "Oynat",
		tooltipUnmute: "Sesi Aç",
		tooltipMute: "Sessize al",
		noVideosAvailable: "All sessions finished", // new translation
	},
	videoPlayer: {
		videoNotPlayingError:
			"Video Oynatımıyla ilgili sorun mu yaşıyorsunuz? Lütfen Yeniden Yükleyin.",
		videoEndedTextFirst: "Video sona erdi. İzlediğiniz için teşekkür ederiz!",
		videoEndedTextSecond: "Video duvarı Soru-Cevap için açık konumdadır.",
		tooltipPlay: "Oynat",
		tooltipPause: "Duraklat",
		tooltipMinimize: "Simge durumuna küçült",
		tooltipFullscreen: "Tam ekran",
		tooltipMute: "Sessize al",
		tooltipUnmute: "Sesi Aç",
	},
	showfloor: {
		allExhibitorsButton: "TÜM SERGİCİLER", //new translation: Showfloor menu
		featuredExhibitorsText: "ÖNE ÇIKAN SERGİCLER",
		boothLinksDialogTitle: "STANT LİNKLERİ",
		boothLinksDialogOtherLinksTitle: "Diğer:",
		boothFilesDialogTitle: "STANT DOSYALARI",
		boothVideoDialogTitle: "VİDEO",
		mobileTabChat: "Sohbet",
		mobileTabFiles: "Dosyalar",
		mobileTabLinks: "Linkler",
		mobileTabVideo: "VİDEO",
		noExhibitorsTextFirst: "KATILIMCILAR STANTLARINDA ÇALIŞIYOR",
		noExhibitorsTextSecond: "TAKİPTE KALIN",
		filterBy: "Şuna göre filtrele",
		searchByTheUser: "Kullanıcıya göre ara",
		showShowfloorsButton: "ALL EXHIBIT HALLS", //new translation
		searchExhibitors: "Search exhibitors", // //new translation: Search exhibitors
		showfloorsTitle: "Exhibit Halls", //new translation: Showfloors
	},
	eventArchive: {
		title: "Video Arşivi",
		searchText: "Aramak için dokunun",
		noVideosText: "Bu etkinlikte video bulunamadı.",
		noArchivedEvents: "Şu anda arşivlenmiş etkinlik yok.",
	},
	exhibitorDashboard: {
		event: "Etkinlik",
		title: "Stantınızı Yönetin",
		visitorsButton: "Stant Ziyaretçileri",
		goToBoothButton: "Beni stanta götür",
		goToBoothButtonDisabled: "Stantınız henüz önizlemeye hazır değil.",
		tabStyle: "Stant Türü",
		tabLogo: "Logo",
		tabFiles: "Dosyalar",
		tabLinks: "Linkler",
		tabAvatars: "Avatarlar",
		tabVideo: "Video",
		boothStyleTab: {
			optionOne: "Seçnek 1 - İskandinav",
			optionTwo: "Seçnek 2 - Modern",
			optionThree: "Seçnek 1 - Dinamik",
			optionFour: "Seçenek 4 - Özel",
		},
		logoTab: {
			title: "Logo",
			fileUploadButton: "Yüklemek için buraya tıklayın",
			noFile: "Dosya seçilmedi",
		},
		filesTab: {
			title: "Dokümanlar:",
			fileNameInput: "Doküman ismi (tüm katılımcılar görebilir)",
			chooseFileButton: "Dosya seçin",
			noDocAdded: "Hiçbir belge eklenmedi",
			fileError: "Lütfen bir doküman ismi ekleyin",
		},
		linksTab: {
			social: {
				title: "Sosyal medya linkleri",
				subtitle: "(tüm katılımcılar tarafından görülebilir)",
				facebookInput: "Facebook linkinizi girin",
				linkedinInput: "LinkedIn linkinizi girin",
				twitterInput: "Twitter linkinizi girin",
			},
			other: {
				title: "Diğer linkler",
				subtitle: "(Ana sayfa veya diğer bağlantılar)",
				linkInput: "Yeni link girin",
				linkLabel: "Yeni Link Etiketi Ekle",
			},
		},
		avatarsTab: {
			mainRepresentatives: {
				title: "Stand Temsilcileri",
				subtitle: "(sadece bir temsilci zorunludur)",
				leftRepresentative: "Sol temsilci",
				rightRepresentative: "Sağ temsilci",
				addDialog: {
					title: "Avatar Ekle",
					contentFirst:
						"Aşağıdaki galeriden bir avatar seçin veya kendi kişiselleştirilmiş avatarınızı yükleyin.",
					contentSecond: "Tasarım yardımına mı ihtiyacınız var? Temasa geçin:",
					female: "Kadın",
					male: "Erkek",
					uploadSectionTitle: "Kendi kişisel avatarınızı yükleyin",
					uploadButton: "Dosya seçin:",
					noFile: "Dosya seçin:",
					assignText:
						"Lüften yukarıdaki temsilciyle ilişkilendirilebilecek bir etkinlik atayın.",
					input: "Buradan isimle arayın",
					avatarLabels: {
						femaleA: "kadın A",
						femaleB: "kadın B",
						femaleC: "kadın C",
						maleA: "erkek A",
						maleB: "erkek B",
						maleC: "erkek C",
					},
				},
			},
			additionalRepresentatives: {
				title: "Ek temsilciler",
				subtitle: "(isteğe bağlı, profil resimleri ile görüntülenecektir)",
				addDialog: {
					title: "Ek Temsilciler Seçin",
					titleScholar: "Choose Co-Author", //new translation
					assignText:
						"Lütfen kendisini temsilci olarak eklemek için bir etkinlik katılımcısını aramaya başlayın.",
					assignError: "Kullanıcı, Stand temsilcisi olarak zaten eklendi!",
					chooseAvatarTextFirst:
						"Aşağıdaki galeriden bir avatar seçin veya kendi kişiselleştirilmiş avatarınızı yükleyin.",
					chooseAvatarTextSecond:
						"Tasarım yardımına mı ihtiyacınız var? Temasa geçin:",
					uploadText: "Kendi kişisel avatarınızı yükleyin",
					chooseFileText: "Dosya seçin:",
					noFile: "Dosya seçilmedi",
				},
			},
			author: {
				title: "Presenting Author", // new translation
				subtitle:
					"Add only an author that registered to the event. You can add 1 author.", // new translation
				mainRepresentative: "Main representative", // new translation
			},
			coAuthors: {
				title: "Co-Authors", // new translation
				subtitle:
					"Add only co-authors that registered to the event. You can add up to 4 co-authors.", // new translation
			},
		},
		videoTab: {
			video: {
				title: "Stand Videosu",
				subtitle: "(Yalnızca Youtube video linki)",
				input: "Youtube URL’sini girin",
			},
			image: {
				title: "Resim",
				subtitle:
					"(Yukarıdaki bölüme bir video bağlantısı eklemediyseniz, resim Standınızda görüntülenir)",
				uploadButton: "Yüklemek için buraya tıklayın",
			},
		},
	},
	boothDashboard: {
		event: "Etkinlik",
		title: "Stantınız",
		manage: "Yönet",
		goToBoothButton: "Beni stanta götür",
		previewNotAvailable: "Stantınız henüz önizlemeye hazır değil.",
		statistics: "İstatistik",
		usersNow: "Anlık katılımcılar",
		usersAllTime: "Tüm zamanlardaki katılımcılar",
		linkClicks: "Bağlantı Tıklanma Sayısı",
		documentViews: "Belge görüntülemeleri",
		videoViews: "Tanıtım Videosu izlendi",
		visitorsSectionTitle: "Stant Ziyaretçileri",
		noVisitors: "Henüz stantınızda ziyaretçi yok.",
		visitorsSearch: "Kullanıcı ara",
		actionType: "AKSİYON TÜRÜ",
		showUsers: "KULLANICILARI GÖSTER",
		ofUsers: "KULLANICI SAYISI",
	},
	polls: {
		polls: "ANKETLER",
		poll: "ANKET",
		noPolls: "Henüz hiç bir anket eklenmedi.",
		pollProgress: "Anket yürürlükte",
		pollClosed: "Anket sona erdi",
		pollVoted: "katılımcı oy kullandı",
	},
	onDemandVideos: {
		sectionTitle: "ON-DEMAND", // new translation
		noOnDemandVideos: "No on demand videos were added yet.", // new translation
	},
	meetings: {
		meeting: "TOPLANTI",
		meetings: "TOPLANTI",
		createDialog: {
			createTitle: "İle toplantı talep et",
			inputDate: "Tarih",
			inputStart: "Başlangıç",
			inputEnd: "Bitiş",
			inputTimezone: "Zaman Dilimi",
			inputNote:
				"Dikkat: Aynı kişiyle yalnızca bir tane toplantı programlanabilir.",
			meetingError:
				"Geçersiz toplantı saati veya tarihi. Lütfen tekrar kontrol edin!",
			buttonDisabled:
				"Bir sohbet başlattıktan sonra toplantıyı programlayabilirsiniz.",
		},
		banner: {
			pending: "Toplantı talebi",
			accepted: "Toplantı tarihine programlandı",
			progress: "Yürürlükteki toplantı",
			cancelTitle: "Toplantı iptal edilsin mi?",
			cancelDescription:
				"Bu toplantıyı iptal etmek istediğinizden emin misiniz?",
			startCall: "ARAMAYI BAŞLAT",
			joinCall: "KATIL",
			pendingOwner: "Şunun için bekleyen toplantı talebi",
			videoCallStarted: "Video toplantı başlatıldı",
		},
		newMeeting: "YENİ TOPLANTI",
		requestMeeting: "İle yeni toplantı talep et",
		startDate: "Başlangıç Tarihi",
		starts: "Başlangıç",
		ends: "Bitiş",
		addMessageHere: "Mesajınızı buraya ekleyin",
		note: "Dikkat: belirli bir kişiyle sadece bir toplnaı talebinde bulunabilirsiniz. Toplantı bittikten (ya da iptal edildikten) sonra bir sonraki toplantıyı tekrar programlayabilirsiniz",
		create: "OLUŞTUR",
		requested: "Talep edildi",
		confirmed: "Onaylandı",
		title: "Toplantılarınız",
		meetingsInfo:
			"Toplantılat sohbetler içerisinden programlanabilir. Lütfen toplantı programlamak istediğiniz kullanıcıyla bir sohbet başlatın.",
	},
	landingPage: {
		animatedSectionButton: "YOLCULUĞA BAŞLAYIN",
		topSectionHeaderPrimary: "Gelecek nesil",
		topSectionHeaderSecondary: "çevrimiçi etkinlikler",
		carouselPresentationText: "Göz gezdirin",
		carouselPresentationTextAccent: "YENİ Neural Network",
		videoPresentationTextPrimary:
			"Neural Network - katılımcılarınızın bayılacağı",
		videoPresentationTextAccent: "çevrimiçi etkinlikler.",
		videoPresentationTextSecondary: "İşte nedeni:",
		testimonialsTextPrimary: "2015'ten beri çevrimiçi etkinlikler -",
		testimonialsTextAccent: "hepsi insanlarla ilgili",
		testimonialsTextSecondary: "...",
		cardsTextPrimary: "Neural Network",
		cardsTextAccent: "vay canına dedirtir",
		cardsTextSecondary: "herkese...",
		contactTextPrimary:
			"Çevrimiçi bir etkinlik düzenlemekle ilgileniyor musunuz?",
		contactTextAccent: "Bize Ulaşın",
		contactTextSecondary: "",
		contactInputName: "İsim",
		contactInputEmail: "Eposta",
		contactInputPhone: "Cep telefonu numarası (isteğe bağlı)",
		contactInputMessage: "Mesaj",
		contactButton: "GÖNDER",
		carouselPresentationTextAccentTextFirst: "YENİ",
		videoPresentationTextPrimaryTextSecond: "çevrimiçi etkinlikler",
		cardsTextPrimaryTextSecond: "getirir",
	},
	loginPlatform: {
		title: "GİRİŞ YAP",
		description:
			"Giriş yaptıktan sonra, kayıt olduğunuz son etkinliğe yönlendirileceksiniz. Menünüzdeki Etkinliklerim seçeneğini kullanarak etkinlikler arasında kolayca geçiş yapabilirsiniz.",
		descriptionTextFirst:
			"Giriş yaptıktan sonra, kayıt olduğunuz son etkinliğe",
		descriptionTextSecond:
			" yönlendirileceksiniz. Menünüzdeki Etkinliklerim seçeneğini kullanarak ",
		descriptionTextThird: "etkinlikler arasında kolayca geçiş yapabilirsiniz.",
	},
	skipped: {
		startVideoChat:
			"Bir Video Toplantı düzenledim. Katılmak için lütfen buraya tıklayın.",
	},
	hubDropdownTitle: "MERKEZ MENÜ",
	marketplace: {
		title: {
			default: "Pazaryeri",
			uppercased: "PAZARYERİ",
		},
		label: "ÜRÜNLER",
		addNew: "YENİ EKLE",
		addNewProduct: "Yeni ürün ekle",
		editProduct: "Ürünü düzenle",
		noProducts: "Henüz ürün yok",
		publish: "YAYINLA",
		productTitle: "Ürün başlığı",
		productDescription: "Ürün tanımı",
		productLink: "Ürün linki",
		productImage: "ÜRÜN GÖRSELİ",
		uploadHint:
			"Ürün önizlemesi olarak görüntülenecek olan bir görsel yükleyin. Daha kaliteli görüntü için tavsiye edilen çözünürlük 600*600 px değerindedir",
		descriptionHint: "Tanım 300 karakter ile sınırlandırılmıştır",
		uploadImage: "ÜRÜNÜN DEĞERİNİ ARTIRMAK İÇİN GÖRSEL YÜKLEYİN",
		uploaded: "Yüklendi",
	},
	personalAgenda: {
		agenda: "AJANDA",
		myProgram: "PROGRAMIM",
		myMeetings: "TOPLANTILARIM",
		addNewTask: {
			default: "Yeni görev ekle",
			uppercased: "YENİ GÖREV EKLE",
		},
		noProgram: "Şu an için mevcut program girdisi bulunmamaktadır",
		noMeetings: "Şu an için mevcut toplantı bulunmamaktadır",
		title: "Başlık",
		startDate: "Başlangıç Tarihi",
		starts: "Başlangıç",
		ends: "Bitiş",
		create: "OLUŞTUR",
		schedule: "ÇİZELGE",
		personalAgenda: "KİŞİSEL AJANDA",
		timetableMenu: "ÇİZELGE MENÜSÜ",
		gmt: "GMT",
	},
	program: {
		toAgenda: "AJANDAYA",
		attending: "KATILIYOR",
		inPerson: "Yüz yüze",
		online: "Çevrimiçi",
		searchWrapperTitle: "TÜM OTURUMLARDA ARA",
		searchWrapperTitlePast: "TÜM GEÇMİŞ OTURUMLARDA ARA",
		searchWrapperTitleUpcoming: "TÜM GELECEK OTURUMLARDA ARA",
		searchWrapperTitleInterval: "OTURUMLARDA ARA",
	},
	businessCard: {
		businessCard: {
			default: "İşletme Kartviziti",
			uppercased: "İŞLETME KARTVİZİTİ",
		},
		editText: "METNİ DÜZENLE",
		colors: "RENKLER",
		branding: "MARKALAMA",
		cardPreview: "KARTVİZİT ÖNİZLEMESİ",
		onceSaved:
			"Kaydedildikten sonra, İşletme Kartviziti profilinizde bağlanacaktır. Bu sizin Ağ Oluşturma, Sohbet ve diğer önemli fonksiyonlara erişiminizi mümkün kılacaktır. Profilinizi sildiğinizde kartvizit otomatik olarak silinecektir",
		required: "talep edildi",
		designation: "Atama",
		Mobile: "Mobil",
		Phone: "Telefon",
		Website: "Websitesi",
		companyName: "Şirket ismi",
		honoric: "Tercih ettiğiniz hitap şeklini ekleyin mesela Bay, Bayan gibi",
		fillUpData: "VERİYİ HESABIMDAN DOLDUR",
		primaryColor: "BİRİNCİL RENK",
		primaryColorDescription:
			"Renk aksan rengidir. Marka renginizi burada kullanın",
		backgroundColor: "ARKAPLAN RENGİ",
		backgroundColorDescription:
			"Bu renk ek renktir. Arkaplan olarak kullanın. Direkt olarak siyah ya da beyaz olabilir.",
		backgroundTextColor: "ARKAPLAN METNİ & SİMGE RENGİ",
		backgroundTextColorDescription:
			"Telefonlar, eposta ve website detayları için bu renk olmalı ve Arkaplan rengiyle de zıt olmalıdır. Kartvizit önizlemesine gidi ve kartvizitin okunabilir ve de yeteri düzeyde kontrasta sahip olduğundan emin olun",
		companyLogo: "ŞİRKET LOGOSU",
		uploadMessage:
			"Şirket logosu olarak görüntülenecek bir görsel yükleyin. Daha kaliteli görüntü için tavsiye edilen çözünürlük 320*200 px değerindedir. Logoyu transparan arkaplanla kullanın (.png dosyası formatı tercih edilir)",
		uploadImage: "GÖRSEL YÜKLE",
		request: "TALEP ET",
		open: "AÇ",
		pending: "BEKLEYEN",
	},
	businessWallet: {
		short: "B CÜZDAN",
		full: "İŞLETME CÜZDANI",
		myContacts: "İRTİBATLARIM",
		requests: "TALEPLER",
		pendings: "BEKLEYENLER",
		searchByName: "İsim ile ara",
		chat: "Sohbet",
		openCard: "KARTVİZİT AÇ",
		empty: "Burada kimse yok",
		cancelRequest: "TALEBİ İPTAL ET",
		approve: "ONAYLA",
		reject: "REDDET",
		myNotes: "NOTLARIM",
		addNewNote: "Yeni not ekle",
		delete: "SİL",
		saveThoughts: "Düşüncelerinizi organize şekilde tek bir yerde toplayın",
	},
	videoConferences: {
		message:
			"Bir Video Toplantı düzenledim. Katılmak için lütfen buraya tıklayın.",
		clickToStart: "Bir video sohbet başlatmak için buraya tıklayın",
		fullScreen: "TAM EKRANDA AÇ",
		minimizeScreen: "EKRANI KÜÇÜLT",
	},
	sessions: {
		sessionList: "OTURUM LİSTESİ",
		createNewSession: {
			uppercased: "YENİ OTURUM OLUŞTURUN",
			default: "Yeni oturum oluşturun",
		},
		sessionName: {
			uppercased: "OTURUM İSMİ",
			default: "Oturum ismi",
		},
		sessionLink: "OTURUM LİNKİ",
		sessionType: "OTURUM TÜRÜ",
		manage: "YÖNET",
		edit: "DÜZENLE",
		delete: "SİL",
		live: {
			uppercased: "CANLI",
			lowercased: "canlı",
			default: "Canlı",
		},
		recorded: {
			uppercased: "KAYDEDİLDİ",
			lowercased: "kaydedildi",
			default: "Kaydedildi",
		},
		editSession: "Oturumu düzenleyin",
		uploadVideo: "VİDEO YÜKLEYİN",
		create: "OLUŞTUR",
		discard: "AT",
		speakers: "KONUŞMACILAR",
		moderators: "MODERATÖRLER",
		speakerName: "KONUŞMACI İSMİ",
		moderatorName: "MODERATÖR İSMİ",
		kick: "ÇIKART",
		screenSharing: "EKRAN PAYLAŞIMI",
		audio: "SES",
		video: "VİDEO",
		startStream: "YAYINI BAŞLAT",
		stopStream: "YAYINI DURDUR",
		startRecording: "KAYDETMEYE BAŞLA",
		endRecording: "KAYDETMEYİ BİTİR",
		sessionChat: "OTURUM SOHBETİ",
		typeMessageHere: "Buraya mesaj yazın",
		sessionLinks: "OTURUM LİNKLERİ",
		speakerUrl: "Konuşmacı URL",
		moderatorUrl: "Moderatör URL",
		copyUrl: "URL kopyala",
		organizer: "ORGANİZATOR",
		moderator: "MODERATÖR",
		speaker: "KONUŞMACI",
		joinAsSpeaker: "Konuşmacı olarak katılın",
		invitedAsSpeaker:
			"AIM Oturum demosuna konuşmacı olarak davet edildiniz. İsminizi girin (oturumun katılımcıları için görünür olacak)",
		yourName: "İsminiz",
		enter: "GİR",
		waitForApproval: "Lütfen onay için bekleyin",
		deny: "KABUL ETME",
		admit: "KABUL ET",
		joinAsModeraotr: "Moderatör olarak katılın",
		invitedAsModerator:
			"AIM Oturum demosuna moderatör  olarak davet edildiniz. İsminizi girin (oturumun katılımcıları için görünür olacak)",
		declined: "Hayır, bir sebepten ötürü reddedildiniz",
	},
	breadcrumbsNavigation: {
		mainLobby: "Ana Lobi",
		village: "Köy",
	},
	village: {
		clickToOpen: "AçmakiçinTıklayın",
	},
	gdpr: {},
};

export default tr;
