import React from 'react';
import {preventDefaultDrag} from '../../Utils/utils';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {ReactComponent as EditPostIcon} from '../../Images/svg/edit-post.svg';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as DeleteIcon} from '../../Images/svg/rubbish-bin.svg';
import WarningIcon from '../../Images/svg/WarningIcon';
import TimeAgo from './TimeAgo';

class SinglePostComment extends React.PureComponent {
    render() {
        const {
            user,
            post,
            comment,
            commentUser,
            commentText,
            canDeleteComment,
            handleOpenActions,
            showActions,
            commentEditMode,
            handleDeleteComment,
            enterCommentEditMode,
            decodeHtml,
            isActiveModeration,
            hasNotApprovedRights,
            onApprovePostComment,
            languages,
            translation,
            defaultTranslation,
            setWrapperRef,
            handleEnterKey,
        } = this.props;

        return (
            <li key={comment._id} className="single-comment-container">
                <div onDragStart={preventDefaultDrag} className="comment-header">
                    <div
                        className="user-avatar"
                        style={{
                            backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${commentUser?.avatarSmall}')`,
                        }}
                    />
                    <div
                        onDragStart={preventDefaultDrag}
                        className={`comment-container ${isActiveModeration ? 'container-moderation' : ''} ${
                            comment.approved === false ? 'not-approved' : ''
                        }`}
                    >
                        <p onDragStart={preventDefaultDrag}>
                            <span draggable="false" className="user-full-name">
                                {commentUser?.first} {commentUser?.last}
                            </span>
                            {commentText}
                        </p>
                        <TimeAgo createdAt={comment.createdAt} updatedAt={comment.updatedAt}/>
                        {comment.approved === false ? (
                            <button className="delete-button wall-action" onClick={handleDeleteComment(comment._id)}>
                                <DeleteIcon width="18" height="18" />
                            </button>
                        ) : (
                            canDeleteComment && (
                                <div onDragStart={preventDefaultDrag} className="postActions">
                                    <div onDragStart={preventDefaultDrag} className="menu-actions">
                                        {(!hasNotApprovedRights || !isActiveModeration) && user._id === comment.user ? (
                                            <button
                                                tabIndex="0"
                                                className="more-button wall-action"
                                                onDragStart={preventDefaultDrag}
                                                onClick={handleOpenActions(comment._id)}
                                                onKeyDown={handleEnterKey('comment', comment._id)}
                                            >
                                                <MoreVertIcon />
                                            </button>
                                        ) : (
                                            <div className="postActions">
                                                <button
                                                    tabIndex="0"
                                                    className="delete-button wall-action"
                                                    onClick={handleDeleteComment(comment._id)}
                                                    onKeyDown={handleEnterKey('comment-delete', comment._id)}
                                                >
                                                    <DeleteIcon width="18" height="18" />
                                                </button>
                                            </div>
                                        )}
                                        {showActions === comment._id ? (
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className={`actions-list ${
                                                    languages.platformLanguage === 'ar' ? 'prevent-direction' : ''
                                                }`}
                                                ref={setWrapperRef}
                                            >
                                                <ul>
                                                    <li
                                                        tabIndex="0"
                                                        className={`${commentEditMode ? 'edit-disabled' : ''}`}
                                                        onClick={enterCommentEditMode(
                                                            post._id,
                                                            comment._id,
                                                            decodeHtml(comment.text)
                                                        )}
                                                        onKeyDown={handleEnterKey(
                                                            'comment-edit',
                                                            {postId: post._id, commentId: comment._id},
                                                            decodeHtml(comment.text)
                                                        )}
                                                    >
                                                        <EditPostIcon fill={colors.dark} width="18" height="18" />
                                                        <span>
                                                            {translation?.sessions?.edit ||
                                                                defaultTranslation?.sessions?.edit}
                                                        </span>
                                                    </li>
                                                    <li
                                                        tabIndex="0"
                                                        onClick={handleDeleteComment(comment._id)}
                                                        onKeyDown={handleEnterKey('comment-delete', comment._id)}
                                                    >
                                                        <DeleteIcon width="18" height="18" />
                                                        <span>
                                                            {translation?.generalText.delete ||
                                                                defaultTranslation?.generalText.delete}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
                {comment.approved === false ? (
                    !hasNotApprovedRights ? (
                        <button
                            className="approve-button primary-button"
                            onClick={onApprovePostComment(post._id, comment._id)}
                        >
                            {translation?.wall.approvePostComment || defaultTranslation?.wall.approvePostComment}
                        </button>
                    ) : (
                        <div className="pending-approval">
                            <span>{translation?.wall.pendingApproval || defaultTranslation?.wall.pendingApproval}</span>
                            <WarningIcon fillSecondary={colors.secondary} />
                        </div>
                    )
                ) : null}
            </li>
        );
    }
}

export default SinglePostComment;