import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Spinner = (props) => {
    return (
        <div
            className={`${
                props.custom === 'lobby-spinner'
                    ? 'lobby-spinner'
                    : props.custom === 'organizer-spinner-side'
                    ? 'organizer-spinner-side'
                    : props.custom === 'organizer-spinner-side-double'
                    ? 'organizer-spinner-side-double'
                    : props.custom === 'organizer-spinner'
                    ? 'organizer-spinner'
                    : 'spinner'
            }`}
        >
            {!props.noAnimation && <CircularProgress color="primary" />}
            {props.message && <p>{props.message}</p>}
        </div>
    );
};

export default Spinner;
