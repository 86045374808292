import {withStyles} from '@material-ui/core/styles/index';
import Checkbox from '@material-ui/core/Checkbox';
import React from 'react';
import colors from '../CSS/_variables.module.scss';

const WhiteCheckbox = withStyles({
    root: {
        color: '#ffffff',
        padding: 0,
        '&$checked': {
            color: colors.primary,
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default WhiteCheckbox;
