import React from 'react';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import PlatformMenu from '../Components/PlatformMenu';
import {connect} from 'react-redux';
import * as actions from '../store/actions/index';
import {Redirect} from 'react-router-dom';
import HomepageBackground from '../Images/background.jpg';
import axios from '../store/axios-instance';
import {preventDefaultDrag} from '../Utils/utils';

class PasswordRecovery extends React.Component {
    state = {
        token: '',
        password: '',
        passwordConfirmation: '',
        disabled: true,
    };

    componentDidMount() {
        // get the token from url, save it to state so that we can send it with the request
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('token');

        this.setState({token: token});

        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        });
    }
    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule('isPasswordMatch');
        const mobileMenu = document.getElementsByClassName('platform mobile-menu-wrapper hide');
        if (mobileMenu.length !== 0) {
            mobileMenu[0].classList.remove('hide');
        }
    }

    handleChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value,
            },
            () => {
                this.refs.form.isFormValid().then((isValid) => {
                    this.setState({disabled: !isValid});
                });
            }
        );
    };

    handleInputFocus = () => {
        const mobileMenu = document.getElementsByClassName('platform mobile-menu-wrapper ');
        if (mobileMenu) {
            mobileMenu[0].classList.add('hide');
        }
    };

    handleInputBlur = () => {
        const mobileMenu = document.getElementsByClassName('platform mobile-menu-wrapper hide');
        if (mobileMenu.length !== 0) {
            mobileMenu[0].classList.remove('hide');
        }
    };

    handleResetPassword = () => {
        axios({
            method: 'post',
            url: '/auth/reset-password',
            data: {password: this.state.password, token: this.state.token},
        })
            .then((response) => {
                localStorage.setItem('token', response.data.token);
                this.props.onAutoLoginWithToken();
            })
            .catch((error) => {
                this.setState({error: 'There seems to be an issue.'});
            });
    };

    render() {
        return (
            <div>
                <PlatformMenu />
                {this.props.loggedIn ? <Redirect to={'/'} /> : null}
                <div
                    onDragStart={preventDefaultDrag}
                    className="homepage login-page register-participant password-recovery-page"
                >
                    <div
                        onDragStart={preventDefaultDrag}
                        className="event-container"
                        style={{backgroundImage: `url('${HomepageBackground}')`}}
                    >
                        <div onDragStart={preventDefaultDrag} className="homepage-overlay">
                            <div onDragStart={preventDefaultDrag} className="smallContainer">
                                <div onDragStart={preventDefaultDrag} className="single-event-container">
                                    <div onDragStart={preventDefaultDrag} className="right-form">
                                        <p>Please enter new password:</p>
                                        <div onDragStart={preventDefaultDrag} className="register-form-wrapper">
                                            <ValidatorForm ref="form" onSubmit={this.handleResetPassword}>
                                                <div onDragStart={preventDefaultDrag} className="validator-input">
                                                    <TextValidator
                                                        className="field-container"
                                                        label="Password"
                                                        onChange={this.handleChange}
                                                        name="password"
                                                        value={this.state.password}
                                                        variant="filled"
                                                        type="password"
                                                        fullWidth
                                                        validators={['required', 'minStringLength:8']}
                                                        errorMessages={[
                                                            'Field is required',
                                                            'Password must have min 8 characters',
                                                        ]}
                                                        onFocus={this.handleInputFocus}
                                                        onBlur={this.handleInputBlur}
                                                    />
                                                    <TextValidator
                                                        className="field-container"
                                                        label="Confirm Password"
                                                        onChange={this.handleChange}
                                                        name="passwordConfirmation"
                                                        value={this.state.passwordConfirmation}
                                                        variant="filled"
                                                        type="password"
                                                        fullWidth
                                                        validators={[
                                                            'isPasswordMatch',
                                                            'required',
                                                            'minStringLength:8',
                                                        ]}
                                                        errorMessages={[
                                                            'Password mismatch',
                                                            'Field is required',
                                                            'Password must have min 8 characters',
                                                        ]}
                                                        onFocus={this.handleInputFocus}
                                                        onBlur={this.handleInputBlur}
                                                    />
                                                </div>
                                                <Button
                                                    type="submit"
                                                    classes={{label: 'dialog-btn-uppercase'}}
                                                    disabled={this.state.disabled}
                                                    variant="contained"
                                                    color="secondary"
                                                >
                                                    Change password
                                                </Button> <p>{this.state.error}</p>
                                            </ValidatorForm>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAutoLoginWithToken: () => dispatch(actions.autoLoginWithToken()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecovery);
