import React, {PureComponent} from 'react';
import * as actions from '../../../store/actions';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {ReactComponent as EmptyIcon} from '../../../Images/svg/no_events.svg';
import { preventDefaultDrag} from '../../../Utils/utils';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';

class MyEventsMobile extends PureComponent{
    state = {
        selectedOption: '',
    };

    redirectToEvent = (eventSlug, eventId) => (e) => {
        this.setState({
            selectedOption: e.target.value,
        });
        setTimeout(() => {
            if (eventSlug !== this.props.eventSlug) {
                // all the users that are on mobile, are redirected to lobby

                this.props.history.push(`/event/${eventSlug}/lobby`);

                this.props.setEventId(eventId);
                this.props.setEventSlug(eventSlug);
                this.props.onCloseTopNavMobile();
                // we should use this action when we want to do any kind of logic or
                // update / reset data in the redux store
                this.props.userNavigatedToOtherEvent()
            }
        }, 150);
    };

    getEventsList = () => {
        const {selectedOption} = this.state;
        const {user, event} = this.props;
        let events = user.eventRoles.slice(0).filter(eventItem => eventItem.event._id !== event._id).sort((x, y) => {
            if (x.event.name < y.event.name) { return -1; }
            if (x.event.name > y.event.name) { return 1; }
            return 0;
        });
        return events?.map((eventRole) => {
            // don't display events where the organizer is "not approved",
            // unless the current user is actually the organizer of the event
            if (
                eventRole.event.owner &&
                eventRole.event.owner.organizerStatus !== 'approved' &&
                !eventRole.roles.includes('organizer')
            ) {
                return null;
            }
            return (
                <FormControlLabel
                    onChange={this.redirectToEvent(eventRole.event.slug, eventRole.event._id)}
                    value={eventRole.event.name}
                    control={<Radio />}
                    label={eventRole.event.name}
                    checked={selectedOption === eventRole.event.name}
                    key={eventRole.event._id}
                />
            );
        });
    };

    render() {
        const {open, user, event, closeDialog, translation, defaultTranslation} = this.props;
        return (
            <Dialog
                id="my-events-dialog"
                open={open}
                onClose={closeDialog}
                aria-labelledby="form-dialog-title"
            >
                <DialogContent>
                    <div onDragStart={preventDefaultDrag} className="my-events-mobile">
                        <div>
                            <h3>
                                {translation?.myEventsDropdown.myEventsList ||
                                defaultTranslation?.myEventsDropdown.myEventsList}
                            </h3>
                            <p>
                                {translation?.myEventsDropdown.yourCurrentEvent ||
                                defaultTranslation?.myEventsDropdown.yourCurrentEvent}
                                {': ' + event?.title}
                            </p>
                            <div className="events-list-wrapper">
                                {user?.eventRoles?.length > 1
                                    ?
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="gender" name="column-radio-buttons-group">
                                            {this.getEventsList()}
                                        </RadioGroup>
                                    </FormControl>
                                    :
                                    <div className="table-row empty-row">
                                        <div>
                                            <EmptyIcon/>
                                            <p>
                                                {translation?.myEventsDropdown.noEvents ||
                                                defaultTranslation?.myEventsDropdown.noEvents}
                                            </p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        event: state.event.data,
        eventSlug: state.event.eventSlug,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setEventId: (eventId) => dispatch(actions.setEventId(eventId)),
        setEventSlug: (eventSlug) => dispatch(actions.setEventSlug(eventSlug)),
        onCloseTopNavMobile: () => dispatch(actions.topNavCloseMobile()),
        userNavigatedToOtherEvent: () => dispatch(actions.userNavigatedToOtherEvent()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyEventsMobile));