import React from 'react';
import * as actions from '../store/actions';
import {connect} from 'react-redux';
import colors from '../CSS/_variables.module.scss';
import {ReactComponent as GreaterArrow} from '../Images/svg/greater-arrow.svg';
import {preventDefaultDrag} from '../Utils/utils';

class CloseTopNavButton extends React.Component {
    handleCloseTopNav = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onCloseTopNav();
        if (this.props.hideTooltip) {
            this.props.hideTooltip();
        }
    };

    render() {
        return (
            <div onDragStart={preventDefaultDrag} onClick={this.handleCloseTopNav} className="close-component">
                <GreaterArrow fill={colors.whiteVariant} />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseTopNav: () => dispatch(actions.topNavClose()),
    };
};

export default connect(null, mapDispatchToProps)(CloseTopNavButton);
