import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import axios from '../../store/axios-instance';
import {preventDefaultDrag} from '../../Utils/utils';

class ChangePassword extends React.Component {
    state = {
        oldPassword: '',
        password: '',
        passwordConfirmation: '',
        disabled: true,
        error: false,
    };

    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            const {password} = this.state;
            if (value !== password) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('passwordIsSame', (value) => {
            if (value === this.state.oldPassword) {
                return false;
            }
            return true;
        });
    }

    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule('isPasswordMatch');
        ValidatorForm.removeValidationRule('passwordIsSame');
    }

    handleChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value,
                error: false,
            },
            () => {
                this.refs.form.isFormValid().then((isValid) => {
                    this.setState({disabled: !isValid});
                });
            }
        );
    };

    handleUpdatePassword = () => {
        const {oldPassword, password} = this.state;
        axios({
            method: 'put',
            url: '/users',
            data: {oldPassword: oldPassword, password: password},
        })
            .then((response) => {
                this.props.onRefreshUserData();
                this.props.closeDialog();
            })
            .catch((error) => {
                this.setState({error: true});
            });
    };

    render() {
        const {oldPassword, password, passwordConfirmation, error} = this.state;
        const {opened, closeDialog, translation} = this.props;
        return (
            <Dialog
                open={opened}
                onClose={() => closeDialog('')}
                aria-labelledby="form-dialog-title"
                className="booth-dialog change-password-dialog"
            >
                <DialogContent className="booth-dialog-content booth-video-dialog-container">
                    <DialogTitle id="form-dialog-title">{translation?.changePassword.title}</DialogTitle>
                    <ValidatorForm ref="form" onSubmit={this.handleUpdatePassword} className="change-password-form">
                        <div onDragStart={preventDefaultDrag} className="field-container">
                            <TextValidator
                                label={translation?.changePassword.oldPassword}
                                onChange={this.handleChange}
                                name="oldPassword"
                                value={oldPassword}
                                variant="outlined"
                                type="password"
                                fullWidth
                                validators={['required', 'minStringLength:8']}
                                errorMessages={[
                                    `${translation?.errors.required}`,
                                    `${translation?.errors.passwordMinLength}`,
                                ]}
                            />
                        </div>
                        <div onDragStart={preventDefaultDrag} className="field-container">
                            <TextValidator
                                label={translation?.changePassword.inputPassword}
                                onChange={this.handleChange}
                                name="password"
                                value={password}
                                variant="outlined"
                                type="password"
                                fullWidth
                                validators={['required', 'minStringLength:8', 'passwordIsSame']}
                                errorMessages={[
                                    `${translation?.errors.required}`,
                                    `${translation?.errors.passwordMinLength}`,
                                    `${translation?.errors.passwordIsSame}`,
                                ]}
                            />
                        </div>
                        <div onDragStart={preventDefaultDrag} className="field-container">
                            <TextValidator
                                label={translation?.changePassword.inputConfirmPassword}
                                onChange={this.handleChange}
                                name="passwordConfirmation"
                                value={passwordConfirmation}
                                variant="outlined"
                                type="password"
                                fullWidth
                                validators={['isPasswordMatch', 'required', 'minStringLength:8']}
                                errorMessages={[
                                    `${translation?.errors.passwordMismatch}`,
                                    `${translation?.errors.required}`,
                                    `${translation?.errors.passwordMinLength}`,
                                ]}
                            />
                        </div>
                        {error && (
                            <p onDragStart={preventDefaultDrag} className="error-message">
                                {translation?.changePassword.oldPasswordError}
                            </p>
                        )}
                    </ValidatorForm>
                </DialogContent>
                <DialogActions className="buttons-actions">
                    <Button onClick={() => closeDialog('')} type="button" classes={{label: 'dialog-btn-uppercase'}}>
                        {translation?.generalText.close}
                    </Button>
                    <Button
                        onClick={this.handleUpdatePassword}
                        type="button"
                        classes={{label: 'dialog-btn-uppercase'}}
                        disabled={this.state.disabled}
                    >
                        {translation?.generalText.update}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRefreshUserData: () => dispatch(actions.refreshUserData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
