import * as actionTypes from '../actions/actionTypes';
import produce from "immer";

const initialState = {
    buildingId: null,
    data: null,
    loading: false,
};

const reducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.SET_BUILDING_ID:
                draft.buildingId = action.payload;
                break;

            case actionTypes.GET_BUILDING_START:
                draft.loading = true;
                break;

            case actionTypes.GET_BUILDING_SUCCESS:
                draft.loading = false;
                draft.data = action.payload;
                draft.buildingId = action.payload?._id
                break;

            case actionTypes.GET_BUILDING_FAIL:
                draft.loading = false;
                break;

            default:
                break;
        }
    })



export default reducer;
