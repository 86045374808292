import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Checkbox,
} from '@material-ui/core';
import Spinner from '../SmallLayoutComponents/Spinner';
class Confirm extends React.Component {
    state = {
        agree: true,
    };

    componentDidMount() {
        const {dialogAgreeText, coOrganizerTermsAgreement} = this.props;
        if (dialogAgreeText || coOrganizerTermsAgreement) {
            this.setState({agree: false});
        }
    }

    onChangeAgree = (e) => {
        this.setState({
            agree: e.target.checked,
        });
    };

    render() {
        const {agree} = this.state;
        const {
            open,
            mobile,
            dialogAgreeText,
            isLoading,
            closeConfirm,
            classList,
            dialogTitle,
            dialogDescription,
            dialogCancelButtonLabel,
            handleConfirm,
            dialogConfirmButtonLabel,
            coOrganizerTermsAgreement,
        } = this.props;

        return (
            <Dialog
                open={open}
                onClose={isLoading ? null : closeConfirm}
                aria-labelledby="form-dialog-title"
                className={`${mobile ? 'dialog-mobile' : ''} ${classList ? [...classList] : ''}`}
                PaperProps={{
                    classes: {
                        root:
                            dialogTitle === 'Unsaved changes' ||
                            dialogTitle === 'Import program' ||
                            dialogTitle === 'Create new Exhibit hall' ||
                            dialogTitle === 'Change access' ||
                            dialogTitle === 'Confirm disable package' ||
                            dialogTitle === 'ENABLE CO-ORGANIZER FUNCTIONALITY'
                                ? `reverse-rtl ${coOrganizerTermsAgreement ? 'corg-gdpr-width' : ''}`
                                : '',
                    },
                }}
            >
                {isLoading && <Spinner />}
                <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent className="scroll-left-container">
                    <DialogContentText
                        id="form-dialog-secondary"
                        dangerouslySetInnerHTML={{__html: dialogDescription}}
                    />
                    {dialogAgreeText && (
                        <DialogContentText className="agree-container">
                            <Checkbox
                                checked={agree}
                                onChange={this.onChangeAgree}
                                name="reviewedGDPR"
                                color="secondary"
                            />
                            <span
                                onDragStart={(e) => e.preventDefault()}
                                draggable="false"
                                className="confirm-event-privacy-policy-text"
                            >
                                I read the description above and agree to enable Enhanced Booth Statistics
                                functionality. I understand that by doing so, I am making a decision on behalf of the
                                event organizer to share event participants’ personal data with event exhibitors and
                                hereby accept all liability and risks related to such sharing.
                            </span>
                        </DialogContentText>
                    )}
                    {coOrganizerTermsAgreement && (
                        <DialogContentText className="agree-container">
                            <Checkbox
                                checked={agree}
                                onChange={this.onChangeAgree}
                                className="corg-terms-checkbox"
                                name="reviewedGDPR"
                                color="secondary"
                            />
                            <span
                                onDragStart={(e) => e.preventDefault()}
                                draggable="false"
                                className="confirm-event-privacy-policy-text"
                            >
                                I read the description above and agree to enable Co-organizer functionality. I
                                understand that by doing so (1) I may provide certain participants with access to event
                                management functionalities and preferences; (2) I am making a decision on behalf of the
                                event organizer to enable functionality that may allow sharing event participants’
                                personal data with users who will have co-organizer access at the determined privilege
                                level. I hereby accept, on behalf of the event organizer, all liability and risks
                                associated with such decisions and sharing. I understand that it is the event organizers
                                responsibility to manage the event and to ensure proper processing of personal data
                                through Co-organizer functionality.
                            </span>
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button type="button" classes={{label: 'dialog-btn-uppercase'}} onClick={closeConfirm}>
                        {dialogCancelButtonLabel ? dialogCancelButtonLabel : 'Cancel'}
                    </Button>
                    <Button
                        type="button"
                        classes={{label: 'dialog-btn-uppercase'}}
                        onClick={handleConfirm}
                        disabled={!agree}
                    >
                        {dialogConfirmButtonLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default Confirm;
