import React from 'react';
import cn from 'classnames';

import IconContainer from '../IconContainer/IconContainer';

import './Button.scss';
import {preventDefaultDrag} from '../../Utils/utils';

const Button = ({component: Component = 'button', title, icon, iconSize, isDisabled, ...rest}) => (
    <Component className={cn('Button', {'Button--disabled': isDisabled})} {...rest}>
        {icon && (
            <span onDragStart={preventDefaultDrag} draggable="false" className="Button__icon">
                <IconContainer size={iconSize}>{icon}</IconContainer>
            </span>
        )}

        <span onDragStart={preventDefaultDrag} draggable="false" className="Button__title">
            {title}
        </span>
    </Component>
);

export default Button;
