import React from "react";
import colors from "../../CSS/_variables.module.scss";
import { ReactComponent as Arrow } from "../../Images/svg/showfloor-arrow-image.svg";
import { preventDefaultDrag } from "../../Utils/utils";

class AuditoriumNavigationMobile extends React.Component {
	getPreviousAuditoriumIndex = () => {
		const { auditoriums, auditoriumRoomIndex } = this.props;
		let previousAuditoriumIndex =
			(auditoriumRoomIndex - 1 + auditoriums.length) % auditoriums.length;

		while (!auditoriums[previousAuditoriumIndex].visible) {
			previousAuditoriumIndex =
				(previousAuditoriumIndex - 1 + auditoriums.length) % auditoriums.length;
			if (previousAuditoriumIndex === auditoriumRoomIndex) {
				break; // Prevent infinite loop if no visible auditorium is found
			}
		}

		return previousAuditoriumIndex;
	};

	getNextAuditoriumIndex = () => {
		const { auditoriums, auditoriumRoomIndex } = this.props;
		let nextAuditoriumIndex = (auditoriumRoomIndex + 1) % auditoriums.length;

		while (!auditoriums[nextAuditoriumIndex].visible) {
			nextAuditoriumIndex = (nextAuditoriumIndex + 1) % auditoriums.length;
			if (nextAuditoriumIndex === auditoriumRoomIndex) {
				break; // Prevent infinite loop if no visible auditorium is found
			}
		}

		return nextAuditoriumIndex;
	};

	render() {
		const {
			timeSlot,
			setActiveAuditoriumIndex,
			auditoriums,
			auditoriumRoomIndex,
			timezoneName,
		} = this.props;

		let formatedTimezoneName = timezoneName.split(")");
		formatedTimezoneName = formatedTimezoneName[0];
		formatedTimezoneName = formatedTimezoneName.substring(1);

		return (
			<div
				onDragStart={preventDefaultDrag}
				className={
					"auditoriums-navigation-mobile " +
					(timeSlot?.video ? "has-booth-wall" : "")
				}
			>
				<div
					onDragStart={preventDefaultDrag}
					className="button previous"
					onClick={setActiveAuditoriumIndex(this.getPreviousAuditoriumIndex())}
				>
					<div onDragStart={preventDefaultDrag} className="button-container">
						<div onDragStart={preventDefaultDrag} className="details">
							<Arrow stroke={colors.primary} />
						</div>
					</div>
				</div>
				<div
					onDragStart={preventDefaultDrag}
					className="auditorium-title-container"
				>
					<p onDragStart={preventDefaultDrag} className="name">
						{auditoriums[auditoriumRoomIndex].name}
					</p>
					<p>
						<span>{formatedTimezoneName}</span>
						<span>
							{auditoriums
								.filter((auditorium) => auditorium.visible)
								.findIndex(
									(auditorium) =>
										auditorium === auditoriums[auditoriumRoomIndex],
								) + 1}
							/{auditoriums.filter((auditorium) => auditorium.visible).length}{" "}
							STAGES
						</span>
					</p>
				</div>
				<div
					onDragStart={preventDefaultDrag}
					className="button next"
					onClick={setActiveAuditoriumIndex(this.getNextAuditoriumIndex())}
				>
					<div onDragStart={preventDefaultDrag} className="button-container">
						<div onDragStart={preventDefaultDrag} className="details">
							<Arrow stroke={colors.primary} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AuditoriumNavigationMobile;
