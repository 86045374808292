import React, {PureComponent} from 'react';
import Button from '@material-ui/core/Button';
import ConfirmDialog from '../../Dialogs/Confirm';
import axios from '../../store/axios-instance';
import * as actions from '../../store/actions';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {ReactComponent as RemoveUserIcon} from '../../Images/svg/remove-user.svg';

class UnregisterEvent extends PureComponent {
    state = {
        unregisterFromEventDialog: false,
    };

    handleUnregisterFromEvent = () =>
        this.setState({
            unregisterFromEventDialog: true,
        });

    handleCloseDialogUnregisterFromEvent = () =>
        this.setState({
            unregisterFromEventDialog: false,
        });

    handleCloseDialog = () =>
        this.setState({
            unregisterFromEventDialog: false,
        });


    unregisterFromEvent = () => {
        const {user, eventSlug, eventId, history} = this.props;
        axios({method: 'delete', url: `/event/v2/${eventId}/unregister-user/${user._id}`})
            .then(() => {
                const userRolesInEvent = user.eventRoles.find((role) => role.event._id === eventId);
                const userIsOrganizer = userRolesInEvent && userRolesInEvent.roles.includes('organizer');
                if (userIsOrganizer) {
                    this.props.onGetUserEventProfile(eventId, user._id);
                    this.props
                        .onRefreshUserData()
                        .then(() =>
                            history.push({
                                pathname: `/event/${eventSlug}/lobby`,
                                state: {isUnregisteredOrganizer: true},
                            })
                        );
                } else {
                    this.props.onRefreshUserData().then(() => {
                        window.location.href = `${process.env.REACT_APP_AIFG_WP_LINK}`;
                    });
                }
            })
            .catch(() => {});
    };

    render() {
        const {unregisterFromEventDialog} = this.state;
        const {translation, event} = this.props;

        return (
            <>
                <Button
                    onClick={this.handleUnregisterFromEvent}
                    type="button"
                    className="change-password-btn"
                >
                    <RemoveUserIcon/>
                    <span>{translation?.myAccountPage.unregisterFromEventButton}</span>
                </Button>
                {unregisterFromEventDialog && (
                    <ConfirmDialog
                        open={unregisterFromEventDialog}
                        closeConfirm={this.handleCloseDialogUnregisterFromEvent}
                        dialogTitle={`${translation?.myAccountPage.unregisterCurrentEventButton}`}
                        dialogDescription={`${translation?.myAccountPage.unregisterCurrentEventConfirmation} ${event.name}`}
                        handleConfirm={this.unregisterFromEvent}
                        dialogCancelButtonLabel={`${translation?.generalText.cancel}`}
                        dialogConfirmButtonLabel={`${translation?.generalText.confirm}`}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        event: state.event.data,
        languages: state.languages,
        eventUsers: state.eventUsers,
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRefreshUserData: () => dispatch(actions.refreshUserData()),
        onGetUserEventProfile: (eventId, userId) => dispatch(actions.getUserEventProfile(eventId, userId)),
        onRemoveStripeSecret: () => dispatch(actions.removeStripeSecretFromUser()),
        resetEventData: () => dispatch(actions.resetEventData()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnregisterEvent));
