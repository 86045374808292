import * as actionTypes from './actionTypes';
import axios from '../axios-instance';
import {getExhibitorsSuccess} from './exhibitorsAction';

const apiCallShowfloorsStart = () => {
    return {
        type: actionTypes.API_CALL_SHOWFLOORS_START,
    };
};
const apiCallShowfloorsFail = (error) => {
    return {
        type: actionTypes.API_CALL_SHOWFLOORS_FAIL,
        payload: error,
    };
};

export const setEditedShowfloorId = (showfloorId) => {
    return {
        type: actionTypes.SET_EDITED_SHOWFLOOR_ID,
        payload: showfloorId,
    };
};

export const setActiveShowfloorId = (showfloorId) => {
    // used when the user refreshes the page to force the load of the same showfloor
    localStorage.setItem('showfloorId', showfloorId);
    return {
        type: actionTypes.SET_ACTIVE_SHOWFLOOR_ID,
        payload: showfloorId,
    };
};

export const createShowfloor = (eventId, showfloorData) => {
    return (dispatch) => {
        dispatch(apiCallShowfloorsStart());

        const apiURL = `/event/${eventId}/showfloors`;
        return axios
            .post(apiURL, showfloorData)
            .then((response) => {
                let showfloor = response.data.showfloor;
                dispatch(createShowfloorSuccess(showfloor));
            })
            .catch((error) => {
                const errorMessage = `Could not create showfloor`;
                dispatch(apiCallShowfloorsFail(errorMessage));
            });
    };
};

const createShowfloorSuccess = (showfloors) => {
    return {
        type: actionTypes.CREATE_SHOWFLOOR_SUCCESS,
        payload: showfloors,
    };
};

export const updateShowfloor = (eventId, showfloorId, showfloorData) => {
    return (dispatch) => {
        dispatch(apiCallShowfloorsStart());

        const apiURL = `/event/${eventId}/showfloors/${showfloorId}`;
        return axios
            .put(apiURL, showfloorData)
            .then((response) => {
                const showfloor = response.data.showfloor;
                dispatch(updateShowfloorSuccess(showfloor));
            })
            .catch((error) => {
                const errorMessage = `Could not update showfloor`;
                dispatch(apiCallShowfloorsFail(errorMessage));
            });
    };
};

const updateShowfloorSuccess = (showfloor) => {
    return {
        type: actionTypes.UPDATE_SHOWFLOOR_SUCCESS,
        payload: showfloor,
    };
};

export const deleteShowfloor = (eventId, showfloorId) => {
    return (dispatch) => {
        dispatch(apiCallShowfloorsStart());

        const apiURL = `/event/${eventId}/showfloors/${showfloorId}`;
        return axios
            .delete(apiURL)
            .then((response) => {
                dispatch(deleteShowfloorSuccess(showfloorId));
            })
            .catch((error) => {
                const errorMessage = `Could not update showfloor`;
                dispatch(apiCallShowfloorsFail(errorMessage));
            });
    };
};

const deleteShowfloorSuccess = (showfloorId) => {
    return {
        type: actionTypes.DELETE_SHOWFLOOR_SUCCESS,
        payload: showfloorId,
    };
};

export const getShowfloors = (eventId) => {
    return (dispatch) => {
        dispatch(apiCallShowfloorsStart());

        const apiURL = `/event/${eventId}/showfloors`;
        return axios
            .get(apiURL)
            .then((response) => {
                let showfloors = [];
                if (response.data) {
                    showfloors = response.data.showfloors;
                }
                dispatch(getShowfloorsSuccess(showfloors));
            })
            .catch((error) => {
                const errorMessage = `Could not get showfloors`;
                dispatch(apiCallShowfloorsFail(errorMessage));
            });
    };
};

const getShowfloorsSuccess = (showfloors) => {
    return {
        type: actionTypes.GET_SHOWFLOORS_SUCCESS,
        payload: showfloors,
    };
};

export const getShowfloor = (eventId, showfloorId) => {
    return (dispatch) => {
        dispatch(apiCallShowfloorsStart());

        const apiURL = `/event/${eventId}/showfloors/${showfloorId}`;
        return axios
            .get(apiURL)
            .then((response) => {
                let showfloor = {};
                if (response.data) {
                    showfloor = response.data.showfloor;
                }
                dispatch(getShowfloorSuccess(showfloor));
                dispatch(getExhibitorsSuccess(showfloor.exhibitors));
            })
            .catch((error) => {
                const errorMessage = `Could not get showfloors`;
                dispatch(apiCallShowfloorsFail(errorMessage));
            });
    };
};

const getShowfloorSuccess = (showfloor) => {
    return {
        type: actionTypes.GET_SHOWFLOOR_SUCCESS,
        payload: showfloor,
    };
};

//SHOWFLOORS ORDER
//reorder
export const reOrderShowfloors = (eventId,reorderedElement) => {
    return async (dispatch) => {
        try {
            dispatch({ type: actionTypes.REORDER_SHOWFLOORS_REQUEST });
            const { data } = await axios.put(`/event/${eventId}/reorder-showfloors`, {showfloors:reorderedElement});
            dispatch({
                type: actionTypes.REORDER_SHOWFLOORS_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: actionTypes.REORDER_SHOWFLOORS_FAIL,
                payload: error.response && error.response.data.message ? error.response.data.message : error.message,
            });
        }
    };
};

export const getOrderedShowfloors = (eventId) => {
    return async (dispatch) => {
        try {
            await axios.get()
            dispatch({ type: actionTypes.GET_ORDERED_SHOWFLOORS_REQUEST });
            const { data } = await axios.get(`/event/${eventId}/ordered-showfloors`);
            dispatch({
                type: actionTypes.GET_ORDERED_SHOWFLOORS_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: actionTypes.GET_ORDERED_SHOWFLOORS_FAIL,
                payload: error.response && error.response.data.message ? error.response.data.message : error.message,
            });
        }
    };
};