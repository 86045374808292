import * as actionTypes from '../actions/actionTypes';
import produce from "immer";

const initialState = {
    loading: false,
    posts: [],
    sortedPosts: [],
    pinnedPosts: [],
    unpinnedPosts: [],
    error: false,
    showingPosts: 20,
};


// CURRENTLY NOT USED
const reducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.GET_WALL_START:
                draft.loading = true;
                break;
            case actionTypes.GET_WALL_SUCCESS:
                draft.loading = false;
                draft.posts = action.payload.posts;
                draft.pinnedPosts = action.payload.posts.filter((post) => post.isPinned);
                draft.unpinnedPosts = action.payload.posts.filter((post) => !post.isPinned).slice(0, draft.showingPosts);
                break;
            case actionTypes.GET_WALL_FAIL:
                draft.loading = false
                break;
            case actionTypes.RESET_WALL_DATA:
                draft.loading = false;
                draft.posts = [];
                draft.sortedPosts = [];
                draft.pinnedPosts = [];
                draft.unpinnedPosts = [];
                draft.error = false;
                draft.showingPosts = 20;
                break;
            default:
                break;
        }
    });


export default reducer;
