import React from 'react';
import './EventWelcomeScreen.scss'
import * as actions from "../../store/actions";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from 'react-redux';
import {isMobile} from 'react-device-detect';
import ShowAgainRadioButton from "./ShowAgainRadioButton";
import CloseIcon from '@material-ui/icons/Close';
import WelcomeVideo from "./WelcomeVideo";
import WelcomePicture from "./WelcomePicture";
import {setUserHasSeenWelcomeScreen} from "../../store/actions/userActions";


class EventWelcomeScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpened: false,
            fullscreen: false,
        };
    }

    componentDidMount() {
        this.getWelcomeScreen();
    }

    componentDidUpdate(prevProps) {

        // whenever the user navigates to another event, get the welcome screen
        if(prevProps.eventId !== this.props.eventId){
            this.getWelcomeScreen();
        }

        // after we get the welcomeScreen data, check if we need to open it
        if(prevProps.eventWelcomeScreen?.data?._id !== this.props.eventWelcomeScreen?.data?._id){
            this.openWelcomeScreen();
        }


    }

    componentWillUnmount() {

    }

    getWelcomeScreen = () => {
        const {event, eventId} = this.props;
        const welcomeScreenIgnored = this.checkWelcomeScreenIgnored();
        const isOrganizer = this.checkUserIsOrganizer();

        // don't display welcome screen for organizers
        if(isOrganizer) return

        // if event doesn't have a welcome screen, don't do anything
        if(!event?.hasWelcomeScreen) return

        // if the user decided to ignore the welcome screen, don't do anything
        if(welcomeScreenIgnored) return

        this.props.getEventWelcomeScreen(eventId);
    }

    checkWelcomeScreenSeen = () => {
        const {user, eventId} = this.props;
        const eventUserData = user.eventRoles.find(role=>role.event._id === eventId);
        return eventUserData?.hasSeenWelcomeScreen;
    }

    checkWelcomeScreenIgnored = () => {
        const {user, eventId} = this.props;
        const eventUserData = user.eventRoles.find(role=>role.event._id === eventId);
        return eventUserData?.ignoreWelcomeScreen;
    }

    checkUserIsOrganizer = () => {
        const {user, eventId} = this.props;
        const eventUserData = user.eventRoles.find(role=>role.event._id === eventId);
        return eventUserData?.roles?.includes('organizer');
    }

    openWelcomeScreen = () => {
        const {eventWelcomeScreen} = this.props;
        const welcomeScreenSeen = this.checkWelcomeScreenSeen();
        const welcomeScreenIgnored = this.checkWelcomeScreenIgnored();
        const isOrganizer = this.checkUserIsOrganizer();

        // don't display welcome screen for organizers
        if(isOrganizer) return

        // if the user has seen the welcomeScreen and the displayFrequency is only once, don't do anything
        if(welcomeScreenSeen && eventWelcomeScreen.data.displayFrequency === 'first-time') return

        // in theory we will not get to this condition because we don't make the getWelcomeScreen api call if the user choosed to ignore it
        // this is just to make sure we never open the dialog if the user choosed to ignore it
        if (welcomeScreenIgnored) return

        this.setState({
            dialogOpened: true
        })
    };

    closeDialog = (event, reason) => {

        // when the user closes the dialog, also make the api call to setUserHasSeenWelcomeScreen
        if (reason !== 'backdropClick') {
            this.props.setUserHasSeenWelcomeScreen(this.props.eventId);
            this.setState({
                dialogOpened: false
            })
        }
    };

    render() {
        const {fullscreen, dialogOpened} = this.state;
        const {translation, defaultTranslation, eventWelcomeScreen} = this.props;
        const fullScreenType = eventWelcomeScreen?.data?.displaySize === 'full-screen-size';
        const isWelcomeVideo = eventWelcomeScreen.data?.type === 'video';
        const isWelcomeImage = eventWelcomeScreen.data?.type === 'picture';
        return (
            <Dialog
                open={dialogOpened}
                onClose={this.closeDialog}
                fullScreen={fullScreenType || isMobile}
                aria-labelledby="form-dialog-title"
                disableEscapeKeyDown={true}
                className={`welcome-screen-dialog ${fullscreen ? 'fullscreen' : ''} ${fullScreenType ? 'type-fullscreen' : ''} ${isMobile ? 'is-mobile' : ''} ${isWelcomeVideo ? 'video-type' : ''}`}
            >
                <DialogTitle id="form-dialog-title">
                    {eventWelcomeScreen?.data?.title}
                    <Button type="button" onClick={this.closeDialog}>
                        <CloseIcon />
                    </Button>
                </DialogTitle>
                {/*{isWelcomeVideo && <WelcomeVideoRefactored videoStreamingUrl={eventWelcomeScreen?.data?.videoUrl}/>}*/}
                {isWelcomeVideo && <WelcomeVideo />}
                {isWelcomeImage && <WelcomePicture />}

                <DialogActions>
                    {eventWelcomeScreen?.data?.displayFrequency === 'everytime' &&
                        <ShowAgainRadioButton/>
                    }
                    <div className="spacer"/>
                    <Button type="button" classes={{root: 'go-to-event-button'}} onClick={this.closeDialog}>
                        {translation?.welcomeScreen?.goToEvent || defaultTranslation?.welcomeScreen?.goToEvent}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        eventWelcomeScreen: state.eventWelcomeScreen,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        user: state.user.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventWelcomeScreen: (eventId) => dispatch(actions.getEventWelcomeScreen(eventId)),
        setUserHasSeenWelcomeScreen: (eventId) => dispatch(setUserHasSeenWelcomeScreen(eventId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventWelcomeScreen);

