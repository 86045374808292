import { useMemo } from "react";
import { ReactComponent as PlayIcon } from "../../../Images/svg/play-black.svg";
import {
	preventDefaultDrag,
	getVideoIdFromYoutubeUrl,
} from "../../../Utils/utils";

const VideoImagePreview = (props) => {
	const { videoUrl, handleOpenLobbyVideo } = props;

	const backgroundImageUrl = useMemo(() => {
		if (videoUrl) {
			const videoId = getVideoIdFromYoutubeUrl(videoUrl);
			return `url('https://img.youtube.com/vi/${videoId}/0.jpg')`;
		}
		return "none";
	}, [videoUrl]);

	return (
		<div
			className="image-preview-video"
			style={{ backgroundImage: backgroundImageUrl }}
			onClick={handleOpenLobbyVideo}
		>
			<div onDragStart={preventDefaultDrag} className="play-button">
				<PlayIcon />
			</div>
		</div>
	);
};

export default VideoImagePreview;
