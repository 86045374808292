import * as actionTypes from './actionTypes';
import * as notificationTypes from './notificationTypes';
import axios from '../axios-instance';
import {getFeaturedUsersSuccess} from "./eventUsersActions";



export const clearError = () => {
    return {
        type: actionTypes.CLEAR_ERROR,
    };
};

export const serverDown = () => {
    return {
        type: actionTypes.SERVER_DOWN,
    };
};

export const sessionExpired = () => {
    return {
        type: actionTypes.SESSION_EXPIRED,
    };
};

export const loginUser = (user) => {
    return (dispatch) => {
        dispatch(loginUserStart());
        axios({method: 'post', url: '/users/v2/login', data: user})
            .then((response) => {
                let data = response.data;
                localStorage.setItem('token', data.token);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
                dispatch(loginUserSuccess(data.user));
            })
            .catch((error) => {
                let errorMessage = 'Error. Please try again.';
                if (error.response) {
                    errorMessage = error.response.data.error;
                }
                dispatch(loginUserFail(errorMessage));
            });
    };
};

export const autoLoginWithToken = () => {
    return (dispatch) => {
        let userToken = localStorage.getItem('token');
        let url_string = window.location.href;
        let url = new URL(url_string);
        // check if we have a token url parameter (from social login/register)
        // if we have one, we always use it
        let tokenUrl = url.searchParams.get('token');

        // if we are on the password recovery page, we don't use the URL token
        if (tokenUrl && url.pathname && !url.pathname.includes('password-recovery')) {
            localStorage.setItem('token', tokenUrl);
            userToken = tokenUrl;
        }
        // const externalId = localStorage.getItem('externalId') || url.searchParams.get('externalId');

        const externalId = url.searchParams.get('externalId') || localStorage.getItem('externalId');
        // check if we have an externalId param on the register page
        // referral from the main AIFG website
        if (url_string.includes('register') && externalId) {
            let eventSlug = window.location.pathname.replace('/event/', '').split('/')[0];
            if (externalId && externalId.length > 0 && eventSlug && eventSlug.length > 0) {
                if (userToken) {
                    dispatch(loginUserStart());
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + userToken;
                    return axios({
                        method: 'get',
                        url: '/users/v2/me',
                        params: {externalId: externalId, eventSlug: eventSlug},
                    })
                        .then((response) => {
                            let data = response.data.data;
                            const defaultPlatformLanguage = data.user.language;
                            dispatch(setPlatformLanguage(defaultPlatformLanguage));
                            dispatch(loginUserSuccess(data.user));
                        })
                        .catch((error) => {
                            let errorMessage = '';
                            dispatch(loginUserFail(errorMessage));
                        });
                }
            }
        } else if (userToken) {
            dispatch(loginUserStart());
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + userToken;
            return axios({method: 'get', url: '/users/v2/me'})
                .then((response) => {
                    let data = response.data.data;
                    const defaultPlatformLanguage = data.user.language;
                    dispatch(setPlatformLanguage(defaultPlatformLanguage));
                    dispatch(loginUserSuccess(data.user));
                })
                .catch((error) => {
                    let errorMessage = '';
                    dispatch(loginUserFail(errorMessage));
                });
        }
    };
};

export const loginUserStart = () => {
    return {
        type: actionTypes.LOGIN_USER_START,
    };
};
export const loginUserSuccess = (user) => {
    return {
        type: actionTypes.LOGIN_USER_SUCCESS,
        payload: user,
    };
};
export const loginUserFail = (err) => {
    return {
        type: actionTypes.LOGIN_USER_FAIL,
        payload: err,
    };
};

export const refreshUserData = () => {
    return (dispatch) => {
        dispatch(loginUserStart());
        return axios({method: 'get', url: '/users/v2/me'})
            .then((response) => {
                let data = response.data.data;
                const defaultPlatformLanguage = data.user.language;

                dispatch(setPlatformLanguage(defaultPlatformLanguage));
                dispatch(loginUserSuccess(data.user));
            })
            .catch((error) => {
                let errorMessage = '';
                if (error && error.response && error.response.data) {
                    errorMessage = error.response.data.error;
                }
                dispatch(loginUserFail(errorMessage));
            });
    };
};

export const updateUserInformationStart = () => {
    return {
        type: actionTypes.UPDATE_USER_START,
    };
};
export const updateUserInformationSuccess = () => {
    return {
        type: actionTypes.UPDATE_USER_SUCCESS,
    };
};
export const updateUserInformationFail = () => {
    return {
        type: actionTypes.UPDATE_USER_FAIL,
    };
};

export const updateUserInformation = (data) => {
    return (dispatch) => {
        dispatch(updateUserInformationStart());
        return axios({
            method: 'put',
            url: '/users',
            data,
        })
            .then(() => dispatch(updateUserInformationSuccess()))
            .catch(() => dispatch(updateUserInformationFail()));
    };
};

export const registerUser = (user) => {
    return (dispatch) => {
        dispatch(registerUserStart());
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios({method: 'post', url: '/users', data: user, config})
            .then((response) => {
                let data = response.data.data;
                dispatch(setUserLoadingFalse());

                localStorage.setItem('token', data.token);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;

                const platformLanguage = data.user?.language || 'en';
                dispatch(setPlatformLanguage(platformLanguage));

                const hasEmailValidation = JSON.parse(process.env.REACT_APP_ENABLE_EMAIL_VALIDATION);
                if (hasEmailValidation) {
                    // for 1.5 sec we need to display the user created successfully screen on the registration page
                    // this is why we delay the registerUserSuccess function call in the setTimeout
                    setTimeout(() => {
                        dispatch(registerUserSuccess(data.user));
                    }, 1500);
                    return data;
                } else {
                    dispatch(registerUserSuccess(data.user));
                    return data;
                }
            })
            .catch((error) => {
                let errorMessage = '';
                if (error.response && error.response.data) {
                    errorMessage = error.response.data.error;
                }
                dispatch(registerUserFail(errorMessage));
                throw errorMessage;
            });
    };
};

export const registerUserStart = () => {
    return {
        type: actionTypes.REGISTER_USER_START,
    };
};
export const registerUserSuccess = (user) => {
    return {
        type: actionTypes.REGISTER_USER_SUCCESS,
        payload: user,
    };
};
export const registerUserFail = (err) => {
    return {
        type: actionTypes.REGISTER_USER_FAIL,
        payload: err,
    };
};

export const createRegistrationCode = (formData) => {
    return (dispatch) => {
        dispatch(createRegistrationCodeStart());

        return axios({method: 'post', url: '/users/request-otp', data: formData})
            .then((response) => {
                let data = response.data.data
                dispatch(createRegistrationCodeSuccess(data));
            })
            .catch((error) => {
                let errorMessage = '';
                if (error.response && error.response.data) {
                    errorMessage = error.response.data.error;
                }
                dispatch(createRegistrationCodeFail(errorMessage));
                throw errorMessage;

            })
    };
}

export const createRegistrationCodeStart = () => {
    return {
        type: actionTypes.CREATE_REGISTRATION_CODE_START,
    }
}

export const createRegistrationCodeSuccess = (formData) => {
    return {
        type: actionTypes.CREATE_REGISTRATION_CODE_SUCCESS,
        payload: formData,
    };
};

export const createRegistrationCodeFail = (err) => {
    return {
        type: actionTypes.CREATE_REGISTRATION_CODE_FAIL,
        payload: err,
    };
};

export const setUserLoadingFalse = () => {
    return {
        type: actionTypes.SET_USER_LOADING_FALSE,
    };
};

export const logoutUser = () => {
    return (dispatch) => {
        dispatch(logoutUserStart());
        delete axios.defaults.headers.common['Authorization'];
        localStorage.removeItem('token');
        dispatch(resetEventData());

        // check to not have the token saved in the localStorage
        if (!localStorage.getItem('token')) {
            dispatch(logoutUserSuccess());
            dispatch(resetArchiveAccess());
        } else {
            dispatch(logoutUserFail());
        }
    };
};

export const logoutUserStart = () => {
    return {
        type: actionTypes.LOGOUT_USER_START,
    };
};
export const logoutUserSuccess = () => {
    return {
        type: actionTypes.LOGOUT_USER_SUCCESS,
    };
};
export const logoutUserFail = () => {
    return {
        type: actionTypes.LOGOUT_USER_FAIL,
    };
};
export const resetArchiveAccess = () => {
    return {
        type: actionTypes.GET_EVENT_ARCHIVE_RESET,
    };
};
export const resetEventData = () => {
    return {
        type: actionTypes.GET_EVENT_RESET,
    };
};

export const sendContactRequest = (targetUserId, eventId) => {
    return (dispatch) => {
        dispatch(sendContactRequestStart());
        axios({method: 'post', url: '/users/send-contact-request', data: {targetUserId: targetUserId, eventId: eventId}})
            .then((response) => {
                dispatch(sendContactRequestSuccess());
                dispatch(getSentRequests());
            })
            .catch((error) => {
                dispatch(sendContactRequestFail(error));
            });
    };
};

export const sendContactRequestStart = () => {
    return {
        type: actionTypes.SEND_CONTACT_REQUEST_START,
    };
};
export const sendContactRequestSuccess = () => {
    return {
        type: actionTypes.SEND_CONTACT_REQUEST_SUCCESS,
    };
};
export const sendContactRequestFail = () => {
    return {
        type: actionTypes.SEND_CONTACT_REQUEST_FAIL,
    };
};

export const acceptContactRequest = (requestId) => {
    return (dispatch) => {
        dispatch(acceptContactRequestStart());
        axios({method: 'post', url: '/users/accept-contact-request', data: {requestId: requestId}})
            .then((response) => {
                dispatch(acceptContactRequestSuccess());
                dispatch(getReceivedRequests());
                dispatch(getContacts());
                dispatch(getPrivateChats());
            })
            .catch((error) => {
                dispatch(acceptContactRequestFail(error));
            });
    };
};

export const acceptContactRequestStart = () => {
    return {
        type: actionTypes.ACCEPT_CONTACT_REQUEST_START,
    };
};
export const acceptContactRequestSuccess = () => {
    return {
        type: actionTypes.ACCEPT_CONTACT_REQUEST_SUCCESS,
    };
};
export const acceptContactRequestFail = () => {
    return {
        type: actionTypes.ACCEPT_CONTACT_REQUEST_FAIL,
    };
};

export const declineContactRequest = (requestId) => {
    return (dispatch) => {
        dispatch(declineContactRequestStart());
        axios({method: 'post', url: '/users/reject-contact-request', data: {requestId: requestId}})
            .then((response) => {
                dispatch(declineContactRequestSuccess());
                dispatch(getReceivedRequests());
                dispatch(getContacts());
            })
            .catch((error) => {
                dispatch(declineContactRequestFail(error));
            });
    };
};

export const declineContactRequestStart = () => {
    return {
        type: actionTypes.DECLINE_CONTACT_REQUEST_START,
    };
};
export const declineContactRequestSuccess = () => {
    return {
        type: actionTypes.DECLINE_CONTACT_REQUEST_SUCCESS,
    };
};
export const declineContactRequestFail = () => {
    return {
        type: actionTypes.DECLINE_CONTACT_REQUEST_FAIL,
    };
};

export const newNotification = (notification) => {
    switch (notification.type) {
        case notificationTypes.CONTACT_REQUEST_RECEIVED:
            return (dispatch) => {
                dispatch(getReceivedRequests());
                dispatch(getNotifications());
            };
        case notificationTypes.CONTACT_REQUEST_ACCEPTED:
            return (dispatch) => {
                dispatch(getContacts());
                dispatch(getSentRequests());
                dispatch(getNotifications());
            };
        case notificationTypes.MEETING_REQUEST_RECEIVED:
            return (dispatch) => {
                dispatch(getMeetings());
                dispatch(getNotifications());
            };
        case notificationTypes.MEETING_REQUEST_ACCEPTED:
            return (dispatch) => {
                dispatch(getMeetings());
                dispatch(getNotifications());
            };
        case notificationTypes.MEETING_STARTING:
            return (dispatch) => {
                dispatch(getNotifications());
            };
        default:
            return (dispatch) => {
                axios({method: 'get', url: '/users/v2/me'})
                    .then((response) => {
                        let data = response.data.data;
                        dispatch(loginUserSuccess(data.user));
                    })
                    .catch((error) => {
                        dispatch(loginUserFail(error));
                    });
            };
    }
};

export const getSentRequests = () => {
    return (dispatch) => {
        dispatch(getSentRequestsStart());
        axios({method: 'get', url: '/users/me/sent-requests'})
            .then((response) => {
                let data = response.data;
                dispatch(getSentRequestsSuccess(data.sentRequests));
            })
            .catch((error) => {
                dispatch(getSentRequestsFail(error));
            });
    };
};

export const getSentRequestsStart = () => {
    return {
        type: actionTypes.GET_SENT_REQUESTS_START,
    };
};
export const getSentRequestsSuccess = (sentRequests) => {
    return {
        type: actionTypes.GET_SENT_REQUESTS_SUCCESS,
        payload: sentRequests,
    };
};
export const getSentRequestsFail = () => {
    return {
        type: actionTypes.GET_SENT_REQUESTS_FAIL,
    };
};

export const getReceivedRequests = () => {
    return (dispatch) => {
        dispatch(getReceivedRequestsStart());
        axios({method: 'get', url: '/users/me/received-requests'})
            .then((response) => {
                let data = response.data;
                dispatch(getReceivedRequestsSuccess(data.receivedRequests));
            })
            .catch((error) => {
                dispatch(getReceivedRequestsFail(error));
            });
    };
};

export const getReceivedRequestsStart = () => {
    return {
        type: actionTypes.GET_RECEIVED_REQUESTS_START,
    };
};
export const getReceivedRequestsSuccess = (receivedRequests) => {
    return {
        type: actionTypes.GET_RECEIVED_REQUESTS_SUCCESS,
        payload: receivedRequests,
    };
};
export const getReceivedRequestsFail = () => {
    return {
        type: actionTypes.GET_RECEIVED_REQUESTS_FAIL,
    };
};

export const setNotReadChatMessages = (numberOfNotReadMessages) => {
    return {
        type: actionTypes.SET_NOT_READ_CHAT_MESSAGES,
        payload: numberOfNotReadMessages,
    };
};

export const getContacts = () => {
    return (dispatch) => {
        dispatch(getContactsStart());
        axios({method: 'get', url: '/users/me/contacts'})
            .then((response) => {
                let data = response.data;
                dispatch(getContactsSuccess(data.contacts));
            })
            .catch((error) => {
                dispatch(getContactsFail(error));
            });
    };
};

export const getContactsStart = () => {
    return {
        type: actionTypes.GET_CONTACTS_START,
    };
};
export const getContactsSuccess = (contacts) => {
    return {
        type: actionTypes.GET_CONTACTS_SUCCESS,
        payload: contacts,
    };
};
export const getContactsFail = () => {
    return {
        type: actionTypes.GET_CONTACTS_FAIL,
    };
};

export const getMeetings = () => {
    return (dispatch) => {
        dispatch(getMeetingsStart());
        axios({method: 'get', url: '/users/me/meetings'})
            .then((response) => {
                let data = response.data;
                dispatch(getMeetingsSuccess(data.meetings));
            })
            .catch((error) => {
                dispatch(getMeetingsFail(error));
            });
    };
};

export const getMeetingsStart = () => {
    return {
        type: actionTypes.GET_MEETINGS_START,
    };
};
export const getMeetingsSuccess = (meetings) => {
    return {
        type: actionTypes.GET_MEETINGS_SUCCESS,
        payload: meetings,
    };
};
export const getMeetingsFail = () => {
    return {
        type: actionTypes.GET_MEETINGS_FAIL,
    };
};

export const getNotifications = () => {
    return (dispatch) => {
        dispatch(getNotificationsStart());
        axios({method: 'get', url: '/users/me/notifications'})
            .then((response) => {
                let data = response.data;
                dispatch(getNotificationsSuccess(data.notifications));
            })
            .catch((error) => {
                dispatch(getNotificationsFail(error));
            });
    };
};

export const getNotificationsStart = () => {
    return {
        type: actionTypes.GET_NOTIFICATIONS_START,
    };
};
export const getNotificationsSuccess = (notifications) => {
    return {
        type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
        payload: notifications,
    };
};
export const getNotificationsFail = () => {
    return {
        type: actionTypes.GET_NOTIFICATIONS_FAIL,
    };
};

export const getGroupChats = (openChatIdAfterFetchingTheChats) => {
    return (dispatch) => {
        dispatch(getGroupChatsStart());
        axios({method: 'get', url: '/group-chats/me'})
            .then((response) => {
                let data = response.data;
                dispatch(getGroupChatsSuccess(data.chats));
                if (openChatIdAfterFetchingTheChats) {
                    dispatch(openGroupChat(openChatIdAfterFetchingTheChats));
                }
            })
            .catch((error) => {
                dispatch(getGroupChatsFail(error));
            });
    };
};

export const getGroupChatsStart = () => {
    return {
        type: actionTypes.GET_GROUP_CHATS_START,
    };
};
export const getGroupChatsSuccess = (groupChats) => {
    return {
        type: actionTypes.GET_GROUP_CHATS_SUCCESS,
        payload: groupChats,
    };
};
export const getGroupChatsFail = () => {
    return {
        type: actionTypes.GET_GROUP_CHATS__FAIL,
    };
};

export const getPrivateChats = (openChatIdAfterFetchingTheChats) => {
    return (dispatch) => {
        dispatch(getPrivateChatsStart());
        axios({method: 'get', url: '/private-chats/me'})
            .then((response) => {
                let data = response.data;
                dispatch(getPrivateChatsSuccess(data.chats));
                if (openChatIdAfterFetchingTheChats) {
                    dispatch(openPrivateChat(openChatIdAfterFetchingTheChats));
                }
            })
            .catch((error) => {
                dispatch(getPrivateChatsFail(error));
            });
    };
};

export const getPrivateChatsStart = () => {
    return {
        type: actionTypes.GET_PRIVATE_CHATS_START,
    };
};
export const getPrivateChatsSuccess = (privateChats) => {
    return {
        type: actionTypes.GET_PRIVATE_CHATS_SUCCESS,
        payload: privateChats,
    };
};
export const getPrivateChatsFail = () => {
    return {
        type: actionTypes.GET_GROUP_CHATS__FAIL,
    };
};

export const openPrivateChat = (privateChatId) => {
    return {
        type: actionTypes.OPEN_PRIVATE_CHAT,
        payload: privateChatId,
    };
};

export const openGroupChat = (groupChatId) => {
    return {
        type: actionTypes.OPEN_GROUP_CHAT,
        payload: groupChatId,
    };
};

export const resetActiveChatId = () => {
    return {
        type: actionTypes.RESET_ACTIVE_CHAT_ID,
    };
};

export const markNotificationAsRead = (notificationId) => {
    return (dispatch) => {
        dispatch(markNotificationAsReadStart());
        dispatch(markNotificationAsReadSuccess(notificationId));
    };
};

export const markNotificationAsReadStart = () => {
    return {
        type: actionTypes.MARK_NOTIFICATION_AS_READ_START,
    };
};
export const markNotificationAsReadSuccess = (notificationId) => {
    return {
        type: actionTypes.MARK_NOTIFICATION_AS_READ_SUCCESS,
        payload: notificationId,
    };
};
export const markNotificationAsReadFail = () => {
    return {
        type: actionTypes.MARK_NOTIFICATION_AS_READ_FAIL,
    };
};

export const createGroupChat = (chatData) => {
    return (dispatch) => {
        dispatch(createGroupChatStart());

        axios({method: 'post', url: '/group-chat', data: chatData})
            .then((response) => {
                let data = response.data;
                dispatch(getGroupChats(data.groupChat._id));
            })
            .catch((error) => {
                dispatch(createGroupChatFail(error));
            });
    };
};

export const createGroupChatStart = () => {
    return {
        type: actionTypes.CREATE_GROUP_CHAT_START,
    };
};
export const createGroupChatSuccess = (newGroupChatId) => {
    return {
        type: actionTypes.CREATE_GROUP_CHAT_SUCCESS,
        payload: newGroupChatId,
    };
};
export const createGroupChatFail = () => {
    return {
        type: actionTypes.CREATE_GROUP_CHAT_FAIL,
    };
};

export const createPrivateChat = (targetUserId) => {
    return (dispatch) => {
        dispatch(createPrivateChatStart());

        axios({method: 'post', url: '/private-chat', data: {targetUserId: targetUserId}})
            .then((response) => {
                let data = response.data;
                dispatch(getPrivateChats(data.privateChat._id));
            })
            .catch((error) => {
                dispatch(createPrivateChatFail(error));
            });
    };
};

export const createPrivateChatStart = () => {
    return {
        type: actionTypes.CREATE_PRIVATE_CHAT_START,
    };
};
export const createPrivateChatSuccess = (newPrivateChat) => {
    return {
        type: actionTypes.CREATE_PRIVATE_CHAT_SUCCESS,
        payload: newPrivateChat,
    };
};
export const createPrivateChatFail = () => {
    return {
        type: actionTypes.CREATE_PRIVATE_CHAT_FAIL,
    };
};

export const sendMessageInPrivateChat = (chatId, message) => {
    return (dispatch) => {
        dispatch(sendMessageInPrivateChatStart());

        axios({method: 'post', url: '/private-chat/' + chatId + '/messages', data: {text: message}})
            .then((response) => {
                dispatch(sendMessageInPrivateChatSuccess());
            })
            .catch((error) => {
                dispatch(sendMessageInPrivateChatFail(error));
            });
    };
};

export const sendMessageInPrivateChatStart = () => {
    return {
        type: actionTypes.SEND_MESSAGE_IN_PRIVATE_CHAT_START,
    };
};
export const sendMessageInPrivateChatSuccess = () => {
    return {
        type: actionTypes.SEND_MESSAGE_IN_PRIVATE_CHAT_SUCCESS,
    };
};
export const sendMessageInPrivateChatFail = () => {
    return {
        type: actionTypes.SEND_MESSAGE_IN_PRIVATE_CHAT_FAIL,
    };
};

export const topNavSeeContacts = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_CONTACTS,
    };
};

export const topNavSeeContactsMobile = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_CONTACTS_MOBILE,
    };
};

export const topNavSeeProgram = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_PROGRAM,
    };
};

export const topNavSeeProgramFromNotifications = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_PROGRAM_FROM_NOTIFICATIONS,
    };
};

export const topNavSeeProgramFromNotificationsMobile = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_PROGRAM_FROM_NOTIFICATIONS_MOBILE,
    };
};

export const topNavSeeProgramMobile = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_PROGRAM_MOBILE,
    };
};

export const topNavSeeChats = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_CHATS,
    };
};

export const seeHelpChat = () => {
    return {
        type: actionTypes.SEE_HELP_CHAT_OPEN,
    };
};

export const closeHelpChat = () => {
    return {
        type: actionTypes.SEE_HELP_CHAT_CLOSE,
    };
};

export const topNavSeeChatsMobile = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_CHATS_MOBILE,
    };
};

export const topNavSeeNotifications = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_NOTIFICATIONS,
    };
};

export const topNavSeeNotificationsMobile = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_NOTIFICATIONS_MOBILE,
    };
};

export const topNavSeeMyEvents = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_MY_EVENTS,
    };
};

export const topNavSeeMyEventsMobile = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_MY_EVENTS_MOBILE,
    };
};

export const topNavSeeMyBooths = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_MY_BOOTHS,
    };
};

export const topNavSeeMyAccount = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_MY_ACCOUNT,
    };
};

export const topNavSeeMyAccountMobile = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_MY_ACCOUNT_MOBILE,
    };
};

export const topNavClose = () => {
    return {
        type: actionTypes.TOP_NAV_CLOSE,
    };
};

export const topNavCloseMobile = () => {
    return {
        type: actionTypes.TOP_NAV_CLOSE_MOBILE,
    };
};

export const bottomSeeRooms = () => {
    return {
        type: actionTypes.BOTTOM_SEE_ROOMS,
    };
};

export const sideNavSeeNetworking = () => {
    return {
        type: actionTypes.SIDE_NAV_SEE_NETWORKING,
    };
};

export const sideNavSeeLiveWall = () => {
    return {
        type: actionTypes.SIDE_NAV_SEE_LIVE_WALL,
    };
};

export const sideNavSeeVideoWall = () => {
    return {
        type: actionTypes.SIDE_NAV_SEE_VIDEO_WALL,
    };
};

export const sideNavSeeInfoTab = () => {
    return {
        type: actionTypes.SIDE_INFO_TAB,
    };
};

export const sideNavClose = () => {
    return {
        type: actionTypes.SIDE_NAV_CLOSE,
    };
};

export const topNavOnventProfile = () => {
    return {
        type: actionTypes.TOP_NAV_ONVENT_PROFILE,
    };
};

export const topNavEventProfile = () => {
    return {
        type: actionTypes.TOP_NAV_EVENT_PROFILE,
    };
};

export const expandedChatsOnVideoConference = () => {
    return {
        type: actionTypes.EXPANDED_CHATS_COMPONENT,
    };
};

export const setUserEventRoles = (rolesData) => {
    return {
        type: actionTypes.SET_USER_EVENT_ROLES,
        payload: rolesData,
    };
};

export const newPrivateChatMessage = (data) => {
    return {
        type: actionTypes.NEW_PRIVATE_CHAT_MESSAGE,
        payload: data,
    };
};

export const newGroupChatMessage = (data) => {
    return {
        type: actionTypes.NEW_GROUP_CHAT_MESSAGE,
        payload: data,
    };
};

export const readMessagesForPrivateChat = (chatId) => {
    return {
        type: actionTypes.READ_MESSAGES_FOR_PRIVATE_CHAT,
        payload: chatId,
    };
};

export const readMessagesForGroupChat = (data) => {
    return {
        type: actionTypes.READ_MESSAGES_FOR_GROUP_CHAT,
        payload: data,
    };
};

export const removeUserFromGroupChat = ({userId, chatId}) => {
    return (dispatch) => {
        dispatch(removeUserFromGroupChatStart());

        return axios({
            method: 'delete',
            url: '/group-chat/' + chatId + '/remove-user',
            data: {targetUserId: userId},
        })
            .then((response) => {
                dispatch(removeUserFromGroupChatSuccess({userId, chatId}));
            })
            .catch((error) => {
                dispatch(removeUserFromGroupChatFail());
            });
    };
};

export const removeUserFromGroupChatStart = () => {
    return {
        type: actionTypes.REMOVE_USER_FROM_GROUP_CHAT_START,
    };
};
export const removeUserFromGroupChatSuccess = (data) => {
    return {
        type: actionTypes.REMOVE_USER_FROM_GROUP_CHAT_SUCCESS,
        payload: data,
    };
};
export const removeUserFromGroupChatFail = () => {
    return {
        type: actionTypes.REMOVE_USER_FROM_GROUP_CHAT_FAIL,
    };
};

export const userEventRolesUpdated = (eventRoles) => {
    return {
        type: actionTypes.EVENT_ROLES_UPDATED_CORG,
        payload: eventRoles,
    };
};

export const setUserConnectedToNotifications = () => {
    return {
        type: actionTypes.SET_USER_CONNECTED_TO_NOTIFICATIONS,
    };
};

export const createPaymentIntent = (data) => {
    return (dispatch) => {
        dispatch(createPaymentIntentStart());

        return axios({method: 'post', url: `/stripe/createPaymentIntent`, data: data})
            .then((response) => {
                let clientSecret = response.data.clientSecret;
                dispatch(createPaymentIntentSuccess(clientSecret));
            })
            .catch((error) => {
                dispatch(createPaymentIntentFail(error));
                throw error;
            });
    };
};

export const createPaymentIntentStart = () => {
    return {
        type: actionTypes.CREATE_PAYMENT_INTENT_START,
    };
};
export const createPaymentIntentSuccess = (clientSecret) => {
    return {
        type: actionTypes.CREATE_PAYMENT_INTENT_SUCCESS,
        payload: clientSecret,
    };
};
export const createPaymentIntentFail = () => {
    return {
        type: actionTypes.CREATE_PAYMENT_INTENT_FAIL,
    };
};

export const removeStripeSecretFromUser = () => {
    return {
        type: actionTypes.REMOVE_STRIPE_SECRET,
    };
};

export const createExhibitorPaymentIntent = (data) => {
    return (dispatch) => {
        dispatch(createExhibitorPaymentIntentStart());

        return axios({method: 'post', url: `/stripe/createExhibitorPaymentIntent`, data: data})
            .then((response) => {
                let clientSecret = response.data.clientSecret;
                dispatch(createExhibitorPaymentIntentSuccess(clientSecret));
            })
            .catch((error) => {
                dispatch(createExhibitorPaymentIntentFail(error));
            });
    };
};

export const createExhibitorPaymentIntentStart = () => {
    return {
        type: actionTypes.CREATE_EXHIBITOR_PAYMENT_INTENT_START,
    };
};
export const createExhibitorPaymentIntentSuccess = (clientSecret) => {
    return {
        type: actionTypes.CREATE_EXHIBITOR_PAYMENT_INTENT_SUCCESS,
        payload: clientSecret,
    };
};
export const createExhibitorPaymentIntentFail = () => {
    return {
        type: actionTypes.CREATE_EXHIBITOR_PAYMENT_INTENT_FAIL,
    };
};

export const cancelPaymentIntent = () => {
    return {
        type: actionTypes.CANCEL_PAYMENT_INTENT,
    };
};

export const setEventAccess = (resourcesAccess) => {
    return {
        type: actionTypes.SET_EVENT_ACCESS,
        payload: resourcesAccess,
    };
};

export const setPreselectedPackageId = (packageId) => {
    return {
        type: actionTypes.SET_PRESELECTED_PACKAGE_ID,
        payload: packageId,
    };
};

const setLanguageDirection = (language) => {
    // always make sure that we remove the attribute (ex when the user logs out from the event)
    // document.documentElement = <html> DOM element
    document.documentElement.removeAttribute('dir');

    // this function is also called from getEventInfo in eventActions
    // after we get the eventInfo we immediatelly set the default language that the organizer chose
    if (language === 'ar') {
        document.documentElement.setAttribute('dir', 'rtl');
        document.documentElement.classList.add('lang-rtl');
    } else {
        document.documentElement.removeAttribute('dir');
        document.documentElement.classList.remove('lang-rtl');
    }
};

export const setEventLanguage = (language) => {
    setLanguageDirection(language);
    return {
        type: actionTypes.SET_EVENT_LANGUAGE,
        payload: language,
    };
};

export const setPlatformLanguage = (language) => {
    setLanguageDirection(language);
    return {
        type: actionTypes.SET_PLATFORM_LANGUAGE,
        payload: language,
    };
};

export const seeLogInForm = () => {
    return {
        type: actionTypes.TAB_LOG_IN_FORM,
    };
};

export const seeSignUpForm = () => {
    return {
        type: actionTypes.TAB_SIGN_UP_FORM,
    };
};

// USER MATCHING
export const saveUpdateUserAnswersStart = () => {
    return {
        type: actionTypes.SAVE_UPDATE_ANSWER_START,
    };
};

export const saveUpdateUserAnswersSuccess = (data) => {
    return {
        type: actionTypes.SAVE_UPDATE_ANSWER_SUCCESS,
        payload: data,
    };
};

export const saveUpdateUserAnswersFail = (err) => {
    return {
        type: actionTypes.SAVE_UPDATE_ANSWER_FAIL,
        payload: err,
    };
};

export const clearMatchesArraySuccess = () => {
    return {
        type: actionTypes.CLEAR_MATCHES_ARRAY,
    };
};

export const clearMatchesArray = () => (dispatch) => dispatch(clearMatchesArraySuccess());

export const saveUpdateUserAnswers = (eventId, questionnaireId, data) => {
    return (dispatch) => {
        dispatch(saveUpdateUserAnswersStart());
        return axios({
            method: 'post',
            url: `/event/v2/${eventId}/questionnaires/${questionnaireId}/save-user-answers`,
            data,
        })
            .then((response) => {
                dispatch(saveUpdateUserAnswersSuccess(response.data.matchingUsers))
                dispatch(getFeaturedUsersSuccess(response.data.featuredUsersFormatted))
            })
            .catch((error) => {
                let errorMessage = 'Error. Please try again.';
                if (error.response) {
                    errorMessage = error.response.data.error;
                }
                dispatch(saveUpdateUserAnswersFail({status: error.response.status, message: errorMessage}));
            });
    };
};

export const getUserMatchingScore = (eventId, questionnaireId) => {
    return (dispatch) => {
        dispatch(saveUpdateUserAnswersStart());
        return axios({
            method: 'get',
            url: `/event/v2/${eventId}/questionnaires/${questionnaireId}/fetch-networking-matching-score`,
        })
            .then((response) => {
                dispatch(saveUpdateUserAnswersSuccess(response.data.matchingUsers))
                dispatch(getFeaturedUsersSuccess(response.data.featuredUsersFormatted))
            })
            .catch((error) => {
                let errorMessage = 'Error. Please try again.';
                if (error.response) {
                    errorMessage = error.response.data.error;
                }
                dispatch(saveUpdateUserAnswersFail({status: error.response.status, message: errorMessage}));
            });
    };
};

export const recalculateUserMatchingScore = (eventId, questionnaireId) => {
    return (dispatch) => {
        dispatch(saveUpdateUserAnswersStart());
        return axios({
            method: 'post',
            url: `/event/v2/${eventId}/questionnaires/${questionnaireId}/fetch-matching-score`,
        })
            .then((response) => {
                dispatch(saveUpdateUserAnswersSuccess(response.data.matchingUsers))
                dispatch(getFeaturedUsersSuccess(response.data.featuredUsersFormatted))
            })
            .catch((error) => {
                let errorMessage = 'Error. Please try again.';
                if (error.response) {
                    errorMessage = error.response.data.error;
                }
                dispatch(saveUpdateUserAnswersFail({status: error.response.status, message: errorMessage}));
            });
    };
};

export const getUserMatchingAnswersStart = () => {
    return {
        type: actionTypes.GET_MATCHING_ANSWERS_START,
    };
};

export const getUserMatchingAnswersSuccess = (data) => {
    return {
        type: actionTypes.GET_MATCHING_ANSWERS_SUCCESS,
        payload: data,
    };
};

export const getUserMatchingAnswersFail = (err) => {
    return {
        type: actionTypes.GET_MATCHING_ANSWERS_FAIL,
        payload: err,
    };
};

export const getUserMatchingAnswers = (eventId, questionnaireId, data) => {
    return (dispatch) => {
        dispatch(getUserMatchingAnswersStart());
        return axios({
            method: 'post',
            url: `/event/v2/${eventId}/questionnaires/${questionnaireId}/fetch-user-answers`,
            data,
        })
            .then((response) => dispatch(getUserMatchingAnswersSuccess(response.data.answers)))
            .catch((error) => {
                let errorMessage = 'Error. Please try again.';
                if (error.response) {
                    errorMessage = error.response.data.error;
                }
                dispatch(getUserMatchingAnswersFail({status: error.response.status, message: errorMessage}));
            });
    };
};

export const removeUserMatchingDataStart = () => {
    return {
        type: actionTypes.REMOVE_USER_MATCHING_DATA_START,
    };
};

export const removeUserMatchingDataSuccess = (data) => {
    return {
        type: actionTypes.REMOVE_USER_MATCHING_DATA_SUCCESS,
        payload: data,
    };
};

export const removeUserMatchingDataFail = (err) => {
    return {
        type: actionTypes.REMOVE_USER_MATCHING_DATA_FAIL,
        payload: err,
    };
};

export const removeUserMatchingData = (eventId) => {
    return (dispatch) => {
        dispatch(removeUserMatchingDataStart());
        return axios({
            method: 'post',
            url: `/event/${eventId}/remove-matching-data`,
        })
            .then((response) => dispatch(removeUserMatchingDataSuccess(response.data.message)))
            .catch((error) => {
                let errorMessage = 'Error. Please try again.';
                if (error.response) {
                    errorMessage = error.response.data.error;
                }
                dispatch(removeUserMatchingDataFail({status: error.response.status, message: errorMessage}));
            });
    };
};
// END USER MATCHING

export const setUserHasSeenWelcomeScreen = (eventId) => {
    return (dispatch) => {
        dispatch(removeUserMatchingDataStart());
        return axios({
            method: 'post',
            url: `/users/me/has-seen-welcome-screen`,
            data: {
                eventId: eventId,
                hasSeenWelcomeScreen: true,
            },
        })
            .then((response) => {
                const userData = response.data.user;
                dispatch(loginUserSuccess(userData));
            })
            .catch((error) => {
                let errorMessage = 'Error. Please try again.';
                if (error.response) {
                    errorMessage = error.response.data.error;
                }
            });
    };
};

export const toggleIgnoreEventWelcomeScreen = (eventId, ignoreWelcomeScreen) => {
    return (dispatch) => {
        return axios({
            method: 'post',
            url: `/users/me/ignore-welcome-screen`,
            data: {
                eventId: eventId,
                ignoreWelcomeScreen: ignoreWelcomeScreen,
            },
        })
            .then((response) => {
                const userData = response.data.user;
                dispatch(loginUserSuccess(userData));
            })
            .catch((error) => {
                let errorMessage = 'Error. Please try again.';
                if (error.response) {
                    errorMessage = error.response.data.error;
                }
            });
    };
};

export const userNavigatedToOtherEvent = () => {
    return {
        type: actionTypes.USER_NAVIGATED_TO_OTHER_EVENT,
    };
};


