import React from 'react';
import {connect} from 'react-redux';
import {getTimeAgoString, preventDefaultDrag} from '../../../../Utils/utils';
import AddIcon from '@material-ui/icons/Add';

class ChatTitle extends React.PureComponent {
    state = {
        chatName: '',
        avatarSrc: '',
        timeAgoString: '',
    };

    componentDidMount() {
        this.setChatName();
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeChatRoomId !== prevProps.activeChatRoomId) {
            this.setChatName();
        }
    }

    setChatName = () => {
        const {privateChatPartnerData} = this.props;

        let privateChatData = this.props.user.privateChats.find(
            (privateChat) => privateChat.chat._id === this.props.activeChatRoomId
        ).chat;

        let timeAgoString = getTimeAgoString(privateChatData.lastMessageAt, this.props.languages.platformLanguage);
        const chatName = privateChatPartnerData.first + ' ' + privateChatPartnerData.last;
        const avatarSrc = privateChatPartnerData?.avatarSmall;
        this.setState({
            chatName: chatName,
            avatarSrc: avatarSrc,
            timeAgoString: timeAgoString,
        });
    };

    render() {
        const {hasMeeting, lastChatMessage, translation, defaultTranslation} = this.props;
        const {chatName, avatarSrc} = this.state;
        return (
            <div onDragStart={preventDefaultDrag} className="single-chat-header">
                <div>
                    <div
                        className="user-avatar"
                        style={{
                            backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${avatarSrc}')`,
                        }}
                    />
                    <p
                        onDragStart={preventDefaultDrag}
                        className={`group-chat-name privateChat ${!hasMeeting ? 'without-meeting' : ''}`}
                    >
                        {chatName}
                    </p>
                    {hasMeeting ? (
                        <div onDragStart={preventDefaultDrag} className="time-ago-wrapper">
                            {/* <span>{this.state.timeAgoString}</span> */}
                        </div>
                    ) : lastChatMessage ? (
                        <button className="new-meeting-button" onClick={this.props.handleOpenCreateMeetingDialog}>
                            <AddIcon fontSize={'inherit'} />
                            <span>{translation?.meetings?.meeting || defaultTranslation?.meetings.meeting}</span>
                        </button>
                    ) : (
                        <div onDragStart={preventDefaultDrag} className="meeting-button-wrapper">
                            <button
                                className="new-meeting-button"
                                onClick={this.props.handleOpenCreateMeetingDialog}
                                disabled={!lastChatMessage}
                            >
                                <AddIcon fontSize={'inherit'} />
                                <span>{translation?.meetings?.meeting || defaultTranslation?.meetings.meeting}</span>
                            </button>
                            <div onDragStart={preventDefaultDrag} className="tooltip-container">
                                <p>
                                    {translation?.meetings?.createDialog.buttonDisabled ||
                                        defaultTranslation?.meetings.createDialog.buttonDisabled}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        activeChatRoomId: state.user.topNavigation.activeChatRoomId,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps)(ChatTitle);
