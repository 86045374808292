import React from 'react';
import '../Wall/OnDemandVideos.scss';
import connect from 'react-redux/es/connect/connect';
import {withRouter} from 'react-router-dom';
import * as actions from '../../store/actions';
import colors from '../../CSS/_variables.module.scss';
import EmptyBoxIcon from '../../Images/svg/EmptyBoxIcon';
import {preventDefaultDrag} from '../../Utils/utils';
import Spinner from '../../SmallLayoutComponents/Spinner';
import OnDemandList from './OnDemandList';
import moment from 'moment';

class OnDemandVideosSection extends React.Component {
    state = {
        onDemandList: [],
        visualViewportHeight: window.innerHeight,
    };

    componentDidMount() {
        const {eventId} = this.props;
        this.props.onGetOnDemandVideos(eventId).then(() => this.getAllTimeSlots());
        window.visualViewport &&
        window.visualViewport.addEventListener('resize', (event) => {
            this.setState({visualViewportHeight: event.target.height});
        });
    }

    componentWillUnmount() {
        window.visualViewport &&
        window.visualViewport.removeEventListener('resize', (event) => {
            this.setState({visualViewportHeight: event.target.height});
        });
    }

    getAllTimeSlots = () => {
        const {onDemandVideos} = this.props;
        let videosList = [{
            auditoriumDetails: [],
            videos: []
        }];

        onDemandVideos.auditoriums.map((auditorium) => {
            auditorium.dailyProgram.map((dayProgram) => {
                dayProgram.program.map((timeSlot) => {
                    let object = {
                        auditoriumId: auditorium._id,
                        // auditoriumName: auditorium.name,
                        date: dayProgram.date,
                        timeSlotId: timeSlot._id,
                    };
                    videosList[0].auditoriumDetails.push(object);
                    videosList[0].videos.push(timeSlot);
                });
            });
        });

        videosList[0].auditoriumDetails.sort((x, y) => {
            let xx = new Date(x.date);
            let yy = new Date(y.date);
            return yy - xx;
        });

        videosList[0].videos.sort((x, y) => {
            let xx = new Date(x.startTimestamp);
            let yy = new Date(y.startTimestamp);
            return yy - xx;
        });

        if (videosList.length > 0) {
            this.setState({
                onDemandList: videosList[0]
            });
        }
    };

    getProgramDayString = (currentDay) => {
        const {translations, platformLanguage} = this.props.languages;
        let months = [
            translations[platformLanguage]?.time.january,
            translations[platformLanguage]?.time.february,
            translations[platformLanguage]?.time.march,
            translations[platformLanguage]?.time.april,
            translations[platformLanguage]?.time.may,
            translations[platformLanguage]?.time.june,
            translations[platformLanguage]?.time.july,
            translations[platformLanguage]?.time.august,
            translations[platformLanguage]?.time.september,
            translations[platformLanguage]?.time.october,
            translations[platformLanguage]?.time.november,
            translations[platformLanguage]?.time.december,
        ];

        let currentDate = moment(currentDay).startOf('day');
        let day = currentDate.date();
        let month = months[currentDate.month()];
        let year = currentDate.year();

        return day + ' ' + month + ' ' + year;
    };

    render() {
        const {onDemandList, visualViewportHeight} = this.state;
        const {onDemandVideos, translation, isRtlLanguage} = this.props;

        if (onDemandVideos.loading) {
            return (
                <div onDragStart={preventDefaultDrag} className="wall-container loading-on-demand">
                    <Spinner/>
                </div>
            );
        }

        return (
            <div onDragStart={preventDefaultDrag} className="wall-container on-demand">
                <h4>{translation?.onDemandVideos?.sectionTitle}</h4>
                <div onDragStart={preventDefaultDrag} className="on-demand-videos-container">
                    {onDemandList && onDemandList?.length === 0 ? (
                        <div onDragStart={preventDefaultDrag}
                             className={`no-videos ${
                                 navigator.userAgent.match(/iPhone/i) === null &&
                                 visualViewportHeight !== window.innerHeight &&
                                 window.innerWidth <= 1024
                                     ? 'empty-videos'
                                     : ''
                             }`}
                        >
                            <p>{translation?.onDemandVideos?.noOnDemandVideos}</p>
                            <EmptyBoxIcon
                                fillPrimary={colors.primary}
                                fillPrimaryDark={colors.primaryDark}
                                fillGreyLight={colors.lightGrey}
                                fillGrey={colors.grey}
                            />
                        </div>
                    ) : (
                        <div onDragStart={preventDefaultDrag} className="on-demand-videos-list">
                            <OnDemandList
                                videos={onDemandList}
                                isRtlLanguage={isRtlLanguage}
                                getProgramDayString={this.getProgramDayString}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        onDemandVideos: state.onDemandVideos,
        isRtlLanguage: state.languages.isRtlLanguage,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onGetOnDemandVideos: (eventId) => dispatch(actions.getOnDemandVideos(eventId)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnDemandVideosSection));
