import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "store/actions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import "./ExternalIDDialogStyles.scss";

const ExternalIDDialog = ({ open: popupOpen, timeslotTitle }) => {
	const dispatch = useDispatch();

	// translations
	const platformLanguage = useSelector(
		(state) => state.languages.platformLanguage,
	);
	const translation = useSelector(
		(state) => state.languages.translations[platformLanguage],
	);
	const defaultTranslation = useSelector(
		(state) => state.languages.translations["en"],
	);
	const isLargeScreen = useSelector((state) => state.layout.isLargeScreen);

	const [open, setOpen] = useState(popupOpen);

	const seeProgram = () => {
		if (isLargeScreen) {
			dispatch(actions.topNavSeeProgram());
			return;
		}

		dispatch(actions.topNavSeeProgramMobile());
	};

	const handleViewProgram = () => {
		localStorage.removeItem("externalId");
		setOpen(false);
		seeProgram();
	};

	const onClose = async () => {
		localStorage.removeItem("externalId");
		setOpen(false);
	};

	const timeslotTitleCount = timeslotTitle.split(" ").length;

	const truncateTitle = (title, count) => {
		return count > 5 ? title.split(" ").slice(0, 5).join(" ") + "..." : title;
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="form-dialog-title"
			PaperProps={{ classes: { root: "reverse-rtl external-id-dialog" } }}
			disableBackdropClick={true} // Disable closing the dialog when clicking outside
			disableEscapeKeyDown={true} // Optional: Disable closing the dialog with the Escape key
		>
			<DialogTitle id="form-dialog-title-externalID">
				{translation.externalIdTranslations.headline ||
					defaultTranslation.externalIdTranslations.headline}
			</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={onClose}
				style={{ position: "absolute", right: 8, top: 8 }}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent>
				<DialogContentText id="form-dialog-secondary">
					<h3 className={"sub-title-external-id-popup"}>
						{translation.externalIdTranslations.subHeadlineOne ||
							defaultTranslation.externalIdTranslations.subHeadlineOne}
						<b>"{truncateTitle(timeslotTitle, timeslotTitleCount)}"</b>{" "}
						{translation.externalIdTranslations.subHeadlineTwo ||
							defaultTranslation.externalIdTranslations.subHeadlineTwo}
					</h3>
					<p
						dangerouslySetInnerHTML={{
							__html:
								translation.externalIdTranslations.checkCalendar ||
								defaultTranslation.externalIdTranslations.checkCalendar,
						}}
					></p>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					classes={{ label: "dialog-btn-uppercase" }}
					type="button"
					onClick={handleViewProgram}
				>
					{translation.externalIdTranslations.button ||
						defaultTranslation.externalIdTranslations.button}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ExternalIDDialog;
