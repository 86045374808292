import React, {PureComponent} from 'react';
import {preventDefaultDrag} from '../../Utils/utils';

class RegisterDescriptionSectionMobile extends PureComponent {
    render() {
        const {
            registerAsParticipant,
            registerAsExhibitor,
            registerAsScholar,
            brandingTranslation,
            translation,
            defaultTranslation,
            isOnTabRegister,
        } = this.props;
        return (
            <div>
                <div
                    onDragStart={preventDefaultDrag}
                    className={`form-header left-log-in-description ${isOnTabRegister ? 'is-sign-up' : ''}`}
                >
                    <h1 className="form-title banner-text mobile-color">
                        {isOnTabRegister ? (
                            <>
                                <span>
                                    {translation?.register.registerButton || defaultTranslation?.register.registerButton}
                                </span>
                                {registerAsParticipant && (
                                    <span>
                                        {' ' +
                                        (translation?.login.logInAsParticipantSecondText ||
                                            defaultTranslation?.login.logInAsParticipantSecondText)}
                                    </span>
                                )}
                                {registerAsExhibitor && (
                                    <span>
                                        {' ' +
                                        (translation?.login.logInAsExhibitorSecondText ||
                                            defaultTranslation?.login.logInAsExhibitorSecondText)}
                                    </span>
                                )}
                                {registerAsScholar && (
                                    <span>
                                        {' ' +
                                        (translation?.login.logInAsPosterSecondText ||
                                            defaultTranslation?.login.logInAsPosterSecondText)}
                                    </span>
                                )}
                            </>
                        ) : (
                            <div>
                                <span>{translation?.login.loginTitle || defaultTranslation?.login.loginTitle}</span>
                            </div>
                        )}
                    </h1>
                    {registerAsExhibitor && (
                        <>
                            <p>
                                {brandingTranslation?.exhibitorsRegDescription.split('\n').map((item, key) => {
                                    return (
                                        <span onDragStart={preventDefaultDrag} draggable="false" key={key}>
                                            {item}
                                            <br />
                                        </span>
                                    );
                                })}
                            </p>
                        </>
                    )}
                    {registerAsScholar && (
                        <>
                            <p>
                                {brandingTranslation?.scholarsRegDescription.split('\n').map((item, key) => {
                                    return (
                                        <span onDragStart={preventDefaultDrag} draggable="false" key={key}>
                                            {item}
                                            <br />
                                        </span>
                                    );
                                })}
                            </p>
                        </>
                    )}
                    {registerAsParticipant && (
                        <>
                            <p>
                                {brandingTranslation?.participantsRegDescription.split('\n').map((item, key) => {
                                    return (
                                        <span onDragStart={preventDefaultDrag} draggable="false" key={key}>
                                            {item}
                                            <br />
                                        </span>
                                    );
                                })}
                            </p>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default RegisterDescriptionSectionMobile;
