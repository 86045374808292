import React from 'react';
import Spinner from '../../SmallLayoutComponents/Spinner';
import LoginBackground from '../../Images/background.jpg';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import SocialLoginButtons from '../../Components/SocialNetworkButtons';
import FullPageSpinner from '../../SmallLayoutComponents/FullPageSpinner';
import {getBackgroundImageUrl, preventDefaultDrag} from '../../Utils/utils';
import LoginEventForm from './LoginEventForm';
import RegisterMenuLinks from '../../Components/RegisterMenuLinks';
import {Helmet} from 'react-helmet';

class SingleLoginEventPage extends React.Component {
    state = {
        brandingTranslation: null,
    };

    componentDidMount() {
        this.props.onSetEventPublicMenu();
        this.setLanguagesData();
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.eventLanguage !== this.props.languages.eventLanguage) {
            this.setLanguagesData();
        }
    }

    // if we had an error from server on login attempt we clear that error
    componentWillUnmount() {
        this.props.onClearError();
    }

    setLanguagesData = () => {
        const brandingTranslation = this.props.eventInfo.brandingTranslations.find(
            (translation) => translation.language === this.props.languages.eventLanguage
        );
        this.setState({
            brandingTranslation: brandingTranslation,
        });
    };

    render() {
        const {brandingTranslation} = this.state;
        let {eventInfo, loadingUser, loadingEvent, isMobile, translation, defaultTranslation} = this.props;

        if (loadingEvent && !eventInfo) {
            return (
                <div onDragStart={preventDefaultDrag} className="login-page-spinner-container">
                    <FullPageSpinner />
                </div>
            );
        }
        let overlayColor = {};
        if (eventInfo.brandingData.overlayColorLogin) {
            overlayColor.background = eventInfo.brandingData.overlayColorLogin;
        }

        return (
            <div onDragStart={preventDefaultDrag} className="login-tab single-login-event-page">
                <Helmet>
                    <title>{`${process.env.REACT_APP_PLATFORM_NAME} - ${
                        brandingTranslation?.homepageEventName
                            ? brandingTranslation?.homepageEventName
                            : eventInfo.title
                    }: ${translation?.login.loginButton || defaultTranslation?.login.loginButton}`}</title>
                    <meta
                        property="og:title"
                        content={`${process.env.REACT_APP_PLATFORM_NAME} - ${brandingTranslation?.homepageEventName}: ${
                            translation?.login.loginButton || defaultTranslation?.login.loginButton
                        }`}
                    />
                </Helmet>
                <div onDragStart={preventDefaultDrag} className="login-page platform-login">
                    <div onDragStart={preventDefaultDrag} className="full-background-container p-relative d-flex">
                        {eventInfo.brandingData && eventInfo.brandingData.loginBackground ? (
                            <img
                                src={getBackgroundImageUrl(eventInfo, 'loginBackground')}
                                className="full-background-img img-cover p-absolute w-100 h-100"
                                alt="background"
                            />
                        ) : (
                            <img
                                src={LoginBackground}
                                className="full-background-img img-cover p-absolute w-100 h-100"
                                alt="background"
                            />
                        )}
                        {/*<div*/}
                        {/*    onDragStart={preventDefaultDrag}*/}
                        {/*    className="full-background-overlay p-absolute w-100 h-100"*/}
                        {/*    style={overlayColor}*/}
                        {/*/>*/}
                        <div onDragStart={preventDefaultDrag} className="header-spacing-container">
                            <div
                                onDragStart={preventDefaultDrag}
                                className="w-100 h-100 d-flex justify-content-center align-items-center"
                            >
                                {eventInfo ? (
                                    <div onDragStart={preventDefaultDrag} className="form-container d-flex">
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className="form-header left-log-in-description is-sign-up"
                                        >
                                            <h1 className="form-title banner-text">
                                                <div>
                                                    <span>
                                                        {translation?.register.loginTitleForm ||
                                                        defaultTranslation?.register.loginTitleForm}
                                                    </span>
                                                </div>
                                            </h1>
                                        </div>
                                        <div>
                                            <div onDragStart={preventDefaultDrag} className="left-form">
                                                <div>
                                                    <p>
                                                        {brandingTranslation?.loginDescription
                                                            .split('\n')
                                                            .map((item, key) => {
                                                                return (
                                                                    <span
                                                                        onDragStart={preventDefaultDrag}
                                                                        draggable="false"
                                                                        key={key}
                                                                    >
                                                                        {item}
                                                                        <br />
                                                                    </span>
                                                                );
                                                            })}
                                                    </p>
                                                </div>
                                            </div>
                                            <div onDragStart={preventDefaultDrag} className="right-form">
                                                <div onDragStart={preventDefaultDrag} className="register-form-wrapper">
                                                    {isMobile && (
                                                        <p
                                                            onDragStart={preventDefaultDrag}
                                                            className="title-description"
                                                        >
                                                            {brandingTranslation?.loginDescription
                                                                .split('\n')
                                                                .map((item, key) => {
                                                                    return (
                                                                        <span
                                                                            onDragStart={preventDefaultDrag}
                                                                            draggable="false"
                                                                            key={key}
                                                                        >
                                                                            {item}
                                                                            <br />
                                                                        </span>
                                                                    );
                                                                })}
                                                        </p>
                                                    )}
                                                    <SocialLoginButtons
                                                        logInTab
                                                        registerAsExhibitor={false}
                                                        registerAsParticipant={false}
                                                        registerAsScholar={false}
                                                    />
                                                    <LoginEventForm
                                                        translation={translation}
                                                        defaultTranslation={defaultTranslation}
                                                        singleLoginEvent

                                                    />
                                                </div>
                                                {(loadingUser || (loadingEvent && eventInfo)) && <Spinner />}
                                                {isMobile && <RegisterMenuLinks />}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        loadingUser: state.user.loading,
        isMobile: state.layout.isMobile,
        eventInfo: state.event.eventInfo,
        languages: state.languages,
        translation: state.languages.translations[state.languages.eventLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onClearError: () => dispatch(actions.clearError()),
        onSetEventPublicMenu: () => dispatch(actions.setEventPublicMenu()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleLoginEventPage);
