const ru = {
	generalText: {
		upload: "Загрузить",
		save: "Сохранить",
		add: "Добавить   ",
		select: "Выбрать   ",
		remove: "Удалить",
		close: "ЗАКРЫТЬ",
		update: "ОБНОВИТЬ",
		cancel: "ОТМЕНИТЬ",
		confirm: "Подтвердить   ",
		download: "Загрузить",
		refresh: "Обновить",
		manage: "Управление",
		back: "Назад",
		submit: "Подтвердить",
		send: "Отправить",
		eventMO: "onvent",
		eventETX: "ивент",
		eventsMO: "onvents",
		eventsETX: "ивенты",
		eventUpperMO: "Onvent",
		eventUpperETX: "Ивент",
		hide: "HIDE", //new translation: HIDE
	},
	time: {
		justNow: "только что",
		minute: "минута",
		minutes: "минуты",
		hour: "час",
		hours: "часы",
		day: "день",
		days: "дня",
		month: "месяц",
		months: "месяцы",
		yesterday: "вчера",
		today: "сегодня",
		tomorrow: "завтра",
		monday: "Понедельник",
		tuesday: "Вторник",
		wednesday: "Среда",
		thursday: "Четверг",
		friday: "Пятница",
		saturday: "Суббота",
		sunday: "Воскресение",
		mondayShort: "Пн",
		tuesdayShort: "Вт ",
		wednesdayShort: "Ср ",
		thursdayShort: "Чт",
		fridayShort: "Пт",
		saturdayShort: "Сб",
		sundayShort: "Вс",
		january: "Январь",
		february: "Февраль",
		march: "Март",
		april: "Апрель",
		may: "Май",
		june: "Июнь",
		july: "Июль",
		august: "Август",
		september: "Сентябрь",
		october: "Октябрь",
		november: "Ноябрь",
		december: "Декабрь",
		januaryShort: "Янв ",
		februaryShort: "Фев ",
		marchShort: "Мар",
		aprilShort: "Апр ",
		mayShort: "Май",
		juneShort: "Июнь",
		julyShort: "Июль",
		augustShort: "Авг ",
		septemberShort: "Сен ",
		octoberShort: "Окт ",
		novemberShort: "Ноя ",
		decemberShort: "Дек ",
	},
	errors: {
		noFile: "Файл не выбран",
		required: "Обязательное к заполнению поле",
		emailNotValid: "Адрес электронной почты недействителен",
		passwordMinLength: "Пароль должен иметь минимум 8 символов",
		passwordMismatch: "Несоответствие паролей",
		passwordIsSame: "Your new password must be different", //new translation
		fileToLarge: "Файл слишком большой. 2Mb максимальный размер файла.",
		fileToLargeTextFirst: "Файл слишком большой.",
		fileToLargeTextSecond: "максимальный размер файла",
		agreeToGDPR: "Пожалуйста, согласитесь с политикой конфиденциальности",
		noInvitationCode: "Пожалуйста, введите код приглашения",
		invitationCodeInvalid: "Код приглашения недействителен",
		imageFileNotSupported:
			"Тип файла не поддерживается. Пожалуйста, используйте один из следующих вариантов:",
		phoneInvalid:
			"Пожалуйста, введите действительный номер телефона. Например: +447517663928",
		linkInvalid: "Пожалуйста, введите действительную ссылку (https://site.com)",
		facebookLinkInvalid:
			"Пожалуйста, введите действительную ссылку (https://facebook.com/)",
		linkedinLinkInvalid:
			"Пожалуйста, введите действительную ссылку (https://linkedin.com/)",
		twitterLinkInvalid:
			"Пожалуйста, введите действительную ссылку (https://twitter.com/)",
		youtubeLinkInvalid:
			"Введите действительную ссылку (https://www.youtube.com/)",
		connectionProblemTextFirst: "Возможно, у Вас проблема с подключением. ",
		connectionProblemButton: "НАЖМИТЕ ЗДЕСЬ   ",
		connectionProblemTextSecond: "или перезагрузите страницу",
		fileTooLarge: "Файо слишком большой/Максимальный размер файла 2Мьб",
		fileTooLargeTextFirst: "Файл слишком большой.",
		fileTooLargeTextSecond: "Максимальный размер файла.",
	},
	footer: {
		cookiesText:
			"Этот веб-сайт использует основные файлы cookie, которые необходимы для его работы. Он также использует сторонние файлы cookie, которые необходимы для работы некоторых функций нашего веб-сайта. Если вы хотите узнать больше или отозвать свое согласие на использование некоторых файлов cookie, обратитесь к нашей",
		cookiesLink: "Политика Cookies",
		cookiesButton: "ПРИНЯТЬ",
		privacyPolicy: "Политика Конфиденциальности",
		cookies: "Cookies",
		termsAndConditions: "Правила и Условия",
		socialLinksTitle: "Подпишитесь на нас:",
	},
	menu: {
		logIn: "ВОЙТИ",
		logOut: "ВЫЙТИ",
		scholarRegistration: "Poster / Study Registration", // new translation
		exhibitorRegistration: "Будьте Экспонентом", // new translation modification (Be Exhibitor => Exhibitor Registration)
		participantRegistration: "РЕГИСТРАЦИЯ", // new translation modification (Register => Participant Registration)
		createEvent: "Организовать ивент",
		launchText: "Запуск в сентябре 2020 г. - открыт для раннего доступа",
	},
	externalIdTranslations: {
		headline: "Вы зарегистрированы",
		subHeadlineOne: "Сессия ",
		subHeadlineTwo: "теперь в вашем календаре Нейронной Сети!",
		button: "ПРОСМОТРЕТЬ ПРОГРАММУ",
		checkCalendar:
			'Проверьте "<strong>My Calendar</strong>" во вкладке "<strong>Program</strong>" для предстоящих сессий и изучите сотни прошедших сессий, используя фильтры поиска. И не пропустите "<strong>Smart Suggestions</strong>" для контента, подобранного специально для вас!',
	},

	homepage: {
		organizedBy: "ОРГАНИЗАТОР",
		date: "ДАТА",
		location: "ДОМЕН И МЕСТО ПРОВЕДЕНИЯ",
		price: "ЦЕНА",
		tabIntro: "ОБЗОР ",
		tabIntroProgramButton: "ПРОГРАММА",
		tabProgram: "ПРОГРАММА",
		tabProgramIntroButton: "ОБЗОР ",
		tabPartners: "ПАРТНЕРЫ",
		tabExhibitors: "УЧАСТНИКИ",
		previousEventsSectionTitle: "ПРЕДЫДУЩИЕ МЕРОПРИЯТИЯ",
		tabSocialMedia: "СОЦИАЛЬНЫЕ СЕТИ",
	},
	socialButtons: {
		linkedinButton: "Войти с помощью LinkedIn",
		facebookButton: "Войти с помощью Facebook",
		googleButton: "Войти с помощью Google",
		twitterButton: "Войти с помощью LinkedIn",
	},
	login: {
		orText: "или",
		inputEmail: "Электронная почта",
		inputPassword: "Пароль",
		rememberMeButton: "Запомнить меня",
		forgotPasswordButton: "Забыли пароль?",
		loginButton: "ВОЙТИ",
		noAccountText: "Еще не зарегестрированы?",
		registerButton: "Зарегистрируйтесь здесь",
		recoverPasswordDialogTitle: "ВОССТАНОВИТЬ ПАРОЛЬ",
		recoverPasswordDialogInputEmail: "Электронная почта",
		recoverPasswordDialogCloseButton: "ЗАКРЫТЬ",
		recoverPasswordDialogRecoverButton: "ВОССТАНОВИТЬ  ",
		errorNotRegistered: "Не зарегестрирован",
		errorUnableToLogin: "Невозможно войти",
	},
	register: {
		formTitle: "Или создайте свой профиль",
		inputFirstName: "Имя",
		inputLastName: "Фамилия",
		inputEmail: "Электронная почта",
		inputPassword: "Пароль",
		inputConfirmPassword: "Подтвердить пароль",
		uploadImageText: "Загрузить изображение профиля (2MB):",
		editImageText: "Изменить изображение профиля (2 МБ):",
		gdprTextFirst: "Продолжая, вы соглашаетесь с нашей",
		gdprTextTerms: "Политикой Использования",
		gdprTextSecond: "и подтверждаете, что вы прочли нашу ",
		gdprTextCookies: "Политику Cookies",
		gdprTextThird: "и",
		gdprTextPrivacy: "Политику Конфиденциальности",
		gdprTextAgree: "Пожалуйста, согласитесь с политикой конфиденциальности",
		registerButton: "Регистрация",
		haveAccountText: "Уже есть аккаунт?",
		haveAccountLoginButton: "Войти здесь",
		errorAlreadyHaveAccount: "Уже зарегестрированы",
		formTitleTextFirst: "Или создайте свой",
		formTitleTextSecond: "профиль",
		invitationCode: "Код приглашения   ",
	},
	participantRegistration: {
		choosePackageTitle: "ВЫБЕРИТЕ ТАРИФНЫЙ ПАКЕТ",
		noPackageText: "Пожалуйста, выберите тарифный пакет",
		gdprTextFirst: "Продолжая, вы соглашаетесь с нашей",
		gdprTextPrivacy: "Политика Конфиденциальности",
		gdprTextSecond: "и",
		gdprTextTerms: "Условиями проведения мероприятия",
		gdprTextAgree: "Пожалуйста, согласитесь с политикой конфиденциальности",
		registerButtonFree: "Завершить регистрацию",
		registerButtonPay: "Перейти к Оплате",
	},
	exhibitorRegistration: {
		choosePackageTitle: "ВЫБЕРИТЕ ТАРИФНЫЙ ПАКЕТ",
		gdprTextFirst: "Продолжая, вы соглашаетесь с нашей",
		gdprTextPrivacy: "\nПолитикой конфиденциальности мероприятия",
		gdprTextSecond: "и",
		gdprTextTerms: "Условиями проведения мероприятия",
		gdprTextAgree: "Пожалуйста, согласитесь с политикой конфиденциальности",
		registerButtonFree: "Зарегистрироваться",
		registerButtonPay: "Перейти к Оплате",
	},
	stripePayment: {
		cardNumber: "Номер карты",
		expirationDate: "Срок действия (ММ/ГГ)",
		cvc: "CVC",
		disclaimerText:
			"Эта платежная операция зашифрована. Безопасность и конфиденциальность гарантируются сервисами Stripe.",
		disclaimerTextLink: "Нажмите, чтобы узнать больше",
		cancelButton: "ОТМЕНИТЬ",
		payButton: "Оплатить",
	},
	recoverPassword: {
		recoverTitle: "ВОССТАНОВИТЬ ПАРОЛЬ",
		cancelButton: "ЗАКРЫТЬ",
		recoverButton: "ВОССТАНОВИТЬ  ",
		recoverTextSend: "Заявка восстановления пароля отправлено успешно.",
	},
	unsubscribe: {
		unsubscribeTitle: "Отписаться от рассылки?",
		unsubscribeHomepage: "Вернуться на Главную",
	},
	notAvailable: {
		notAvailableTitle: "Ивент недоступен",
		notAvailableSubtitle:
			"Мероприятие, которое вы ищете, недоступно. Оно был закрыто организатором или отключено администратором",
		notAvailableTitleTextSecond: "недоступно",
		notAvailableSubtitleTextFirst: "",
		notAvailableSubtitleTextSecond:
			"вы ищете, недоступно. Оно был закрыто организатором или отключено администратором",
		closedTitle: "закрыто",
		closedSubtitleTextFirst: "Вы успешно зарегестрировались ",
		closedSubtitleTextSecond: "закрыто",
	},
	notFound: {
		notFoundDescription: "Упс ... Здесь нет ивентов!",
		notFoundHomepage: "Вернуться на Главную",
	},
	eventMenu: {
		organizerButton: "ОРГАНИЗАТОР",
		exhibitorButton: "ЭКСПОНЕНТЫ",
		boothButton: "СТЕНД",
		boothsButton: "СТЕНДЫ",
		homeButton: "ГЛАВНАЯ",
		contactsButton: "КОНТАКТЫ",
		meetingsButton: "ВСТРЕЧИ",
		chatsButton: "ЧАТЫ",
		programButton: "ПРОГРАММА",
		archiveButton: "АРХИВ",
		myAccountButton: "МОЙ АККАУНТ",
		lobbyButton: "ЛОББИ",
		roomsButton: "ЗАЛЫ",
		connectionProblemTextFirst:
			"Доступны новые обновления. Пожалуйста, нажмите",
		connectionProblemButton: "ЗДЕСЬ",
		connectionProblemTextSecond: "или обновите страницу.",
		sessionProblemTextFirst: "Время сеанса истекло. Пожалуйста, нажмите",
		sessionProblemButton: "ЗДЕСЬ",
		sessionProblemTextSecond: "чтобы войти повторно.",
		backButton: "НАЗАД",
		hubButton: "Центр",
		metaverse: "МЕТАВСЕЛЕННАЯ",
		floorPlan: "ПЛАН ЭТАЖЕЙ",
	},
	myEventsDropdown: {
		title: "МОИ ИВЕНТЫ",
		createButtonDesktop: "Создать ивент",
		createButtonMobile: "СОЗДАТЬ",
	},
	myBoothsDropdown: {
		title: "МОЙ СТЕНД",
	},
	contactsDropdown: {
		title: "ВАШИ КОНТАКТЫ",
		requestsTitle: "Запросы контактов",
		rejectButton: "ОТКАЗАТЬ",
		acceptButton: "ПРИНЯТЬ",
		pendingButton: "Ожидается",
		addButton: "Добавить   ",
		noContacts:
			"У вас пока нет контактов. Посетите Нетворкинг чтобы завести новые связи",
		noScannedContacts: "У вас еще нет отсканированных контактов.",
	},
	meetingsDropdown: {
		meetingWith: "Встреча с",
		pending: "В ожидании",
		accepted: "Подтверждено",
		canceled: "Отменено",
		noMeetings: "Встречи не запланированы",
		finished: "Завершено",
	},
	programDropdown: {
		timeZone: "Часовой пояс",
		with: "с",
		noProgram: "",
	},
	notificationsDropdown: {
		title: "Уведомления",
		contactRequestReceived: "пригласил вас в сеть контактов",
		contactRequestAccepted:
			"принял ваш запрос на контакт. Начните общение сейчас",
		meetingRequestReceived: "\nотправил вам приглашение на встречу",
		meetingRequestAccepted: "принял ваше приглашение для встречи",
		meetingRequestCanceled: "отклонил ваш запрос для встречи",
		meetingStarting: "Встреча скоро начнется. Занимайте места",
		newBoothPost: "Написал на стене вашего стенда. Откройте чтобы проверить",
		noNotifications: "У вас нет уведомлений",
		seeAll: "ПОСМОТРЕТЬ ВСЕ",
		hideAll: "СВЕРНУТЬ ВСЕ",
		agendaItemUpdated:
			"Сохраненный элемент программы был обновлен организатором мероприятия",
		agendaItemDelted:
			"Сохраненный элемент программы был удален организатором мероприятия",
		newMeetingRequest: "\nУ вас есть новое приглашение для встречи",
		meetingAccepted: "\nВаше приглашение на встречу принято",
		meetingCancelled: "\nВаша встреча была отменена",
		meetingWillStartTextFirst: "Встреча с",
		meetingWillStartTextSecond: "Скоро начнется. Проверьте!",
	},
	chatsDropdown: {
		title: "ЧАТЫ",
		newChatButton: "Новый чат",
		newChatStart: "Начать новый чат",
		newChatProvideText:
			"\nУкажите имя для вашего чата. Впоследствии вы сможете отредактировать имя:",
		noChats: "Создайте хотя бы один чат для общения с участниками мероприятия",
		member: "Участник",
		members: "Участники",
		noOpenedChatText: "Выберите чат, чтобы продолжить беседу.",
		noMessagesInChatTextFirst: "Разбейте лед!",
		noMessagesInChatTextSecond: "Будьте первым, кто начнет беседу.",
		newChatNameInput: "Название чата (по желанию)",
		newChatAddMembers: "Добавить других участников в этот чат",
		editChatAddMembers:
			"Найдите посетителей этого мероприятия, которых вы хотите добавить в групповой чат.",
		newChatSearchInput: "Найти посетителей",
		newChatNoUsersMessage: "Посетители не найдены",
		newChatAddUserButton: "Добавить   ",
		newChatRemoveUserButton: "Удалить",
		newChatCancelButton: "ЗАКРЫТЬ",
		newChatCreateButton: "СОЗДАТЬ",
		membersLeaveButton: "ВЫЙТИ",
		membersUpdateButton: "ОБНОВИТЬ",
		membersChatButton: "ЧАТ",
		chatSettingsButton: "НАСТРОЙКИ",
		newMessageInput: "Напечатать сообщение здесь",
		newVideoCallTooltipText: "Нажмите здесь, чтобы начать видеочат",
		fullscreenButton: "Открыть на весь экран",
		minimizeButton: "\nСвернуть экран",
	},
	myAccountDropdown: {
		title: "МОЙ АККАУНТ",
		viewProfileButton: "\nПросмотреть профиль",
		logoutButton: "ВЫЙТИ",
	},
	myAccountPage: {
		platformProfileSectionTitle: "Профиль платформы",
		changePasswordButton: "ПОМЕНЯТЬ ПАРОЛЬ",
		deleteAccountButton: "УДАЛИТЬ МОЙ АККАУНТ",
		platformLanguage: "\nЯзык платформы:",
		reviewPlatformGdprText: "Ознакомьтесь с нашими условиями Onvent:",
		privacyPolicyLink: "Уведомление о конфиденциальности / политика",
		cookiesLink: "Cookies",
		termsAndConditionsLink: "Правила и Условия",
		eventProfileSectionTitle: "Профиль мероприятия",
		eventPackage: "Пакет мероприятия",
		reviewEventGdprText: "Ознакомьтесь с условиями нашего мероприятия:",
		unregisterFromEventButton: "ОТМЕНА РЕГИСТРАЦИИ НА МЕРОПРИЯТИЕ",
		unregisterCurrentEventButton: "Отменить регистрацию на текущее мероприятие",
		unregisterCurrentEventConfirmation:
			"Подтвердите, что вы хотите отменить регистрацию на мероприятие",
		enableParticipantProfile: "Включить профиль участника",
		editUserDialogTitle: "Редактировать данные Пользователя",
		editExhibitorDialogTitle: "Изменить данные контактного лица компании",
		editParticipantDialogTitle: "Редактировать данные Участника",
		firstName: "Имя",
		lastName: "Фамилия",
		uploadImage: "Загрузить изображение",
		removeImage: "Удалить изображение",
		deleteTitle: "УДАЛИТЬ МОЙ АККАУНТ",
		deleteConfirm: "Подтвердите удаление моего аккаунта",
		deleteError:
			"Вы не можете удалить свой аккаунт. Пожалуйста, удалите все созданные мероприятия, чтобы иметь возможность удалить свой аккаунт.",
		deleteSuccess: "Ваш аккаунт был удален",
		email: "Электронная почта",
		reviewEventPrivacyPolicy:
			"Ознакомьтесь с Политикой конфиденциальности мероприятия:",
		title: "Название",
		company: "Компания",
		phone: "Телефон",
		platformProfileSectionTitleTextSecond: "профиль",
		reviewPlatformGdprTextFirst: "Ознакомьтесь с нашими   ",
		reviewPlatformGdprTextSecond: "Условиями ",
	},
	changePassword: {
		title: "ПОМЕНЯТЬ ПАРОЛЬ",
		oldPassword: "Старый пароль   ",
		oldPasswordError: "Старый пароль не совпадает!",
		inputPassword: "Новый пароль",
		inputConfirmPassword: "Подтвердить пароль",
		changePasswordButton: "ПОМЕНЯТЬ ПАРОЛЬ",
	},
	roomsDropdown: {
		title: "МЕНЮ ЗАЛОВ",
		auditoriumButton: "АУДИТОРИЯ",
		showfloorButton: "ВЫСТАВКА",
		archiveButton: "АРХИВ",
		onDemandButton: "ON-DEMAND", // new translation
		metaverse: "МЕТАВСЕЛЕННАЯ",
		floorPlan: "ПЛАН ЭТАЖЕЙ",
	},
	sideMenu: {
		liveWall: "ЖИВАЯ СТЕНА",
		networking: "НЕТВОРКИНГ",
		info: "ИНФО",
		videoWall: "ВИДЕО-СТЕНА",
		booth: "СТЕНД",
		boothWall: "СТЕНА СТЕНДА",
		resources: "РЕСУРСЫ",
		networkingTooltip: "У вас нет доступа к Нетворкингу",
		polls: "ОПРОСЫ",
	},
	wall: {
		sortBy: "Сортировать по:",
		popularity: "ПОПУЛЯРНОСТИ",
		time: "НОВИЗНЕ",
		emptyWallText: "\nБудьте первым, кто добавит здесь пост",
		newPostInput: "Расскажите о себе вкратце",
		newCommentInput: "Комментарий",
		resourcesVideoDescription: "ОПИСАНИЕ ВИДЕО",
		resourcesLinks: "Ссылки",
		resourcesFiles: "Файлы",
		whatIsOnYourMind: "Что думаете?",
	},
	networking: {
		searchInput: "Искать посетителей",
		at: "в/на",
		regenerateMatches: "регенерировать пары",
	},
	closedAuditoriumDialog: {
		title: "Информация об Аудитории",
		content: "\nДоступ в Аудиторию был ограничен.",
		contentPackage: "Ваш тарифный пакет не имеет доступа к аудиториям.",
		button: "ХОРОШО",
	},
	closedShowfloorDialog: {
		title: "Информация о Выставке",
		content: "\nДоступ на выставочную площадку был ограничен.",
		contentPackage:
			"\nВ вашем тарифном пакете нет доступа к выставочной площадке.", //New translation: Your access package doesn't have access to any showfloors.
		button: "ХОРОШО",
	},
	auditorium: {
		willStart: "\nСледующая сессия начнется через ...",
		noAccess: "\nВаш тарифный пакет не имеет доступа к этой аудитории.",
		noVideos: "\nВ этой аудитории не запланировано воспроизведение видео.",
		stageTab: "СЦЕНА",
		stagesButton: "СЦЕНЫ",
		videoWallButton: "ВИДЕО-СТЕНА",
		resourcesButton: "РЕСУРСЫ",
		slotEndsTextFirst: "\nЭтот слот заканчивается через...",
		slotEndsTextSecond:
			"\nПрезентация будет доступна по запросу в конце программы.",
		slotTitleWith: "с",
		allRooms: "ВСЕ ЗАЛЫ",
		onDemandButton: "ON-DEMAND", // new translation
		allFloors: "ВСЕ ПЛАНЫ ЭТАЖЕЙ",
		hide: "СКРЫТЬ",
	},
	auditoriumArchive: {
		title: "Архиву Аудитории",
		titleEvent: "Архив Мероприятия",
		with: "с",
		stage: "СЦЕНА",
		videoWall: "Стена Видео",
		resources: "РЕСУРСЫ",
		tooltipMinimize: "Свернуть",
		tooltipFullscreen: "Развернуть",
		tooltipPause: "Пауза",
		tooltipPlay: "Вопроизвести",
		tooltipUnmute: "Включить звук",
		tooltipMute: "Выключить звук",
		noVideosAvailable: "All sessions finished", // new translation
	},
	videoPlayer: {
		videoNotPlayingError:
			"Возникли проблемы с видеопотоком? Пожалуйста, перезагрузите.",
		videoEndedTextFirst: "\nВидео закончилось. Спасибо за просмотр",
		videoEndedTextSecond: "Видео-стена открыта для вопросов и ответов.",
		tooltipPlay: "Вопроизвести",
		tooltipPause: "Пауза",
		tooltipMinimize: "Свернуть",
		tooltipFullscreen: "Развернуть",
		tooltipMute: "Выключить звук",
		tooltipUnmute: "Включить звук",
	},
	showfloor: {
		allExhibitorsButton: "ВСЕ ЭКСПОНЕНТЫ", //new translation: Showfloor menu
		featuredExhibitorsText: "РЕКОМЕНДУЕМЫЕ ЭКСПОНЕНТЫ",
		boothLinksDialogTitle: "ССЫЛКИ НА СТЕНДЫ",
		boothLinksDialogOtherLinksTitle: "Другое:",
		boothFilesDialogTitle: "ФАЙЛЫ СТЕНДА",
		boothVideoDialogTitle: "ВИДЕО",
		mobileTabChat: "ЧАТ",
		mobileTabFiles: "ФАЙЛЫ",
		mobileTabLinks: "ССЫЛКИ",
		mobileTabVideo: "ВИДЕО",
		noExhibitorsTextFirst: "\nУЧАСТНИКИ РАБОТАЮТ НАД СВОИМИ СТЕНДАМИ",
		noExhibitorsTextSecond: "БУДЬТЕ НА СВЯЗИ",
		filterBy: "\nФильтровать по",
		searchByTheUser: "Поиск по имени пользователя",
		showShowfloorsButton: "ALL EXHIBIT HALLS", //new translation
		searchExhibitors: "Search exhibitors", // //new translation: Search exhibitors
		showfloorsTitle: "Exhibit Halls", //new translation: Showfloors
	},
	eventArchive: {
		title: "Видео Архив",
		searchText: "Нажмите для поиска",
		noVideosText: "\nВидео по этому иветну не найдено.",
		noArchivedEvents: "\nВ настоящее время нет заархивированных ивентов.",
	},
	exhibitorDashboard: {
		event: "МЕРОПРИЯТИЕ",
		title: "Настройки стенда",
		visitorsButton: "Посетители стенда",
		goToBoothButton: "Посмотреть стенд",
		goToBoothButtonDisabled:
			"Ваш стенд пока недоступен для предварительного просмотра.",
		tabStyle: "Стиль Стенда",
		tabLogo: "Логотип",
		tabFiles: "Файлы",
		tabLinks: "Ссылки",
		tabAvatars: "Аватары",
		tabVideo: "Видео",
		boothStyleTab: {
			optionOne: "Вариант 1 - Скандинавский стиль",
			optionTwo: "Вариант 2 - Модерн стиль",
			optionThree: "Вариант 3 - Динамический стиль",
			optionFour: "Вариант 4 - Пользовательский",
		},
		logoTab: {
			title: "Логотип",
			fileUploadButton: "Нажмите чтобы загрузить",
			noFile: "Файл не выбран",
		},
		filesTab: {
			title: "Документы:",
			fileNameInput: "Название документа (доступно для всех посетителей)",
			chooseFileButton: "Выбрать файл",
			noDocAdded: "Документы не добавлены",
			fileError: "Пожалуйста, добавьте название документа",
		},
		linksTab: {
			social: {
				title: "Ссылки на Социальные сети",
				subtitle: "(видны всем участникам)",
				facebookInput: "Ваш Facebook профиль",
				linkedinInput: "Ваш LinkedIn профиль",
				twitterInput: "Ваш Twitter профиль",
			},
			other: {
				title: "Другие ссылки",
				subtitle: "(Домашняя страница или другие ссылки)",
				linkInput: "Введите новую ссылку",
				linkLabel: "Вставить название ссылки",
			},
		},
		avatarsTab: {
			mainRepresentatives: {
				title: "Представители Стенда",
				subtitle: "\n(минимум один представитель - обязательно)",
				leftRepresentative: "Представитель слева",
				rightRepresentative: "Представитель справа",
				addDialog: {
					title: "Добавить Аватар",
					contentFirst:
						"\nВыберите аватар из галереи ниже или загрузите свой собственный персональный аватар.",
					contentSecond: "\nНужна помощь в дизайне? Свяжитесь с нами:",
					female: "Женщина",
					male: "Мужчина",
					uploadSectionTitle:
						"Загрузите свой собственный персонализированный аватар",
					uploadButton: "Выбрать файл",
					noFile: "Выбрать файл",
					assignText:
						"Пожалуйста, назначьте ответственного участника мероприятия, который будет связан с вышеуказанным представителем.",
					input: "Искать по имени здесь",
					avatarLabels: {
						femaleA: "женщина A",
						femaleB: "женщина В",
						femaleC: "женщина С",
						maleA: "мужчина А",
						maleB: "мужчина В",
						maleC: "мужчина С",
					},
				},
			},
			additionalRepresentatives: {
				title: "Дополнительный представитель",
				subtitle: "\n(необязательно, будет отображаться вместе с аватаркой)",
				addDialog: {
					title: "Выбрать дополнительного представителя",
					titleScholar: "Choose Co-Author", //new translation
					assignText:
						"Начните поиск участника мероприятия, чтобы добавить его / ее в качестве представителя.",
					assignError:
						"\nПользователь уже добавлен в качестве представителя стенда",
					chooseAvatarTextFirst:
						"\nВыберите аватар из галереи ниже или загрузите свой собственный персональный аватар.",
					chooseAvatarTextSecond: "\nНужна помощь в дизайне? Свяжитесь с нами:",
					uploadText: "Загрузите свой собственный персонализированный аватар",
					chooseFileText: "Выбрать файл",
					noFile: "Файл не выбран",
				},
			},
			author: {
				title: "Presenting Author", // new translation
				subtitle:
					"Add only an author that registered to the event. You can add 1 author.", // new translation
				mainRepresentative: "Main representative", // new translation
			},
			coAuthors: {
				title: "Co-Authors", // new translation
				subtitle:
					"Add only co-authors that registered to the event. You can add up to 4 co-authors.", // new translation
			},
		},
		videoTab: {
			video: {
				title: "Видео Стенда",
				subtitle: "\n(Ссылка на видео - только Youtube)",
				input: "\nВведите ссылку на видео из Youtube",
			},
			image: {
				title: "Изображение",
				subtitle:
					"\n(Изображение отображается на вашем стенде, если вы не добавили ссылку на видео в разделе выше)",
				uploadButton: "Нажмите чтобы загрузить",
			},
		},
	},
	boothDashboard: {
		event: "Ивент",
		title: "Ваш Стенд",
		manage: "Управление",
		goToBoothButton: "Посмотреть стенд",
		previewNotAvailable:
			"Ваш стенд пока недоступен для предварительного просмотра.",
		statistics: "Статистика",
		usersNow: "Посетители сейчас",
		usersAllTime: "Посетители суммарно",
		linkClicks: "\nПереходы по ссылкам",
		documentViews: "Просмотры документов",
		videoViews: "Просмотры видео презентации",
		visitorsSectionTitle: "Посетители стенда",
		noVisitors: "В вашем стенде пока нет посетителей.",
		visitorsSearch: "Найти посетителей",
		actionType: "\nТИП ДЕЙСТВИЯ",
		showUsers: "\nПОКАЗАТЬ ПОЛЬЗОВАТЕЛЕЙ",
		ofUsers: "КОЛИЧЕСТВО ПОЛЬЗОВАТЕЛЕЙ",
	},
	polls: {
		polls: "ОПРОСЫ",
		poll: "ОПРОС ",
		noPolls: "Опросы еще не добавлены",
		pollProgress: "Опрос в процессе",
		pollClosed: "Опрос завершен",
		pollVoted: "проголосовавшие участники",
	},
	onDemandVideos: {
		sectionTitle: "ON-DEMAND", // new translation
		noOnDemandVideos: "No on demand videos were added yet.", // new translation
	},
	meetings: {
		meeting: "ВСТРЕЧА",
		meetings: "ВСТРЕЧА",
		createDialog: {
			createTitle: "Запрос о встрече с",
			inputDate: "Дата",
			inputStart: "Начало",
			inputEnd: "Конец",
			inputTimezone: "Часовой пояс",
			inputNote:
				"Примечание: с одним и тем же человеком одновременно может быть запланирована только одна встреча",
			meetingError: "Неверные дата и время. Проверьте!",
			buttonDisabled:
				"Вы можете назначить встречу после того, как начнете разговор!",
		},
		banner: {
			pending: "Приглашение для встречи",
			accepted: "Встреча назначена на ",
			progress: "Встреча в процессе",
			cancelTitle: "Отменить встречу?",
			cancelDescription: "Вы уверены, что хотите отменить встречу?",
			startCall: "ПОЗВОНИТЬ",
			joinCall: "ПРИСОЕДИНИТЬСЯ",
			pendingOwner: "Ожидающий запрос о встрече с",
			videoCallStarted: "Видео встреча началась",
		},
		newMeeting: "НОВАЯ ВСТРЕЧА",
		requestMeeting: "Запросить встречу с",
		startDate: "Дата начала",
		starts: "Начинается",
		ends: "Заканчивается",
		addMessageHere: "Добавить ваше сообщение здесь",
		note: "Примечание:  с конкретным человеком вы можете назначить только одну встречу. После завершения встречи (или отмены), можно  назначить следующую встречу.",
		create: "СОЗДАТЬ",
		requested: "Запрошено",
		confirmed: "Подтверждено",
		title: "Ваши встречи",
		meetingsInfo:
			"Встречи можно запланировать в чатах. Начните чат с пользователем, с которым хотите назначить встречу.",
	},
	landingPage: {
		animatedSectionButton: "НАЧАТЬ ПУТЕШЕСТВИЕ",
		topSectionHeaderPrimary: "\nСледующее поколение",
		topSectionHeaderSecondary: "онлайн меоприятий",
		carouselPresentationText: "Посмотреть",
		carouselPresentationTextAccent: "\nНОВЫЙ Neural Network",
		videoPresentationTextPrimary:
			"\nNeural Network - онлайн-мероприятия, которые ваши",
		videoPresentationTextAccent: "\nучастники будут любить.",
		videoPresentationTextSecondary: "Вот почему:",
		testimonialsTextPrimary: "\nОнлайн-мероприятия с 2015 года -",
		testimonialsTextAccent:
			"\nмы всегда сфокусированы на человеческих ценностях ",
		testimonialsTextSecondary: "…",
		cardsTextPrimary: "\nNeural Network создает",
		cardsTextAccent: "WOW",
		cardsTextSecondary: "для…",
		contactTextPrimary: "\nЗаинтересованы в организации онлайн-ивента?",
		contactTextAccent: "Свяжитесь с нами",
		contactTextSecondary: "",
		contactInputName: "Имя",
		contactInputEmail: "Электронная почта",
		contactInputPhone: "Номер мобильного телефона (необязательно)",
		contactInputMessage: "Сообщение",
		contactButton: "ОТПРАВИТЬ",
		carouselPresentationTextAccentTextFirst: "НОВЫЙ",
		videoPresentationTextPrimaryTextSecond:
			"\nонлайн-мероприятия, которые ваши",
		cardsTextPrimaryTextSecond: "приносит",
	},
	loginPlatform: {
		title: "ВОЙТИ",
		description:
			'После входа в систему вы будете перенаправлены на последнее мероприятие, на которое вы зарегистрировались. Вы можете легко переключаться между событиями, используя опцию "Мои события" в вашем Меню.',
		descriptionTextFirst:
			"После входа в систему вы будете перенаправлены на последний",
		descriptionTextSecond:
			"\nвы зарегистрировались на. Вы можете легко переключаться между",
		descriptionTextThird: "\nиспользуя опцию Мои события в вашем меню.",
	},
	skipped: {
		startVideoChat:
			"Я организовал видеовстречу. Пожалуйста, нажмите здесь, чтобы присоединиться.",
	},
	hubDropdownTitle: "МЕНЮ ЦЕНТРА",
	marketplace: {
		title: {
			default: "Торговая площадка",
			uppercased: "ТОРГОВАЯ ПЛОЩАДКА",
		},
		label: "ПРОДУКТЫ",
		addNew: "ДОЬАВИТЬ ",
		addNewProduct: "\nДобавить новый продукт",
		editProduct: "\nРедактировать продукт",
		noProducts: "\nТоваров пока нет",
		publish: "\nОПУБЛИКОВАТЬ",
		productTitle: "\nНазвание продукта",
		productDescription: "Описание продукта",
		productLink: "Ссылка на продукт",
		productImage: "ИЗОБРАЖЕНИЕ ПРОДУКТА",
		uploadHint:
			"Загрузите изображение, которое будет отображаться в качестве предварительного просмотра продукта. Рекомендуемое разрешение для лучшего качества изображения 600-600 px ",
		descriptionHint: "Текст описания ограничен до 300 символов",
		uploadImage: "\nЗАГРУЗИТЕ ИЗОБРАЖЕНИЕ, ЧТОБЫ ПОВЫШИТЬ ЦЕННОСТЬ ПРОДУКТА",
		uploaded: "Загружено",
	},
	personalAgenda: {
		agenda: "Повестка дня",
		myProgram: "МОЯ ПРОГРАММА",
		myMeetings: "МОИ ВСТРЕЧИ",
		addNewTask: {
			default: "Добавить новую задачу",
			uppercased: "ДОБАВИТЬ НОВУЮ ЗАДАЧУ",
		},
		noProgram: "На данный момент нет доступных записей программы",
		noMeetings: "Встреч на данный момент нет ",
		title: "Название ",
		startDate: "Дата начала",
		starts: "Начинается",
		ends: "Заканчивается",
		create: "СОЗДАТЬ",
		schedule: "НАЗНАЧИТЬ",
		personalAgenda: "ЛИЧНАЯ ПОВЕСТКА ДНЯ",
		timetableMenu: "МЕНЮ РАСПИСАНИЯ",
		gmt: "время по Гринвичу",
	},
	program: {
		toAgenda: "К ПОВЕСТКЕ",
		attending: "ПОСЕЩЕНИЕ",
		inPerson: "Лично",
		online: "Онлайн",
		searchWrapperTitle: "ПОИСК ПО ВСЕМ СЕССИЯМ",
		searchWrapperTitlePast: "ПОИСК ПО ВСЕМ ПРОШЕДШИМ СЕССИЯМ",
		searchWrapperTitleUpcoming: "ПОИСК ПО ВСЕМ ПРЕДСТОЯЩИМ СЕССИЯМ",
		searchWrapperTitleInterval: "ПОИСК ПО СЕССИЯМ",
	},
	businessCard: {
		businessCard: {
			default: "Визитка",
			uppercased: "ВИЗИТКА",
		},
		editText: "РЕДАКТИРОВАТЬ ТЕКСТ",
		colors: "ЦВЕТА",
		branding: "Брендинг",
		cardPreview: "ПРЕДВАРИТЕЛЬНЫЙ ПРОСМОТР ВИЗИТКИ",
		onceSaved:
			"После сохранения, визитная карточка всегда будет подключена к вашему профилю. Это позволит вам получить доступ к сети, чатам и другим важным функциям. При удалении профиля, карта будет удалена автоматически. ",
		required: "требуется",
		designation: "Должность",
		Mobile: "\nМобильный",
		Phone: "Телефон",
		Website: "Вебсайт",
		companyName: "Название Компании",
		honoric:
			"Добавьте ваши предпочтительные почетные звания, такие как мистер, миссис, сер, шейх и т. д.",
		fillUpData: "ЗАПОЛНИТЕ ДАННЫМИ С МОЕГО АККАУНТА",
		primaryColor: "ПЕРВИЧНЫЙ ЦВЕТ",
		primaryColorDescription:
			"Этот цвет является акцентом цвета. Используйте свой фирменный цвет ",
		backgroundColor: "ФОНОВЫЙ ЦВЕТ",
		backgroundColorDescription:
			"Этот цвет является дополнительным цветом. Добавьте его в качестве фона. По умолчанию он может быть белым или черным",
		backgroundTextColor: "\nЦВЕТ ФОНОВОГО ТЕКСТА И ИКОН",
		backgroundTextColorDescription:
			"Этот цвет для номеров телефонов, электронной почты и сведений о веб-сайте должен контрастировать с цветом фона. Перейдите в Предварительный просмотр карты, чтобы убедиться, что карта читается и имеет достаточный уровень контрастности.",
		companyLogo: "ЛОГОТИП КОМПАНИИ",
		uploadMessage:
			"Загрузите изображение, которое будет отображаться как логотип компании. Для лучшего качества изображения рекомендуемое разрешение 320 * 200 пикселей . Используйте логотип с прозрачным фоном (желательно формат файла .png)",
		uploadImage: "ЗАГРУЗИТЕ ИЗОБРАЖЕНИЕ ",
		request: "ЗАПРОС",
		open: "ОТКРЫТЬ  ",
		pending: "В ОЖИДАНИИ",
	},
	businessWallet: {
		short: "В КОШЕЛЕК",
		full: "ЬИЗНЕСС КОШЕЛЕК",
		myContacts: "МОИ КОНТАКТЫ",
		requests: "ЗАПРОСЫ",
		pendings: "В ОЖИДАНИИ",
		searchByName: "Поиск по Имени",
		chat: "Чат",
		openCard: "ОТКРЫТЬ ВИЗИТКУ",
		empty: "Здесь еще никого нет",
		cancelRequest: "ОТМЕНИТЬ ЗАПРОС",
		approve: "ПОДТВЕРДИТЬ",
		reject: "ОТКЛОНИТЬ",
		myNotes: "МОИ ЗАПИСИ",
		addNewNote: "Добавить новую запись",
		delete: "УДАЛИТЬ",
		saveThoughts: "\nСохраняйте свои мысли в одном месте",
	},
	videoConferences: {
		message:
			"Я создал кабинет для видеозвонка. Пожалуйста, нажмите здесь, чтобы присоединиться ",
		clickToStart: "Нажмите здесь, чтобы начать видеочат",
		fullScreen: "РАЗВЕРНУТЬ",
		minimizeScreen: "МИНИМИЗИРОВАТЬ ЭКРАН",
	},
	sessions: {
		sessionList: "ЛИСТ СЕССИЙ",
		createNewSession: {
			uppercased: "СОЗДАТЬ НОВУЮ СЕССИЮ",
			default: "Создать новубю сессию",
		},
		sessionName: {
			uppercased: "НАЗВАНИЕ СЕССИИ",
			default: "Название сессии",
		},
		sessionLink: "ССЫЛКА СЕССИИ",
		sessionType: "ТИП СЕССИИ",
		manage: "УПРАВЛЕНИЕ",
		edit: "РЕДАКТИРОВАТЬ",
		delete: "УДАЛИТЬ",
		live: {
			uppercased: "ПРЯМАЯ ТРАНСЛЯЦИЯ",
			lowercased: "прямая трансляция",
			default: "Прямая трансляция",
		},
		recorded: {
			uppercased: "ЗАПИСАНО",
			lowercased: "Записано",
			default: "Записано",
		},
		editSession: "Редактировать сессию",
		uploadVideo: "ЗАГРУЗИТЬ ВИДЕО",
		create: "СОЗДАТЬ",
		discard: "СБРОСИТЬ",
		speakers: "СПИКЕРЫ",
		moderators: "МОДЕРАТОРЫ",
		speakerName: "ИМЯ СПИКЕРА",
		moderatorName: "ИМЯ МОДЕРАТОРА",
		kick: "ТОЛКНУТЬ",
		screenSharing: "ДЕМОНСТРАЦИЯ ЭКРАНА",
		audio: "АУДИО",
		video: "ВИДЕО",
		startStream: "НАЧАТЬ СТРИМ",
		stopStream: "ОСТАНОВИТЬ СТРИМ",
		startRecording: "НАЧАТЬ ЗАПИСЬ",
		endRecording: "ЗАКОНЧИТЬ ЗАПИСЬ",
		sessionChat: "ЧАТ СЕССИИ",
		typeMessageHere: "Напишите сообщение здесь",
		sessionLinks: "ССЫЛКА СЕССИИ",
		speakerUrl: "URL Спикера ",
		moderatorUrl: "URL Модератора ",
		copyUrl: "Копировать URL",
		organizer: "ОРГАНИЗАТОР",
		moderator: "МОДЕРАТОРЫ",
		speaker: "СПИКЕР ",
		joinAsSpeaker: "Присоединиться к апикеру ",
		invitedAsSpeaker:
			"Вас пригласили в качестве спикера на демонстрацию AIM Сессии. Введите свое имя (оно будет видно участникам сеанса)",
		yourName: "Ваше имя",
		enter: "ВВОД",
		waitForApproval: "Пожалуйста, ожидайте подтверждение",
		deny: "ОТКЛОНИТЬ",
		admit: "ПРИНЯТЬ",
		joinAsModeraotr: "Присоеиняйтесь к нам в качестве модератора",
		invitedAsModerator:
			"Вас пригласили в качестве спикера на демонстрацию AIM Сессии. Введите свое имя (оно будет видно участникам сеанса)",
		declined: "Oppps, вам почему-то отказали",
	},
	breadcrumbsNavigation: {
		mainLobby: "Главное Лобби",
		village: "Деревня",
	},
	village: {
		clickToOpen: "нажмите, чтобы открыть",
	},
	gdpr: {},
};

export default ru;
