import React from 'react';
import '../../CSS/boothManagement.scss';
import '../../CSS/boothParticipantView.scss';
import * as actions from '../../store/actions/index';
import {connect} from 'react-redux';
import ExhibitorBooth from '../Event/ExhibitorBooth';
import FeaturedExhibitorsSlider from '../Showfloor/FeaturedExhibitorsSlider/FeaturedExhibitorsSlider';
import ExhibitorsOverlay from '../Showfloor/ExhibitorsOverlay/ExhibitorsOverlay';
import Wall from '../../HOC/Wall/Wall';
import ShowfloorNoExhibitors from '../../SmallLayoutComponents/ShowfloorNoExhibitors';
import DetectOutsideClick from '../../HOC/DetectOutsideClick';
import Spinner from '../../SmallLayoutComponents/Spinner';
import axios from '../../store/axios-instance';
import NotificationPresentationStarts from '../../Components/NotificationPresentationStarts';
import ExhibitorsOverlayMobile from '../Showfloor//ExhibitorsOverlayMobile';
import ExhibitorBoothMobile from '../Showfloor/ExhibitorBoothMobile';
import BreadcrumbsNavigation from '../../Components/BreadcrumbsNavigation';
import NoBuildingAccess from './NoBuildingAccess';
import {preventDefaultDrag} from '../../Utils/utils';

class BuildingShowfloor extends React.Component {
    state = {
        previousExhibitorLogoUrl: '',
        nextExhibitorLogoUrl: '',
        previousExhibitorCompany: '',
        nextExhibitorCompany: '',
        showExhibitorsOverlay: false,
        currentMobileTab: 'booth',
        currentSubTab: 'representatives',
    };

    componentDidMount() {
        this.props.onSetEventProtectedMenu();

        // make sure we close the top navigation when the user lands on this page
        this.props.closeTopNavigation();
        const {loadingExhibitors, eventId, buildingId} = this.props;

        // get the exhibitors
        if (!loadingExhibitors)
            this.props.onGetBuildingExhibitors(eventId, buildingId, this.getExhibitorPrivateBoothPreview());
    }

    componentWillUnmount() {
        // reset the active exhibitor ID when the user navigates to another page
        this.props.onSetActiveExhibitorId(null);
        clearTimeout(this.timeoutId);
        clearTimeout(this.timeoutId2);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loadingExhibitors && !this.props.loadingExhibitors) {
            // we got the event exhibitors
            // if we want to load an exact exhibitor, we access the query parameter and skip the random functionality
            let exhibitorId;
            if (this.props.location.search.length) {
                exhibitorId = this.props.location.search.split('?')[1].split('=')[1];
            }
            // if we also reloaded the page using a socket notification (the exhibitor changed something in the booth)
            // we don't want to reload the page with a random exhibitor
            if (this.props.activeExhibitorId) {
                exhibitorId = this.props.activeExhibitorId;
                // trigger the reload data event for that exhibitor
                this.props.onGetExhibitor(this.props.activeExhibitorId);
            }
            // if we have this exhibitorId in our array of EXHIBITORS we load it
            // else we load a random one from the FEATURED EXHIBITORS ARRAY
            // this way we make sure we don't break the app if the user tried to load an Id that doesn't exist
            let validExhibitorIdIndex = this.props.exhibitors.findIndex((exhibitor) => exhibitor._id === exhibitorId);
            if (validExhibitorIdIndex !== -1) {
                this.props.onSetActiveExhibitorId(exhibitorId);
            } else {
                // set up a random exhibitor from the featured ones and save it in the store
                let featuredExhibitorsLength = this.props.featuredExhibitors.length;
                if (featuredExhibitorsLength) {
                    let randomFeaturedExhibitorIndex = Math.floor(Math.random() * featuredExhibitorsLength);
                    let randomExhibitorId = this.props.featuredExhibitors[randomFeaturedExhibitorIndex]._id;
                    this.props.onSetActiveExhibitorId(randomExhibitorId);
                } else {
                    this.props.exhibitors[0] && this.props.onSetActiveExhibitorId(this.props.exhibitors[0]._id);
                }
            }
            if (this.props.eventUsers.participants && this.props.user) {
                const participantInformation = this.props.eventUsers.participants.find(
                    (participant) => participant.user._id === this.props.user._id
                );
                if (participantInformation && !participantInformation.showfloorInfoSeen) {
                    this.handleSeeInfoScreen();
                    this.timeoutId = setTimeout(this.showExhibitorsOverlay, 3000);
                }
            }
            if (this.props.exhibitors && this.props.user) {
                const exhibitorInformation = this.props.exhibitors.find(
                    (exhibitor) => exhibitor.user._id === this.props.user._id
                );
                if (exhibitorInformation && !exhibitorInformation.showfloorInfoSeen) {
                    const isExhibitor = true;
                    this.handleSeeInfoScreen(isExhibitor);
                    this.timeoutId2 = setTimeout(this.showExhibitorsOverlay, 3000);
                }
            }
        }
        if (prevProps.activeExhibitorId !== this.props.activeExhibitorId) {
            this.setActiveExhibitorSiblingLogos();
            this.hideExhibitorsOverlay();
        }
    }

    handleSeeInfoScreen = (isExhibitor) => {
        const {eventId} = this.props;
        axios({method: 'put', url: `/event/${eventId}/showfloor-info-seen`})
            .then((response) => {
                // this.props.onRefreshUserData();
                if (isExhibitor) {
                    this.props.onGetExhibitors(eventId, this.getExhibitorPrivateBoothPreview());
                } else {
                    this.props.onGetUsers(eventId);
                }
            })
            .catch((error) => {});
    };

    getCurrentExhibitorIndex = () => {
        return this.props.exhibitors.findIndex((exhibitor) => exhibitor._id === this.props.activeExhibitorId);
    };

    getPreviousExhibitorIndex = (currentExhibitorIndex) => {
        return currentExhibitorIndex === 0 ? this.props.exhibitors.length - 1 : currentExhibitorIndex - 1;
    };

    getNextExhibitorIndex = (currentExhibitorIndex) => {
        return currentExhibitorIndex === this.props.exhibitors.length - 1 ? 0 : currentExhibitorIndex + 1;
    };

    setActiveExhibitorSiblingLogos = () => {
        let currentExhibitorIndex = this.getCurrentExhibitorIndex();
        let previousExhibitorIndex = this.getPreviousExhibitorIndex(currentExhibitorIndex);
        let nextExhibitorIndex = this.getNextExhibitorIndex(currentExhibitorIndex);

        let previousExhibitor = this.props.exhibitors[previousExhibitorIndex];
        let nextExhibitor = this.props.exhibitors[nextExhibitorIndex];

        let previousExhibitorLogoUrl = previousExhibitor.filesUrl + previousExhibitor.booth.logo;
        let nextExhibitorLogoUrl = nextExhibitor.filesUrl + nextExhibitor.booth.logo;

        this.setState({
            previousExhibitorLogoUrl,
            nextExhibitorLogoUrl,
            previousExhibitorCompany: previousExhibitor.company,
            nextExhibitorCompany: nextExhibitor.company,
            previousExhibitorHasLogo: previousExhibitor.booth.logo,
            nextExhibitorHasLogo: nextExhibitor.booth.logo,
        });
    };

    previousExhibitor = () => {
        let currentExhibitorIndex = this.getCurrentExhibitorIndex();
        let previousExhibitorIndex = this.getPreviousExhibitorIndex(currentExhibitorIndex);
        let previousExhibitorId = this.props.exhibitors[previousExhibitorIndex]._id;
        this.props.onSetActiveExhibitorId(previousExhibitorId);
    };

    getExhibitorPrivateBoothPreview = () => {
        return this.props.allExhibitors.exhibitors.find(
            (exhibitor) =>
                exhibitor.user._id === this.props.user._id &&
                exhibitor.isPreviewEnabled &&
                exhibitor.status !== 'approved'
        );
    };

    nextExhibitor = () => {
        let currentExhibitorIndex = this.getCurrentExhibitorIndex();
        let nextExhibitorIndex = this.getNextExhibitorIndex(currentExhibitorIndex);
        let nextExhibitorId = this.props.exhibitors[nextExhibitorIndex]._id;
        this.props.onSetActiveExhibitorId(nextExhibitorId);
    };

    showExhibitorsOverlay = () => this.setState({showExhibitorsOverlay: true});

    hideExhibitorsOverlay = () => this.setState({showExhibitorsOverlay: false});

    handleChangeMobileTab = (tabName) => (e) => {
        this.setState({
            currentMobileTab: tabName,
        });
    };

    render() {
        const {event, resourcesAccess, eventRoles, isLargeScreen, buildingId, exhibitors} = this.props;
        const {currentMobileTab} = this.state;
        if (
            event.hasAccessManagement &&
            eventRoles.isParticipant &&
            resourcesAccess !== null &&
            !resourcesAccess.buildings.includes(buildingId)
        ) {
            return <NoBuildingAccess />;
        }

        // const showBooth = isLargeScreen || (!isLargeScreen && currentMobileTab === 'booth');
        const showWall = isLargeScreen || (!isLargeScreen && currentMobileTab === 'wall');

        return (
            <>
                <div
                    className={
                        'showfloor-page event-mobile-page ' +
                        (!this.props.user.showfloorInfoSeen ? 'show-info-screen' : '')
                    }
                    style={{height: '100vh'}}
                >
                    <BreadcrumbsNavigation />
                    {this.props.loadingExhibitors && <Spinner />}
                    {exhibitors.length ? (
                        <>
                            {isLargeScreen ? (
                                <div onDragStart={preventDefaultDrag} className="booth">
                                    {this.props.activeExhibitorId && (
                                        <ExhibitorBooth
                                            previousExhibitor={this.previousExhibitor}
                                            nextExhibitor={this.nextExhibitor}
                                            previousExhibitorLogo={this.state.previousExhibitorLogoUrl}
                                            previousExhibitorCompany={this.state.previousExhibitorCompany}
                                            previousExhibitorHasLogo={this.state.previousExhibitorHasLogo}
                                            nextExhibitorLogo={this.state.nextExhibitorLogoUrl}
                                            nextExhibitorCompany={this.state.nextExhibitorCompany}
                                            nextExhibitorHasLogo={this.state.nextExhibitorHasLogo}
                                        />
                                    )}
                                    <FeaturedExhibitorsSlider showExhibitorsOverlay={this.showExhibitorsOverlay} />
                                    <DetectOutsideClick
                                        hideExhibitorsOverlay={this.hideExhibitorsOverlay}
                                        target="exhibitorsOverlay"
                                    >
                                        <ExhibitorsOverlay
                                            hideExhibitorsOverlay={this.hideExhibitorsOverlay}
                                            showExhibitorsOverlay={this.state.showExhibitorsOverlay}
                                        />
                                    </DetectOutsideClick>
                                </div>
                            ) : (
                                <div onDragStart={preventDefaultDrag} className="booth-mobile-header">
                                    <ExhibitorsOverlayMobile
                                        hideExhibitorsOverlay={this.hideExhibitorsOverlay}
                                        openExhibitorsOverlay={this.showExhibitorsOverlay}
                                        showExhibitorsOverlay={this.state.showExhibitorsOverlay}
                                    />
                                    <div onDragStart={preventDefaultDrag} className="tabs-container">
                                        <div
                                            className={`button-tab tab ${currentMobileTab === 'booth' ? 'active' : ''}`}
                                            onClick={this.handleChangeMobileTab('booth')}
                                        >
                                            BOOTH
                                        </div>
                                        <div
                                            className={`button-tab tab ${currentMobileTab === 'wall' ? 'active' : ''}`}
                                            onClick={this.handleChangeMobileTab('wall')}
                                        >
                                            BOOTH WALL
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!isLargeScreen && currentMobileTab === 'booth' && this.props.activeExhibitorId && (
                                <ExhibitorBoothMobile />
                            )}
                            {showWall && (
                                <div onDragStart={preventDefaultDrag} className="booth-wall-container">
                                    <div onDragStart={preventDefaultDrag} className="booth-wall-header">
                                        <span>Booth Wall</span>
                                    </div>
                                    <Wall isBoothWall />
                                </div>
                            )}
                        </>
                    ) : (
                        <ShowfloorNoExhibitors />
                    )}
                </div>
                <NotificationPresentationStarts />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        loadingEvent: state.event.loading,
        event: state.event.data,
        eventUsers: state.eventUsers,
        user: state.user.data,
        eventRoles: state.user.eventRoles,
        resourcesAccess: state.user.resourcesAccess,
        exhibitors: state.exhibitors.approvedExhibitors,
        allExhibitors: state.exhibitors,
        loadingExhibitors: state.exhibitors.loading,
        featuredExhibitors: state.exhibitors.featuredExhibitors,
        activeExhibitorId: state.exhibitors.activeExhibitorId,
        activeChatRoomId: state.user.topNavigation.activeChatRoomId,
        isLargeScreen: state.layout.isLargeScreen,
        buildingId: state.building.buildingId,
        building: state.building.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetBuildingExhibitors: (eventId, buildingId, privatePreview) =>
            dispatch(actions.getBuildingExhibitors(eventId, buildingId, privatePreview)),
        onGetExhibitor: (exhibitorId) => dispatch(actions.getExhibitor(exhibitorId)),
        onGetUsers: (eventId) => dispatch(actions.getUsers(eventId)),
        onSetActiveExhibitorId: (exhibitorId) => dispatch(actions.setActiveExhibitorId(exhibitorId)),
        closeTopNavigation: () => dispatch(actions.topNavClose()),
        onRefreshUserData: () => dispatch(actions.refreshUserData()),
        onSetEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildingShowfloor);
