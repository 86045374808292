import React from 'react';
import Spinner from './Spinner';
import {withRouter} from 'react-router-dom';

const CustomizedSpinner = withRouter(({location}) => {
    return location.pathname.includes('/privacy-policy') && !location.pathname.includes('/event/') ? (
        <Spinner custom="lobby-spinner" />
    ) : location.pathname.includes('/cookies') && !location.pathname.includes('/event/') ? (
        <Spinner custom="lobby-spinner" />
    ) : location.pathname.includes('/terms-and-conditions') && !location.pathname.includes('/event/') ? (
        <Spinner custom="lobby-spinner" />
    ) : location.pathname.includes('/dashboard/settings') && location.pathname.includes('/event/') ? (
        <Spinner custom="organizer-spinner" />
    ) : location.pathname.includes('/dashboard/branding') && location.pathname.includes('/event/') ? (
        <Spinner custom="organizer-spinner" />
    ) : location.pathname.includes('/dashboard/buildings') && location.pathname.includes('/event/') ? (
        <Spinner custom="organizer-spinner-side-double" />
    ) : location.pathname.includes('/dashboard/auditoriums') && location.pathname.includes('/event/') ? (
        <Spinner custom="organizer-spinner" />
    ) : location.pathname.includes('/dashboard/program') && location.pathname.includes('/event/') ? (
        <Spinner custom="organizer-spinner" />
    ) : location.pathname.includes('/dashboard/showfloor') && location.pathname.includes('/event/') ? (
        <Spinner custom="organizer-spinner" />
    ) : location.pathname.includes('/dashboard/participants') && location.pathname.includes('/event/') ? (
        <Spinner custom="organizer-spinner" />
    ) : location.pathname.includes('/dashboard/statistics') && location.pathname.includes('/event/') ? (
        <Spinner custom="organizer-spinner" />
    ) : location.pathname.includes('/dashboard/newsletters/add-audience') &&
      location.pathname.includes('/dashboard/') ? (
        <Spinner custom="organizer-spinner" />
    ) : location.pathname.includes('/dashboard/newsletters/edit-audience') &&
      location.pathname.includes('/dashboard/') ? (
        <Spinner custom="organizer-spinner" />
    ) : location.pathname.includes('/dashboard/newsletters') && location.pathname.includes('/event/') ? (
        <Spinner custom="organizer-spinner" />
    ) : location.pathname.includes('/dashboard/archived-events') && location.pathname.includes('/event/') ? (
        <Spinner custom="organizer-spinner" />
    ) : location.pathname.includes('/dashboard/event-gdpr') && location.pathname.includes('/event/') ? (
        <Spinner custom="organizer-spinner" />
    ) : location.pathname.includes('/dashboard/event-payment') && location.pathname.includes('/event/') ? (
        <Spinner custom="organizer-spinner" />
    ) : location.pathname.includes('/lobby') ? (
        <Spinner custom="lobby-spinner" />
    ) : location.pathname.includes('/my-account') ? (
        <Spinner custom="lobby-spinner" />
    ) : location.pathname.includes('/showfloor') ? (
        <Spinner custom="lobby-spinner" />
    ) : location.pathname.includes('/auditorium') ? (
        <Spinner custom="lobby-spinner" />
    ) : location.pathname.includes('/auditorium-archive') ? (
        <Spinner custom="lobby-spinner" />
    ) : location.pathname.includes('/event-archive') ? (
        <Spinner custom="lobby-spinner" />
    ) : location.pathname.includes('/village') ? (
        <Spinner custom="lobby-spinner" />
    ) : location.pathname.includes('/exhibitor-dashboard') ? (
        <Spinner custom="lobby-spinner" />
    ) : location.pathname.includes('/exhibitor-booth-view') ? (
        <Spinner custom="lobby-spinner" />
    ) : location.pathname.includes('/exhibitor-booth-preview') ? (
        <Spinner custom="lobby-spinner" />
    ) : (
        <Spinner /> // login, register...and everything else that's not covered above
    );
});

export default CustomizedSpinner;
