import React, {PureComponent} from 'react';
import {getFormatedDate, getVideoIdFromYoutubeUrl, preventDefaultDrag} from '../../Utils/utils';
import {Link} from 'react-router-dom';
import {ReactComponent as PlayIcon} from '../../Images/svg/play.svg';
import callAxios from 'axios';
import {connect} from 'react-redux';
import Spinner from '../../SmallLayoutComponents/Spinner';

class EventArchiveVideoItem extends PureComponent {
    state = {
        thumbnailVimeoImage: null,
        spinner: false
    };

    componentDidMount() {
        this.setThumbnailImage();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.video !== this.props.video) {
            this.setThumbnailImage();
        }
    }

    setThumbnailImage = () => {
        const {video} = this.props;
        if (video.streamingEngine === 'Vimeo') {
            if (video?.url) {
                this.setState({
                    spinner: true
                });
                callAxios.get(`https://vimeo.com/api/oembed.json?url=${video?.url}`)
                    .then(res => {
                        this.setState({
                            thumbnailVimeoImage: `${res.data.thumbnail_url}.jpg`,
                            spinner: false
                        });
                    })
            }
        }
    };

    render() {
        const {spinner, thumbnailVimeoImage} = this.state;
        const {video, eventItem} = this.props;
        let imageURL = '';
        if(video.streamingEngine === 'Vimeo') {
            imageURL = thumbnailVimeoImage;
        } else {
            const videoId = getVideoIdFromYoutubeUrl(video.url);
            imageURL = `https://img.youtube.com/vi/${videoId}/0.jpg`;
        }
        return (
            <div
                onDragStart={preventDefaultDrag}
                className="archive-video"
                key={video._id}
            >
                <div
                    onDragStart={preventDefaultDrag}
                    className="video-preview-container"
                >
                    <Link
                        to={{
                            pathname:
                                '/event/' +
                                this.props.event.slug +
                                '/event-archive/event/' +
                                eventItem._id +
                                '/' +
                                video._id,
                        }}
                    >
                        <div
                            onDragStart={(e) =>
                                e.preventDefault()
                            }
                            className="clickable-element"
                        >
                            <div
                                onDragStart={(e) =>
                                    e.preventDefault()
                                }
                                className="image-preview-video"
                            >
                                {spinner && <Spinner/>}
                                <img
                                    src={imageURL}
                                    alt="preview video"
                                />
                                {/*remove d-none to show duration*/}
                                <div
                                    onDragStart={(e) =>
                                        e.preventDefault()
                                    }
                                    className="video-duration d-none"
                                >
                                    <span>40:16</span>
                                </div>
                                <div
                                    onDragStart={(e) =>
                                        e.preventDefault()
                                    }
                                    className="overlay"
                                />
                                <div
                                    onDragStart={(e) =>
                                        e.preventDefault()
                                    }
                                    className="play-button"
                                >
                                    <PlayIcon />
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div
                    onDragStart={preventDefaultDrag}
                    className="video-details-container"
                >
                    <div
                        onDragStart={(e) =>
                            e.preventDefault()
                        }
                        className="speakers-container"
                    >
                        {video.speakers.length
                            ? video.speakers.map(
                                (speaker, index) => {
                                    return (
                                        <span
                                            className="speaker"
                                            key={index}
                                        >
                                                {speaker}
                                            {index !== video.speakers.length - 1
                                                ? ', '
                                                : null}
                                            </span>
                                    );
                                }
                            )
                            : null}
                    </div>
                    <Link
                        to={{
                            pathname:
                                '/event/' +
                                this.props.event.slug +
                                '/event-archive/event/' +
                                eventItem._id +
                                '/' +
                                video._id,
                        }}
                    >
                        {video.title}
                    </Link>
                    <div
                        onDragStart={(e) =>
                            e.preventDefault()
                        }
                        className="program-date-hash"
                    >
                        <span
                            draggable="false"
                            className="date"
                        >
                            {eventItem.eventDate
                                ? getFormatedDate(
                                    eventItem.eventDate
                                )
                                : eventItem.eventDate}
                        </span>
                        <div>
                            {video.hashtags.length > 0 &&
                            video.hashtags[0].split(' ').map(
                                (hashtag) =>
                                    <span
                                        draggable="false"
                                        className="hashtag"
                                    >
                                    {hashtag}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
    };
};

export default connect(mapStateToProps)(EventArchiveVideoItem);