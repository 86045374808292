import {createTheme} from '@material-ui/core/styles';
import variables from './CSS/_variables.module.scss';
import infoGreen from './Images/info_yellow.png';
import {isMobile} from 'react-device-detect';

const themeObj = createTheme({
    palette: {
        primary: {
            main: variables.secondary,
        },
        secondary: {
            main: variables.primary,
        },
        error: {
            main: variables.error,
        },
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                maxWidth: `${isMobile ? '300px' : '500px'}`,
                fontFamily: variables.fontFamily,
                fontSize: '12px',
                padding: '8px 8px 8px 40px',
                borderRadius: '4px',
                border: `1px solid ${variables.primary}`,
                lineHeight: '13.43px',
                color: variables.secondary,
                fontWeight: '500',
                filter: 'drop-shadow(0px 14px 14px rgba(32, 15, 54, 0.25))',
                background: `url(${infoGreen})`,
                backgroundColor: 'white',
                backgroundPosition: '10px center',
                backgroundRepeat: 'no-repeat',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& svg': {
                    marginRight: '8px',
                },
            },
            arrow: {
                color: variables.primary,
                '&::before': {
                    border: `2px solid ${variables.primary}`,
                },
            },
            button: {backgroundColor: 'red'},
            touch: {
                padding: '8px 8px 8px 40px',
            }
        },
    },
});

export default themeObj;
