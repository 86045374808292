import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';

const initialState = {
    loading: false,
    exhibitors: [],
    featuredExhibitors: [],
    approvedExhibitors: [],
    activeExhibitorId: null,
};

const reducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.GET_EXHIBITORS_START:
                draft.loading = true;
                break;

            case actionTypes.GET_EXHIBITORS_SUCCESS:
                let approvedExhibitors = action.payload.filter((exhibitor) => exhibitor.status === 'approved');
                let featuredExhibitors = approvedExhibitors.filter((exhibitor) => exhibitor.isFeatured);
                draft.loading = false;
                draft.exhibitors = action.payload;
                draft.featuredExhibitors = featuredExhibitors;
                draft.approvedExhibitors = approvedExhibitors;
                break;

            case actionTypes.GET_EXHIBITORS_FAIL:
                draft.loading = false;
                break;

            case actionTypes.SET_ACTIVE_EXHIBITOR_ID:
                draft.activeExhibitorId = action.payload;
                break;


            default:
                break;
        }
    });

export default reducer;
