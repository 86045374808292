import React from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import '../CSS/headerMenu.scss';
import {connect} from 'react-redux';
import * as actions from '../store/actions';
import {preventDefaultDrag} from '../Utils/utils';
import AdminLogo from '../SmallLayoutComponents/AdminLogo';

class AdminMenu extends React.Component {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            mobileMenuVisible: false,
            headerScrolled: false,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('touchstart', this.handleClickOutside);
        window.addEventListener('scroll', this.setHeaderScrolled);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {mobileMenuVisible} = this.state;
        if (prevProps.location.pathname !== window.location.pathname && mobileMenuVisible) {
            this.collapseMenu();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('touchstart', this.handleClickOutside);
        window.removeEventListener('scroll', this.setHeaderScrolled);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                mobileMenuVisible: false,
            });
        }
    }

    setHeaderScrolled = () => {
        if (window.scrollY > 0) {
            if (this.state.headerScrolled === false) {
                this.setState({headerScrolled: true});
            }
        } else {
            if (this.state.headerScrolled === true) {
                this.setState({headerScrolled: false});
            }
        }
    };

    collapseMenu = () => {
        this.setState({mobileMenuVisible: false});
    };

    toggleMenu = () => {
        this.setState({mobileMenuVisible: !this.state.mobileMenuVisible});
    };

    onHandleLogout = (e) => {
        e.preventDefault();
        this.props.onLogoutUser();
    };

    render() {
        let topLinksContainerStyle = {height: '55px'};
        if (!this.state.mobileMenuVisible) {
            topLinksContainerStyle = {
                height: '0',
            };
        }
        return (
            <div onDragStart={preventDefaultDrag} className="superadmin-dashboard">
                <header className={'outside-event-header ' + this.props.className}>
                    <div
                        onDragStart={preventDefaultDrag}
                        className={'menu-wrapper ' + (this.state.headerScrolled ? 'scrolled' : '')}
                    >
                        <div onDragStart={preventDefaultDrag} className="brand-logo">
                            <Link to={{pathname: '/'}}>
                                <AdminLogo colored={!this.state.headerScrolled} />
                            </Link>
                        </div>
                        <div onDragStart={preventDefaultDrag} className="menu-links">
                            <Button
                                type="button"
                                classes={{root: 'homepage-logout-button'}}
                                onClick={this.onHandleLogout}
                            >
                                <span>Log out</span>
                            </Button>
                        </div>
                    </div>
                    <div
                        className={'mobile-menu-wrapper ' + (this.state.mobileMenuVisible ? 'opened' : '')}
                        ref={this.setWrapperRef}
                    >
                        <div
                            onDragStart={preventDefaultDrag}
                            className="top-links-container"
                            style={topLinksContainerStyle}
                        />
                        <div onDragStart={preventDefaultDrag} className="bottom-links-container">
                            <div
                                onDragStart={preventDefaultDrag}
                                className="button-container"
                                onClick={this.toggleMenu}
                            >
                                <div onDragStart={preventDefaultDrag} className="menu-bar" />
                                <div onDragStart={preventDefaultDrag} className="menu-bar" />
                                <div onDragStart={preventDefaultDrag} className="menu-bar" />
                            </div>
                            <div onDragStart={preventDefaultDrag} className="menu-links">
                                <div onDragStart={preventDefaultDrag} className="menu-links">
                                    <Button
                                        type="button"
                                        classes={{root: 'homepage-logout-button'}}
                                        onClick={this.onHandleLogout}
                                    >
                                        <span>Log out</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        user: state.user.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogoutUser: () => dispatch(actions.logoutUser()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminMenu));
