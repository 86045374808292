import React, {PureComponent} from 'react';
import {Button, CircularProgress} from '@material-ui/core';
import {preventDefaultDrag} from '../../Utils/utils';
import CloseIcon from '@material-ui/icons/Close';
import MatchingParticipantQuestion from '../../Components/EventManagement/Matching/MatchingParticipantQuestion';
import {ReactComponent as SaveIcon} from '../../Images/svg/save.svg';
import colors from '../../CSS/_variables.module.scss';
import Confirm from '../../Dialogs/Confirm';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

class AccountMatching extends PureComponent{
    render() {
        const {
            reference,
            eventMatching,
            loadingMatchingAnswers,
            answers,
            matchingDetailsPrecompleted,
            submitMatchingAnswersDisabled,
            submitMatchingAnswersRequired,
            openConfirmUnsavedChanges,
            confirmRemoveMatchingData,
            hasMatchingEnabled,
            matchingFormSeen,
            handleAddChangeAnswers,
            openConfirmRemoveMatchingData,
            handleSubmitAnswers,
            closeConfirmRemoveMatchingData,
            handleRemoveMatchingData,
            closeClickOutside,
            translation,
            defaultTranslation
        } = this.props;

        return (
            <>
                {hasMatchingEnabled &&
                matchingFormSeen &&
                (loadingMatchingAnswers || !matchingDetailsPrecompleted ? (
                    <div className="matching-loading">
                        <CircularProgress color="primary" />
                    </div>
                ) : (
                    <div className="scroll-left-container">
                        <div>
                            <div
                                ref={reference}
                                onDragStart={preventDefaultDrag}
                                className="edit-section-container event-matching"
                            >
                                <div onDragStart={preventDefaultDrag} className="matching-section-header">
                                    <h3>
                                        {translation?.matching?.editMatchingAnswers ||
                                        defaultTranslation?.matching?.editMatchingAnswers}
                                    </h3>
                                    <Button
                                        type="button"
                                        classes={{label: 'dialog-btn-uppercase'}}
                                        disableFocusRipple
                                        onClick={openConfirmRemoveMatchingData}
                                    >
                                        <CloseIcon />
                                        {translation?.matching?.rejectMatchingBtn ||
                                        defaultTranslation?.matching?.rejectMatchingBtn}
                                    </Button>
                                </div>
                                <div className="edit-user-personal-info-container">
                                    <div className="edit-form-matching">
                                        <div className={`matching-questions-container ${submitMatchingAnswersRequired ? 'error' : ''}`}>
                                            {matchingDetailsPrecompleted &&
                                            !!eventMatching.questions &&
                                            eventMatching?.questions?.map((question, questionIndex) => {
                                                    return (
                                                        <MatchingParticipantQuestion
                                                            key={questionIndex}
                                                            isMyAccount
                                                            answers={answers}
                                                            question={question}
                                                            questionIndex={questionIndex}
                                                            handleChange={handleAddChangeAnswers}
                                                        />
                                                    );
                                                }
                                            )}
                                        </div>
                                        {submitMatchingAnswersRequired && (
                                            <span className="matching-required-error">
                                                {translation?.matching?.matchingRequiredError ||
                                                defaultTranslation?.matching?.matchingRequiredError}
                                            </span>
                                        )}
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            classes={{root: 'restyled-save-button', label: 'dialog-btn-uppercase'}}
                                            disableFocusRipple
                                            disabled={submitMatchingAnswersDisabled || submitMatchingAnswersRequired}
                                            onClick={handleSubmitAnswers}
                                        >
                                            <SaveIcon
                                                fill={
                                                    submitMatchingAnswersDisabled || submitMatchingAnswersRequired
                                                        ? colors.muiDisabled
                                                        : colors.dark
                                                }
                                            />
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {confirmRemoveMatchingData && (
                    <Confirm
                        open={confirmRemoveMatchingData}
                        closeConfirm={closeConfirmRemoveMatchingData}
                        dialogTitle={
                            translation?.matching?.rejectMatchingTitle ||
                            defaultTranslation?.matching?.rejectMatchingTitle
                        }
                        dialogDescription={
                            translation?.matching?.rejectMatchingText ||
                            defaultTranslation?.matching?.rejectMatchingText
                        }
                        dialogCancelButtonLabel={
                            translation?.generalText?.cancel || defaultTranslation?.generalText?.cancel
                        }
                        dialogConfirmButtonLabel={
                            translation?.contactsDropdown?.rejectButton ||
                            defaultTranslation?.contactsDropdown?.rejectButton
                        }
                        handleConfirm={handleRemoveMatchingData}
                    />
                )}
                {openConfirmUnsavedChanges && (
                    <Confirm
                        open={openConfirmUnsavedChanges}
                        closeConfirm={closeClickOutside}
                        dialogTitle={
                            translation?.generalText?.unsavedChangesTitle ||
                            defaultTranslation?.generalText?.unsavedChangesTitle
                        }
                        dialogDescription={
                            translation?.generalText?.unsavedChangesText ||
                            defaultTranslation?.generalText?.unsavedChangesText
                        }
                        dialogConfirmButtonLabel={
                            translation?.generalText?.save || defaultTranslation?.generalText?.save
                        }
                        dialogCancelButtonLabel={
                            translation?.generalText?.cancel || defaultTranslation?.generalText?.cancel
                        }
                        handleConfirm={handleSubmitAnswers}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        eventRoles: state.user.eventRoles,
        event: state.event.data,
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        eventUsers: state.eventUsers,
        eventMatching: state.organizer.eventMatching,
        loadingMatchingAnswers: state.user.loadingGetMatches,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountMatching));