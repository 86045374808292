import React from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import '../CSS/headerMenu.scss';
import {connect} from 'react-redux';
import * as actions from '../store/actions';
import Button from '@material-ui/core/Button';
import {preventDefaultDrag} from '../Utils/utils';

class RegisterMenuLinks extends React.Component {
    state = {
        mobileMenuVisible: false,
        headerScrolled: false,
        translation: null,
        menuLinks: [],
    };

    setMenuLinks = () => {
        const {eventSlug, loggedIn, eventInfo} = this.props;
        let menuLinks = [
            {
                name: 'participantRegistration',
                labelTranslation: 'participantRegistration',
                pathname: `/event/${eventSlug}/register`,
                searchParam: '?registerAsParticipant=true',
                isActive: false,
                isVisible: true,
                class: 'participant',
            },
            {
                name: 'exhibitorRegistration',
                labelTranslation: 'exhibitorRegistration',
                pathname: `/event/${eventSlug}/register`,
                searchParam: '?registerAsExhibitor=true',
                isActive: false,
                isVisible: true,
                class: 'exhibitor',
            },
            {
                name: 'posterRegistration',
                labelTranslation: 'scholarRegistration',
                pathname: `/event/${eventSlug}/register`,
                searchParam: '?registerAsScholar=true',
                isActive: false,
                isVisible: true,
                class: 'poster',
            },
        ];
        if (loggedIn) {
            let posterRegistrationLink = menuLinks.find((pathname) => pathname.name === 'posterRegistration');
            posterRegistrationLink.searchParam = '';
            posterRegistrationLink.pathname = `/event/${eventSlug}/scholar-registration`;

            let exhibitorRegistrationLink = menuLinks.find((pathname) => pathname.name === 'exhibitorRegistration');
            exhibitorRegistrationLink.searchParam = '';
            exhibitorRegistrationLink.pathname = `/event/${eventSlug}/exhibitor-registration`;

            let participantRegistrationLink = menuLinks.find((pathname) => pathname.name === 'participantRegistration');
            participantRegistrationLink.searchParam = '';
            participantRegistrationLink.pathname = `/event/${eventSlug}/participant-registration`;
        }

        menuLinks.forEach((menuLink) => {
            const {location} = this.props;
            // hide the link if you are on that page
            const locationFullUrl = `${location.pathname}${location.search}`;
            const menuLinkFullUrl = `${menuLink.pathname}${menuLink.searchParam}`;
            if (locationFullUrl === menuLinkFullUrl) {
                menuLink.isVisible = false;
            }
        });

        // let loginLink = menuLinks.find((pathname) => pathname.name === 'login');
        // // we always "hide the loginLink if the user is loggedIn, "show" the loginLink if the user is not loggedIn
        // loginLink.isVisible = !loggedIn;

        if (!eventInfo?.scholarExhibitorsType) {
            let posterRegistrationLink = menuLinks.find((pathname) => pathname.name === 'posterRegistration');
            posterRegistrationLink.isVisible = false;
        }

        if (!eventInfo?.companyExhibitorsType) {
            let exhibitorRegistrationLink = menuLinks.find((pathname) => pathname.name === 'exhibitorRegistration');
            exhibitorRegistrationLink.isVisible = false;
        }

        this.setState({
            menuLinks: menuLinks,
        });
    };

    componentDidMount() {
        this.setLanguagesData();
        this.setMenuLinks();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.languages.eventLanguage !== this.props.languages.eventLanguage) {
            this.setLanguagesData();
        }

        if (
            prevProps.location.pathname !== window.location.pathname ||
            prevProps.location.search !== window.location.search ||
            prevProps.loggedIn !== this.props.loggedIn
        ) {
            this.setMenuLinks();
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.eventLanguage];
        this.setState({
            translation: translation,
        });
    };

    onHandleLogout = (e) => {
        e.preventDefault();
        this.props.onLogoutUser();
    };

    handleChangeLanguage = (e) => {
        const selectedLanguage = e.target.value;
        this.props.onSetEventLanguage(selectedLanguage);
    };

    render() {
        const {translation, menuLinks} = this.state;
        const {loggedIn, platform} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} className="links-register-pages">
                <div onDragStart={preventDefaultDrag} className="menu-links">
                    {loggedIn && (
                        <Button type="button" classes={{root: 'homepage-logout-button'}} onClick={this.onHandleLogout}>
                            <span>{translation?.menu.logOut}</span>
                        </Button>
                    )}
                    {platform ? (
                        <>
                            {!loggedIn && (
                                <>
                                    {this.props.location.pathname === `/login` ? null : (
                                        <Link
                                            to={{pathname: '/login'}}
                                            className={
                                                'login ' + (this.props.location.pathname === '/login' ? 'active' : '')
                                            }
                                        >
                                            <span>Log in</span>
                                        </Link>
                                    )}
                                    <Link
                                        to={{pathname: '/register'}}
                                        className={
                                            'register ' + (this.props.location.pathname === '/register' ? 'active' : '')
                                        }
                                    >
                                        <span>ORGANIZE EVENT</span>
                                    </Link>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {/*{menuLinks.map((menuLink) => {*/}
                            {/*    if (!menuLink.isVisible) {*/}
                            {/*        return null;*/}
                            {/*    }*/}
                            {/*    return (*/}
                            {/*        <Link*/}
                            {/*            key={menuLink.name}*/}
                            {/*            to={{*/}
                            {/*                pathname: menuLink.pathname,*/}
                            {/*                search: menuLink.searchParam,*/}
                            {/*            }}*/}
                            {/*            className={menuLink.class}*/}
                            {/*        >*/}
                            {/*            <span>{translation?.menu[menuLink.labelTranslation]}</span>*/}
                            {/*        </Link>*/}
                            {/*    );*/}
                            {/*})}*/}
                        </>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        eventSlug: state.event.eventSlug,
        languages: state.languages,
        eventInfo: state.event.eventInfo,
        isMobile: state.layout.isMobile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogoutUser: () => dispatch(actions.logoutUser()),
        onSetEventLanguage: (languageCode) => dispatch(actions.setEventLanguage(languageCode)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterMenuLinks));
