const pt = {
	generalText: {
		upload: "Enviar",
		save: "Salvar",
		add: "Adicionar",
		select: "Selecionar",
		remove: "Remover",
		close: "FECHAR",
		update: "ATUALIZAR",
		cancel: "CANCELAR",
		confirm: "Confirmar",
		download: "Baixar",
		refresh: "Atualizar",
		manage: "Gerenciar",
		back: "Voltar",
		submit: "Enviar",
		send: "Enviar",
		eventMO: "onvento",
		eventETX: "evento",
		eventsMO: "onventos",
		eventsETX: "eventos",
		eventUpperMO: "Onvento",
		eventUpperETX: "Evento",
		hide: "HIDE", //new translation: HIDE
	},
	time: {
		justNow: "agora mesmo",
		minute: "minuto",
		minutes: "minutos",
		hour: "hora",
		hours: "horas",
		day: "dia",
		days: "dias",
		month: "mês",
		months: "meses",
		yesterday: "ontem",
		today: "hoje",
		tomorrow: "amanhã",
		monday: "segunda-feira",
		tuesday: "terça-feira",
		wednesday: "quarta-feira",
		thursday: "quinta-feira",
		friday: "sexta-feira",
		saturday: "sábado",
		sunday: "domingo",
		mondayShort: "seg",
		tuesdayShort: "ter",
		wednesdayShort: "qua",
		thursdayShort: "qui",
		fridayShort: "sex",
		saturdayShort: "sab",
		sundayShort: "dom",
		january: "janeiro",
		february: "fevereiro",
		march: "março",
		april: "abril",
		may: "mai",
		june: "junho",
		july: "julho",
		august: "agosto",
		september: "setembro",
		october: "outubro",
		november: "novembro",
		december: "dezembro",
		januaryShort: "jan",
		februaryShort: "fev",
		marchShort: "mar",
		aprilShort: "abr",
		mayShort: "mai",
		juneShort: "jun",
		julyShort: "jul",
		augustShort: "ago",
		septemberShort: "set",
		octoberShort: "out",
		novemberShort: "nov",
		decemberShort: "dez",
	},
	errors: {
		noFile: "Nenhum arquivo selecionado",
		required: "O campo é obrigatório",
		emailNotValid: "E-mail inválido",
		passwordMinLength: "A senha deve ter no mínimo 8 caracteres",
		passwordMismatch: "Senha incorreta",
		passwordIsSame: "Your new password must be different", //new translation
		fileToLarge: "Arquivo é muito grande. Tamanho máximo do arquivo é 2 MB.",
		fileToLargeTextFirst: "Arquivo é muito grande.",
		fileToLargeTextSecond: "tamanho máximo do arquivo.",
		agreeToGDPR: "Concorde com a política de privacidade",
		noInvitationCode: "Por favor insira o seu código de convite",
		invitationCodeInvalid: "O código do convite é inválido.",
		imageFileNotSupported:
			"Tipo de arquivo não suportado. Use um dos seguintes:",
		phoneInvalid: "Insira um número de telefone válido. Por ex. +447517663928",
		linkInvalid: "Informe um link válido (https://site.com)",
		facebookLinkInvalid: "Informe um link válido (https://facebook.com/)",
		linkedinLinkInvalid: "Informe um link válido (https://linkedin.com/)",
		twitterLinkInvalid: "Informe um link válido (https://twitter.com/)",
		youtubeLinkInvalid: "Informe um link válido (https://www.youtube.com/)",
		connectionProblemTextFirst:
			"Parece haver um problema de conexão. Por favor",
		connectionProblemButton: "CLIQUE",
		connectionProblemTextSecond: "ou atualize a página.",
		fileTooLarge: "Arquivo é muito grande.Tamanho máximo de arquivo é 2 MB.",
		fileTooLargeTextFirst: "Arquivo é muito grande.",
		fileTooLargeTextSecond: "tamanho máximo de arquivo.",
	},
	footer: {
		cookiesText:
			"Este site utiliza cookies essenciais, que são necessários ao seu funcionamento. Ele também utiliza cookies de terceiros, necessários para o funcionamento de algumas funcionalidades do nosso site. Se quiser saber mais ou suspender o seu consentimento para alguns cookies, consulte a nossa",
		cookiesLink: "política de cookies",
		cookiesButton: "ACEITAR",
		privacyPolicy: "Política de privacidade",
		cookies: "Cookies",
		termsAndConditions: "Termos e condições",
		socialLinksTitle: "Siga-nos:",
	},
	menu: {
		logIn: "ENTRAR",
		logOut: "SAIR",
		scholarRegistration: "Poster / Study Registration", // new translation
		exhibitorRegistration: "Ser um expositor", // new translation modification (Be Exhibitor => Exhibitor Registration)
		participantRegistration: "REGISTRO", // new translation modification (Register => Participant Registration)
		createEvent: "Organizar evento",
		launchText:
			"Lançamento em setembro de 2020 - aberto para acesso antecipado",
	},
	externalIdTranslations: {
		headline: "Você está registrado",
		subHeadlineOne: "A sessão em ",
		subHeadlineTwo: "agora está no seu calendário de Rede Neural!",
		button: "VER PROGRAMA",
		checkCalendar:
			'Verifique "<strong>My Calendar</strong>" na aba "<strong>Program</strong>" para sessões futuras e explore centenas de sessões passadas usando os filtros de pesquisa. E não perca as "<strong>Smart Suggestions</strong>" para conteúdo selecionado especialmente para você!',
	},

	homepage: {
		organizedBy: "ORGANIZADO POR",
		date: "DATA",
		location: "DOMÍNIO E LOCALIZAÇÃO",
		price: "PREÇO",
		tabIntro: "INTRODUÇÃO",
		tabIntroProgramButton: "PROGRAMA",
		tabProgram: "PROGRAMA",
		tabProgramIntroButton: "INTRODUÇÃO",
		tabPartners: "PARCEIROS",
		tabExhibitors: "EXPOSITORES",
		previousEventsSectionTitle: "EVENTOS ANTERIORES",
		tabSocialMedia: "REDES SOCIAIS",
	},
	socialButtons: {
		linkedinButton: "Entrar com o LinkedIn",
		facebookButton: "Entrar com o Facebook",
		googleButton: "Entrar com o Google",
		twitterButton: "Entrar com o Twitter",
	},
	login: {
		orText: "ou",
		inputEmail: "E-mail",
		inputPassword: "Senha",
		rememberMeButton: "Lembrar de mim",
		forgotPasswordButton: "Esqueceu a senha?",
		loginButton: "ENTRAR",
		noAccountText: "Não tem uma conta?",
		registerButton: "Registre-se aqui",
		recoverPasswordDialogTitle: "RECUPERAR SENHA",
		recoverPasswordDialogInputEmail: "E-mail",
		recoverPasswordDialogCloseButton: "FECHAR",
		recoverPasswordDialogRecoverButton: "RECUPERAR",
		errorNotRegistered: "Não registrado",
		errorUnableToLogin: "Não foi possível fazer o login",
	},
	register: {
		formTitle: "Ou crie seu perfil onvent",
		inputFirstName: "Nome",
		inputLastName: "Sobrenome",
		inputEmail: "E-mail",
		inputPassword: "Senha",
		inputConfirmPassword: "Confirmar senha",
		uploadImageText: "Carregar imagem de perfil (2 MB):",
		editImageText: "Editar imagem do perfil (2 MB):",
		gdprTextFirst: "Ao prosseguir, você concorda com nossos",
		gdprTextTerms: "Termos de uso",
		gdprTextSecond: "e confirma que você leu nosso",
		gdprTextCookies: "Cookie",
		gdprTextThird: "e",
		gdprTextPrivacy: "Política de privacidade",
		gdprTextAgree: "Concorde com a política de privacidade",
		registerButton: "Registrar-se",
		haveAccountText: "Já tem uma conta?",
		haveAccountLoginButton: "Entre aqui",
		errorAlreadyHaveAccount: "Já registrado.",
		formTitleTextFirst: "Ou crie seu",
		formTitleTextSecond: "perfil",
		invitationCode: "Código de Convite",
	},
	participantRegistration: {
		choosePackageTitle: "ESCOLHA O PACOTE DE ACESSO",
		noPackageText: "Por favor, selecione um Pacote",
		gdprTextFirst: "Ao prosseguir, você concorda com nossos",
		gdprTextPrivacy: "Política de privacidade",
		gdprTextSecond: "e",
		gdprTextTerms: "Termos e Condições do Evento",
		gdprTextAgree: "Concorde com a política de privacidade",
		registerButtonFree: "Finalizar registro",
		registerButtonPay: "Prossiga para o pagamento",
	},
	exhibitorRegistration: {
		choosePackageTitle: "ESCOLHA O PACOTE DE ACESSO",
		gdprTextFirst: "Ao prosseguir, você concorda com nossos",
		gdprTextPrivacy: "Política de privacidade do evento",
		gdprTextSecond: "e",
		gdprTextTerms: "Termos e Condições do Evento",
		gdprTextAgree: "Concorde com a política de privacidade",
		registerButtonFree: "Finalizar registro",
		registerButtonPay: "Prossiga para o pagamento",
	},
	stripePayment: {
		cardNumber: "Número do cartão",
		expirationDate: "Data de Vencimento (MM/AA)",
		cvc: "Código de Segurança",
		disclaimerText:
			"Esta operação de pagamento é criptografada de ponta a ponta. Segurança e privacidade são garantidas por Stripe Services.",
		disclaimerTextLink: "Clique aqui para saber mais.",
		cancelButton: "CANCELAR",
		payButton: "Pagamento",
	},
	recoverPassword: {
		recoverTitle: "RECUPERAR SENHA",
		cancelButton: "FECHAR",
		recoverButton: "RECUPERAR",
		recoverTextSend: "E-mail de recuperação de senha enviado com sucesso.",
	},
	unsubscribe: {
		unsubscribeTitle: "Cancelar assinatura?",
		unsubscribeHomepage: "Ir para a página inicial",
	},
	notAvailable: {
		notAvailableTitle: "",
		notAvailableSubtitle: "",
		notAvailableTitleTextSecond: "está indisponível",
		notAvailableSubtitleTextFirst: "O",
		notAvailableSubtitleTextSecond:
			"que você está buscando não está disponível. Ele foi fechado pelo organizador ou desativado por um administrador",
		closedTitle: "fechado agora",
		closedSubtitleTextFirst: "Você se registrou com sucesso para este",
		closedSubtitleTextSecond: "está fechado atualmente",
	},
	notFound: {
		notFoundDescription: "Opa ... Nenhum evento pode ser encontrado aqui!",
		notFoundHomepage: "Ir para a página inicial",
	},
	eventMenu: {
		organizerButton: "ORGANIZADOR",
		exhibitorButton: "EXPOSITOR",
		boothButton: "ESTANDE",
		boothsButton: "ESTANDES",
		homeButton: "PÁGINA INICIAL",
		contactsButton: "CONTATOS",
		meetingsButton: "REUNIÕES",
		chatsButton: "CHATS",
		programButton: "PROGRAMA",
		archiveButton: "ARQUIVO",
		myAccountButton: "MINHA CONTA",
		lobbyButton: "Lobby",
		roomsButton: "SALAS",
		connectionProblemTextFirst: "Dostępne są nowe aktualizacje. Kliknij proszę",
		connectionProblemButton: "AQUI",
		connectionProblemTextSecond: "ou atualize a página.",
		sessionProblemTextFirst: "Sua sessão expirou. Por favor clique",
		sessionProblemButton: "AQUI",
		sessionProblemTextSecond: "para fazer o login novamente.",
		backButton: "VOLTAR",
		hubButton: "CENTRAL",
		metaverse: "METAVERSO",
		floorPlan: "PLANTA BAIXA",
	},
	myEventsDropdown: {
		title: "MEUS EVENTOS",
		createButtonDesktop: "Criar Evento",
		createButtonMobile: "CRIAR",
	},
	myBoothsDropdown: {
		title: "MEUS ESTANDES",
	},
	contactsDropdown: {
		title: "SEUS CONTATOS",
		requestsTitle: "Solicitações de contato",
		rejectButton: "REJEITAR",
		acceptButton: "ACEITAR",
		pendingButton: "Pendente",
		addButton: "Adicionar",
		noContacts:
			"Você ainda não tem contatos Visite a seção de Networking para começar a construir sua lista de contatos.",
		noScannedContacts: "Você ainda não tem nenhum contato escaneado.",
	},
	meetingsDropdown: {
		meetingWith: "Reunião com",
		pending: "Pendente",
		accepted: "Aceitada",
		canceled: "Cancelada",
		noMeetings: "Nenhuma reunião agendada ainda",
		finished: "Terminado",
	},
	programDropdown: {
		timeZone: "Fuso horário:",
		with: "Com",
		noProgram: "",
	},
	notificationsDropdown: {
		title: "Notificações",
		contactRequestReceived: "enviou-lhe uma solicitação de contato. Confira!",
		contactRequestAccepted:
			"aceitou sua solicitação de contato. Comece a conversar!",
		meetingRequestReceived: "enviou-lhe um pedido de reunião. Confira!",
		meetingRequestAccepted: "aceitou o seu pedido de reunião. Confira!",
		meetingRequestCanceled: "cancelou um pedido de reunião. Confira!",
		meetingStarting: "Uma reunião começará em breve. Confira!",
		newBoothPost: "postou no mural do seu estande. Confira!",
		noNotifications: "Você ainda não tem notificações!",
		seeAll: "",
		hideAll: "",
		agendaItemUpdated:
			"O item do programa salvo foi atualizado pelo organizador do evento",
		agendaItemDelted:
			"O item do programa salvo foi excluído pelo organizador do evento",
		newMeetingRequest: "Você tem uma nova solicitação de reunião",
		meetingAccepted: "Sua solicitação de reunião foi aceita",
		meetingCancelled: "Sua reunião foi cancelada",
		meetingWillStartTextFirst: "Uma reunião com",
		meetingWillStartTextSecond: "começará logo. Confira!",
	},
	chatsDropdown: {
		title: "CHATS",
		newChatButton: "Novo chat",
		newChatStart: "Inicie um novo chat",
		newChatProvideText: "",
		noChats:
			"Crie pelo menos um chat para conversar com os participantes do evento",
		member: "membro",
		members: "Membros",
		noOpenedChatText: "Clique em um chat para continuar a conversa.",
		noMessagesInChatTextFirst: "Quebrar o gelo.",
		noMessagesInChatTextSecond: "Inicie esta conversa.",
		newChatNameInput: "Nome do bate-papo (opcional)",
		newChatAddMembers: "Adicione contatos e outros participantes a este chat:",
		editChatAddMembers:
			"Pesquise os usuários deste evento que você deseja adicionar ao chat em grupo.",
		newChatSearchInput: "Pesquisar usuários",
		newChatNoUsersMessage: "Usuários não encontrados",
		newChatAddUserButton: "Adicionar",
		newChatRemoveUserButton: "Remover",
		newChatCancelButton: "FECHAR",
		newChatCreateButton: "CRIAR",
		membersLeaveButton: "SAIR",
		membersUpdateButton: "ATUALIZAR",
		membersChatButton: "Bate-papo",
		chatSettingsButton: "Configurações",
		newMessageInput: "Digite a mensagem aqui",
		newVideoCallTooltipText: "Clique aqui para iniciar um chat por vídeo",
		fullscreenButton: "Abrir tela inteira",
		minimizeButton: "Minimizar a tela",
	},
	myAccountDropdown: {
		title: "MINHA CONTA",
		viewProfileButton: "Ver perfil",
		logoutButton: "SAIR",
	},
	myAccountPage: {
		platformProfileSectionTitle: "perfil Neural Network",
		changePasswordButton: "MUDAR SENHA",
		deleteAccountButton: "DELETAR MINHA CONTA",
		platformLanguage: "Linguagem da plataforma:",
		reviewPlatformGdprText: "Reveja as condições da Onvent:",
		privacyPolicyLink: "Aviso / política de privacidade",
		cookiesLink: "Cookies",
		termsAndConditionsLink: "Termos e condições",
		eventProfileSectionTitle: "Perfil do evento",
		eventPackage: "Pacote do Evento",
		reviewEventGdprText: "Reveja as nossas condições de evento:",
		unregisterFromEventButton: "CANCELAR O REGISTRO NO EVENTO",
		unregisterCurrentEventButton: "Cancelar o registro no evento atual",
		unregisterCurrentEventConfirmation:
			"Por favor, confirme que você deseja cancelar o registro do evento",
		enableParticipantProfile: "Habilitar perfil de participante",
		editUserDialogTitle: "Editar informações do usuário",
		editExhibitorDialogTitle: "Editar detalhes do contato na empresa",
		editParticipantDialogTitle: "Editar detalhes do participante",
		firstName: "Nome",
		lastName: "Sobrenome",
		uploadImage: "Carregar imagem",
		removeImage: "Remover imagem",
		deleteTitle: "DELETAR MINHA CONTA",
		deleteConfirm: "Confirmar a exclusão do minha conta",
		deleteError:
			"Você não pode excluir sua conta. Exclua todos os eventos criados para poder excluir sua conta.",
		deleteSuccess: "Sua conta foi excluída.",
		email: "e-mail",
		reviewEventPrivacyPolicy:
			"Confira nossa Política de Privacidade do Evento:",
		title: "Título",
		company: "Empresa",
		phone: "Telefone",
		platformProfileSectionTitleTextSecond: "perfil",
		reviewPlatformGdprTextFirst: "Revise as nossas",
		reviewPlatformGdprTextSecond: "condições",
	},
	changePassword: {
		title: "MUDAR SENHA",
		oldPassword: "Senha antiga",
		oldPasswordError: "Senha antiga não confere!",
		inputPassword: "Nova senha",
		inputConfirmPassword: "Confirmar senha",
		changePasswordButton: "MUDAR SENHA",
	},
	roomsDropdown: {
		title: "MENU DE SALAS",
		auditoriumButton: "Auditório",
		showfloorButton: "Sala de exposições",
		archiveButton: "ARQUIVO",
		onDemandButton: "ON-DEMAND", // new translation
		metaverse: "METAVERSO",
		floorPlan: "PLANTA BAIXA",
	},
	sideMenu: {
		liveWall: "Mural ao vivo",
		networking: "Networking",
		info: "INFORMAÇÕES",
		videoWall: "Mural de vídeo",
		booth: "ESTANDE",
		boothWall: "Mural do estande",
		resources: "RECURSOS",
		networkingTooltip:
			"Você não tem acesso a todas as funcionalidades de Networking.",
		polls: "ENQUETES",
	},
	wall: {
		sortBy: "Ordenar por:",
		popularity: "POPULARIDADE",
		time: "TEMPO",
		emptyWallText: "Seja o primeiro a adicionar uma postagem aqui",
		newPostInput: "No que você está pensando?",
		newCommentInput: "Comentar",
		resourcesVideoDescription: "DESCRIÇÃO EM VÍDEO",
		resourcesLinks: "Links",
		resourcesFiles: "Arquivos",
		whatIsOnYourMind: "No que você está pensando?",
	},
	networking: {
		searchInput: "Pesquisar por usuário",
		at: "em",
		regenerateMatches: "regenerar partidas",
	},
	closedAuditoriumDialog: {
		title: "Informações do auditório",
		content: "O acesso ao Auditório foi restrito.",
		contentPackage: "Seu pacote de acesso não tem acesso a nenhum auditório.",
		button: "OK",
	},
	closedShowfloorDialog: {
		title: "Informações da Sala de exposições",
		content: "O acesso à Sala de Exposições foi restrito.",
		contentPackage: "Seu pacote de acesso não tem acesso à Sala de Exposições.", //New translation: Your access package doesn't have access to any showfloors.
		button: "OK",
	},
	auditorium: {
		willStart: "A próxima sessão começará em ...",
		noAccess: "Seu pacote de acesso não tem acesso a este auditório.",
		noVideos: "Nenhum vídeo está agendado neste auditório.",
		stageTab: "PALCO",
		stagesButton: "PALCOS",
		videoWallButton: "Mural de vídeo",
		resourcesButton: "RECURSOS",
		slotEndsTextFirst: "Este horário de programa termina em",
		slotEndsTextSecond:
			"A apresentação estará disponível mediante solicitação no final do programa.",
		slotTitleWith: "Com",
		allRooms: "TODAS AS SALAS",
		onDemandButton: "ON-DEMAND", // new translation
		allFloors: "TODAS AS PLANTAS BAIXAS",
		hide: "ESCONDER",
	},
	auditoriumArchive: {
		title: "Arquivo de Auditórios",
		titleEvent: "Arquivo de Eventos",
		with: "Com",
		stage: "PALCO",
		videoWall: "Mural de vídeo",
		resources: "RECURSOS",
		tooltipMinimize: "Minimizar",
		tooltipFullscreen: "Tela cheia",
		tooltipPause: "Pausa",
		tooltipPlay: "Reproduzir",
		tooltipUnmute: "Ativar som",
		tooltipMute: "Mudo",
		noVideosAvailable: "All sessions finished", // new translation
	},
	videoPlayer: {
		videoNotPlayingError:
			"Está tendo problemas com o vídeo? Por favor, recarregue.",
		videoEndedTextFirst: "O vídeo terminou. Obrigado por assistir!",
		videoEndedTextSecond:
			"O mural do vídeo está aberto para perguntas e respostas.",
		tooltipPlay: "Reproduzir",
		tooltipPause: "Pausa",
		tooltipMinimize: "Minimizar",
		tooltipFullscreen: "Tela cheia",
		tooltipMute: "Mudo",
		tooltipUnmute: "Ativar som",
	},
	showfloor: {
		allExhibitorsButton: "TODOS OS EXPOSITORES", //new translation: Showfloor menu
		featuredExhibitorsText: "EXPOSITORES EM DESTAQUE",
		boothLinksDialogTitle: "LINKS PARA ESTANDES",
		boothLinksDialogOtherLinksTitle: "Outros:",
		boothFilesDialogTitle: "ARQUIVOS DE ESTANDES",
		boothVideoDialogTitle: "VÍDEO",
		mobileTabChat: "Bate-papo",
		mobileTabFiles: "Arquivos",
		mobileTabLinks: "Links",
		mobileTabVideo: "VÍDEO",
		noExhibitorsTextFirst: "OS EXPOSITORES ESTÃO TRABALHANDO NOS ESTANDES",
		noExhibitorsTextSecond: "FIQUE LIGADO",
		filterBy: "Filtrar por",
		searchByTheUser: "Pesquisar pelo usuário",
		showShowfloorsButton: "ALL EXHIBIT HALLS", //new translation
		searchExhibitors: "Search exhibitors", // //new translation: Search exhibitors
		showfloorsTitle: "Exhibit Halls", //new translation: Showfloors
	},
	eventArchive: {
		title: "Arquivo de Vídeo",
		searchText: "Toque para pesquisar",
		noVideosText: "Nenhum vídeo encontrado neste evento.",
		noArchivedEvents: "Atualmente não há eventos arquivados.",
	},
	exhibitorDashboard: {
		event: "Evento",
		title: "Gerenciar seu estande",
		visitorsButton: "Visitantes de estandes",
		goToBoothButton: "Me leve ao estande",
		goToBoothButtonDisabled:
			"Seu estande ainda não está disponível para visualização.",
		tabStyle: "Estilo de estande",
		tabLogo: "Logotipo",
		tabFiles: "Arquivos",
		tabLinks: "Links",
		tabAvatars: "Avatares",
		tabVideo: "Vídeo",
		boothStyleTab: {
			optionOne: "Opção 1 - Escandinavo",
			optionTwo: "Opção 2 - Moderno",
			optionThree: "Opção 3 - Dinâmica",
			optionFour: "Opção 4 - personalizado",
		},
		logoTab: {
			title: "Logotipo",
			fileUploadButton: "Clique aqui para fazer o upload",
			noFile: "Nenhum arquivo selecionado",
		},
		filesTab: {
			title: "Documentos:",
			fileNameInput: "Nome do documento (visível para todos os participantes)",
			chooseFileButton: "Escolher arquivo",
			noDocAdded: "Nenhum documento adicionado",
			fileError: "Adicione um nome de documento",
		},
		linksTab: {
			social: {
				title: "Links de redes sociais",
				subtitle: "(visível para todos os participantes)",
				facebookInput: "Insira seu link do Facebook",
				linkedinInput: "Insira seu link do LinkedIn",
				twitterInput: "Insira o seu link do Twitter",
			},
			other: {
				title: "Outros links",
				subtitle: "(Página inicial ou outros links)",
				linkInput: "Inserir Novo Link",
				linkLabel: "Inserir novo rótulo de link",
			},
		},
		avatarsTab: {
			mainRepresentatives: {
				title: "Representantes de estandes",
				subtitle: "(apenas um representante obrigatório)",
				leftRepresentative: "Representante esquerdo",
				rightRepresentative: "Representante direito",
				addDialog: {
					title: "Adicionar Avatar",
					contentFirst:
						"Escolha um avatar da galeria abaixo ou carregue seu próprio avatar personalizado.",
					contentSecond: "Precisa de ajuda com o design? Entrar em contato:",
					female: "Feminino",
					male: "Masculino",
					uploadSectionTitle: "Carregue seu avatar pessoal",
					uploadButton: "Escolher arquivo:",
					noFile: "Escolher arquivo:",
					assignText:
						"Designe um participante do evento para ser associado ao representante acima.",
					input: "Pesquise por nome aqui",
					avatarLabels: {
						femaleA: "mulher A",
						femaleB: "mulher B",
						femaleC: "mulher C",
						maleA: "homem A",
						maleB: "homem B",
						maleC: "homem C",
					},
				},
			},
			additionalRepresentatives: {
				title: "Representantes adicionais",
				subtitle: "(opcional, será exibido com a foto do perfil)",
				addDialog: {
					title: "Escolha um representante adicional",
					titleScholar: "Choose Co-Author", //new translation
					assignText:
						"Comece a pesquisar um participante do evento para adicioná-lo como representante.",
					assignError:
						"O usuário já foi adicionado como representante do estande!",
					chooseAvatarTextFirst:
						"Escolha um avatar da galeria abaixo ou carregue seu próprio avatar personalizado.",
					chooseAvatarTextSecond:
						"Precisa de ajuda com o design? Entrar em contato:",
					uploadText: "Carregue seu avatar pessoal",
					chooseFileText: "Escolher arquivo:",
					noFile: "Nenhum arquivo selecionado",
				},
			},
			author: {
				title: "Presenting Author", // new translation
				subtitle:
					"Add only an author that registered to the event. You can add 1 author.", // new translation
				mainRepresentative: "Main representative", // new translation
			},
			coAuthors: {
				title: "Co-Authors", // new translation
				subtitle:
					"Add only co-authors that registered to the event. You can add up to 4 co-authors.", // new translation
			},
		},
		videoTab: {
			video: {
				title: "Vídeo do estande",
				subtitle: "(Apenas link de vídeo do Youtube)",
				input: "Insira a URL do Youtube",
			},
			image: {
				title: "Imagem",
				subtitle:
					"(A imagem é exibida em seu estande caso você não tenha adicionado um link de vídeo na seção acima)",
				uploadButton: "Clique aqui para fazer o upload",
			},
		},
	},
	boothDashboard: {
		event: "Evento",
		title: "Seu estande",
		manage: "Gerenciar",
		goToBoothButton: "Me leve ao estande",
		previewNotAvailable:
			"Seu estande ainda não está disponível para visualização.",
		statistics: "Estatísticas",
		usersNow: "Participantes agora",
		usersAllTime: "Histórido de participantes",
		linkClicks: "Cliques de link",
		documentViews: "Visualizações de documentos",
		videoViews: "Visualizações de apresentação em vídeo",
		visitorsSectionTitle: "Visitantes de estandes",
		noVisitors: "Ainda não há visitantes em seu estande.",
		visitorsSearch: "Pesquisar usuários",
		actionType: "TIPO DE AÇÃO",
		showUsers: "MOSTRAR USUÁRIOS",
		ofUsers: "Nº DE USUÁRIOS",
	},
	polls: {
		polls: "VOTAÇÕES",
		poll: "VOTAÇÃO",
		noPolls: "Nenhuma votação foi adicionada ainda.",
		pollProgress: "Votação em andamento",
		pollClosed: "Votação fechada",
		pollVoted: "participantes votaram",
	},
	onDemandVideos: {
		sectionTitle: "ON-DEMAND", // new translation
		noOnDemandVideos: "No on demand videos were added yet.", // new translation
	},
	meetings: {
		meeting: "REUNIÃO",
		meetings: "REUNIÃO",
		createDialog: {
			createTitle: "Solicitar reunião com",
			inputDate: "Data",
			inputStart: "Inicia",
			inputEnd: "Termina",
			inputTimezone: "Fuso horário",
			inputNote:
				"Nota: Apenas uma reunião pode ser agendada por vez com a mesma pessoa.",
			meetingError: "Data ou hora de reunião inválida. Por favor, verifique!",
			buttonDisabled:
				"Você pode organizar uma reunião depois que você começar uma conversa!",
		},
		banner: {
			pending: "Pedido de reunião",
			accepted: "Reunião agendada para",
			progress: "Reunião em andamento",
			cancelTitle: "Cancelar reunião?",
			cancelDescription: "Tem certeza de que deseja cancelar esta reunião?",
			startCall: "INICIAR CHAMADA",
			joinCall: "ENTRAR",
			pendingOwner: "Solicitação de reunião pendente para",
			videoCallStarted: "Reunião em vídeo começou",
		},
		newMeeting: "NOVA REUNIÃO",
		requestMeeting: "Solicitando reunião com",
		startDate: "Data de início",
		starts: "Começa",
		ends: "Termina",
		addMessageHere: "Adicione sua mensagem aqui",
		note: "Nota: você só pode solicitar uma reunião com uma pessoa em particular. Depois que a reunião for encerrada (ou cancelada), você pode reagendar a próxima reunião",
		create: "CRIAR",
		requested: "Solicitado",
		confirmed: "Confirmado",
		title: "Suas reuniões",
		meetingsInfo:
			"As reuniões podem ser agendadas a partir de chats. Comece um chat com o usuário com o qual deseja agendar uma reunião.",
	},
	landingPage: {
		animatedSectionButton: "INICIAR JORNADA",
		topSectionHeaderPrimary: "Próxima geração",
		topSectionHeaderSecondary: "eventos on-line",
		carouselPresentationText: "Conferir",
		carouselPresentationTextAccent: "NOVO Neural Network",
		videoPresentationTextPrimary: "Neural Network - eventos on-line que seus",
		videoPresentationTextAccent: "participantes vão adorar.",
		videoPresentationTextSecondary: "Aqui está o porquê:",
		testimonialsTextPrimary: "Eventos on-line desde 2015 -",
		testimonialsTextAccent: "o importante para nós são as pessoas",
		testimonialsTextSecondary: "...",
		cardsTextPrimary: "Neural Network deixa",
		cardsTextAccent: "uma sensação de UAU",
		cardsTextSecondary: "para...",
		contactTextPrimary: "Interessado em organizar um evento on-line?",
		contactTextAccent: "Entre em contato",
		contactTextSecondary: "",
		contactInputName: "Nome",
		contactInputEmail: "E-mail",
		contactInputPhone: "Número de celular (opcional)",
		contactInputMessage: "Mensagem",
		contactButton: "ENVIAR",
		carouselPresentationTextAccentTextFirst: "NOVO",
		videoPresentationTextPrimaryTextSecond: "eventos on-line que seu",
		cardsTextPrimaryTextSecond: "traz",
	},
	loginPlatform: {
		title: "ENTRAR",
		description:
			"Após fazer o login, você será redirecionado para o último evento em que se registrou. Você pode alternar facilmente entre onvents ",
		descriptionTextFirst:
			"Após fazer o login, você será redirecionado para o último",
		descriptionTextSecond:
			"evento em que se registrou. Você pode alternar facilmente entre",
		descriptionTextThird: "usando a opção Meus eventos no menu.",
	},
	skipped: {
		startVideoChat:
			"Eu marquei uma videoconferência. Por favor clique aqui para entrar.",
	},
	hubDropdownTitle: "MENU DA CENTRAL",
	marketplace: {
		title: {
			default: "Marketplace",
			uppercased: "MARKETPLACE",
		},
		label: "PRODUTOS",
		addNew: "ADICIONAR NOVO",
		addNewProduct: "Adicionar novo produto",
		editProduct: "Editar produto",
		noProducts: "Nenhum produto ainda",
		publish: "PUBLICAR",
		productTitle: "Título do produto",
		productDescription: "Descrição do produto",
		productLink: "Link do produto",
		productImage: "IMAGEM DO PRODUTO",
		uploadHint:
			"Faça upload de uma imagem que será exibida como visualização do produto. Resolução sugerida de 600*600 px para melhor qualidade de imagem",
		descriptionHint: "A descrição é limitada a 300 símbolos",
		uploadImage: "CARREGUE IMAGEM PARA AUMENTAR O VALOR DO PRODUTO.",
		uploaded: "Carregado",
	},
	personalAgenda: {
		agenda: "AGENDA",
		myProgram: "MEU PROGRAMA",
		myMeetings: "MINHAS REUNIÕES",
		addNewTask: {
			default: "Adicionar nova tarefa",
			uppercased: "ADICIONAR NOVA TAREFA",
		},
		noProgram: "Nenhuma entrada de programa disponível por enquanto",
		noMeetings: "Nenhuma reunião disponível por enquanto",
		title: "Título",
		startDate: "Data de início",
		starts: "Começa",
		ends: "Termina",
		create: "CRIAR",
		schedule: "PROGRAMAÇÃO",
		personalAgenda: "AGENDA PESSOAL",
		timetableMenu: "MENU DE HORÁRIOS",
		gmt: "GMT",
	},
	program: {
		toAgenda: "PARA AGENDA",
		attending: "PARTICIPANDO",
		inPerson: "Em pessoa",
		online: "Online",
		searchWrapperTitle: "PESQUISAR EM TODAS AS SESSÕES",
		searchWrapperTitlePast: "PESQUISAR EM TODAS AS SESSÕES PASSADAS",
		searchWrapperTitleUpcoming: "PESQUISAR EM TODAS AS PRÓXIMAS SESSÕES",
		searchWrapperTitleInterval: "PESQUISAR EM SESSÕES",
	},
	businessCard: {
		businessCard: {
			default: "Cartão de visitas",
			uppercased: "CARTÃO DE VISITAS",
		},
		editText: "EDITAR TEXTO",
		colors: "CORES",
		branding: "MARCA",
		cardPreview: "VISUALIZAR CARTÃO",
		onceSaved:
			"Depois de salvo, o cartão de visitas estará sempre conectado ao seu perfil. Ele permitirá que você tenha acesso a rede, bate-papo e outras funcionalidades importantes. O cartão será excluído automaticamente quando você excluir seu perfil",
		required: "obrigatório",
		designation: "Designação",
		Mobile: "Celular",
		Phone: "Telefone",
		Website: "Site",
		companyName: "Nome da empresa",
		honoric: "Adicione seu título preferido, como Sr., Sra., Ser, Sheikh, etc.",
		fillUpData: "PREENCHER DADOS DA MINHA CONTA",
		primaryColor: "COR PRIMÁRIA",
		primaryColorDescription: "Esta é a cor de destaque. Use a cor da sua marca",
		backgroundColor: "COR DE FUNDO",
		backgroundColorDescription:
			"Esta é a cor secundária. Use-a como cor de fundo. Por padrão, ela pode ser preta ou branca",
		backgroundTextColor: "COR DE FUNDO E ÍCONS DE CORES",
		backgroundTextColorDescription:
			"Esta cor para os contatos de telefones, e-mail e site da Web, e deve ser contrastada com a cor de fundo. Vá para a visualização do cartão para ter certeza de que o cartão está legível e tem nível de contraste suficiente",
		companyLogo: "LOGOTIPO DA EMPRESA",
		uploadMessage:
			"Faça upload de uma imagem que será exibida como o logotipo da empresa. Resolução sugerida de 320*200 px para melhor qualidade de imagem. Use um logotipo com fundo transparente (formato de arquivo .png é preferível)",
		uploadImage: "CARREGAR IMAGEM",
		request: "SOLICITAÇÃO",
		open: "ABERTA",
		pending: "PENDENTE",
	},
	businessWallet: {
		short: "B WALLET",
		full: "CARTEIRA DE NEGÓCIOS",
		myContacts: "MEUS CONTATOS",
		requests: "SOLICITAÇÕES",
		pendings: "PENDENTES",
		searchByName: "Pesquisar por nome",
		chat: "Chat",
		openCard: "ABRIR CARTÃO",
		empty: "Ninguém aqui ainda",
		cancelRequest: "CANCELAR SOLICITAÇÃO",
		approve: "APROVAR",
		reject: "REJEITAR",
		myNotes: "MINHAS NOTAS",
		addNewNote: "Adicionar nova nota",
		delete: "EXCLUIR",
		saveThoughts: "Salve seus pesamentos em um só espaço",
	},
	videoConferences: {
		message: "Eu criei uma Sala de Reunião por Vídeo. Clique para entrar",
		clickToStart: "Clique aqui para começar uma vídeochamada",
		fullScreen: "ABRIR TELA INTEIRA",
		minimizeScreen: "MINIMIZAR TELA",
	},
	sessions: {
		sessionList: "LISTA DE SESSÕES",
		createNewSession: {
			uppercased: "CRIAR NOVA SESSÃO",
			default: "Criar nova sessão",
		},
		sessionName: {
			uppercased: "NOME DA SESSÃO",
			default: "Nome da sessão",
		},
		sessionLink: "LINK DA SESSÃO",
		sessionType: "TIPO DA SESSÃO",
		manage: "GERENCIAR",
		edit: "EDITAR",
		delete: "EXCLUIR",
		live: {
			uppercased: "AO VIVO",
			lowercased: "ao vivo",
			default: "Ao vivo",
		},
		recorded: {
			uppercased: "GRAVADA",
			lowercased: "gravada",
			default: "Gravada",
		},
		editSession: "Editar sessão",
		uploadVideo: "CARREGAR VÍDEO",
		create: "CRIAR",
		discard: "DESCARTAR",
		speakers: "PALESTRANTES",
		moderators: "MODERADORES",
		speakerName: "NOME DO PALESTRANTE",
		moderatorName: "NOME DO MODERADOR",
		kick: "EXPULSAR",
		screenSharing: "COMPARTILHAMENTO DE TELA",
		audio: "ÁUDIO",
		video: "VÍDEO",
		startStream: "INICIAR TRANSMISSÃO",
		stopStream: "PARAR TRANSMISSÃO",
		startRecording: "INICIAR GRAVAÇÃO",
		endRecording: "TERMINAR GRAVAÇÃO",
		sessionChat: "CHAT DA SESSÃO",
		typeMessageHere: "Digite a mensagem aqui",
		sessionLinks: "LINKS DA SESSÃO",
		speakerUrl: "URL do Palestrante",
		moderatorUrl: "URL do Moderador",
		copyUrl: "copiar URL",
		organizer: "ORGANIZADOR",
		moderator: "MODERADOR",
		speaker: "PALESTRANTE",
		joinAsSpeaker: "Entrar como palestrante",
		invitedAsSpeaker:
			"Você foi convidado como palestrante para a demonstração do AIM Sessions. Insira seu nome (ele ficará visível para os participantes da sessão)",
		yourName: "Seu nome",
		enter: "ENTRAR",
		waitForApproval: "Aguarde aprovação",
		deny: "NEGAR",
		admit: "PERMITIR",
		joinAsModeraotr: "Entrar como moderador",
		invitedAsModerator:
			"Você foi convidado como moderador para a demonstração do AIM Sessions. Insira seu nome (ele ficará visível para os participantes da sessão)",
		declined: "Você foi negado",
	},
	breadcrumbsNavigation: {
		mainLobby: "Lobby principal",
		village: "Vila",
	},
	village: {
		clickToOpen: "clique para abrir",
	},
	gdpr: {},
};

export default pt;
