import InputBase from '@material-ui/core/InputBase';
import React from 'react';
import {connect} from 'react-redux';
import colors from '../../../../CSS/_variables.module.scss';
import {ReactComponent as Send} from '../../../../Images/svg/sendMessage.svg';
import {ReactComponent as OpenConference} from '../../../../Images/svg/videoConferenceButton.svg';
import {isWebview} from '../../../../Utils/is-webview';
import {preventDefaultDrag} from '../../../../Utils/utils';
import * as actions from '../../../../store/actions/index';
import axios from '../../../../store/axios-instance';
import StartVideoChatTooltip from '../VideoConference/StartVideoChatTooltip';
import {isIOS} from 'react-device-detect';

class SendGroupChatMessage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            newMessage: '',
            sendingMessage: false,
            isSending: false,
        };

        this.newMessageRef = React.createRef();
        this.setButtonRef = this.setButtonRef.bind(this);
        this._handleSendMessage = this._handleSendMessage.bind(this);
    }

    chatMessagesContainer = document.getElementById('chat-messages-container');

    componentDidMount() {
        const {isLargeScreen} = this.props;
        if (isLargeScreen) {
            this.newMessageRef.current.focus();
        }
        document.addEventListener('mousedown', this._handleSendMessage);
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeChatRoomId !== prevProps.activeChatRoomId) {
            this.newMessageRef.current.focus();
        }
    }

    componentWillUnmount() {
        const eventPage = document.getElementsByClassName('event-page hide-new-chat-message');
        if (eventPage.length !== 0) {
            eventPage[0].classList.remove('hide-new-chat-message');
        }
        document.removeEventListener('mousedown', this._handleSendMessage);
        clearTimeout(this.sendMessageTimeoutId);
        clearTimeout(this.inputFocusTimeoutId);
        clearTimeout(this.inputBlurTimeoutId);
    }

    setButtonRef = (node) => (this.sendButton = node);

    _handleSendMessage = (event) => {
        if (this.sendButton && this.sendButton.contains(event.target)) {
            this.setState({
                isSending: true,
            });
        } else {
            this.setState({
                isSending: false,
            });
        }
    };

    handleChange = (name) => (event) =>
        this.setState({
            [name]: event.target.value,
        });

    handleKeyDown = (e) => {
        const {newMessage} = this.state;
        if (e.key === 'Enter' && newMessage.trim()) {
            this.onSendGroupMessage(e);
        }
    };

    onSendGroupMessage = (e) => {
        const {sendingMessage, newMessage} = this.state;
        e && e.preventDefault();
        this.setState({sendingMessage: true});
        if (!sendingMessage) {
            axios({
                method: 'post',
                url: '/group-chat/' + this.props.activeChatRoomId + '/messages',
                data: {text: newMessage},
            }).then((response) => {
                this.setState({newMessage: '', sendingMessage: false});
                this.newMessageRef.current.focus();
            });
        }
        this.sendMessageTimeoutId = setTimeout(() => {
            if (this.chatMessagesContainer) {
                this.chatMessagesContainer.scroll({top: this.chatMessagesContainer.scrollHeight, behavior: 'smooth'});
            }
        }, 500);
    };

    startConference = () =>
        this.setState({newMessage: "I've set up a Video Meeting room. Please click here to join it."}, () => {
            this.onSendGroupMessage();
            this.props.startConference();
        });

    handleInputFocus = () => {
        const eventPage = document.getElementsByClassName('event-page');
        if (eventPage) {
            eventPage[0].classList.add('hide-new-chat-message');
        }
        this.inputFocusTimeoutId = setTimeout(() => {
            if (this.chatMessagesContainer) {
                this.chatMessagesContainer.scroll({top: this.chatMessagesContainer.scrollHeight, behavior: 'smooth'});
            }
        }, 500);
    };

    handleInputBlur = () => {
        const {isSending} = this.state;
        if (!isSending) {
            const eventPage = document.getElementsByClassName('event-page hide-new-chat-message');
            if (eventPage.length !== 0) {
                eventPage[0].classList.remove('hide-new-chat-message');
            }
            this.inputBlurTimeoutId = setTimeout(() => {
                if (this.chatMessagesContainer) {
                    this.chatMessagesContainer.scroll({
                        top: this.chatMessagesContainer.scrollHeight,
                        behavior: 'smooth',
                    });
                }
            }, 500);
        }
    };

    render() {
        const {newMessage, sendingMessage} = this.state;
        const {translation, joinBannerActive, videoConferenceActive} = this.props;
        return (
            <form autoComplete={'off'} className="chat-message-form" onSubmit={this.onSendGroupMessage}>
                <div onDragStart={preventDefaultDrag} className={`new-comment-container ${isIOS ? 'ios-support' : ''}`}>
                    <InputBase
                        id="newMessage"
                        multiline={true}
                        maxRows={4}
                        placeholder={translation?.chatsDropdown.newMessageInput}
                        value={newMessage}
                        onChange={this.handleChange('newMessage')}
                        onKeyDown={this.handleKeyDown}
                        onFocus={this.handleInputFocus}
                        onBlur={this.handleInputBlur}
                        ref={this.newMessageRef}
                    />
                    {newMessage ? (
                        <button
                            disabled={!newMessage.trim() || sendingMessage}
                            className="send-group-message"
                            type="submit"
                            ref={this.setButtonRef}
                        >
                            <Send fill={colors.primary} />
                        </button>
                    ) : joinBannerActive || videoConferenceActive ? (
                        <button disabled>
                            <Send fill={colors.grey} />
                        </button>
                    ) : isWebview() ? null : (
                        <button onClick={this.startConference} className="video-conference-button" type="button">
                            <OpenConference fill={colors.primary} />
                        </button>
                    )}
                    <StartVideoChatTooltip newMessage={newMessage} translation={translation} />
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activeChatRoomId: state.user.topNavigation.activeChatRoomId,
        isLargeScreen: state.layout.isLargeScreen,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        newGroupChatMessage: (data) => dispatch(actions.newGroupChatMessage(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendGroupChatMessage);
