import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from '../../store/axios-instance';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import './DeleteMyAccountStyles.scss';

class DeleteMyAccount extends React.Component {
    componentDidMount() {
        this.checkCanDeleteAccount();
    }

    state = {
        userDeleted: false,
        canDeleteAccount: true,
    };

    checkCanDeleteAccount = () => {
        const {user} = this.props;

        let canDeleteAccount = true;
        user.eventRoles.forEach((eventRole) => {
            if (eventRole.roles.includes('organizer')) {
                canDeleteAccount = false;
            }
        });

        this.setState({canDeleteAccount: canDeleteAccount});
    };

    handleDeleteMyAccount = () => {
        const {user} = this.props;
        axios({method: 'delete', url: `/users/${user._id}`})
            .then((response) => {
                this.setState({userDeleted: true});
            })
            .catch((error) => {});
    };

    closeDialog = () => {
        const {userDeleted} = this.state;
        if (userDeleted) {
            this.props.onLogoutUser();
        } else {
            this.props.closeDialog();
        }
    };

    render() {
        const {userDeleted, canDeleteAccount} = this.state;
        const {mobile, opened, translation} = this.props;
        return (
            <Dialog
                open={opened}
                onClose={this.closeDialog}
                aria-labelledby="form-dialog-title"
                className={`${mobile ? 'dialog-mobile' : ''}`}
            >
                <DialogTitle id="form-dialog-title">{translation?.myAccountPage.deleteTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="form-dialog-secondary">
                        {canDeleteAccount ? (
                            <span>
                                {userDeleted
                                    ? translation?.myAccountPage.deleteSuccess
                                    : translation?.myAccountPage.deleteConfirm}
                            </span>
                        ) : (
                            <span>{translation?.myAccountPage.deleteError}</span>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button type="button" classes={{label: 'dialog-btn-uppercase'}} onClick={this.closeDialog}>
                        {translation?.generalText.close}
                    </Button>
                    {!this.state.userDeleted && this.state.canDeleteAccount && (
                        <Button
                            type="button"
                            classes={{label: 'dialog-btn-uppercase', root: 'button-helper'}}
                            onClick={this.handleDeleteMyAccount}
                        >
                            {translation?.myAccountPage.deleteConfirm}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogoutUser: () => dispatch(actions.logoutUser()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteMyAccount);
