import React from 'react';
import '../CSS/headerMenu.scss';
import { isIE, isYandex } from 'react-device-detect';
// import CookieBanner from '../SmallLayoutComponents/CookieBanner';
import RedirectOnLogin from '../HOC/RedirectOnLogin';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../store/actions/index';
import PlatformMenu from './PlatformMenu';
import HeaderMenu from './HeaderMenu';
import EventMenu from './EventMenu';
import AdminMenu from './AdminMenu';
import {
    connectToNotifications,
    disconnectFromNotifications,
    notificationReceived,
    flashNotificationReceived,
    sessionUpdated,
} from '../Api/socketApi';
import Snackbar from '@material-ui/core/Snackbar';
import FullOverlay from '../SmallLayoutComponents/FullOverlay';
import { preventDefaultDrag } from '../Utils/utils';
import SessionGuestMenu from "./SessionGuestMenu";
// import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
};

class Menu extends React.Component {
    eventReloadTimer = null;
    clearCacheAndReloadAppTimer = null;

    state = {
        snackbar: {
            message: 'You have been removed from the group chat.',
            open: false,
        },
        showRemovedFromEventDialog: false,
    };

    componentDidMount() {
        // cookie bot is loaded from public/index.html

        // always connect to notifications at platform level
        localStorage.setItem('triggeredEventReload', 'false');
        notificationReceived((err, data) => {
            const notification = data.notification;

            if (notification.type === 'clearCacheAndReloadApp') {
                this.clearCacheAndReloadAppNotification();
            } else if (notification.type === 'reloadEventData') {
                this.reloadEventDataNotification(notification);
            } else if (notification.type === 'reloadExhibitorData') {
                this.reloadExhibitorDataNotification(notification);
            } else if (notification.type === 'newPrivateChatMessage' || notification.type === 'newGroupChatMessage') {
                if (notification.type === 'newPrivateChatMessage') {
                    this.newPrivateChatMessageNotification(data);
                } else {
                    this.newGroupChatMessageNotification(data);
                }
            } else if (notification.type === 'removedFromGroupChat') {
                this.removedFromGroupChatNotification(notification);
            } else if (notification.type === 'removedFromEvent') {
                this.removedFromEventNotification(notification);
            } else if (
                notification.type === 'eventParticipantProfileCreated' ||
                notification.type === 'eventExhibitorProfileCreated'
            ) {
                // after the payment was completed
                // we listen for the server notification that informs us that the payment was completed
                // and the profile was created
                this.props.onRefreshUserData();
            } else {
                this.props.onNewNotification(notification);
            }
        });

        flashNotificationReceived((err, data) => {
            this.props.onFlashNotificationReceived(data);
        });

        sessionUpdated((err, data) => {
            // whenever the Organizer changes something to the session we receive a socket notification
            // Organizer starts/stops the sessionStream
            // Organizer toggles the canRequestMic option
            // so that I can update in Redux the timeslot=>Session=>isActive (true/false)
            this.props.onUpdateTimeslotSession(data);
        });
    }

    componentDidUpdate(prevProps) {
        // we always connect the user to notifications AFTER HE LOGGED IN
        // WE CONNECT HIM ONLY ONCE
        if (prevProps.loggedIn === false && this.props.loggedIn === true) {
            if (!this.props.userConnectedToNotifications) {
                connectToNotifications(this.props.user._id);
                this.props.onSetUserConnectedToNotifications();
            }
        }

        // if the user switched to a new event and someone modified something to the old event, we clear the eventReloadTimer
        // so that we make sure that when he gets to the new event we will not get the old event data
        if (prevProps.eventId !== this.props.eventId && this.eventReloadTimer) {
            localStorage.setItem('triggeredEventReload', 'false');
            clearTimeout(this.eventReloadTimer);
        }
    }

    componentWillUnmount() {
        if (this.clearCacheAndReloadAppTimer) {
            clearTimeout(this.clearCacheAndReloadAppTimer);
        }
        if (this.eventReloadTimer) {
            localStorage.setItem('triggeredEventReload', 'false');
            clearTimeout(this.eventReloadTimer);
        }
    }

    clearCacheAndReloadAppNotification = () => {
        // set a timeout in milliseconds (1 ms  ...  2 minutes)
        let randomIntervalInMilliseconds = Math.floor(Math.random() * (120000 - 1 + 1)) + 1;
        this.clearCacheAndReloadAppTimer = setTimeout(() => window.location.reload(true), randomIntervalInMilliseconds);
    };

    reloadEventDataNotification = (notification) => {
        const { eventId } = this.props;
        // this.props.onGetEvent(eventId);
        // set a timeout in milliseconds (1 ms  ...  2 minutes)
        if (notification.eventId === eventId) {
            let triggeredEventRefresh = localStorage.getItem('triggeredEventReload');
            if (triggeredEventRefresh !== 'true') {
                localStorage.setItem('triggeredEventReload', 'true');
                let randomIntervalInMilliseconds = Math.floor(Math.random() * (120000 - 1 + 1)) + 1;
                this.eventReloadTimer = setTimeout(this.reloadEventData, randomIntervalInMilliseconds);
            }
        }
    };

    reloadExhibitorDataNotification = (notification) => {
        if (notification.exhibitorId === this.props.activeExhibitorId) {
            this.props.onGetExhibitor(this.props.activeExhibitorId);
        }
    };

    newPrivateChatMessageNotification = (data) => {
        // if we have the private chat, we update the last message of that chat
        // if we don't have the private chat, it means it is a newly created chat that we don't have
        // and we refresh all the user chats
        // we do the same for the group chats
        let privateChatExists = this.props.user.privateChats.find(
            (chat) => chat.chat._id === data.notification.objectId
        );
        if (privateChatExists) {
            this.props.newPrivateChatMessage(data);
        } else {
            this.props.onGetPrivateChats();
        }
    };

    newGroupChatMessageNotification = (data) => {
        let groupChatExists = this.props.user.groupChats.find((chat) => chat.chat._id === data.notification.objectId);
        if (groupChatExists) {
            this.props.newGroupChatMessage(data);
        } else {
            this.props.onGetGroupChats();
        }
    };

    removedFromGroupChatNotification = (notification) => {
        if (notification.objectId === this.props.activeChatRoomId) {
            this.props.onCloseTopNav();
            // show a snackbar to inform the user he has been removed from the group chat
            // Benji request: do not show snackbar notification, kept the rest of the functionality
            // this.setState({snackbar: {...this.state.snackbar, open: true}});
            this.props.onGetGroupChats();
        } else {
            this.props.onGetGroupChats();
        }
    };

    removedFromEventNotification = (notification) => {
        if (notification.eventId === this.props.eventId && this.props.event.owner._id !== this.props.user._id) {
            this.setState({ showRemovedFromEventDialog: true });
        } else {
            this.props.onRefreshUserData();
        }
    };

    reloadEventData = () => {
        const { eventId } = this.props;
        localStorage.setItem('triggeredEventReload', 'false');
        this.props.onReloadEventData(eventId);
    };

    handleCloseSnackbar = () => {
        this.setState({ snackbar: { ...this.state.snackbar, open: false } });
    };

    handleLogoutUser = () => {
        // if user logged out we should also disconnect him from notifications
        this.setState({ showRemovedFromEventDialog: false });
        disconnectFromNotifications(this.props.user._id);
        this.props.onLogoutUser();
    };

    handleCloseModal = () => {
        this.props.onRefreshUserData();
        this.setState({ showRemovedFromEventDialog: false });
    };

    render() {
        const { layout, loggedIn, eventId } = this.props;
        if (layout.menu.noMenu) {
            return null
        }

        return (
            <div onDragStart={preventDefaultDrag} className="menu">
                {layout.menu.platformMenu && <PlatformMenu />}
                {layout.menu.eventPublicMenu && <HeaderMenu />}
                {layout.menu.eventProtectedMenu && loggedIn && eventId && !isYandex && !isIE && <EventMenu />}
                {layout.menu.adminMenu && loggedIn && <AdminMenu />}
                {layout.menu.sessionGuestMenu && <SessionGuestMenu />}
                <RedirectOnLogin />
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    key={this.state.snackbar.message}
                    open={this.state.snackbar.open}
                    onClose={this.handleCloseSnackbar}
                    autoHideDuration={10000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={
                        <span onDragStart={preventDefaultDrag} draggable="false" id="message-id">
                            {this.state.snackbar.message}
                        </span>
                    }
                />
                {this.state.showRemovedFromEventDialog && (
                    <FullOverlay
                        title="Event is unavailable"
                        text={'You have been removed from this event.'}
                        buttonText="Log out"
                        closeOnRedirect={true}
                        handleClose={this.handleCloseModal}
                        handleClick={this.handleLogoutUser}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        user: state.user.data,
        layout: state.layout,
        eventId: state.event.eventId,
        event: state.event.data,
        userConnectedToNotifications: state.user.connectedToNotifications,
        activeChatRoomId: state.user.topNavigation.activeChatRoomId,
        activeExhibitorId: state.exhibitors.activeExhibitorId,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        onSetUserConnectedToNotifications: () => dispatch(actions.setUserConnectedToNotifications()),
        onNewNotification: (notification) => dispatch(actions.newNotification(notification)),
        onFlashNotificationReceived: (notification) => dispatch(actions.newFlashNotification(notification)),
        newPrivateChatMessage: (data) => dispatch(actions.newPrivateChatMessage(data)),
        newGroupChatMessage: (data) => dispatch(actions.newGroupChatMessage(data)),
        onGetPrivateChats: () => dispatch(actions.getPrivateChats()),
        onGetGroupChats: () => dispatch(actions.getGroupChats()),
        onReloadEventData: (eventId) => dispatch(actions.reloadEventData(eventId)),
        onCloseTopNav: () => dispatch(actions.topNavClose()),
        onRefreshUserData: () => dispatch(actions.refreshUserData()),
        onLogoutUser: () => dispatch(actions.logoutUser()),
        onGetExhibitor: (exhibitorId) => dispatch(actions.getExhibitor(exhibitorId)),
        onUpdateTimeslotSession: (data) => dispatch(actions.updateTimeslotSession(data)),
        onGetEvent: (eventId) => dispatch(actions.getEvent(eventId)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
