export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SERVER_DOWN = 'SERVER_DOWN';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';

export const SET_PLATFORM_MENU = 'SET_PLATFORM_MENU';
export const SET_EVENT_PUBLIC_MENU = 'SET_EVENT_PUBLIC_MENU';
export const SET_EVENT_PROTECTED_MENU = 'SET_EVENT_PROTECTED_MENU';
export const SET_ADMIN_MENU = 'SET_ADMIN_MENU';
export const SET_NO_MENU = 'SET_NO_MENU';
export const SET_SESSION_GUEST_MENU = 'SET_SESSION_GUEST_MENU';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_IS_TABLET = 'SET_IS_TABLET';
export const SET_IS_LARGE_SCREEN = 'SET_IS_LARGE_SCREEN';

export const TOP_NAV_SEE_CONTACTS = 'TOP_NAV_SEE_CONTACTS';
export const TOP_NAV_SEE_MEETINGS = 'TOP_NAV_SEE_MEETINGS';
export const TOP_NAV_SEE_MEETINGS_MOBILE = 'TOP_NAV_SEE_MEETINGS_MOBILE';
export const TOP_NAV_SEE_PROGRAM = 'TOP_NAV_SEE_PROGRAM';
export const TOP_NAV_SEE_PROGRAM_FROM_NOTIFICATIONS = 'TOP_NAV_SEE_PROGRAM_FROM_NOTIFICATIONS';
export const TOP_NAV_SEE_CHATS = 'TOP_NAV_SEE_CHATS';
export const SEE_HELP_CHAT_OPEN = 'SEE_HELP_CHAT_OPEN';
export const SEE_HELP_CHAT_CLOSE = 'SEE_HELP_CHAT_CLOSE';
export const TOP_NAV_SEE_NOTIFICATIONS = 'TOP_NAV_SEE_NOTIFICATIONS';
export const TOP_NAV_SEE_MY_EVENTS = 'TOP_NAV_SEE_MY_EVENTS';
export const TOP_NAV_SEE_MY_BOOTHS = 'TOP_NAV_SEE_MY_BOOTHS';
export const TOP_NAV_SEE_CONTACTS_MOBILE = 'TOP_NAV_SEE_CONTACTS_MOBILE';
export const TOP_NAV_SEE_PROGRAM_FROM_NOTIFICATIONS_MOBILE = 'TOP_NAV_SEE_PROGRAM_FROM_NOTIFICATIONS_MOBILE';
export const TOP_NAV_SEE_PROGRAM_MOBILE = 'TOP_NAV_SEE_PROGRAM_MOBILE';
export const TOP_NAV_SEE_CHATS_MOBILE = 'TOP_NAV_SEE_CHATS_MOBILE';
export const TOP_NAV_SEE_NOTIFICATIONS_MOBILE = 'TOP_NAV_SEE_NOTIFICATIONS_MOBILE';
export const TOP_NAV_SEE_MY_EVENTS_MOBILE = 'TOP_NAV_SEE_MY_EVENTS_MOBILE';
export const TOP_NAV_SEE_MY_ACCOUNT = 'TOP_NAV_SEE_MY_ACCOUNT';
export const TOP_NAV_SEE_MY_ACCOUNT_MOBILE = 'TOP_NAV_SEE_MY_ACCOUNT_MOBILE';
export const TOP_NAV_CLOSE = 'TOP_NAV_CLOSE';
export const TOP_NAV_CLOSE_MOBILE = 'TOP_NAV_CLOSE_MOBILE';
export const BOTTOM_SEE_ROOMS = 'BOTTOM_SEE_ROOMS';
export const EXPAND_CHATS = 'EXPAND_CHATS';

export const SIDE_NAV_SEE_NETWORKING = 'SIDE_NAV_SEE_NETWORKING';
export const SIDE_NAV_SEE_LIVE_WALL = 'SIDE_NAV_SEE_WALL';
export const SIDE_NAV_SEE_VIDEO_WALL = 'SIDE_NAV_SEE_VIDEO_WALL';
export const SIDE_NAV_CLOSE = 'SIDE_NAV_CLOSE';
export const SIDE_INFO_TAB = 'SIDE_INFO_TAB';

export const SIDE_NAV_HAS_VIDEO_WALL = 'SIDE_NAV_HAS_VIDEO_WALL';
export const SET_ACTIVE_WALL = 'SET_ACTIVE_WALL';
export const SET_TIME_SLOT_VIDEO_WALL_ID = 'SET_TIME_SLOT_VIDEO_WALL_ID';

export const SET_NOT_READ_CHAT_MESSAGES = 'SET_NOT_READ_CHAT_MESSAGES';

export const LOGIN_USER_START = 'LOGIN_USER_START';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';
export const SET_USER_LOADING_FALSE = 'SET_USER_LOADING_FALSE';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const REGISTER_USER_START = 'REGISTER_USER_START';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';

export const CREATE_REGISTRATION_CODE_START = 'CREATE_REGISTRATION_CODE_START';
export const CREATE_REGISTRATION_CODE_SUCCESS = 'CREATE_REGISTRATION_CODE_SUCCESS';
export const CREATE_REGISTRATION_CODE_FAIL = 'CREATE_REGISTRATION_CODE_FAIL';

export const SET_EVENT_ID = 'SET_EVENT_ID';
export const SET_EVENT_SLUG = 'SET_EVENT_SLUG';

export const SET_EVENT_ID_BY_SLUG_START = 'SET_EVENT_ID_BY_SLUG_START';
export const SET_EVENT_ID_BY_SLUG_SUCCESS = 'SET_EVENT_ID_BY_SLUG_SUCCESS';
export const SET_EVENT_ID_BY_SLUG_FAIL = 'SET_EVENT_ID_BY_SLUG_FAIL';
export const SET_EVENT_NOT_FOUND = 'SET_EVENT_NOT_FOUND';

export const CREATE_EVENT_START = 'CREATE_EVENT_START';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAIL = 'CREATE_EVENT_FAIL';

export const REGISTER_PARTICIPANT_START = 'REGISTER_PARTICIPANT_START';
export const REGISTER_PARTICIPANT_SUCCESS = 'REGISTER_PARTICIPANT_SUCCESS';
export const REGISTER_PARTICIPANT_FAIL = 'REGISTER_PARTICIPANT_FAIL';

export const REGISTER_EXHIBITOR_START = 'REGISTER_EXHIBITOR_START';
export const REGISTER_EXHIBITOR_SUCCESS = 'REGISTER_EXHIBITOR_SUCCESS';
export const REGISTER_EXHIBITOR_FAIL = 'REGISTER_EXHIBITOR_FAIL';

export const LOGOUT_USER_START = 'LOGOUT_USER_START';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAIL = 'LOGOUT_USER_FAIL';

export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

// platform level
export const GET_ONLINE_USERS_START = 'GET_ONLINE_USERS_START';
export const GET_ONLINE_USERS_SUCCESS = 'GET_ONLINE_USERS_SUCCESS';
export const GET_ONLINE_USERS_FAIL = 'GET_ONLINE_USERS_FAIL';

// event level
export const GET_EVENT_ONLINE_USERS_START = 'GET_EVENT_ONLINE_USERS_START';
export const GET_EVENT_ONLINE_USERS_SUCCESS = 'GET_EVENT_ONLINE_USERS_SUCCESS';
export const GET_EVENT_ONLINE_USERS_FAIL = 'GET_EVENT_ONLINE_USERS_FAIL';
export const SET_EVENT_ONLINE_USER = 'SET_EVENT_ONLINE_USER';

export const GET_PARTICIPANTS_START = 'GET_PARTICIPANTS_START';
export const GET_PARTICIPANTS_SUCCESS = 'GET_PARTICIPANTS_SUCCESS';
export const GET_PARTICIPANTS_FAIL = 'GET_PARTICIPANTS_FAIL';

export const GET_EXHIBITORS_START = 'GET_EXHIBITORS_START';
export const GET_EXHIBITORS_SUCCESS = 'GET_EXHIBITORS_SUCCESS';
export const GET_EXHIBITORS_FAIL = 'GET_EXHIBITORS_FAIL';
export const SET_ACTIVE_EXHIBITOR_ID = 'SET_ACTIVE_EXHIBITOR_ID';

export const GET_EXHIBITOR_START = 'GET_EXHIBITOR_START';
export const GET_EXHIBITOR_SUCCESS = 'GET_EXHIBITOR_SUCCESS';
export const GET_EXHIBITOR_FAIL = 'GET_EXHIBITOR_FAIL';

export const SEND_CONTACT_REQUEST_START = 'SEND_CONTACT_REQUEST_START';
export const SEND_CONTACT_REQUEST_SUCCESS = 'SEND_CONTACT_REQUEST_SUCCESS';
export const SEND_CONTACT_REQUEST_FAIL = 'SEND_CONTACT_REQUEST_FAIL';

export const ACCEPT_CONTACT_REQUEST_START = 'ACCEPT_CONTACT_REQUEST_START';
export const ACCEPT_CONTACT_REQUEST_SUCCESS = 'ACCEPT_CONTACT_REQUEST_SUCCESS';
export const ACCEPT_CONTACT_REQUEST_FAIL = 'ACCEPT_CONTACT_REQUEST_FAIL';

export const DECLINE_CONTACT_REQUEST_START = 'DECLINE_CONTACT_REQUEST_START';
export const DECLINE_CONTACT_REQUEST_SUCCESS = 'DECLINE_CONTACT_REQUEST_SUCCESS';
export const DECLINE_CONTACT_REQUEST_FAIL = 'DECLINE_CONTACT_REQUEST_FAIL';

export const GET_SENT_REQUESTS_START = 'GET_SENT_REQUESTS_START';
export const GET_SENT_REQUESTS_SUCCESS = 'GET_SENT_REQUESTS_SUCCESS';
export const GET_SENT_REQUESTS_FAIL = 'GET_SENT_REQUESTS_FAIL';

export const GET_RECEIVED_REQUESTS_START = 'GET_RECEIVED_REQUESTS_START';
export const GET_RECEIVED_REQUESTS_SUCCESS = 'GET_RECEIVED_REQUESTS_SUCCESS';
export const GET_RECEIVED_REQUESTS_FAIL = 'GET_RECEIVED_REQUESTS_FAIL';

export const GET_CONTACTS_START = 'GET_CONTACTS_START';
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
export const GET_CONTACTS_FAIL = 'GET_CONTACTS_FAIL';

export const GET_MEETINGS_START = 'GET_MEETINGS_START';
export const GET_MEETINGS_SUCCESS = 'GET_MEETINGS_SUCCESS';
export const GET_MEETINGS_FAIL = 'GET_MEETINGS_FAIL';

export const GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';

export const GET_GROUP_CHATS_START = 'GET_GROUP_CHATS_START';
export const GET_GROUP_CHATS_SUCCESS = 'GET_GROUP_CHATS_SUCCESS';
export const GET_GROUP_CHATS__FAIL = 'GET_GROUP_CHATS__FAIL';

export const GET_PRIVATE_CHATS_START = 'GET_PRIVATE_CHATS_START';
export const GET_PRIVATE_CHATS_SUCCESS = 'GET_PRIVATE_CHATS_SUCCESS';
export const GET_PRIVATE_CHATS__FAIL = 'GET_PRIVATE_CHATS__FAIL';

export const MARK_NOTIFICATION_AS_READ_START = 'MARK_NOTIFICATION_AS_READ_START';
export const MARK_NOTIFICATION_AS_READ_SUCCESS = 'MARK_NOTIFICATION_AS_READ_SUCCESS';
export const MARK_NOTIFICATION_AS_READ_FAIL = 'MARK_NOTIFICATION_AS_READ_FAIL';

export const GET_LIVE_WALL_START = 'GET_LIVE_WALL_START';
export const GET_LIVE_WALL_SUCCESS = 'GET_LIVE_WALL_SUCCESS';
export const GET_LIVE_WALL_FAIL = 'GET_LIVE_WALL_FAIL';

export const GET_EVENT_START = 'GET_EVENT_START';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_FAIL = 'GET_EVENT_FAIL';

export const GET_EVENT_INFO_START = 'GET_EVENT_INFO_START';
export const GET_EVENT_INFO_SUCCESS = 'GET_EVENT_INFO_SUCCESS';
export const GET_EVENT_INFO_FAIL = 'GET_EVENT_INFO_FAIL';

export const EVENT_SETTING_UPDATED = 'EVENT_SETTING_UPDATED';

export const GET_PROGRAM_START = 'GET_PROGRAM_START';
export const GET_PROGRAM_SUCCESS = 'GET_PROGRAM_SUCCESS';
export const GET_PROGRAM_FAIL = 'GET_PROGRAM_FAIL';

export const LIKE_POST_START = 'LIKE_POST_START';
export const LIKE_POST_SUCCESS = 'LIKE_POST_SUCCESS';
export const LIKE_POST_FAIL = 'LIKE_POST_FAIL';

export const UNLIKE_POST_START = 'UNLIKE_POST_START';
export const UNLIKE_POST_SUCCESS = 'UNLIKE_POST_SUCCESS';
export const UNLIKE_POST_FAIL = 'UNLIKE_POST_FAIL';

export const CREATE_GROUP_CHAT_START = 'CREATE_GROUP_CHAT_START';
export const CREATE_GROUP_CHAT_SUCCESS = 'CREATE_GROUP_CHAT_SUCCESS';
export const CREATE_GROUP_CHAT_FAIL = 'CREATE_GROUP_CHAT_FAIL';

export const CREATE_PRIVATE_CHAT_START = 'CREATE_PRIVATE_CHAT_START';
export const CREATE_PRIVATE_CHAT_SUCCESS = 'CREATE_PRIVATE_CHAT_SUCCESS';
export const CREATE_PRIVATE_CHAT_FAIL = 'CREATE_PRIVATE_CHAT_FAIL';

export const OPEN_PRIVATE_CHAT = 'OPEN_PRIVATE_CHAT';
export const OPEN_GROUP_CHAT = 'OPEN_GROUP_CHAT';

export const SEND_MESSAGE_IN_PRIVATE_CHAT_START = 'SEND_MESSAGE_IN_PRIVATE_CHAT_START';
export const SEND_MESSAGE_IN_PRIVATE_CHAT_SUCCESS = 'SEND_MESSAGE_IN_PRIVATE_CHAT_SUCCESS';
export const SEND_MESSAGE_IN_PRIVATE_CHAT_FAIL = 'SEND_MESSAGE_IN_PRIVATE_CHAT_FAIL';

export const ADD_SPEAKER_START = 'ADD_SPEAKER_START';
export const ADD_SPEAKER_SUCCESS = 'ADD_SPEAKER_SUCCESS';
export const ADD_SPEAKER_FAIL = 'ADD_SPEAKER_FAIL';

export const REMOVE_SPEAKER_START = 'REMOVE_SPEAKER_START';
export const REMOVE_SPEAKER_SUCCESS = 'REMOVE_SPEAKER_SUCCESS';
export const REMOVE_SPEAKER_FAIL = 'REMOVE_SPEAKER_FAIL';

export const ADD_TIME_SLOT_START = 'ADD_TIME_SLOT_START';
export const ADD_TIME_SLOT_SUCCESS = 'ADD_TIME_SLOT_SUCCESS';
export const ADD_TIME_SLOT_FAIL = 'ADD_TIME_SLOT_FAIL';

export const UPDATE_TIMESLOT_SESSION = 'UPDATE_TIMESLOT_SESSION';
export const SET_ENABLE_MATCHING = 'SET_ENABLE_MATCHING';
export const TOGGLE_MATCHING_DIALOG = 'TOGGLE_MATCHING_DIALOG';
export const CLOSE_MATCHING_BANNER = 'CLOSE_MATCHING_BANNER';

export const NEW_FLASH_NOTIFICATION = 'NEW_FLASH_NOTIFICATION';
export const CLOSE_FLASH_NOTIFICATION = 'CLOSE_FLASH_NOTIFICATION';

export const NEW_PRIVATE_CHAT_MESSAGE = 'NEW_PRIVATE_CHAT_MESSAGE';
export const NEW_GROUP_CHAT_MESSAGE = 'NEW_GROUP_CHAT_MESSAGE';

export const READ_MESSAGES_FOR_PRIVATE_CHAT = 'READ_MESSAGES_FOR_PRIVATE_CHAT';
export const READ_MESSAGES_FOR_GROUP_CHAT = 'READ_MESSAGES_FOR_GROUP_CHAT';
export const RESET_ACTIVE_CHAT_ID = 'RESET_ACTIVE_CHAT_ID';
export const SET_USER_CONNECTED_TO_NOTIFICATIONS = 'SET_USER_CONNECTED_TO_NOTIFICATIONS';

export const REMOVE_USER_FROM_GROUP_CHAT_START = 'REMOVE_USER_FROM_GROUP_CHAT_START';
export const REMOVE_USER_FROM_GROUP_CHAT_SUCCESS = 'REMOVE_USER_FROM_GROUP_CHAT_SUCCESS';
export const REMOVE_USER_FROM_GROUP_CHAT_FAIL = 'REMOVE_USER_FROM_GROUP_CHAT_FAIL';

export const EVENT_ROLES_UPDATED_CORG = 'EVENT_ROLES_UPDATED_CORG';

export const GET_EVENT_ARCHIVE_START = 'GET_EVENT_ARCHIVE_START';
export const GET_EVENT_ARCHIVE_SUCCESS = 'GET_EVENT_ARCHIVE_SUCCESS';
export const GET_EVENT_ARCHIVE_FAIL = 'GET_EVENT_ARCHIVE_FAIL';
export const GET_EVENT_ARCHIVE_RESET = 'GET_EVENT_ARCHIVE_RESET';
export const GET_EVENT_RESET = 'GET_EVENT_RESET';

export const API_CALL_INVITATIONS_START = 'API_CALL_INVITATIONS_START';
export const API_CALL_INVITATIONS_FAIL = 'API_CALL_INVITATIONS_FAIL';
export const GET_INVITATION_CODES_SUCCESS = 'GET_INVITATIONS_SUCCESS';
export const CHECK_INVITATION_CODE_SUCCESS = 'CHECK_INVITATION_CODE_SUCCESS';
export const CHECK_INVITATION_CODE_FAIL = 'CHECK_INVITATION_CODE_FAIL';
export const USE_INVITATION_CODE_SUCCESS = 'USE_INVITATION_CODE_SUCCESS';

export const GET_ORGANIZERS_START = 'GET_ORGANIZERS_START';
export const GET_ORGANIZERS_SUCCESS = 'GET_ORGANIZERS_SUCCESS';
export const GET_ORGANIZERS_FAIL = 'GET_ORGANIZERS_FAIL';

export const APPROVE_ORGANIZER_START = 'APPROVE_ORGANIZER_START';
export const APPROVE_ORGANIZER_SUCCESS = 'APPROVE_ORGANIZER_SUCCESS';
export const APPROVE_ORGANIZER_FAIL = 'APPROVE_ORGANIZER_FAIL';

export const DISABLE_ORGANIZER_START = 'DISABLE_ORGANIZER_START';
export const DISABLE_ORGANIZER_SUCCESS = 'DISABLE_ORGANIZER_SUCCESS';
export const DISABLE_ORGANIZER_FAIL = 'DISABLE_ORGANIZER_FAIL';

export const DELETE_ORGANIZER_START = 'DELETE_ORGANIZER_START';
export const DELETE_ORGANIZER_SUCCESS = 'DELETE_ORGANIZER_SUCCESS';
export const DELETE_ORGANIZER_FAIL = 'DELETE_ORGANIZER_FAIL';

export const GET_BRANDING_TRANSLATION_START = 'GET_BRANDING_TRANSLATION_START';
export const GET_BRANDING_TRANSLATION_SUCCESS = 'GET_BRANDING_TRANSLATION_SUCCESS';
export const GET_BRANDING_TRANSLATION_FAIL = 'GET_BRANDING_TRANSLATION_FAIL';

export const UPDATE_BRANDING_TRANSLATION_START = 'UPDATE_BRANDING_TRANSLATION_START';
export const UPDATE_BRANDING_TRANSLATION_SUCCESS = 'UPDATE_BRANDING_TRANSLATION_SUCCESS';
export const UPDATE_BRANDING_TRANSLATION_FAIL = 'UPDATE_BRANDING_TRANSLATION_FAIL';

export const CREATE_PAYMENT_INTENT_START = 'CREATE_PAYMENT_INTENT_START';
export const CREATE_PAYMENT_INTENT_SUCCESS = 'CREATE_PAYMENT_INTENT_SUCCESS';
export const CREATE_PAYMENT_INTENT_FAIL = 'CREATE_PAYMENT_INTENT_FAIL';

export const REMOVE_STRIPE_SECRET = 'REMOVE_STRIPE_SECRET';

export const CREATE_EXHIBITOR_PAYMENT_INTENT_START = 'CREATE_EXHIBITOR_PAYMENT_INTENT_START';
export const CREATE_EXHIBITOR_PAYMENT_INTENT_SUCCESS = 'CREATE_EXHIBITOR_PAYMENT_INTENT_SUCCESS';
export const CREATE_EXHIBITOR_PAYMENT_INTENT_FAIL = 'CREATE_EXHIBITOR_PAYMENT_INTENT_FAIL';

export const CANCEL_PAYMENT_INTENT = 'CANCEL_PAYMENT_INTENT';

export const ADD_ACCES_PACKAGE_START = 'ADD_ACCES_PACKAGE_START';
export const ADD_ACCES_PACKAGE_SUCCESS = 'ADD_ACCES_PACKAGE_SUCCESS';
export const ADD_ACCES_PACKAGE_FAIL = 'ADD_ACCES_PACKAGE_FAIL';

export const ADD_EXHIBITOR_ACCES_PACKAGE_START = 'ADD_EXHIBITOR_ACCES_PACKAGE_START';
export const ADD_EXHIBITOR_ACCES_PACKAGE_SUCCESS = 'ADD_EXHIBITOR_ACCES_PACKAGE_SUCCESS';
export const ADD_EXHIBITOR_ACCES_PACKAGE_FAIL = 'ADD_EXHIBITOR_ACCES_PACKAGE_FAIL';

export const UPDATE_ACCES_PACKAGE_START = 'UPDATE_ACCES_PACKAGE_START';
export const UPDATE_ACCES_PACKAGE_SUCCESS = 'UPDATE_ACCES_PACKAGE_SUCCESS';
export const UPDATE_ACCES_PACKAGE_FAIL = 'UPDATE_ACCES_PACKAGE_FAIL';

export const UPDATE_EXHIBITOR_ACCES_PACKAGE_START = 'UPDATE_EXHIBITOR_ACCES_PACKAGE_START';
export const UPDATE_EXHIBITOR_ACCES_PACKAGE_SUCCESS = 'UPDATE_EXHIBITOR_ACCES_PACKAGE_SUCCESS';
export const UPDATE_EXHIBITOR_ACCES_PACKAGE_FAIL = 'UPDATE_EXHIBITOR_ACCES_PACKAGE_FAIL';

export const DELETE_ACCES_PACKAGE_START = 'DELETE_ACCES_PACKAGE_START';
export const DELETE_ACCES_PACKAGE_SUCCESS = 'DELETE_ACCES_PACKAGE_SUCCESS';
export const DELETE_ACCES_PACKAGE_FAIL = 'DELETE_ACCES_PACKAGE_FAIL';

export const DELETE_EXHIBITOR_ACCES_PACKAGE_START = 'DELETE_EXHIBITOR_ACCES_PACKAGE_START';
export const DELETE_EXHIBITOR_ACCES_PACKAGE_SUCCESS = 'DELETE_EXHIBITOR_ACCES_PACKAGE_SUCCESS';
export const DELETE_EXHIBITOR_ACCES_PACKAGE_FAIL = 'DELETE_EXHIBITOR_ACCES_PACKAGE_FAIL';

export const SET_USER_EVENT_ROLES = 'SET_USER_EVENT_ROLES';
export const SET_EVENT_ACCESS = 'SET_EVENT_ACCESS';
export const SET_PRESELECTED_PACKAGE_ID = 'SET_PRESELECTED_PACKAGE_ID';
export const SET_EVENT_LANGUAGE = 'SET_EVENT_LANGUAGE';
export const SET_PLATFORM_LANGUAGE = 'SET_PLATFORM_LANGUAGE';
export const SET_ORGANIZER_BRANDING_LANGUAGE = 'SET_ORGANIZER_BRANDING_LANGUAGE';
export const SET_ADMIN_BRANDING_LANGUAGE = 'SET_ADMIN_BRANDING_LANGUAGE';

export const TOP_NAV_ONVENT_PROFILE = 'TOP_NAV_ONVENT_PROFILE';
export const TOP_NAV_EVENT_PROFILE = 'TOP_NAV_EVENT_PROFILE';
export const EXPANDED_CHATS_COMPONENT = 'EXPANDED_CHATS_COMPONENT';

export const VIDEO_CONFERENCE_START_CALLING = 'VIDEO_CONFERENCE_START_CALLING';
export const VIDEO_CONFERENCE_STOP_CALLING = 'VIDEO_CONFERENCE_STOP_CALLING';

export const SET_EDITED_BUILDING = 'SET_EDITED_BUILDING';
export const SET_EDITED_BUILDING_ID = 'SET_EDITED_BUILDING_ID';
export const SET_EDITED_BUILDING_TAB = 'SET_EDITED_BUILDING_TAB';

export const SET_BUILDING_ID = 'SET_BUILDING_ID';
export const GET_BUILDING_START = 'GET_BUILDING_START';
export const GET_BUILDING_SUCCESS = 'GET_BUILDING_SUCCESS';
export const GET_BUILDING_FAIL = 'GET_BUILDING_FAIL';

export const API_CALL_ON_DEMAND_VIDEOS_START = 'API_CALL_ON_DEMAND_VIDEOS_START';
export const API_CALL_ON_DEMAND_VIDEOS_FAIL = 'API_CALL_ON_DEMAND_VIDEOS_FAIL';
export const GET_ON_DEMAND_VIDEOS_SUCCESS = 'GET_ON_DEMAND_VIDEOS_SUCCESS';
export const HAS_ON_DEMAND_VIDEOS_SUCCESS = 'HAS_ON_DEMAND_VIDEOS_SUCCESS';

export const API_CALL_SHOWFLOORS_START = 'API_CALL_SHOWFLOORS_START';
export const API_CALL_SHOWFLOORS_FAIL = 'API_CALL_SHOWFLOORS_FAIL';
export const SET_EDITED_SHOWFLOOR_ID = 'SET_EDITED_SHOWFLOOR_ID';
export const SET_ACTIVE_SHOWFLOOR_ID = 'SET_ACTIVE_SHOWFLOOR_ID';
export const GET_SHOWFLOORS_SUCCESS = 'GET_SHOWFLOORS_SUCCESS';
export const GET_SHOWFLOOR_SUCCESS = 'GET_SHOWFLOOR_SUCCESS';
export const CREATE_SHOWFLOOR_SUCCESS = 'CREATE_SHOWFLOOR_SUCCESS';
export const UPDATE_SHOWFLOOR_SUCCESS = 'UPDATE_SHOWFLOOR_SUCCESS';
export const DELETE_SHOWFLOOR_SUCCESS = 'DELETE_SHOWFLOOR_SUCCESS';

export const REORDER_SHOWFLOORS_REQUEST = 'REORDER_SHOWFLOORS_REQUEST';
export const REORDER_SHOWFLOORS_SUCCESS = 'REORDER_SHOWFLOORS_SUCCESS';
export const REORDER_SHOWFLOORS_FAIL = 'REORDER_SHOWFLOORS_FAIL';
export const GET_ORDERED_SHOWFLOORS_REQUEST = 'GET_ORDERED_SHOWFLOORS_REQUEST';
export const GET_ORDERED_SHOWFLOORS_SUCCESS = 'GET_ORDERED_SHOWFLOORS_SUCCESS';
export const GET_ORDERED_SHOWFLOORS_FAIL = 'GET_ORDERED_SHOWFLOORS_FAIL';

export const TAB_LOG_IN_FORM = 'TAB_LOG_IN_FORM';
export const TAB_SIGN_UP_FORM = 'TAB_SIGN_UP_FORM';

export const ADD_EVENT_PARTICIPANT = 'ADD_EVENT_PARTICIPANT';
export const ADD_EVENT_EXHIBITOR = 'ADD_EVENT_EXHIBITOR';
export const REMOVE_EVENT_PARTICIPANT = 'REMOVE_EVENT_PARTICIPANT';
export const REMOVE_EVENT_EXHIBITOR = 'REMOVE_EVENT_EXHIBITOR';
export const UPDATE_EVENT_PARTICIPANT = 'UPDATE_EVENT_PARTICIPANT';
export const UPDATE_EVENT_EXHIBITOR = 'UPDATE_EVENT_EXHIBITOR';
export const UPDATE_EVENT_ORGANIZER = 'UPDATE_EVENT_ORGANIZER';

export const GET_EVENT_USERS_WITH_ADDITIONAL_DATA_START = 'GET_EVENT_USERS_WITH_ADDITIONAL_DATA_START';
export const GET_EVENT_USERS_WITH_ADDITIONAL_DATA_SUCCESS = 'GET_EVENT_USERS_WITH_ADDITIONAL_DATA_SUCCESS';
export const GET_EVENT_USERS_WITH_ADDITIONAL_DATA_FAIL = 'GET_EVENT_USERS_WITH_ADDITIONAL_DATA_FAIL';
export const USER_PACKAGE_UPDATED_SUCCESS = 'USER_PACKAGE_UPDATED_SUCCESS';
export const REMOVE_USER_FROM_EVENT_SUCCESS = 'REMOVE_USER_FROM_EVENT_SUCCESS';
export const GET_EVENT_USERS_WITH_ROLES_START = 'GET_EVENT_USERS_WITH_ROLES_START';
export const GET_EVENT_USERS_WITH_ROLES_SUCCESS = 'GET_EVENT_USERS_WITH_ROLES_SUCCESS';
export const GET_EVENT_USERS_WITH_ROLES_FAIL = 'GET_EVENT_USERS_WITH_ROLES_FAIL';
export const ADD_EVENT_PARTICIPANT_WITH_ROLES = 'ADD_EVENT_PARTICIPANT_WITH_ROLES';
export const ADD_EVENT_EXHIBITOR_WITH_ROLES = 'ADD_EVENT_EXHIBITOR_WITH_ROLES';
export const UPDATE_EVENT_PARTICIPANT_WITH_ROLES = 'UPDATE_EVENT_PARTICIPANT_WITH_ROLES';
export const UPDATE_EVENT_EXHIBITOR_WITH_ROLES = 'UPDATE_EVENT_EXHIBITOR_WITH_ROLES';

export const GET_CAMPAIGNS_START = 'GET_CAMPAIGNS_START';
export const GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS';
export const GET_CAMPAIGNS_FAIL = 'GET_CAMPAIGNS_FAIL';

export const ADD_CAMPAIGN_START = 'ADD_CAMPAIGN_START';
export const ADD_CAMPAIGN_SUCCESS = 'ADD_CAMPAIGN_SUCCESS';
export const ADD_CAMPAIGN_FAIL = 'ADD_CAMPAIGN_FAIL';

export const UPDATE_CAMPAIGN_START = 'UPDATE_CAMPAIGN_START';
export const UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS';
export const UPDATE_CAMPAIGN_FAIL = 'UPDATE_CAMPAIGN_FAIL';

export const DELETE_CAMPAIGN_START = 'DELETE_CAMPAIGN_START';
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN_FAIL = 'DELETE_CAMPAIGN_FAIL';
export const ADD_CAMPAIGN_TO_QUEUE_START = 'ADD_CAMPAIGN_TO_QUEUE_START';

export const GET_AUDIENCES_START = 'GET_AUDIENCES_START';
export const GET_AUDIENCES_SUCCESS = 'GET_AUDIENCES_SUCCESS';
export const GET_AUDIENCES_FAIL = 'GET_AUDIENCES_FAIL';

export const ADD_AUDIENCE_START = 'ADD_AUDIENCE_START';
export const ADD_AUDIENCE_SUCCESS = 'ADD_AUDIENCE_SUCCESS';
export const ADD_AUDIENCE_FAIL = 'ADD_AUDIENCE_FAIL';

export const UPDATE_AUDIENCE_START = 'UPDATE_AUDIENCE_START';
export const UPDATE_AUDIENCE_SUCCESS = 'UPDATE_AUDIENCE_SUCCESS';
export const UPDATE_AUDIENCE_FAIL = 'UPDATE_AUDIENCE_FAIL';

export const DELETE_AUDIENCE_START = 'DELETE_AUDIENCE_START';
export const DELETE_AUDIENCE_SUCCESS = 'DELETE_AUDIENCE_SUCCESS';
export const DELETE_AUDIENCE_FAIL = 'DELETE_AUDIENCE_FAIL';

export const UPDATE_SINGLE_EVENT_ARCHIVE_SUCCESS = 'UPDATE_SINGLE_EVENT_ARCHIVE_SUCCESS';
export const UPDATE_SINGLE_AUDITORIUM_SUCCESS = 'UPDATE_SINGLE_AUDITORIUM_SUCCESS';

export const UPDATE_AUDITORIUM_RESOURCES = 'UPDATE_AUDITORIUM_RESOURCES';


export const GET_ADMIN_ALL_EVENTS_START = 'GET_ADMIN_ALL_EVENTS_START';
export const GET_ADMIN_ALL_EVENTS_SUCCESS = 'GET_ADMIN_ALL_EVENTS_SUCCESS';
export const GET_ADMIN_ALL_EVENTS_FAIL = 'GET_ADMIN_ALL_EVENTS_FAIL';

// START MATCHING
export const GET_QUESTIONNAIRES_START = 'GET_QUESTIONNAIRES_START';
export const GET_QUESTIONNAIRES_SUCCESS = 'GET_QUESTIONNAIRES_SUCCESS';
export const GET_QUESTIONNAIRES_FAIL = 'GET_QUESTIONNAIRES_FAIL';

export const CREATE_QUESTIONNAIRES_START = 'CREATE_QUESTIONNAIRES_START';
export const CREATE_QUESTIONNAIRES_SUCCESS = 'CREATE_QUESTIONNAIRES_SUCCESS';
export const CREATE_QUESTIONNAIRES_FAIL = 'CREATE_QUESTIONNAIRES_FAIL';

export const UPDATE_QUESTIONNAIRES_START = 'UPDATE_QUESTIONNAIRES_START';
export const UPDATE_QUESTIONNAIRES_SUCCESS = 'UPDATE_QUESTIONNAIRES_SUCCESS';
export const UPDATE_QUESTIONNAIRES_FAIL = 'UPDATE_QUESTIONNAIRES_FAIL';

export const DELETE_QUESTIONNAIRES_START = 'DELETE_QUESTIONNAIRES_START';
export const DELETE_QUESTIONNAIRES_SUCCESS = 'DELETE_QUESTIONNAIRES_SUCCESS';
export const DELETE_QUESTIONNAIRES_FAIL = 'DELETE_QUESTIONNAIRES_FAIL';

export const ADD_QUESTION_START = 'ADD_QUESTION_START';
export const ADD_QUESTION_SUCCESS = 'ADD_QUESTION_SUCCESS';
export const ADD_QUESTION_FAIL = 'ADD_QUESTION_FAIL';

export const UPDATE_QUESTION_START = 'UPDATE_QUESTION_START';
export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS';
export const UPDATE_QUESTION_FAIL = 'UPDATE_QUESTION_FAIL';

export const DELETE_QUESTION_START = 'DELETE_QUESTION_START';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
export const DELETE_QUESTION_FAIL = 'DELETE_QUESTION_FAIL';

export const REORDER_QUESTIONS_SUCCESS = 'REORDER_QUESTIONS_SUCCESS';


export const SAVE_UPDATE_ANSWER_START = 'SAVE_UPDATE_ANSWER_START';
export const SAVE_UPDATE_ANSWER_SUCCESS = 'SAVE_UPDATE_ANSWER_SUCCESS';
export const SAVE_UPDATE_ANSWER_FAIL = 'SAVE_UPDATE_ANSWER_FAIL';

export const CLEAR_MATCHES_ARRAY = 'CLEAR_MATCHES_ARRAY';

export const GET_MATCHING_ANSWERS_START = 'GET_MATCHING_ANSWERS_START';
export const GET_MATCHING_ANSWERS_SUCCESS = 'GET_MATCHING_ANSWERS_SUCCESS';
export const GET_MATCHING_ANSWERS_FAIL = 'GET_MATCHING_ANSWERS_FAIL';

export const REMOVE_USER_MATCHING_DATA_START = 'REMOVE_USER_MATCHING_DATA_START';
export const REMOVE_USER_MATCHING_DATA_SUCCESS = 'REMOVE_USER_MATCHING_DATA_SUCCESS';
export const REMOVE_USER_MATCHING_DATA_FAIL = 'GET_MATCHING_ANSWERS_FAIL';

export const GET_FEATURED_USERS_START = 'GET_FEATURED_USERS_START';
export const GET_FEATURED_USERS_SUCCESS = 'GET_FEATURED_USERS_SUCCESS';
export const GET_FEATURED_USERS_FAIL = 'GET_FEATURED_USERS_FAIL';
// END MATCHING

// SESSIONS
export const GET_SESSIONS_START = 'SESSIONS/GET_SESSIONS_START';
export const GET_SESSIONS_SUCCESS = 'SESSIONS/GET_SESSIONS_SUCCESS';
export const GET_SESSIONS_FAIL = 'SESSIONS/GET_SESSIONS_FAIL';

export const CREATE_SESSIONS_START = 'SESSIONS/CREATE_SESSIONS_START';
export const CREATE_SESSIONS_SUCCESS = 'SESSIONS/CREATE_SESSIONS_SUCCESS';
export const CREATE_SESSIONS_FAIL = 'SESSIONS/CREATE_SESSIONS_FAIL';

export const UPDATE_SESSIONS_START = 'SESSIONS/UPDATE_SESSIONS_START';
export const UPDATE_SESSIONS_SUCCESS = 'SESSIONS/UPDATE_SESSIONS_SUCCESS';
export const UPDATE_SESSIONS_FAIL = 'SESSIONS/UPDATE_SESSIONS_FAIL';

export const DELETE_SESSIONS_START = 'SESSIONS/DELETE_SESSIONS_START';
export const DELETE_SESSIONS_SUCCESS = 'SESSIONS/DELETE_SESSIONS_SUCCESS';
export const DELETE_SESSIONS_FAIL = 'SESSIONS/DELETE_SESSIONS_FAIL';

export const GET_PROFILE_DATA_START = 'SESSIONS/GET_PROFILE_DATA_START';
export const GET_PROFILE_DATA_SUCCESS = 'SESSIONS/GET_PROFILE_DATA_SUCCESS';
export const GET_PROFILE_DATA_FAIL = 'SESSIONS/GET_PROFILE_DATA_FAIL';

export const GET_SESSION_DATA_START = 'SESSIONS/GET_SESSION_DATA_START';
export const GET_SESSION_DATA_SUCCESS = 'SESSIONS/GET_SESSION_DATA_SUCCESS';
export const GET_SESSION_DATA_FAIL = 'SESSIONS/GET_SESSION_DATA_FAIL';

export const SEND_CHAT_MESSAGE_START = 'SESSIONS/SEND_CHAT_MESSAGE_START';
export const SEND_CHAT_MESSAGE_SUCCESS = 'SESSIONS/SEND_CHAT_MESSAGE_SUCCESS';
export const SEND_CHAT_MESSAGE_FAIL = 'SESSIONS/SEND_CHAT_MESSAGE_FAIL';

export const RECIEVE_CHAT_MESSAGE = 'SESSIONS/RECIEVE_CHAT_MESSAGE';

export const REGISTER_SESSION_MEMBER_START = 'SESSIONS/REGISTER_SESSION_MEMBER_START';
export const REGISTER_SESSION_MEMBER_SUCCESS = 'SESSIONS/REGISTER_SESSION_MEMBER_SUCCESS';
export const REGISTER_SESSION_MEMBER_FAIL = 'SESSIONS/REGISTER_SESSION_MEMBER_FAIL';

export const VIDEO_CONFERENCE_READY = 'SESSIONS/VIDEO_CONFERENCE_READY';
export const VIDEO_CONFERENCE_LOADING = 'SESSIONS/VIDEO_CONFERENCE_LOADING';
export const CLOSE_VIDEO_CONFERENCE = 'SESSIONS/CLOSE_VIDEO_CONFERENCE';

export const VIDEO_CONFERENCE_CHANGE_PLAYBACKS = 'SESSIONS/VIDEO_CONFERENCE_CHANGE_PLAYBACKS';

export const VIDEO_CONFERENCE_SHARE_SCREEN_TOGGLED = 'SESSIONS/VIDEO_CONFERENCE_SHARE_SCREEN_TOGGLED';
export const VIDEO_CONFERENCE_FULL_SCREEN_TOGGLED = 'SESSIONS/VIDEO_CONFERENCE_FULL_SCREEN_TOGGLED';

export const SESSION_STREAM_START_REQUEST = 'SESSIONS/SESSION_STREAM_START_REQUEST';
export const SESSION_STREAM_START_SUCCESS = 'SESSIONS/SESSION_STREAM_START_SUCCESS';
export const SESSION_STREAM_START_FAIL = 'SESSIONS/SESSION_STREAM_START_FAIL';

export const SESSION_STREAM_STOP_REQUEST = 'SESSIONS/SESSION_STREAM_STOP_REQUEST';
export const SESSION_STREAM_STOP_SUCCESS = 'SESSIONS/SESSION_STREAM_STOP_SUCCESS';
export const SESSION_STREAM_STOP_FAIL = 'SESSIONS/SESSION_STREAM_STOP_FAIL';

export const SESSION_STREAM_START_RECORD_REQUEST = 'SESSIONS/SESSION_STREAM_START_RECORD_REQUEST';
export const SESSION_STREAM_START_RECORD_SUCCESS = 'SESSIONS/SESSION_STREAM_START_RECORD_SUCCESS';
export const SESSION_STREAM_START_RECORD_FAIL = 'SESSIONS/SESSION_STREAM_START_RECORD_FAIL';

export const SESSION_STREAM_STOP_RECORD_REQUEST = 'SESSIONS/SESSION_STREAM_STOP_RECORD_REQUEST';
export const SESSION_STREAM_STOP_RECORD_SUCCESS = 'SESSIONS/SESSION_STREAM_STOP_RECORD_SUCCESS';
export const SESSION_STREAM_STOP_RECORD_FAIL = 'SESSIONS/SESSION_STREAM_STOP_RECORD_FAIL';

export const CHANGE_SESSION_PERMISSION_REQUEST = 'SESSIONS/CHANGE_SESSION_PERMISSION_REQUEST';
export const CHANGE_SESSION_PERMISSION_SUCCESS = 'SESSIONS/CHANGE_SESSION_PERMISSION_SUCCESS';
export const CHANGE_SESSION_PERMISSION_FAIL = 'SESSIONS/CHANGE_SESSION_PERMISSION_FAIL';

export const ZOOM_SESSION_STREAM_START_REQUEST = 'SESSIONS/ZOOM_SESSION_STREAM_START_REQUEST';
export const ZOOM_SESSION_STREAM_START_SUCCESS = 'SESSIONS/ZOOM_SESSION_STREAM_START_SUCCESS';
export const ZOOM_SESSION_STREAM_START_FAIL = 'SESSIONS/ZOOM_SESSION_STREAM_START_FAIL';

export const ZOOM_SESSION_STREAM_STOP_REQUEST = 'SESSIONS/ZOOM_SESSION_STREAM_STOP_REQUEST';
export const ZOOM_SESSION_STREAM_STOP_SUCCESS = 'SESSIONS/ZOOM_SESSION_STREAM_STOP_SUCCESS';
export const ZOOM_SESSION_STREAM_STOP_FAIL = 'SESSIONS/ZOOM_SESSION_STREAM_STOP_FAIL';

export const ZOOM_SESSION_STREAM_START_RECORD_REQUEST = 'SESSIONS/ZOOM_SESSION_STREAM_START_RECORD_REQUEST';
export const ZOOM_SESSION_STREAM_START_RECORD_SUCCESS = 'SESSIONS/ZOOM_SESSION_STREAM_START_RECORD_SUCCESS';
export const ZOOM_SESSION_STREAM_START_RECORD_FAIL = 'SESSIONS/ZOOM_SESSION_STREAM_START_RECORD_FAIL';

export const ZOOM_SESSION_STREAM_STOP_RECORD_REQUEST = 'SESSIONS/ZOOM_SESSION_STREAM_STOP_RECORD_REQUEST';
export const ZOOM_SESSION_STREAM_STOP_RECORD_SUCCESS = 'SESSIONS/ZOOM_SESSION_STREAM_STOP_RECORD_SUCCESS';
export const ZOOM_SESSION_STREAM_STOP_RECORD_FAIL = 'SESSIONS/ZOOM_SESSION_STREAM_STOP_RECORD_FAIL';

export const SET_ADMIN_ALL_EVENTS_EXCEL_START = 'SET_ADMIN_ALL_EVENTS_EXCEL_START';
export const SET_ADMIN_ALL_EVENTS_EXCEL_SUCCESS = 'SET_ADMIN_ALL_EVENTS_EXCEL_SUCCESS';
export const SET_ADMIN_ALL_EVENTS_EXCEL_FAIL = 'SET_ADMIN_ALL_EVENTS_EXCEL_FAIL';

export const GET_EVENTS_TODAY_START = 'GET_EVENTS_TODAY_START';
export const GET_EVENTS_TODAY_SUCCESS = 'GET_EVENTS_TODAY_SUCCESS';
export const GET_EVENTS_TODAY_FAIL = 'GET_EVENTS_TODAY_FAIL';

export const GET_LIVE_SESSION_START = 'GET_LIVE_SESSION_START';
export const GET_LIVE_SESSION_SUCCESS = 'GET_LIVE_SESSION_SUCCESS';
export const GET_LIVE_SESSION_FAIL = 'GET_LIVE_SESSION_FAIL';

export const GET_LIVE_SESSION_USER_START = 'GET_LIVE_SESSION_USER_START';
export const GET_LIVE_SESSION_USER_SUCCESS = 'GET_LIVE_SESSION_USER_SUCCESS';
export const GET_LIVE_SESSION_USER_FAIL = 'GET_LIVE_SESSION_USER_FAIL';

export const SET_LIVE_SESSION_IS_ACTIVE = 'SET_LIVE_SESSION_IS_ACTIVE';
export const SET_LIVE_SESSION_RECORDS = 'SET_LIVE_SESSION_RECORDS';

export const SET_AUDITORIUM_MUTED = 'SET_AUDITORIUM_MUTED';
export const SET_AUDITORIUM_UNMUTED = 'SET_AUDITORIUM_UNMUTED';

export const GET_PUBLISHED_EVENT_REQUEST = 'GET_PUBLISHED_EVENT_REQUEST';
export const GET_PUBLISHED_EVENT_SUCCESS = 'GET_PUBLISHED_EVENT_SUCCESS';
export const GET_PUBLISHED_EVENT_FAIL = 'GET_PUBLISHED_EVENT_FAIL';

export const GET_DATA_FOR_DOWNLOAD_EXCEL_REQUEST = 'GET_DATA_FOR_DOWNLOAD_EXCEL_REQUEST';
export const GET_DATA_FOR_DOWNLOAD_EXCEL_SUCCESS = 'GET_DATA_FOR_DOWNLOAD_EXCEL_SUCCESS';
export const GET_DATA_FOR_DOWNLOAD_EXCEL_FAIL = 'GET_DATA_FOR_DOWNLOAD_EXCEL_FAIL';

export const GET_EVENT_SPEAKERS_REQUEST = 'GET_EVENT_SPEAKERS_REQUEST';
export const GET_EVENT_SPEAKERS_SUCCESS = 'GET_EVENT_SPEAKERS_SUCCESS';
export const GET_EVENT_SPEAKERS_FAIL = 'GET_EVENT_SPEAKERS_FAIL';
export const GET_EVENT_SPEAKERS_RESET = 'GET_EVENT_SPEAKERS_RESET';

export const ADD_EVENT_SPEAKER_REQUEST = 'ADD_EVENT_SPEAKER_REQUEST';
export const ADD_EVENT_SPEAKER_SUCCESS = 'ADD_EVENT_SPEAKER_SUCCESS';
export const ADD_EVENT_SPEAKER_FAIL = 'ADD_EVENT_SPEAKER_FAIL';
export const ADD_EVENT_SPEAKER_RESET = 'ADD_EVENT_SPEAKER_RESET';

export const UPDATE_EVENT_SPEAKER_REQUEST = 'UPDATE_EVENT_SPEAKER_REQUEST';
export const UPDATE_EVENT_SPEAKER_SUCCESS = 'UPDATE_EVENT_SPEAKER_SUCCESS';
export const UPDATE_EVENT_SPEAKER_FAIL = 'UPDATE_EVENT_SPEAKER_FAIL';
export const UPDATE_EVENT_SPEAKER_RESET = 'UPDATE_EVENT_SPEAKER_RESET';

export const UPDATE_EVENT_SPEAKER_STATUS_REQUEST = 'UPDATE_EVENT_SPEAKER_STATUS_REQUEST';
export const UPDATE_EVENT_SPEAKER_STATUS_SUCCESS = 'UPDATE_EVENT_SPEAKER_STATUS_SUCCESS';
export const UPDATE_EVENT_SPEAKER_STATUS_FAIL = 'UPDATE_EVENT_SPEAKER_STATUS_FAIL';

export const UPDATE_EVENT_SPEAKER_ORDER_REUQEST = 'UPDATE_EVENT_SPEAKER_ORDER_REUQEST';
export const UPDATE_EVENT_SPEAKER_ORDER_SUCCESS = 'UPDATE_EVENT_SPEAKER_ORDER_SUCCESS';
export const UPDATE_EVENT_SPEAKER_ORDER_FAIL = 'UPDATE_EVENT_SPEAKER_ORDER_FAIL';

export const DELETE_EVENT_SPEAKER_REQUEST = 'DELETE_EVENT_SPEAKER_REQUEST';
export const DELETE_EVENT_SPEAKER_SUCCESS = 'DELETE_EVENT_SPEAKER_SUCCESS';
export const DELETE_EVENT_SPEAKER_FAIL = 'DELETE_EVENT_SPEAKER_FAIL';

export const UPLOAD_EVENT_SPEAKER_IMAGE_REQUEST = 'UPLOAD_EVENT_SPEAKER_IMAGE_REQUEST';
export const UPLOAD_EVENT_SPEAKER_IMAGE_SUCCESS = 'UPLOAD_EVENT_SPEAKER_IMAGE_SUCCESS';
export const UPLOAD_EVENT_SPEAKER_IMAGE_FAIL = 'UPLOAD_EVENT_SPEAKER_IMAGE_FAIL';

export const DELETE_EVENT_SPEAKER_IMAGE_REQUEST = 'DELETE_EVENT_SPEAKER_IMAGE_REQUEST';
export const DELETE_EVENT_SPEAKER_IMAGE_SUCCESS = 'DELETE_EVENT_SPEAKER_IMAGE_SUCCESS';
export const DELETE_EVENT_SPEAKER_IMAGE_FAIL = 'DELETE_EVENT_SPEAKER_IMAGE_FAIL';
export const DELETE_EVENT_SPEAKER_IMAGE_RESET = 'DELETE_EVENT_SPEAKER_IMAGE_RESET';

export const SET_USER_MEDIA_STREAM_CAPTURE = 'SET_USER_MEDIA_STREAM_CAPTURE';
export const SET_USER_MEDIA_STREAM_AUDIO_DEVICE_ID = 'SET_USER_MEDIA_STREAM_AUDIO_DEVICE_ID';
export const SET_USER_MEDIA_STREAM_VIDEO_DEVICE_ID = 'SET_USER_MEDIA_STREAM_VIDEO_DEVICE_ID';

export const WELCOME_SCREEN_API_CALL_START = 'WELCOME_SCREEN_API_CALL_START';
export const WELCOME_SCREEN_ERROR = 'WELCOME_SCREEN_ERROR';
export const GET_WELCOME_SCREEN_SUCCESS = 'GET_WELCOME_SCREEN_SUCCESS';

export const GET_SUGGESTIONS_START = 'GET_SUGGESTIONS_START';
export const GET_SUGGESTIONS_SUCCESS = 'GET_SUGGESTIONS_SUCCESS';
export const GET_SUGGESTIONS_FAIL = 'GET_SUGGESTIONS_FAIL';

export const GET_MY_AGENDA_START = 'GET_MY_AGENDA_START';
export const GET_MY_AGENDA_SUCCESS = 'GET_MY_AGENDA_SUCCESS';
export const GET_MY_AGENDA_FAIL = 'GET_MY_AGENDA_FAIL';

export const ADD_SESSION_AGENDA = 'ADD_SESSION_AGENDA';
export const REMOVE_SESSION_AGENDA = 'REMOVE_SESSION_AGENDA';

export const SHOW_PAST_SESSIONS = 'SHOW_PAST_SESSIONS';

export const SEE_PROGRAM_TOOLTIP = 'SEE_PROGRAM_TOOLTIP';

export const GET_PROGRAMME_START = 'GET_PROGRAMME_START';
export const GET_PROGRAMME_SUCCESS = 'GET_PROGRAMME_SUCCESS';
export const GET_PROGRAMME_FAIL = 'GET_PROGRAMME_FAIL';
export const SET_PROGRAMME_SEARCH = 'SET_PROGRAMME_SEARCH';

export const IMPORT_AUDITORIUM_PROGRAM_REQUEST = 'IMPORT_AUDITORIUM_PROGRAM_REQUEST';
export const IMPORT_AUDITORIUM_PROGRAM_SUCCESS = 'IMPORT_AUDITORIUM_PROGRAM_SUCCESS';
export const IMPORT_AUDITORIUM_PROGRAM_FAIL = 'IMPORT_AUDITORIUM_PROGRAM_FAIL';
export const IMPORT_AUDITORIUM_PROGRAM_RESET = 'IMPORT_AUDITORIUM_PROGRAM_RESET';

export const IMPORT_AUDITORIUM_TIMESLOT_IMPORT_REQUEST = 'IMPORT_AUDITORIUM_TIMESLOT_IMPORT_REQUEST';
export const IMPORT_AUDITORIUM_TIMESLOT_IMPORT_SUCCESS = 'IMPORT_AUDITORIUM_TIMESLOT_IMPORT_SUCCESS';
export const IMPORT_AUDITORIUM_TIMESLOT_IMPORT_FAIL = 'IMPORT_AUDITORIUM_TIMESLOT_IMPORT_FAIL';
export const IMPORT_AUDITORIUM_TIMESLOT_IMPORT_RESET = 'IMPORT_AUDITORIUM_TIMESLOT_IMPORT_RESET';

export const GET_EVENT_REGISTRATION_FIELDS_START = 'GET_EVENT_REGISTRATION_FIELDS_START';
export const GET_EVENT_REGISTRATION_FIELDS_SUCCESS = 'GET_EVENT_REGISTRATION_FIELDS_SUCCESS';
export const GET_EVENT_REGISTRATION_FIELDS_FAIL = 'GET_EVENT_REGISTRATION_FIELDS_FAIL';

export const GET_EVENT_BRANDING_START = 'GET_EVENT_BRANDING_START';
export const GET_EVENT_BRANDING_SUCCESS = 'GET_EVENT_BRANDING_SUCCESS';
export const GET_EVENT_BRANDING_FAIL = 'GET_EVENT_BRANDING_FAIL';

export const GET_EVENT_BRANDING_TRANSLATIONS_START = 'GET_EVENT_BRANDING_TRANSLATIONS_START';
export const GET_EVENT_BRANDING_TRANSLATIONS_SUCCESS = 'GET_EVENT_BRANDING_TRANSLATIONS_SUCCESS';
export const GET_EVENT_BRANDING_TRANSLATIONS_FAIL = 'GET_EVENT_BRANDING_TRANSLATIONS_FAIL';

export const USER_NAVIGATED_TO_OTHER_EVENT = 'USER_NAVIGATED_TO_OTHER_EVENT';

export const GET_USER_EVENT_PROFILE_START = 'GET_USER_EVENT_PROFILE_START';
export const GET_USER_EVENT_PROFILE_SUCCESS = 'GET_USER_EVENT_PROFILE_SUCCESS';
export const GET_USER_EVENT_PROFILE_FAIL = 'GET_USER_EVENT_PROFILE_FAIL';
export const TRIGGER_RELOAD_USER_PROFILE = 'TRIGGER_RELOAD_USER_PROFILE';

export const GET_WALL_START = 'GET_WALL_START';
export const GET_WALL_SUCCESS = 'GET_WALL_SUCCESS';
export const GET_WALL_FAIL = 'GET_WALL_FAIL';
export const RESET_WALL_DATA = 'RESET_WALL_DATA';


export const TOGGLE_IS_FEATURED_USER_SUCCESS = 'TOGGLE_IS_FEATURED_USER_SUCCESS';

export const GET_EVENT_FLOORS_REQUEST = 'GET_EVENT_FLOORS_REQUEST';
export const GET_EVENT_FLOORS_SUCCESS = 'GET_EVENT_FLOORS_SUCCESS';
export const GET_EVENT_FLOORS_FAIL = 'GET_EVENT_FLOORS_FAIL';
export const GET_EVENT_FLOORS_RESET = 'GET_EVENT_FLOORS_RESET';

export const ADD_EVENT_FLOOR_REQUEST = 'ADD_EVENT_FLOOR_REQUEST';
export const ADD_EVENT_FLOOR_SUCCESS = 'ADD_EVENT_FLOOR_SUCCESS';
export const ADD_EVENT_FLOOR_FAIL = 'ADD_EVENT_FLOOR_FAIL';
export const ADD_EVENT_FLOOR_RESET = 'ADD_EVENT_FLOOR_RESET';

export const UPDATE_EVENT_FLOOR_REQUEST = 'UPDATE_EVENT_FLOOR_REQUEST';
export const UPDATE_EVENT_FLOOR_SUCCESS = 'UPDATE_EVENT_FLOOR_SUCCESS';
export const UPDATE_EVENT_FLOOR_FAIL = 'UPDATE_EVENT_FLOOR_FAIL';
export const UPDATE_EVENT_FLOOR_RESET = 'UPDATE_EVENT_FLOOR_RESET';

export const UPDATE_EVENT_FLOOR_ORDER_REQUEST = 'UPDATE_EVENT_FLOOR_ORDER_REQUEST';
export const UPDATE_EVENT_FLOOR_ORDER_SUCCESS = 'UPDATE_EVENT_FLOOR_ORDER_SUCCESS';
export const UPDATE_EVENT_FLOOR_ORDER_FAIL = 'UPDATE_EVENT_FLOOR_ORDER_FAIL';

export const UPDATE_EVENT_FLOOR_STATUS_REQUEST = 'UPDATE_EVENT_FLOOR_STATUS_REQUEST';
export const UPDATE_EVENT_FLOOR_STATUS_SUCCESS = 'UPDATE_EVENT_FLOOR_STATUS_SUCCESS';
export const UPDATE_EVENT_FLOOR_STATUS_FAIL = 'UPDATE_EVENT_FLOOR_STATUS_FAIL';

export const DELETE_EVENT_FLOOR_REQUEST = 'DELETE_EVENT_FLOOR_REQUEST';
export const DELETE_EVENT_FLOOR_SUCCESS = 'DELETE_EVENT_FLOOR_SUCCESS';
export const DELETE_EVENT_FLOOR_FAIL = 'DELETE_EVENT_FLOOR_FAIL';