import React, {Component} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import connect from 'react-redux/es/connect/connect';
import {preventDefaultDrag} from '../Utils/utils';

class ColoredScrollbars extends Component {
    scrollbars = React.createRef();

    componentDidMount() {
        if (this.props.scrolltochatid) {
            this.scrollChatIntoView();
        }
    }

    componentDidUpdate(prevProps) {
        // if(prevProps.scrolltochatid!==this.props.scrolltochatid){
        //     this.scrollChatIntoView();
        // }
        if (prevProps.usersentnewmessage !== this.props.usersentnewmessage && this.props.usersentnewmessage === 'yes') {
            this.scrollbars.current.scrollToBottom();
        }
        if (prevProps.chatid !== this.props.chatid) {
            this.scrollbars.current.scrollToBottom();
        }

        // fix for scrolling chat list to the current active chat
        const prevPropsChat = prevProps?.sortedChats?.find(
            (sortedChat) => sortedChat.chat._id === this.props?.scrolltochatid
        );
        const currentPropsChat = this.props?.sortedChats?.find(
            (sortedChat) => sortedChat?.chat?._id === this.props?.scrolltochatid
        );
        if (prevPropsChat?.chat?.lastMessageText !== currentPropsChat?.chat?.lastMessageText) {
            this.scrollChatIntoView();
        }
    }

    state = {
        scrollBottom: true,
    };

    // we use this.props.scrollbottom property for chats, to know if we need to scroll
    // we use this.state.scrollBottom to know if the user has scrolled to top to see older messages so that we can stop the scrollbottom
    handleUpdate = (values) => {
        if (this.state.scrollBottom && this.props.scrollbottom) {
            this.scrollbars.current.scrollToBottom();
        }
    };

    scrollChatIntoView = () => {
        let activeChatElement = document.getElementById(this.props.scrolltochatid);
        if (activeChatElement) {
            activeChatElement.scrollIntoView();
        }
    };

    handleScroll = () => {
        let values = this.scrollbars.current.getValues();
        if (values.top !== 1 && this.props.scrollbottom) {
            this.setState({scrollBottom: false});
        } else {
            this.setState({scrollBottom: true});
        }
    };

    renderView = ({style, ...props}) => {
        let viewStyle = {
            marginTop: 5,
            padding: this.props.coorganizersearch === 'true' ? 13 : 10,
        };

        if (this.props.isRtlLanguage) {
            viewStyle = {...viewStyle, marginLeft: style.marginRight, marginRight: 0};
        }
        return <div onDragStart={preventDefaultDrag} className="box" style={{...style, ...viewStyle}} {...props} />;
    };

    renderThumb = ({style, ...props}) => {
        const thumbStyle = {
            backgroundColor: `#777081`,
            border: `1px solid #FFFFFF`,
            boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.25)`,
            borderRadius: `8px`,
            right: 0,
        };
        return (
            <div
                onDragStart={preventDefaultDrag}
                className="grey-scroll"
                style={{...style, ...thumbStyle}}
                {...props}
            />
        );
    };

    render() {
        //in Scrollbars instead of {...props} we should use {...rest}
        // and then we could use camelCase properties on the ColoredScrollbars
        // https://reactjs.org/warnings/unknown-prop.html
        // const {...rest} = this.props;
        const {isRtlLanguage, dispatch, sortedChats, ...rest} = this.props;

        return (
            <Scrollbars
                ref={this.scrollbars}
                className="scrollbar"
                renderView={this.renderView}
                onScroll={this.handleScroll}
                renderThumbVertical={this.renderThumb}
                onUpdate={this.handleUpdate}
                {...rest}
            />
        );
    }
}
const mapStateToProps = (state) => {
    return {
        isRtlLanguage: state.languages.isRtlLanguage,
    };
};

export default connect(mapStateToProps)(ColoredScrollbars);
