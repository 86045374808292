import React from 'react';
import isEqual from 'lodash/isEqual';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import axios from '../../store/axios-instance';
import '../../CSS/myAccount.scss';
import * as actions from '../../store/actions/index';
import {preventDefaultDrag} from '../../Utils/utils';
import MyAccountMobile from './AccountMobile/MyAccountMobile';
import LeftSideMenu from '../../Components/LeftSideMenu';
import AccountNavigation from './AccountNavigation';
import AccountDetails from './AccountDetails';
import AccountEvents from './AccountEvents';
import AccountGDPRTemplate from './GDPR/AccountGDPRTemplate';
import AccountManageNotifications from './Notifications/AccountManageNotifications';
import AccountMatching from './AccountMatching';
import cloneDeep from 'lodash/cloneDeep';
import {EXHIBITOR_TYPES} from '../../Utils/constants/shared';

class MyAccount extends React.Component {
    wrapperRef = React.createRef();

    constructor(props) {
        super(props);
        const {defaultTranslation, languages} = props;
        this.state = {
            participantData: null,
            exhibitorData: null,
            predefinedRegistrationFields: [],
            tabsList: [
                {
                    name: 'accountDetails',
                    label: `${
                        languages.translations[languages.platformLanguage]?.myAccountPage.accountTab ||
                        defaultTranslation?.myAccountPage.accountTab
                    }`,
                },
                {
                    name: 'matchingForm',
                    label: `${
                        languages.translations[languages.platformLanguage]?.myAccountPage.matchingForm ||
                        defaultTranslation?.myAccountPage.matchingForm
                    }`,
                },
                {
                    name: 'accountEvents',
                    label: `${
                        languages.translations[languages.platformLanguage]?.myEventsDropdown.title ||
                        defaultTranslation?.myEventsDropdown.title
                    }`,
                },
                {
                    name: 'manageNotifications',
                    label: `${
                        languages.translations[languages.platformLanguage]?.myAccountPage.manageNotifications ||
                        defaultTranslation?.myAccountPage.manageNotifications
                    }`,
                },
                {
                    name: 'privacyPolicy',
                    label: `${
                        languages.translations[languages.platformLanguage]?.gdpr.metaTitlePrivacyPolicy ||
                        defaultTranslation?.gdpr.metaTitlePrivacyPolicy
                    }`,
                },
                {
                    name: 'cookies',
                    label: `${
                        languages.translations[languages.platformLanguage]?.myAccountPage.cookiesLink ||
                        defaultTranslation?.myAccountPage.cookiesLink
                    }`,
                },
                {
                    name: 'terms',
                    label: `${
                        languages.translations[languages.platformLanguage]?.myAccountPage.termsAndConditionsTab ||
                        defaultTranslation?.myAccountPage.termsAndConditionsTab
                    }`,
                },
            ],
            selectedTab: 'accountDetails',
            matchingDetailsPrecompleted: false,
            answers: [],
            answersClone: [],
            submitMatchingAnswersDisabled: true,
            submitMatchingAnswersRequired: false,
            openConfirmUnsavedChanges: false,
            openConfirmRemoveMatchingData: false,
            confirmRemoveMatchingData: false,
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        const {event} = this.props;
        this.props.onSetEventProtectedMenu();

        this.setRegistrationFieldsData();

        this.props.closeTopNavigation();

        const hasMatchingEnabled = event?.enableMatching || event?.hasMatching;
        if (hasMatchingEnabled) this.getMatchingData();
        this.setState({matchingDetailsPrecompleted: false});

        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        const {event, eventRoles, triggerReloadUserProfile, eventId, user} = this.props;
        const hasMatchingEnabled = event?.enableMatching || event?.hasMatching;
        // force a state update if the user changed something in a participant or exhibitor
        if(!prevProps.triggerReloadUserProfile  && triggerReloadUserProfile){
            this.props.onGetUserEventProfile(eventId, user._id);
            this.props.getEventRegistrationFields(eventId);
        }

        if (prevProps.eventRoles.isExhibitor === false && eventRoles.isExhibitor === true) {
            this.setRegistrationFieldsData();
        }
        if (prevProps.event._id !== event._id) {
            if (hasMatchingEnabled) this.getMatchingData();
            this.setRegistrationFieldsData();
        }

        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.updateTranslationsForButtonsLabels();
        }

        if (!isEqual(prevProps.userEventProfile, this.props.userEventProfile)) {
            this.setRegistrationFieldsData();
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('sticky-menu');
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(e) {
        const {submitMatchingAnswersDisabled, submitMatchingAnswersRequired} = this.state;
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(e.target)) {
            if (!submitMatchingAnswersDisabled && !submitMatchingAnswersRequired) {
                this.setState({openConfirmUnsavedChanges: true});
            }
        }
    }

    closeClickOutside = () => this.setState({openConfirmUnsavedChanges: false});

    getRegistrationType = () => {
        axios({method: 'get', url: `/users/get-registration-type`})
            .then((response) => {
                this.setState({registrationType: response.data.registrationType});
            })
            .catch((error) => {});
    };

    setRegistrationFieldsData = () => {
        const {eventRoles, eventId, registrationFields} = this.props;
        // in getEvent we don't receive the

        if (!registrationFields.fetched) {
            this.props.getEventRegistrationFields(eventId).then(() => {
                if (eventRoles.isExhibitor) {
                    this.setExhibitorData();
                } else {
                    this.setParticipantData();
                }
            });
        } else {
            if (eventRoles.isExhibitor) {
                this.setExhibitorData();
            } else {
                this.setParticipantData();
            }
        }
    };

    setParticipantData = () => {
        const {registrationFields, userEventProfile} = this.props;
        let participantData = cloneDeep(userEventProfile);
        this.setState({participantData: participantData});

        // get participant predefined fields
        const {participantPredefinedRegistrationFields} = registrationFields?.data;
        let predefinedRegistrationFields = [];
        Object.keys(participantPredefinedRegistrationFields).map((key) => {
            const participantPredefinedField = participantPredefinedRegistrationFields[key];
            if (participantPredefinedField.isEnabled) {
                let fieldLabel = participantPredefinedField.label;
                predefinedRegistrationFields.push({label: fieldLabel, value: key});
            }
            return null;
        });
        this.setState({predefinedRegistrationFields: predefinedRegistrationFields});
    };

    handleOpenEditUserDialog = () => {
        this.setState({editUserDialog: true});
    };

    handleOpenEditEventProfileDialog = () => {
        const {exhibitorData, participantData} = this.state;
        const {eventRoles} = this.props;
        if (eventRoles.isExhibitor && exhibitorData && exhibitorData.type === EXHIBITOR_TYPES.scholar) {
            this.handleOpenEditScholarContactPersonDialog();
        } else if (eventRoles.isExhibitor && exhibitorData && exhibitorData.type === EXHIBITOR_TYPES.company) {
            this.handleOpenEditExhibitorContactPersonDialog();
        } else if (!eventRoles.isExhibitor && participantData) {
            this.handleOpenEditParticipantDialog();
        }
    };

    handleOpenEditParticipantDialog = () => {
        this.setState({editParticipantDialog: true});
    };

    handleOpenEditExhibitorContactPersonDialog = () => {
        this.setState({editExhibitorContactPersonDialog: true});
    };

    handleOpenEditScholarContactPersonDialog = () => {
        this.setState({editScholarContactPersonDialog: true});
    };

    handleOpenCreateEventProfileDialog = () => {
        this.setState({createParticipantProfileDialog: true});
    };

    handleCloseDialog = () => {
        this.setState({
            editUserDialog: false,
            editParticipantDialog: false,
            editExhibitorContactPersonDialog: false,
            editScholarContactPersonDialog: false,
            createParticipantProfileDialog: false,
        });
    };

    handleRemoveUserAvatar = () => {
        axios({method: 'delete', url: '/users/me/avatar'})
            .then((response) => {
                this.props.onRefreshUserData();
            })
            .catch((error) => {});
    };

    handleImageChange = (e) => {
        const {languages} = this.props;
        e.preventDefault();
        let file = e.target.files[0];

        let isValid = true;

        if (file) {
            isValid = file.size < 2 * 1024 * 1024 && isValid;
            if (!isValid) {
                this.setState({
                    imgErrorText: languages.translations[languages.platformLanguage]?.errors.fileTooLarge,
                });
            }

            isValid =
                (file.type === 'image/png' ||
                    file.type === 'image/jpg' ||
                    file.type === 'image/jpeg' ||
                    file.type === 'image/gif') &&
                isValid;
            if (
                file.type !== 'image/png' &&
                file.type !== 'image/jpg' &&
                file.type !== 'image/jpeg' &&
                file.type !== 'image/gif'
            ) {
                this.setState({
                    imgErrorText: `${
                        languages.translations[languages.platformLanguage]?.errors.imageFileNotSupported
                    } jpeg, jpg, jfif, gif or png.`,
                });
            }
        }

        if (isValid) {
            this.setState({imgErrorText: ''});
            const formData = new FormData();

            formData.append('avatar', file, file.name, file.type);
            axios({method: 'post', url: '/users/me/avatar', data: formData})
                .then(() => {
                    this.props.onRefreshUserData();
                })
                .catch(() => this.props.onRefreshUserData());
        }

        e.target.value = '';
    };

    handleChangeUserLanguage = (e) => {
        const languageCode = e.target.value;
        axios({method: 'put', url: '/users', data: {language: languageCode}})
            .then((response) => {
                this.props.onSetPlatformLanguage(languageCode);
                this.props.onRefreshUserData();
            })
            .catch((error) => {});
    };

    getMatchingData = () => {
        const {eventId, onGetQuestionnaires} = this.props;
        onGetQuestionnaires(eventId).then(() => this.setEmptyQuestions());
    };

    getQuestionsIdsFromMatchingForm = () => {
        const {eventMatching} = this.props;

        if (!eventMatching?.questions) return [];

        // create an array of questions ids
        let questionsIds = [];
        eventMatching?.questions.forEach((question) => questionsIds.push(question._id));

        return questionsIds;
    };

    setEmptyQuestions = () => {
        const {eventId, eventMatching, onGetUserMatchingAnswers} = this.props;
        let questionsClone = JSON.parse(JSON.stringify(eventMatching?.questions));
        // trim down and rename current eventMatching questions
        let questionAllowed = ['questionId', 'answerIds'];
        let reduceQuestions = questionsClone?.map((el) => {
            delete Object.assign(el, {questionId: el['_id']})['_id'];
            delete Object.assign(el, {answerIds: el['answers']})['answers'];
            return Object.keys(el)
                .filter((key) => questionAllowed.includes(key))
                .reduce((obj, key) => {
                    obj[key] = el[key];
                    if (key === 'answerIds') obj[key] = [];
                    return obj;
                }, {});
        });
        // clone for arrays equality comparation, set state then in setState callback
        // get urrent participant answered questions then
        // populate the answers in state

        const questionIds = this.getQuestionsIdsFromMatchingForm();
        const data = {
            questionIds,
        };

        let questionsEqualityClone = JSON.parse(JSON.stringify(reduceQuestions));
        this.setState({answers: reduceQuestions, answersClone: questionsEqualityClone}, () =>
            onGetUserMatchingAnswers(eventId, eventMatching?._id, data).then(() => this.setParticipantAnswers())
        );
    };

    setParticipantAnswers = () => {
        const {user} = this.props;
        const {answers} = this.state;
        let predefinedAnswers = cloneDeep(answers);

        // userAnswersByQuestionId is an array of objects
        // each object is {questionId: questionId, answerIds:[answerId1, answerId2 etc]}
        const userAnswersByQuestionId = [];

        const userAnswersFromDb = cloneDeep(user?.matchingAnswers);

        predefinedAnswers.forEach((question) => {
            const questionId = question.questionId;
            const userAnswers = userAnswersFromDb.filter((userAnswer) => userAnswer.question === questionId);

            // here we want to create only an array of answerIds
            const answerIds = Array.from(Object.values(userAnswers), (answer) => answer.answer);
            userAnswersByQuestionId.push({
                questionId,
                answerIds,
            });
        });

        // clone for arrays equality comparation then set answers and clone to state
        let equalityClone = JSON.parse(JSON.stringify(userAnswersByQuestionId));
        this.setState({answers: userAnswersByQuestionId, answersClone: equalityClone}, () =>
            this.setState({matchingDetailsPrecompleted: true})
        );
    };

    handleAddChangeAnswers = (questionId, type) => (e) => {
        const {eventMatching} = this.props;
        const {answers, answersClone} = this.state;
        let updatedAnswers = [...answers];
        // handle keep answers object in state
        let questionIndex = updatedAnswers.findIndex((answer) => answer.questionId === questionId);
        let qIdx = eventMatching?.questions?.findIndex((question) => question._id === questionId);
        let aIdx = eventMatching?.questions[qIdx]?.answers?.findIndex((answer) => answer.title === e.target.value);
        let answerId = eventMatching?.questions[qIdx]?.answers[aIdx]?._id;
        let data = {};
        data.questionId = questionId;
        data.answerIds = [];
        if (type === 'checkbox') {
            // if question answer is ALREADY in state then check if option was unchecked and remove
            // from answerIds array, else push the newly checked answer
            if (questionIndex !== -1) {
                let answerIndex = updatedAnswers[questionIndex].answerIds.findIndex((aId) => aId === answerId);
                if (answerIndex !== -1) updatedAnswers[questionIndex].answerIds.splice(answerIndex, 1);
                if (answerIndex === -1) updatedAnswers[questionIndex].answerIds.push(answerId);
            } else {
                data.answerIds.push(answerId);
                updatedAnswers.push(data);
            }
        } else {
            // if question answer is ALREADY in state then check if option was unchecked and remove
            // from answerIds array, else push the newly checked answer
            if (questionIndex !== -1) {
                let answerIndex = updatedAnswers[questionIndex].answerIds.findIndex((aId) => aId === answerId);
                if (answerIndex !== -1) updatedAnswers[questionIndex].answerIds = [];
                if (answerIndex === -1) updatedAnswers[questionIndex].answerIds = [answerId];
            } else {
                data.answerIds.push(answerId);
                updatedAnswers.push(data);
            }
        }
        // check required questions
        let count = 0;
        for (let i = 0; i < eventMatching?.questions?.length; i++) {
            if (eventMatching?.questions[i].isRequired) updatedAnswers[i].answerIds.length === 0 && count++;
        }
        this.setState({answers: updatedAnswers}, () =>
            this.setState({
                submitMatchingAnswersDisabled: JSON.stringify(answers) === JSON.stringify(answersClone),
                submitMatchingAnswersRequired: count > 0,
            })
        );
    };

    handleSubmitAnswers = () => {
        const {answers} = this.state;
        const {eventId, eventMatching, onSaveUpdateUserAnswers} = this.props;
        let changedEqualityClone = JSON.parse(JSON.stringify(answers));
        let data = {};
        data.answers = answers;
        onSaveUpdateUserAnswers(eventId, eventMatching?._id, data)
            .then(() =>
                this.setState({
                    submitMatchingAnswersDisabled: true,
                    submitMatchingAnswersRequired: false,
                    openConfirmUnsavedChanges: false,
                    answersClone: changedEqualityClone,
                })
            )
            .catch(() => {});
    };

    handleRemoveMatchingData = () => {
        const {eventId, user, onRemoveUserMatchingData} = this.props;
        this.closeConfirmRemoveMatchingData();
        onRemoveUserMatchingData(eventId)
            .then(() => this.props.onGetUserEventProfile(eventId, user._id))
            .catch(() => {});
    };

    openConfirmRemoveMatchingData = () => this.setState({confirmRemoveMatchingData: true});
    closeConfirmRemoveMatchingData = () =>
        this.setState({
            confirmRemoveMatchingData: false,
            selectedTab: 'accountDetails',
        });

    updateTranslationsForButtonsLabels = () => {
        let tabsListItems = [...this.state.tabsList];
        const {defaultTranslation, languages} = this.props;
        tabsListItems[0].label = `${
            languages?.translations[languages.platformLanguage]?.myAccountPage.accountTab ||
            defaultTranslation?.myAccountPage.accountTab
        }`;
        tabsListItems[1].label = `${
            languages.translations[languages.platformLanguage]?.myAccountPage.matchingForm ||
            defaultTranslation?.myAccountPage.matchingForm
        }`;
        tabsListItems[2].label = `${
            languages.translations[languages.platformLanguage]?.myEventsDropdown.title ||
            defaultTranslation?.myEventsDropdown.title
        }`;
        tabsListItems[3].label = `${
            languages.translations[languages.platformLanguage]?.myAccountPage.manageNotifications ||
            defaultTranslation?.myAccountPage.manageNotifications
        }`;
        // {
        //     name: 'manageNotifications',
        //     label: `${
        //         languages.translations[languages.platformLanguage]?.myAccountPage.manageNotifications ||
        //         defaultTranslation?.myAccountPage.manageNotifications
        //     }`,
        // },
        tabsListItems[4].label = `${
            languages.translations[languages.platformLanguage]?.gdpr.metaTitlePrivacyPolicy ||
            defaultTranslation?.gdpr.metaTitlePrivacyPolicy
        }`;
        tabsListItems[5].label = `${
            languages.translations[languages.platformLanguage]?.myAccountPage.cookiesLink ||
            defaultTranslation?.myAccountPage.cookiesLink
        }`;
        tabsListItems[6].label = `${
            languages.translations[languages.platformLanguage]?.myAccountPage.termsAndConditionsTab ||
            defaultTranslation?.myAccountPage.termsAndConditionsTab
        }`;
        this.setState({tabsList: tabsListItems});
    };

    setExhibitorData = () => {
        const {registrationFields, userEventProfile} = this.props;
        let exhibitorData = cloneDeep(userEventProfile);
        this.setState({exhibitorData: exhibitorData});

        if (exhibitorData?.type === EXHIBITOR_TYPES.scholar) {
            // get scholar predefined fields
            const {scholarPredefinedRegistrationFields} = registrationFields?.data;
            let predefinedRegistrationFields = [];
            Object.keys(scholarPredefinedRegistrationFields).map((key) => {
                const scholarPredefinedField = scholarPredefinedRegistrationFields[key];
                if (scholarPredefinedField.isEnabled) {
                    let fieldLabel = scholarPredefinedField.label;
                    predefinedRegistrationFields.push({label: fieldLabel, value: key});
                }
                return null;
            });
            this.setState({predefinedRegistrationFields: predefinedRegistrationFields});
        } else {
            // get exhibitor predefined fields
            const {exhibitorPredefinedRegistrationFields} = registrationFields?.data;
            let predefinedRegistrationFields = [];
            Object.keys(exhibitorPredefinedRegistrationFields).map((key) => {
                const exhibitorPredefinedField = exhibitorPredefinedRegistrationFields[key];
                if (exhibitorPredefinedField.isEnabled) {
                    let fieldLabel = exhibitorPredefinedField.label;
                    predefinedRegistrationFields.push({label: fieldLabel, value: key});
                }
                return null;
            });
            this.setState({predefinedRegistrationFields: predefinedRegistrationFields});
        }
    };

    selectTab = (tab) => () => {
        this.setState({selectedTab: tab});
    };

    render() {
        const {
            participantData,
            exhibitorData,
            tabsList,
            selectedTab,
            predefinedRegistrationFields,
            answers,
            matchingDetailsPrecompleted,
            submitMatchingAnswersDisabled,
            submitMatchingAnswersRequired,
            openConfirmUnsavedChanges,
            confirmRemoveMatchingData,
        } = this.state;
        const {user, event, eventId, registrationFields, isMobile, isTablet, userEventProfile} = this.props;
        let accessPackageData = null;
        let exhibitorAccessPackageData = null;
        if (event.hasAccessManagement) {
            const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === event.slug);
            const userEventAccessPackageId = userRolesInEvent.accessPackage;
            accessPackageData = event.accessPackages.find(
                (accessPackage) => accessPackage._id === userEventAccessPackageId
            );
        }
        if (event.exhibitorHasAccessManagement || event.scholarHasAccessManagement) {
            const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === event.slug);
            const userEventAccessPackageId = userRolesInEvent.exhibitorAccessPackage;
            exhibitorAccessPackageData = event.exhibitorAccessPackages.find(
                (accessPackage) => accessPackage._id === userEventAccessPackageId
            );
        }

        if (!registrationFields.fetched) {
            return <p>Loading</p>;
        }

        if (isMobile || isTablet) {
            return <MyAccountMobile />;
        }

        const userRoles = user?.eventRoles?.filter((roles) => roles.event._id === eventId)?.shift()?.roles;
        const hasParticipantExhibitorProfile =
            userRoles.indexOf('participant') >= 0 || userRoles.indexOf('exhibitor') >= 0;
        const hasMatchingEnabled = event?.enableMatching || event?.hasMatching;
        const matchingFormSeen = userEventProfile?.matchingFormSeen;

        return (
            <div onDragStart={preventDefaultDrag} className="my-account-page">
                <LeftSideMenu />
                <div className="my-account-wrapper">
                    <AccountNavigation
                        tabsList={tabsList}
                        selectedTab={selectedTab}
                        selectTab={this.selectTab}
                        hasMatchingEnabled={hasMatchingEnabled}
                        matchingFormSeen={matchingFormSeen}
                        matchingDetailsPrecompleted={matchingDetailsPrecompleted}
                        hasParticipantExhibitorProfile={hasParticipantExhibitorProfile}
                    />
                    <div className="aside-account-wrapper">
                        {selectedTab === 'accountDetails' && (
                            <AccountDetails
                                accessPackageData={accessPackageData}
                                participantData={participantData}
                                exhibitorData={exhibitorData}
                                exhibitorAccessPackageData={exhibitorAccessPackageData}
                                predefinedRegistrationFields={predefinedRegistrationFields}
                            />
                        )}
                        {selectedTab === 'matchingForm' && (
                            <AccountMatching
                                reference={this.wrapperRef}
                                answers={answers}
                                matchingDetailsPrecompleted={matchingDetailsPrecompleted}
                                submitMatchingAnswersDisabled={submitMatchingAnswersDisabled}
                                submitMatchingAnswersRequired={submitMatchingAnswersRequired}
                                openConfirmUnsavedChanges={openConfirmUnsavedChanges}
                                confirmRemoveMatchingData={confirmRemoveMatchingData}
                                hasMatchingEnabled={hasMatchingEnabled}
                                matchingFormSeen={matchingFormSeen}
                                handleAddChangeAnswers={this.handleAddChangeAnswers}
                                openConfirmRemoveMatchingData={this.openConfirmRemoveMatchingData}
                                handleSubmitAnswers={this.handleSubmitAnswers}
                                closeConfirmRemoveMatchingData={this.closeConfirmRemoveMatchingData}
                                handleRemoveMatchingData={this.handleRemoveMatchingData}
                                closeClickOutside={this.closeClickOutside}
                            />
                        )}
                        {selectedTab === 'accountEvents' && <AccountEvents />}
                        {selectedTab === 'privacyPolicy' && <AccountGDPRTemplate template="platformPrivacyPolicy" />}
                        {selectedTab === 'cookies' && <AccountGDPRTemplate template="platformCookies" />}
                        {selectedTab === 'terms' && <AccountGDPRTemplate template="platformTermsAndConditions" />}
                        {selectedTab === 'manageNotifications' && <AccountManageNotifications />}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        eventRoles: state.user.eventRoles,
        event: state.event.data,
        eventMatching: state.organizer.eventMatching,
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        isMobile: state.layout.isMobile,
        isTablet: state.layout.isTablet,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        registrationFields: state.event.registrationFields,
        userEventProfile: state.userEventProfile.data,
        triggerReloadUserProfile:  state.userEventProfile.triggerReloadUserProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeTopNavigation: () => dispatch(actions.topNavClose()),
        onSetEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
        onGetQuestionnaires: (eventId) => dispatch(actions.getQuestionnaires(eventId)),
        onGetUserMatchingAnswers: (eventId, questionnaireId, data) =>
            dispatch(actions.getUserMatchingAnswers(eventId, questionnaireId, data)),
        onSaveUpdateUserAnswers: (eventId, questionnaireId, data) =>
            dispatch(actions.saveUpdateUserAnswers(eventId, questionnaireId, data)),
        onRemoveUserMatchingData: (eventId) => dispatch(actions.removeUserMatchingData(eventId)),
        onGetUserEventProfile: (eventId, userId) => dispatch(actions.getUserEventProfile(eventId, userId)),
        getEventRegistrationFields: (eventId) => dispatch(actions.getEventRegistrationFields(eventId)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyAccount));
