import React from 'react';
import {connect} from 'react-redux';
import ExhibitorsListMobile from '../../Dialogs/ExhibitorBooth/ExhibitorsListMobile';
import * as actions from '../../store/actions';
import SearchIcon from '@material-ui/icons/Search';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as Arrow} from '../../Images/svg/arrow_down.svg';
import {preventDefaultDrag} from '../../Utils/utils';
import {isMobile} from 'react-device-detect';

class ExhibitorsOverlayMobile extends React.Component {
    state = {
        showExhibitorsOverlay: isMobile,
        openSearchContainer: false,
    };

    toggleExhibitorsOverlay = () => {
        this.setState((prevState) => ({
            showExhibitorsOverlay: !prevState.showExhibitorsOverlay,
            openSearchContainer: false,
        }));
    };

    handleCloseExhibitorsOverlay = () => {
        this.setState({
            showExhibitorsOverlay: false,
        });
    };

    closeSearchContainer = () => {
        this.setState({
            openSearchContainer: false,
        });
    };

    handleToggleSearchContainer = () => {
        this.setState((prevState) => ({
            openSearchContainer: !prevState.openSearchContainer,
        }));
    };

    handleSetActiveShowfloor = (showfloorId, isRestricted) => (e) => {
        if (isRestricted) {
            return;
        }
        this.props.onSetActiveShowfloorId(showfloorId);
    };

    setActiveExhibitorId = (exhibitorId) => (e) => {
        this.props.onSetActiveExhibitorId(exhibitorId);
    };

    getCurrentShowfloor = () => {
        const {event, activeShowfloorId} = this.props;
        const showfloor = event.showfloors.find((showfloor) => showfloor._id === activeShowfloorId);
        return showfloor;
    };

    render() {
        const {openSearchContainer, showExhibitorsOverlay} = this.state;
        const {exhibitor, hide, translation, defaultTranslation} = this.props;
        const showfloor = this.getCurrentShowfloor();

        return (
            <div onDragStart={preventDefaultDrag} className="exhibitors-overlay-mobile">
                <div onDragStart={preventDefaultDrag} className="exhibitors-container">
                    <h4 className={`title ${exhibitor ? '' : 'hide'}`}>
                        <div onDragStart={preventDefaultDrag} onClick={this.toggleExhibitorsOverlay}>
                            {exhibitor?.type === 'scholar' ? (
                                <p>
                                    <span
                                        draggable="false"
                                        className={`poster-title ${showfloor?.name ? 'showfloor' : ''}`}
                                    >
                                        {exhibitor?.booth.studyTitle
                                            ? exhibitor?.booth.studyTitle
                                            : translation?.showfloor.titleNotAvailable ||
                                              defaultTranslation?.showfloor.titleNotAvailable}
                                    </span>
                                    {showfloor?.name && <span>{showfloor?.name}</span>}
                                </p>
                            ) : (
                                <p>
                                    <span>{showfloor?.name}</span>
                                    <span>{exhibitor?.company}</span>
                                </p>
                            )}
                            <Arrow fill={colors.white} className={showExhibitorsOverlay ? 'arrow-up' : ''} />
                        </div>
                        {exhibitor ? (
                            <div
                                onDragStart={preventDefaultDrag}
                                className="search-exhibitors"
                                onClick={this.handleToggleSearchContainer}
                            >
                                <SearchIcon />
                            </div>
                        ) : null}
                    </h4>
                </div>
                <ExhibitorsListMobile
                    open={showExhibitorsOverlay || openSearchContainer}
                    hide={hide}
                    openSearchContainer={openSearchContainer}
                    closeSearchContainer={this.closeSearchContainer}
                    handleCloseExhibitorsOverlay={this.handleCloseExhibitorsOverlay}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        event: state.event.data,
        eventId: state.event.eventId,
        activeShowfloorId: state.showfloors.activeShowfloorId,
        activeExhibitorId: state.exhibitors.activeExhibitorId,
        resourcesAccess: state.user.resourcesAccess,
        exhibitor: state.exhibitor.data,
        exhibitors: state.exhibitors.approvedExhibitors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetActiveShowfloorId: (showfloorId) => dispatch(actions.setActiveShowfloorId(showfloorId)),
        onSetActiveExhibitorId: (exhibitorId) => dispatch(actions.setActiveExhibitorId(exhibitorId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitorsOverlayMobile);
