import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions';
import {preventDefaultDrag, urlifyString} from '../../../Utils/utils';

class ChatMessage extends React.PureComponent {
    render() {
        let {text} = this.props.message;
        const {userMessageData} = this.props;
        text = urlifyString(text);
        return (
            <>
                <div onDragStart={preventDefaultDrag} className="avatar-wrapper">
                    {this.props.showUserDetails ? (
                        <div
                            className="user-avatar"
                            style={{
                                backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${userMessageData?.avatarSmall}')`,
                            }}
                        />
                    ) : null}
                </div>
                <div onDragStart={preventDefaultDrag} className="messages-wrappper">
                    {this.props.showUserDetails ? (
                        <p onDragStart={preventDefaultDrag} className="user-details">
                            {userMessageData?.first} {userMessageData?.last}
                        </p>
                    ) : null}
                    <p onDragStart={preventDefaultDrag}>{text}</p>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        maximizeChats: state.user.maximizeChats,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        seeExpandedVideoConference: () => dispatch(actions.expandedChatsOnVideoConference()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatMessage);
