import React, {Component} from 'react';
import Slider from 'react-slick';
import {ReactComponent as PartnerFacebookIcon} from '../Images/svg/partner-facebook.svg';
import {ReactComponent as PartnerLinkedinIcon} from '../Images/svg/partner-linkedin.svg';
import {ReactComponent as PartnerTwitterIcon} from '../Images/svg/partner-twitter.svg';
import {ReactComponent as SliderArrow} from '../Images/svg/slider-arrow.svg';
import {connect} from 'react-redux';
import {preventDefaultDrag} from '../Utils/utils';

class PartnersSlider extends Component {
    state = {
        numberOfSlides: 5,
        oldSlide: null,
        activeSlide: 0,
    };

    componentDidMount() {
        this.calculateNumberOfSlides();
        window.addEventListener('resize', this.calculateNumberOfSlides);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calculateNumberOfSlides);
    }

    calculateNumberOfSlides = () => {
        const {slides} = this.props;
        let numberOfSlides;
        if (window.innerWidth > 1199.9) {
            numberOfSlides = 5;
        } else if (window.innerWidth > 767.9) {
            numberOfSlides = 3;
        } else {
            numberOfSlides = 2;
        }

        if (slides.length < numberOfSlides) {
            numberOfSlides = slides.length;
        }
        this.setState({numberOfSlides: numberOfSlides});
    };

    goToPreviousSlide = () => {
        // const {isRtlLanguage} = this.props;
        this.slider.slickPrev();
        // isRtlLanguage ? this.slider.slickNext() : this.slider.slickPrev();
    };

    goToNextSlide = () => {
        // const {isRtlLanguage} = this.props;
        this.slider.slickNext();
        // isRtlLanguage ? this.slider.slickPrev() : this.slider.slickNext();
    };

    beforeSlideChange = (current, next) => {
        this.setState({oldSlide: current, activeSlide: next});
    };

    render() {
        const {activeSlide} = this.state;
        const {eventInfo, slides} = this.props;

        if (slides.length === 0) {
            return null;
        }

        let topSliderSettings = {
            className: 'navigation-slider',
            dots: false,
            arrows: false,
            autoplay: false,
            draggable: true,
            pauseOnHover: false,
            swipe: true,
            infinite: true,
            speed: 500,
            slidesToShow: this.state.numberOfSlides,
            slidesToScroll: 1,
            swipeToSlide: true,
            focusOnSelect: true,
            initialSlide: 0,
            centerMode: true,
            centerPadding: 0,
            beforeChange: (current, next) => this.beforeSlideChange(current, next),
        };
        const topSliderStyles = {
            width: this.state.numberOfSlides * 208 + 'px',
            margin: 'auto',
        };

        return (
            <div onDragStart={preventDefaultDrag} className={`homepage-slider ${this.props.className}`}>
                <div onDragStart={preventDefaultDrag} className="top-slider">
                    {slides.length > 1 && (
                        <div
                            onDragStart={preventDefaultDrag}
                            className="arrow previous"
                            onClick={this.goToPreviousSlide}
                        >
                            <SliderArrow />
                        </div>
                    )}
                    <div onDragStart={preventDefaultDrag} className="top-slider-container" style={topSliderStyles}>
                        <Slider {...topSliderSettings} ref={(slider) => (this.slider = slider)}>
                            {slides.map((partner) => {
                                return (
                                    <div onDragStart={preventDefaultDrag} key={partner._id}>
                                        <div onDragStart={preventDefaultDrag} className="slider-image-container">
                                            {partner.image ? (
                                                <img
                                                    src={eventInfo.brandingData.filesUrl + partner.image}
                                                    alt={partner.name}
                                                />
                                            ) : (
                                                <p onDragStart={preventDefaultDrag} className="logo-placeholder">
                                                    {partner.name}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                    {slides.length > 1 && (
                        <div onDragStart={preventDefaultDrag} className="arrow next" onClick={this.goToNextSlide}>
                            <SliderArrow />
                        </div>
                    )}
                </div>
                <div onDragStart={preventDefaultDrag} className="bottom-slider-container">
                    <div onDragStart={preventDefaultDrag} className="homepage-container">
                        {slides.map((partner, partnerIndex) => {
                            return (
                                <div
                                    key={partner._id}
                                    className={`partner-details ${activeSlide === partnerIndex ? 'active' : ''}`}
                                >
                                    <div onDragStart={preventDefaultDrag} className="partner-details-container">
                                        <div onDragStart={preventDefaultDrag} className="main-details">
                                            <div onDragStart={preventDefaultDrag} className="info">
                                                {partner.webpage ? (
                                                    <a href={partner.webpage} target="_blank" rel="noopener noreferrer">
                                                        <h2>{partner.name}</h2>
                                                    </a>
                                                ) : (
                                                    <h2 className="no-hover">{partner.name}</h2>
                                                )}
                                                {partner.description && (
                                                    <p>
                                                        {partner.description.split('\n').map((item, key) => {
                                                            return (
                                                                <span
                                                                    onDragStart={preventDefaultDrag}
                                                                    draggable="false"
                                                                    key={key}
                                                                >
                                                                    {item}
                                                                    <br />
                                                                </span>
                                                            );
                                                        })}
                                                    </p>
                                                )}
                                                <div onDragStart={preventDefaultDrag} className="contact">
                                                    {partner.webpage ? (
                                                        <a
                                                            href={partner.webpage}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="webpage-link partner-link-website"
                                                        >
                                                            <h2>{partner.name}</h2>
                                                        </a>
                                                    ) : (
                                                        <h2 className="no-hover">{partner.name}</h2>
                                                    )}
                                                    <div onDragStart={preventDefaultDrag} className="links-container">
                                                        {partner.twitter && (
                                                            <a
                                                                href={partner.twitter}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <PartnerTwitterIcon />
                                                            </a>
                                                        )}
                                                        {partner.linkedin && (
                                                            <a
                                                                href={partner.linkedin}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <PartnerLinkedinIcon />
                                                            </a>
                                                        )}
                                                        {partner.facebook && (
                                                            <a
                                                                href={partner.facebook}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <PartnerFacebookIcon />
                                                            </a>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div onDragStart={preventDefaultDrag} className="logo">
                                            <div onDragStart={preventDefaultDrag} className="partner-logo-container">
                                                {partner.image ? (
                                                    <img
                                                        src={eventInfo.brandingData.filesUrl + partner.image}
                                                        alt="logo"
                                                    />
                                                ) : (
                                                    <p onDragStart={preventDefaultDrag} className="logo-placeholder">
                                                        {partner.name}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventInfo: state.event.eventInfo,
        isRtlLanguage: state.languages.isRtlLanguage,
    };
};

export default connect(mapStateToProps)(PartnersSlider);
