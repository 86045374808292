import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import axios from '../../../store/axios-instance';
import {preventDefaultDrag} from '../../../Utils/utils';

class AccountGDPRTemplate extends PureComponent {
    state = {
        gdprText: '',
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.languages.platformLanguage !== this.props.languages.platformLanguage
        ) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const {languages, template} = this.props;
        let selectedLang = languages.platformLanguage;
        this.getGDPRText(selectedLang, template);
    };

    getGDPRText = (lang, template) => {
        axios({
            method: 'get',
            url: `/gdpr/${lang}/${template}/`,
        })
            .then((response) => {
                this.setState({
                    gdprText: response.data.content,
                });
                return response.data.content;
            })
            .catch((error) => {});
    };

    render() {
        const {gdprText} = this.state;
        return (
            <div onDragStart={preventDefaultDrag} className="scroll-left-container">
                <div
                    className="gdpr-template-wrapper"
                    onDragStart={preventDefaultDrag}
                    dangerouslySetInnerHTML={{__html: gdprText}} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
    };
};

export default withRouter(connect(mapStateToProps)(AccountGDPRTemplate));
