import React from 'react';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import * as actions from '../store/actions';
import axios from '../store/axios-instance';
import ColoredScrollbars from '../SmallLayoutComponents/ColoredScrollbars';
import Spinner from '../SmallLayoutComponents/Spinner';
import VideoMeetingBanner from '../Components/EventMenuDropdowns/Chats/VideoMeetingBanner';
import Button from '@material-ui/core/Button';
import colors from '../CSS/_variables.module.scss';
import {ReactComponent as MeetingsInfoIcon} from '../Images/svg/counter.svg';
import {preventDefaultDrag} from '../Utils/utils';
import {isIOS} from 'react-device-detect';
import {Tooltip} from '@material-ui/core';
import isEqual from 'lodash/isEqual';

class EditGroupChat extends React.Component {
    state = {
        search: '',
        chatName: '',
        chat: null,
        loading: false,
        users: [],
        filterUsers: [],
        chatMembers: [],
        usersToAdd: [],
        videoBannerShow: false,
        userToAddId: null,
    };

    componentDidMount() {
        // we also set the true parameter to only set up at load the chatName
        this.getChatData(true);
        this.setAvailableChatUsers();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!isEqual(prevProps.eventUsers.allEventUsers, this.props.eventUsers.allEventUsers)){
            // this is needed because if a participant changed the "chat preference"
            // we want this update to be seen in real time
            this.setAvailableChatUsers();
        }
    }

    componentWillUnmount() {
        this.props.onGetGroupChats();
    }

    setAvailableChatUsers = () => {
        const {eventUsers, user} = this.props;

        // in users we save all the users that can be found in the group chat to be added
        // it contains all the event users
        // + all the user contacts (as long as they are not already in the event users array)
        // so that they will not be duplicated
        let users = [...eventUsers.allEventUsers];
        user.contacts.forEach((contact) => {
            let contactFoundInEvent = eventUsers.allEventUsers.find((eventUser) => {
                return eventUser.user === contact.user._id;
            });
            if (!contactFoundInEvent) {
                users.push(contact);
            }
        });
        this.setState({users: users}, ()=> {
            if (this.state.search.length > 2) {
                this.filterUsers();
            }
        });
    }

    handleRemoveUserFromGroupChat = (userId) =>
        this.setState({usersToAdd: this.state.usersToAdd.filter((userToAdd) => userToAdd.user !== userId)});

    _showInfoBanner = () =>
        this.setState({
            videoBannerShow: true,
        });

    _closeVideoBanner = () =>
        this.setState({
            videoBannerShow: false,
        });

    handleInputFocus = () => {
        const eventPage = document.getElementsByClassName('event-page');
        if (eventPage && !isIOS) {
            eventPage[0].classList.add('hide');
        }
    };

    handleInputBlur = () => {
        const eventPage = document.getElementsByClassName('event-page hide');
        if (eventPage.length !== 0 && !isIOS) {
            eventPage[0].classList.remove('hide');
        }
    };

    handleKeyDown = (e) => {
        const {search} = this.state;
        if (e.key === 'Enter' && search.trim()) {
            e.target.blur();
        }
    };

    handleAddUserToGroupChat = (newUser) => {
        const {usersToAdd} = this.state;
        let filteredPerson = usersToAdd.filter((user) => user._id !== newUser._id);
        const updatedUsers = [...filteredPerson, newUser];
        this.setState({usersToAdd: updatedUsers});
    };

    getChatData = (setInitialChatName) => {
        this.setState({loading: true});
        axios({method: 'get', url: '/group-chat/' + this.props.activeChatRoomId}).then((response) => {
            let data = response.data.groupChat;
            if (setInitialChatName) {
                this.setState({chatName: data.name});
            }

            let chatMembers = data.users.map((el) => el._id);
            this.setState({chat: data, loading: false, chatMembers: chatMembers});
        });
    };

    onSearchChange = (e) => {
        this.setState(
            {
                search: e.target.value,
            },
            () => {
                if (this.state.search.trim().length >= 1) this.filterUsers();
                if (this.state.search.trim().length < 1) this.setState({filterUsers: []});
            }
        );
    };

    filterUsers = () => {
        let filterUsers = this.state.users.filter((obj) => {
            let fullName = `${obj.first} ${obj.last}`;
            if (fullName.toLowerCase().includes(this.state.search.toLowerCase())) {
                return obj;
            }
            return false;
        });

        // hide chat members from search results
        filterUsers = filterUsers.filter((obj) => {
            if (!this.state.chatMembers.includes(obj.user)) {
                return obj;
            }
            return false;
        });

        this.setState({filterUsers: filterUsers});
    };

    handleUpdateChat = async () => {
        // add to group chat
        this.setState({loading: true});

        const addUsersPromises = this.state.usersToAdd.map((userToAdd) =>
            axios({
                method: 'post',
                url: '/group-chat/' + this.props.activeChatRoomId + '/add-user',
                data: {targetUserId: userToAdd.user},
            })
        );

        await Promise.all(addUsersPromises).catch((err) => {});

        // update chat name
        if (this.state.chatName !== this.state.chat.name) {
            await axios({
                method: 'put',
                url: '/group-chat/' + this.props.activeChatRoomId,
                data: {name: this.state.chatName},
            }).catch((error) => {});
        }

        this.setState(
            {
                search: '',
                chatName: '',
                chat: null,
                loading: false,
                users: [],
                filterUsers: [],
                chatMembers: [],
                usersToAdd: [],
            },
            this.props.closeGroupChatDialog
        );
    };

    checkIfIsContact = (userId) => {
        const {user} = this.props;
        let isContact = user?.contacts?.find((contact) => contact.user._id === userId);
        return isContact !== undefined;
    };

    checkIfIsAMatch = (userId) => {
        const {user} = this.props;
        let isAMatch = user?.matchingUsers?.find((match) => match.matchingLevel === 'high' && match.user === userId);
        return isAMatch !== undefined;
    };

    checkIfChatButtonIsDisabled = (userData) => {
        const {translation, defaultTranslation} = this.props;
        let chatPreference = {
            disabledChat: false,
            tooltipText: '',
        };
        let isAMatch = this.checkIfIsAMatch(userData.user);
        let isContact = this.checkIfIsContact(userData._id);
        let acceptHighMatches = translation?.networking.acceptHighMatches || defaultTranslation?.networking.acceptHighMatches;
        let acceptContacts = translation?.networking.acceptContacts || defaultTranslation?.networking.acceptContacts;
        let acceptContactsOrHighMatches = translation?.networking.acceptContactsOrHighMatches || defaultTranslation?.networking.acceptContactsOrHighMatches;
        let denyEverybody = translation?.networking.denyEverybody || defaultTranslation?.networking.denyEverybody;
        if (userData.chatPreference === 'matches') {
            if (!isAMatch) {
                chatPreference.tooltipText = acceptHighMatches;
                chatPreference.disabledChat = true;
            }
        } else if (userData.chatPreference === 'contacts') {
            if (!isContact) {
                chatPreference.tooltipText = acceptContacts;
                chatPreference.disabledChat = true;
            }
        } else if (userData.chatPreference === 'contactsOrMatches') {
            if (!isAMatch && !isContact) {
                chatPreference.tooltipText = acceptContactsOrHighMatches;
                chatPreference.disabledChat = true;
            }
        } else if (userData.chatPreference === 'nobody') {
            chatPreference.tooltipText = denyEverybody;
            chatPreference.disabledChat = true;
        }
        return chatPreference;
    };

    render() {
        const {usersToAdd, chat, chatName, search, filterUsers, loading, videoBannerShow} = this.state;
        const {translation, defaultTranslation} = this.props;

        return (
            <div onDragStart={preventDefaultDrag} className="chat-page edit">
                <div>
                    <VideoMeetingBanner
                        videoBannerShow={videoBannerShow}
                        translation={translation}
                        defaultTranslation={defaultTranslation}
                        closeBanner={this._closeVideoBanner}
                    />
                    <div onDragStart={preventDefaultDrag} className="new-chat-wrapper">
                        {chat ? (
                            <div>
                                <div onDragStart={preventDefaultDrag} className="input-container">
                                    <div onDragStart={preventDefaultDrag} className="chat-name-container d-flex">
                                        <TextField
                                            id="chatName"
                                            name="chatName"
                                            label={translation?.chatsDropdown.newChatNameInput}
                                            value={chatName}
                                            onChange={(e) => this.setState({chatName: e.target.value})}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            onFocus={this.handleInputFocus}
                                            onBlur={this.handleInputBlur}
                                        />
                                    </div>
                                    <p>{translation?.chatsDropdown.editChatAddMembers}</p>
                                    <TextField
                                        id="search"
                                        label={translation?.chatsDropdown.newChatSearchInput}
                                        type="search"
                                        margin="normal"
                                        className="m-bottom"
                                        value={search}
                                        onChange={this.onSearchChange}
                                        onFocus={this.handleInputFocus}
                                        onBlur={this.handleInputBlur}
                                        onKeyDown={this.handleKeyDown}
                                        variant="outlined"
                                    />
                                </div>
                                <div onDragStart={preventDefaultDrag} className="members-chat-wrapper">
                                    {loading && <Spinner />}
                                    <ColoredScrollbars>
                                        <ul className="members-list-event">
                                            {filterUsers.map((participant, participantIndex) => {
                                                let participantUser = participant;
                                                if (typeof participant.user === 'object') {
                                                    participantUser = participant.user;
                                                }
                                                let chatPreference = this.checkIfChatButtonIsDisabled(participantUser);
                                                return (
                                                    <React.Fragment key={participantIndex}>
                                                        <li>
                                                            <div
                                                                className="user-avatar"
                                                                style={{
                                                                    backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${participantUser.avatarSmall}')`,
                                                                }}
                                                            />
                                                            <div
                                                                onDragStart={preventDefaultDrag}
                                                                className="chat-member-details"
                                                            >
                                                                <p>
                                                                    {participantUser.first} {participantUser.last}
                                                                </p>
                                                                {participantUser.company !== '' ? (
                                                                    <span>{participantUser.company}</span>
                                                                ) : null}
                                                            </div>
                                                            {usersToAdd.some((user) => user._id === participant._id) ? (
                                                                <button
                                                                    className="remove-member"
                                                                    onClick={() =>
                                                                        this.handleRemoveUserFromGroupChat(
                                                                            participant.user
                                                                        )
                                                                    }
                                                                >
                                                                    {translation?.generalText.remove}
                                                                </button>
                                                            ) :
                                                                chatPreference.disabledChat
                                                                ?
                                                                <Tooltip
                                                                    id="chat-preference"
                                                                    title={chatPreference.tooltipText}
                                                                    arrow
                                                                    enterTouchDelay={1}
                                                                >
                                                                    <div className="add-member">
                                                                        {translation?.generalText.add}
                                                                    </div>
                                                                </Tooltip>
                                                                :
                                                                <button
                                                                    className="add-member"
                                                                    onClick={() =>
                                                                        this.handleAddUserToGroupChat(participant)
                                                                    }
                                                                >
                                                                    {translation?.generalText.add}
                                                                </button>
                                                            }
                                                        </li>
                                                        <div
                                                            onDragStart={preventDefaultDrag}
                                                            className="user-wrapper-separator"
                                                        />
                                                    </React.Fragment>
                                                );
                                            })}

                                            {search.length > 2 && !filterUsers.length && (
                                                <li>{translation?.chatsDropdown.newChatNoUsersMessage}</li>
                                            )}
                                        </ul>
                                    </ColoredScrollbars>
                                </div>
                            </div>
                        ) : null}
                        {chat ? (
                            <div
                                onDragStart={preventDefaultDrag}
                                className="new-chat-buttons-container edit-group-chat"
                            >
                                <div>
                                    <MeetingsInfoIcon
                                        fill={colors.primary}
                                        className="info-button"
                                        onClick={this._showInfoBanner}
                                    />
                                    <Button
                                        type="button"
                                        classes={{label: 'dialog-btn-uppercase'}}
                                        onClick={this.props.closeGroupChatDialog}
                                    >
                                        {translation?.chatsDropdown.newChatCancelButton}
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;
                                    <Button
                                        type="button"
                                        classes={{label: 'dialog-btn-uppercase'}}
                                        onClick={() => this.handleUpdateChat()}
                                        disabled={chatName === chat.name && !usersToAdd.length}
                                    >
                                        {translation?.chatsDropdown.membersUpdateButton}
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;
                                </div>
                                <div onDragStart={preventDefaultDrag} className="video-meetings-info-wrapper">
                                    <div>
                                        <MeetingsInfoIcon fill={colors.primary} />
                                        <p>
                                            {translation?.meetings.banner.videoCallStartedInfo ||
                                                defaultTranslation?.meetings.banner.videoCallStartedInfo}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        eventUsers: state.eventUsers,
        activeChatRoomId: state.user.topNavigation.activeChatRoomId,
        eventId: state.event.data._id,
        eventOwner: state.event.data.owner,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetGroupChats: () => dispatch(actions.getGroupChats()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGroupChat);
