import * as actionTypes from "../actions/actionTypes";
import produce from "immer";

const initialTopNavigation = {
	seeContacts: false,
	seeProgram: false,
	seeMyEvents: false,
	seeMyBooths: false,
	seeNotifications: false,
	seeMyAccount: false,
	seeContactsMobile: false,
	seeProgramMobile: false,
	seeChatsMobile: false,
	seeChatsDesktop: false,
	seeMyEventsMobile: false,
	seeNotificationsMobile: false,
	seeMyAccountMobile: false,
	activeChatRoomId: null,
	isGroupChat: false,
	seeRooms: false,
	isOpenedFromNotifications: false,
};

const seeChatsNav = {
	seeChats: false,
};

const seeHelpChat = {
	seeHelpOpen: false,
};

const initialSideNavigation = {
	isOpened: false,
	seeNetworking: false,
	seeLiveWall: false,
	seeVideoWall: false,
	seeInfoTab: false,
};

const initialEventRoles = {
	isOrganizer: false,
	isCoOrganizer: false,
	isCoOrganizerActive: false,
	isOrganizerRep: false,
	isExhibitor: false,
	exhibitorId: null,
	exhibitorType: null,
	isExhibitorRep: false,
	exhibitorRepIds: [],
	isParticipant: false,
	participantId: null,
};

const initialMyAccountTabs = {
	seeOnventProfile: false,
	seeEventProfile: false,
	seeEditUserInformation: false,
};

const initialRegisterTabs = {
	seeLogInForm: true,
	seeSignUpForm: false,
};

const maximizeChatsComponent = {
	seeExpandedVideoConference: true,
};

const initialState = {
	loggedIn: false,
	loading: localStorage.getItem("token") ? true : false,
	notReadChatMessages: 0,
	error: null,
	data: undefined,
	topNavigation: { ...initialTopNavigation },
	chatsDesktop: { ...seeChatsNav },
	sideNavigation: { ...initialSideNavigation },
	connectedToNotifications: false,
	serverDown: false,
	sessionExpired: false,
	stripeSecret: "",
	resourcesAccess: null,
	eventRoles: { ...initialEventRoles },
	profileTabs: { ...initialMyAccountTabs },
	maximizeChats: { ...maximizeChatsComponent },
	preselectedAccessPackageId: null,
	registerTabs: { ...initialRegisterTabs },
	helpChat: { ...seeHelpChat },
	loadingGetMatches: false,
	errorGetMatches: null,
	loadingRemoveMatchingData: false,
	errorRemoveMatchingData: null,
};

// state.user.isExhibitor

const reducer = (state = initialState, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case actionTypes.CLEAR_ERROR:
				draft.error = null;
				break;

			case actionTypes.SERVER_DOWN:
				draft.serverDown = true;
				break;

			case actionTypes.SESSION_EXPIRED:
				draft.sessionExpired = true;
				break;

			case actionTypes.LOGIN_USER_START:
				draft.loading = true;
				draft.error = null;
				break;

			case actionTypes.LOGIN_USER_SUCCESS:
				draft.loggedIn = true;
				draft.loading = false;
				draft.error = null;
				draft.sessionExpired = false;
				draft.data = action.payload;
				draft.serverDown = false;
				break;

			case actionTypes.LOGIN_USER_FAIL:
				draft.loading = false;
				draft.loggedIn = false;
				draft.error = action.payload;
				break;

			case actionTypes.UPDATE_USER_START:
				draft.loadingGetMatches = true;
				break;

			case actionTypes.UPDATE_USER_SUCCESS:
				draft.loadingGetMatches = false;
				break;

			case actionTypes.UPDATE_USER_FAIL:
				draft.loadingGetMatches = false;
				break;

			case actionTypes.REGISTER_USER_START:
				draft.loading = true;
				break;

			case actionTypes.REGISTER_USER_SUCCESS:
				draft.loggedIn = true;
				draft.loading = false;
				draft.error = null;
				draft.sessionExpired = false;
				draft.data = action.payload;
				break;

			case actionTypes.REGISTER_USER_FAIL:
				draft.loading = false;
				draft.error = action.payload;
				break;
			case actionTypes.SET_USER_LOADING_FALSE:
				draft.loading = false;
				break;

			case actionTypes.REGISTER_PARTICIPANT_START:
				draft.loading = true;
				break;

			case actionTypes.REGISTER_PARTICIPANT_SUCCESS:
				draft.loading = false;
				break;

			case actionTypes.REGISTER_PARTICIPANT_FAIL:
				draft.loading = false;
				break;

			case actionTypes.REGISTER_EXHIBITOR_START:
				draft.loading = true;
				break;

			case actionTypes.REGISTER_EXHIBITOR_SUCCESS:
				draft.loading = false;
				break;

			case actionTypes.REGISTER_EXHIBITOR_FAIL:
				draft.loading = false;
				break;

			case actionTypes.LOGOUT_USER_START:
				draft.loading = true;
				break;

			case actionTypes.LOGOUT_USER_SUCCESS:
				return { ...initialState, loading: false };

			case actionTypes.LOGOUT_USER_FAIL:
				return initialState;

			case actionTypes.SEND_CONTACT_REQUEST_START:
				draft.loading = true;
				break;

			case actionTypes.SEND_CONTACT_REQUEST_SUCCESS:
				draft.loading = false;
				break;

			case actionTypes.SEND_CONTACT_REQUEST_FAIL:
				draft.loading = false;
				break;

			case actionTypes.ACCEPT_CONTACT_REQUEST_START:
				draft.loading = true;
				break;

			case actionTypes.ACCEPT_CONTACT_REQUEST_SUCCESS:
				draft.loading = false;
				break;

			case actionTypes.ACCEPT_CONTACT_REQUEST_FAIL:
				draft.loading = false;
				break;

			case actionTypes.GET_SENT_REQUESTS_START:
				draft.loading = true;
				break;

			case actionTypes.GET_SENT_REQUESTS_SUCCESS:
				draft.data.sentRequests = action.payload;
				draft.loading = false;
				break;

			case actionTypes.GET_SENT_REQUESTS_FAIL:
				draft.loading = false;
				break;

			case actionTypes.GET_RECEIVED_REQUESTS_START:
				draft.loading = true;
				break;

			case actionTypes.GET_RECEIVED_REQUESTS_SUCCESS:
				draft.data.receivedRequests = action.payload;
				draft.loading = false;
				break;

			case actionTypes.GET_RECEIVED_REQUESTS_FAIL:
				draft.loading = false;
				break;

			case actionTypes.GET_NOTIFICATIONS_START:
				draft.loading = true;
				break;

			case actionTypes.GET_NOTIFICATIONS_SUCCESS:
				draft.data.notifications = action.payload;
				draft.loading = false;
				break;

			case actionTypes.GET_NOTIFICATIONS_FAIL:
				draft.loading = false;
				break;

			case actionTypes.GET_CONTACTS_START:
				draft.loading = true;
				break;

			case actionTypes.GET_CONTACTS_SUCCESS:
				draft.data.contacts = action.payload;
				draft.loading = false;
				break;

			case actionTypes.GET_CONTACTS_FAIL:
				draft.loading = false;
				break;

			case actionTypes.GET_MEETINGS_START:
				draft.loading = true;
				break;

			case actionTypes.GET_MEETINGS_SUCCESS:
				draft.data.meetings = action.payload;
				draft.loading = false;
				break;

			case actionTypes.GET_MEETINGS_FAIL:
				draft.loading = false;
				break;

			case actionTypes.CREATE_GROUP_CHAT_START:
				draft.loading = true;
				break;

			case actionTypes.CREATE_GROUP_CHAT_SUCCESS:
				draft.loading = false;
				draft.data.groupChats = draft.data.groupChats.push(action.payload);
				break;

			case actionTypes.CREATE_GROUP_CHAT_FAIL:
				draft.loading = false;
				break;

			case actionTypes.CREATE_PRIVATE_CHAT_START:
				draft.loading = true;
				break;

			case actionTypes.CREATE_PRIVATE_CHAT_SUCCESS:
				draft.loading = false;
				draft.data.privateChats = draft.data.privateChats.push(action.payload);
				break;

			case actionTypes.CREATE_PRIVATE_CHAT_FAIL:
				draft.loading = false;
				break;

			case actionTypes.GET_GROUP_CHATS_START:
				draft.loading = true;
				break;

			case actionTypes.GET_GROUP_CHATS_SUCCESS:
				draft.data.groupChats = action.payload;
				if (draft.topNavigation.activeChatRoomId) {
					const updatedGroupChatsIndex = draft.data.groupChats.findIndex(
						(chat) => chat.chat._id === state.topNavigation.activeChatRoomId,
					);
					if (updatedGroupChatsIndex !== -1) {
						draft.data.groupChats[updatedGroupChatsIndex].unread = 0;
					}
				}
				draft.loading = false;
				break;

			case actionTypes.GET_GROUP_CHATS__FAIL:
				draft.loading = false;
				break;

			case actionTypes.RESET_ACTIVE_CHAT_ID:
				draft.topNavigation = { ...state.topNavigation };
				draft.topNavigation.activeChatRoomId = null;
				draft.topNavigation.isGroupChat = false;
				break;

			case actionTypes.GET_PRIVATE_CHATS_START:
				draft.loading = true;
				break;

			case actionTypes.GET_PRIVATE_CHATS_SUCCESS:
				draft.data.privateChats = action.payload;
				if (draft.topNavigation.activeChatRoomId) {
					const updatedPrivateChatsIndex = draft.data.privateChats.findIndex(
						(chat) => chat.chat._id === draft.topNavigation.activeChatRoomId,
					);
					if (updatedPrivateChatsIndex !== -1) {
						draft.data.privateChats[updatedPrivateChatsIndex].unread = 0;
					}
				}
				draft.loading = false;
				break;

			case actionTypes.GET_PRIVATE_CHATS__FAIL:
				draft.loading = false;
				break;

			case actionTypes.OPEN_PRIVATE_CHAT:
				draft.topNavigation = { ...initialTopNavigation };
				draft.topNavigation.seeChatsMobile = true;
				draft.topNavigation.activeChatRoomId = action.payload;
				draft.topNavigation.isGroupChat = false;
				draft.chatsDesktop.seeChats = true;
				break;
			case actionTypes.OPEN_GROUP_CHAT:
				draft.topNavigation = { ...initialTopNavigation };
				draft.topNavigation.seeChatsMobile = true;
				draft.topNavigation.activeChatRoomId = action.payload;
				draft.topNavigation.isGroupChat = true;
				draft.chatsDesktop.seeChats = true;
				break;

			case actionTypes.TOP_NAV_SEE_CONTACTS:
				draft.topNavigation = { ...initialTopNavigation };
				draft.topNavigation.seeContacts = !state.topNavigation.seeContacts;
				draft.topNavigation.seeChatsMobile =
					!draft.topNavigation.seeChatsMobile;
				draft.topNavigation.seeChatsDesktop =
					!draft.topNavigation.seeChatsDesktop;
				draft.chatsDesktop.seeChats = false;
				break;

			case actionTypes.TOP_NAV_SEE_CONTACTS_MOBILE:
				draft.topNavigation = { ...initialTopNavigation };
				draft.topNavigation.seeContactsMobile =
					!state.topNavigation.seeContactsMobile;
				draft.isGroupChat = state.topNavigation.isGroupChat;
				break;

			case actionTypes.TOP_NAV_SEE_MEETINGS:
				draft.topNavigation = { ...initialTopNavigation };
				draft.topNavigation.seeMeetings = !state.topNavigation.seeMeetings;
				draft.topNavigation.activeChatRoomId =
					state.topNavigation.activeChatRoomId;
				draft.topNavigation.isGroupChat = state.topNavigation.isGroupChat;
				draft.helpChat = { ...seeHelpChat };
				break;
			case actionTypes.TOP_NAV_SEE_MEETINGS_MOBILE:
				draft.topNavigation = { ...initialTopNavigation };
				draft.topNavigation.seeMeetingsMobile =
					!state.topNavigation.seeMeetingsMobile;
				draft.topNavigation.isGroupChat = state.topNavigation.isGroupChat;
				break;

			case actionTypes.TOP_NAV_SEE_PROGRAM:
				draft.topNavigation = { ...initialTopNavigation };
				draft.topNavigation.seeProgram = !state.topNavigation.seeProgram;
				draft.topNavigation.seeChatsMobile =
					!draft.topNavigation.seeChatsMobile;
				draft.topNavigation.seeChatsDesktop =
					!draft.topNavigation.seeChatsDesktop;
				draft.chatsDesktop.seeChats = false;

				// let initialTopNavigationProgramBuffer = {...initialTopNavigation};
				// initialTopNavigationProgramBuffer.activeChatRoomId = state.topNavigation.activeChatRoomId;
				// draft.topNavigation = {...initialTopNavigationProgramBuffer};
				// draft.topNavigation.seeProgram = !state.topNavigation.seeProgram;
				// draft.topNavigation.isGroupChat = state.topNavigation.isGroupChat;
				// draft.helpChat = {...seeHelpChat};
				break;

			case actionTypes.TOP_NAV_SEE_PROGRAM_FROM_NOTIFICATIONS:
				let initialTopNavigationProgramFromNotification = {
					...initialTopNavigation,
				};
				initialTopNavigationProgramFromNotification.activeChatRoomId =
					state.topNavigation.activeChatRoomId;
				draft.topNavigation = {
					...initialTopNavigationProgramFromNotification,
				};
				draft.topNavigation.seeProgram = !state.topNavigation.seeProgram;
				draft.topNavigation.isOpenedFromNotifications =
					!state.topNavigation.isOpenedFromNotifications;
				draft.topNavigation.isGroupChat = state.topNavigation.isGroupChat;
				draft.helpChat = { ...seeHelpChat };
				break;

			case actionTypes.TOP_NAV_SEE_PROGRAM_MOBILE:
				draft.topNavigation = { ...initialTopNavigation };
				draft.topNavigation.seeProgramMobile =
					!state.topNavigation.seeProgramMobile;
				draft.topNavigation.isGroupChat = state.topNavigation.isGroupChat;
				break;

			case actionTypes.TOP_NAV_SEE_PROGRAM_FROM_NOTIFICATIONS_MOBILE:
				draft.topNavigation = { ...initialTopNavigation };
				draft.topNavigation.seeProgramMobile =
					!state.topNavigation.seeProgramMobile;
				draft.topNavigation.isOpenedFromNotifications =
					!state.topNavigation.isOpenedFromNotifications;
				draft.topNavigation.isGroupChat = state.topNavigation.isGroupChat;
				break;

			case actionTypes.TOP_NAV_SEE_CHATS:
				draft.chatsDesktop = { ...seeChatsNav };
				draft.chatsDesktop.seeChats = !draft.chatsDesktop.seeChats;
				break;

			case actionTypes.TOP_NAV_SEE_CHATS_MOBILE:
				draft.topNavigation = { ...initialTopNavigation };
				draft.topNavigation.seeChatsMobile =
					!draft.topNavigation.seeChatsMobile;
				draft.topNavigation.seeChatsDesktop =
					!draft.topNavigation.seeChatsDesktop;
				draft.chatsDesktop.seeChats = !draft.chatsDesktop.seeChats;
				break;

			case actionTypes.SEE_HELP_CHAT_OPEN:
				draft.helpChat.seeHelpOpen = true;
				break;
			case actionTypes.SEE_HELP_CHAT_CLOSE:
				draft.helpChat.seeHelpOpen = false;
				break;

			case actionTypes.TOP_NAV_SEE_MY_EVENTS:
				// check one more time
				let initialTopNavigationEventsBuffer = { ...initialTopNavigation };
				initialTopNavigationEventsBuffer.activeChatRoomId =
					state.topNavigation.activeChatRoomId;
				draft.topNavigation = { ...initialTopNavigationEventsBuffer };
				draft.topNavigation.seeMyEvents = !state.topNavigation.seeMyEvents;
				draft.topNavigation.isGroupChat = state.topNavigation.isGroupChat;
				break;
			case actionTypes.TOP_NAV_SEE_MY_EVENTS_MOBILE:
				// TODO: check one more time
				draft.topNavigation = { ...initialTopNavigation };
				draft.topNavigation.seeMyEventsMobile =
					!state.topNavigation.seeMyEventsMobile;
				draft.topNavigation.isGroupChat = state.topNavigation.isGroupChat;
				break;

			case actionTypes.TOP_NAV_SEE_MY_BOOTHS:
				draft.topNavigation = { ...initialTopNavigation };
				draft.topNavigation.seeMyBooths = !state.topNavigation.seeMyBooths;
				draft.topNavigation.isGroupChat = state.topNavigation.isGroupChat;
				break;

			case actionTypes.TOP_NAV_SEE_NOTIFICATIONS:
				let initialTopNavigationNotificationsBuffer = {
					...initialTopNavigation,
				};
				initialTopNavigationNotificationsBuffer.activeChatRoomId =
					state.topNavigation.activeChatRoomId;
				draft.topNavigation = { ...initialTopNavigationNotificationsBuffer };
				draft.topNavigation.seeNotifications =
					!state.topNavigation.seeNotifications;
				draft.topNavigation.isGroupChat = state.topNavigation.isGroupChat;
				draft.topNavigation.seeChatsDesktop =
					state.topNavigation.seeChatsDesktop;
				break;

			case actionTypes.TOP_NAV_SEE_NOTIFICATIONS_MOBILE:
				draft.topNavigation = { ...initialTopNavigation };
				draft.topNavigation.seeNotificationsMobile =
					!state.topNavigation.seeNotificationsMobile;
				draft.topNavigation.isGroupChat = state.topNavigation.isGroupChat;
				break;
			case actionTypes.TOP_NAV_SEE_MY_ACCOUNT:
				let initialTopNavigationAccountBuffer = { ...initialTopNavigation };
				initialTopNavigationAccountBuffer.activeChatRoomId =
					state.topNavigation.activeChatRoomId;
				draft.topNavigation = { ...initialTopNavigationAccountBuffer };
				draft.topNavigation.seeMyAccount = !state.topNavigation.seeMyAccount;
				draft.topNavigation.isGroupChat = state.topNavigation.isGroupChat;
				draft.topNavigation.seeChatsDesktop =
					state.topNavigation.seeChatsDesktop;
				break;
			case actionTypes.TOP_NAV_SEE_MY_ACCOUNT_MOBILE:
				draft.topNavigation = { ...initialTopNavigation };
				draft.topNavigation.seeMyAccountMobile =
					!state.topNavigation.seeMyAccountMobile;
				draft.topNavigation.isGroupChat = state.topNavigation.isGroupChat;
				break;
			case actionTypes.TOP_NAV_CLOSE:
				let initialTopNavigationClose = { ...initialTopNavigation };
				initialTopNavigationClose.activeChatRoomId =
					draft.topNavigation.activeChatRoomId;
				draft.topNavigation = { ...initialTopNavigationClose };
				draft.topNavigation.isGroupChat = state.topNavigation.isGroupChat;
				draft.topNavigation.seeChatsDesktop =
					state.topNavigation.seeChatsDesktop;
				break;
			case actionTypes.TOP_NAV_CLOSE_MOBILE:
				draft.topNavigation = { ...initialTopNavigation };
				break;
			case actionTypes.BOTTOM_SEE_ROOMS:
				draft.topNavigation = { ...initialTopNavigation };
				draft.topNavigation.isGroupChat = state.topNavigation.isGroupChat;
				draft.topNavigation.seeRooms = !state.topNavigation.seeRooms;
				break;
			case actionTypes.SIDE_NAV_SEE_NETWORKING:
				draft.sideNavigation = { ...initialSideNavigation };
				draft.sideNavigation.isOpened = true;
				draft.sideNavigation.seeNetworking = true;
				break;
			case actionTypes.SIDE_NAV_SEE_LIVE_WALL:
				draft.sideNavigation = { ...initialSideNavigation };
				draft.sideNavigation.isOpened = true;
				draft.sideNavigation.seeLiveWall = true;
				break;
			case actionTypes.SIDE_NAV_SEE_VIDEO_WALL:
				draft.sideNavigation = { ...initialSideNavigation };
				draft.sideNavigation.isOpened = true;
				draft.sideNavigation.seeVideoWall = true;
				break;
			case actionTypes.SIDE_NAV_CLOSE:
				draft.sideNavigation = { ...initialSideNavigation };
				break;

			case actionTypes.SET_USER_EVENT_ROLES:
				draft.eventRoles = action.payload;
				break;

			case actionTypes.SIDE_INFO_TAB:
				draft.sideNavigation = { ...initialSideNavigation };
				draft.sideNavigation.isOpened = true;
				draft.sideNavigation.seeInfoTab = true;
				break;

			case actionTypes.SET_NOT_READ_CHAT_MESSAGES:
				draft.notReadChatMessages = action.payload;
				break;

			case actionTypes.NEW_PRIVATE_CHAT_MESSAGE:
				let updatedPrivateChats = draft.data.privateChats;
				let updatedPrivateChatsIndex = updatedPrivateChats.findIndex(
					(chat) => chat.chat._id === action.payload.notification.objectId,
				);
				updatedPrivateChats[updatedPrivateChatsIndex].chat.lastMessageAt =
					action.payload.message.createdAt;
				updatedPrivateChats[updatedPrivateChatsIndex].chat.lastMessageText =
					action.payload.message.text;
				if (action.payload.notification.incrementUnread) {
					updatedPrivateChats[updatedPrivateChatsIndex].unread++;
				}
				draft.data.privateChats = updatedPrivateChats;
				break;

			case actionTypes.NEW_GROUP_CHAT_MESSAGE:
				let updatedGroupChats = draft.data.groupChats;
				let updatedGroupChatsIndex = updatedGroupChats.findIndex(
					(chat) => chat.chat._id === action.payload.notification.objectId,
				);
				updatedGroupChats[updatedGroupChatsIndex].chat.lastMessageAt =
					action.payload.message.createdAt;
				updatedGroupChats[updatedGroupChatsIndex].chat.lastMessageText =
					action.payload.message.text;
				if (action.payload.notification.incrementUnread) {
					updatedGroupChats[updatedGroupChatsIndex].unread++;
				}
				// return updateObject(state, {data: {...state.data, groupChats: updatedGroupChats}});
				draft.data.groupChats = updatedGroupChats;
				break;

			case actionTypes.READ_MESSAGES_FOR_PRIVATE_CHAT:
				let updatedPrivateChatsUnreadMessages = draft.data.privateChats;
				let updatedPrivateChatsUnreadMessagesIndex =
					updatedPrivateChatsUnreadMessages.findIndex(
						(chat) => chat.chat._id === action.payload,
					);
				updatedPrivateChatsUnreadMessages[
					updatedPrivateChatsUnreadMessagesIndex
				].unread = 0;
				// return updateObject(state, {data: {...state.data, privateChats: updatedPrivateChatsUnreadMessages}});
				draft.data.privateChats = updatedPrivateChatsUnreadMessages;
				break;

			case actionTypes.READ_MESSAGES_FOR_GROUP_CHAT:
				const updatedGroupChatsIndexUnreadMessages =
					draft.data.groupChats.findIndex(
						(chat) => chat.chat._id === action.payload,
					);
				if (updatedGroupChatsIndexUnreadMessages !== -1) {
					draft.data.groupChats[updatedGroupChatsIndexUnreadMessages].unread =
						0;
				}
				break;

			case actionTypes.REMOVE_USER_FROM_GROUP_CHAT_START:
				draft.loading = true;
				break;

			case actionTypes.REMOVE_USER_FROM_GROUP_CHAT_SUCCESS:
				const { userId, chatId } = action.payload;

				draft.loading = false;
				// delete removed user from group chat with id equal chatId
				const updatedGroupChatIndex = draft.data.groupChats.findIndex(
					(groupChat) => groupChat.chat._id === chatId,
				);
				draft.data.groupChats[updatedGroupChatIndex].chat.users =
					draft.data.groupChats[updatedGroupChatIndex].chat.users.filter(
						(user) => user._id !== userId,
					);
				break;

			case actionTypes.REMOVE_USER_FROM_GROUP_CHAT_FAIL:
				draft.loading = false;
				break;

			case actionTypes.EVENT_ROLES_UPDATED_CORG:
				const updatedEventRoles = action.payload;
				let updatedUserEventRoles = draft.data.eventRoles;
				let updatedRolesUserIndex = updatedUserEventRoles.findIndex(
					(user) => user._id === updatedEventRoles._id,
				);
				updatedUserEventRoles[updatedRolesUserIndex].accessRights =
					updatedEventRoles.accessRights;
				updatedUserEventRoles[updatedRolesUserIndex].roles =
					updatedEventRoles.roles;
				// return updateObject(state, {data: {...state.data, eventRoles: updatedUserEventRoles}});
				draft.data.eventRoles = updatedUserEventRoles;
				break;

			case actionTypes.MARK_NOTIFICATION_AS_READ_START:
				draft.loading = true;
				break;
			case actionTypes.MARK_NOTIFICATION_AS_READ_SUCCESS:
				let updatedNotificationStatus = draft.data.notifications.find(
					function (notification) {
						return notification._id === action.payload;
					},
				);
				updatedNotificationStatus = {
					...updatedNotificationStatus,
					read: true,
				};

				const updatedNotificationsArray = state.data.notifications.map(
					(item) => {
						if (item._id === action.payload) {
							return updatedNotificationStatus;
						} else {
							return item;
						}
					},
				);
				draft.notifications = updatedNotificationsArray;
				break;
			case actionTypes.MARK_NOTIFICATION_AS_READ_FAIL:
				draft.loading = false;
				break;

			case actionTypes.SET_USER_CONNECTED_TO_NOTIFICATIONS:
				draft.connectedToNotifications = true;
				break;

			case actionTypes.CREATE_PAYMENT_INTENT_START:
				draft.loading = true;
				break;

			case actionTypes.CREATE_PAYMENT_INTENT_SUCCESS:
				draft.loading = false;
				draft.stripeSecret = action.payload;
				break;

			case actionTypes.CREATE_PAYMENT_INTENT_FAIL:
				draft.loading = false;
				break;

			case actionTypes.REMOVE_STRIPE_SECRET:
				draft.stripeSecret = "";
				break;

			case actionTypes.CREATE_EXHIBITOR_PAYMENT_INTENT_START:
				draft.loading = true;
				break;

			case actionTypes.CREATE_EXHIBITOR_PAYMENT_INTENT_SUCCESS:
				draft.loading = false;
				draft.stripeSecret = action.payload;
				break;

			case actionTypes.CREATE_EXHIBITOR_PAYMENT_INTENT_FAIL:
				draft.loading = false;
				break;

			case actionTypes.CANCEL_PAYMENT_INTENT:
				draft.loading = false;
				draft.stripeSecret = "";
				break;

			case actionTypes.SET_EVENT_ACCESS:
				draft.resourcesAccess = action.payload;
				break;

			case actionTypes.SET_PRESELECTED_PACKAGE_ID:
				draft.preselectedAccessPackageId = action.payload;
				break;

			case actionTypes.TOP_NAV_ONVENT_PROFILE:
				// return updateObject(state, {profileTabs: {...initialMyAccountTabs, seeOnventProfile: true}});
				draft.profileTabs = { ...initialMyAccountTabs };
				draft.profileTabs.seeOnventProfile = true;
				break;

			case actionTypes.TOP_NAV_EVENT_PROFILE:
				// return updateObject(state, {profileTabs: {...initialMyAccountTabs, seeEventProfile: true}});
				draft.profileTabs = { ...initialMyAccountTabs };
				draft.profileTabs.seeEventProfile = true;
				break;

			case actionTypes.TAB_LOG_IN_FORM:
				draft.registerTabs.seeLogInForm = true;
				draft.registerTabs.seeSignUpForm = false;
				break;

			case actionTypes.TAB_SIGN_UP_FORM:
				draft.registerTabs.seeLogInForm = false;
				draft.registerTabs.seeSignUpForm = true;
				break;

			case actionTypes.EXPANDED_CHATS_COMPONENT:
				draft.maximizeChats.seeExpandedVideoConference =
					!draft.maximizeChats.seeExpandedVideoConference;
				break;

			// MATCHING
			case actionTypes.SAVE_UPDATE_ANSWER_START:
				draft.loadingGetMatches = true;
				draft.errorGetMatches = null;
				break;
			case actionTypes.SAVE_UPDATE_ANSWER_SUCCESS:
				draft.loadingGetMatches = false;
				draft.data.matchingUsers = action.payload;
				draft.errorGetMatches = null;
				break;
			case actionTypes.SAVE_UPDATE_ANSWER_FAIL:
				draft.loadingGetMatches = false;
				draft.errorGetMatches = action.payload;
				break;

			case actionTypes.CLEAR_MATCHES_ARRAY:
				const { matchingUsers, ...dataNoMatchingUsers } = draft.data;
				// return updateObject(state, {
				//     loadingGetMatches: false,
				//     data: dataNoMatchingUsers,
				//     errorGetMatches: null,
				// });
				draft.loadingGetMatches = false;
				draft.data = dataNoMatchingUsers;
				draft.errorGetMatches = null;
				break;

			case actionTypes.GET_MATCHING_ANSWERS_START:
				draft.loadingGetMatches = true;
				draft.errorGetMatches = null;
				break;

			case actionTypes.GET_MATCHING_ANSWERS_SUCCESS:
				// return updateObject(state, {
				//     loadingGetMatches: false,
				//     data: {...state.data, matchingAnswers: action.payload},
				//     errorGetMatches: null,
				// });
				draft.loadingGetMatches = false;
				draft.data.matchingAnswers = action.payload;
				break;

			case actionTypes.GET_MATCHING_ANSWERS_FAIL:
				draft.loadingGetMatches = false;
				draft.errorGetMatches = action.payload;
				break;

			case actionTypes.REMOVE_USER_MATCHING_DATA_START:
				draft.loadingRemoveMatchingData = true;
				draft.errorRemoveMatchingData = null;
				break;

			case actionTypes.REMOVE_USER_MATCHING_DATA_SUCCESS:
				draft.loadingRemoveMatchingData = false;
				draft.errorRemoveMatchingData = null;
				draft.data.matchingUsers = null;
				break;

			case actionTypes.REMOVE_USER_MATCHING_DATA_FAIL:
				draft.loadingRemoveMatchingData = false;
				draft.errorRemoveMatchingData = action.payload;
				break;

			// END MATCHING

			case actionTypes.SET_PLATFORM_LANGUAGE:
				if (draft.data && draft.data.language) {
					draft.data.language = action.payload;
				}
				break;

			default:
				break;
		}
	});

export default reducer;
