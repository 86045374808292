import React, { Component } from "react";

export default class ArchiveIcon extends Component {
	render() {
		return (
			<svg
				draggable="false"
				xmlns="http://www.w3.org/2000/svg"
				version="1.1"
				id="Layer_1"
				x="0px"
				y="0px"
				width="24"
				height="24"
				viewBox="0 0 24 24"
			>
				<g>
					<path
						fill="white"
						d="M10,14.5c-0.8,0-1.5-0.7-1.5-1.5V2c0-0.8,0.7-1.5,1.5-1.5h12c0.8,0,1.5,0.7,1.5,1.5v11c0,0.8-0.7,1.5-1.5,1.5   H10z"
					/>
					<path
						fill="black"
						d="M22,1c0.6,0,1,0.4,1,1v11c0,0.6-0.4,1-1,1H10c-0.6,0-1-0.4-1-1V2c0-0.6,0.4-1,1-1H22 M22,0H10C8.9,0,8,0.9,8,2   v11c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V2C24,0.9,23.1,0,22,0L22,0z"
					/>
				</g>
				<g>
					<path
						fill="white"
						d="M2,23.5c-0.8,0-1.5-0.7-1.5-1.5V6c0-0.8,0.7-1.5,1.5-1.5h16c0.8,0,1.5,0.7,1.5,1.5v16c0,0.8-0.7,1.5-1.5,1.5H2   z"
					/>
					<path
						fill="black"
						d="M18,5c0.6,0,1,0.4,1,1v16c0,0.6-0.4,1-1,1H2c-0.6,0-1-0.4-1-1V6c0-0.6,0.4-1,1-1H18 M18,4H2C0.9,4,0,4.9,0,6   v16c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C20,4.9,19.1,4,18,4L18,4z"
					/>
				</g>
				<path
					fill="black"
					d="M16,10.4c0-0.2-0.1-0.3-0.3-0.4c-0.1,0-0.1,0-0.2,0s-0.2,0-0.3,0.1L12,12.8v-1c0-1.1-0.9-2-2-2H6  c-1.1,0-2,0.9-2,2v4.5c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-1l3.2,2.7c0.1,0.1,0.2,0.1,0.3,0.1s0.1,0,0.2,0c0.2-0.1,0.3-0.2,0.3-0.4  L15.5,14L16,10.4z M11,16.3c0,0.6-0.4,1-1,1H6c-0.6,0-1-0.4-1-1v-4.5c0-0.6,0.4-1,1-1h4c0.6,0,1,0.4,1,1V16.3z M14.8,16.3l-2.1-1.8  L12.1,14l0.6-0.5l2.1-1.8L14.5,14L14.8,16.3z"
				/>
			</svg>
		);
	}
}
