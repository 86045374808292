import { useMemo } from "react";
import NoVideoImage from "../../../assets/no-video.png";

const LobbyVideoImage = (props) => {
	const { brandingData } = props;

	const lobbyVideoImageUrl = useMemo(() => {
		if (brandingData?.lobbyVideoImage) {
			return brandingData.filesUrl + brandingData.lobbyVideoImage;
		}
		return NoVideoImage;
	}, [brandingData]);

	return (
		<div
			className="image-preview-video no-video"
			style={{ backgroundImage: `url('${lobbyVideoImageUrl}')` }}
			role="img"
			aria-label="Lobby video preview image"
		/>
	);
};

export default LobbyVideoImage;
