import React, {PureComponent} from 'react';
import {preventDefaultDrag} from '../../Utils/utils';

class RegisterDescriptionSection extends PureComponent {
    render() {
        const {
            registerAsParticipant,
            registerAsExhibitor,
            registerAsScholar,
            brandingTranslation,
            translation,
            defaultTranslation,
            isOnTabRegister,
        } = this.props;
        return (
            <div onDragStart={preventDefaultDrag} className={`left-form ${isOnTabRegister ? 'sign-up' : ''}`}>
                <div>
                    <div
                        onDragStart={preventDefaultDrag}
                        className={`form-header left-log-in-description ${isOnTabRegister ? 'is-sign-up' : ''}`}
                    >
                        <h1 className="form-title banner-text">
                            {isOnTabRegister ? (
                                <>
                                    <span>
                                        {translation?.register.registerButton ||
                                            defaultTranslation?.register.registerButton}
                                    </span>
                                    {registerAsParticipant && (
                                        <span>
                                            {' ' +
                                            (translation?.login.logInAsParticipantSecondText ||
                                                defaultTranslation?.login.logInAsParticipantSecondText)}
                                        </span>
                                    )}
                                    {registerAsExhibitor && (
                                        <span>
                                            {' ' +
                                            (translation?.login.logInAsExhibitorSecondText ||
                                                defaultTranslation?.login.logInAsExhibitorSecondText)}
                                        </span>
                                    )}
                                    {registerAsScholar && (
                                        <span>
                                            {' ' +
                                            (translation?.login.logInAsPosterSecondText ||
                                                defaultTranslation?.login.logInAsPosterSecondText)}
                                        </span>
                                    )}
                                </>
                            ) : (
                                <div>
                                     <span>
                                        {translation?.login.loginTitle || defaultTranslation?.login.loginTitle}
                                    </span>
                                </div>
                            )}
                        </h1>
                        {isOnTabRegister && (
                            <div onDragStart={preventDefaultDrag} draggable="false" className="steps-text">
                                <div>
                                    <div className="active">
                                        {translation?.register.oneStep ||
                                        defaultTranslation?.register.oneStep}
                                    </div>
                                    <div>
                                        {translation?.register.twoStep ||
                                        defaultTranslation?.register.twoStep}
                                    </div>
                                </div>
                                <span>
                                    {translation?.register.steps ||
                                    defaultTranslation?.register.steps}
                                </span>
                            </div>
                        )}
                    </div>
                    {registerAsExhibitor && (
                        <>
                            <p>
                                {brandingTranslation?.exhibitorsRegDescription.split('\n').map((item, key) => {
                                    return (
                                        <span onDragStart={preventDefaultDrag} draggable="false" key={key}>
                                            {item}
                                            <br />
                                        </span>
                                    );
                                })}
                            </p>
                        </>
                    )}
                    {registerAsScholar && (
                        <>
                            <p>
                                {brandingTranslation?.scholarsRegDescription.split('\n').map((item, key) => {
                                    return (
                                        <span onDragStart={preventDefaultDrag} draggable="false" key={key}>
                                            {item}
                                            <br />
                                        </span>
                                    );
                                })}
                            </p>
                        </>
                    )}
                    {registerAsParticipant && (
                        <>
                            <p>
                                {brandingTranslation?.participantsRegDescription.split('\n').map((item, key) => {
                                    return (
                                        <span onDragStart={preventDefaultDrag} draggable="false" key={key}>
                                            {item}
                                            <br />
                                        </span>
                                    );
                                })}
                            </p>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default RegisterDescriptionSection;
