import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {useSelector} from 'react-redux';

import CloseIcon from '@material-ui/icons/Close';
import './DialogAddToExternalCalendar.scss';
import {ReactComponent as AddedIcon} from '../../../../Images/svg/added.svg';
import ExternalCalendarLinks from './ExternalCalendarLinks';

const DialogAddToExternalCalendar = ({open, onClose, eventCalendarData, calendarEventType}) => {

    const currentTranslation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    // by default the translations are for adding to external calendar a "Session"
    const [calendarTranslations, setCalendarTranslations] = useState({
        title: currentTranslation.addToCalendarDialog?.titleSession || defaultTranslation.addToCalendarDialog.titleSession,
        confirmationText: currentTranslation.addToCalendarDialog?.confirmationTextSession || defaultTranslation.addToCalendarDialog.confirmationTextSession,
        addToOtherCalendarsText: currentTranslation.addToCalendarDialog?.addToOtherCalendarsTextSession || defaultTranslation.addToCalendarDialog.addToOtherCalendarsTextSession,
    })

    // we need to change the translations if we are trying to add a "Meeting" to the external calendar
    useEffect(()=>{
        if(calendarEventType === "meeting"){
            setCalendarTranslations({
                title: currentTranslation.addToCalendarDialog?.titleMeeting || defaultTranslation.addToCalendarDialog.titleMeeting,
                confirmationText: currentTranslation.addToCalendarDialog?.confirmationTextMeeting || defaultTranslation.addToCalendarDialog.confirmationTextMeeting,
                addToOtherCalendarsText: currentTranslation.addToCalendarDialog?.addToOtherCalendarsTextMeeting || defaultTranslation.addToCalendarDialog.addToOtherCalendarsTextMeeting,
            })
        }
    }, [calendarEventType])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            className={'add-to-external-calendar-dialog preserveHeaderDropdown'}
            onClick={e=>e.stopPropagation()}
        >
            <div className='close-icon' onClick={onClose}>
                <CloseIcon />
            </div>
            <div className='content-wrapper'>
                <p className={'title'}>{calendarTranslations.title}</p>

                <div className='success-message'>
                    <div className='success-icon'>
                        <AddedIcon/>
                    </div>
                    <span>{calendarTranslations.confirmationText}</span>
                </div>

                <div className='calendar-links'>
                    <p>{calendarTranslations.addToOtherCalendarsText}</p>
                    <ExternalCalendarLinks eventCalendarData={eventCalendarData} />
                </div>
            </div>
        </Dialog>
    );
};

export default DialogAddToExternalCalendar;


