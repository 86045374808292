import React, { PureComponent } from "react";
import { preventDefaultDrag } from "../../../../Utils/utils";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions";
import CustomSelectField from "./CustomSelectField";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import colors from "../../../../CSS/_variables.module.scss";
import IconFilter from "../../../Icons/Filter/Filter";
import "./ProgrammeSearchStyles.scss";
import cloneDeep from "lodash/cloneDeep";

const optionsStreams = [
	"Keynote",
	"Webinar",
	"Discovery - AI/ML in 5G",
	"Discovery - Trustworthy AI",
	"Discovery - AI and Health",
	"Discovery - AI and Climate Science",
	"Discovery - GeoAI",
	"Innovation Factory",
	"Perspective",
	"Artistic Intelligence",
	"On the Go",
	"Breakthrough Track",
	"Focus Group",
];

const optionsTopics = [
	"5G",
	"Culture",
	"Cybersecurity",
	"Data",
	"Digital Economy",
	"Digital Financial Services",
	"Disaster Management",
	"Disinformation",
	"Education",
	"Energy",
	"Environment and Climate change",
	"Ethics",
	"Food",
	"Gaming",
	"Health",
	"Inclusivity",
	"Innovation & Creativity",
	"Justice & Strong Institutions",
	"Nuclear Science",
	"Pandemic",
	"Robotics for Good",
	"Safety",
	"Smart Cities",
	"Smart Mobility",
	"Space",
	"Sport",
];

const typeOptions = ["Online", "In Person"];

const years = ["2022", "2021", "2020", "2019", "2018", "2017"];

class ProgrammeSearch extends PureComponent {
	constructor(props) {
		super(props);

		let sdgs = this.renderSDGS();
		const auditoriums = this.getAvailableAuditoriums();

		this.state = {
			search: "",
			fields: [
				{
					label: "Programme streams",
					name: "stream",
					value: "",
					options: optionsStreams,
				},
				{
					label: "Topics",
					name: "topic",
					value: "",
					options: optionsTopics,
				},
				{
					label: "UN SDGs",
					name: "sdg",
					value: "",
					options: sdgs,
				},
				{
					label: "Select year",
					name: "year",
					value: "",
					options: years,
				},
				{
					label: "Auditorium",
					name: "auditorium",
					value: "",
					options: auditoriums,
				},
				{
					label: "Type",
					name: "type",
					value: "",
					options: typeOptions,
				},
			],
			showSimpleFilters: false,
			disabled: true,
			executed: false,
		};
	}

	componentDidMount() {
		const { scrollContainerRef } = this.props;
		this.setSearchedResults();
		this.setSearchFields();
		this.delayOverflowOnProgrammeList();
		setTimeout(() => {
			if (scrollContainerRef) {
				scrollContainerRef.current?.addEventListener(
					"scroll",
					this.handleShowSimpleFilters,
				);
			}
		}, 100);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { programmeSearch } = this.props;
		if (!isEqual(prevProps.programmeSearch, programmeSearch)) {
			// this.setSearchedResults();
			this.delayOverflowOnProgrammeList();
		}
	}

	componentWillUnmount() {
		const { scrollContainerRef } = this.props;
		scrollContainerRef?.current?.removeEventListener(
			"scroll",
			this.handleShowSimpleFilters,
		);
	}

	handleShowSimpleFilters = () => {
		const { executed } = this.state;
		const { isProgramEmpty, scrollContainerRef } = this.props;
		if (!isProgramEmpty && !executed) {
			if (scrollContainerRef?.current?.scrollTop > 50) {
				this.setState({
					showSimpleFilters: true,
					executed: true,
				});
			}
		}
	};

	delayOverflowOnProgrammeList = () => {
		const { showSimpleFilters } = this.state;
		if (showSimpleFilters) {
			document
				.querySelector(".programme-wrapper")
				.classList.add("overflow-auto");
		} else {
			document
				.querySelector(".programme-wrapper")
				.classList.remove("overflow-auto");
		}
	};

	setSearchedResults = () => {
		const { eventId, programmeSearch } = this.props;
		let data = {};
		if (!isEmpty(programmeSearch)) {
			let searchValue = programmeSearch.search.trim();
			let fields = programmeSearch.fields;
			if (searchValue.length >= 1) {
				data["search"] = searchValue;
			}
			fields.forEach((field) => {
				if (field.value) {
					data[field.name] = field.value;
				}
			});
		}
		this.props.getProgramme(eventId, data);
	};

	setSearchFields = () => {
		const { programmeSearch } = this.props;
		if (!isEmpty(programmeSearch)) {
			this.setState({
				search: programmeSearch.search,
				fields: programmeSearch.fields,
				showSimpleFilters: true,
			});
		}
	};

	renderSDGS = () => {
		const { event } = this.props;
		let sdgs = [];
		event?.sdgs?.forEach((sdg, sdgIndex) => {
			sdgs.push({
				id: sdg._id,
				title: `Goal ${sdgIndex + 1}: ${sdg.title}`,
			});
		});
		return sdgs;
	};

	getAvailableAuditoriums = () => {
		const { event } = this.props;

		const auditoriums = event.auditoriums
			.filter((auditorium) => auditorium.programVisible)
			.map((auditorium) => auditorium.name);

		// Remove duplicates
		const filteredAuditoriums = auditoriums.filter(
			(auditorium, index) => auditoriums.indexOf(auditorium) === index,
		);

		return filteredAuditoriums;
	};

	renderFields = () => {
		const { fields } = this.state;
		const { isProgramEmpty } = this.props;
		const _fields = this.props.event.programSearchFieldVisibility
			? fields.filter(
					(field) => this.props.event.programSearchFieldVisibility[field.name],
			  )
			: fields;
		return _fields?.map((field) => {
			return (
				<CustomSelectField
					key={field.name}
					field={field}
					handleChange={this.handleFieldChange}
					isEmpty={isProgramEmpty}
				/>
			);
		});
	};

	renderNumberOfFilters = () => {
		const { programmeSearch } = this.props;
		let filters = 0;
		programmeSearch?.fields?.forEach((field) => {
			if (field.value) {
				filters++;
			}
		});
		return filters;
	};

	openAdvancedFilters = () => {
		this.setState({
			showSimpleFilters: false,
			executed: true,
		});
	};

	resetFilters = () => {
		const { fields } = this.state;
		const { eventId } = this.props;
		let data = cloneDeep(fields);
		data.forEach((field) => {
			if (field.value) {
				field.value = "";
			}
		});
		this.setState(
			{
				search: "",
				fields: data,
			},
			() => {
				this.props.getProgramme(eventId, {}).then(() => {
					this.props.setProgrammeSearch({});
				});
			},
		);
	};

	handleChange = (e) => {
		this.setState(
			{
				search: e.target.value,
			},
			() => {
				// if there is at least on field with a value we also want to keep the search button enabled
				const filterByOtherFields = this.state.fields.filter(
					(field) => field.value,
				)?.length;

				if (this.state.search.trim().length >= 1 || filterByOtherFields) {
					this.setState({
						disabled: false,
					});
				} else {
					this.setState({
						disabled: true,
					});
				}
			},
		);
	};

	handleKeyDown = (e) => {
		if (e.key === "Enter" && !this.state.disabled) {
			this.handleSearch();
		}
	};

	handleFieldChange = (fieldName, fieldValue) => {
		const { fields, search } = this.state;
		let updatedFields = cloneDeep(fields);
		let updatedFieldIndex = updatedFields.findIndex(
			(field) => field.name === fieldName,
		);
		updatedFields[updatedFieldIndex].value = fieldValue;

		let isThereAnyChange = updatedFields.find(
			(newField) => newField.value !== "",
		);
		if (isThereAnyChange === undefined && search.length <= 0) {
			this.setState({
				disabled: true,
			});
		} else {
			this.setState({
				fields: updatedFields,
				disabled: false,
			});
		}
		this.setState({
			fields: updatedFields,
		});
	};

	handleDeleteSearch = () => {
		const { fields } = this.state;
		const { eventId, programmeSearch } = this.props;
		this.setState(
			{
				search: "",
			},
			() => {
				if (!isEmpty(programmeSearch)) {
					let data = cloneDeep(fields);
					data.forEach((field) => {
						if (field.value) {
							field.value = "";
						}
					});
					this.setState({
						fields: data,
					});
					this.props.getProgramme(eventId, {}).then(() => {
						this.props.setProgrammeSearch({});
					});
				}
			},
		);
	};

	handleSearch = () => {
		const { search, fields } = this.state;
		const { eventId, scrollContainerRef } = this.props;
		let searchValue = search.trim();
		let data = {};
		if (searchValue.length >= 1) {
			data["search"] = searchValue;
		}
		fields.forEach((field) => {
			if (field.value) {
				data[field.name] = field.value;
			}
		});
		this.props.getProgramme(eventId, data).then(() => {
			this.setState(
				{
					disabled: true,
					showSimpleFilters: true,
					executed: true,
				},
				() => {
					scrollContainerRef.current.scrollTop = 0;
					this.props.setProgrammeSearch({
						search: searchValue,
						fields: fields,
					});
				},
			);
		});
	};

	render() {
		const { search, disabled, showSimpleFilters } = this.state;
		const { programmeSearch, translation, defaultTranslation, event } =
			this.props;

		const { programSearchFieldVisibility } = event;
		return (
			<div
				onDragStart={preventDefaultDrag}
				className={`search-programme ${
					showSimpleFilters ? "simple" : "advanced"
				}`}
			>
				<div>
					<div
						className={`simple-programme-variant ${
							showSimpleFilters ? "show" : "hide"
						}`}
					>
						<div className="simple-container">
							<>
								<input
									id="programme-search"
									placeholder={
										!programSearchFieldVisibility?.search ?? false
											? "Text search disabled"
											: translation?.program?.searchSessions ||
											  defaultTranslation?.program?.searchSessions
									}
									type="search"
									autoComplete="off"
									value={search}
									onChange={this.handleChange}
									onKeyDown={this.handleKeyDown}
									disabled={!programSearchFieldVisibility?.search ?? false}
								/>
								<div
									className="filters-container"
									onClick={this.openAdvancedFilters}
								>
									{search.length >= 55 && (
										<span className="three-dots">...</span>
									)}

									<IconFilter fill={colors.secondary} />
									{this.renderNumberOfFilters() !== 0 && (
										<div>
											<span>{this.renderNumberOfFilters()}</span>
										</div>
									)}
								</div>
							</>
						</div>
						<div className="action-buttons">
							<CancelRoundedIcon
								className={search?.length >= 1 ? "fill" : ""}
								onClick={this.handleDeleteSearch}
							/>
							<Button
								type="button"
								classes={{ label: "dialog-btn-uppercase" }}
								onClick={this.handleSearch}
								disabled={search?.length < 1 || disabled}
							>
								{translation?.program?.search ||
									defaultTranslation?.program?.search}
							</Button>
						</div>
					</div>
					<div
						className={`advanced-programme-variant ${
							showSimpleFilters ? "hide" : ""
						}`}
					>
						<div>{this.renderFields()}</div>
					</div>
				</div>
				<div>
					{!isEmpty(programmeSearch) && (
						<Button
							className="reset-filters"
							type="button"
							classes={{ label: "dialog-btn-uppercase" }}
							onClick={this.resetFilters}
						>
							{translation?.program?.reset ||
								defaultTranslation?.program?.reset}
						</Button>
					)}
					<Button
						type="button"
						classes={{ label: "dialog-btn-uppercase" }}
						onClick={this.handleSearch}
						disabled={disabled}
						style={{ color: disabled ? "white" : "black" }}
					>
						{translation?.program?.search ||
							defaultTranslation?.program?.search}
					</Button>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		event: state.event.data,
		eventId: state.event.eventId,
		programmeSearch: state.event.programmeSearch,
		translation: state.languages.translations[state.languages.platformLanguage],
		defaultTranslation: state.languages.translations["en"],
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProgramme: (eventId, data) =>
			dispatch(actions.getProgramme(eventId, data)),
		setProgrammeSearch: (search) =>
			dispatch(actions.setProgrammeSearch(search)),
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ProgrammeSearch),
);
