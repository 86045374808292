import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import colors from '../../../CSS/_variables.module.scss';
import {ReactComponent as BackButtonIcon} from '../../../Images/svg/back-btn.svg';
import OpenChatIcon from '../../../Images/svg/OpenChat';
import {preventDefaultDrag} from '../../../Utils/utils';

class ChatsHeader extends React.Component {
    state = {windowWidth: window.innerWidth};

    handleResize = (e) => {
        this.setState({windowWidth: window.innerWidth});
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener('resize', this.handleResize);
    }

    closeActiveChat = () => {
        this.props.onResetActiveChatId();
    };

    render() {
        const {translation, event, eventRoles, resourcesAccess, openCreateGroupChatDialog, chatsProps} = this.props;

        let restrictedNetwork = false;
        if (event.hasAccessManagement && eventRoles.isParticipant && resourcesAccess && !resourcesAccess.networking) {
            restrictedNetwork = true;
        } else if (
            (event.exhibitorHasAccessManagement || event.scholarHasAccessManagement) &&
            eventRoles.isExhibitor &&
            resourcesAccess &&
            !resourcesAccess.networking
        ) {
            restrictedNetwork = true;
        }

        return (
            <div onDragStart={preventDefaultDrag} className="chat-header-container">
                {openCreateGroupChatDialog && (
                    <>
                        <BackButtonIcon
                            width="28"
                            height="28"
                            fill={this.state.windowWidth < 1024 ? colors.white : colors.dark}
                            className="create-new-chat back-button desktop"
                            onClick={this.props.handleCloseCreateGroupChat}
                        />
                    </>
                )}
                <div>
                    <p onDragStart={preventDefaultDrag} className="dropdown-title">
                        {!openCreateGroupChatDialog && (
                            <>
                                {chatsProps.activeChatRoomId ? (
                                    <BackButtonIcon
                                        width="25"
                                        height="25"
                                        fill={this.state.windowWidth < 1024 ? colors.white : colors.dark}
                                        className="close-active-chat-button"
                                        onClick={this.closeActiveChat}
                                    />
                                ) : null}
                            </>
                        )}
                        <span>{translation?.chatsDropdown.title}</span>
                    </p>
                    <button
                        onClick={this.props.handleOpenCreateGroupChat}
                        disabled={restrictedNetwork}
                    >
                        {restrictedNetwork ? (
                            <Tooltip title="Restricted">
                                <div>
                                    <OpenChatIcon
                                        primary={colors.primary}
                                        secondary={colors.primary}
                                    />
                                    <span>New Chat</span>
                                </div>
                            </Tooltip>
                        ) : (
                            <div>
                                <OpenChatIcon
                                    primary={colors.primary}
                                    secondary={colors.primary}
                                />
                                <span>New Chat</span>
                            </div>
                        )}
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventRoles: state.user.eventRoles,
        resourcesAccess: state.user.resourcesAccess,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onResetActiveChatId: () => dispatch(actions.resetActiveChatId()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatsHeader);
