import React from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {
    Elements,
    ElementsConsumer,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
} from '@stripe/react-stripe-js';
import Spinner from '../../SmallLayoutComponents/Spinner';
import {connect} from 'react-redux';
import './StripePaymentDialog.scss';
import {ReactComponent as SecurePayment} from '../../Images/svg/securePayment.svg';
import {ReactComponent as PowerStripe} from '../../Images/svg/powerStripe.svg';
import {ReactComponent as SecureLocket} from '../../Images/svg/secureLocket.svg';
import {preventDefaultDrag} from '../../Utils/utils';
import variables from '../../CSS/_variables.module.scss';

class CheckoutForm extends React.Component {
    state = {
        cardNumber: {
            error: false,
            message: '',
        },
        cardExpiry: {
            error: false,
            message: '',
        },
        cardCVC: {
            error: false,
            message: '',
        },
        formError: '',
        waitingBackEndConfirmation: false,
    };

    handleSubmit = async (event) => {
        // Block native form submission.
        event.preventDefault();

        this.setState({waitingBackEndConfirmation: true});
        const {stripe, elements, stripeSecret, userFullName} = this.props;

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            this.setState({waitingBackEndConfirmation: false});
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const result = await stripe.confirmCardPayment(`${stripeSecret}`, {
            payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    name: userFullName,
                },
            },
        });
        if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            this.setState({formError: result.error.message, waitingBackEndConfirmation: false});
        } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
                // Show a success message to your customer
                // There's a risk of the customer closing the window before callback
                // execution. Set up a webhook or plugin to listen for the
                // payment_intent.succeeded event that handles any business critical
                // post-payment actions.
                this.setState({
                    waitingBackEndConfirmation: true,
                });
            } else if (result.paymentIntent.status === 'requires_action') {
                console.log('requires_action response');
            } else if (result.paymentIntent.status === 'requires_confirmation') {
                console.log('requires_confirmation response');
            }
        }
    };

    handleChangeFor = (type) => ({error}) => {
        this.setState({formError: ''});
        if (error) {
            this.setState({
                [type]: {
                    error: true,
                    message: error.message,
                },
            });
        } else {
            this.setState({
                [type]: {
                    error: false,
                    message: '',
                },
            });
        }
    };

    render() {
        const {stripe, selectedPackageData, currency, translation} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} className="stripe-form-container">
                <form onSubmit={this.handleSubmit}>
                    <div onDragStart={preventDefaultDrag} className="form-input-container">
                        <CardNumberElement
                            options={{
                                placeholder: `${translation?.stripePayment.cardNumber}`,
                                style: {
                                    base: {
                                        padding: '14px 8px',
                                        fontFamily: `${variables.fontFamily}, Helvetica, Arial`,
                                        fontSize: '16px',
                                        lineHeight: '30px',
                                        color: '#847878 ',
                                        '::placeholder': {
                                            color: '#aab7c4',
                                        },
                                    },
                                },
                            }}
                            onChange={this.handleChangeFor('cardNumber')}
                        />
                        {this.state.cardNumber.error && (
                            <p onDragStart={preventDefaultDrag} className="error">
                                {this.state.cardNumber.message}
                            </p>
                        )}
                    </div>

                    <div onDragStart={preventDefaultDrag} className="form-input-container">
                        <CardExpiryElement
                            options={{
                                placeholder: `${translation?.stripePayment.expirationDate}`,
                                style: {
                                    base: {
                                        padding: '14px 8px',
                                        fontFamily: `${variables.fontFamily}, Helvetica, Arial`,
                                        fontSize: '16px',
                                        lineHeight: '30px',
                                        color: '#847878 ',
                                        '::placeholder': {
                                            color: '#aab7c4',
                                        },
                                    },
                                },
                            }}
                            onChange={this.handleChangeFor('cardExpiry')}
                        />
                        {this.state.cardExpiry.error && (
                            <p onDragStart={preventDefaultDrag} className="error">
                                {this.state.cardExpiry.message}
                            </p>
                        )}
                    </div>

                    <div onDragStart={preventDefaultDrag} className="form-input-container">
                        <CardCvcElement
                            options={{
                                placeholder: `${translation?.stripePayment.cvc}`,
                                style: {
                                    base: {
                                        padding: '14px 8px',
                                        fontFamily: `${variables.fontFamily}, Helvetica, Arial`,
                                        fontSize: '16px',
                                        lineHeight: '30px',
                                        color: '#847878 ',
                                        '::placeholder': {
                                            color: '#aab7c4',
                                        },
                                    },
                                },
                            }}
                            onChange={this.handleChangeFor('cardCVC')}
                        />
                        {this.state.cardCVC.error && (
                            <p onDragStart={preventDefaultDrag} className="error">
                                {this.state.cardCVC.message}
                            </p>
                        )}
                    </div>
                    <div onDragStart={preventDefaultDrag} className="form-error-message-container">
                        {!this.state.cardCVC.error &&
                            !this.state.cardNumber.error &&
                            !this.state.cardExpiry.error &&
                            this.state.formError}
                    </div>
                    <p onDragStart={preventDefaultDrag} className="stripe-info">
                        {translation?.stripePayment.disclaimerText}
                        <br />
                        <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">
                            {translation?.stripePayment.disclaimerTextLink}
                        </a>
                    </p>
                    <div onDragStart={preventDefaultDrag} className="payment-actions-container">
                        <button type="button" onClick={this.props.onCancel} className="cancel">
                            {translation?.stripePayment.cancelButton}
                        </button>
                        <button type="submit" disabled={!stripe} className="submit">
                            {translation?.stripePayment.payButton} {currency}
                            {selectedPackageData.price}
                        </button>
                    </div>
                </form>
                {this.state.waitingBackEndConfirmation && (
                    <Spinner message={'Validating payment. You will be redirected in a moment...'} />
                )}
            </div>
        );
    }
}

class StripePaymentDialog extends React.Component {
    componentDidMount() {
        this.loadStripe();
        document.body.style.overflow = 'hidden';
    }
    // Make sure to call `loadStripe` outside of a component’s render to avoid
    // recreating the `Stripe` object on every render.

    componentWillUnmount() {
        document.body.style.overflow = 'initial';
    }

    loadStripe = () => {
        const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
            stripeAccount: `${this.props.organizerStripeAccountId}`,
        });
        this.setState({
            stripePromise: stripePromise,
        });
    };

    state = {
        stripePromise: null,
        loading: false,
    };

    render() {
        const {stripePromise} = this.state;
        if (!stripePromise) {
            return null;
        }
        const {user} = this.props;
        const userFullName = user.first + ' ' + user.last;

        return (
            <div onDragStart={preventDefaultDrag} className="stripe-dialog-wrapper">
                <div onDragStart={preventDefaultDrag} className={`stripe-payment-dialog`}>
                    <div onDragStart={preventDefaultDrag} className="title">
                        <h2>
                            <span>
                                <span onDragStart={preventDefaultDrag} draggable="false" className="secure-locket">
                                    <SecureLocket />
                                </span>{' '}
                                <SecurePayment />
                            </span>
                            <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">
                                <PowerStripe />
                            </a>
                        </h2>
                    </div>
                    <div onDragStart={preventDefaultDrag} className={'content'}>
                        <Elements stripe={stripePromise}>
                            <ElementsConsumer>
                                {({elements, stripe}) => (
                                    <CheckoutForm
                                        elements={elements}
                                        stripe={stripe}
                                        stripeSecret={this.props.stripeSecret}
                                        onCancel={this.props.onClose}
                                        userFullName={userFullName}
                                        selectedPackageData={this.props.selectedPackageData}
                                        currency={this.props.currency}
                                        translation={this.props.translation}
                                        defaultTranslation={this.props.defaultTranslation}
                                    />
                                )}
                            </ElementsConsumer>
                        </Elements>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps)(StripePaymentDialog);
