import React from 'react';

import './Svg.scss';

const Svg = ({children, viewBox = '0 0 24 24', className}) => (
    <svg draggable="false" className={`Svg ${className}`} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">
        {children}
    </svg>
);

export default Svg;
