import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {ValidatorComponent} from 'react-material-ui-form-validator';
import {TextValidator} from 'react-material-ui-form-validator';

class CheckboxField extends ValidatorComponent {
    handleChange = (e) => {
        const {field} = this.props;
        let updatedOptions = [...field.value];

        if (e.target.checked) {
            updatedOptions.push(e.target.value);
        } else {
            updatedOptions = updatedOptions.filter((option) => option !== e.target.value);
        }

        this.props.handleChange(field.name, updatedOptions);
    };

    renderValidatorComponent() {
        const {field, fieldValues} = this.props;
        return (
            <>
                <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">{field.label}</FormLabel>
                    <FormGroup
                        aria-label={field.label}
                        name={field.name}
                        value={field.value}
                        className="registration-radio-group"
                    >
                        {field.options.map((option) => {
                            let checked = false;
                            if (fieldValues.includes(option)) {
                                checked = true;
                            }
                            return (
                                <FormControlLabel
                                    value={option}
                                    control={<Checkbox onChange={this.handleChange} />}
                                    label={option}
                                    key={option}
                                    checked={checked}
                                />
                            );
                        })}
                    </FormGroup>
                </FormControl>
                <TextValidator
                    key={field.name}
                    label={field.label}
                    type="text"
                    name={field.name}
                    value={field.value}
                    variant="outlined"
                    onChange={this.handleChange}
                    validators={field.validators}
                    errorMessages={field.errorMessages}
                    multiline={field.multiline > 0}
                    rows={field.multiline}
                    fullWidth={true}
                    margin="normal"
                    className="field-container hidden"
                />
            </>
        );
    }
}

export default CheckboxField;
