import React from 'react';
import {checkTimeslotHasResources, preventDefaultDrag} from '../../Utils/utils';
import {connect} from 'react-redux';

class AuditoriumTabs extends React.Component {
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.event.hideAuditoriumNetworking !== this.props.event.hideAuditoriumNetworking) {
            if (this.props.event.hideAuditoriumNetworking && this.props.currentTab === 'networking') {
                this.props.handleChangeTab('video')();
            }
        }
    }

    render() {
        const {timeSlot, translation, defaultTranslation, currentTab, hasVideoWall, hasPolls, event} = this.props;
        const timeslotHasResources = checkTimeslotHasResources(timeSlot);

        return (
            <div onDragStart={preventDefaultDrag} className="tabs-container">
                <div
                    className={`button-tab tab ${currentTab === 'video' ? 'active' : ''}`}
                    onClick={this.props.handleChangeTab('video')}
                >
                    <span>{translation?.auditorium.stageTab}</span>
                </div>
                {hasVideoWall && (
                    <div
                        className={`button-tab tab ${currentTab === 'wall' ? 'active' : ''}`}
                        onClick={this.props.handleChangeTab('wall')}
                    >
                        <span>{translation?.auditorium.videoWallButton}</span>
                    </div>
                )}
                {hasVideoWall && !event.hideAuditoriumNetworking && (
                    <div
                        className={`button-tab tab ${currentTab === 'networking' ? 'active' : ''}`}
                        onClick={this.props.handleChangeTab('networking')}
                    >
                        <span>
                            {translation?.sideMenu.audience ||
                            defaultTranslation?.sideMenu.audience}
                        </span>
                    </div>
                )}
                {hasPolls && (
                    <div
                        className={`button-tab tab ${currentTab === 'polls' ? 'active' : ''}`}
                        onClick={this.props.handleChangeTab('polls')}
                    >
                        <span>{translation?.polls?.polls || defaultTranslation?.polls?.polls}</span>
                    </div>
                )}
                {timeslotHasResources && (
                    <div
                        className={`button-tab tab ${currentTab === 'resources' ? 'active' : ''}`}
                        onClick={this.props.handleChangeTab('resources')}
                    >
                        <span>{translation?.auditorium.resourcesButton}</span>
                    </div>
                )}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps)(AuditoriumTabs);
