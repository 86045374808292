import Person from './Components/Person';
import './HomepageSpeakersStyles.scss';
import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {listSpeakers} from '../../../store/actions/eventSpeakersActions';
import Spinner from '../../../SmallLayoutComponents/Spinner';

const HomepageSpeakers = ({eventId}) => {
    const {innerWidth} = window;
    const dispatch = useDispatch();

    //STORE STATE
    const speakersEvent = useSelector((state) => state.speakers);
    const {eventSpeakers, loading} = speakersEvent;

    //LOCAL STATES
    const [visited, setVisited] = useState([]);
    const [activeSpeakerId, setActiveSpeakerId] = useState(null);

    const activeSpeaker = (userId) => (e) => {
        setActiveSpeakerId(activeSpeakerId === userId ? null : userId);
        innerWidth < 1024 && document.body.classList.add('no-scroll-speaker');
        e.preventDefault();
    };

    // If speaker is visited before,its close button color will be different
    const visitedSpeaker = (speakerId) => {
        const visitedSpeaker = visited.find((visitedSpeaker) => visitedSpeaker._id === speakerId);
        if (visitedSpeaker) {
            return;
        } else {
            return setVisited([...visited, {_id: speakerId}]);
        }
    };

    const closeAllDialogs = (speakerId) => (e) => {
        visitedSpeaker(speakerId);
        setActiveSpeakerId(null);
        innerWidth < 1024 && document.body.classList.remove('no-scroll-speaker');
    };

    const keyDownCloseAllDialogs = (speakerId) => (e) => {
        if (e.key === 'Enter') {
            visitedSpeaker(speakerId);
            setActiveSpeakerId(null);
            innerWidth < 1024 && document.body.classList.remove('no-scroll-speaker');
        }
    };

    const isVisited = (userId) => {
        const isVisitedSpeaker = visited.find((visitedSpeaker) => visitedSpeaker._id === userId);

        if (isVisitedSpeaker) {
            return true;
        } else {
            return false;
        }
    };

    const handleKeyDown = (userId) => (e) => {
        if (e.key === 'Enter') {
            setActiveSpeakerId(activeSpeakerId === userId ? null : userId);
            innerWidth < 1024 && document.body.classList.add('no-scroll-speaker');
        }
    };
    useEffect(() => {
        dispatch(listSpeakers(eventId, false));
    }, [dispatch, eventId]);

    return (
        <div className="main">
            <h2 className="mobile-title">SPEAKERS</h2>
            <div className="speakers-container">
                {loading ? (
                    <Spinner />
                ) : (
                    eventSpeakers?.success &&
                    eventSpeakers?.data.map((user, index) => (
                        <Person
                            eventId={eventId}
                            key={index}
                            status={activeSpeakerId === user._id ? true : false}
                            activeSpeaker={activeSpeaker(user._id)}
                            handleKeyDown={handleKeyDown(user._id)}
                            activeSpeakerId={activeSpeakerId}
                            user={user}
                            visited={isVisited(user._id)}
                            closeAllDialogs={closeAllDialogs}
                            keyDownCloseAllDialogs={keyDownCloseAllDialogs}
                        />
                    ))
                )}
            </div>
        </div>
    );
};

export default HomepageSpeakers;
