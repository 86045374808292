import React, {PureComponent} from 'react';
import {preventDefaultDrag} from '../../Utils/utils';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import * as actions from '../../store/actions';
import {connect} from 'react-redux';
import axios from '../../store/axios-instance';
import {ReactComponent as SaveIcon} from '../../Images/svg/save.svg';
import {Button} from '@material-ui/core';

class EditUserInfoForm extends PureComponent{
    state = {
        user: {
            firstName: '',
            lastName: '',
        },
        disabled: false
    };

    componentDidMount() {
        this.setState({
            user: {
                firstName: this.props.user.first,
                lastName: this.props.user.last,
            },
        });
        ValidatorForm.addValidationRule('noCommasAllowed', (value) => {
            let rule = new RegExp('^[^,]+$', 'i');
            let match = rule.test(value);

            if (value === undefined || value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
    }

    componentWillUnmount() {
        ValidatorForm.removeValidationRule('noCommasAllowed');
    }

    handleChange = (e) => {
        this.setState(
            {
                user: {
                    ...this.state.user,
                    [e.target.name]: e.target.value,
                },
            },
            () => {
                this.refs.form.isFormValid().then((isValid) => {
                    this.setState({disabled: !isValid});
                });
            }
        );
    };

    handleUpdateUserInformation = () => {
        const {user} = this.state;
        axios({method: 'put', url: '/users', data: {first: user.firstName, last: user.lastName}})
            .then((response) => {
                this.props.onRefreshUserData();
                this.props.closeDialog();
            })
            .catch((error) => {});
    };

    render() {
        let {disabled, user} = this.state;
        let {firstName, lastName} = user;
        let {translation, defaultTranslation} = this.props;

        return (
            <ValidatorForm ref="form" onSubmit={this.handleUpdateUserInformation}>
                <div onDragStart={preventDefaultDrag} className="validator-input">
                    <TextValidator
                        className="field-container"
                        label={translation?.myAccountPage.firstName}
                        onChange={this.handleChange}
                        name="firstName"
                        value={firstName}
                        variant="outlined"
                        fullWidth

                        validators={['required', 'noCommasAllowed']}
                        errorMessages={[`${translation?.errors.required}`, `${translation?.errors.noCommasAllowed || defaultTranslation.errors.noCommasAllowed}`]}
                    />
                    <TextValidator
                        className="field-container"
                        label={translation?.myAccountPage.lastName}
                        onChange={this.handleChange}
                        name="lastName"
                        value={lastName}
                        variant="outlined"
                        fullWidth

                        validators={['required', 'noCommasAllowed']}
                        errorMessages={[`${translation?.errors.required}`, `${translation?.errors.noCommasAllowed || defaultTranslation.errors.noCommasAllowed}`]}
                    />
                </div>
                <Button
                    type="submit"
                    variant="outlined"
                    classes={{
                        root: 'restyled-save-button button-save',
                        label: 'dialog-btn-uppercase',
                    }}
                    disabled={(this.props.user.first === firstName) && (this.props.user.last === lastName) || disabled}
                >
                    <SaveIcon/>
                    {translation?.generalText.save}
                </Button>
            </ValidatorForm>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRefreshUserData: () => dispatch(actions.refreshUserData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserInfoForm);