import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions';
import {ReactComponent as ButtonArrow} from './arrow_image_down.svg';

import './ExhibitorsOverlay.scss';
import ShowfloorsList from './ShowfloorsList';
import ExhibitorsList from './ExhibitorsList';
import PostersList from './PostersList';
import {preventDefaultDrag} from '../../../Utils/utils';

class ExhibitorsOverlay extends React.Component {
    state = {
        searchString: '',
    };

    handleSetActiveShowfloor = (showfloorId, isRestricted) => (e) => {
        if (isRestricted) {
            return;
        }
        this.setState({
            searchString: '',
        });
        this.props.onSetActiveShowfloorId(showfloorId);
    };

    getCurrentShowfloor = () => {
        const {event, activeShowfloorId} = this.props;
        const showfloor = event.showfloors.find((showfloor) => showfloor._id === activeShowfloorId);
        return showfloor;
    };

    handleSetActiveExhibitor = (exhibitorId) => (e) => {
        this.props.onSetActiveExhibitorId(exhibitorId);
        this.props.hideExhibitorsOverlay();
    };

    render() {
        const {event, exhibitors, showExhibitorsOverlay} = this.props;

        const showfloor = this.getCurrentShowfloor();

        return (
            <div
                onDragStart={preventDefaultDrag}
                className={'exhibitors-overlay ' + (showExhibitorsOverlay ? 'show' : '')}
            >
                <div
                    className={`close-button ${event.showfloors.length > 5 ? 'with-collapse-button' : ''}`}
                    onClick={this.props.hideExhibitorsOverlay}
                    data-content={event.showfloors.length}
                    data-exhibitors={exhibitors.length}
                >
                    <ButtonArrow />
                </div>
                <ShowfloorsList handleSetActiveShowfloor={this.handleSetActiveShowfloor} />
                {showfloor?.type === 'exhibitors' && (
                    <ExhibitorsList
                        handleSetActiveExhibitor={this.handleSetActiveExhibitor}
                        showExhibitorsOverlay={showExhibitorsOverlay}
                    />
                )}
                {showfloor?.type === 'posters' && (
                    <PostersList
                        handleSetActiveExhibitor={this.handleSetActiveExhibitor}
                        showExhibitorsOverlay={showExhibitorsOverlay}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        exhibitors: state.exhibitors.approvedExhibitors,
        activeExhibitorId: state.exhibitors.activeExhibitorId,
        languages: state.languages,
        activeShowfloorId: state.showfloors.activeShowfloorId,
        resourcesAccess: state.user.resourcesAccess,
        showfloors: state.showfloors.showfloors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetActiveExhibitorId: (exhibitorId) => dispatch(actions.setActiveExhibitorId(exhibitorId)),
        onSetActiveShowfloorId: (showfloorId) => dispatch(actions.setActiveShowfloorId(showfloorId)),
        // onGetExhibitor: (exhibitorId) => dispatch(actions.getExhibitor(exhibitorId)),
        onGetShowfloors: (eventId) => dispatch(actions.getShowfloors(eventId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitorsOverlay);
