import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {preventDefaultDrag} from '../Utils/utils';

class EventLanguageModalMobile extends React.Component {
    render() {
        const {mobile, languages, open, handleCloseSelectLanguageDialog, handleChangeLanguage} = this.props;
        const displayLanguages = languages.platformLanguages;

        return (
            <Dialog
                id="select-languages-dialog"
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        this.props.handleCloseSelectLanguageDialog();
                    }
                }}
                aria-labelledby="form-dialog-title"
                className={`${mobile ? 'dialog-mobile' : ''}`}
            >
                <DialogContent>
                    <div onDragStart={preventDefaultDrag} className="languages-list-container">
                        <ul>
                            {displayLanguages.map((language) => {
                                return (
                                    <li
                                        key={language.value}
                                        value={language.value}
                                        onClick={handleChangeLanguage(language.value)}
                                        className={`${language.value === languages.platformLanguage ? 'active' : ''}`}
                                    >
                                        {language.label}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

export default EventLanguageModalMobile;
