import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import {preventDefaultDrag} from '../../Utils/utils';

class AuditoriumSnackbar extends React.Component {
    snackbarTimerId = null;

    state = {
        totalSecondsTillCurrentTimeSlotEnds: null,
        snackbar: {
            open: false,
            message: '',
        },
    };

    componentDidMount() {
        if (this.props.totalSecondsTillCurrentTimeSlotEnds) {
            this.clearSnackbarCountdown();
            this.startSnackbarCountdown();
        }
    }

    componentDidUpdate(prevProps) {
        let timeslotChanged =
            prevProps.timeSlot !== this.props.timeSlot && this.props.totalSecondsTillCurrentTimeSlotEnds;
        if (
            prevProps.totalSecondsTillCurrentTimeSlotEnds !== this.props.totalSecondsTillCurrentTimeSlotEnds ||
            timeslotChanged
        ) {
            this.clearSnackbarCountdown();
            this.startSnackbarCountdown();
        }
    }

    componentWillUnmount() {
        this.clearSnackbarCountdown();
    }

    startSnackbarCountdown = () => {
        this.setState(
            {
                totalSecondsTillCurrentTimeSlotEnds: this.props.totalSecondsTillCurrentTimeSlotEnds,
            },
            () => {
                this.snackbarTimerId = setInterval(this.snackbarCountdown, 1000);
            }
        );
    };

    snackbarCountdown = () => {
        const {totalSecondsTillCurrentTimeSlotEnds} = this.state;
        const {timeSlot, translation, defaultTranslation} = this.props;
        if (totalSecondsTillCurrentTimeSlotEnds !== 0) {
            switch (totalSecondsTillCurrentTimeSlotEnds) {
                case 600:
                    this.setState({
                        snackbar: {
                            open: true,
                            message: `${translation?.auditorium.slotEndsTextFirst} 10 minutes. ${
                                timeSlot?.onDemandAvailable
                                    ? translation?.auditorium.slotEndsTextSecondUpdated ||
                                      defaultTranslation?.auditorium.slotEndsTextSecondUpdated
                                    : ''
                            }`,
                        },
                        totalSecondsTillCurrentTimeSlotEnds: totalSecondsTillCurrentTimeSlotEnds - 1,
                    });
                    break;
                case 300:
                    this.setState({
                        snackbar: {
                            open: true,
                            message: `${translation?.auditorium.slotEndsTextFirst} 5 minutes. ${
                                timeSlot?.onDemandAvailable
                                    ? translation?.auditorium.slotEndsTextSecondUpdated ||
                                      defaultTranslation?.auditorium.slotEndsTextSecondUpdated
                                    : ''
                            }`,
                        },
                        totalSecondsTillCurrentTimeSlotEnds: totalSecondsTillCurrentTimeSlotEnds - 1,
                    });
                    break;
                case 60:
                    this.setState({
                        snackbar: {
                            open: true,
                            message: `${translation?.auditorium.slotEndsTextFirst} 1 minute. ${
                                timeSlot?.onDemandAvailable
                                    ? translation?.auditorium.slotEndsTextSecondUpdated ||
                                      defaultTranslation?.auditorium.slotEndsTextSecondUpdated
                                    : ''
                            }`,
                        },
                        totalSecondsTillCurrentTimeSlotEnds: totalSecondsTillCurrentTimeSlotEnds - 1,
                    });
                    break;
                default:
                    this.setState({
                        totalSecondsTillCurrentTimeSlotEnds: totalSecondsTillCurrentTimeSlotEnds - 1,
                    });
            }
        } else {
            this.setState({
                totalSecondsTillCurrentTimeSlotEnds: null,
            });

            this.clearSnackbarCountdown();
            this.props.loadCurrentTimeSlot();
        }
    };

    handleCloseSnackbar = () => {
        this.setState({
            snackbar: {
                open: false,
                message: '',
            },
        });
    };

    clearSnackbarCountdown = () => {
        if (this.snackbarTimerId) {
            clearInterval(this.snackbarTimerId);
            this.snackbarTimerId = null;
        }
    };

    render() {
        return (
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                className={'auditorium-snackbar'}
                key={this.state.snackbar.message}
                open={this.state.snackbar.open}
                onClose={this.handleCloseSnackbar}
                autoHideDuration={10000}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={
                    <span onDragStart={preventDefaultDrag} draggable="false" id="message-id">
                        {this.state.snackbar.message}
                    </span>
                }
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setEventId: (eventId) => dispatch(actions.setEventId(eventId)),
        setEventSlug: (eventSlug) => dispatch(actions.setEventSlug(eventSlug)),
        onCloseTopNav: () => dispatch(actions.topNavClose()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuditoriumSnackbar));
