import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import {SelectValidator} from 'react-material-ui-form-validator';
import {ValidatorComponent} from 'react-material-ui-form-validator';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

class SelectField extends ValidatorComponent {
    handleChange = (e) => {
        this.props.handleChange(e.target.name, e.target.value);
    };

    renderValidatorComponent() {
        const {field} = this.props;
        return (
            <FormControl className="setting-input select-validator" variant="filled" fullWidth>
                <InputLabel id="select-country" className={`${field.value ? 'filled' : ''}`}>{field.label}</InputLabel>
                <SelectValidator
                    onChange={this.handleChange}
                    name={field.name}
                    value={field.value ? field.value : ''}
                    validators={field.validators}
                    errorMessages={field.errorMessages}
                    className="field-container"
                    variant="filled"
                    margin="normal"
                    fullWidth
                >
                    {field.options.map((option) => {
                        return (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        );
                    })}
                </SelectValidator>
            </FormControl>
        );
    }
}

export default SelectField;
