import React from "react";

import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";

import QRCode from "react-qr-code";

const QRCodeValueDialog = ({ open, user, eventId, closeDialog }) => {
	const qrData = JSON.stringify({ userId: user.qrCodeHash, eventId });

	return (
		<Dialog
			id="my-events-dialog"
			open={open}
			onClose={closeDialog}
			aria-labelledby="form-dialog-title"
		>
			<DialogContent>
				<div className="my-events-mobile">
					<div>
						<QRCode
							size={256}
							style={{ height: "auto", maxWidth: "100%", width: "100%" }}
							value={qrData}
							viewBox="0 0 256 256"
						/>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default QRCodeValueDialog;
