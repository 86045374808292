import React, {PureComponent} from 'react';
import {stopPropagation} from '../../Utils/utils';
import {Checkbox, FormControl, InputLabel, OutlinedInput, Select} from '@material-ui/core';
import CustomExpandMore from '../../Components/Icons/ExpandMore/ExpandMoreIcon';
import {CustomChip, SdgMenuItem} from '../../Components/EventManagement/SDGs/SdgMenuItem';
import '../../Components/EventManagement/Auditoriums/TimeslotSDGsStyle.scss';
import colors from '../../CSS/_variables.module.scss';
import {connect} from 'react-redux';

class AccountSDGs extends PureComponent {
    state = {
        sdgs: [],
    };

    componentDidMount() {
        this.setSDGS();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.sdgsValues !== this.props.sdgsValues) {
            this.setSDGS();
        }
    }

    setSDGS = () => {
        const {sdgsValues} = this.props;
        this.setState({
            sdgs: [...sdgsValues],
        });
    };

    getAllSDGs = () => {
        const {sdgs} = this.state;
        const {event} = this.props;
        return event?.sdgs?.map(
            (sdg, sdgIndex) => {
                let checked = false;
                if (sdgs?.includes(sdg._id)) {
                    checked = true;
                }
                return (
                    <SdgMenuItem
                        key={
                            sdg._id
                        }
                        value={
                            sdg._id
                        }
                    >
                        <Checkbox
                            checked={checked}
                            classes={{
                                root: 'matching-with-cb',
                            }}
                        />
                        {
                            'Goal ' + (sdgIndex + 1) + ': ' + sdg.title
                        }
                    </SdgMenuItem>
                );
            }
        )
    };

    handleChangeSDG = (e) => {
        const {event} = this.props;
        let elements = e.target.value;
        let selectedSDGs = [];
        event?.sdgs?.forEach((eventSDG) => {
            elements.forEach((elem) => {
                if (eventSDG._id === elem) {
                    selectedSDGs.push(elem);
                }
            });
        });
        this.setState({
            sdgs: selectedSDGs,
        }, () => {
            this.props.handleSDGS(selectedSDGs);
        });
    };

    getSDGTitle = (id) => {
        const {event} = this.props;
        return event?.sdgs?.filter((sdg) => sdg._id === id)[0]?.title;
    };

    getIndexOfSDG = (id) => {
        const {event} = this.props;
        return event?.sdgs?.findIndex(sdg => sdg._id === id);
    };

    handleRemoveSDG = (value) => () => {
        let updatedSDGs = [...this.state.sdgs];
        const indexSdg = updatedSDGs.indexOf(value);
        updatedSDGs.splice(indexSdg, 1);
        this.setState({
            sdgs: updatedSDGs,
        }, () => {
            this.props.handleSDGS(updatedSDGs);
        });
    };

    render() {
        const {sdgs} = this.state;
        const {field} = this.props;

        return (
            <FormControl
                fullWidth
                variant="outlined"
                className={`setting-input account-sdgs ${sdgs?.length === 0 ? 'shrink-label' : ''}`}
            >
                <InputLabel shrink={sdgs?.length > 0} style={{backgroundColor: colors.white}}>
                    {field.label}
                </InputLabel>
                <Select
                    multiple
                    labelId="demo-mutiple-chip-label"
                    id="select-sdgs"
                    color="secondary"
                    classes={{
                        root: `${
                            field?.value?.length >= 1
                                ? 'changed-padding'
                                : ''
                        }`,
                        icon: 'icon-closed-select',
                        iconOpen:
                            'icon-open-select',
                    }}
                    IconComponent={
                        CustomExpandMore
                    }
                    input={
                        <OutlinedInput
                            notched
                            labelWidth={123}
                        />
                    }
                    MenuProps={{
                        getContentAnchorEl:
                            null,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        classes: {
                            paper: 'dropdown-sdgs',
                        },
                        ref: this.papperRef,
                    }}
                    onChange={this.handleChangeSDG}
                    value={
                        sdgs || []
                    }
                    renderValue={(selected) => (
                        <>
                            <div className="chips-wrapper-foreground"/>
                            <div className="chips-wrapper">
                                {selected.map(
                                    (
                                        value,
                                        valueIndex
                                    ) => (
                                        <CustomChip
                                            size="small"
                                            tabIndex="-1"
                                            key={
                                                valueIndex
                                            }
                                            label={
                                                'Goal ' + (this.getIndexOfSDG(value) + 1) + ': ' + this.getSDGTitle(value)
                                            }
                                            onDelete={this.handleRemoveSDG(value)}
                                            onMouseDown={
                                                stopPropagation
                                            }
                                        />
                                    )
                                )}
                            </div>
                        </>
                    )}
                >
                    {/* sdgs   */}
                    {this.getAllSDGs()}
                </Select>
            </FormControl>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps)(AccountSDGs);