import React from 'react';
import {TextValidator} from 'react-material-ui-form-validator';
import {ValidatorComponent} from 'react-material-ui-form-validator';
import variables from '../../CSS/_variables.module.scss';

class TextFieldInfoPoint extends ValidatorComponent {
    handleChange = (e) => {
        this.props.handleChange(e.target.name, e.target.value);
    };

    getTextWidth = (text) => {
        // re-use canvas object for better performance
        let canvas = (this.getTextWidth.canvas = document.createElement('canvas'));
        let context = canvas.getContext('2d');
        context.font = `normal 16px ${variables.fontFamily}`;
        let metrics = context.measureText(text);
        return Math.ceil(metrics.width);
    };

    handleLongPlaceholderOnMobile = (e) => {
        const {isLargeScreen} = this.props;
        let placeholder = e.target.closest(".input-wrapper").querySelector('label').textContent;
        let placeholderWidth = this.getTextWidth(placeholder);
        if (placeholderWidth > e.target.clientWidth && !isLargeScreen) {
            e.target.closest('.input-wrapper').classList.add('show-tooltip-mobile');
        } else {
            e.target.closest('.input-wrapper').classList.remove('show-tooltip-mobile');
        }
    };

    removeAnimationPlaceholderMobile = (e) => {
        e.target.closest('.input-wrapper').classList.remove('show-tooltip-mobile');
    };

    renderValidatorComponent() {
        const {field} = this.props;
        let placeholderWidth = this.getTextWidth(field.label);
        return (
            <div className={`input-wrapper ${placeholderWidth > 302 ? 'show-tooltip' : ''}`}>
                <div className="tooltip">
                    <div>
                        <p>{field.label}</p>
                    </div>
                </div>
                <TextValidator
                    key={field.name}
                    label={field.label}
                    type={field.type}
                    name={field.name}
                    value={field.value}
                    variant="filled"
                    onChange={this.handleChange}
                    validators={field.validators}
                    errorMessages={field.errorMessages}
                    multiline={field.multiline > 0}
                    rows={field.multiline}
                    fullWidth={true}
                    margin="normal"
                    className="field-container"
                    onFocus={this.handleLongPlaceholderOnMobile}
                    onBlur={this.removeAnimationPlaceholderMobile}
                />
            </div>
        );
    }
}

export default TextFieldInfoPoint;
