import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import {EventAvailable as EventAvailableIcon, PlayArrow as PlayArrowIcon} from '@material-ui/icons';

import {zoomSessionStreamStart, zoomSessionStreamStop} from '../../../../store/actions';

const ZoomStreamControls = ({session, loadingZoomSession, zoomSessionStreamStart, zoomSessionStreamStop}) => {
    const handleStartStream = useCallback(() => zoomSessionStreamStart(session._id), [
        session._id,
        zoomSessionStreamStart,
    ]);

    const handleStopStream = useCallback(() => zoomSessionStreamStop(session._id), [
        session._id,
        zoomSessionStreamStop,
    ]);

    const sessionHasRecords = session.records.length > 0;

    return (
        <div className="buttons_group">
            {!session.isActive && !sessionHasRecords && (
                <button type="button" className="button" onClick={handleStartStream} disabled={loadingZoomSession}>
                    <PlayArrowIcon className="button_icon" />
                    <span>{loadingZoomSession ? 'STARTING' : 'START'} STREAM</span>
                </button>
            )}

            {session.isActive && (
                <React.Fragment>
                    <button type="button" className="button" onClick={handleStopStream}>
                        <EventAvailableIcon className="button_icon" />
                        <span>STOP STREAM</span>
                    </button>
                </React.Fragment>
            )}

            {sessionHasRecords && !session.isActive && (
                <span>The session has ended. You can download the recording from above.</span>
            )}
        </div>
    );
};

export default connect(
    (state) => ({
        session: state.sessions.zoomSessionDashboard.session,
        loadingZoomSession: state.sessions.zoomSessionDashboard.isLoading,
    }),
    {
        zoomSessionStreamStart,
        zoomSessionStreamStop,
    }
)(ZoomStreamControls);
