import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as DownloadIcon } from '../../../Images/svg/download_2.svg';
import DownloadUsersExcelDialog from '../../EventManagement/DownloadUsersExcel/DownloadUsersExcelDialog';
import axios from '../../../store/axios-instance';
import './DownloadScannedContacts.scss';
import Snackbar from '@material-ui/core/Snackbar';

const DownloadScannedContacts = ({ scannedContacts }) => {
	const eventId = useSelector((state) => state.event.eventId);
	const { email } = useSelector((state) => state.user.data);
	const [openDialog, setOpenDialog] = useState(false);
	const [openSnackbar, setOpenSnackbar] = useState(false);

	const dialogTitle = 'Download Scanned Contacts';
	const dialogContent = useMemo(
		() =>
			`The list of scanned contacts will be compiled and will be sent to you within minutes. You will receive the Excel sheet on the email address associated with your account: ${email}.`,
		[email]
	);

	const scannedContactsData = useMemo(
		() =>
			scannedContacts.map((contact) => ({
				first: contact.user.first,
				last: contact.user.last,
				email: contact.user.email,
				scanDate: contact.qrCodeScannedTimeStamp,
				id: contact.user._id,
			})),
		[scannedContacts]
	);

	const params = useMemo(
		() => ({
			contacts: scannedContactsData,
			eventId,
		}),
		[scannedContactsData, eventId]
	);

	const downloadScannedContacts = useCallback(async () => {
		try {
			await axios.post('/users/me/scanned-contacts', params);
		} catch (error) {
			console.error('Error downloading scanned contacts:', error);
			// Optionally, handle error here or show an error notification
		}
	}, [params]);

	const handleConfirm = useCallback(() => {
		setOpenDialog(false);
		downloadScannedContacts();
		handleOpenSnackbar();
	}, [downloadScannedContacts]);

	const handleOpenSnackbar = useCallback(() => {
		setOpenSnackbar(true);
	}, []);

	const handleCloseSnackbar = useCallback(() => {
		setOpenSnackbar(false);
	}, []);

	return (
		<div>
			<DownloadUsersExcelDialog
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				onConfirm={handleConfirm}
				dialogContent={dialogContent}
				dialogTitle={dialogTitle}
			/>

			{scannedContacts.length > 0 && (
				<div
					className="export-list-container"
					onClick={() => setOpenDialog(true)}
				>
					<div className="export-list-button">
						<DownloadIcon />
						<p className="export-list-text">EXPORT LIST</p>
					</div>
				</div>
			)}
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={openSnackbar}
				onClose={handleCloseSnackbar}
				message="The list of scanned contacts is being compiled and will be sent to you shortly."
				autoHideDuration={5000}
			/>
		</div>
	);
};

export default DownloadScannedContacts;
