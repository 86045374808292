import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import ColoredScrollbars from '../../../../SmallLayoutComponents/ColoredScrollbars';
import '../../../../CSS/chats.scss';
import PrivateChatListItem from './PrivateChatListItem';
import GroupChatListItem from './GroupChatListItem';
import Confirm from '../../../../Dialogs/Confirm';
import {preventDefaultDrag} from '../../../../Utils/utils';

class ChatsList extends React.Component {
    state = {
        openLeaveVideoMeetingDialog: false,
        openChat: null,
    };

    _closeDialog = () => {
        this.setState({
            openLeaveVideoMeetingDialog: false,
        });
    };

    handleOpenGroupChat = (groupChatId) => (e) => {
        const {activeChatRoomId, videoConference} = this.props;
        this.props.handleCloseCreateGroupChat();
        if (videoConference && activeChatRoomId !== groupChatId) {
            this.setState({
                openLeaveVideoMeetingDialog: true,
                openChat: {
                    groupChatId: groupChatId,
                    type: 'groupChat',
                },
            });
        } else {
            this.props.onOpenGroupChat(groupChatId);
        }
    };

    handleOpenPrivateChat = (privateChatId) => (e) => {
        const {activeChatRoomId, videoConference} = this.props;
        if (videoConference && activeChatRoomId !== privateChatId) {
            this.setState({
                openLeaveVideoMeetingDialog: true,
                openChat: {
                    privateChatId: privateChatId,
                    type: 'privateChat',
                },
            });
        } else {
            this.props.handleCloseCreateGroupChat();
            this.props.onOpenPrivateChat(privateChatId);
        }
    };

    _handleOpenChats = () => {
        const {openChat} = this.state;
        if (openChat?.type === 'privateChat') {
            this.props.handleCloseCreateGroupChat();
            this.props.onOpenPrivateChat(openChat?.privateChatId);
            this._closeDialog();
        } else if (openChat?.type === 'groupChat') {
            this.props.handleCloseCreateGroupChat();
            this.props.onOpenGroupChat(openChat?.groupChatId);
            this._closeDialog();
        }
    };

    render() {
        const {openLeaveVideoMeetingDialog} = this.state;
        const {sortedChats, activeChatRoomId, translation, defaultTranslation} = this.props;
        if (sortedChats.length === 0) {
            return null;
        }

        return (
            <>
                <ColoredScrollbars scrolltochatid={activeChatRoomId} sortedChats={sortedChats}>
                    <div onDragStart={preventDefaultDrag} className="all-chats">
                        {sortedChats.map((chatData) => {
                            // if the chat has a "users" property it means that is a group chat
                            if (chatData.chat.users !== undefined) {
                                return (
                                    <GroupChatListItem
                                        chatData={chatData}
                                        handleOpenGroupChat={this.handleOpenGroupChat}
                                        key={chatData?.chat?._id}
                                        lastMessageAt={chatData?.chat?.lastMessageAt}
                                    />
                                );
                                // hide empty chats, except for the active chat
                            } else if ('lastMessageText' in chatData.chat || activeChatRoomId === chatData.chat._id) {
                                return (
                                    <PrivateChatListItem
                                        chatData={chatData}
                                        handleOpenPrivateChat={this.handleOpenPrivateChat}
                                        key={chatData?.chat?._id}
                                    />
                                );
                            }
                            return null;
                        })}
                    </div>
                </ColoredScrollbars>
                <Confirm
                    open={openLeaveVideoMeetingDialog}
                    closeConfirm={this._closeDialog}
                    dialogTitle={
                        translation?.chatsDropdown.leaveVideoMeetingTitle ||
                        defaultTranslation?.chatsDropdown.leaveVideoMeetingTitle
                    }
                    dialogDescription={
                        translation?.chatsDropdown.leaveVideoMeetingDescription ||
                        defaultTranslation?.chatsDropdown.leaveVideoMeetingDescription
                    }
                    dialogConfirmButtonLabel={translation?.generalText.leave || defaultTranslation?.generalText.leave}
                    handleConfirm={this._handleOpenChats}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        activeChatRoomId: state.user.topNavigation.activeChatRoomId,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onOpenGroupChat: (groupChatId) => dispatch(actions.openGroupChat(groupChatId)),
        onOpenPrivateChat: (privateChatId) => dispatch(actions.openPrivateChat(privateChatId)),
        onResetActiveChatId: () => dispatch(actions.resetActiveChatId()),
        onCloseTopNav: () => dispatch(actions.topNavClose()),
        seeChats: () => dispatch(actions.topNavSeeChats()),
        seeExpandedVideoConference: () => dispatch(actions.expandedChatsOnVideoConference()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatsList);
