import React from 'react';
import ColoredScrollbars from '../../SmallLayoutComponents/ColoredScrollbars';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import {getFormatedDate, preventDefaultDrag} from '../../Utils/utils';
import '../../CSS/auditorium.scss';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as Search} from '../../Images/svg/search-icon.svg';
import EventArchiveVideoItem from './EventArchiveVideoItem';
import LeftSideMenu from '../../Components/LeftSideMenu';

class EventArchive extends React.Component {
    state = {
        search: '',
    };

    componentDidMount() {
        this.props.onSetEventProtectedMenu();

        const {eventId} = this.props;
        this.props.onGetEventArchive(eventId);

        this.props.closeTopNavigation();
    }

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

    getEventFilteredVideos = (videos) => {
        if (this.state.search.length < 2) {
            return videos;
        } else {
            let filteredVideos = videos.filter((video) => {
                if (video.title.toLowerCase().includes(this.state.search.trim().toLowerCase())) {
                    return video;
                }
                if (video.description.toLowerCase().includes(this.state.search.trim().toLowerCase())) {
                    return video;
                }

                let matchedHastags = [];
                if (video.hashtags.length > 0) {
                    matchedHastags = video.hashtags.filter((hashtag) =>
                        hashtag.toLowerCase().includes(this.state.search.trim().toLowerCase())
                    );
                }
                if (matchedHastags.length > 0) {
                    return video;
                }

                let matchedSpeakersName = [];
                let matchedSpeakersCompany = [];
                if (video.speakers.length > 0) {
                    matchedSpeakersName = video.speakers.filter((speaker) =>
                        speaker.toLowerCase().includes(this.state.search.trim().toLowerCase())
                    );
                }
                if (matchedSpeakersName.length > 0) {
                    return video;
                }
                if (matchedSpeakersCompany.length > 0) {
                    return video;
                }

                return null;
            });
            return filteredVideos;
        }
    };

    getArchiveVideoList = (event) => {
        const filteredEventVideos = this.getEventFilteredVideos(event.videos);
        return filteredEventVideos.map((video, videoIndex) => {
            return <EventArchiveVideoItem key={videoIndex} video={video} eventItem={event} />;
        })
    };

    render() {
        const {translation} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} className="auditorium-archive-page">
                <LeftSideMenu/>
                <div onDragStart={preventDefaultDrag} className="program-dashboard">
                    <div onDragStart={preventDefaultDrag} className="program-section">
                        <div onDragStart={preventDefaultDrag} className="smallContainer">
                            <div onDragStart={preventDefaultDrag} className="page-title">
                                <span onDragStart={preventDefaultDrag} draggable="false" className="name">
                                    {translation?.eventArchive.title}
                                </span>
                                <div onDragStart={preventDefaultDrag} className="search-container position-relative">
                                    <input
                                        id="search"
                                        placeholder={translation?.eventArchive.searchText}
                                        value={this.state.search}
                                        onChange={this.handleChange('search')}
                                    />
                                    <Search fill={colors.grey} />
                                </div>
                            </div>
                            {this.props.eventArchive && this.props.eventArchive.length > 0 ? (
                                <div
                                    onDragStart={preventDefaultDrag}
                                    className="auditoriums-archive-container event-archive"
                                >
                                    <ColoredScrollbars>
                                        {this.props.eventArchive.map((event) => {
                                            const filteredEventVideos = this.getEventFilteredVideos(event.videos);
                                            return (
                                                <>
                                                    {filteredEventVideos.length > 0 ? (
                                                        <div
                                                            className="single-auditorium-archive-container"
                                                            key={event._id}
                                                        >
                                                            <h4 className="auditorium-name">
                                                                <span>{event.eventName}</span>
                                                                <span
                                                                    onDragStart={preventDefaultDrag}
                                                                    draggable="false"
                                                                    className="break"
                                                                >
                                                                    |
                                                                </span>
                                                                <span
                                                                    onDragStart={preventDefaultDrag}
                                                                    draggable="false"
                                                                    className="dates"
                                                                >
                                                                    {event.eventStartDate === event.eventEndDate
                                                                        ? getFormatedDate(event.eventStartDate, true)
                                                                        : `${getFormatedDate(
                                                                              event.eventStartDate,
                                                                              true
                                                                          )} - ${getFormatedDate(
                                                                              event.eventEndDate,
                                                                              true
                                                                          )}`}
                                                                </span>
                                                            </h4>
                                                            <div
                                                                onDragStart={preventDefaultDrag}
                                                                className="archive-videos-list"
                                                            >
                                                                {this.getArchiveVideoList(event)}
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </>
                                            );
                                        })}
                                    </ColoredScrollbars>
                                </div>
                            ) : (
                                <p>{translation?.eventArchive.noArchivedEvents}</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        event: state.event.data,
        loadingEvent: state.event.loading,
        eventArchive: state.eventArchive.data,
        loadingEventArchive: state.eventArchive.loading,
        user: state.user.data,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetEventArchive: (eventId) => dispatch(actions.getEventArchive(eventId)),
        onSetEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
        closeTopNavigation: () => dispatch(actions.topNavClose()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventArchive);
