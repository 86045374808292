import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import colors from '../../../CSS/_variables.module.scss';
import OpenChatIcon from '../../../Images/svg/OpenChat';
import {ReactComponent as Connect} from '../../../Images/svg/add-button.svg';
import RequestSent from '../../../Images/svg/RequestSent';
import {ReactComponent as FacebookIcon} from '../../../Images/svg/facebook-logo.svg';
import {ReactComponent as LinkedinIcon} from '../../../Images/svg/linkedin-sign.svg';
import {ReactComponent as TwitterIcon} from '../../../Images/svg/twitter.svg';
import {ReactComponent as GoogleScholarIcon} from '../../../Images/svg/gscho-logo.svg';
import {ReactComponent as ResearchGateIcon} from '../../../Images/svg/rgate-logo.svg';
import {Tooltip} from '@material-ui/core';
import {getPrivateChatId, preventDefaultDrag} from '../../../Utils/utils';
import InviteToStage from '../../../HOC/VirtualisedNetworking/InviteToStage/InviteToStage';
import isEqual from 'lodash/isEqual';
import axios from '../../../store/axios-instance';
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import MatchingPercentage from "./MatchingPercentage";

class NewUserCard extends React.Component {
    state = {
        tooltipText: '',
        disabledChat: false,
    };

    componentDidMount() {
        this.checkIfChatButtonIsDisabled();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(
            (prevProps.userData?.chatPreference !== this.props.userData?.chatPreference) ||
            !isEqual(prevProps.allEventUsers, this.props.allEventUsers)
        ){
            // we want to first 'reset' the chat button state,
            // after that we want to check if the button is disabled or not
            this.setState({
                tooltipText: '',
                disabledChat: false,
            }, this.checkIfChatButtonIsDisabled);
        }

    }

    checkIfIsOnline = (userId) => this.props.onlineUsersObject[userId];
    handleSendFriendConnection = () => this.props.onSendContactRequest(this.props.userId, this.props.eventId);

    checkIfIsContact = () => {
        const {userContacts, userId} = this.props;
        let isContact = userContacts?.find((contact) => contact.user?._id === userId);
        return isContact !== undefined;
    };

    checkIfIsFriendConnectionSent = () => {
        const {userSentRequests, userId} = this.props;
        let isFriendConnectionSent = userSentRequests?.find(
            (request) => request.user === userId && (request.status === 'pending' || request.status === 'rejected')
        );
        return isFriendConnectionSent !== undefined;
    };

    checkIfIsFriendConnectionReceived = () => {
        const {userReceivedRequests, userId} = this.props;
        let isFriendConnectionReceived = userReceivedRequests?.find(
            (request) => request.user?._id === userId && (request.status === 'pending' || request.status === 'rejected')
        );
        return isFriendConnectionReceived !== undefined ? isFriendConnectionReceived._id : false;
    };

    handleOpenPrivateChat = (restrictedNetwork) => () => {
        const {disabledChat} = this.state;
        const {user, userId, userLoading, privateChats, dialogClose, noDialog} = this.props;
        if (restrictedNetwork || disabledChat) {
            return null;
        }
        const privateChatId = getPrivateChatId(privateChats, userId);

        if (privateChatId) {
            if (!noDialog) dialogClose();
            this.props.onOpenPrivateChat(privateChatId);
        } else {
            if (userId !== user?._id && !userLoading) {
                if (!noDialog) dialogClose();
                this.props.onCreatePrivateChat(userId);
            }
        }
    };

    checkIfChatButtonIsDisabled = () => {
        const {userData, translation, defaultTranslation, matchingLevel} = this.props;

        // check if chat preference is set to matches
        let isContact = this.checkIfIsContact();
        let acceptHighMatches = translation?.networking.acceptHighMatches || defaultTranslation?.networking.acceptHighMatches;
        let acceptContacts = translation?.networking.acceptContacts || defaultTranslation?.networking.acceptContacts;
        let acceptContactsOrHighMatches = translation?.networking.acceptContactsOrHighMatches || defaultTranslation?.networking.acceptContactsOrHighMatches;
        let denyEverybody = translation?.networking.denyEverybody || defaultTranslation?.networking.denyEverybody;
        if (userData?.chatPreference === 'matches') {
            if (this.getMatchingLevel(matchingLevel) !== 'high') {
                this.setState({
                    tooltipText: acceptHighMatches,
                    disabledChat: true,
                });
            }
        } else if (userData?.chatPreference === 'contacts') {
            if (!isContact) {
                this.setState({
                    tooltipText: acceptContacts,
                    disabledChat: true,
                });
            }
        } else if (userData?.chatPreference === 'contactsOrMatches') {
            if (matchingLevel !== 'high' && !isContact) {
                this.setState({
                    tooltipText: acceptContactsOrHighMatches,
                    disabledChat: true,
                });
            }
        } else if (userData?.chatPreference === 'nobody') {
            this.setState({
                tooltipText: denyEverybody,
                disabledChat: true,
            });
        }
    };

    getMatchingLevel = (matchingLevel) => {
        if (!matchingLevel) return;
        if (matchingLevel > 0 && matchingLevel <= 29) return 'normal'
        if (matchingLevel <= 79) return 'medium'
        return 'high'
    }

    redirectToSocial = (url) => (e) => {
        window.open(url);
        // id of logged in user
        const firstUserId = this.props.user._id;
        // id of user from list
        const secondUserId = this.props.userId;
        if(firstUserId !== secondUserId) {
            axios.post(`/event/${this.props.eventId}/save-social-media-visits`, {firstUser: firstUserId, secondUser: secondUserId});
        }
    }

    render() {
        const {tooltipText, disabledChat} = this.state;
        const {
            user,
            event,
            eventRoles,
            resourcesAccess,
            userId,
            userData,
            allEventUsersObject,
            defaultTranslation,
            displayOnly,
            userLoading,
            matchingLevel,
            dialogCard,
            showInviteToStageButton,
            isLargeScreen,
        } = this.props;
        const currentUser = allEventUsersObject[userId];
        let restrictedNetwork = false;
        if (event.hasAccessManagement && eventRoles.isParticipant && resourcesAccess && !resourcesAccess.networking) {
            restrictedNetwork = true;
        } else if (
            (event.exhibitorHasAccessManagement || event.scholarHasAccessManagement) &&
            eventRoles.isExhibitor &&
            resourcesAccess &&
            !resourcesAccess.networking
        ) {
            restrictedNetwork = true;
        }

        const isMyself = currentUser?.user === user?._id;
        const isOnline = this.checkIfIsOnline(userId);
        const isContact = this.checkIfIsContact();
        const isFriendConnectionSent = this.checkIfIsFriendConnectionSent();
        const isFriendConnectionReceived = this.checkIfIsFriendConnectionReceived();
        const hasMatchingEnabled = event?.enableMatching || event?.hasMatching;
        const isFeatured = userData?.isFeatured;

        if(!currentUser) return null

        return (
            <div className={`user-main-card ${displayOnly || dialogCard ? 'display-dialog-card' : ''}`}>
                <div
                    onDragStart={preventDefaultDrag}
                    className={`user-container ${isOnline ? 'is-online' : 'is-offline'}`}
                >
                    <Tooltip
                        id="featured-match"
                        title={isFeatured && isLargeScreen ? 'Featured match' : ''}
                        enterTouchDelay={1}
                        TransitionComponent={({ children}) => children}
                    >
                        <div
                            className="participant-avatar"
                            style={{
                                backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${currentUser?.avatarSmall}')`,
                            }}>
                            {isFeatured &&
                                <div className="is-featured">
                                    <Tooltip
                                        id="featured-match"
                                        title={!isLargeScreen ? 'Featured match' : ''}
                                        enterTouchDelay={1}
                                        TransitionComponent={({ children}) => children}
                                    >
                                        <TrendingUpIcon/>
                                    </Tooltip>
                                </div>}
                        </div>
                    </Tooltip>

                    <div className={`match-level`}>
                        {displayOnly && <MatchingPercentage matchingLevel={100}/>}
                        {hasMatchingEnabled &&
                            (this.getMatchingLevel(matchingLevel)) &&
                            <MatchingPercentage matchingLevel={matchingLevel}/>
                        }
                    </div>
                    <div onDragStart={preventDefaultDrag} className="user-details">
                        <div>
                            <div onDragStart={preventDefaultDrag} className="user-name">
                                <p onDragStart={preventDefaultDrag} className="user-name-top ellipsis-third-line">
                                    {currentUser?.first} {currentUser?.last}
                                </p>
                                <p onDragStart={preventDefaultDrag} className="user-name-bottom ellipsis-second-line">
                                    {currentUser?.title}{' '}
                                    {currentUser?.title && (currentUser?.company || currentUser?.affiliation)
                                        ? 'at'
                                        : null}{' '}
                                    {currentUser?.company || currentUser?.affiliation}
                                </p>
                            </div>
                        </div>
                        {displayOnly ? (
                            <div className="participant-button-chat display-only">
                                <div>
                                    <OpenChatIcon primary={colors.primary} secondary={colors.primary} />
                                    <span>{defaultTranslation?.networking.chat}</span>
                                </div>
                            </div>
                        ) : (
                            !isMyself && (
                                <button
                                    className="participant-button-chat"
                                    onClick={this.handleOpenPrivateChat(restrictedNetwork)}
                                    disabled={userLoading || restrictedNetwork}
                                >
                                    {disabledChat
                                    ?
                                        <Tooltip
                                            id="chat-preference"
                                            title={tooltipText}
                                            arrow
                                            enterTouchDelay={1}
                                        >
                                            <div>
                                                <OpenChatIcon primary={colors.primary} secondary={colors.primary} />
                                                <span>{defaultTranslation?.networking.chat}</span>
                                            </div>
                                        </Tooltip>
                                    :
                                        restrictedNetwork
                                        ?
                                            <Tooltip title="Restricted" enterTouchDelay={1}>
                                                <div>
                                                    <OpenChatIcon primary={colors.primary} secondary={colors.primary} />
                                                    <span>{defaultTranslation?.networking.chat}</span>
                                                </div>
                                            </Tooltip>
                                            :
                                            <div>
                                                <OpenChatIcon primary={colors.primary} secondary={colors.primary} />
                                                <span>{defaultTranslation?.networking.chat}</span>
                                            </div>
                                    }
                                </button>
                            )
                        )}
                    </div>
                </div>
                <div className={`bottom-row ${showInviteToStageButton && !isMyself ? 'sessions-actions' : ''}`}>
                    <div className="social-add">
                        <div className="match-social">
                            {!!currentUser?.facebook && (
                                <div
                                    className="social-button"
                                    onClick={this.redirectToSocial(currentUser?.facebook)}
                                >
                                    {restrictedNetwork ? (
                                        <Tooltip title="Restricted" enterTouchDelay={1}>
                                            <FacebookIcon width={24} height={24} fill={colors.grey} />
                                        </Tooltip>
                                    ) : (
                                        <FacebookIcon width={24} height={24} fill={colors.grey} />
                                    )}
                                </div>
                            )}
                            {!!currentUser?.googleScholar && (
                                <a
                                    onClick={this.redirectToSocial(currentUser?.googleScholar)}
                                    className="social-button"
                                    rel="noopener noreferrer"
                                >
                                    {restrictedNetwork ? (
                                        <Tooltip title="Restricted" enterTouchDelay={1}>
                                            <GoogleScholarIcon width={24} height={24} fill={colors.grey} />
                                        </Tooltip>
                                    ) : (
                                        <GoogleScholarIcon width={24} height={24} fill={colors.grey} />
                                    )}
                                </a>
                            )}
                            {!!currentUser?.linkedin && (
                                <a
                                    onClick={this.redirectToSocial(currentUser?.linkedin)}
                                    className="social-button"
                                    rel="noopener noreferrer"
                                >
                                    {restrictedNetwork ? (
                                        <Tooltip title="Restricted" enterTouchDelay={1}>
                                            <LinkedinIcon width={24} height={24} fill={colors.grey} />
                                        </Tooltip>
                                    ) : (
                                        <LinkedinIcon width={24} height={24} fill={colors.grey} />
                                    )}
                                </a>
                            )}
                            {!!currentUser?.researchGate && (
                                <a
                                    onClick={this.redirectToSocial(currentUser?.researchGate)}
                                    className="social-button"
                                    rel="noopener noreferrer"
                                >
                                    {restrictedNetwork ? (
                                        <Tooltip title="Restricted" enterTouchDelay={1}>
                                            <ResearchGateIcon width={24} height={24} fill={colors.grey} />
                                        </Tooltip>
                                    ) : (
                                        <ResearchGateIcon width={24} height={24} fill={colors.grey} />
                                    )}
                                </a>
                            )}
                            {!!currentUser?.twitter && (
                                <a
                                    onClick={this.redirectToSocial(currentUser?.twitter)}
                                    className="social-button"
                                    rel="noopener noreferrer"
                                >
                                    {restrictedNetwork ? (
                                        <Tooltip title="Restricted" enterTouchDelay={1}>
                                            <TwitterIcon width={24} height={24} fill={colors.grey} />
                                        </Tooltip>
                                    ) : (
                                        <TwitterIcon width={24} height={24} fill={colors.grey} />
                                    )}
                                </a>
                            )}
                        </div>
                        {displayOnly ? (
                            <div className="participant-button-add display-only">
                                <Connect fill={colors.secondary} />
                            </div>
                        ) : (
                            <>
                                {!isMyself && isFriendConnectionSent && (
                                    <div onDragStart={preventDefaultDrag} className="connection">
                                        <button className="participant-button-add" disabled>
                                            <RequestSent fillWarning={colors.warning} />
                                        </button>
                                    </div>
                                )}
                                {!isMyself && !isContact && !isFriendConnectionSent && !isFriendConnectionReceived && (
                                    <div onDragStart={preventDefaultDrag} className="connection">
                                        <button
                                            className="participant-button-add"
                                            onClick={this.handleSendFriendConnection}
                                            disabled={restrictedNetwork}
                                        >
                                            {restrictedNetwork ? (
                                                <Tooltip title="Restricted" enterTouchDelay={1}>
                                                    <Connect fill={colors.secondary} />
                                                </Tooltip>
                                            ) : (
                                                <Connect fill={colors.secondary} />
                                            )}
                                        </button>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                {!isMyself && showInviteToStageButton && <InviteToStage user={userData} />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        userContacts: state.user.data?.contacts,
        userSentRequests: state.user.data?.sentRequests,
        userReceivedRequests: state.user.data?.receivedRequests,
        userLoading: state.user.loading,
        event: state.event.data,
        eventRoles: state.user.eventRoles,
        allEventUsersObject: state.eventUsers.allEventUsersObject,
        featuredUsersFormatted: state.eventUsers.featuredUsersFormatted,
        onlineUsersObject: state.onlineUsers.onlineUsersObject,
        privateChats: state.user.data.privateChats,
        resourcesAccess: state.user.resourcesAccess,
        platformLanguage: state.languages.platformLanguage,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        eventId: state.event.eventId,
        isLargeScreen: state.layout.isLargeScreen,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onOpenPrivateChat: (privateChatId) => dispatch(actions.openPrivateChat(privateChatId)),
        onCreatePrivateChat: (targetUserId) => dispatch(actions.createPrivateChat(targetUserId)),
        onSendContactRequest: (to, eventId) => dispatch(actions.sendContactRequest(to, eventId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUserCard);
