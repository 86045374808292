import axios from '../axios-instance';
import * as actionTypes from './actionTypes';

import {startCapture, startPlayback} from '../../Utils/videoConference';
import {NotificationManager} from 'react-notifications';
import {DEFAULT_MEDIA_KINDS} from '../../Utils/utils';

export const getSessions = (eventId) => (dispatch) => {
    dispatch({type: actionTypes.GET_SESSIONS_START});

    axios
        .get(`/sessions/events/${eventId}`)
        .then(({data}) => {
            dispatch({
                type: actionTypes.GET_SESSIONS_SUCCESS,
                sessions: data.sessions,
            });
        })
        .catch((error) => dispatch({type: actionTypes.GET_SESSIONS_FAIL}));
};

export const createSession = (data) => (dispatch) => {
    dispatch({type: actionTypes.CREATE_SESSIONS_START});
    const formData = new FormData();

    formData.append('name', data.name);
    formData.append('event', data.event);
    formData.append('type', data.type);

    if (data.file) {
        formData.append('link', data.file, data.fileName, data.file.type);
    }

    const config = {
        headers: {'Content-Type': 'multipart/form-data'},
    };

    return axios
        .post(`/sessions`, formData, config)
        .then((response) => {
            dispatch({type: actionTypes.CREATE_SESSIONS_SUCCESS});
        })
        .catch((error) => dispatch({type: actionTypes.CREATE_SESSIONS_FAIL}));
};

export const updateSession = (sessionId, data) => (dispatch) => {
    dispatch({type: actionTypes.UPDATE_SESSIONS_START});
    const formData = new FormData();

    formData.append('name', data.name);
    formData.append('event', data.event);
    formData.append('type', data.type);

    if (data.file) {
        formData.append('link', data.file, data.fileName, data.file.type);
    }

    const config = {
        headers: {'Content-Type': 'multipart/form-data'},
    };

    return axios
        .put(`/sessions/${sessionId}`, formData, config)
        .then((response) => {
            dispatch({type: actionTypes.UPDATE_SESSIONS_SUCCESS});
        })
        .catch((error) => dispatch({type: actionTypes.UPDATE_SESSIONS_FAIL}));
};

export const deleteSession = (sessionId) => (dispatch) => {
    dispatch({type: actionTypes.DELETE_SESSIONS_START});

    return axios
        .delete(`/sessions/${sessionId}`)
        .then((response) => {
            dispatch({type: actionTypes.DELETE_SESSIONS_SUCCESS});
        })
        .catch((error) => dispatch({type: actionTypes.DELETE_SESSIONS_FAIL}));
};

export const getProfileData = (sessionId) => {
    return (dispatch) => {
        dispatch({type: actionTypes.GET_PROFILE_DATA_START});

        axios
            .get(`/sessions/${sessionId}/profile`)
            .then(({data: {profile}}) => dispatch({type: actionTypes.GET_PROFILE_DATA_SUCCESS, payload: profile}))
            .catch((err) => dispatch({type: actionTypes.GET_PROFILE_DATA_FAIL}));
    };
};

export const getSessionData = ({sessionId, callback}) => {
    return (dispatch) => {
        dispatch(getSessionDataStart());
        axios
            .get(`/sessions/${sessionId}`)
            .then(({data: {session}}) => {
                dispatch(getSessionDataSuccess({session}));
                return session;
            })
            .then((data) => {
                if (data.type !== 'zoom') dispatch(openSessionConference());
                if (callback) callback(data);
            })
            .catch((err) => dispatch(getSessionDataFail()));
    };
};

export const updateSessionThumbnail = (sessionId, image) => (dispatch) => {
    dispatch(getSessionDataStart());
    const formData = new FormData();
    formData.append('thumbnail', image, image.name, image.type);

    const config = {
        headers: {'Content-Type': 'multipart/form-data'},
    };

    return axios
        .post(`/sessions/${sessionId}/thumbnail`, formData, config)
        .then(({data: {session}}) => {
            dispatch(getSessionDataSuccess({session}));
        })
        .catch((err) => dispatch(getSessionDataFail()));
};

export const deleteSessionThumbnail = (sessionId) => (dispatch) => {
    dispatch({type: actionTypes.UPDATE_SESSIONS_START});
    return axios
        .delete(`/sessions/${sessionId}/thumbnail`)
        .then(({data: {session}}) => {
            dispatch(getSessionDataSuccess({session}));
        })
        .catch((err) => dispatch(getSessionDataFail()));
};

const getSessionDataStart = () => ({type: actionTypes.GET_SESSION_DATA_START});
export const getSessionDataSuccess = (payload) => ({type: actionTypes.GET_SESSION_DATA_SUCCESS, payload});
const getSessionDataFail = () => ({type: actionTypes.GET_SESSION_DATA_FAIL});

export const sendChatMessage = ({sessionId, text}) => {
    return (dispatch) => {
        dispatch({type: actionTypes.SEND_CHAT_MESSAGE_START});

        axios
            .post(`/sessions/${sessionId}/chat`, {text})
            .then(({data: {chat}}) => dispatch({type: actionTypes.SEND_CHAT_MESSAGE_SUCCESS, payload: chat}))
            .catch((err) => dispatch({type: actionTypes.SEND_CHAT_MESSAGE_FAIL}));
    };
};

export const receiveChatMessage = (payload) => ({
    type: actionTypes.RECIEVE_CHAT_MESSAGE,
    payload,
});

export const registerSessionMember = ({sessionId, payload, callback}) => {
    return (dispatch) => {
        dispatch({type: actionTypes.REGISTER_SESSION_MEMBER_START});

        return axios
            .post(`/sessions/${sessionId}/register`, payload)
            .then(({data}) => {
                dispatch({type: actionTypes.REGISTER_SESSION_MEMBER_SUCCESS, payload: data.user});
                localStorage.setItem('sessionToken', data.sessionToken);
            })
            .then(() => {
                if (callback) callback();
            })
            .catch((err) => dispatch({type: actionTypes.REGISTER_SESSION_MEMBER_FAIL}));
    };
};

export const videoConferenceReady = (payload) => ({
    type: actionTypes.VIDEO_CONFERENCE_READY,
    payload,
});

export const videConferenceLoading = () => ({
    type: actionTypes.VIDEO_CONFERENCE_LOADING,
});

export const closeSessionConference = () => {
    return async (dispatch, setState) => {
        const {
            sessions: {
                sessionDashboard: {
                    videoConference: {capture, playbacks, selfPlayback},
                },
            },
        } = setState();

        playbacks.forEach(async (playback) => await playback.close());
        if (selfPlayback) await selfPlayback.close();
        if (capture) {
            capture.mediaStream.getVideoTracks().forEach((track) => {
                track.stop();
                capture.removeTrack(track);
            });
            await capture.close();
        }

        dispatch({
            type: actionTypes.CLOSE_VIDEO_CONFERENCE,
        });
    };
};

export const openSessionConference = () => {
    return async (dispatch, setState) => {
        const {
            sessions: {
                sessionDashboard: {
                    profile,
                    session: {users},
                },
            },
        } = setState();

        dispatch(videConferenceLoading());

        const usersPromises = users
            .filter(({userId, connected}) => userId !== profile.userId && connected)
            .map(({userId}) => startPlayback(userId));

        const payload = {
            capture: await startCapture(profile.userId),
            selfPlayback: await startPlayback(profile.userId, ['video']),
            playbacks: await Promise.all(usersPromises),
        };
        dispatch(videoConferenceReady(payload));
    };
};

const updatePlaybacks = (payload) => ({
    type: actionTypes.VIDEO_CONFERENCE_CHANGE_PLAYBACKS,
    payload,
});

export const changeSessionPlaybacks = (newUsers) => {
    return async (dispatch, setState) => {
        const {
            sessions: {
                sessionDashboard: {videoConference},
            },
        } = setState();

        for (const user of newUsers) {
            const stream = user.userId;
            const index = videoConference.playbacks.findIndex((pb) => pb.configs.stream === stream);
            if (index >= 0) {
                await videoConference.playbacks[index].close();
                videoConference.playbacks.splice(index, 1);
            } else {
                const playback = await startPlayback(stream);
                videoConference.playbacks.push(playback);
            }
        }

        dispatch(updatePlaybacks({playbacks: [...videoConference.playbacks]}));
    };
};

export const toggleSessionScreen = (isShare) => {
    return async (dispatch, setState) => {
        const {
            sessions: {
                sessionDashboard: {
                    videoConference,
                    profile: {userId},
                },
            },
        } = setState();

        const capture = await startCapture(userId, DEFAULT_MEDIA_KINDS, isShare, videoConference.capture);
        if (!capture) return;
        dispatch(toggleSessionShareScreen({capture, isScreenShared: isShare}));
    };
};

export const toggleSessionShareScreen = (payload) => ({
    type: actionTypes.VIDEO_CONFERENCE_SHARE_SCREEN_TOGGLED,
    payload,
});

export const toggleSessionFullScreen = () => ({type: actionTypes.VIDEO_CONFERENCE_FULL_SCREEN_TOGGLED});

export const sessionStreamStart = (sessionId) => {
    return (dispatch) => {
        dispatch(sessionStreamStartRequest());

        axios
            .post(`/sessions/${sessionId}/stream`)
            .then(({data: {session}}) => dispatch(sessionStreamStartSuccess(session)))
            .catch(() => dispatch(sessionStreamStartFail()));
    };
};

const sessionStreamStartRequest = () => ({type: actionTypes.SESSION_STREAM_START_REQUEST});
const sessionStreamStartSuccess = (payload) => ({type: actionTypes.SESSION_STREAM_START_SUCCESS, payload});
const sessionStreamStartFail = () => ({type: actionTypes.SESSION_STREAM_START_FAIL});

export const sessionStreamStop = (sessionId) => {
    return (dispatch) => {
        dispatch(sessionStreamStopRequest());

        axios
            .delete(`/sessions/${sessionId}/stream`)
            .then(({data: {session}}) => dispatch(sessionStreamStopSuccess(session)))
            .catch(() => dispatch(sessionStreamStopFail()));
    };
};

const sessionStreamStopRequest = () => ({type: actionTypes.SESSION_STREAM_STOP_REQUEST});
const sessionStreamStopSuccess = (payload) => ({type: actionTypes.SESSION_STREAM_STOP_SUCCESS, payload});
const sessionStreamStopFail = () => ({type: actionTypes.SESSION_STREAM_STOP_FAIL});

export const sessionStreamRecordStart = (sessionId) => {
    return (dispatch) => {
        dispatch(sessionStreamStartRecordRequest());

        axios
            .post(`/sessions/${sessionId}/records`)
            .then(({data: {session}}) => dispatch(sessionStreamStartRecordSuccess(session)))
            .catch(() => dispatch(sessionStreamStartRecordFail()));
    };
};

const sessionStreamStartRecordRequest = () => ({type: actionTypes.SESSION_STREAM_START_RECORD_REQUEST});
const sessionStreamStartRecordSuccess = (payload) => ({type: actionTypes.SESSION_STREAM_START_RECORD_SUCCESS, payload});
const sessionStreamStartRecordFail = () => ({type: actionTypes.SESSION_STREAM_START_RECORD_FAIL});

export const sessionStreamRecordStop = (sessionId) => {
    return (dispatch) => {
        dispatch(sessionStreamRecordStopRequest());

        axios
            .delete(`/sessions/${sessionId}/records`)
            .then(({data: {session}}) => dispatch(sessionStreamRecordStopSuccess(session)))
            .catch(() => dispatch(sessionStreamRecordStopFail()));
    };
};

const sessionStreamRecordStopRequest = () => ({type: actionTypes.SESSION_STREAM_STOP_RECORD_REQUEST});
const sessionStreamRecordStopSuccess = (payload) => ({type: actionTypes.SESSION_STREAM_STOP_RECORD_SUCCESS, payload});
const sessionStreamRecordStopFail = () => ({type: actionTypes.SESSION_STREAM_STOP_RECORD_FAIL});


export const changeSpeakerAuthorization = ({sessionId, userId, permissionType, permissionValue}) => {
    return (dispatch) => {
        const userToken = localStorage.sessionToken || localStorage.token;
        if (!userToken) return;

        dispatch(changeSessionPermissionRequest());

        const authorizationData = {
            permissionType: permissionType,
            permissionValue: permissionValue
        }

        axios
            .post(`/sessions/${sessionId}/edit-user-permissions/${userId}`, authorizationData)
            .then(({data: {session}}) => dispatch(changeSessionPermissionSuccess(session)))
            .catch(() => dispatch(changeSessionPermissionFail()));
    };
};


export const changeSessionPermission = ({sessionId, permissionType, userId}) => {
    return (dispatch) => {
        const userToken = localStorage.sessionToken || localStorage.token;
        if (!userToken) return;

        dispatch(changeSessionPermissionRequest());

        axios
            .post(`/sessions/${sessionId}/${permissionType}/${userId}`)
            .then(({data: {session}}) => dispatch(changeSessionPermissionSuccess(session)))
            .catch(() => dispatch(changeSessionPermissionFail()));
    };
};

const changeSessionPermissionRequest = () => ({type: actionTypes.CHANGE_SESSION_PERMISSION_REQUEST});
const changeSessionPermissionSuccess = (payload) => ({type: actionTypes.CHANGE_SESSION_PERMISSION_SUCCESS, payload});
const changeSessionPermissionFail = () => ({type: actionTypes.CHANGE_SESSION_PERMISSION_FAIL});

// ZOOM SESSIONS
export const zoomSessionStreamStart = (sessionId) => {
    return (dispatch) => {
        dispatch(zoomSessionStreamStartRequest());

        axios
            .post(`/sessions/${sessionId}/stream`)
            .then(({data: {session}}) => dispatch(zoomSessionStreamStartSuccess(session)))
            .catch(() => {
                NotificationManager.error("Live stream hasn't been connected", null, 5000, () => {
                    alert("Live stream hasn't been connected");
                });
                dispatch(zoomSessionStreamStartFail());
            });
    };
};

const zoomSessionStreamStartRequest = () => ({type: actionTypes.ZOOM_SESSION_STREAM_START_REQUEST});
const zoomSessionStreamStartSuccess = (payload) => ({type: actionTypes.ZOOM_SESSION_STREAM_START_SUCCESS, payload});
const zoomSessionStreamStartFail = () => ({type: actionTypes.ZOOM_SESSION_STREAM_START_FAIL});

export const zoomSessionStreamStop = (sessionId) => {
    return (dispatch) => {
        dispatch(zoomSessionStreamStopRequest());

        axios
            .delete(`/sessions/${sessionId}/stream`)
            .then(({data: {session}}) => dispatch(zoomSessionStreamStopSuccess(session)))
            .catch(() => dispatch(zoomSessionStreamStopFail()));
    };
};

const zoomSessionStreamStopRequest = () => ({type: actionTypes.ZOOM_SESSION_STREAM_STOP_REQUEST});
const zoomSessionStreamStopSuccess = (payload) => ({type: actionTypes.ZOOM_SESSION_STREAM_STOP_SUCCESS, payload});
const zoomSessionStreamStopFail = () => ({type: actionTypes.ZOOM_SESSION_STREAM_STOP_FAIL});

export const zoomSessionStreamRecordStart = (sessionId) => {
    return (dispatch) => {
        dispatch(zoomSessionStreamStartRecordRequest());

        axios
            .post(`/sessions/${sessionId}/records`)
            .then(({data: {session}}) => dispatch(zoomSessionStreamStartRecordSuccess(session)))
            .catch(() => dispatch(zoomSessionStreamStartRecordFail()));
    };
};

const zoomSessionStreamStartRecordRequest = () => ({type: actionTypes.ZOOM_SESSION_STREAM_START_RECORD_REQUEST});
const zoomSessionStreamStartRecordSuccess = (payload) => ({
    type: actionTypes.ZOOM_SESSION_STREAM_START_RECORD_SUCCESS,
    payload,
});
const zoomSessionStreamStartRecordFail = () => ({type: actionTypes.ZOOM_SESSION_STREAM_START_RECORD_FAIL});

export const zoomSessionStreamRecordStop = (sessionId) => {
    return (dispatch) => {
        dispatch(zoomSessionStreamRecordStopRequest());

        axios
            .delete(`/sessions/${sessionId}/records`)
            .then(({data: {session}}) => dispatch(zoomSessionStreamRecordStopSuccess(session)))
            .catch(() => dispatch(zoomSessionStreamRecordStopFail()));
    };
};

const zoomSessionStreamRecordStopRequest = () => ({type: actionTypes.ZOOM_SESSION_STREAM_STOP_RECORD_REQUEST});
const zoomSessionStreamRecordStopSuccess = (payload) => ({
    type: actionTypes.ZOOM_SESSION_STREAM_STOP_RECORD_SUCCESS,
    payload,
});
const zoomSessionStreamRecordStopFail = () => ({type: actionTypes.ZOOM_SESSION_STREAM_STOP_RECORD_FAIL});
