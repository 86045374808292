import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@material-ui/core';
import '../ImportTimeslotConfirmationStyles.scss';
import React from 'react';

const DownloadUsersExcelDialog = ({
	open,
	onClose,
	onConfirm,
	dialogContent,
	dialogTitle,
}) => {
	return (
		<div>
			<Dialog
				className="edit-user-information import-invitation-codes"
				open={open}
				onClose={onClose}
				aria-labelledby="form-dialog-title"
				PaperProps={{ classes: { root: 'reverse-rtl' } }}
			>
				<DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
				<DialogContent>
					<p> {dialogContent} </p>
				</DialogContent>
				<DialogActions className={'buttons-actions'}>
					<Button className="import-button" type="button" onClick={onClose}>
						Cancel
					</Button>
					<Button className="import-button" onClick={onConfirm} type="submit">
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
export default DownloadUsersExcelDialog;
