const es = {
	generalText: {
		upload: "Cargar",
		save: "Guardar",
		add: "Agregar",
		select: "Seleccionar",
		remove: "Eliminar",
		close: "CERRAR",
		update: "ACTUALIZAR",
		cancel: "CANCELAR",
		confirm: "Confirmar",
		download: "Descargar",
		refresh: "Actualizar",
		manage: "Administrar",
		back: "Atrás",
		submit: "Enviar",
		send: "Enviar",
		eventMO: "onvent",
		eventETX: "event",
		eventsMO: "onvents",
		eventsETX: "eventos",
		eventUpperMO: "Onvent",
		eventUpperETX: "Event",
		hide: "HIDE", //new translation: HIDE
	},
	time: {
		justNow: "justo ahora",
		minute: "minuto",
		minutes: "minutos",
		hour: "hora",
		hours: "horas",
		day: "día",
		days: "días",
		month: "mes",
		months: "meses",
		yesterday: "ayer",
		today: "hoy",
		tomorrow: "mañana",
		monday: "lunes",
		tuesday: "martes",
		wednesday: "miércoles",
		thursday: "jueves",
		friday: "viernes",
		saturday: "sábado",
		sunday: "domingo",
		mondayShort: "lun",
		tuesdayShort: "mar",
		wednesdayShort: "mier",
		thursdayShort: "jue",
		fridayShort: "vier",
		saturdayShort: "sab",
		sundayShort: "dom",
		january: "enero",
		february: "febrero",
		march: "marzo",
		april: "abril",
		may: "mayo",
		june: "junio",
		july: "julio",
		august: "agosto",
		september: "septiembre",
		october: "octubre",
		november: "noviembre",
		december: "diciembre",
		januaryShort: "ene",
		februaryShort: "feb",
		marchShort: "mar",
		aprilShort: "abr",
		mayShort: "may",
		juneShort: "jun",
		julyShort: "jul",
		augustShort: "ago",
		septemberShort: "sep",
		octoberShort: "oct",
		novemberShort: "nov",
		decemberShort: "dic",
	},
	errors: {
		noFile: "No se ha elegido ningún archivo",
		required: "El campo es obligatorio",
		emailNotValid: "El correo electrónico no es válido",
		passwordMinLength: "La contraseña debe tener un mínimo de 8 caracteres",
		passwordMismatch: "La contraseña no coincide",
		passwordIsSame: "Your new password must be different", //new translation
		fileToLarge:
			"El archivo es demasiado grande. Tamaño máximo de archivo: 2 MB",
		fileToLargeTextFirst: "El archivo es demasiado grande",
		fileToLargeTextSecond: "Máximo tamaño de archivo",
		agreeToGDPR: "Por favor, acepta la política de privacidad",
		noInvitationCode: "Por favor, introduce tu código de invitación",
		invitationCodeInvalid: "El código de invitación no es válido",
		imageFileNotSupported:
			"Tipo de archivo no soportado. Por favor usar uno de los siguientes:",
		phoneInvalid:
			"Por favor introduce un número de teléfono válido. Por ejemplo: +528110654789",
		linkInvalid: "Por favor introduce un enlace válido (https://site.com)",
		facebookLinkInvalid:
			"Por favor, introduce un enlace válido (https://facebook.com/)",
		linkedinLinkInvalid:
			"Por favor, introduce un enlace válido (https://linkedin.com/)",
		twitterLinkInvalid:
			"Por favor, introduce un enlace válido (https://twitter.com/)",
		youtubeLinkInvalid:
			"Por favor introduce un enlace válido (https://youtube.com)",
		connectionProblemTextFirst:
			"Parece que hay un problema con la conexión. Por favor",
		connectionProblemButton: "Click",
		connectionProblemTextSecond: "O refresca la página",
		fileTooLarge: "",
		fileTooLargeTextFirst: "El archivo está muy pesado.",
		fileTooLargeTextSecond: "Tamaño máximo de archivo",
	},
	footer: {
		cookiesText:
			"Este sitio web usa cookies esenciales, que son necesarias para su funcionamiento. También utiliza cookies de terceros, que son necesarias para el funcionamiento de algunas de nuestras funcionalidades. Si deseas aprender más o retirar tu consentimiento hacia algunas cookies, por favor consulta nuestra",
		cookiesLink: "política de cookies",
		cookiesButton: "ACEPTAR",
		privacyPolicy: "Política de privacidad",
		cookies: "Cookies",
		termsAndConditions: "Términos y condiciones",
		socialLinksTitle: "Síguenos:",
	},
	menu: {
		logIn: "INICIAR SESIÓN",
		logOut: "CERRAR SESIÓN",
		scholarRegistration: "Poster / Study Registration", // new translation
		exhibitorRegistration: "Conviértete en Expositor", // new translation modification (Be Exhibitor => Exhibitor Registration)
		participantRegistration: "REGISTRO", // new translation modification (Register => Participant Registration)
		createEvent: "Organiza un evento",
		launchText:
			"Lanzamiento en septiembre de 2020: abierto para acceso previo (early bird)",
	},
	externalIdTranslations: {
		headline: "Estás registrado",
		subHeadlineOne: "La sesión el ",
		subHeadlineTwo: "¡ahora está en tu calendario de Red Neuronal!",
		button: "VER PROGRAMA",
		checkCalendar:
			'Revisa "<strong>My Calendar</strong>" en la pestaña "<strong>Program</strong>" para las próximas sesiones y explora cientos de sesiones pasadas utilizando los filtros de búsqueda. ¡Y no te pierdas las "<strong>Smart Suggestions</strong>" para contenido seleccionado solo para ti!',
	},

	homepage: {
		organizedBy: "ORGANIZADO POR",
		date: "FECHA",
		location: "DOMINIO Y LOCALIZACIÓN",
		price: "PRECIO",
		tabIntro: "INTRODUCCIÓN",
		tabIntroProgramButton: "PROGRAMA",
		tabProgram: "PROGRAMA",
		tabProgramIntroButton: "INTRODUCCIÓN",
		tabPartners: "SOCIOS",
		tabExhibitors: "EXPOSITORES",
		previousEventsSectionTitle: "EVENTOS ANTERIORES",
		tabSocialMedia: "REDES SOCIALES",
	},
	socialButtons: {
		linkedinButton: "Inicia sesión con LinkedIn",
		facebookButton: "Inicia sesión con Facebook",
		googleButton: "Inicia sesión con Google",
		twitterButton: "Inicia sesión con Twitter",
	},
	login: {
		orText: "o",
		inputEmail: "Correo electrónico",
		inputPassword: "Contraseña",
		rememberMeButton: "Recuérdame",
		forgotPasswordButton: "¿Olvidaste la contraseña?",
		loginButton: "INICIAR SESIÓN",
		noAccountText: "¿No tienes una cuenta?",
		registerButton: "Regístrate aquí",
		recoverPasswordDialogTitle: "RECUPERACIÓN DE CONTRASEÑA",
		recoverPasswordDialogInputEmail: "Correo electrónico",
		recoverPasswordDialogCloseButton: "CERRAR",
		recoverPasswordDialogRecoverButton: "RECUPERAR",
		errorNotRegistered: "No registrado",
		errorUnableToLogin: "No se ha podido ingresar",
	},
	register: {
		formTitle: "O crea tu perfil en Events10X",
		inputFirstName: "Nombre",
		inputLastName: "Apellido",
		inputEmail: "Correo electrónico",
		inputPassword: "Contraseña",
		inputConfirmPassword: "Confirma la contraseña",
		uploadImageText: "Subir imagen de perfil (2MB):",
		editImageText: "Editar imagen de perfil (2MB)",
		gdprTextFirst: "Al proceder, estás de acuerdo con nuestros",
		gdprTextTerms: "Términos de uso",
		gdprTextSecond: "y confirmas que has leído nuestros",
		gdprTextCookies: "cookies",
		gdprTextThird: "y",
		gdprTextPrivacy: "Política de privacidad",
		gdprTextAgree: "Por favor, acepta la política de privacidad",
		registerButton: "Inscríbete",
		haveAccountText: "¿Ya tienes una cuenta?",
		haveAccountLoginButton: "Inicia sesión aquí",
		errorAlreadyHaveAccount: "Ya te has registrado con este correo electrónico",
		formTitleTextFirst: "O crea tu",
		formTitleTextSecond: "perfil",
		invitationCode: "código de invitación",
	},
	participantRegistration: {
		choosePackageTitle: "ELIGE EL PAQUETE DE ACCESO",
		noPackageText: "Por favor elige un paquete",
		gdprTextFirst: "Al proceder, estás de acuerdo con nuestra",
		gdprTextPrivacy: "Política de privacidad",
		gdprTextSecond: "y",
		gdprTextTerms: "Los términos y condiciones del evento",
		gdprTextAgree: "Por favor, acepta la política de privacidad",
		registerButtonFree: "Completa tu registro",
		registerButtonPay: "Procede al pago",
	},
	exhibitorRegistration: {
		choosePackageTitle: "ELIGE EL PAQUETE DE ACCESO",
		gdprTextFirst: "Al proceder, estás de acuerdo con nuestra",
		gdprTextPrivacy: "Política de privacidad de eventos",
		gdprTextSecond: "y",
		gdprTextTerms: "Términos y condiciones de eventos",
		gdprTextAgree: "Por favor, acepta la política de privacidad",
		registerButtonFree: "Registro completo",
		registerButtonPay: "Procede al pago",
	},
	stripePayment: {
		cardNumber: "Número de tarjeta",
		expirationDate: "Fecha de expiración (MM/AA)",
		cvc: "CVC",
		disclaimerText:
			"Esta operación de pago está encriptada fin a fin. La seguridad y privacidad están garantizadas por los servicios de nuestra plataforma de pago",
		disclaimerTextLink: "Haz click aquí para saber más",
		cancelButton: "CANCELAR",
		payButton: "Pagar",
	},
	recoverPassword: {
		recoverTitle: "RECUPERACIÓN DE CONTRASEÑA",
		cancelButton: "CERRAR",
		recoverButton: "RECUPERAR",
		recoverTextSend:
			"El correo electrónico de recuperación de la contraseña se ha enviado correctamente.",
	},
	unsubscribe: {
		unsubscribeTitle: "¿Deseas dejar de estar inscrito?",
		unsubscribeHomepage: "Ir a la página principal",
	},
	notAvailable: {
		notAvailableTitle: "Event10X no está disponible",
		notAvailableSubtitle:
			"El evento que buscas no está disponible. Es probable que haya sido cerrado por el organizador o desactivado por el administrador",
		notAvailableTitleTextSecond: "no está disponible",
		notAvailableSubtitleTextFirst: "El",
		notAvailableSubtitleTextSecond:
			"que estás buscando no está disponible. Probablemente haya sido cerrado por el organizador o inhabilitado por el administrador",
		closedTitle: "cerrado ahora",
		closedSubtitleTextFirst: "Te has registrado exitosamente a este",
		closedSubtitleTextSecond: "está actualmente cerrado",
	},
	notFound: {
		notFoundDescription: "Opps…No hemos encontrado tu evento con esa URL",
		notFoundHomepage: "Ir a la página principal",
	},
	eventMenu: {
		organizerButton: "ORGANIZADOR",
		exhibitorButton: "EXPOSITOR",
		boothButton: "STAND VIRTUAL",
		boothsButton: "STANDS VIRTUALES",
		homeButton: "INICIO",
		contactsButton: "CONTACTOS",
		meetingsButton: "REUNIONES",
		chatsButton: "CHATS",
		programButton: "PROGRAMA",
		archiveButton: "MEMORIA DE ARCHIVOS",
		myAccountButton: "MI CUENTA",
		lobbyButton: "Lobby",
		roomsButton: "SALAS",
		connectionProblemTextFirst:
			"Hay nuevas actualizaciones disponibles. Por favor, haga clic",
		connectionProblemButton: "AQUÍ",
		connectionProblemTextSecond: "O refresca la página",
		sessionProblemTextFirst: "Tu sesión ha expirado. Por favor da click",
		sessionProblemButton: "AQUÍ",
		sessionProblemTextSecond: "Para iniciar sesión de nuevo.",
		backButton: "ATRÁS",
		hubButton: "HUB",
		metaverse: "METAVERSO",
		floorPlan: "PLANO DE PLANTA",
	},
	myEventsDropdown: {
		title: "MIS EVENTOS",
		createButtonDesktop: "Crear evento",
		createButtonMobile: "CREAR",
	},
	myBoothsDropdown: {
		title: "MIS STANDS VIRTUALES",
	},
	contactsDropdown: {
		title: "TUS CONTACTOS",
		requestsTitle: "Solicitudes de contacto",
		rejectButton: "RECHAZAR",
		acceptButton: "ACEPTAR",
		pendingButton: "Pendiente",
		addButton: "Agregar",
		noContacts:
			"No tienes ningún contacto aún. Por favor visita la sessión de Networking para comenzar a gestionar tus contactos.",
		noScannedContacts: "Aún no tienes ningún contacto escaneado.",
	},
	meetingsDropdown: {
		meetingWith: "Reunión con",
		pending: "Pendiente",
		accepted: "Aceptada",
		canceled: "Cancelada",
		noMeetings: "No hay reuniones agendadas todavía",
		finished: "Terminado",
	},
	programDropdown: {
		timeZone: "Huso horario",
		with: "con",
		noProgram: "",
	},
	notificationsDropdown: {
		title: "Notificaciones",
		contactRequestReceived: "Has enviado una petición de contacto, ¡revísala!",
		contactRequestAccepted:
			"ha aceptado tu solicitud de contacto, ¡comienza a platicar!",
		meetingRequestReceived:
			"Te han enviado una petición de reunión, ¡revísala!",
		meetingRequestAccepted: "Han aceptado la petición de reunión, ¡revísala!",
		meetingRequestCanceled: "Han cancelado la reunión, ¡revísala!",
		meetingStarting: "Una reunión comenzarà pronto, ¡revísala!",
		newBoothPost: "ha posteado en tu stand virtual, ¡revísalo!",
		noNotifications: "No tienes ninguna notificación todavía.",
		seeAll: "VER TODO",
		hideAll: "ESCONDER TODO",
		agendaItemUpdated:
			"El Programa ha sido actualizado por el organizador del evento",
		agendaItemDelted:
			"El Programa ha sido eliminado por el organizador del evento",
		newMeetingRequest: "Tienes una invitación para una reunión",
		meetingAccepted: "Tu petición de reunión ha sido aceptada",
		meetingCancelled: "Tu reunión ha sido cancelada",
		meetingWillStartTextFirst: "Una reunión con",
		meetingWillStartTextSecond: "comenzará pronto",
	},
	chatsDropdown: {
		title: "CHATS",
		newChatButton: "Nuevo chat",
		newChatStart: "Comenzar un nuevo chat",
		newChatProvideText:
			"Por favor nombre tu chat. Podrás editarlo más adelante:",
		noChats:
			"Crear al menos un chat para platicar con los participantes del evento",
		member: "miembro",
		members: "miembros",
		noOpenedChatText: "Dar click en el chat para continuar la conversación",
		noMessagesInChatTextFirst: "Rompe el hielo",
		noMessagesInChatTextSecond: "Sé el primero en abrir la conversación.",
		newChatNameInput: "Nombre del chat (opcional)",
		newChatAddMembers: "Agrega contactos y otros participantes a este chat:",
		editChatAddMembers:
			"Por favor busca a los usuarios registrados en este evento que deseas agregar a este chat grupal.",
		newChatSearchInput: "Buscar usuarios",
		newChatNoUsersMessage: "No se han encontrado usuarios",
		newChatAddUserButton: "Agregar",
		newChatRemoveUserButton: "Eliminar",
		newChatCancelButton: "CERRAR",
		newChatCreateButton: "CREAR",
		membersLeaveButton: "Salir",
		membersUpdateButton: "ACTUALIZAR",
		membersChatButton: "Chat",
		chatSettingsButton: "Ajustes",
		newMessageInput: "Escribe el mensaje aquí",
		newVideoCallTooltipText: "Haz click aquí para comenzar una videollamada",
		fullscreenButton: "Pantalla completa",
		minimizeButton: "Minimizar pantalla",
	},
	myAccountDropdown: {
		title: "MI CUENTA",
		viewProfileButton: "Ver perfil",
		logoutButton: "CERRAR SESIÓN",
	},
	myAccountPage: {
		platformProfileSectionTitle: "Mi perfil de Events10X",
		changePasswordButton: "CAMBIAR LA CONTRASEÑA",
		deleteAccountButton: "ELIMINAR MI CUENTA",
		platformLanguage: "Plataforma de lenguaje:",
		reviewPlatformGdprText: "Revisa las condiciones de Events10X:",
		privacyPolicyLink: "Nota de privacidad / Políticas",
		cookiesLink: "Cookies",
		termsAndConditionsLink: "Términos y condiciones",
		eventProfileSectionTitle: "Perfil de evento",
		eventPackage: "Paquete de evento",
		reviewEventGdprText: "Revisa nuestras condiciones:",
		unregisterFromEventButton: "Sal del registro del evento:",
		unregisterCurrentEventButton: "Sal del registro de este evento:",
		unregisterCurrentEventConfirmation:
			"Por favor confirma que deseas salir del registro del evento",
		enableParticipantProfile: "Activa el perfil del participante",
		editUserDialogTitle: "Editar los detalles del usuario",
		editExhibitorDialogTitle: "Edita los detalles del contacto de la compañía",
		editParticipantDialogTitle: "Editar los detalles del participante",
		firstName: "Nombre",
		lastName: "Apellido",
		uploadImage: "Cargar imagen",
		removeImage: "Eliminar imagen",
		deleteTitle: "ELIMINAR MI CUENTA",
		deleteConfirm: "Confirmo que deseo eliminar mi cuenta",
		deleteError:
			"No puedes borrar la cuenta. Primero borra los eventos creados para borrar la cuenta",
		deleteSuccess: "Tu cuenta ha sido borrada.",
		email: "Correo electrónico",
		reviewEventPrivacyPolicy: "Revisa nuestra política de privacidad",
		title: "Cargo",
		company: "Empresa",
		phone: "Teléfono",
		platformProfileSectionTitleTextSecond: "perfil",
		reviewPlatformGdprTextFirst: "Revisa nuestras",
		reviewPlatformGdprTextSecond: "condiciones",
	},
	changePassword: {
		title: "CAMBIAR LA CONTRASEÑA",
		oldPassword: "Contraseña antigua",
		oldPasswordError: "La contraseña antigua no coincide",
		inputPassword: "Nueva contraseña",
		inputConfirmPassword: "Confirma la contraseña",
		changePasswordButton: "CAMBIAR LA CONTRASEÑA",
	},
	roomsDropdown: {
		title: "Menú de salas",
		auditoriumButton: "Auditorio",
		showfloorButton: "Piso de exhibición",
		archiveButton: "MEMORIA DE ARCHIVOS",
		onDemandButton: "ON-DEMAND", // new translation
		metaverse: "METAVERSO",
		floorPlan: "PLANO DE PLANTA",
	},
	sideMenu: {
		liveWall: "Chat en vivo",
		networking: "Red de contactos",
		info: "INFORMACIÓN",
		videoWall: "Video",
		booth: "Stand virtual",
		boothWall: "Stand",
		resources: "Recursos",
		networkingTooltip: "No tienes acceso a las funciones de networking",
		polls: "ENCUESTAS",
	},
	wall: {
		sortBy: "Ordenar por:",
		popularity: "POPULARIDAD",
		time: "TIEMPO",
		emptyWallText: "Sé el primero en agregar un post",
		newPostInput: "¿Qué estás pensando?",
		newCommentInput: "Comentario",
		resourcesVideoDescription: "Descripción del video",
		resourcesLinks: "Enlaces",
		resourcesFiles: "Archivos",
		whatIsOnYourMind: "¿Qué estás pensando?",
	},
	networking: {
		searchInput: "Buscar por usuario",
		at: "en",
		regenerateMatches: "regenerate matches",
	},
	closedAuditoriumDialog: {
		title: "Información del auditorio",
		content: "El acceso al auditorio ha sido restringido",
		contentPackage: "Tu paquete  no cuenta con acceso a ningún auditorio",
		button: "OK",
	},
	closedShowfloorDialog: {
		title: "Información de la Sala de Exhibición",
		content: "Acceso a la Sala de Exhibición ha sido restringido",
		contentPackage: "Tu paquete  no cuenta con acceso a la Sala de Exhibición", //New translation: Your access package doesn't have access to any showfloors.
		button: "OK",
	},
	auditorium: {
		willStart: "La siguiente sesión comenzará en…",
		noAccess: "Tu paquete  no cuenta con acceso a este auditorio",
		noVideos:
			"No hay ninguna sesión programada en este auditorio por el momento",
		stageTab: "ESCENARIO",
		stagesButton: "ESCENARIOS",
		videoWallButton: "Muro de video",
		resourcesButton: "RECURSOS",
		slotEndsTextFirst: "Esta sesión termina en",
		slotEndsTextSecond:
			"La presentación estará disponible para reproducción al finalizar la presente sesión",
		slotTitleWith: "con",
		allRooms: "TODAS LAS SALAS",
		onDemandButton: "ON-DEMAND", // new translation
		allFloors: "TODOS LOS PLANOS DE PLANTA",
		hide: "OCULTAR",
	},
	auditoriumArchive: {
		title: "Memoria de Archivos del Auditorio",
		titleEvent: "Memoria de Archivos del Evento",
		with: "con",
		stage: "ESCENARIO",
		videoWall: "Muro de Video",
		resources: "RECURSOS",
		tooltipMinimize: "Minimizar",
		tooltipFullscreen: "Pantalla completa",
		tooltipPause: "Pausa",
		tooltipPlay: "Iniciar",
		tooltipUnmute: "Quitar silencio",
		tooltipMute: "Silenciar",
		noVideosAvailable: "All sessions finished", // new translation
	},
	videoPlayer: {
		videoNotPlayingError:
			"¿Estás experimentando problemas con el video? Por favor refresca la página",
		videoEndedTextFirst:
			"El video ha concluido; ¡Muchas gracias por participar!",
		videoEndedTextSecond:
			"El muro del video está abierto para Preguntas y Respuestas",
		tooltipPlay: "Iniciar",
		tooltipPause: "Pausa",
		tooltipMinimize: "Minimizar",
		tooltipFullscreen: "Pantalla completa",
		tooltipMute: "Silenciar",
		tooltipUnmute: "Quitar silencio",
	},
	showfloor: {
		allExhibitorsButton: "TODOS LOS EXPOSITORES", //new translation: Showfloor menu
		featuredExhibitorsText: "EXPOSITORES",
		boothLinksDialogTitle: "ENLACES DEL STAND",
		boothLinksDialogOtherLinksTitle: "Otro:",
		boothFilesDialogTitle: "ARCHIVOS DEL STAND VIRTUAL",
		boothVideoDialogTitle: "VIDEO",
		mobileTabChat: "Chat",
		mobileTabFiles: "Archivos",
		mobileTabLinks: "Enlaces",
		mobileTabVideo: "VIDEO",
		noExhibitorsTextFirst:
			"LOS EXPOSITORES SE ENCUENTRAN TRABAJANDO EN SUS STANDS VIRTUALES",
		noExhibitorsTextSecond: "PERMANECE PENDIENTE",
		filterBy: "Ordenar por",
		searchByTheUser: "Buscar por usuario",
		showShowfloorsButton: "ALL EXHIBIT HALLS", //new translation
		searchExhibitors: "Search exhibitors", // //new translation: Search exhibitors
		showfloorsTitle: "Exhibit Halls", //new translation: Showfloors
	},
	eventArchive: {
		title: "Memoria de Archivos de Video",
		searchText: "Da click aquí para buscar",
		noVideosText: "No hay videos en este evento",
		noArchivedEvents: "No hay videos en el archivo por el momento",
	},
	exhibitorDashboard: {
		event: "Evento",
		title: "Configura tu stand virtual",
		visitorsButton: "Visitantes del stand virtual",
		goToBoothButton: "Llévame al stand virtual",
		goToBoothButtonDisabled:
			"Tu stand virtual no está disponible para la vista previa todavía.",
		tabStyle: "Estilo del stand virtual",
		tabLogo: "Logotipo",
		tabFiles: "Archivos",
		tabLinks: "Enlaces",
		tabAvatars: "Avatares",
		tabVideo: "Video",
		boothStyleTab: {
			optionOne: "Opción 1 - Escandinavo",
			optionTwo: "Opción 2 - Moderno",
			optionThree: "Opción 3 - Dinámico",
			optionFour: "Opción 4 - Personalizado",
		},
		logoTab: {
			title: "Logotipo",
			fileUploadButton: "Haga clic aquí para cargar",
			noFile: "No se ha elegido ningún archivo",
		},
		filesTab: {
			title: "Documentos:",
			fileNameInput:
				"Nombre del documento (visible para todos los participantes)",
			chooseFileButton: "Elige el archivo",
			noDocAdded: "No se han agregado documentos",
			fileError: "Por favor, agrega un nombre de documento",
		},
		linksTab: {
			social: {
				title: "Enlaces a redes sociales",
				subtitle: "(visible para todos los participantes)",
				facebookInput: "Inserta tu enlace de Facebook",
				linkedinInput: "Inserta tu enlace LinkedIn",
				twitterInput: "Inserta tu enlace de Twitter",
			},
			other: {
				title: "Otros enlaces",
				subtitle: "(Página principal u otros enlaces)",
				linkInput: "Insertar un nuevo enlace",
				linkLabel: "Insertar una nueva etiqueta de enlace",
			},
		},
		avatarsTab: {
			mainRepresentatives: {
				title: "Representantes del stand virtual (Usuario Expositor)",
				subtitle: "(solamente un  Usuario Expositor es obligatorio)",
				leftRepresentative: "Usuario Expositor de la izquierda",
				rightRepresentative: "Usuario Expositor de la derecha",
				addDialog: {
					title: "Agregar Avatar",
					contentFirst:
						"Elige un avatar de la galería que se encuentra abajo, o carga tu avatar personalizado",
					contentSecond: "¿Necesitas ayuda para el diseño? Ponte en contacto:",
					female: "Mujer",
					male: "Hombre",
					uploadSectionTitle: "Sube tu avatar personal",
					uploadButton: "Elige el archivo",
					noFile: "Elige el archivo",
					assignText:
						"Por favor, asigna un participante del evento para que se asocie con el Usuario Expositor.",
					input: "Busca por nombre aquí",
					avatarLabels: {
						femaleA: "Mujer A",
						femaleB: "Mujer B",
						femaleC: "Mujer C",
						maleA: "Hombre A",
						maleB: "Hombre B",
						maleC: "Hombre C",
					},
				},
			},
			additionalRepresentatives: {
				title: "Usuarios Expositores adicionales",
				subtitle: "(opcional, será mostrado con su fotografía de perfil)",
				addDialog: {
					title: "Elegir un Usuario Expositor adicional",
					titleScholar: "Choose Co-Author", //new translation
					assignText:
						"Por favor busca a los participantes para agregarlos como representantes",
					assignError: "El usuario ha sido agregado como Usuario Expositor",
					chooseAvatarTextFirst:
						"Elige un avatar de la galería que se encuentra abajo, o carga tu avatar personalizado",
					chooseAvatarTextSecond:
						"¿Necesitas ayuda para el diseño? Ponte en contacto:",
					uploadText: "Sube tu avatar personal",
					chooseFileText:
						"No se admite el tipo de archivo. Por favor, utiliza uno de los siguientes: jpeg, jpg o png.",
					noFile: "No se ha elegido ningún archivo",
				},
			},
			author: {
				title: "Presenting Author", // new translation
				subtitle:
					"Add only an author that registered to the event. You can add 1 author.", // new translation
				mainRepresentative: "Main representative", // new translation
			},
			coAuthors: {
				title: "Co-Authors", // new translation
				subtitle:
					"Add only co-authors that registered to the event. You can add up to 4 co-authors.", // new translation
			},
		},
		videoTab: {
			video: {
				title: "Video para el stand virtual",
				subtitle: "(Solamente ligas de YouTube)",
				input: "Introduce el URL de YouTube",
			},
			image: {
				title: "Imagen",
				subtitle:
					"(La imagen se mostrará en tu stand virtual en caso de que no hayas agregafo un link en la sección de arriba)",
				uploadButton: "Haz clic aquí para cargar",
			},
		},
	},
	boothDashboard: {
		event: "Evento",
		title: "Tu stand virtual",
		manage: "Administrar",
		goToBoothButton: "Llévame al stand virtual",
		previewNotAvailable:
			"Tu stand virtual no está disponible para la vista previa todavía.",
		statistics: "Estadísticas",
		usersNow: "Asistentes ahora",
		usersAllTime: "Asistentes históricos",
		linkClicks: "Clicks en los enlaces",
		documentViews: "Vistas de los documentos",
		videoViews: "Vistas del video",
		visitorsSectionTitle: "Visitantes del stand virtual",
		noVisitors: "Aún no hay visitantes en tu stand virtual",
		visitorsSearch: "Buscar usuarios",
		actionType: "TIPO DE ACCIÓN",
		showUsers: "MOSTRAR USUARIOS",
		ofUsers: "# DE USUARIOS",
	},
	polls: {
		polls: "ENCUESTAS",
		poll: "ENCUESTA",
		noPolls: "No se ha agregado ninguna encuesta todavía.",
		pollProgress: "Encuesta en progreso.",
		pollClosed: "Encuesta cerrada.",
		pollVoted: "Participantes que han votado",
	},
	onDemandVideos: {
		sectionTitle: "ON-DEMAND", // new translation
		noOnDemandVideos: "No on demand videos were added yet.", // new translation
	},
	meetings: {
		meeting: "REUNIÓN",
		meetings: "REUNIÓN",
		createDialog: {
			createTitle: "Solicitar reunión con",
			inputDate: "Fecha",
			inputStart: "Comienza",
			inputEnd: "Termina",
			inputTimezone: "Zona horaria",
			inputNote:
				"Nota: Solamente una reunión puede ser agendada a la vez con la misma persona.",
			meetingError:
				"No es una fecha y hora válida para la reunión. ¡Por favor revísalas!",
			buttonDisabled:
				"Puedes agendar una reunión después de comenzar la conversación.",
		},
		banner: {
			pending: "Petición de reunión",
			accepted: "Reunión agendada para",
			progress: "Reunión en progreso",
			cancelTitle: "¿Cancelar reunión?",
			cancelDescription: "¿Estás seguro de cancelar esta reunión?",
			startCall: "COMENZAR LLAMADA",
			joinCall: "UNIRSE",
			pendingOwner: "Hay una petición de reunión pendiente de",
			videoCallStarted: "La reunión por videollamada ha comenzado",
		},
		newMeeting: "NUEVA REUNIÓN",
		requestMeeting: "Pedir reunión con",
		startDate: "Fecha de inicio",
		starts: "Inicia",
		ends: "Termina",
		addMessageHere: "Agrega tu mensaje aquí",
		note: "Nota: solamente puedes solicitar una reunión con una persona a la vez. Si la reunión es pospuesta o cancelada podrás re agendarla.",
		create: "CREAR",
		requested: "Solicitada",
		confirmed: "Confirmada",
		title: "Tus reuniones",
		meetingsInfo:
			"Las reuniones pueden ser comenzadas desde el Chat. Por favor comienza la conversación desde el Chat si deseas agendar una reunión",
	},
	landingPage: {
		animatedSectionButton: "¡COMIENZA AQUÍ!",
		topSectionHeaderPrimary: "La siguiente generación",
		topSectionHeaderSecondary: "eventos en línea",
		carouselPresentationText: "¡Mira!",
		carouselPresentationTextAccent: "NUEVO evento",
		videoPresentationTextPrimary: "Events10X - eventos virtuales...a tus",
		videoPresentationTextAccent: "participantes les va a encantar",
		videoPresentationTextSecondary: "Aquí te decimos por qué",
		testimonialsTextPrimary: "Eventos en línea desde 2015",
		testimonialsTextAccent: "se trata de la gente",
		testimonialsTextSecondary: "…",
		cardsTextPrimary: "EVENTS10X incluye",
		cardsTextAccent: "el WOW",
		cardsTextSecondary: "para que…",
		contactTextPrimary: "¿Estás interesado en organizar un evento?",
		contactTextAccent: "Establece contacto",
		contactTextSecondary: "",
		contactInputName: "Nombre",
		contactInputEmail: "Correo electrónico",
		contactInputPhone: "Teléfono móvil",
		contactInputMessage: "Mensaje",
		contactButton: "ENVIAR",
		carouselPresentationTextAccentTextFirst: "NUEVO",
		videoPresentationTextPrimaryTextSecond: "eventos en línea que tu",
		cardsTextPrimaryTextSecond: "trae",
	},
	loginPlatform: {
		title: "INICIAR SESIÓN",
		description:
			"Después de iniciar sesión, serás redireccionado al último evento en el que te hayas registrado. Puedes fácilmente navegar entre tus eventos usando el menú MIS EVENTOS",
		descriptionTextFirst: "Después de iniciar sesión, serás dirigido al último",
		descriptionTextSecond:
			"al que te has registrado. Puedes cambiar fácilmente entre ellos",
		descriptionTextThird: "Usando la opción de MIS EVENTOS en el menú.",
	},
	skipped: {
		startVideoChat:
			"He preparado una videollamada. Por favor, haz clic aquí para unirte.",
	},
	hubDropdownTitle: "MENÚ DEL HUB",
	marketplace: {
		title: {
			default: "Marketplace",
			uppercased: "MARKETPLACE",
		},
		label: "PRODUCTOS",
		addNew: "AGREGAR NUEVO",
		addNewProduct: "Agregar producto nuevo",
		editProduct: "Editar producto",
		noProducts: "No hay productos todavía",
		publish: "PUBLICAR",
		productTitle: "Título del producto",
		productDescription: "Descripción del producto",
		productLink: "Enlace al producto",
		productImage: "IMAGEN DEL PRODUCTO",
		uploadHint:
			"Carga una imagen para pre-visualizar el producto. La resolución sugerida es de 600x600 px",
		descriptionHint: "La descripción se limita a 300 símbolos",
		uploadImage: "CARGA UNA IMAGEN PARA PRE-VISUALIZAR EL PRODUCTO",
		uploaded: "Carga terminada",
	},
	personalAgenda: {
		agenda: "AGENDA",
		myProgram: "MI PROGRAMA",
		myMeetings: "MIS REUNIONES",
		addNewTask: {
			default: "Agregar nueva tarea",
			uppercased: "AGREGAR NUEVA TAREA",
		},
		noProgram: "No existen actividades en el programa por el momento",
		noMeetings: "No hay reuniones disponibles por el momento",
		title: "Título",
		startDate: "Fecha de inicio",
		starts: "Comienza",
		ends: "Termina",
		create: "CREAR",
		schedule: "AGENDAR",
		personalAgenda: "AGENDA PERSONAL",
		timetableMenu: "MENU DE LA AGENDA",
		gmt: "GMT",
	},
	program: {
		toAgenda: "A LA AGENDA",
		attending: "CONFIRMA",
		inPerson: "En persona",
		online: "En línea",
		searchWrapperTitle: "BUSCAR EN TODAS LAS SESIONES",
		searchWrapperTitlePast: "BUSCAR EN TODAS LAS SESIONES PASADAS",
		searchWrapperTitleUpcoming: "BUSCAR EN TODAS LAS SESIONES PRÓXIMAS",
		searchWrapperTitleInterval: "BUSCAR EN SESIONES",
	},
	businessCard: {
		businessCard: {
			default: "Tarjeta de presentación",
			uppercased: "TARJETA DE PRESENTACIÓN",
		},
		editText: "EDITAR TEXTO",
		colors: "COLORES",
		branding: "LOGOTIPO",
		cardPreview: "VISTA PREVIA DE LA TARJETA",
		onceSaved:
			"Una vez que la hayas guardado, tu Tarjeta de Presentación quedará almacenada en tu perfil de Events10X. Con ella podrás expandir tu red de negocios. Se eliminará si eliminas la cuenta.",
		required: "requerido",
		designation: "Cargo",
		Mobile: "Teléfono móvil",
		Phone: "Teléfono",
		Website: "Sitio web",
		companyName: "Nombre de la empresa",
		honoric: "Agrega tu prefijo preferido",
		fillUpData: "LLENAR LOS DATOS CON LA INFORMACIÓN DE MI CUENTA",
		primaryColor: "COLOR PRIMARIO",
		primaryColorDescription: "Usa los colores de tu logotipo aquí",
		backgroundColor: "COLOR DE FONDO",
		backgroundColorDescription:
			"Este es el color adicional. Si no eliges ninguno, el sistema escogerá blanco o negro",
		backgroundTextColor: "COLOR DEL TEXTO Y EL FONDO",
		backgroundTextColorDescription:
			"En este color aparecerán los teléfonos y el sitio web. Da click en Vista Previa para asegurarte que el contraste de colores es correcto.",
		companyLogo: "LOGOTIPO",
		uploadMessage:
			"Se sugiere una imagen de 320*200 px en formato png (fondo transparente)",
		uploadImage: "CARGAR IMAGEN",
		request: "SOLICITAR",
		open: "ABRIR",
		pending: "PENDIENTE",
	},
	businessWallet: {
		short: "TARJETERO",
		full: "TARJETERO DE NEGOCIOS",
		myContacts: "MIS CONTACTOS",
		requests: "SOLICITUDES",
		pendings: "PENDIENTES",
		searchByName: "Buscar por nombre",
		chat: "Chat",
		openCard: "ABRIR TARJETA",
		empty: "No hay nadie aquí aún",
		cancelRequest: "CANCELAR SOLICITUD",
		approve: "APROBAR",
		reject: "RECHAZAR",
		myNotes: "MIS NOTAS",
		addNewNote: "Agregar nueva nota",
		delete: "BORRAR",
		saveThoughts: "Este espacio es para tus notas",
	},
	videoConferences: {
		message:
			"He preparado una videollamada. Por favor, haz clic aquí para unirte.",
		clickToStart: "Da click aquí para comenzar una videollamada",
		fullScreen: "PANTALLA COMPLETA",
		minimizeScreen: "MINIMIZAR PANTALLA",
	},
	sessions: {
		sessionList: "LISTA DE SESIONES",
		createNewSession: {
			uppercased: "CREAR NUEVA SESIÓN",
			default: "Crear nueva sesión",
		},
		sessionName: {
			uppercased: "NOMBRE DE LA SESIÓN",
			default: "Nombre de la sesión",
		},
		sessionLink: "ENLACE DE LA SESIÓN",
		sessionType: "TIPO DE SESIÓN",
		manage: "ADMINISTRAR",
		edit: "EDITAR",
		delete: "BORRAR",
		live: {
			uppercased: "EN VIVO",
			lowercased: "en vivo",
			default: "En vivo",
		},
		recorded: {
			uppercased: "GRABADO",
			lowercased: "grabado",
			default: "Grabado",
		},
		editSession: "Editar sesión",
		uploadVideo: "CARGAR VIDEO",
		create: "CREAR",
		discard: "DESECHAR",
		speakers: "PONENTES",
		moderators: "MODERADORES",
		speakerName: "NOMBRE DEL PONENTE",
		moderatorName: "NOMBRE DEL MODERADOR",
		kick: "SACAR",
		screenSharing: "COMPARTIR PANTALLA",
		audio: "AUDIO",
		video: "VIDEO",
		startStream: "COMENZAR TRANSMISIÓN",
		stopStream: "DETENER TRANSMISIÓN",
		startRecording: "COMENZAR GRABACIÓN",
		endRecording: "DETENER GRABACIÓN",
		sessionChat: "CHAT DE LA SESIÓN",
		typeMessageHere: "Escribe el mensaje aquí",
		sessionLinks: "ENLACE A LA SESIÓN",
		speakerUrl: "URL del Ponente",
		moderatorUrl: "URL del Moderador",
		copyUrl: "copiar URL",
		organizer: "ORGANIZADOR",
		moderator: "MODERADOR",
		speaker: "PONENTES",
		joinAsSpeaker: "Unirme como ponente",
		invitedAsSpeaker:
			"Has sido invitado como ponente a esta sesión. Ingresa tu nombre (será visible para el resto de los participantes)",
		yourName: "Tu nombre",
		enter: "ENVIAR",
		waitForApproval: "Por favor espera la aprobación del organizador",
		deny: "RECHAZAR",
		admit: "ADMITIR",
		joinAsModeraotr: "Unirme como moderador",
		invitedAsModerator:
			"Has sido invitado como moderador a esta sesión. Ingresa tu nombre (será visible para el resto de los participantes)",
		declined: "Lo sentimos, tu registro ha sido denegado",
	},
	breadcrumbsNavigation: {
		mainLobby: "Lobby Principal",
		village: "HUB",
	},
	village: {
		clickToOpen: "Click para abrir",
	},
	gdpr: {},
};

export default es;
