import {isMobile} from 'react-device-detect';
import {connect} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';
import NotFound from '../../Pages/NotFound';
import Spinner from '../../SmallLayoutComponents/FullPageSpinner';
import {getReactAppAifgLink} from '../../Utils/get-react-app-aifg-link';
import EventPrivateComponent from './EventPrivateComponent';
const ExhibitorRoute = ({component: Component, loggedIn, user, loadingUser, ...rest}) => (
    <Route
        {...rest}
        render={(props) => {
            const eventSlug = props.match.params.eventSlug;

            if (!loggedIn && loadingUser) {
                return <Spinner />;
            } else if (loggedIn && user) {
                const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === eventSlug);
                const userIsExhibitor = userRolesInEvent && userRolesInEvent.roles.includes('exhibitor');
                if (userIsExhibitor) {
                    if (isMobile) {
                        return (
                            <Redirect
                                to={{
                                    pathname: `/event/${eventSlug}/lobby`,
                                }}
                            />
                        );
                    } else {
                        return (
                            <EventPrivateComponent {...props}>
                                <Component {...props} />
                            </EventPrivateComponent>
                        );
                    }
                } else {
                    return <NotFound />;
                }
            } else if (!loggedIn && !loadingUser) {
                return (
                    <Route
                        path="/"
                        component={() => {
                            window.location.href = getReactAppAifgLink();
                            return null;
                        }}
                    />
                );
            } else {
                return <NotFound />;
            }
        }}
    />
);

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        user: state.user.data,
        loadingUser: state.user.loading,
    };
};

export default connect(mapStateToProps)(ExhibitorRoute);
