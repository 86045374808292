import React from 'react';
import * as actions from '../../store/actions';
import {connect} from 'react-redux';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as NoResultsExibitors} from '../../Images/svg/no_search_results.svg';
import {ReactComponent as NoExibitors} from '../../Images/svg/no_exhibitors.svg';
import {ReactComponent as ExhibitorNoPhoto} from '../../Images/svg/showfloor_icon_rooms.svg';
import {ReactComponent as Checked} from '../../Images/svg/checked.svg';
import {ReactComponent as ArrowRightGrey} from '../../Images/svg/arrow_right.svg';
import {preventDefaultDrag} from '../../Utils/utils';

class ExhibitorsListMobile extends React.Component {
    state = {
        searchString: '',
        openSearchContainer: false,
        filteredExhibitors: [],
    };

    componentDidMount() {
        // initialize our filtered exhibitors with all the exhibitors in the current showfloor
        const {exhibitors} = this.props;
        this.setState({
            filteredExhibitors: exhibitors,
        });
    }

    componentDidUpdate(prevProps) {
        // whenever a user changes the showfloor, we need to reinitialize the exhibitors
        if (prevProps.loadingExhibitors === true && this.props.loadingExhibitors === false) {
            this.handleFilterExhibitors();
        }
    }

    handleFilterExhibitors = () => {
        const {exhibitors} = this.props;
        const {searchString} = this.state;
        const searchStringLowerCase = searchString.toLowerCase();
        const filteredExhibitors = exhibitors.filter((exhibitor) => {
            let isInSearch = exhibitor.company?.toLowerCase().includes(searchStringLowerCase);
            if (exhibitor.type === 'scholar') {
                isInSearch = exhibitor.booth.studyTitle?.toLowerCase().includes(searchStringLowerCase);
            }
            return isInSearch;
        });
        this.setState({
            filteredExhibitors: filteredExhibitors,
        });
    };

    handleUpdateSearchString = (e) => {
        const searchString = e.target.value;
        this.setState(
            {
                searchString: searchString,
            },
            this.handleFilterExhibitors
        );
    };

    handleEmptyStringSearch = () => {
        this.setState({
            searchString: '',
        });
    };

    setActiveExhibitorId = (exhibitorId) => (e) => {
        const {handleCloseExhibitorsOverlay} = this.props;
        this.props.onSetActiveExhibitorId(exhibitorId);
        handleCloseExhibitorsOverlay();
    };

    handleSetActiveShowfloor = (showfloorId, isRestricted) => (e) => {
        if (isRestricted) {
            return;
        }
        this.props.onSetActiveShowfloorId(showfloorId);
    };

    handleSetActiveExhibitor = (exhibitorId) => (e) => {
        const {closeSearchContainer, handleCloseExhibitorsOverlay} = this.props;
        this.props.onSetActiveExhibitorId(exhibitorId);
        handleCloseExhibitorsOverlay();
        closeSearchContainer();
    };

    handleInputFocus = () => {
        const eventPage = document.getElementsByClassName('event-page');
        if (eventPage) {
            eventPage[0].classList.add('hide');
        }
    };

    handleInputBlur = () => {
        const eventPage = document.getElementsByClassName('event-page hide');
        if (eventPage.length !== 0) {
            eventPage[0].classList.remove('hide');
        }
    };

    handleKeyDown = (e) => {
        const {search} = this.state;
        if (e.key === 'Enter' && search.trim()) {
            e.target.blur();
        }
    };

    render() {
        const {searchString, filteredExhibitors} = this.state;
        const {
            event,
            eventId,
            activeShowfloorId,
            resourcesAccess,
            exhibitor,
            exhibitors,
            activeExhibitorId,
            open,
            hide,
            openSearchContainer,
            defaultTranslation,
            translation,
        } = this.props;

        return (
            <div
                className={`${open ? 'opened' : ''} exhibitors-list-dialog booth-dialog multiple-showfloors ${
                    hide ? 'hide' : ''
                }`}
            >
                <div
                    onDragStart={preventDefaultDrag}
                    className={`booth-dialog-content ${openSearchContainer ? 'search-opened' : ''}`}
                >
                    <div onDragStart={preventDefaultDrag} className="search-exhibitor-container mobile">
                        <input
                            id="standard-search"
                            placeholder={
                                exhibitor?.type === 'scholar'
                                    ? translation?.showfloor.searchPosters
                                    : translation?.showfloor.searchExhibitors
                            }
                            type="search"
                            value={searchString}
                            onChange={this.handleUpdateSearchString}
                            onFocus={this.handleInputFocus}
                            onBlur={this.handleInputBlur}
                            onKeyDown={this.handleKeyDown}
                        />
                        {searchString && (
                            <CloseRoundedIcon className="delete-text" onClick={this.handleEmptyStringSearch} />
                        )}
                    </div>
                    <div
                        onDragStart={preventDefaultDrag}
                        className="showfloors-container"
                        data-content={event.showfloors.length}
                    >
                        <div onDragStart={preventDefaultDrag} className="showfloors-list">
                            {event.showfloors.map((showfloor) => {
                                const isActive = activeShowfloorId === showfloor._id;
                                let isRestricted = false;
                                if (resourcesAccess) {
                                    isRestricted = !resourcesAccess?.showfloors.includes(showfloor._id);
                                }

                                return (
                                    <div
                                        className={`single-showfloor ${isActive ? 'active' : 'inactive'} ${
                                            isRestricted ? 'restricted' : ''
                                        } ${showfloor.image ? 'with-image' : ''}`}
                                        key={showfloor._id}
                                    >
                                        <div
                                            className="showfloor-container"
                                            onClick={this.handleSetActiveShowfloor(showfloor._id, isRestricted)}
                                        >
                                            {isRestricted && (
                                                <LockOutlinedIcon color={'inherit'} className={'restricted-icon'} />
                                            )}
                                            {showfloor.image && (
                                                <div onDragStart={preventDefaultDrag} className="image-container">
                                                    <img
                                                        draggable="false"
                                                        onDragStart={preventDefaultDrag}
                                                        src={`${process.env.REACT_APP_EVENT_FOLDER}${eventId}/${showfloor.image}`}
                                                        alt="logo"
                                                    />
                                                </div>
                                            )}
                                            <div onDragStart={preventDefaultDrag} className="text-container">
                                                <p>{showfloor.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div onDragStart={preventDefaultDrag} className="exhibitors-list-mobile">
                        <div onDragStart={preventDefaultDrag} data-empty={filteredExhibitors.length}>
                            {exhibitors.length > 0 ? (
                                <>
                                    {filteredExhibitors.map((exhibitor) => {
                                        const isActive = activeExhibitorId === exhibitor._id;
                                        return (
                                            <div
                                                className={`single-exhibitor ${
                                                    exhibitor.type === 'scholar' ? 'scholar' : ''
                                                }${isActive ? ' active' : ''}`}
                                                key={exhibitor._id}
                                                onClick={this.handleSetActiveExhibitor(exhibitor._id)}
                                            >
                                                {exhibitor.type === 'scholar' ? (
                                                    <div
                                                        onDragStart={preventDefaultDrag}
                                                        className="exhibitor-container scholar"
                                                    >
                                                        <div
                                                            onDragStart={preventDefaultDrag}
                                                            className="poster-title-wrapper"
                                                        >
                                                            <p>
                                                                {exhibitor?.booth.studyTitle
                                                                    ? exhibitor?.booth.studyTitle
                                                                    : translation?.showfloor.titleNotAvailable ||
                                                                      defaultTranslation?.showfloor.titleNotAvailable}
                                                            </p>
                                                            {isActive ? (
                                                                <Checked fill={colors.primary} />
                                                            ) : (
                                                                <ArrowRightGrey />
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        onDragStart={preventDefaultDrag}
                                                        className="exhibitor-container"
                                                    >
                                                        <div
                                                            onDragStart={preventDefaultDrag}
                                                            className="logo-container"
                                                        >
                                                            {exhibitor.booth.logo ? (
                                                                <img
                                                                    draggable="false"
                                                                    onDragStart={preventDefaultDrag}
                                                                    src={exhibitor.filesUrl + exhibitor.booth.logo}
                                                                    alt={exhibitor.company}
                                                                />
                                                            ) : (
                                                                <ExhibitorNoPhoto fill={colors.grey} />
                                                            )}
                                                        </div>
                                                        <p>{exhibitor.company}</p>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                    {filteredExhibitors.length === 0 ? (
                                        <div onDragStart={preventDefaultDrag} className="empty-exhibitors-list">
                                            <NoResultsExibitors fill={colors.primary} />
                                            <p>
                                                {translation?.showfloor.emptyPostersResults ||
                                                    defaultTranslation?.showfloor.emptyPostersResults}
                                            </p>
                                        </div>
                                    ) : null}
                                </>
                            ) : (
                                <div onDragStart={preventDefaultDrag} className="empty-exhibitors-list">
                                    <NoExibitors fill={colors.primary} />
                                    <p>
                                        <span>
                                            {(
                                                translation?.showfloor.noPresentersTextFirst ||
                                                defaultTranslation?.showfloor.noPresentersTextFirst
                                            ).toLowerCase()}
                                        </span>
                                        <span>
                                            {(
                                                translation?.showfloor.noExhibitorsTextSecond ||
                                                defaultTranslation?.showfloor.noExhibitorsTextSecond
                                            ).toLowerCase()}
                                        </span>
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        event: state.event.data,
        eventId: state.event.eventId,
        exhibitor: state.exhibitor.data,
        exhibitors: state.exhibitors.approvedExhibitors,
        loadingExhibitors: state.exhibitors.loading,
        activeExhibitorId: state.exhibitors.activeExhibitorId,
        activeShowfloorId: state.showfloors.activeShowfloorId,
        resourcesAccess: state.user.resourcesAccess,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetActiveExhibitorId: (exhibitorId) => dispatch(actions.setActiveExhibitorId(exhibitorId)),
        onSetActiveShowfloorId: (showfloorId) => dispatch(actions.setActiveShowfloorId(showfloorId)),
        onGetExhibitor: (exhibitorId) => dispatch(actions.getExhibitor(exhibitorId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitorsListMobile);
