import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import axios from '../../../../store/axios-instance';
import * as actions from '../../../../store/actions/index';
import moment from 'moment';
import variables from '../../../../CSS/_variables.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CheckIcon from '@material-ui/icons/Check';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Confirm from '../../../../Dialogs/Confirm';
import {preventDefaultDrag, getDayLabelString, formatDecimalHoursToHoursAndMinutes} from '../../../../Utils/utils';
import TimeIcon from '../../../../Images/svg/TimeIcon';
import {ReactComponent as ConfirmedIcon} from '../../../../Images/svg/confirmed-status.svg';
import {ReactComponent as FinishedIcon} from '../../../../Images/svg/finished-status.svg';
import {ReactComponent as PendingIcon} from '../../../../Images/svg/pending-status.svg';
import {ReactComponent as RequestedIcon} from '../../../../Images/svg/requested-status.svg';
import {ReactComponent as CanceledIcon} from '../../../../Images/svg/canceled-status.svg';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';

class SingleMeetingItem extends PureComponent {
    state = {
        partnerName: '',
        meeting: '',
        meetingStatus: '',
        bannerText: '',
        isOwner: false,
        dates: {
            day: '',
            start: '',
            end: '',
        },
        openConfirmCancelMeeting: false,
    };

    componentDidMount() {
        this.setMeetingData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.agendaSessions !== this.props.agendaSessions) {
            this.setMeetingData();
        }
    }

    setMeetingData = () => {
        const {user, meetingId, agendaSessions} = this.props;
        const meetingData = agendaSessions.find((el) => el === meetingId);
        let name = '';
        let isOwner = false;
        if (meetingData) {
            this.getMeetingFormattedDate(meetingData.startTimestamp, meetingData.endTimestamp);
            this.getMeetingStatus(meetingData);

            if (user._id === meetingData.owner.user._id) {
                name = meetingData.partner.user.first + ' ' + meetingData.partner.user.last;
                isOwner = true;
            } else {
                name = meetingData.owner.user.first + ' ' + meetingData.owner.user.last;
            }
        }
        this.setState({partnerName: name, meeting: meetingData, isOwner: isOwner});
    };

    getMeetingStatus = (meetingData) => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        const defaultTranslation = this.props.languages.translations['en'];

        const now = new Date().getTime();
        const start = new Date(meetingData.startTimestamp).getTime();
        const end = new Date(meetingData.endTimestamp).getTime();

        let status = '';
        let bannerText = '';

        // TODO: switch
        if (meetingData.status === 'canceled') {
            status = 'canceled';
            bannerText = translation?.meetingsDropdown.canceled || defaultTranslation?.meetingsDropdown.canceled;
        } else if (now > end && meetingData.status === 'accepted') {
            status = 'finished';
            bannerText = translation?.meetingsDropdown.finished || defaultTranslation?.meetingsDropdown.finished;
        } else if (now < end && now > start && meetingData.status === 'accepted') {
            status = 'progress';
            bannerText = translation?.meetingsDropdown.progress || defaultTranslation?.meetingsDropdown.progress;
        } else if (meetingData.status === 'accepted') {
            status = 'accepted';
            bannerText = translation?.meetingsDropdown?.confirmed || defaultTranslation?.meetingsDropdown?.confirmed;
        } else {
            status = 'pending';
            bannerText = translation?.meetingsDropdown.pending || defaultTranslation?.meetingsDropdown.pending;
        }

        this.setState({meetingStatus: status, bannerText: bannerText});
    };

    getMeetingFormattedDate = (dateToFormatStart, dateToFormatEnd) => {
        let months = [
            this.props.languages.translations[this.props.languages.platformLanguage]?.time.january,
            this.props.languages.translations[this.props.languages.platformLanguage]?.time.february,
            this.props.languages.translations[this.props.languages.platformLanguage]?.time.march,
            this.props.languages.translations[this.props.languages.platformLanguage]?.time.april,
            this.props.languages.translations[this.props.languages.platformLanguage]?.time.may,
            this.props.languages.translations[this.props.languages.platformLanguage]?.time.june,
            this.props.languages.translations[this.props.languages.platformLanguage]?.time.july,
            this.props.languages.translations[this.props.languages.platformLanguage]?.time.august,
            this.props.languages.translations[this.props.languages.platformLanguage]?.time.september,
            this.props.languages.translations[this.props.languages.platformLanguage]?.time.october,
            this.props.languages.translations[this.props.languages.platformLanguage]?.time.november,
            this.props.languages.translations[this.props.languages.platformLanguage]?.time.december,
        ];

        const momentDate = moment(dateToFormatStart);
        const momentDateEnd = moment(dateToFormatEnd);

        const momentDay = momentDate.format('DD');
        const momentMonth = months[momentDate.month()];
        const momentYear = momentDate.year();

        const start = momentDate.hours() + ':' + momentDate.format('mm');
        const end = momentDateEnd.hours() + ':' + momentDateEnd.format('mm');

        this.setState({
            dates: {
                ...this.state.dates,
                day: `${momentDay} ${momentMonth} ${momentYear}`,
                start: start,
                end: end,
            },
        });
    };

    handleAcceptMeeting = () => {
        const {eventId} = this.props;
        let data = {
            meetingId: this.state.meeting._id,
        };

        axios({method: 'post', url: `/users/accept-meeting-request`, data: data})
            .then(() => {
                this.props.onRefreshUserData()
                this.props.getMyAgenda(eventId).then(() => {
                    this.props.handleFilterMyAgenda();
                    this.setMeetingData();
                });
            })
            .catch((error) => {});
    };

    handleCancelMeeting = () => {
        this.setState({
            openConfirmCancelMeeting: true,
        });
    };

    handleClose = () => {
        this.setState({
            openConfirmCancelMeeting: false,
        });
    };

    cancelMeeting = () => {
        const {eventId} = this.props;
        let data = {
            meetingId: this.state.meeting._id,
        };

        axios({method: 'post', url: `/users/cancel-meeting-request`, data: data})
            .then(() => {
                this.props.getMyAgenda(eventId).then(() => {
                    this.props.handleFilterMyAgenda();
                    this.setMeetingData();
                    this.handleClose();
                });
            })
            .catch((error) => {});
    };

    handleOpenMeeting = () => {
        this.props.onOpenPrivateChat(this.state.meeting.privateChat);
    };

    handleMeetingStatus = () => {
        const {meetingStatus, isOwner} = this.state;
        if (meetingStatus === 'accepted') {
            return <ConfirmedIcon/>;
        } else if (meetingStatus === 'pending') {
            if (isOwner) {
                return <PendingIcon/>;
            } else {
                return <RequestedIcon/>;
            }
        } else if (meetingStatus === 'finished') {
            return <FinishedIcon/>;
        } else if (meetingStatus === 'canceled') {
            return <CanceledIcon/>;
        } else {
            return null;
        }
    };

    addMeetingToCalendar = (e) => {
        e.preventDefault()
        const {meeting, partnerName} = this.state;
        const eventCalendarData = {
            title: `Meeting with ${partnerName} on Neural Network`,
            location: window.location.origin,
            start: meeting.startTimestamp,
            end: meeting.endTimestamp
        };
        const calendarEventType = 'meeting';
        this.props.showAddToExternalCalendarDialog(eventCalendarData, calendarEventType)
    }

    render() {
        const {
            partnerName,
            isOwner,
            openConfirmCancelMeeting,
            meetingStatus,
            meeting,
            bannerText,
            dates
        } = this.state;
        const {
            event,
            isLargeScreen,
            languages,
            translation,
            defaultTranslation
        } = this.props;

        return (
            <>
                <div
                    onDragStart={preventDefaultDrag}
                    className={`single-session-item meeting`}
                >
                    <div>
                        <div>
                            <div className="program-time-container">
                                <TimeIcon primary={variables.primary}/>
                                <span>{dates.day + ' - ' + getDayLabelString(meeting.startTimestamp, languages)}</span>
                            </div>
                            {isLargeScreen && (
                                <div className="status-wrapper">
                                    <div className="status-button meeting-status-button">
                                        {this.handleMeetingStatus()}
                                        <span>{bannerText}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
                            {meetingStatus === 'accepted' && meetingStatus !== 'finished' &&
                                <div className='add-to-external-calendar-button' onClick={this.addMeetingToCalendar}>
                                    <InsertInvitationIcon />
                                </div>
                            }
                            {meetingStatus === 'canceled' || meetingStatus === 'finished' ? (
                                <div onDragStart={preventDefaultDrag} className="session-item-actions-container"/>
                            ) : (
                                <div onDragStart={preventDefaultDrag} className="session-item-actions-container">
                                    {meetingStatus !== 'pending' && (
                                        <div onDragStart={preventDefaultDrag} className="button" onClick={this.handleCancelMeeting}>
                                            <CloseIcon fontSize={'inherit'} />
                                        </div>
                                    )}
                                    {meetingStatus === 'pending' && !isOwner && (
                                        <>
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="button"
                                                onClick={this.handleCancelMeeting}
                                            >
                                                <RemoveCircleOutlineIcon fontSize={'inherit'} />
                                            </div>
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="button accept-icon"
                                                onClick={this.handleAcceptMeeting}
                                            >
                                                <CheckIcon fontSize={'inherit'} className="primary-color" />
                                            </div>
                                        </>
                                    )}
                                    {meetingStatus === 'pending' && isOwner && (
                                        <div onDragStart={preventDefaultDrag} className="button" onClick={this.handleCancelMeeting}>
                                            <CloseIcon fontSize={'inherit'} />
                                        </div>
                                    )}

                                    {meetingStatus === 'accepted' && (
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className="button forward-icon"
                                            onClick={this.handleOpenMeeting}
                                        >
                                            <ArrowForwardIosIcon fontSize={'inherit'} style={{color: variables.secondary}} />
                                        </div>
                                    )}
                                    {meetingStatus === 'progress' && (
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className="button forward-icon"
                                            onClick={this.handleOpenMeeting}
                                        >
                                            <ArrowForwardIosIcon fontSize={'inherit'} style={{color: variables.secondary}} />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    {!isLargeScreen && (
                        <div className="status-wrapper mobile">
                            <div className="status-button meeting-status-button">
                                {this.handleMeetingStatus()}
                                <span>{bannerText}</span>
                            </div>
                        </div>
                    )}
                    <p className="time-timeslot">
                        {dates.start + ' - ' + dates.end + ' GMT '}
                        {event.timezoneValue >= 0 ? '+' : ''}
                        {formatDecimalHoursToHoursAndMinutes(event.timezoneValue)}
                    </p>
                    <p className="title">
                        {`${
                            translation?.meetingsDropdown.meetingWith || defaultTranslation?.meetingsDropdown.meetingWith
                        } ${partnerName}`}
                    </p>
                </div>
                {openConfirmCancelMeeting && (
                    <Confirm
                        open={openConfirmCancelMeeting}
                        closeConfirm={this.handleClose}
                        dialogTitle={
                            translation?.meetings.banner.cancelTitle || defaultTranslation?.meetings.banner.cancelTitle
                        }
                        dialogDescription={
                            translation?.meetings.banner.cancelDescription ||
                            defaultTranslation?.meetings.banner.cancelDescription
                        }
                        handleConfirm={this.cancelMeeting}
                        dialogCancelButtonLabel={
                            translation?.generalText.close || defaultTranslation?.generalText.close
                        }
                        dialogConfirmButtonLabel={
                            translation?.generalText.confirm || defaultTranslation?.generalText.confirm
                        }
                        classList={['preserveHeaderDropdown']}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        eventId: state.event.eventId,
        isLargeScreen: state.layout.isLargeScreen,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onOpenPrivateChat: (privateChatId) => dispatch(actions.openPrivateChat(privateChatId)),
        getMyAgenda: (eventId) => dispatch(actions.getMyAgenda(eventId)),
        onRefreshUserData: () => dispatch(actions.refreshUserData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleMeetingItem);
