import React, {useState, useMemo} from 'react';
import {useSelector} from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import classNames from 'classnames';
import PermissionUser from './components/PermissionUser';

import {PARTICIPANT_TYPES} from '../../../../Utils/constants/organizer/sessions';
import {getUsers} from '../../selectors';

const PermissionsModule = ({currentUser, routeParams}) => {
    const users = useSelector((state) => getUsers(state));

    const [activeTab, setActiveTab] = useState('speakers');

    const {sessionId} = routeParams;

    const renderThumb = ({style, ...props}) => {
        const thumbStyle = {
            backgroundColor: `#777081`,
            left: 0,
            border: `1px solid #FFFFFF`,
            boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.25)`,
            borderRadius: `8px`
        };
        return <div className="grey-scroll" style={{...style, ...thumbStyle}} {...props} />;
    };

    const usersByRole = useMemo(() => {
        const speakerList = [];
        const moderatorsList = [];

        users.forEach((user) => {
            if (user.kicked) return;
            if (user.role === PARTICIPANT_TYPES.speaker) {
                speakerList.push(user);
            } else moderatorsList.push(user);
        });

        return {
            speakerList,
            moderatorsList,
        };
    }, [users]);

    return (
        <div
            className={classNames('module -permissions', {
                withTopMargin: currentUser?.role !== PARTICIPANT_TYPES.organizer,
            })}
        >
            <div className="module-container">
                <div className="module-header">
                    <p
                        className={`tab ${activeTab === 'speakers' ? '-active' : ''}`}
                        onClick={() => {
                            setActiveTab('speakers');
                        }}
                    >
                        SPEAKERS
                    </p>
                    <p
                        className={`tab ${activeTab === 'moderators' ? '-active' : ''}`}
                        onClick={() => {
                            setActiveTab('moderators');
                        }}
                    >
                        MODERATORS
                    </p>
                </div>
                <div className="module-body">
                    <div className="module-row -head">
                        <div>{activeTab === 'speakers' ? 'Speaker' : 'Moderator'} NAME</div>
                        <div className="d-flex">
                            <div className="module-action_column">kick</div>
                            <div className="module-action_column">screen sharing</div>
                            <div className="module-action_column">audio</div>
                            <div className="module-action_column">video</div>
                        </div>
                    </div>

                    <Scrollbars className="scrollbar" renderThumbVertical={renderThumb} width="100%" height="100%">
                        {activeTab === 'speakers' &&
                            usersByRole.speakerList.map((user) => {
                                return (
                                    <PermissionUser
                                        key={user._id}
                                        currentUser={currentUser}
                                        user={user}
                                        sessionId={sessionId}
                                    />
                                );
                            })}
                        {activeTab === 'moderators' &&
                            usersByRole.moderatorsList.map((user) => {
                                return (
                                    <PermissionUser
                                        key={user._id}
                                        currentUser={currentUser}
                                        user={user}
                                        sessionId={sessionId}
                                    />
                                );
                            })}
                    </Scrollbars>
                </div>
            </div>
        </div>
    );
};

export default PermissionsModule;
