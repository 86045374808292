import React from 'react';
import '../CSS/otherPages.scss';
import HomepageBackground from '../Images/background.jpg';
import {connect} from 'react-redux';
import {preventDefaultDrag} from '../Utils/utils';

class NotFound extends React.Component {
    state = {
        translation: null,
    };

    componentDidMount() {
        this.setLanguagesData();
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loggedIn !== this.props.loggedIn) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        let userPreferredLanguage = 'en';
        if (this.props?.loggedIn) {
            userPreferredLanguage = this.props?.user.language;
        }
        const translation = this.props.languages.translations[userPreferredLanguage];
        this.setState({
            translation: translation,
        });
    };

    render() {
        const {translation} = this.state;
        return (
            <div>
                <div onDragStart={preventDefaultDrag} className="homepage login-page not-found-page">
                    <div
                        onDragStart={preventDefaultDrag}
                        className="event-container"
                        style={{backgroundImage: `url('${HomepageBackground}')`}}
                    >
                        <div onDragStart={preventDefaultDrag} className="homepage-overlay">
                            <div onDragStart={preventDefaultDrag} className="smallContainer">
                                <div onDragStart={preventDefaultDrag} className="not-found-page-info-screen">
                                    <h1 className="page-title">404</h1>
                                    <p>{translation?.notFound.notFoundDescription}</p>
                                    <a href="/">
                                        <span>{translation?.notFound.notFoundHomepage}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
        loggedIn: state.user.loggedIn,
        user: state.user.data,
    };
};

export default connect(mapStateToProps)(NotFound);
