import React from "react";
import Wall from "../../HOC/Wall/Wall";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import AuditoriumBackgroundImage from "../../SmallLayoutComponents/AuditoriumBackgroundImage";
import Resources from "../../HOC/Wall/Resources";
import AuditoriumSideButtons from "../Auditorium/AuditoriumSideButtons/AuditoriumSideButtons";
import AuditoriumVideoPlayer from "../Auditorium/AuditoriumVideoPlayer";
import AuditoriumTabs from "../Auditorium/AuditoriumTabs";
import Networking from "../../HOC/Networking";
import "../../CSS/auditorium.scss";
import colors from "../../CSS/_variables.module.scss";
import AuditoriumBanners from "../Auditorium/AuditoriumBanners";
import { ReactComponent as GreaterArrow } from "../../Images/svg/greater-arrow.svg";
import { ReactComponent as BackIcon } from "../../Images/svg/back.svg";
import { preventDefaultDrag } from "../../Utils/utils";
import MatchingDialog from "../../Components/EventManagement/Matching/MatchingDialog";
import MatchingDialogConfirm from "../../Components/EventManagement/Matching/MatchingDialogConfirm";
import LeftSideMenu from "../../Components/LeftSideMenu";

class Auditorium extends React.Component {
	state = {
		archivedEventId: this.props.match.params.archivedEventId,
		videoId: this.props.match.params.videoId,
		archivedEventData: null,
		timeSlot: null,
		currentTab: "wall",
		removeHoverEffect: false,
		openMatchingCloseDialog: false,
	};

	componentDidMount() {
		this.props.onSetEventProtectedMenu();

		const { eventId } = this.props;
		if (!this.props.eventArchive) {
			this.props.onGetEventArchive(eventId);
		}

		// only on component mount and only on mobile devices set the active tab to the video
		const { isLargeScreen } = this.props;
		if (!isLargeScreen) {
			this.setState({ currentTab: "video" });
		}

		this.setVideoData();

		if (this._removeHoverEffects()) {
			this.setState({
				removeHoverEffect: true,
			});
		}
	}

	componentDidUpdate(prevProps) {
		const { videoId } = this.props.match.params;
		if (
			this.state.videoId !== videoId ||
			prevProps.event !== this.props.event ||
			prevProps.eventArchive !== this.props.eventArchive
		) {
			this.setVideoData();
		}
		if (
			prevProps.event.hideAuditoriumNetworking !==
			this.props.event.hideAuditoriumNetworking
		) {
			if (
				this.props.event.hideAuditoriumNetworking &&
				this.state.currentTab === "networking"
			) {
				// if is on tablet/mobile, we handle the automatic tabChange from the AuditoriumTabs component
				if (this.props.isLargeScreen) {
					this.handleChangeTab("wall")();
				}
			}
		}
	}

	setVideoData = () => {
		const { archivedEventId } = this.props.match.params;
		const { videoId } = this.props.match.params;

		this.setState(
			{
				videoId: videoId,
				archivedEventId: archivedEventId,
			},
			() => this.loadCurrentVideo(),
		);
	};

	loadCurrentVideo = () => {
		if (this.props.eventArchive !== null && this.props.event !== null) {
			const archivedEventData = this.props.eventArchive.find(
				(eventArchiveData) =>
					eventArchiveData._id === this.state.archivedEventId,
			);
			const timeSlot = archivedEventData.videos.find(
				(video) => video._id === this.state.videoId,
			);

			this.setVideoWallData(timeSlot);
			this.setState({
				archivedEventData: archivedEventData,
				timeSlot: timeSlot,
			});
		}
	};

	setVideoWallData = (timeSlot) => {
		this.props.setHasVideoWall(true);

		// we set up the current time slot video Id
		const activeVideoWallId = timeSlot.videoWall;

		let highlightUsers = [];

		if (timeSlot.auditoriumSpeakers && timeSlot.auditoriumSpeakers.length) {
			timeSlot.auditoriumSpeakers.forEach((speaker) => {
				highlightUsers.push(speaker.user._id);
			});
		}

		// we set up the current wall Id to be the video Wall Id
		const wallData = {
			wallId: activeVideoWallId,
			highlightUsers: highlightUsers,
		};
		this.props.setTimeSlotVideoWall(wallData);
		this.props.setActiveWall(wallData);

		// we open the sideBar to the videoWall
		this.props.seeVideoWall();
	};

	handleChangeTab = (tabName) => (e) => {
		this.setState({
			currentTab: tabName,
		});
	};

	_removeHoverEffects = () => {
		const slotTitle = document.querySelector(
			".auditorium-page .slot-title p.title span",
		);

		if (slotTitle) {
			return slotTitle.offsetHeight <= 22;
		} else {
			return null;
		}
	};

	handleRequestCloseMatchingBanner = () =>
		this.props.onRequestCloseMatchingBanner(true);
	openMatchingCloseDialog = () =>
		this.setState({
			openMatchingCloseDialog: !this.state.openMatchingCloseDialog,
		});

	closeMatchingModal = () => {
		this.setState({ openMatchingCloseDialog: false });
		this.props.onToggleMatchingDialog(false);
	};

	openMatchingModal = () => {
		const { eventId, onGetQuestionnaires, onToggleMatchingDialog } = this.props;
		onGetQuestionnaires(eventId);
		onToggleMatchingDialog(true);
	};

	render() {
		const { event, isLargeScreen, translation, defaultTranslation } =
			this.props;
		const {
			timeSlot,
			archivedEventData,
			currentTab,
			removeHoverEffect,
			openMatchingCloseDialog,
		} = this.state;

		if (!timeSlot || !archivedEventData) {
			return null;
		}

		const hideVideo = !isLargeScreen && currentTab !== "video";
		const sideContainerHidden = currentTab === "";
		const displaySideBar =
			isLargeScreen ||
			(!isLargeScreen && currentTab !== "" && currentTab !== "video");

		return (
			<div
				onDragStart={preventDefaultDrag}
				className="position-background left-sidebar-show"
			>
				<LeftSideMenu />
				<div
					id="js-auditorium-page"
					className={`auditorium-page mobile-page-container ${
						currentTab !== "video" ? "wall-tab-active" : ""
					}`}
				>
					{/*SHOW BACKGROUND IMAGE ONLY ON LARGE SCREENS*/}

					{!isLargeScreen && (
						<div onDragStart={preventDefaultDrag} className="page-title">
							{translation?.eventArchive.title}
						</div>
					)}

					{isLargeScreen && (
						<div
							className={`slot-title ${
								this._removeHoverEffects() || removeHoverEffect
									? "remove-hover"
									: "hover-active"
							}`}
						>
							<div>
								<p onDragStart={preventDefaultDrag} className={"title "}>
									<span>
										{timeSlot.speakers.length ? (
											<>
												{timeSlot.speakers.map((speaker, index) => {
													return (
														<span
															onDragStart={preventDefaultDrag}
															draggable="false"
															key={index}
														>
															{speaker}
															{index === timeSlot.speakers.length - 1 ? (
																<span>
																	{" "}
																	{translation?.programDropdown.with ||
																		defaultTranslation?.programDropdown
																			.with}{" "}
																</span>
															) : (
																", "
															)}
														</span>
													);
												})}
											</>
										) : null}
										{timeSlot.title}
									</span>
								</p>
							</div>
						</div>
					)}
					{!isLargeScreen && (
						<AuditoriumTabs
							timeSlot={timeSlot}
							hasVideoWall={timeSlot.videoWall}
							hasPolls={false}
							currentTab={currentTab}
							handleChangeTab={this.handleChangeTab}
						/>
					)}
					<div
						onDragStart={preventDefaultDrag}
						className={`centerOfPage ${hideVideo ? "hide" : ""}`}
					>
						<div onDragStart={preventDefaultDrag} className="relativeCenter">
							{isLargeScreen && (
								<AuditoriumBanners
									classes={"left-banner"}
									filesUrl={event.brandingData.filesUrl}
									lobbyBanner={event.brandingData.lobbyBanner}
								/>
							)}
							<AuditoriumVideoPlayer
								timeSlot={timeSlot}
								videoStreamingUrl={timeSlot.url}
								showFastForwardButtons={true}
								showPlayPauseButtons={true}
								greyVideoProgressBar
							/>
							{isLargeScreen && (
								<AuditoriumBanners
									classes={"right-banner"}
									filesUrl={event.brandingData.filesUrl}
									lobbyBanner={event.brandingData.lobbyBanner}
								/>
							)}
							{!isLargeScreen && (
								<div onDragStart={preventDefaultDrag} className={`slot-title`}>
									<div>
										<p onDragStart={preventDefaultDrag} className="title ">
											<span>{timeSlot && timeSlot.title}</span>
										</p>
									</div>
									<p onDragStart={preventDefaultDrag} className="subtitle">
										{archivedEventData && archivedEventData.eventName}
									</p>
								</div>
							)}
						</div>
					</div>

					{/*If the video doesn't have a videoWall, don't display the sidebar at all*/}
					{displaySideBar && timeSlot.videoWall && (
						<div
							onDragStart={preventDefaultDrag}
							className={`booth-wall-container ${
								sideContainerHidden ? "hidden" : ""
							}`}
						>
							<div
								onDragStart={preventDefaultDrag}
								className="booth-wall-header"
							>
								{timeSlot ? (
									<>
										<div
											onClick={this.handleChangeTab("wall")}
											className={`tab wall-tab ${
												currentTab === "wall" ? "active" : ""
											}`}
										>
											<span>{translation?.auditorium.videoWallButton}</span>
										</div>
										{!event.hideAuditoriumNetworking && (
											<div
												onClick={this.handleChangeTab("networking")}
												className={`tab networking-tab ${
													currentTab === "networking" ? "active" : ""
												}`}
											>
												<span>
													{translation?.sideMenu.audience ||
														defaultTranslation?.sideMenu.audience}
												</span>
											</div>
										)}
									</>
								) : (
									<span>{translation?.auditorium.onDemandButton}</span>
								)}
								{!timeSlot && currentTab === "onDemand" ? (
									<div
										className="close-dropdown auditorium-sidebar-close-dropdown"
										onClick={this.handleChangeTab("")}
									>
										<GreaterArrow fill={colors.whiteVariant} />
									</div>
								) : (
									<AuditoriumSideButtons
										timeSlot={timeSlot}
										translation={translation}
										defaultTranslation={defaultTranslation}
										handleChangeTab={this.handleChangeTab}
										hasOnDemandVideos={false}
										hasVideoWall={timeSlot.videoWall}
										hasPolls={false}
										showOnDemand={false}
										currentTab={currentTab}
									/>
								)}
							</div>
							{currentTab !== "wall" &&
								currentTab !== "networking" &&
								timeSlot && (
									<div
										onDragStart={preventDefaultDrag}
										className="booth-wall-subheader"
									>
										<BackIcon onClick={this.handleChangeTab("wall")} />
										<span>
											{currentTab === "polls" &&
												(translation?.polls?.polls ||
													defaultTranslation?.polls?.polls)}
											{currentTab === "resources" &&
												(translation?.auditorium?.resourcesButton ||
													defaultTranslation?.auditorium?.resourcesButton)}
											{currentTab === "onDemand" &&
												(translation?.auditorium?.onDemandButton ||
													defaultTranslation?.auditorium?.onDemandButton)}
										</span>
									</div>
								)}
							{currentTab === "wall" && timeSlot && <Wall isVideoWall />}
							{currentTab === "networking" && (
								<Networking
									attendees={timeSlot.attendees}
									isAuditoriumNetworking
									openMatchingDialog={this.openMatchingModal}
								/>
							)}
							{currentTab === "resources" && timeSlot && (
								<Resources currentTimeslot={timeSlot} />
							)}
						</div>
					)}
				</div>
				{event?.matchingDialog && (
					<MatchingDialog
						noOpenNetworking
						open={event?.matchingDialog}
						close={this.openMatchingCloseDialog}
						closeNoPrompt={this.closeMatchingModal}
						closeBannerOnFinish={this.handleRequestCloseMatchingBanner}
					/>
				)}
				{openMatchingCloseDialog && (
					<MatchingDialogConfirm
						hasCloseButton
						open={openMatchingCloseDialog}
						dialogTitle={
							translation?.matching?.closeMatchingTitle ||
							defaultTranslation?.matching?.closeMatchingTitle
						}
						dialogDescription={`${
							translation?.matching?.closeMatchingTextA ||
							defaultTranslation?.matching?.closeMatchingTextA
						} <br><br> ${
							translation?.matching?.closeMatchingTextB ||
							defaultTranslation?.matching?.closeMatchingTextB
						}`}
						cancelBtnLabel={
							translation?.generalText?.cancel ||
							defaultTranslation?.generalText?.cancel
						}
						confirmBtnLabel={
							translation?.generalText?.close ||
							defaultTranslation?.generalText?.close
						}
						closeConfirm={this.openMatchingCloseDialog}
						handleConfirm={this.closeMatchingModal}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		eventId: state.event.eventId,
		event: state.event.data,
		eventArchive: state.eventArchive.data,
		user: state.user.data,
		isLargeScreen: state.layout.isLargeScreen,
		languages: state.languages,
		translation: state.languages.translations[state.languages.platformLanguage],
		defaultTranslation: state.languages.translations["en"],
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onGetEventArchive: (eventId) => dispatch(actions.getEventArchive(eventId)),
		closeSidebar: () => dispatch(actions.sideNavClose()),
		setHasVideoWall: (booleanValue) =>
			dispatch(actions.sideNavHasVideoWall(booleanValue)),
		setTimeSlotVideoWall: (wallData) =>
			dispatch(actions.setTimeSlotVideoWall(wallData)),
		setActiveWall: (wallData) => dispatch(actions.setActiveWall(wallData)),
		seeVideoWall: () => dispatch(actions.sideNavSeeVideoWall()),
		onSetEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
		onGetQuestionnaires: (eventId) =>
			dispatch(actions.getQuestionnaires(eventId)),
		onToggleMatchingDialog: (bool) =>
			dispatch(actions.toggleMatchingDialog(bool)),
		onRequestCloseMatchingBanner: (bool) =>
			dispatch(actions.requestCloseMatchingBanner(bool)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Auditorium);
