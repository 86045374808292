import React from 'react';
import {ValidatorForm} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions';
import axios from '../../../store/axios-instance';
import TextField from '../../../Components/InputFields/TextField';
import SelectCountry from '../../../Components/InputFields/SelectCountry';
import RadioField from '../../../Components/InputFields/RadioField';
import SelectField from '../../../Components/InputFields/SelectField';
import CheckboxField from '../../../Components/InputFields/CheckboxField';
import {preventDefaultDrag} from '../../../Utils/utils';
import CustomSDGFields from '../../../Components/InputFields/CustomSDGFields';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {
    isLinkRule,
    isFacebookLinkRule,
    isLinkedinLinkRule,
    isTwitterLinkRule,
} from 'Utils/validationRules';

class EditParticipantInformation extends React.Component {


    state = {
        user: {
            fields: [
                {
                    isEnabled: true,
                    name: 'company',
                    type: 'text',
                    value: '',
                    label: 'Company',
                    multiline: 0,
                    validators: [],
                    errorMessages: [],
                },
                {
                    isEnabled: true,
                    name: 'title',
                    type: 'text',
                    value: '',
                    label: 'Title',
                    multiline: 0,
                    validators: [],
                    errorMessages: [],
                },
                {
                    isEnabled: true,
                    name: 'phone',
                    type: 'text',
                    value: '',
                    label: 'Mobile',
                    multiline: 0,
                    validators: ['matchRegexp:^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$'],
                    errorMessages: [
                        `${this.props.languages.translations[this.props.languages.eventLanguage].errors.phoneInvalid}`,
                    ],
                },
                {
                    isEnabled: true,
                    name: 'facebook',
                    type: 'text',
                    value: '',
                    label: 'Facebook profile',
                    multiline: 0,
                    validators: ['isFacebookLink'],
                    errorMessages: [
                        `${
                            this.props.languages.translations[this.props.languages.eventLanguage].errors
                                .facebookLinkInvalid
                        }`,
                    ],
                },
                {
                    isEnabled: true,
                    name: 'linkedin',
                    type: 'text',
                    value: '',
                    label: 'Your Linkedin profile',
                    multiline: 0,
                    validators: ['isLinkedinLink'],
                    errorMessages: [
                        `${
                            this.props.languages.translations[this.props.languages.eventLanguage].errors
                                .linkedinLinkInvalid
                        }`,
                    ],
                },
                {
                    isEnabled: true,
                    name: 'twitter',
                    type: 'text',
                    value: '',
                    label: 'Your Twitter profile',
                    multiline: 0,
                    validators: ['isLTwitterLink'],
                    errorMessages: [
                        `${
                            this.props.languages.translations[this.props.languages.eventLanguage].errors
                                .twitterLinkInvalid
                        }`,
                    ],
                },
                {
                    isEnabled: true,
                    name: 'country',
                    type: 'text',
                    value: '',
                    label: 'Country',
                    multiline: 0,
                    validators: [],
                    errorMessages: [],
                },
                {
                    isEnabled: true,
                    name: 'website',
                    type: 'text',
                    value: '',
                    label: 'Website',
                    multiline: 0,
                    validators: ['isLink'],
                    errorMessages: [
                        `${this.props.languages.translations[this.props.languages.eventLanguage].errors.linkInvalid}`,
                    ],
                },
                {
                    isEnabled: true,
                    name: 'sdgs',
                    type: 'text',
                    value: [],
                    label: 'Which SDGs are you interested in?',
                    multiline: 0,
                    validators: [],
                    errorMessages: [],
                },
            ],
            chatPreference: {
                name: 'chatPreference',
                type: 'radio',
                value: 'everybody',
                label: 'Who should we allow to contact you in this event via the Neural Network chat?',
                options: [
                    {label: 'Everybody', value: 'everybody'},
                    {label: 'Only Contacts', value: 'contacts'},
                    {label: 'Only High Matches (if Smart Matching is enabled for this event)', value: 'matches'},
                    {label: 'Only Contacts or High Matches', value: 'contactsOrMatches'},
                    {label: 'Nobody', value: 'nobody'},
                ],
                multiline: 0,
                validators: [],
                errorMessages: [],
            },
        },
        disabled: true,
    };



    componentDidMount() {
        this.addValidationRules();
        this.setRegistrationFields();
    }

    componentDidUpdate(prevProps) {
        const {event, userEventProfile} = this.props;
        // force a state update if the user changed something in a participant or exhibitor
        if (prevProps.event._id !== event._id) {
            this.setRegistrationFields();
        }
    }

    componentWillUnmount() {
        // remove rule when it is not needed
        this.removeValidationRules();
        this.setRegistrationFields();
    }



    setRegistrationFields = () => {
        const {fields} = this.state.user;
        const {predefinedFields, customFields} = this.props;

        let updatedFields = [...fields];
        updatedFields.forEach((field) => {
            let eventInfoField = predefinedFields[field.name];

            if (!eventInfoField) return;

            field.label = eventInfoField.label;
            field.isEnabled = eventInfoField.isEnabled;
            if (eventInfoField.isRequired) {
                field.validators.push(['required']);
                field.errorMessages.push([
                    this.props.languages.translations[this.props.languages.eventLanguage].errors.required,
                ]);
            }
        });

        customFields.forEach((extraField) => {

            const field = {
                isEnabled: true,
                name: extraField._id,
                type: extraField.type,
                value: '',
                label: extraField.label,
                multiline: 0,
                validators: [],
                errorMessages: [],
                options: extraField.options,
            };
            if (extraField.isRequired) {
                field.validators.push(['required']);
                field.errorMessages.push([
                    this.props.languages.translations[this.props.languages.eventLanguage].errors.required,
                ]);
            }
            updatedFields.push(field);
        });

        let enabledUpdatedFields = updatedFields.filter((field) => field.isEnabled);
        this.setState({
            user: {...this.state.user, fields: enabledUpdatedFields},
        }, this.addNoCommasValidation);
    };

    addNoCommasValidation = () => {
        const updatedUser = cloneDeep(this.state.user);
        const {translation, defaultTranslation} = this.props;
        updatedUser.fields.forEach(field => {
            // for the sdg field we send as values an array
            // an array contains ',' and we couldn't pass the validation
            // by adding !Array.isArray(field.value) we fixed this bug
            if(field.type === "text" && !Array.isArray(field.value)){
                field.validators.push('noCommasAllowed');
                const errorMessage = translation.errors.noCommasAllowed || defaultTranslation.errors.noCommasAllowed;
                field.errorMessages.push(errorMessage);
            }
        })
        this.setState({
            user: updatedUser
        })
    }

    handleFieldChange = (fieldName, fieldValue) => {
        const {fields, chatPreference} = this.state.user;
        let updatedFields = [...fields];

        if (fieldName === 'chatPreference') {
            chatPreference.value = fieldValue;
        } else {
            let updatedFieldIndex = updatedFields.findIndex((field) => field.name === fieldName);
            updatedFields[updatedFieldIndex].value = fieldValue;
        }

        this.setState({user: {...this.state.user, fields: updatedFields}}, () => {
            this.refs.form.isFormValid().then((isValid) => {
                this.setState({disabled: !isValid});
            });
        });
    };

    handleCreateOrganizerProfile = () => {
        const {fields, chatPreference} = this.state.user;
        const {eventId} = this.props;
        let user = {};
        user[chatPreference.name] = chatPreference.value;
        fields.forEach((field) => {
            user[field.name] = field.value;
        });

        axios({method: 'post', url: '/event/' + eventId + '/create-participant-profile', data: user})
            .then((response) => {
                const {eventId, userId} = this.props;
                this.props.onRefreshUserData();
                this.props.onGetUserEventProfile(eventId, userId);
                this.props.closeDialog('');

            })
            .catch((error) => {});
    };

    addValidationRules = () => {
        ValidatorForm.addValidationRule('isFacebookLink', (value) => {
            let rule = isFacebookLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isLinkedinLink', (value) => {
            let rule = isLinkedinLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isLTwitterLink', (value) => {
            let rule = isTwitterLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isLink', (value) => {
            let rule = isLinkRule
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                this.setState({newLinkError: true});
                return false;
            }
            this.setState({newLinkError: false});
            return true;
        });
        ValidatorForm.addValidationRule('noCommasAllowed', (value) => {
            let rule = new RegExp('^[^,]+$', 'i');
            let match = rule.test(value);

            if (value === undefined || value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
    };

    removeValidationRules = () => {
        ValidatorForm.removeValidationRule('isFacebookLink');
        ValidatorForm.removeValidationRule('isLinkedinLink');
        ValidatorForm.removeValidationRule('isLTwitterLink');
        ValidatorForm.removeValidationRule('isLink');
        ValidatorForm.removeValidationRule('noCommasAllowed');
    };

    handleChangeCountry = (e) => {
        this.setState({
            user: {...this.state.user, country: e.target.value},
        });
    };

    render() {
        const {opened, fields, chatPreference} = this.state.user;
        const {event, translation, defaultTranslation} = this.props;
        return (
            <div
                onDragStart={preventDefaultDrag}
                className={`edit-user-information small-padding-top ${opened ? 'opened' : ''}`}
            >
                <div>
                    <div onDragStart={preventDefaultDrag} className="page-title">
                        <p>
                            {translation?.myAccountPage.createParticipantProfile ||
                                defaultTranslation?.myAccountPage.createParticipantProfile ||
                                'Create Participant Profile'}
                        </p>
                    </div>
                    <div onDragStart={preventDefaultDrag} className="content-wrapper">
                        <div onDragStart={preventDefaultDrag} className="form-wrapper">
                            <ValidatorForm ref="form" onSubmit={this.handleCreateOrganizerProfile}>
                                <div className="chat-preference-wrapper">
                                    <RadioField
                                        field={chatPreference}
                                        handleChange={this.handleFieldChange}
                                        key={chatPreference.name}
                                        withLabel
                                    />
                                </div>
                                {fields.map((field) => {
                                    if (field.name === 'country') {
                                        return (
                                            <SelectCountry
                                                field={field}
                                                handleChange={this.handleFieldChange}
                                                key={field.name}
                                            />
                                        );
                                    }
                                    if (field.type === 'select') {
                                        return (
                                            <SelectField
                                                field={field}
                                                handleChange={this.handleFieldChange}
                                                key={field.name}
                                            />
                                        );
                                    }
                                    if (field.type === 'radio') {
                                        return (
                                            <RadioField
                                                field={field}
                                                handleChange={this.handleFieldChange}
                                                key={field.name}
                                            />
                                        );
                                    }
                                    if (field.type === 'checkbox') {
                                        return (
                                            <CheckboxField
                                                field={field}
                                                handleChange={this.handleFieldChange}
                                                key={field.name}
                                                fieldValues={field.value}
                                            />
                                        );
                                    }
                                    if (field.name === 'sdgs') {
                                        return (
                                            <CustomSDGFields
                                                field={field}
                                                key={field.name}
                                                handleChange={this.handleFieldChange}
                                                eventInfo={event}
                                                fieldValues={field.value}
                                            />
                                        )
                                    }
                                    return (
                                        <TextField
                                            field={field}
                                            handleChange={this.handleFieldChange}
                                            key={field.name}
                                        />
                                    );
                                })}
                                <div onDragStart={preventDefaultDrag} className="buttons-actions">
                                    <button className="cancel-button" onClick={() => this.props.closeDialog('')}>
                                        {translation?.generalText.close}
                                    </button>
                                    <button className="update-button" disabled={this.state.disabled}>
                                        {translation?.generalText.save}
                                    </button>
                                </div>
                            </ValidatorForm>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        predefinedFields: state.event?.registrationFields?.data?.participantPredefinedRegistrationFields,
        customFields: state.event?.registrationFields?.data?.participantRegistrationFields,
        eventId: state.event.eventId,
        event: state.event.data,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        userId: state.user.data._id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetUserEventProfile: (eventId, userId) => dispatch(actions.getUserEventProfile(eventId, userId)),
        onRefreshUserData: () => dispatch(actions.refreshUserData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditParticipantInformation);
