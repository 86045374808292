import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
class ConfirmDelete extends React.Component {
    render() {
        const {
            classes,
            disabledConfirm,
            open,
            closeConfirmDeleteDialog,
            dialogTitle,
            dialogDescription,
            handleConfirmDelete,
            dialogConfirmButtonLabel,
        } = this.props;
        return (
            <Dialog
                disableRestoreFocus
                className={classes}
                open={open}
                onClose={closeConfirmDeleteDialog}
                aria-labelledby="form-dialog-title"
                PaperProps={{classes: {root: 'reverse-rtl'}}}
            >
                <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="form-dialog-secondary">{dialogDescription}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button type="button" classes={{label: 'dialog-btn-uppercase'}} onClick={closeConfirmDeleteDialog}>
                        Cancel
                    </Button>
                    <Button
                        type="button"
                        classes={{label: 'dialog-btn-uppercase'}}
                        disabled={disabledConfirm}
                        onClick={handleConfirmDelete}
                    >
                        {dialogConfirmButtonLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ConfirmDelete;
