import * as actionTypes from '../actions/actionTypes';
import { normalize, schema } from 'normalizr';
import produce from "immer";

const initialState = {
    loading: false,
    loadingGetFeaturedUsers: false,
    participants: [],
    exhibitors: [],
    organizers: [],
    allEventUsers: [],
    allEventUsersObject: {},
    eventOnlineUsers: [],
    featuredUsersFormatted: [],
    error: false,
};

const userSchema = new schema.Entity('users', {}, { idAttribute: 'user' });
const sortUsersAlphabetically = (x, y) => {
    if (x.first.toLowerCase() === y.first.toLowerCase()) {
        return x.last.toLowerCase() < y.last.toLowerCase() ? -1 : 1;
    }
    return x.first.toLowerCase() < y.first.toLowerCase() ? -1 : 1;
};

const reducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.GET_USERS_START:
            case actionTypes.GET_EVENT_ONLINE_USERS_START:
                draft.loading = true
                break;

            case actionTypes.GET_USERS_FAIL:
            case actionTypes.GET_EVENT_ONLINE_USERS_FAIL:
                draft.loading = false;
                break;

            case actionTypes.GET_USERS_SUCCESS:
                const usersAndOrganizers = action.payload.users.concat(action.payload.organizers);
                const usersAndOrganizersSchema = normalize(usersAndOrganizers, [userSchema])

                draft.loading = false
                // the users will be received sorted out in alphabetical order from the backend
                // in allEventUsers we don't add organizers because we don't need to display them in networking
                draft.allEventUsers = action.payload.users;

                // in allEventUsersObject we will add organizers because this object will be used
                // to display information about the user who posted on the wall
                // and the organizer can do that
                draft.allEventUsersObject = usersAndOrganizersSchema?.entities?.users
                break;

            case actionTypes.ADD_EVENT_PARTICIPANT:
            case actionTypes.ADD_EVENT_EXHIBITOR:
                // make sure we don't push the user to the array if he is already added
                if (draft.allEventUsers.findIndex((eventUser) => eventUser?.user === action.payload?.user) === -1) {
                    draft.allEventUsers.push(action.payload);
                }
                draft.allEventUsers.sort(sortUsersAlphabetically);

                // make sure we don't push the user to the eventUsersObject if he is already added
                if(!draft.allEventUsersObject[action.payload?.user]){
                    draft.allEventUsersObject[action.payload?.user] = action.payload
                }
                break;

            case actionTypes.REMOVE_EVENT_PARTICIPANT:
            case actionTypes.REMOVE_EVENT_EXHIBITOR:
                draft.allEventUsers = draft.allEventUsers.filter((eventUser) => eventUser.user !== action.payload.userId);
                delete draft.allEventUsersObject[action.payload.userId]
                break;

            case actionTypes.UPDATE_EVENT_PARTICIPANT:
            case actionTypes.UPDATE_EVENT_EXHIBITOR:
                const updatedEventUserData = action.payload;
                const updatedEventUserDataIndex = draft.allEventUsers.findIndex(
                    (eventUser) => eventUser.user === updatedEventUserData.user
                );
                if (updatedEventUserDataIndex !== -1) {
                    draft.allEventUsers[updatedEventUserDataIndex] = updatedEventUserData;
                }
                draft.allEventUsers.sort(sortUsersAlphabetically);

                // make sure the user exists and only after that update his data
                if(draft.allEventUsersObject[updatedEventUserData.user]){
                    draft.allEventUsersObject[updatedEventUserData.user] = updatedEventUserData
                }
                break;

            case actionTypes.UPDATE_EVENT_ORGANIZER:
                const updatedOrganizer = action.payload;
                if(draft.allEventUsersObject[updatedOrganizer.user]){
                    draft.allEventUsersObject[updatedOrganizer.user] = updatedOrganizer
                }
                break;

            case actionTypes.GET_EVENT_ONLINE_USERS_SUCCESS:
                draft.loading = false
                draft.eventOnlineUsers = action.payload;
                break;

            case actionTypes.GET_FEATURED_USERS_SUCCESS:
                draft.loadingGetFeaturedUsers = false;
                draft.featuredUsersFormatted = action.payload;
                break;

            default:
                break;
        }
    })

export default reducer;
