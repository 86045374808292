import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';

class ConfirmEvent extends React.Component {
    render() {
        const {open, closeConfirm} = this.props;
        return (
            <Dialog
                open={open}
                onClose={closeConfirm}
                aria-labelledby="form-dialog-title"
                PaperProps={{classes: {root: 'reverse-rtl'}}}
                className="confirm-event"
            >
                <DialogTitle id="form-dialog-title">Create Onvent</DialogTitle>
                <DialogContent>
                    <DialogContentText id="form-dialog-secondary">
                        You are about to create an Organizer account. In order to activate your Organizer account, you
                        need a {process.env.REACT_APP_PLATFORM_NAME} license. By clicking Confirm you let us know that you are interested in
                        purchasing a {process.env.REACT_APP_PLATFORM_NAME} license, and our team will get in touch with you.
                        <br />
                        <br />
                        If you are trying to login to an event as a participant, please go back to the event and click
                        register or login.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeConfirm} type="button" classes={{label: 'dialog-btn-uppercase'}}>
                        Cancel
                    </Button>
                    <Button type="button" classes={{label: 'dialog-btn-uppercase'}} onClick={this.props.handleConfirm}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ConfirmEvent;
