import openSocket from 'socket.io-client';
import {isWebview} from '../Utils/is-webview';
import * as actions from '../store/actions';
import store from '../store/store';
// let socket = openSocket(process.env.REACT_APP_SOCKET_API, { reconnect: true, transports: ['websocket', 'polling'] });

let socket, socketOptions;
let token = localStorage.getItem('token');
// let isFirefox = /Firefox[\/\s](\d+\.\d+)/.test(navigator.userAgent); //test for Firefox/x.x     or Firefox x.x (ignoring remaining digits);

socketOptions = {
    reconnect: true,
    secure: true,
    auth: {
        token: token,
    },
    transports: ['websocket', 'polling'],
};

// if (isFirefox) {
//     socketOptions.transports = ['polling']
// }

socket = openSocket.connect(process.env.REACT_APP_SOCKET_API, socketOptions);

socket.on('disconnect', () => {
    console.log('socket disconnect');
    if (isWebview) return;
    store.dispatch(actions.serverDown());
});

socket.io.on('reconnect_attempt', () => {
    console.log('socket reconnect_attempt');
    let token = localStorage.getItem('token');
    socket.io.opts.query = {
        token: token,
    };
});

socket.io.on('reconnect', () => {
    // ...
    console.log('socket reconnected');
});

// connect to Event
function connectToEvent(userId, eventId) {
    socket.emit('connect-to-event', {userId: userId, eventId: eventId});
}

// connect Organizer to Event (for extra data)
function connectOrganizerToEvent(userId, eventId) {
    socket.emit('connect-organizer-to-event', {userId: userId, eventId: eventId});
}

// disconnect from Event
function disconnectFromEvent(userId, eventId) {
    socket.emit('disconnect-from-event', {userId: userId, eventId: eventId});
}

// connect to Private chat
function connectToPrivateChat(chatId, userId) {
    socket.emit('connect-to-private-chat', {chatId: chatId, userId: userId});
}

// connect to Private chat video stream
function connectToPrivateChatStream(userId, chatId) {
    socket.emit('connect-to-private-chat-stream', {userId: userId, chatId: chatId});
}

// new message in Private chat
function privateMessageReceived(cb) {
    socket.on('new-message-saved', (message) => cb(null, message));
}

const deregisterPrivateMessageReceived = () => {
    socket.off('new-message-saved');
};

// new meeting notification in Private Chat
function meetingNotificationReceived(cb) {
    socket.on('meeting-notification', (notificationData) => cb(null, notificationData));
}

const deregisterMeetingNotificationReceived = () => {
    socket.off('meeting-notification');
};

// disconnect from private chat
function disconnectFromPrivateChat(chatId, userId) {
    socket.emit('disconnect-from-private-chat', {chatId: chatId, userId: userId});
}

// disconnect from private chat video stream
function disconnectFromPrivateChatStream(userId, chatId) {
    socket.emit('disconnect-from-private-chat-stream', {userId: userId, chatId: chatId});
}

// connect to Group chat
function connectToGroupChat(chatId, userId) {
    socket.emit('connect-to-group-chat', {chatId: chatId, userId: userId});
}

// connect to Group chat video stream
function connectToGroupChatStream(userId, chatId) {
    socket.emit('connect-to-group-chat-stream', {userId: userId, chatId: chatId});
}

// new message in Group chat
function groupMessageReceived(cb) {
    socket.on('new-group-message-saved', (message) => cb(null, message));
}

const deregisterGroupMessageReceived = () => {
    socket.off('new-group-message-saved');
};

// disconnect from group chat
function disconnectFromGroupChat(chatId, userId) {
    socket.emit('disconnect-from-group-chat', {chatId: chatId, userId: userId});
}

// disconnect from group chat video stream
function disconnectFromGroupChatStream(userId, chatId) {
    socket.emit('disconnect-from-group-chat-stream', {userId: userId, chatId: chatId});
}

// returns users that are currently connected to a video stream (group or private)
function usersConnectedToVideoStream(userIds) {
    socket.on('stream-participants-updated', userIds);
}

// connect to wall
function connectToWall(wallId, userId) {
    socket.emit('connect-to-wall', {wallId: wallId, userId: userId});
}

// disconnect from wall
function disconnectFromWall(wallId, userId) {
    socket.emit('disconnect-from-wall', {wallId: wallId, userId: userId});
}

function postReceived(cb) {
    socket.on('new-post-saved', (post) => cb(null, post));
}

const deregisterPostReceived = () => {
    socket.off('new-post-saved');
};

function postDeleted(cb) {
    socket.on('wall-post-deleted', (post) => cb(null, post));
}

const deregisterPostDeleted = () => {
    socket.off('wall-post-deleted');
};

function commentReceived(cb) {
    socket.on('new-post-comment-saved', (commentData) => cb(null, commentData));
}

const deregisterCommentReceived = () => {
    socket.off('new-post-comment-saved');
};

function commentDeleted(cb) {
    socket.on('wall-post-comment-deleted', (commentData) => cb(null, commentData));
}

const deregisterCommentDeleted = () => {
    socket.off('wall-post-comment-deleted');
};

function postLikeReceived(cb) {
    socket.on('new-post-like-saved', (postLikeData) => cb(null, postLikeData));
}

const deregisterPostLikeReceived = () => {
    socket.off('new-post-like-saved');
};

function postUnlikeReceived(cb) {
    socket.on('post-like-removed', (postUnlike) => cb(null, postUnlike));
}

const deregisterPostUnlikeReceived = () => {
    socket.off('post-like-removed');
};

function pinPost(cb) {
    socket.on('pinned-post-updated', (postPinnedData) => cb(null, postPinnedData));
}

const deregisterPinPost = () => {
    socket.off('pinned-post-updated');
};

function wallPostUpdated(cb) {
    socket.on('post-updated', (newPostsData) => cb(null, newPostsData));
}

const deregisterWallPostUpdated = () => {
    socket.off('post-updated');
};

function wallCommentUpdated(cb) {
    socket.on('wall-post-comment-updated', (newCommentsData) => cb(null, newCommentsData));
}

const deregisterWallCommentUpdated = () => {
    socket.off('wall-post-comment-updated');
};

function wallPostApproved(cb) {
    socket.on('post-approved', (postApproved) => cb(null, postApproved));
}

const deregisterWallPostApproved = () => {
    socket.off('post-approved');
};

function wallPostCommentApproved(cb) {
    socket.on('wall-post-comment-approved', (postApproved) => cb(null, postApproved));
}

const deregisterWallPostCommentApproved = () => {
    socket.off('wall-post-comment-approved');
};

function reloadWall(cb) {
    socket.on('reload-wall', (wallId) => cb(null, wallId));
}

// get notifications for user
function connectToNotifications(userId) {
    socket.emit('connect-to-user-notifications', {userId: userId});
}

function notificationReceived(cb) {
    socket.on('new-notification', (notificationData) => {
        cb(null, notificationData);
    });
}
function socketEventSettingUpdated(cb) {
    socket.on('event-setting-updated', (settingData) => cb(null, settingData));
}

function socketCustomRegFieldsUpdated(cb) {
    socket.on('event-customRegField-updated', (data) => cb(null, data));
}

function deregisterSocketCustomRegFieldsUpdated(cb) {
    socket.off('event-customRegField-updated');
}

function flashNotificationReceived(cb) {
    socket.on('new-flash-notification', (notificationData) => cb(null, notificationData));
}

function disconnectFromNotifications(userId) {
    socket.emit('disconnect-from-user-notifications', {userId: userId});
}

// get notifications for user
function connectToBooth(exhibitorId, userId) {
    socket.emit('connect-to-booth', {exhibitorId: exhibitorId, userId: userId});
}
function connectAdminToBooth(exhibitorId) {
    socket.emit('connect-admin-to-booth', {exhibitorId: exhibitorId});
}
function disconnectFromBooth(exhibitorId, userId, connectTimestamp, disconnectTimestamp, tracking) {
    socket.emit('disconnect-from-booth', {
        exhibitorId: exhibitorId,
        userId: userId,
        connectTimestamp: connectTimestamp,
        disconnectTimestamp: disconnectTimestamp,
        tracking: tracking,
    });
}
function disconnectAdminFromBooth(exhibitorId) {
    socket.emit('disconnect-admin-from-booth', {exhibitorId: exhibitorId});
}
function boothVisitorsUpdated(cb) {
    socket.on('booth-participants-updated', (data) => cb(null, data));
}

function liveWallPostsDeleted(cb) {
    socket.on('all-wall-posts-deleted', (data) => cb(null, data));
}

function connectUserToTimeslot(userId, timeslotId, auditoriumId, eventId) {
    const data = {
        userId: userId,
        programId: timeslotId,
        auditoriumId: auditoriumId,
        eventId: eventId,
    };
    socket.emit('connect-to-timeslot', data);
}
function disconnectUserFromTimeslot(userId, timeslotId) {
    const data = {
        userId: userId,
        programId: timeslotId,
    };
    socket.emit('disconnect-from-timeslot', data);
}

function newTimeslotAttendee(cb) {
    socket.on('timeslot-user-connected', (userId) => cb(null, userId));
}

// function checkSocketConnection(cb) {
//     socket.on('send-server-timestamp', (data) => cb(null, data));
// }

// function removeCheckSocketConnectionListener() {
//     socket.off('send-server-timestamp');
// }

// a function to update auditorium resources in real time when user is on "auditorium" page
const handleAuditoriumResourcesUpdate = (cb) => {
    socket.on('update-auditorium-resources', (data) => cb(data.notification.data));
};

// a handler to update auditorium resources in redux store when user is not on "auditorium" page
socket.on('update-auditorium-resources', (data) => {
    store.dispatch(actions.updateAuditoriumResources(data.notification.data));
});

// polls
const connectToTimeslotPoll = (userId, programId) =>
    socket.emit('connect-to-program-polls', {
        programId,
        userId,
    });

const disconnectToTimeslotPoll = (userId, programId) =>
    socket.emit('disconnect-from-program-polls', {
        programId,
        userId,
    });

const handlePollUpdate = (cb) => {
    socket.on('poll-updated', (data) => cb(data));
};

const handlePollCreate = (cb) => {
    socket.on('poll-created', (data) => cb(data));
};

const handlePollDelete = (cb) => {
    socket.on('poll-deleted', (data) => cb(data));
};

const handlePollsUpdated = (cb) => {
    socket.on('polls-updated', (data) => cb(data));
};

// userStatuses
const handleUserStatusUpdate = (cb) => {
    socket.on('users-status-update', ({user}) => cb({id: user.userId, status: user.status}));
};

const changeUserStatusToBusy = (userId) => {
    socket.emit('change-to-busy', {userId});
};

const changeUserStatusToOnline = (userId) => {
    socket.emit('change-to-online', {userId});
};
const callUsers = (userIds) => {
    socket.emit('call-users', userIds);
};
const receiveCallUsers = (userIds) => {
    socket.on('receive-call-users', userIds);
};

// EVENT SPECIFIC SOCKET EVENTS
// socket events to update the eventUsers reducer
// the cb functions for this socketEvents are located in eventMenu.js component
const eventUsersNewParticipant = (cb) => {
    socket.on('new-participant', (data) => cb(null, data));
};
const eventUsersNewExhibitor = (cb) => {
    socket.on('new-exhibitor', (data) => cb(null, data));
};
const eventUsersUpdatedOrganizer = (cb) => {
    socket.on('updated-organizer', (data) => cb(null, data));
};
const eventUsersUpdatedParticipant = (cb) => {
    socket.on('updated-participant', (data) => cb(null, data));
};
const eventUsersUpdatedExhibitor = (cb) => {
    socket.on('updated-exhibitor', (data) => cb(null, data));
};
const eventUsersRemovedParticipant = (cb) => {
    socket.on('removed-participant', (data) => cb(null, data));
};
const eventUsersRemovedExhibitor = (cb) => {
    socket.on('removed-exhibitor', (data) => cb(null, data));
};
const eventDeleted = (cb) => {
    socket.on('event-deleted', (data) => cb(null, data));
};
const userEventRolesUpdate = (cb) => {
    socket.on('event-roles-update', (data) => cb(null, data));
};
const eventMatching = (cb) => {
    socket.on('event-setting-updated', (data) => cb(null, data));
};

// Session
const connectToSession = (sessionId, sessionToken) => {
    socket.emit('connect-to-session', {sessionId, sessionToken});
};

const disconnectFromSession = (sessionId, sessionToken) =>
    socket.emit('disconnect-from-session', {sessionId, sessionToken});

const sessionUpdated = (cb) => {
    socket.on('session-updated', (data) => cb(null, data));
};

const sessionUserInvite = (cb) => {
    socket.on('session-invite', (data) => cb(null, data));
};
const deregisterSessionUserInvite = () => {
    socket.off('session-invite');
};

const sessionUserCreated = (cb) => {
    socket.on('session-user-created', (data) => cb(data));
};

const deregisterSessionUserCreated = () => {
    socket.off('session-user-created');
};

const sessionUserApproved = (cb) => {
    socket.on('session-user-approved', (data) => cb(data));
};

const deregisterSessionUserApproved = () => {
    socket.off('session-user-approved');
};

const sessionUserReady = (cb) => {
    socket.on('session-user-ready', (data) => cb(data));
};
const deregisterSessionUserReady = () => {
    socket.off('session-user-ready');
};

const invitationAccepted = (cb) => {
    socket.on('session-invitation-accepted', (data) => cb(data));
};

const invitationDeclined = (cb) => {
    socket.on('session-invitation-declined', (data) => cb(data));
};

const sessionUserKicked = (cb) => {
    socket.on('session-user-kicked', (data) => cb(data));
};
const deregisterSessionUserKicked = () => {
    socket.off('session-user-kicked');
};

const sessionUserLeft = (cb) => {
    socket.on('session-user-left', (data) => cb(data));
};

const deregisterSessionUserLeft = () => {
    socket.off('session-user-left');
};

const sessionUserMuted = (cb) => {
    socket.on('session-user-muted', (data) => cb(data));
};

const sessionUserUnmuted = (cb) => {
    socket.on('session-user-unmuted', (data) => cb(data));
};

const sessionUserVideoEnabled = (cb) => {
    socket.on('session-user-video-enabled', (data) => cb(data));
};

const sessionUserVideoDisabled = (cb) => {
    socket.on('session-user-video-disabled', (data) => cb(data));
};

const sessionUserSharingEnabled = (cb) => {
    socket.on('session-user-sharing-enabled', (data) => cb(data));
};

const sessionUserSharingDisabled = (cb) => {
    socket.on('session-user-sharing-disabled', (data) => cb(data));
};

const sessionUserPermissionUpdated = (cb) => {
    socket.on('session-user-permissions-updated', (data) => cb(data));
};

const sessionChanged = (cb) => {
    socket.on('session-changed', ({session}) => cb(session));
};

const sessionCanRequestMicrophone = (cb) => {
    socket.on('session-request-microphone', (data) => cb(data));
};

function connectToSessionChat(sessionToken, chatId, userId, sessionId) {
    socket.emit('connect-to-session-chat', {chatId, userId, sessionToken, sessionId});
}
function disconnectFromSessionChat(sessionToken, chatId, userId, sessionId) {
    socket.emit('disconnect-from-session-chat', {chatId, userId, sessionToken, sessionId});
}

function liveSessionMessageReceived(cb) {
    socket.on('new-session-message-saved', (data) => cb(data));
}

// ETX implementation
function sessionMessageReceived(cb) {
    socket.on('new-session-message-saved', ({message}) => cb(message));
}

// Session Wall => socket events for wall where we don't have an authenticated user
// connect to wall
function connectToSessionWall(wallId) {
    socket.emit('connect-to-session-wall', {wallId: wallId});
}

// disconnect from wall
function disconnectFromSessionWall(wallId) {
    socket.emit('disconnect-from-session-wall', {wallId: wallId});
}

function sessionWallPostReceived(cb) {
    socket.on('new-post-saved', (post) => cb(null, post));
}

function sessionWallPostUpdated(cb) {
    socket.on('post-updated', (post) => cb(null, post));
}

function sessionWallPostDeleted(cb) {
    socket.on('wall-post-deleted', (post) => cb(null, post));
}

function sessionWallCommentReceived(cb) {
    socket.on('new-post-comment-saved', (commentData) => cb(null, commentData));
}

function sessionWallCommentUpdated(cb) {
    socket.on('wall-post-comment-updated', (commentData) => cb(null, commentData));
}

function sessionWallCommentDeleted(cb) {
    socket.on('wall-post-comment-deleted', (commentData) => cb(null, commentData));
}

function sessionWallPostLikeReceived(cb) {
    socket.on('new-post-like-saved', (postLikeData) => cb(null, postLikeData));
}

function sessionWallPostUnlikeReceived(cb) {
    socket.on('post-like-removed', (postUnlike) => cb(null, postUnlike));
}

function sessionWallPinPost(cb) {
    socket.on('pinned-post-updated', (postPinnedData) => cb(null, postPinnedData));
}

export {
    boothVisitorsUpdated,
    callUsers,
    changeUserStatusToBusy,
    changeUserStatusToOnline,
    commentDeleted,
    commentReceived,
    connectAdminToBooth,
    connectOrganizerToEvent,
    connectToBooth,
    connectToEvent,
    connectToGroupChat,
    connectToGroupChatStream,
    connectToNotifications,
    connectToPrivateChat,
    connectToPrivateChatStream,
    connectToSession,
    connectToSessionChat,
    connectToSessionWall,
    connectToTimeslotPoll,
    connectToWall,
    connectUserToTimeslot,
    deregisterCommentDeleted,
    deregisterCommentReceived,
    deregisterGroupMessageReceived,
    deregisterMeetingNotificationReceived,
    deregisterPinPost,
    deregisterPostDeleted,
    deregisterPostLikeReceived,
    deregisterPostReceived,
    deregisterPostUnlikeReceived,
    deregisterPrivateMessageReceived,
    deregisterSessionUserApproved,
    deregisterSessionUserCreated,
    deregisterSessionUserInvite,
    deregisterSessionUserKicked,
    deregisterSessionUserLeft,
    deregisterSessionUserReady,
    deregisterSocketCustomRegFieldsUpdated,
    deregisterWallCommentUpdated,
    deregisterWallPostApproved,
    deregisterWallPostCommentApproved,
    deregisterWallPostUpdated,
    disconnectAdminFromBooth,
    disconnectFromBooth,
    disconnectFromEvent,
    disconnectFromGroupChat,
    disconnectFromGroupChatStream,
    disconnectFromNotifications,
    disconnectFromPrivateChat,
    disconnectFromPrivateChatStream,
    disconnectFromSession,
    disconnectFromSessionChat,
    disconnectFromSessionWall,
    disconnectFromWall,
    disconnectToTimeslotPoll,
    disconnectUserFromTimeslot,
    eventDeleted,
    eventMatching,
    eventUsersNewExhibitor,
    eventUsersNewParticipant,
    eventUsersRemovedExhibitor,
    eventUsersRemovedParticipant,
    eventUsersUpdatedExhibitor,
    eventUsersUpdatedOrganizer,
    eventUsersUpdatedParticipant,
    flashNotificationReceived,
    groupMessageReceived,
    handleAuditoriumResourcesUpdate,
    handlePollCreate,
    handlePollDelete,
    handlePollUpdate,
    handlePollsUpdated,
    handleUserStatusUpdate,
    invitationAccepted,
    invitationDeclined,
    liveSessionMessageReceived,
    liveWallPostsDeleted,
    meetingNotificationReceived,
    newTimeslotAttendee,
    notificationReceived,
    pinPost,
    postDeleted,
    postLikeReceived,
    postReceived,
    postUnlikeReceived,
    privateMessageReceived,
    receiveCallUsers,
    reloadWall,
    sessionCanRequestMicrophone,
    sessionChanged,
    sessionMessageReceived,
    sessionUpdated,
    sessionUserApproved,
    sessionUserCreated,
    sessionUserInvite,
    sessionUserKicked,
    sessionUserLeft,
    sessionUserMuted,
    sessionUserPermissionUpdated,
    sessionUserReady,
    sessionUserSharingDisabled,
    sessionUserSharingEnabled,
    sessionUserUnmuted,
    sessionUserVideoDisabled,
    sessionUserVideoEnabled,
    sessionWallCommentDeleted,
    sessionWallCommentReceived,
    sessionWallCommentUpdated,
    sessionWallPinPost,
    sessionWallPostDeleted,
    sessionWallPostLikeReceived,
    sessionWallPostReceived,
    sessionWallPostUnlikeReceived,
    sessionWallPostUpdated,
    socketCustomRegFieldsUpdated,
    socketEventSettingUpdated,
    userEventRolesUpdate,
    usersConnectedToVideoStream,
    wallCommentUpdated,
    wallPostApproved,
    wallPostCommentApproved,
    wallPostUpdated,
};
