import React, {PureComponent} from 'react';
import Button from '@material-ui/core/Button';
import ChangePassword from '../../Dialogs/MyAccount/ChangePassword';
import axios from '../../store/axios-instance';
import {preventDefaultDrag} from '../../Utils/utils';
import {ReactComponent as UpdatePass} from '../../Images/svg/update_password.svg';

class ChangePasswordAccount extends PureComponent {
    state = {
        registrationType: '',
        changeUserPasswordDialog: false,
        changePasswordServerMessage: '',
    };

    componentDidMount() {
        this.getRegistrationType();
    }

    getRegistrationType = () => {
        axios({method: 'get', url: `/users/get-registration-type`})
            .then((response) => {
                this.setState({registrationType: response.data.registrationType});
            })
            .catch((error) => {});
    };

    handleChangePassword = () => {
        this.setState({changeUserPasswordDialog: true});
    };

    handleCloseDialog = (serverMessage) => {
        let message = '';
        if (serverMessage) {
            message = serverMessage;
        }
        this.setState({
            changeUserPasswordDialog: false,
            changePasswordServerMessage: message,
        });
    };

    render() {
        const {registrationType, changeUserPasswordDialog, changePasswordServerMessage} = this.state;
        const {translation} = this.props;
        return (
            <>
                {(registrationType === '' || registrationType === 'email') && (
                    <Button
                        onClick={this.handleChangePassword}
                        type="button"
                        className="change-password-btn"
                    >
                        <UpdatePass/>
                        <span>{translation?.myAccountPage.changePasswordButton}</span>
                    </Button>
                )}
                {changePasswordServerMessage ? (
                    <p onDragStart={preventDefaultDrag} className="server-message">
                        {changePasswordServerMessage ? changePasswordServerMessage : ''}
                    </p>
                ) : null}

                {changeUserPasswordDialog && (
                    <ChangePassword opened={changeUserPasswordDialog} closeDialog={this.handleCloseDialog} />
                )}
            </>
        );
    }
}

export default ChangePasswordAccount;
