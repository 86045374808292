import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import LockIcon from '@material-ui/icons/Lock';
import React from 'react';
import {connect} from 'react-redux';
import '../../CSS/networkAndLiveWall.scss';
import '../../CSS/networkAndLiveWallMobile.scss';
import Info from '../../HOC/Info';
import Networking from '../../HOC/Networking';
import Wall from '../../HOC/Wall/Wall';
import {preventDefaultDrag} from '../../Utils/utils';
import * as actions from '../../store/actions';
import MatchingBanners from '../EventManagement/Matching/MatchingBanners';
import MatchingDialog from '../EventManagement/Matching/MatchingDialog';
import MatchingDialogConfirm from '../EventManagement/Matching/MatchingDialogConfirm';

class SideMenuMobile extends React.Component {
    state = {
        alreadyShowed: false,
        openMatchingCloseDialog: false,
        bannerDisableOnFinish: false,
        seeMatchesSort: false,
    };

    componentDidMount() {
        this.getMatchingBannerShowed();
    }

    componentDidUpdate(prevProps) {
        const {event} = this.props;
        if (prevProps.event._id !== event._id) {
            this.getMatchingBannerShowed();
        }
        if (prevProps.event.hideLobbyNetworking !== this.props.event.hideLobbyNetworking) {
            if (this.props.event.hideLobbyNetworking && this.props.sideNavigation.seeNetworking) {
                this.props.handleSeeLiveWall();
            }
        }
    }

    openMatchingCloseDialog = () => {
        this.setState({openMatchingCloseDialog: !this.state.openMatchingCloseDialog});
    };
    handleRequestCloseMatchingBanner = () => {
        this.setState({bannerDisableOnFinish: true});
        this.props.onRequestCloseMatchingBanner(true);
    };
    closeMatchingModal = () => {
        this.setState({openMatchingCloseDialog: false});
        this.props.onToggleMatchingDialog(false);
    };
    openMatchingModal = () => {
        const {eventId, onGetQuestionnaires, onToggleMatchingDialog} = this.props;
        onGetQuestionnaires(eventId);
        onToggleMatchingDialog(true);
    };

    handleGetQuestionnaire = () => {
        const {eventId, onGetQuestionnaires} = this.props;
        onGetQuestionnaires(eventId);
    };

    getMatchingBannerShowed = () => {
        const {eventSlug, user} = this.props;
        const matchingBannerData = localStorage.getItem('matchingBannerData');
        if (matchingBannerData !== null) {
            const matchingBannerDataArray = JSON.parse(matchingBannerData);
            let data = matchingBannerDataArray
                ?.filter((bannerData) => bannerData.event === eventSlug && bannerData.userId === user?._id)
                ?.shift();
            if (data !== undefined && data['userId'] === user?._id) {
                this.setState({alreadyShowed: data['alreadyShowed']});
            }
        }
    };

    handleSeeNetworkingMatches = () => {
        this.setState(
            {
                seeMatchesSort: true,
            },
            () => {
                this.props.seeNetworking();
            }
        );
    };

    handleHideMatchesTab = () => {
        this.setState({
            seeMatchesSort: false,
        });
    };

    render() {
        const {alreadyShowed, openMatchingCloseDialog, bannerDisableOnFinish, seeMatchesSort} = this.state;
        const {
            event,
            eventRoles,
            resourcesAccess,
            handleSeeLiveWall,
            handleSeeVideoWall,
            handleSeeInfoTab,
            handleSeeNetworkingTab,
            translation,
            defaultTranslation,
            openMatchingDialog,
        } = this.props;

        let hasAccessToNetworking = true;
        if (event.hasAccessManagement && eventRoles.isParticipant && resourcesAccess && !resourcesAccess.networking) {
            hasAccessToNetworking = false;
        } else if (
            (event.exhibitorHasAccessManagement || event.scholarHasAccessManagement) &&
            eventRoles.isExhibitor &&
            resourcesAccess &&
            !resourcesAccess.networking
        ) {
            hasAccessToNetworking = false;
        }

        return (
            <>
                <div
                    onDragStart={preventDefaultDrag}
                    id="side-menu-mobile"
                    className="event-actions-container swipeable-drawer"
                >
                    {this.props.event && (
                        <div>
                            <Button
                                className="networking purple-background"
                                variant="contained"
                                onClick={handleSeeNetworkingTab}
                            >
                                {translation?.sideMenu.networking}
                            </Button>
                            <Button
                                className="live_wall green-background"
                                variant="contained"
                                onClick={handleSeeLiveWall}
                            >
                                {translation?.sideMenu.liveWall}
                            </Button>
                        </div>
                    )}
                </div>
                <div
                    onDragStart={preventDefaultDrag}
                    id="wall-and-network-mobile"
                    className="wall-and-networking-container open"
                >
                    <div>
                        <div onDragStart={preventDefaultDrag} className="tabs-container">
                            {this.props.sideNavigationDetails.hasVideoWall ? (
                                <>
                                    {!this.props.sideNavigation.seeLiveWall && (
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className="live-wall-tab button-tab tab"
                                            onClick={handleSeeLiveWall}
                                        >
                                            {translation?.sideMenu.liveWall}
                                        </div>
                                    )}
                                    {!this.props.sideNavigation.seeVideoWall && (
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className="video-wall-tab button-tab tab"
                                            onClick={handleSeeVideoWall}
                                        >
                                            {translation?.sideMenu.videoWall}
                                        </div>
                                    )}

                                    {!this.props.sideNavigation.seeNetworking && !event.hideLobbyNetworking && (
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className="networking-tab button-tab tab "
                                            onClick={handleSeeNetworkingTab}
                                        >
                                            {translation?.sideMenu?.matchMaking ||
                                                defaultTranslation?.sideMenu?.matchMaking}
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    {!event.hideLobbyNetworking && (
                                        <div
                                            className={
                                                'networking-tab button-tab tab ' +
                                                (this.props.sideNavigation.seeNetworking ? 'active' : '')
                                            }
                                            onClick={handleSeeNetworkingTab}
                                        >
                                            {!hasAccessToNetworking ? (
                                                <Tooltip title={translation?.sideMenu.networkingTooltip}>
                                                    <div
                                                        onDragStart={preventDefaultDrag}
                                                        className="restricted-network"
                                                    >
                                                        <LockIcon fontSize="small" />{' '}
                                                        {translation?.sideMenu?.matchMaking ||
                                                            defaultTranslation?.sideMenu?.matchMaking}
                                                    </div>
                                                </Tooltip>
                                            ) : (
                                                <>
                                                    {translation?.sideMenu?.matchMaking ||
                                                        defaultTranslation?.sideMenu?.matchMaking}
                                                </>
                                            )}
                                        </div>
                                    )}
                                    <div
                                        className={
                                            'live-wall-tab button-tab tab ' +
                                            (this.props.sideNavigation.seeLiveWall ? 'active' : '')
                                        }
                                        onClick={handleSeeLiveWall}
                                    >
                                        {translation?.sideMenu.liveWall}
                                    </div>
                                    {event.representatives.length > 0 && (
                                        <div
                                            className={
                                                'info-tab button-tab tab ' +
                                                (this.props.sideNavigation.seeInfoTab ? 'active' : '')
                                            }
                                            onClick={handleSeeInfoTab}
                                        >
                                            {translation?.sideMenu.info}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        <div>
                            {!alreadyShowed && (
                                <MatchingBanners
                                    mobile
                                    bannerDisableOnFinish={bannerDisableOnFinish}
                                    requestCloseBanner={event?.requestCloseBanner}
                                    openMatchingModal={this.openMatchingModal}
                                />
                            )}
                            {this.props.sideNavigation.seeLiveWall ? <Wall isLiveWall /> : null}
                            {this.props.sideNavigation.seeNetworking && (
                                <Networking
                                    openMatchingDialog={openMatchingDialog}
                                    seeMatches={seeMatchesSort}
                                    handleHideMatchesTab={this.handleHideMatchesTab}
                                />
                            )}
                            {this.props.sideNavigation.seeInfoTab && <Info />}
                        </div>
                    </div>
                    {event?.matchingDialog && (
                        <MatchingDialog
                            menuMobile
                            open={event?.matchingDialog}
                            close={this.openMatchingCloseDialog}
                            closeNoPrompt={this.closeMatchingModal}
                            closeBannerOnFinish={this.handleRequestCloseMatchingBanner}
                            handleSeeNetworking={this.handleSeeNetworkingMatches}
                        />
                    )}
                    {openMatchingCloseDialog && (
                        <MatchingDialogConfirm
                            hasCloseButton
                            open={openMatchingCloseDialog}
                            dialogTitle={
                                translation?.matching?.closeMatchingTitle ||
                                defaultTranslation?.matching?.closeMatchingTitle
                            }
                            dialogDescription={`${
                                translation?.matching?.closeMatchingTextA ||
                                defaultTranslation?.matching?.closeMatchingTextA
                            } <br><br> ${
                                translation?.matching?.closeMatchingTextB ||
                                defaultTranslation?.matching?.closeMatchingTextB
                            }`}
                            cancelBtnLabel={translation?.generalText?.cancel || defaultTranslation?.generalText?.cancel}
                            confirmBtnLabel={translation?.generalText?.close || defaultTranslation?.generalText?.close}
                            closeConfirm={this.openMatchingCloseDialog}
                            handleConfirm={this.closeMatchingModal}
                        />
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        eventUsers: state.eventUsers,
        user: state.user.data,
        sideNavigation: state.user.sideNavigation,
        sideNavigationDetails: state.event.sideNavigationDetails,
        eventRoles: state.user.eventRoles,
        resourcesAccess: state.user.resourcesAccess,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        seeNetworking: () => dispatch(actions.sideNavSeeNetworking()),
        seeLiveWall: () => dispatch(actions.sideNavSeeLiveWall()),
        seeVideoWall: () => dispatch(actions.sideNavSeeVideoWall()),
        closeSidebar: () => dispatch(actions.sideNavClose()),
        setActiveWall: (wallData) => dispatch(actions.setActiveWall(wallData)),
        seeInfoTab: () => dispatch(actions.sideNavSeeInfoTab()),
        onGetQuestionnaires: (eventId) => dispatch(actions.getQuestionnaires(eventId)),
        onToggleMatchingDialog: (bool) => dispatch(actions.toggleMatchingDialog(bool)),
        onRequestCloseMatchingBanner: (bool) => dispatch(actions.requestCloseMatchingBanner(bool)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenuMobile);
