import React from "react";
import Wall from "../../HOC/Wall/Wall";
import AuditorumsSlider from "../Auditorium/AuditoriumsSlider";
import AuditoriumBackgroundImage from "../../SmallLayoutComponents/AuditoriumBackgroundImage";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import {
	checkIfEventHasEnded,
	checkIfAuditoriumProgramHasEnded,
	getUtcTimestamp,
	getTimeslotStartTimestamp,
	getTimeslotEndTimestamp,
	preventDefaultDrag,
} from "../../Utils/utils";
import "../../CSS/auditorium.scss";
import NextVideoTimer from "../Auditorium//NextVideoTimer";
import AuditoriumVideoPlayer from "../Auditorium/AuditoriumVideoPlayer";
import AuditoriumBanners from "../Auditorium/AuditoriumBanners";
import AuditoriumNavigation from "../Auditorium/AuditoriumNavigation";
import AuditoriumNavigationMobile from "../Auditorium/AuditoriumNavigationMobile";
import AuditoriumSlotTitle from "../Auditorium/AuditoriumSlotTitle";
import AuditoriumSnackbar from "../Auditorium/AuditoriumSnackbar";
import LockIcon from "@material-ui/icons/Lock";
import { AuditorumPollsOutput } from "../../Components/AuditorumPollsOutput";
import BreadcrumbsNavigation from "../../Components/BreadcrumbsNavigation";
import NoBuildingAccess from "./NoBuildingAccess";

class BuildingAuditorium extends React.Component {
	eventHasEndedTimerId = 0;

	state = {
		auditoriumRoomIndex: 0,
		auditoriumTimeslots: [],
		currentTimeSlotIndex: -1,
		videoStreamingUrl: "",
		viewVideoWall: false,
		totalSecondsTillNextVideo: null,
		totalSecondsTillCurrentTimeSlotEnds: null,
		checkedVideoDuration: false,
		auditoriumProgramHasEnded: false,
		auditoriumHasVideos: true,
		currentMobileTab: "video",
		currentTab: "wall",
	};

	componentDidMount() {
		const auditoriumsLength = this.props.event.auditoriums.length;
		const auditoriumIndexURL = +this.props.match.params.auditoriumIndex;
		// if the URL has an auditorium/${index} that matches the length of the current event
		// auditoriums (starting from 0), load and set the auditorium from URL
		// in localStorage, state and URL then
		if (
			auditoriumIndexURL !== undefined &&
			!isNaN(auditoriumIndexURL) &&
			auditoriumIndexURL >= 0 &&
			auditoriumIndexURL <= auditoriumsLength - 1
		) {
			this.setActiveAuditoriumIndex(auditoriumIndexURL)();
		} else {
			this.setAuditoriumTimeslots();
		}

		this.props.onSetEventProtectedMenu();
		// check if event has ended and also set up a timer that checks every 10 seconds if the event has ended
		this.checkIfBuildingHasEnded();
		this.startEventHasEndedCountdown();
		this.props.closeTopNavigation();
		window.addEventListener("beforeunload", this.clearEventHasEndedCountdown);

		// window.addEventListener("blur", this.tabBlured);
	}

	componentWillUnmount() {
		this.clearEventHasEndedCountdown();
		this.props.closeSidebar();
		window.removeEventListener(
			"beforeunload",
			this.clearEventHasEndedCountdown,
		);
		// window.removeEventListener("focus", this.loadCurrentTimeSlot);
		// window.removeEventListener("blur", this.tabBlured);
	}

	tabBlured = () => {
		// if the user moves to another tab, we clear all the countdowns
		this.clearEventHasEndedCountdown();
		// only if he leaves the tab we want to liste the 'focus' on tab event
		// if we liste to 'focus' in componentDidMount we fire the loadCurrentTimeSlot function 2 times and all the countdowns speed 2x
		// if the user moves to another tab, we have to trigger the loadCurrentTimeslot function when he focuses back to it
		// the loadCurrentTimeslot function also reRegisteres all the countodowns
		window.addEventListener("focus", this.loadCurrentTimeSlot);
	};

	// fallback for last time slots without video
	// once every 10 seconds we check if the event has ended
	startEventHasEndedCountdown = () => {
		this.eventHasEndedTimerId = setInterval(
			this.checkIfBuildingHasEnded,
			10000,
		);
	};

	clearEventHasEndedCountdown = () => clearInterval(this.eventHasEndedTimerId);

	setAuditoriumTimeslots = () => {
		// this function will run at componentDidMount or when the user navigates through auditoriums
		// create an array with all timeslots of the current auditorium
		// make sure to also add to each timeslot the day when it runs

		const { building } = this.props;

		const currentAuditorium =
			building.auditoriums[this.state.auditoriumRoomIndex];

		let auditoriumTimeslots = [];
		currentAuditorium.dailyProgram.forEach((dayProgram) => {
			const currentProgramDay = dayProgram.date;
			dayProgram.program.forEach((timeslot) => {
				timeslot.date = currentProgramDay;
				auditoriumTimeslots.push(timeslot);
			});
		});

		// after we have set up the timeslots for the auditorium, we trigger the loadCurrentTimeslot
		// so that we make sure we change the current timeslot properly

		this.setState(
			{
				auditoriumTimeslots: auditoriumTimeslots,
			},
			() => {
				this.loadCurrentTimeSlot();
			},
		);
	};

	checkIfBuildingHasEnded = () => {
		// check if event has ended
		// if yes, redirect to the video archive page
		const { event, building, buildingId } = this.props;
		let buildingHasEnded = checkIfEventHasEnded(building, event.timezoneValue);
		if (buildingHasEnded) {
			this.clearEventHasEndedCountdown();
			this.props.history.push(
				`/event/${this.props.eventSlug}/village/building/${buildingId}/auditorium-archive`,
			);
		}
	};

	checkIfAuditoriumProgramHasEnded = () => {
		this.setState({ auditoriumProgramHasEnded: false }, () => {
			let auditoriumProgramHasEnded = checkIfAuditoriumProgramHasEnded(
				this.props.building,
				this.props.event.timezoneValue,
				this.state.auditoriumRoomIndex,
			);
			if (auditoriumProgramHasEnded) {
				this.setState({ auditoriumProgramHasEnded: true });
			}
		});
	};

	checkIfAuditoriumHasVideos = () => {
		const { building } = this.props;
		const currentAuditorium =
			building.auditoriums[this.state.auditoriumRoomIndex];
		let allAuditoriumTimeslots = [];

		// we create an array with all the timeslots (for each day, we push the timeslots to allAuditoriumTimeslots)
		// if at least one timeslot has Videos, it means that the auditoriumHasVideos = true
		currentAuditorium.dailyProgram.forEach((dayProgram) =>
			allAuditoriumTimeslots.push(...dayProgram.program),
		);
		const programHasVideo = allAuditoriumTimeslots.filter((timeslot) => {
			return timeslot.video || !!timeslot.session;
		});
		this.setState({ auditoriumHasVideos: programHasVideo.length > 0 });
	};

	loadCurrentTimeSlot = (videoDuration) => {
		this.checkIfBuildingHasEnded();
		this.checkIfAuditoriumProgramHasEnded();
		this.checkIfAuditoriumHasVideos();
		let currentTime = new Date();
		let currentTimeStamp = getUtcTimestamp(currentTime);

		const { auditoriumTimeslots } = this.state;

		// first we check if we have a current running video
		// save the index in the program
		let currentTimeSlotIndex = auditoriumTimeslots.findIndex((timeSlot) => {
			let timeSlotStartTimestamp = getTimeslotStartTimestamp(
				timeSlot.date,
				timeSlot,
				this.props.event.timezoneValue,
			);
			let timeSlotEndTimestamp = getTimeslotEndTimestamp(
				timeSlot.date,
				timeSlot,
				this.props.event.timezoneValue,
			);

			if (
				currentTimeStamp >= timeSlotStartTimestamp &&
				currentTimeStamp < timeSlotEndTimestamp
			) {
				// if this current Timeslot has video, we set up the video Url
				if (timeSlot.video && !timeSlot.isLiveStreaming) {
					// let milisecondsFromStart = currentTimeStamp - timeSlotStartTimestamp;
					// let secondsFromStart = Math.floor(milisecondsFromStart / 1000);
					// let videoUrl = timeSlot.video + '?t=' + secondsFromStart;
					// this.setState({videoStreamingUrl: videoUrl});
					let videoUrl = timeSlot.video;
					this.setState({ videoStreamingUrl: videoUrl });
				} else if (timeSlot.video && timeSlot.isLiveStreaming) {
					let videoUrl = timeSlot.video;
					this.setState({ videoStreamingUrl: videoUrl });
				}
				return timeSlot;
			}

			return null;
		});

		if (currentTimeSlotIndex !== -1) {
			const timeSlot = auditoriumTimeslots[currentTimeSlotIndex];
			// we set up a timer for the snackbar that will open 10, 5, 2 minutes before the timeslotEnds
			// totalSecondsTillCurrentTimeSlotEnds will be used in AuditoriumSnackbar
			let timeSlotEndTimestamp = getTimeslotEndTimestamp(
				timeSlot.date,
				timeSlot,
				this.props.event.timezoneValue,
			);
			let videoWillEndInNextSeconds = timeSlotEndTimestamp - currentTimeStamp;

			videoWillEndInNextSeconds = Math.floor(videoWillEndInNextSeconds / 1000);
			this.setState({
				totalSecondsTillCurrentTimeSlotEnds: videoWillEndInNextSeconds,
			});
		} else {
			this.setState({ totalSecondsTillCurrentTimeSlotEnds: null });
		}
		// if we don't have an active timeslot at this time, search if we have a next timeslot to set up the timer
		// we also display the timer if the current timeslot has no video added
		if (
			currentTimeSlotIndex === -1 ||
			!auditoriumTimeslots[currentTimeSlotIndex].video
		) {
			auditoriumTimeslots.forEach((timeSlot) => {
				let timeSlotStartTimestamp = getTimeslotStartTimestamp(
					timeSlot.date,
					timeSlot,
					this.props.event.timezoneValue,
				);

				if (
					currentTimeStamp < timeSlotStartTimestamp &&
					(timeSlot.video || timeSlot.imageUrl || !!timeSlot.session)
				) {
					let nextVideoWillStartInNextSeconds =
						timeSlotStartTimestamp - currentTimeStamp;
					nextVideoWillStartInNextSeconds =
						nextVideoWillStartInNextSeconds / 1000;

					this.setState({
						totalSecondsTillNextVideo: nextVideoWillStartInNextSeconds,
					});

					// the showLiveWall is not used, we modified and when we don't have a video timeslot
					// we don't show the Wall at all
					this.showLiveWall();
					return timeSlot;
				}

				return null;
			});
		} else {
			// if we have a time slot currently running
			// we set up the sideMenu to have videoWall
			this.props.setHasVideoWall(true);
			this.setState({ videoEnded: false });

			// we set up the current time slot video Id
			const timeSlot = auditoriumTimeslots[currentTimeSlotIndex];
			const activeVideoWallId = timeSlot.videoWall;
			// we save in state the current user Id's we need to highlight in the wall posts
			let highlightUsers = [];
			timeSlot.speakers.forEach((speaker) => {
				highlightUsers.push(speaker.user._id);
			});

			// we set up the current wall Id to be the video Wall Id
			const wallData = {
				wallId: activeVideoWallId,
				highlightUsers: highlightUsers,
			};
			this.props.setTimeSlotVideoWall(wallData);
			this.props.setActiveWall(wallData);

			// we open the sideBar to the videoWall
			this.props.seeVideoWall();
		}

		this.setState({ currentTimeSlotIndex: currentTimeSlotIndex });
	};

	showLiveWall = () => {
		// the showLiveWall is not used, we modified and when we don't have a video timeslot
		// we don't show the Wall at all
		this.props.setHasVideoWall(false);
		this.props.seeLiveWall(true);

		const wallData = {
			wallId: this.props.event.liveWallId,
			highlightUsers: [],
		};

		this.props.setActiveWall(wallData);
	};

	setActiveAuditoriumIndex = (auditoriumIndex) => () => {
		const { history, eventSlug } = this.props;
		this.props.closeSidebar();
		this.setState(
			{ auditoriumRoomIndex: auditoriumIndex, currentTimeSlotIndex: -1 },
			() => {
				this.setAuditoriumTimeslots();
				history.push(`/event/${eventSlug}/auditorium/${auditoriumIndex}`);
			},
		);
	};

	handleChangeTab = (tabName) => {
		this.setState({
			currentTab: tabName,
		});
	};

	handleChangeMobileTab = (tabName) => {
		this.setState({
			currentMobileTab: tabName,
		});

		// commented out because now we always play the video in the background (we hide it using css)
		// we don't need to reload it every time

		// if(tabName === 'video'){
		// we need to recalculate everything, because we don't know how much time the user
		// was on the wall
		// we always have to recalculate how many seconds from the start have passed for the video

		// this.loadCurrentTimeSlot();
		// }
	};

	render() {
		const {
			event,
			eventRoles,
			resourcesAccess,
			isLargeScreen,
			building,
			buildingId,
		} = this.props;
		const auditoriums = building.auditoriums;
		const {
			auditoriumTimeslots,
			currentTimeSlotIndex,
			auditoriumRoomIndex,
			totalSecondsTillCurrentTimeSlotEnds,
			totalSecondsTillNextVideo,
			auditoriumProgramHasEnded,
			currentMobileTab,
			currentTab,
		} = this.state;

		if (
			event.hasAccessManagement &&
			eventRoles.isParticipant &&
			resourcesAccess !== null &&
			!resourcesAccess.buildings.includes(building._id)
		) {
			return <NoBuildingAccess />;
		}

		let hasAccessToCurrentAuditorium = true;

		if (
			event.hasAccessManagement &&
			eventRoles.isParticipant &&
			resourcesAccess
		) {
			hasAccessToCurrentAuditorium =
				resourcesAccess.buildings.includes(buildingId);
		}

		const hasVideoWall =
			currentTimeSlotIndex !== -1 &&
			auditoriumTimeslots[currentTimeSlotIndex].video &&
			hasAccessToCurrentAuditorium;

		const showWall =
			(hasVideoWall && isLargeScreen) ||
			(hasVideoWall && !isLargeScreen && currentMobileTab === "wall");
		const hideVideo = !isLargeScreen && currentMobileTab === "wall";

		return (
			<div
				onDragStart={preventDefaultDrag}
				className="position-background auditorium-page-wrapper"
			>
				<BreadcrumbsNavigation className={"white"} />
				{totalSecondsTillCurrentTimeSlotEnds &&
					hasAccessToCurrentAuditorium && (
						<AuditoriumSnackbar
							totalSecondsTillCurrentTimeSlotEnds={
								totalSecondsTillCurrentTimeSlotEnds
							}
							loadCurrentTimeSlot={this.loadCurrentTimeSlot}
							currentTimeSlotIndex={currentTimeSlotIndex}
						/>
					)}
				<div
					onDragStart={preventDefaultDrag}
					id="js-auditorium-page"
					className="auditorium-page mobile-page-container"
				>
					{!isLargeScreen && (
						<div onDragStart={preventDefaultDrag} className="page-title">
							Auditorium
						</div>
					)}

					{!isLargeScreen && (
						<div onDragStart={preventDefaultDrag} className="tabs-container">
							<div
								className={`button-tab tab ${
									currentMobileTab === "video" ? "active" : ""
								}`}
								onClick={() => this.handleChangeMobileTab("video")}
							>
								STAGE
							</div>
							{hasVideoWall && (
								<>
									<div
										className={`button-tab tab ${
											currentMobileTab === "wall" && currentTab === "wall"
												? "active"
												: ""
										}`}
										onClick={() => {
											this.handleChangeMobileTab("wall");
											this.handleChangeTab("wall");
										}}
									>
										VIDEO WALL
									</div>
									<div
										className={`button-tab tab ${
											currentMobileTab === "wall" && currentTab === "polls"
												? "active"
												: ""
										}`}
										onClick={() => {
											this.handleChangeMobileTab("wall");
											this.handleChangeTab("polls");
										}}
									>
										POLLS
									</div>
								</>
							)}
						</div>
					)}

					{/*SHOW BACKGROUND IMAGE ONLY ON LARGE SCREENS*/}

					{hasAccessToCurrentAuditorium &&
						currentTimeSlotIndex !== -1 &&
						isLargeScreen && (
							<AuditoriumSlotTitle auditoriumRoomIndex={auditoriumRoomIndex} />
						)}

					{auditoriums.length > 1 && (
						<AuditorumsSlider
							auditoriums={auditoriums}
							activeAuditoriumIndex={auditoriumRoomIndex}
							onSetActiveAuditoriumIndex={this.setActiveAuditoriumIndex}
						/>
					)}

					{showWall && (
						<div
							onDragStart={preventDefaultDrag}
							className="booth-wall-container"
						>
							<div
								onDragStart={preventDefaultDrag}
								className="booth-wall-header"
							>
								<span
									onClick={() => {
										this.handleChangeTab("wall");
									}}
									className={
										this.state.currentTab === "wall" ? "-is-active" : ""
									}
								>
									Video Wall
								</span>
								{/* {auditoriumTimeslots[currentTimeSlotIndex].polls.length > 0 && ( */}
								<span
									onClick={() => {
										this.handleChangeTab("polls");
									}}
									className={
										this.state.currentTab === "polls" ? "-is-active" : ""
									}
								>
									polls
								</span>
							</div>
							{this.state.currentTab === "wall" && <Wall isVideoWall />}
							{this.state.currentTab === "polls" && (
								<AuditorumPollsOutput
									auditoriumId={auditoriums[auditoriumRoomIndex]._id}
									programId={auditoriumTimeslots[currentTimeSlotIndex]._id}
								/>
							)}
						</div>
					)}

					<div
						onDragStart={preventDefaultDrag}
						className={`centerOfPage ${hideVideo ? "hide" : ""}`}
					>
						<div onDragStart={preventDefaultDrag} className="relativeCenter">
							<AuditoriumBanners
								filesUrl={event.brandingData.filesUrl}
								brandingData={building}
							/>
							{auditoriums.length > 1 &&
								isLargeScreen &&
								!(
									currentTimeSlotIndex !== -1 &&
									auditoriumTimeslots[currentTimeSlotIndex]
								) && (
									<AuditoriumNavigation
										currentTimeSlotIndex={currentTimeSlotIndex}
										auditoriumTimeslots={auditoriumTimeslots}
										setActiveAuditoriumIndex={this.setActiveAuditoriumIndex}
										auditoriums={auditoriums}
										auditoriumRoomIndex={auditoriumRoomIndex}
									/>
								)}
							{hasAccessToCurrentAuditorium ? (
								<>
									{currentTimeSlotIndex !== -1 &&
									auditoriumTimeslots[currentTimeSlotIndex] ? (
										<AuditoriumVideoPlayer
											auditoriumTimeslots={auditoriumTimeslots}
											currentTimeSlotIndex={currentTimeSlotIndex}
											videoStreamingUrl={this.state.videoStreamingUrl}
											auditoriumRoomIndex={auditoriumRoomIndex}
											setActiveAuditoriumIndex={this.setActiveAuditoriumIndex}
										/>
									) : (
										<div
											onDragStart={preventDefaultDrag}
											className="next-video"
										>
											<div
												onDragStart={preventDefaultDrag}
												className="next-video-container"
											>
												{currentTimeSlotIndex !== -1 &&
												auditoriumTimeslots[currentTimeSlotIndex] &&
												auditoriumTimeslots[currentTimeSlotIndex].imageUrl ? (
													<div
														onDragStart={preventDefaultDrag}
														className="timeslot-background-image"
													>
														<img
															src={
																auditoriumTimeslots[currentTimeSlotIndex]
																	.imageUrl
															}
															alt="current program"
														/>
													</div>
												) : (
													<>
														{auditoriumProgramHasEnded ? (
															<p
																onDragStart={preventDefaultDrag}
																className="auditorium-program-ended-text"
															>
																No videos are scheduled to play in this
																Auditorium.
															</p>
														) : (
															<>
																{auditoriumTimeslots.length > 0 &&
																this.state.auditoriumHasVideos ? (
																	<p>
																		{currentTimeSlotIndex ===
																		auditoriumTimeslots.length - 1 ? (
																			<span>
																				{" "}
																				No videos are scheduled to play in this
																				Auditorium.
																			</span>
																		) : (
																			<NextVideoTimer
																				totalSecondsTillNextVideo={
																					totalSecondsTillNextVideo
																				}
																				loadCurrentTimeSlot={
																					this.loadCurrentTimeSlot
																				}
																			/>
																		)}
																	</p>
																) : (
																	<p>
																		<span>
																			No videos are scheduled to play in this
																			Auditorium.
																		</span>
																	</p>
																)}
															</>
														)}
													</>
												)}
											</div>
										</div>
									)}
								</>
							) : (
								<div onDragStart={preventDefaultDrag} className="next-video">
									<div
										onDragStart={preventDefaultDrag}
										className="next-video-container restricted"
									>
										<p>
											<span>
												Your access package doesn't have access to this
												auditorium.
											</span>
											<LockIcon />
										</p>
									</div>
								</div>
							)}
							{!isLargeScreen && (
								<div
									onDragStart={preventDefaultDrag}
									className="slot-mobile-details"
								>
									<AuditoriumSlotTitle
										auditoriumRoomIndex={auditoriumRoomIndex}
									/>
									{auditoriums.length > 1 && !isLargeScreen && (
										<AuditoriumNavigationMobile
											currentTimeSlotIndex={currentTimeSlotIndex}
											auditoriumTimeslots={auditoriumTimeslots}
											setActiveAuditoriumIndex={this.setActiveAuditoriumIndex}
											auditoriums={auditoriums}
											auditoriumRoomIndex={auditoriumRoomIndex}
											timezoneName={event.timezoneName}
										/>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		eventId: state.event.eventId,
		eventSlug: state.event.eventSlug,
		event: state.event.data,
		user: state.user.data,
		eventRoles: state.user.eventRoles,
		resourcesAccess: state.user.resourcesAccess,
		isMobile: state.layout.isMobile,
		isLargeScreen: state.layout.isLargeScreen,
		buildingId: state.building.buildingId,
		building: state.building.data,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeSidebar: () => dispatch(actions.sideNavClose()),
		closeTopNavigation: () => dispatch(actions.topNavClose()),
		setHasVideoWall: (booleanValue) =>
			dispatch(actions.sideNavHasVideoWall(booleanValue)),
		setTimeSlotVideoWall: (wallId) =>
			dispatch(actions.setTimeSlotVideoWall(wallId)),
		setActiveWall: (wallData) => dispatch(actions.setActiveWall(wallData)),
		seeVideoWall: () => dispatch(actions.sideNavSeeVideoWall()),
		seeLiveWall: () => dispatch(actions.sideNavSeeLiveWall()),
		onSetEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildingAuditorium);
