import MomentUtils from '@date-io/moment';
import {ThemeProvider} from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import moment from 'moment';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
// import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import './CSS/rtlLanguages.scss';
import './index.scss';
import themeObj from './materialUITheme';

import * as serviceWorker from './serviceWorker';

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';

import store from './store/store';

// load sentry if enabled
if (JSON.parse(process.env.REACT_APP_ENABLE_SENTRY) === true) {
    import('./sentry.config').then(({initaliseSentry}) => initaliseSentry());
}

if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
        if (child.parentNode !== this) {
            if (console) {
                console.error('Cannot remove a child from a different parent', child, this);
            }
            return child;
        }
        return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
        if (referenceNode && referenceNode.parentNode !== this) {
            if (console) {
                console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
            }
            return newNode;
        }
        return originalInsertBefore.apply(this, arguments);
    };
}

const app = (
    <Provider store={store}>
        <ThemeProvider theme={themeObj}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.unregister();

// reportWebVitals();
