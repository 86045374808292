export const isLinkRule = new RegExp(
    '^(http|ftp|https):\\/\\/[\\w\\-_]+(\\.[\\w\\-_]+)+([\\w\\-\\.,@?^=%&amp;:/~\\+#]*[\\w\\-\\@?^=%&amp;/~\\+#])?\\S*$',
    'i'
);

export const isFacebookLinkRule = new RegExp('^(http|https)://(www.)?facebook.com/\\S*$', 'i');

export const isLinkedinLinkRule = new RegExp('^(http|https)://(www.)?linkedin.com/\\S*$', 'i');

export const isTwitterLinkRule = new RegExp('^(http|https)://(www.)?twitter.com/\\S*$', 'i');

export const isGoogleScholarLinkRule = new RegExp('^(http|https)://(www.)?scholar.google.com/\\S*$', 'i');

export const isResearchGateLinkRule = new RegExp('^(http|https)://(www.)?researchgate.net/\\S*$', 'i');

export const isYoutubeLinkRule = new RegExp('^(http(s)?://)?((w){3}.)?youtu(be|.be)?(.com)?/\\S*$', 'i');

export const isVimeoLinkRule = new RegExp('^https://vimeo.com/((?!event).)\\S*$', 'i');

export const isYoutubeOrVimeoLinkRule = new RegExp(isYoutubeLinkRule.source + '|' + isVimeoLinkRule.source);

export const isWistiaLinkRule = new RegExp(/https:\/\/[A-Za-z0-9_-]+\.(wistia\.com|wi\.st)\/(medias|embed)\/\S*$.*/, 'i');

export const isM3U8Rule = new RegExp('.m3u8$', 'i');

export const specialCharactersString = '`~!@#$%^*()_|+=?;,.<>{}[]\\"/';

export const atLeastOneLetter = new RegExp('(?=.*?[A-Za-z]).+');

export const isDomainValid = new RegExp('^(?!\\-)(?:(?:[a-zA-Z\\d][a-zA-Z\\d\\-]{0,61})?[a-zA-Z\\d]\\.){1,126}(?!\\d+)[a-zA-Z\\d]{1,63}$');

export const isEmailValid = new RegExp('^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');

export const isPhoneValidRule = new RegExp('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$');