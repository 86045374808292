import React from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import * as actions from '../../../store/actions';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import MasterAdminNavigation from '../MasterAdminNavigation';
import AdminAccordionNavigation from '../AdminAccordionNavigation';
import '../../../CSS/adminDashboard.scss';
import Spinner from '../../../SmallLayoutComponents/Spinner';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';
import {preventDefaultDrag} from '../../../Utils/utils';
import SortMenu from './SortMenu';
import ReactExport from 'react-export-excel';
import cloneDeep from 'lodash/cloneDeep';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class EventsToday extends React.Component {
    state = {
        currentPage: 1,
        tabsList: [
            {name: 'organizersList', label: 'Organizers list', link: '/dashboard/organizers'},
            {name: 'statistics', label: 'Statistics', link: '/dashboard/organizers/statistics'},
            {name: 'allEvents', label: 'All events', link: '/dashboard/organizers/all-events'},
            {name: 'eventsToday', label: 'Events today', link: '/dashboard/organizers/events-today'},
        ],
        selectedTab: 'eventsToday',
        searchValue: '',
        eventsTodayList: null,
        filteredEventsToday: null,
        dataExcel: null,
    };

    componentDidMount() {
        this.props.onSetAdminMenu();
        this.getEventsTodayData();
    }

    getEventsTodayData = () => {
        this.props.onGetEventsToday().then(() => {
            let downloadData = [];
            this.props.eventsToday.forEach((event) => {
                downloadData.push({
                    name: event.name,
                    eventStartDate: event.eventStartDate,
                    eventEndDate: event.eventEndDate,
                    eventOrganizer: event.owner.first + event.owner.last,
                    slug: event.slug,
                    participantsCount: event.participantsCount,
                    exhibitorsCount: event.exhibitorsCount
                });
            });
            this.setState({
                eventsTodayList: this.props.eventsToday,
                filteredEventsToday: this.props.eventsToday,
                dataExcel: downloadData
            });
        });
    };

    refreshEventsTodayData = () => {
        this.getEventsTodayData();
    };

    returnAllEventsList = () => {
        const {eventsTodayList} = this.state;
        return eventsTodayList?.map((event) => {
            return (
                <div onDragStart={(e) => e.preventDefault()} className="single-organizer-container" key={event._id}>
                    <div onDragStart={(e) => e.preventDefault()} className="row single-organizer-row">
                        <div onDragStart={(e) => e.preventDefault()} className="event-name-header">
                            {event.name}
                        </div>
                        <div onDragStart={(e) => e.preventDefault()} className="event-date-header">
                            {event.eventStartDate}
                        </div>
                        <div onDragStart={(e) => e.preventDefault()} className="event-date-header">
                            {event.eventEndDate}
                        </div>
                        <div onDragStart={(e) => e.preventDefault()} className="event-organizer-header">
                            {event.owner.first + ' ' + event.owner.last}
                        </div>
                        <div onDragStart={(e) => e.preventDefault()} className="event-organizer-header">
                            <Link to={{pathname: `/event/${event.slug}`}} target="_blank" rel="noopener noreferrer">
                                {`/${event.slug}`}
                            </Link>
                        </div>
                        <div onDragStart={(e) => e.preventDefault()} className="event-no-participants-header">
                            {event.participantsCount}
                        </div>
                        <div onDragStart={(e) => e.preventDefault()} className="event-no-exhibitors-header">
                            {event?.exhibitorsCount}
                        </div>
                    </div>
                </div>
            );
        });
    };

    sortEventsToday = (sortBy, order) => {
        const {eventsTodayList} = this.state;
        let sortedArray;
        if (order === 'asc') {
            sortedArray = cloneDeep(eventsTodayList).sort((a, b) =>
                a[sortBy] > b[sortBy] ? 1 : -1
            );
        } else {
            sortedArray = cloneDeep(eventsTodayList).sort((a, b) =>
                a[sortBy] < b[sortBy] ? 1 : -1
            );
        }
        this.setState({
            eventsTodayList: sortedArray,
        });
    };

    filterOrganizersOnSearch = () => {
        const {filteredEventsToday} = this.state;
        let newArrayOfFilteredEvents = [...filteredEventsToday];
        if (this.state.searchValue.length > 2) {
            // in the search input we look into first name, last name and email
            newArrayOfFilteredEvents = filteredEventsToday.filter((event) => {
                return (
                    event.name.toLowerCase().includes(this.state.searchValue.toLowerCase()) ||
                    event.owner.first.toLowerCase().includes(this.state.searchValue.toLowerCase()) ||
                    event.owner.last.toLowerCase().includes(this.state.searchValue.toLowerCase()) ||
                    event.slug.toLowerCase().includes(this.state.searchValue.toLowerCase())
                );
            });
            this.setState({
                eventsTodayList: newArrayOfFilteredEvents,
            });
        } else {
            this.setState({
                eventsTodayList: [...newArrayOfFilteredEvents],
            });
        }
    };

    onSearchChange = (e) => {
        this.setState(
            {
                searchValue: e.target.value,
            },
            () => {
                if (this.state.searchValue.length > 2 || this.state.searchValue.length === 0) {
                    this.filterOrganizersOnSearch();
                }
            }
        );
    };

    resetSearchInput = () => {
        const {filteredEventsToday} = this.state;
        this.setState({
            searchValue: '',
            eventsTodayList: [...filteredEventsToday],
        });
    };

    render() {
        const {tabsList, selectedTab, searchValue, eventsTodayList, dataExcel} = this.state;
        const {loading} = this.props;

        return (
            <div onDragStart={preventDefaultDrag} className="superadmin-dashboard">
                <MasterAdminNavigation />
                <section className="top-section">
                    <div onDragStart={preventDefaultDrag} className="admin-dashboard">
                        <div className="admin-sidebar">
                            <AdminAccordionNavigation tabsList={tabsList} selectedTab={selectedTab} />
                        </div>
                        <div className={`admin-content-container ${loading ? 'loading-section' : ''}`}>
                            <div className="all-events-container">
                                <div className="admin-download-header">
                                    <h1>Events Today</h1>
                                </div>
                                <div className="admin-download-header">
                                    <div className="search-wrapper">
                                        <TextField
                                            label="Search for event name, organizer, slug..."
                                            type="text"
                                            value={searchValue}
                                            onChange={(e) => {
                                                this.onSearchChange(e);
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton
                                                        disableRipple
                                                        disableTouchRipple
                                                        disableFocusRipple
                                                        onClick={this.resetSearchInput}
                                                    >
                                                        <ClearIcon
                                                            classes={{
                                                                root:
                                                                    searchValue.length > 0
                                                                        ? 'dark-color'
                                                                        : 'grey-color',
                                                            }}
                                                        />
                                                    </IconButton>
                                                ),
                                            }}
                                            fullWidth={true}
                                            variant={'outlined'}
                                        />
                                    </div>
                                    <div onDragStart={preventDefaultDrag} className="d-flex">
                                        <ExcelFile
                                            filename="EventsToday"
                                            element={
                                                <div
                                                    onDragStart={preventDefaultDrag}
                                                    className="add-button-container"
                                                >
                                                    <GetAppIcon />
                                                    <span>DOWNLOAD</span>
                                                </div>
                                            }
                                        >
                                            <ExcelSheet data={dataExcel} name="EventsToday">
                                                <ExcelColumn label="Event name" value="name" />
                                                <ExcelColumn label="Event start date" value="eventStartDate" />
                                                <ExcelColumn label="Event end date" value="eventEndDate" />
                                                <ExcelColumn label="Event organizer" value="eventOrganizer" />
                                                <ExcelColumn label="Event slug" value="slug" />
                                                <ExcelColumn label="No. of participants" value="participantsCount" />
                                                <ExcelColumn label="No. of booths" value="exhibitorsCount" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className="add-button-container"
                                            onClick={this.refreshEventsTodayData}
                                        >
                                            <RefreshIcon />
                                            <span>REFRESH</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="all-events-list">
                                    <div onDragStart={preventDefaultDrag} className="organizers-list">
                                        <div onDragStart={preventDefaultDrag} className="organizers-list-header">
                                            <div onDragStart={preventDefaultDrag} className="event-name-header">
                                                Event Name
                                            </div>
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="label-image event-date-header"
                                            >
                                                <SortMenu
                                                    text={'Event Start Date'}
                                                    image
                                                    arrowDown
                                                    sortBy="eventStartDate"
                                                    sortArray={this.sortEventsToday}
                                                />
                                            </div>
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="label-image event-date-header"
                                            >
                                                <SortMenu
                                                    text={'Event End Date'}
                                                    image
                                                    arrowDown
                                                    sortBy="eventEndDate"
                                                    sortArray={this.sortEventsToday}
                                                />
                                            </div>
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="event-organizer event-organizer-header"
                                            >
                                                Event Organizer
                                            </div>
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="event-organizer event-organizer-header"
                                            >
                                                Event slug
                                            </div>
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="label-image event-no-participants-header"
                                            >
                                                <SortMenu
                                                    text={'No. of Participants'}
                                                    image
                                                    arrowDown
                                                    sortBy="participantsCount"
                                                    sortArray={this.sortEventsToday}
                                                />
                                            </div>
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="label-image event-no-exhibitors-header"
                                            >
                                                <SortMenu
                                                    text={'No. of Booths'}
                                                    image
                                                    arrowDown
                                                    sortBy="exhibitorsCount"
                                                    sortArray={this.sortEventsToday}
                                                />
                                            </div>
                                        </div>
                                        {loading && <Spinner />}
                                        {eventsTodayList?.length ? (
                                            this.returnAllEventsList()
                                        ) : (
                                            <p>{loading ? '' : 'No results found'}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventsToday: state.admin.eventsToday,
        loading: state.admin.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetAdminMenu: () => dispatch(actions.setAdminMenu()),
        onGetEventsToday: () => dispatch(actions.getEventsToday())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventsToday));
