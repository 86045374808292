import React, { PureComponent } from "react";

class TimeIcon extends PureComponent {
	render() {
		return (
			<svg
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M9.66663 10.5552V6.33464H9.91663V10.168V10.4511L10.1594 10.5967L13.4758 12.5866L13.3362 12.8134L9.66663 10.5552ZM9.99996 1.16797C5.14048 1.16797 1.16663 5.14183 1.16663 10.0013C1.16663 14.8608 5.14048 18.8346 9.99996 18.8346C14.8594 18.8346 18.8333 14.8608 18.8333 10.0013C18.8333 5.14183 14.8594 1.16797 9.99996 1.16797Z"
					fill="white"
					stroke="black"
				/>
			</svg>
		);
	}
}

export default TimeIcon;
