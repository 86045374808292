import React from 'react';
import {Link} from 'react-router-dom';
import MainLogo from '../SmallLayoutComponents/MainLogo';
import {withRouter} from 'react-router-dom';
import '../CSS/headerMenu.scss';
import {connect} from 'react-redux';
import * as actions from '../store/actions';
// import PlatformLanguage from './HeaderMenuDropdowns/PlatformLanguage';
// import MainLogoMobile from '../SmallLayoutComponents/MainLogoMobile';
// import Button from '@material-ui/core/Button';
// import {ReactComponent as Share} from '../Images/svg/sm-share.svg';
import SMPopup from '../Dialogs/SMPopup';
import {preventDefaultDrag} from '../Utils/utils';

class HeaderMenu extends React.Component {
    constructor(props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutsideMobile = this.handleClickOutsideMobile.bind(this);

        this.state = {
            mobileMenuVisible: false,
            headerScrolled: false,
            menuLinks: [],
            sharePopup: false,
        };
    }

    setMenuLinks = () => {
        const {eventSlug, loggedIn, eventInfo} = this.props;
        let menuLinks = [
            {
                name: 'participantRegistration',
                labelTranslation: 'participantRegistration',
                pathname: `/event/${eventSlug}/register`,
                searchParam: '?registerAsParticipant=true',
                isActive: false,
                isVisible: true,
                class: 'participant',
            },
            {
                name: 'exhibitorRegistration',
                labelTranslation: 'exhibitorRegistration',
                pathname: `/event/${eventSlug}/register`,
                searchParam: '?registerAsExhibitor=true',
                isActive: false,
                isVisible: true,
                class: 'exhibitor',
            },
            {
                name: 'posterRegistration',
                labelTranslation: 'scholarRegistration',
                pathname: `/event/${eventSlug}/register`,
                searchParam: '?registerAsScholar=true',
                isActive: false,
                isVisible: true,
                class: 'poster',
            },
        ];
        if (loggedIn) {
            let posterRegistrationLink = menuLinks.find((pathname) => pathname.name === 'posterRegistration');
            posterRegistrationLink.searchParam = '';
            posterRegistrationLink.pathname = `/event/${eventSlug}/scholar-registration`;

            let exhibitorRegistrationLink = menuLinks.find((pathname) => pathname.name === 'exhibitorRegistration');
            exhibitorRegistrationLink.searchParam = '';
            exhibitorRegistrationLink.pathname = `/event/${eventSlug}/exhibitor-registration`;

            let participantRegistrationLink = menuLinks.find((pathname) => pathname.name === 'participantRegistration');
            participantRegistrationLink.searchParam = '';
            participantRegistrationLink.pathname = `/event/${eventSlug}/participant-registration`;
        }

        menuLinks.forEach((menuLink) => {
            const {location} = this.props;
            // hide the link if you are on that page
            const locationFullUrl = `${location.pathname}${location.search}`;
            const menuLinkFullUrl = `${menuLink.pathname}${menuLink.searchParam}`;
            if (locationFullUrl === menuLinkFullUrl) {
                menuLink.isVisible = false;
            }

            // hide 'join as ...' buttons on 'event-privacy-policy' and 'terms-and-conditions'
            if (location.pathname.includes('event-privacy-policy') || location.pathname.includes('event-terms-and-conditions')) {
                menuLink.isVisible = false;                
            }
        });

        // let loginLink = menuLinks.find((pathname) => pathname.name === 'login');
        // // we always "hide the loginLink if the user is loggedIn, "show" the loginLink if the user is not loggedIn
        // loginLink.isVisible = !loggedIn;

        if (!eventInfo?.scholarExhibitorsType) {
            let posterRegistrationLink = menuLinks.find((pathname) => pathname.name === 'posterRegistration');
            posterRegistrationLink.isVisible = false;
        }

        if (!eventInfo?.companyExhibitorsType) {
            let exhibitorRegistrationLink = menuLinks.find((pathname) => pathname.name === 'exhibitorRegistration');
            exhibitorRegistrationLink.isVisible = false;
        }

        this.setState({
            menuLinks: menuLinks,
        });
    };

    componentDidMount() {
        document.addEventListener('touchstart', this.handleClickOutsideMobile);
        window.addEventListener('scroll', this.setHeaderScrolled);
        this.setMenuLinks();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if (prevProps.location.pathname !== window.location.pathname) {
        //     this.collapseMenu();
        // }
        //
        // if (
        //     prevProps.location.pathname !== window.location.pathname ||
        //     prevProps.location.search !== window.location.search ||
        //     prevProps.loggedIn !== this.props.loggedIn
        // ) {
        //    this.setMenuLinks();
        // }
    }

    componentWillUnmount() {
        document.removeEventListener('touchstart', this.handleClickOutsideMobile);
        window.removeEventListener('scroll', this.setHeaderScrolled);
        clearTimeout(this.timeoutId)
    }

    openSharePopup = () => this.setState({sharePopup: true});
    closeSharePopup = () => this.setState({sharePopup: false});

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutsideMobile(event) {
        if (
            (this.wrapperRef && !this.wrapperRef.contains(event.target)) ||
            event.target.tagName.toLowerCase() === 'span'
        ) {
            this.timeoutId = setTimeout(
                () =>
                    this.setState({
                        mobileMenuVisible: false,
                    }),
                200
            );
        }
    }

    setHeaderScrolled = () => {
        if (window.scrollY > 0) {
            if (this.state.headerScrolled === false) {
                this.setState({headerScrolled: true});
            }
        } else {
            if (this.state.headerScrolled === true) {
                this.setState({headerScrolled: false});
            }
        }
    };

    collapseMenu = () => {
        this.setState({mobileMenuVisible: false});
    };

    toggleMenu = () => {
        this.setState({mobileMenuVisible: !this.state.mobileMenuVisible});
    };

    onHandleLogout = (e) => {
        e.preventDefault();
        this.props.onLogoutUser();
    };

    handleChangeLanguage = (e) => {
        const selectedLanguage = e.target.value;
        this.props.onSetEventLanguage(selectedLanguage);
    };

    getTopLinksContainerStyle = () => {
        const {menuLinks} = this.state;

        let topLinksContainerStyle = {
            height: `0`,
        };
        if (this.state.mobileMenuVisible) {
            // we should always substract 1 because we always show the registerAsParticipantLink
            let topMenuLinksLength = menuLinks.filter((menuLink) => menuLink.isVisible).length - 1;

            // we should always add 1 because the logInButton or logoutButton is not in the menuLinks state
            topMenuLinksLength++;

            let height = topMenuLinksLength * 66;
            topLinksContainerStyle = {
                height: `${height}px`,
            };
        }
        return topLinksContainerStyle;
    };

    getAllMenuLinksItems = () => {
        const {menuLinks} = this.state;
        const {translation} = this.props;
        return menuLinks.map((menuLink) => {
            if (!menuLink.isVisible) {
                return null;
            }
            return (
                <Link
                    key={menuLink.name}
                    to={{
                        pathname: menuLink.pathname,
                        search: menuLink.searchParam,
                    }}
                    className={menuLink.class}
                >
                    <span>{translation?.menu[menuLink.labelTranslation]}</span>
                </Link>
            );
        });
    };

    getMenuLinksWithBurgerMenu = () => {
        const {menuLinks} = this.state;
        const {translation} = this.props;
        return menuLinks.map((menuLink) => {
            if (menuLink.name === 'participantRegistration') {
                return (
                    <Link
                        key={menuLink.name}
                        to={{
                            pathname: menuLink.pathname,
                            search: menuLink.searchParam,
                        }}
                        className={menuLink.class}
                    >
                        <span>{translation?.menu[menuLink.labelTranslation]}</span>
                    </Link>
                );
            } else {
                return null;
            }

        });
    };

    render() {
        const {eventInfo, eventSlug, loggedIn, isMobile, registerTabs, translation, defaultTranslation} = this.props;
        const {menuLinks, mobileMenuVisible, sharePopup} = this.state;
        const isPreviewMode = this.props.location.search.includes('preview=true');
        let topLinksContainerStyle = this.getTopLinksContainerStyle();
        let showMenuBottom = this.props.location.pathname.split('/')[3] !== undefined;

        return (
            <>
                <header
                    className={`outside-event-header ${registerTabs.seeLogInForm ? 'log-in-tab' : 'sign-up-tab'} ${
                        showMenuBottom ? 'hide-bottom-menu' : ''
                    }`}
                >
                    <div
                        onDragStart={preventDefaultDrag}
                        className={'menu-wrapper event-level ' + (this.state.headerScrolled ? 'scrolled' : '')}
                    >
                        <div className="container-aifg">
                            <div onDragStart={preventDefaultDrag} className="brand-logo">
                                <a href={`${process.env.REACT_APP_AIFG_WP_LINK}`}>
                                    <MainLogo />
                                </a>
                            </div>
                            {!isPreviewMode && (
                                <div onDragStart={preventDefaultDrag} className="menu-links">
                                    <a href={`${process.env.REACT_APP_AIFG_WP_LINK}/about`} rel="noopener noreferrer">
                                        <span>About</span>
                                    </a>
                                    <a href={`${process.env.REACT_APP_AIFG_WP_LINK}/programme`} rel="noopener noreferrer">
                                        <span>Programme</span>
                                    </a>
                                    <a href={`${process.env.REACT_APP_AIFG_WP_LINK}/speakers`} rel="noopener noreferrer">
                                        <span>Speakers</span>
                                    </a>
                                    <a href={`${process.env.REACT_APP_AIFG_WP_LINK}/engage`} rel="noopener noreferrer">
                                        <span>Engage</span>
                                    </a>
                                    <a href={`${process.env.REACT_APP_AIFG_WP_LINK}/newsroom`} rel="noopener noreferrer">
                                        <span>Newsroom</span>
                                    </a>
                                    <Link
                                        key={'participantRegistration'}
                                        to={{
                                            pathname: `/event/${eventSlug}${loggedIn ? '/participant-registration' : '/register'}`,
                                            search: `${loggedIn ? '' : '?registerAsParticipant=true'}`,
                                        }}
                                        className="join-network"
                                    >
                                        <span>
                                            {translation?.menu.joinNetwork ||
                                            defaultTranslation?.menu.joinNetwork}
                                        </span>
                                    </Link>
                                </div>
                            )}
                            {/*{process.env.REACT_APP_SM_SHARE === 'true' && (*/}
                            {/*    <Button*/}
                            {/*        type="button"*/}
                            {/*        classes={{root: 'sm-share-button'}}*/}
                            {/*        onClick={this.openSharePopup}*/}
                            {/*        disableTouchRipple*/}
                            {/*        disableFocusRipple*/}
                            {/*        disableRipple*/}
                            {/*    >*/}
                            {/*        <Share fill="#000000" className="sm-share-icon" />*/}
                            {/*    </Button>*/}
                            {/*)}*/}
                            {/*{!isPreviewMode && !this.props.disabledLanguageSelector && <PlatformLanguage />}*/}
                            {/*{!isPreviewMode && (*/}
                            {/*    <div onDragStart={preventDefaultDrag} className="menu-links">*/}
                            {/*        {loggedIn ? (*/}
                            {/*            <Button*/}
                            {/*                type="button"*/}
                            {/*                classes={{root: 'homepage-logout-button'}}*/}
                            {/*                onClick={this.onHandleLogout}*/}
                            {/*            >*/}
                            {/*                <span>{translation?.menu.logOut}</span>*/}
                            {/*            </Button>*/}
                            {/*        ) : (*/}
                            {/*            <>*/}
                            {/*                {this.props.location.pathname.split('/')[3] !== 'login' ? (*/}
                            {/*                    <Link to={{pathname: `/event/${eventSlug}/login`}} className="login-event">*/}
                            {/*                        <span>{translation?.menu.logIn}</span>*/}
                            {/*                    </Link>*/}
                            {/*                ) : null}*/}
                            {/*            </>*/}
                            {/*        )}*/}
                            {/*        /!*{this.getAllMenuLinksItems()}*!/*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </div>
                    </div>
                    {!isPreviewMode && (
                        <div
                            className={`mobile-menu-wrapper ${mobileMenuVisible ? 'opened' : ''}`}
                            ref={this.setWrapperRef}
                        >
                            <div className="top-links-container" style={topLinksContainerStyle}>
                                <div onDragStart={preventDefaultDrag} className="menu-links">
                                    {/*{loggedIn ? (*/}
                                    {/*    <Button*/}
                                    {/*        type="button"*/}
                                    {/*        classes={{root: 'homepage-logout-button'}}*/}
                                    {/*        onClick={this.onHandleLogout}*/}
                                    {/*    >*/}
                                    {/*        <span>{translation?.menu.logOut}</span>*/}
                                    {/*    </Button>*/}
                                    {/*) : (*/}
                                    {/*    <Link*/}
                                    {/*        to={{pathname: `/event/${eventSlug}/login`}}*/}
                                    {/*        className={*/}
                                    {/*            'login-event ' +*/}
                                    {/*            (this.props.location.pathname === '/login' ? 'active' : '')*/}
                                    {/*        }*/}
                                    {/*    >*/}
                                    {/*        <span>{translation?.menu.logIn}</span>*/}
                                    {/*    </Link>*/}
                                    {/*)}*/}
                                    {/*{menuLinks.map((menuLink) => {*/}
                                    {/*    if (menuLink.name === 'participantRegistration' || !menuLink.isVisible) {*/}
                                    {/*        return null;*/}
                                    {/*    }*/}
                                    {/*    return (*/}
                                    {/*        <Link*/}
                                    {/*            key={menuLink.name}*/}
                                    {/*            to={{*/}
                                    {/*                pathname: menuLink.pathname,*/}
                                    {/*                search: menuLink.searchParam,*/}
                                    {/*            }}*/}
                                    {/*            className={menuLink.class}*/}
                                    {/*        >*/}
                                    {/*            <span>{translation?.menu[menuLink.labelTranslation]}</span>*/}
                                    {/*        </Link>*/}
                                    {/*    );*/}
                                    {/*})}*/}
                                </div>
                            </div>
                            <div onDragStart={preventDefaultDrag} className="bottom-links-container">
                                <div onDragStart={preventDefaultDrag} className="menu-links">
                                    {this.getMenuLinksWithBurgerMenu()}
                                </div>
                            </div>
                        </div>
                    )}
                </header>
                <SMPopup
                    open={sharePopup}
                    closeConfirm={this.closeSharePopup}
                    dialogTitle={
                        translation?.socialMediaShare?.dialogTitleGeneral ||
                        defaultTranslation?.socialMediaShare?.dialogTitleGeneral
                    }
                    eventName={eventInfo?.name}
                    popupLanguageLevel="event"
                    targetURL={window.location.href}
                    facebookQuote={`${
                        translation?.socialMediaShare?.imAttending || defaultTranslation?.socialMediaShare?.imAttending
                    } ${`"${eventInfo?.name}" ${
                        translation?.socialMediaShare?.event || defaultTranslation?.socialMediaShare?.event
                    }.`} ${
                        translation?.socialMediaShare?.interestedClick ||
                        defaultTranslation?.socialMediaShare?.interestedClick
                    }`}
                    twitterMessage={`${
                        translation?.socialMediaShare?.imAttending || defaultTranslation?.socialMediaShare?.imAttending
                    } ${`"${eventInfo?.name}" ${
                        translation?.socialMediaShare?.event || defaultTranslation?.socialMediaShare?.event
                    }.`} ${
                        translation?.socialMediaShare?.interestedClick ||
                        defaultTranslation?.socialMediaShare?.interestedClick
                    }`}
                    emailSubject={`${process.env.REACT_APP_PLATFORM_NAME} - ${eventInfo?.name}`}
                    emailBody={`${
                        translation?.socialMediaShare?.imAttending || defaultTranslation?.socialMediaShare?.imAttending
                    } ${`"${eventInfo?.name}" ${
                        translation?.socialMediaShare?.event || defaultTranslation?.socialMediaShare?.event
                    }.`} ${
                        translation?.socialMediaShare?.interestedClick ||
                        defaultTranslation?.socialMediaShare?.interestedClick
                    } ${window.location.href}`}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        user: state.user.data,
        eventSlug: state.event.eventSlug,
        languages: state.languages,
        translation: state.languages.translations[state.languages.eventLanguage],
        defaultTranslation: state.languages.translations['en'],
        eventInfo: state.event.eventInfo,
        isMobile: state.layout.isMobile,
        registerTabs: state.user.registerTabs,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogoutUser: () => dispatch(actions.logoutUser()),
        onSetEventLanguage: (languageCode) => dispatch(actions.setEventLanguage(languageCode)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderMenu));
