import React, {PureComponent} from 'react';
import {preventDefaultDrag} from '../../Utils/utils';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InputBase from '@material-ui/core/InputBase';
import {ReactComponent as Plus} from '../../Images/svg/plus.svg';
import colors from '../../CSS/_variables.module.scss';
import connect from 'react-redux/es/connect/connect';
import axios from '../../store/axios-instance';
import {isIOS, isMobileSafari} from 'react-device-detect';
import Divider from '@material-ui/core/Divider';
import Spinner from '../../SmallLayoutComponents/Spinner';

class AddEditPostInput extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            postInputValue: '',
            maxStringLengthError: false,
        };
        this.inputRef = React.createRef();
        this.handleMouseDown = this.handleMouseDown.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleMouseDown);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleMouseDown);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // user entered edit mode
        if (!prevProps.postEditMode && this.props.postEditMode) {
            this.setState(
                {
                    postInputValue: this.props.editedPostInputValue,
                },
                () => {
                    this.inputRef.current.focus();
                }
            );
        }

        // user exited edit mode
        if (prevProps.postEditMode && !this.props.postEditMode) {
            this.setState(
                {
                    postInputValue: '',
                },
                () => {
                    this.inputRef.current.blur();
                }
            );
        }
    }

    // MOBILE: add new post / exit edit mode buttons click/touch is triggered only
    // after second click/touch on either buttons because of input losing focus
    // and virtual keyboard being dismissed
    // the default behaviour is prevented if a touch device is detected
    handleMouseDown(e) {
        const {postEditMode} = this.props;
        const {postInputValue} = this.state;
        if (
            document.activeElement === this.inputRef.current &&
            (postEditMode || postInputValue?.length > 0) &&
            !!('ontouchstart' in window) &&
            !this.inputRef.current.contains(e.target)
        ) {
            e.preventDefault();
        }
    }
    handleFocusAddNewPost = () => {
        if (isIOS && isMobileSafari) {
            document.querySelector('.add-new-post-container').style.position = 'absolute';
            document.querySelector('.add-new-post-container').style.bottom = '22% ';
        }
    };
    handleInputFocus = () => {
        const eventPage = document.getElementsByClassName('event-page');
        if (eventPage) eventPage[0]?.classList.add('hide');
        // move input cursor at the end of string when focusing
        this.inputRef.current.selectionStart = this.inputRef.current.value.length;
        this.inputRef.current.selectionEnd = this.inputRef.current.value.length;
        if (isMobileSafari && isIOS) {
            document.querySelector('.add-new-post-container').style.position = 'absolute';

            if (document.querySelector('.wall-moderation') && !document.querySelector('.lobby-mobile')) {
                document.querySelector('.add-new-post-container').style.bottom = '235px';
            } else {
                document.querySelector('.add-new-post-container').style.bottom = '90px';
            }
        }
        const isInputFocused = true;
        this.props.focusInput(isInputFocused);
    };

    handleInputBlur = () => {
        const eventPage = document.getElementsByClassName('event-page hide');
        if (eventPage.length !== 0) eventPage[0].classList.remove('hide');
        if (isMobileSafari && isIOS) {
            document.querySelector('.add-new-post-container').style.position = 'fixed';
            if (document.querySelector('.lobby-mobile')) {
                document.querySelector('.add-new-post-container').style.bottom = '55px';
            } else {
                document.querySelector('.add-new-post-container').style.bottom = '66px';
            }
        }
        const isInputFocused = false;
        this.props.focusInput(isInputFocused);
    };

    handleChange = (e) =>
        this.setState({
            [e.target.name]: e.target.value,
            maxStringLengthError: e.target.value.length > 1200,
        });

    handleKeyDown = (e) => {
        const {postEditMode} = this.props;
        if (e.key === 'Enter') {
            e.preventDefault();
            if (!this.state.maxStringLengthError) {
                this.handleAddPost();
            }
        }
        if (e.key === 'Escape' && postEditMode) {
            e.preventDefault();
            this.props.onExitEditMode();
        }
    };

    handleAddPost = () => {
        // we don't want to make duplicate api calls for adding a post
        const {loading} = this.state;
        if (loading) return;

        const {postInputValue} = this.state;
        const {postEditMode, editedPostId, eventId, activeWallId} = this.props;

        const trimmedPostInputValue = postInputValue?.trim();

        if (trimmedPostInputValue?.length) {
            this.setState({loading: true});
            if (postEditMode) {
                axios({
                    method: 'put',
                    url: `/event/${eventId}/live-wall/${activeWallId}/post/${editedPostId}`,
                    data: {text: trimmedPostInputValue},
                }).then(() => {
                    this.props.onExitEditMode();
                    this.setState({loading: false});
                });
            } else {
                axios({
                    method: 'post',
                    url: `/event/${eventId}/live-wall/${activeWallId}`,
                    data: {text: trimmedPostInputValue},
                }).then(() =>
                    this.setState(
                        {
                            postInputValue: '',
                            loading: false,
                        },
                        () => this.inputRef.current.blur()
                    )
                );
            }
        }
    };

    render() {
        const {maxStringLengthError, postInputValue, loading} = this.state;
        const {postEditMode, translation, defaultTranslation, userAvatar} = this.props;
        return (
            <>
                <div
                    onDragStart={preventDefaultDrag}
                    onFocus={this.handleFocusAddNewPost}
                    className={`add-new-post-container ${maxStringLengthError ? 'input-error' : ''} ${
                        isIOS ? 'ios-support' : ''
                    }`}
                >
                    {postEditMode && (
                        <div className="edit-mode-container-mobile">
                            <p className="edit-mode-text">
                                <span onClick={this.props.onExitEditMode} className="edit-close-mobile">
                                    <HighlightOffIcon />
                                </span>{' '}
                                {translation?.wall.editingState || defaultTranslation?.wall.editingState}
                            </p>
                        </div>
                    )}
                    <div
                        className="user-avatar"
                        style={{
                            backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${userAvatar}')`,
                        }}
                    />

                    <InputBase
                        inputRef={this.inputRef}
                        id="newPost"
                        className={!postInputValue ? 'empty' : ''}
                        multiline
                        fullWidth
                        maxRows={10} //after 10 lines scroll appears
                        placeholder={translation?.wall.newPostInput}
                        value={postInputValue}
                        name={'postInputValue'}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        onFocus={this.handleInputFocus}
                        onBlur={this.handleInputBlur}
                    />

                    {this.state.loading ? (
                        <Spinner custom="wall-spinner" />
                    ) : (
                        <button
                            className="add-new-post"
                            onClick={this.handleAddPost}
                            disabled={!postInputValue?.length || maxStringLengthError || loading}
                        >
                            <Plus fill={colors.primary} />
                        </button>
                    )}

                    {maxStringLengthError && (
                        <p className={'error-text'}>
                            {translation?.errors.maxWallInputLength || defaultTranslation?.errors.maxWallInputLength}
                        </p>
                    )}
                </div>
                {postEditMode && (
                    <div className="edit-mode-wrapper">
                        <Divider light />
                        <div className="edit-mode-container">
                            <p className="edit-mode-text">
                                <span onClick={this.props.onExitEditMode}>
                                    {translation?.wall.escapeToCancel || defaultTranslation?.wall.escapeToCancel}
                                </span>
                                {' • '}
                                <span onClick={this.handleAddPost}>
                                    {translation?.wall.enterToSave || defaultTranslation?.wall.enterToSave}
                                </span>
                            </p>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        activeWallId: state.event.sideNavigationDetails?.activeWallId,
        userAvatar: state.user.data?.avatarSmall,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps, null)(AddEditPostInput);
