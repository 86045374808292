import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import colors from '../../../CSS/_variables.module.scss';
import {ReactComponent as NoResultsExibitors} from '../../../Images/svg/no_search_results.svg';
import {ReactComponent as NoExibitors} from '../../../Images/svg/no_exhibitors.svg';
import {preventDefaultDrag} from '../../../Utils/utils';

class PostersList extends React.Component {
    state = {
        searchString: '',
        filteredExhibitors: [],
    };

    componentDidMount() {
        // initialize our filtered exhibitors with all the exhibitors in the current showfloor
        const {exhibitors} = this.props;
        this.setState({
            filteredExhibitors: exhibitors,
        });
    }

    componentDidUpdate(prevProps) {
        // whenever a user changes the showfloor, we need to reinitialize the exhibitors
        if (prevProps.loadingExhibitors === true && this.props.loadingExhibitors === false) {
            this.handleFilterExhibitors();
        }
        if (prevProps.activeShowfloorId !== this.props.activeShowfloorId && this.props.activeShowfloorId !== null) {
            this.handleEmptyStringSearch();
        }
    }

    getCurrentShowfloor = () => {
        const {event, activeShowfloorId} = this.props;
        const showfloor = event.showfloors.find((showfloor) => showfloor._id === activeShowfloorId);
        return showfloor;
    };

    handleFilterExhibitors = async () => {
        const {exhibitors} = this.props;

        // const showfloor = showfloors.find((showfloor) => showfloor._id === activeShowfloorId);

        const {searchString} = this.state;
        const searchStringLowerCase = searchString.toLowerCase();
        const filteredExhibitors = exhibitors.filter((exhibitor) => {
            let isInSearch = exhibitor.booth.studyTitle.toLowerCase().includes(searchStringLowerCase);
            isInSearch = isInSearch || exhibitor.user.first.toLowerCase().includes(searchStringLowerCase);
            isInSearch = isInSearch || exhibitor.user.last.toLowerCase().includes(searchStringLowerCase);
            isInSearch =
                isInSearch ||
                exhibitor.booth.authors.find((author) => author.name.toLowerCase().includes(searchStringLowerCase));
            return isInSearch;
        });
        // if (filteredExhibitors && !showfloor?.randomBoothSelector) {
        if (filteredExhibitors) {
            const sorted = filteredExhibitors.sort(function (a, b) {
                return a.booth?.position - b.booth?.position;
            });
            this.setState({filteredExhibitors: sorted});
        } 
           
        return this.setState({filteredExhibitors: filteredExhibitors});
        
        // this.setState({
        //     filteredExhibitors: filteredExhibitors,
        // });
    };

    handleUpdateSearchString = (e) => {
        const searchString = e.target.value;
        this.setState(
            {
                searchString: searchString,
            },
            this.handleFilterExhibitors
        );
    };

    handleEmptyStringSearch = () => {
        this.setState(
            {
                searchString: '',
            },
            this.handleFilterExhibitors
        );
    };

    renderAuthors = (exhibitor) => {
        const {translation, defaultTranslation} = this.props;
        const authorsLength = exhibitor.booth.authors.length;
        if (authorsLength === 0) {
            return (
                <span>
                    {translation?.showfloor.authorsNotAvailable || defaultTranslation?.showfloor.authorsNotAvailable}
                </span>
            );
        }
        return (
            <>
                {exhibitor.booth.authors.map((author, authorIndex) => {
                    return (
                        <span onDragStart={preventDefaultDrag} draggable="false" key={author._id}>
                            {author.name}
                            {authorIndex !== authorsLength - 1 ? ', ' : ''}
                        </span>
                    );
                })}
            </>
        );
    };

    getTotalAuthors = () => {
        const {filteredExhibitors} = this.state;
        let authors = 0;
        filteredExhibitors.forEach((exhibitor) => (authors += exhibitor.booth.authors.length));
        return authors;
    };

    render() {
        const {searchString, filteredExhibitors} = this.state;
        const {exhibitors, activeExhibitorId, translation, defaultTranslation, event, showExhibitorsOverlay} =
            this.props;

        const showfloor = this.getCurrentShowfloor();

        return (
            <div
                onDragStart={preventDefaultDrag}
                className="exhibitors-container posters-list-container"
                data-content={event.showfloors.length}
            >
                <div onDragStart={preventDefaultDrag} className="exhibitors-container-title">
                    <div>
                        <p onDragStart={preventDefaultDrag} className="title">
                            {showfloor?.name}
                        </p>
                        <p onDragStart={preventDefaultDrag} className="subtitle posters-title">
                            {translation?.showfloor.allPostersStudiesTitle ||
                                defaultTranslation?.showfloor.allPostersStudiesTitle}
                        </p>
                        <p onDragStart={preventDefaultDrag} className="subtitle">
                            {filteredExhibitors.length}{' '}
                            {translation?.showfloor.posters || defaultTranslation?.showfloor.posters},{' '}
                            {this.getTotalAuthors()}{' '}
                            {translation?.showfloor.authors || defaultTranslation?.showfloor.authors}
                        </p>
                    </div>
                    <div
                        onDragStart={preventDefaultDrag}
                        className={`search-exhibitor-container ${exhibitors.length === 0 ? 'hide' : ''}`}
                    >
                        <TextField
                            label={
                                event?.showfloors?.length > 1
                                    ? translation?.showfloor?.searchPostersMultiple ||
                                      defaultTranslation?.showfloor?.searchPostersMultiple
                                    : translation?.showfloor.searchPosters ||
                                      defaultTranslation?.showfloor.searchPosters
                            }
                            onChange={this.handleUpdateSearchString}
                            name="invitationCode"
                            value={searchString}
                            variant="outlined"
                            fullWidth
                            className="field-container"
                            InputProps={{
                                endAdornment: searchString ? (
                                    <CloseRoundedIcon className="delete-text" onClick={this.handleEmptyStringSearch} />
                                ) : (
                                    <SearchIcon />
                                ),
                            }}
                        />
                    </div>
                </div>
                <div
                    className={`posters-list scroll-left-container ${showExhibitorsOverlay ? 'overflow-auto' : ''}`}
                    data-scroll={filteredExhibitors.length >= 3}
                    data-empty={filteredExhibitors.length}
                >
                    <div>
                        {exhibitors.length > 0 ? (
                            <>
                                {filteredExhibitors.map((exhibitor) => {
                                    const isActive = activeExhibitorId === exhibitor._id;

                                    return (
                                        <div
                                            className={`single-poster ${isActive ? 'active' : ''}`}
                                            key={exhibitor._id}
                                            onClick={this.props.handleSetActiveExhibitor(exhibitor._id)}
                                        >
                                            <div onDragStart={preventDefaultDrag} className="poster-container">
                                                <p onDragStart={preventDefaultDrag} className="poster-title">
                                                    {exhibitor.booth.studyTitle
                                                        ? exhibitor.booth.studyTitle
                                                        : 'Title not available'}
                                                </p>
                                                <p onDragStart={preventDefaultDrag} className="poster-authors">
                                                    {this.renderAuthors(exhibitor)}
                                                </p>
                                                <p onDragStart={preventDefaultDrag} className="poster-highlights">
                                                    {exhibitor.booth.highlights
                                                        ? exhibitor.booth.highlights
                                                        : 'Abstract not available'}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                                {filteredExhibitors.length === 0 ? (
                                    <div onDragStart={preventDefaultDrag} className="empty-exhibitors-list">
                                        <NoResultsExibitors fill={colors.primary} />
                                        <p>
                                            {translation?.showfloor.emptyPostersResults ||
                                                defaultTranslation?.showfloor.emptyPostersResults}
                                        </p>
                                    </div>
                                ) : null}
                            </>
                        ) : (
                            <div onDragStart={preventDefaultDrag} className="empty-exhibitors-list">
                                <NoExibitors fill={colors.primary} />
                                <p>
                                    <span>
                                        {(
                                            translation?.showfloor.noPresentersTextFirst ||
                                            defaultTranslation?.showfloor.noPresentersTextFirst
                                        ).toLowerCase()}
                                    </span>
                                    <span>
                                        {(
                                            translation?.showfloor.noExhibitorsTextSecond ||
                                            defaultTranslation?.showfloor.noExhibitorsTextSecond
                                        ).toLowerCase()}
                                    </span>
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        exhibitors: state.exhibitors.approvedExhibitors,
        loadingExhibitors: state.exhibitors.loading,
        activeExhibitorId: state.exhibitors.activeExhibitorId,
        languages: state.languages,
        activeShowfloorId: state.showfloors.activeShowfloorId,
        resourcesAccess: state.user.resourcesAccess,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        showfloors: state.showfloors.showfloors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetActiveExhibitorId: (exhibitorId) => dispatch(actions.setActiveExhibitorId(exhibitorId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostersList);
