import React from "react";

import { ReactComponent as LeftArrowIcon } from "../../Images/svg/floor_plan_mobile_left.svg";
import { ReactComponent as RightArrowIcon } from "../../Images/svg/floor_plan_mobile_right.svg";

const FloorPlanSliderMobile = ({
	activeFloorName,
	activeFloorIndex,
	setActiveFloorIndex,
}) => {
	return (
		<div
			id="jopa"
			style={{
				position: "absolute",
				bottom: 0,
				height: 53,
				zIndex: 7,
				backgroundColor: "white",
				width: "100%",
				display: "flex",
				overflow: "hidden",
				justifyContent: "space-between",
			}}
		>
			<LeftArrowIcon
				style={{
					position: "relative",
					height: 53,
					cursor: "pointer",
				}}
				onClick={() => setActiveFloorIndex(activeFloorIndex - 1)}
			/>
			<h3
				style={{
					margin: 0,
					flex: 1,
					display: "flex",
					alignSelf: "center",
					justifyContent: "flex-start",
					marginLeft: 12,
				}}
			>
				{activeFloorName}
			</h3>
			<RightArrowIcon
				style={{
					position: "relative",
					cursor: "pointer",
					height: 53,
				}}
				onClick={() => setActiveFloorIndex(activeFloorIndex + 1)}
			/>
		</div>
	);
};

export default FloorPlanSliderMobile;
