import ReactPlayer from "react-player";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import FastForwardIcon from "@material-ui/icons/FastForward";
import { preventDefaultDrag } from "../../../Utils/utils";

const LobbyVideoFullscreen = (props) => {
	const {
		videoUrl,
		thumbnailImage,
		classes,
		translation,
		fullscreen,
		isVideoPlaying,
		muteVideo,
		progressTime,
		youtubePlayer,
		handleVideoReady,
		handleProgressBar,
		handleProgressChange,
		toggleRewind,
		togglePause,
		toggleForward,
		toggleMute,
		exitFullscreen,
	} = props;
	return (
		<Dialog
			open={true}
			fullScreen={fullscreen}
			aria-labelledby="form-dialog-title"
			disableEscapeKeyDown={true}
			className={`booth-video-dialog-container booth-dialog ${
				fullscreen ? "fullscreen" : ""
			} ${classes !== undefined ? classes : ""}`}
		>
			<DialogContent className="booth-dialog-content booth-video-dialog-container">
				<DialogTitle id="form-dialog-title">
					{translation?.showfloor.boothVideoDialogTitle}
				</DialogTitle>
				<div onDragStart={preventDefaultDrag} className="booth-video-container">
					<img
						src={thumbnailImage}
						alt="Video thumbnail"
						className={`booth-thumbnail ${!isVideoPlaying && "show"}`}
					/>
					<ReactPlayer
						ref={youtubePlayer}
						url={videoUrl}
						onReady={handleVideoReady}
						onProgress={handleProgressBar}
						playing
						loop
						playsinline
						playIcon={<></>}
						controls={false}
						width="100%"
						height="100%"
						volume={muteVideo ? 0 : 1}
						className="react-player"
					/>
					<Slider
						classes={{
							root: `progress-bar ${!isVideoPlaying && "hide"}`,
						}}
						onChange={handleProgressChange}
						value={progressTime}
						color="secondary"
					/>
					<div
						onDragStart={preventDefaultDrag}
						className="auditoriumOverlayPlayer"
					></div>
				</div>
				<div
					onDragStart={preventDefaultDrag}
					className="booth-video-actions-container"
				>
					<button onClick={exitFullscreen} className="fullscreen-button">
						<FullscreenExitIcon fontSize="inherit" />
					</button>
					<button
						onClick={toggleRewind}
						className="fullscreen-button mute-button"
					>
						<FastRewindIcon fontSize="inherit" />
					</button>
					<button
						onClick={togglePause}
						className="fullscreen-button mute-button"
					>
						{isVideoPlaying ? (
							<PauseIcon fontSize="inherit" />
						) : (
							<PlayArrowIcon fontSize="inherit" />
						)}
					</button>
					<button
						onClick={toggleForward}
						className="fullscreen-button mute-button"
					>
						<FastForwardIcon fontSize="inherit" />
					</button>
					<button
						onClick={toggleMute}
						className="fullscreen-button mute-button"
					>
						{muteVideo ? (
							<VolumeOffIcon fontSize="inherit" />
						) : (
							<VolumeUpIcon fontSize="inherit" />
						)}
					</button>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					type="button"
					classes={{ label: "booth-cancel-button" }}
					onClick={exitFullscreen}
				>
					{translation?.generalText.close}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default LobbyVideoFullscreen;
