import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';

const initialState = {
    loading: false,
    audiences: [],
    campaigns: [],
    error: false,
};

const reducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.GET_AUDIENCES_START:
            case actionTypes.ADD_AUDIENCE_START:
            case actionTypes.UPDATE_AUDIENCE_START:
            case actionTypes.DELETE_AUDIENCE_START:
            case actionTypes.GET_CAMPAIGNS_START:
            case actionTypes.ADD_CAMPAIGN_START:
            case actionTypes.UPDATE_CAMPAIGN_START:
            case actionTypes.DELETE_CAMPAIGN_START:
                draft.loading = true;
                break;

            case actionTypes.GET_AUDIENCES_FAIL:
            case actionTypes.ADD_AUDIENCE_FAIL:
            case actionTypes.UPDATE_AUDIENCE_FAIL:
            case actionTypes.DELETE_AUDIENCE_FAIL:
            case actionTypes.GET_CAMPAIGNS_FAIL:
            case actionTypes.ADD_CAMPAIGN_FAIL:
            case actionTypes.UPDATE_CAMPAIGN_FAIL:
            case actionTypes.DELETE_CAMPAIGN_FAIL:
                draft.loading = false;
                break;

            case actionTypes.GET_AUDIENCES_SUCCESS:
                draft.loading = false;
                draft.audiences = action.payload;
                break;

            case actionTypes.ADD_AUDIENCE_SUCCESS:
                draft.loading = false;
                draft.audiences.push(action.payload);
                break;

            case actionTypes.UPDATE_AUDIENCE_SUCCESS:
                draft.loading = false;
                let updatedAudienceIndex = draft.audiences.findIndex((audience) => audience._id === action.payload._id);
                if (updatedAudienceIndex !== -1) {
                    draft.audiences[updatedAudienceIndex] = action.payload;
                }
                break;

            case actionTypes.DELETE_AUDIENCE_SUCCESS:
                draft.loading = false;
                draft.audiences = draft.audiences.filter((audience) => audience._id !== action.payload);
                break;

            case actionTypes.GET_CAMPAIGNS_SUCCESS:
                draft.loading = false;
                draft.campaigns = action.payload.campaigns;
                break;

            case actionTypes.ADD_CAMPAIGN_SUCCESS:
                draft.loading = false;
                draft.campaigns.push(action.payload);
                break;

            case actionTypes.UPDATE_CAMPAIGN_SUCCESS:
                draft.loading = false;
                let updatedCampaignIndex = draft.campaigns.findIndex((campaign) => campaign._id === action.payload._id);
                if (updatedCampaignIndex !== -1) {
                    draft.campaigns[updatedCampaignIndex] = action.payload;
                }
                break;

            case actionTypes.DELETE_CAMPAIGN_SUCCESS:
                draft.loading = false;
                draft.campaigns = draft.campaigns.filter((campaign) => campaign._id !== action.payload);
                break;

            case actionTypes.ADD_CAMPAIGN_TO_QUEUE_START:
                let updatedQueuedCampaignIndex = draft.campaigns.findIndex(
                    (campaign) => campaign._id === action.payload
                );
                if (updatedQueuedCampaignIndex !== -1) {
                    draft.campaigns[updatedQueuedCampaignIndex].alreadySent = true;
                    draft.campaigns[updatedQueuedCampaignIndex].sentOn = new Date().toISOString();
                }
                break;

            default:
                break;
        }
    })



export default reducer;
