import React, {PureComponent} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import InformDialog from '../../../Dialogs/InformDialog';
import colors from '../../../CSS/_variables.module.scss';
import {ReactComponent as ShowFloorIcon} from '../../../Images/svg/showfloor_icon_rooms.svg';
import {preventDefaultDrag} from '../../../Utils/utils';
import {ReactComponent as HoverHand} from '../../../Images/svg/hover-hand.svg';

class ShowfloorLink extends PureComponent {

    handleEnterKey = (e) => {
        if (e.key === 'Enter') return this.props.handleOpenNoShowfloorDialog();
    };

    getNumberOfBooths = () => {
        const {event} = this.props;
        let booths = 0;
        event?.showfloors?.forEach((showfloor) => {
            booths += showfloor.exhibitors?.length;
        });
        return booths;
    };

    render() {
        const {
            classes,
            translation,
            defaultTranslation,
            event,
            eventSlug,
            location,
            hasAccessToShowfloor,
            showNoShowfloorDialog,
            handleOpenNoShowfloorDialog,
            showNoShowfloorAccessDialog,
            handleOpenNoShowfloorAccess,
            closeDialog,
            lobby,
        } = this.props;

        const activeShowfloor = location.pathname.indexOf(`/${eventSlug}/showfloor`) > -1;

        let boothsLength = this.getNumberOfBooths();

        return (
            <>
                <div onDragStart={preventDefaultDrag} className={classes}>
                    {event.restrictShowfloorAccess ? (
                        <>
                            {lobby ? (
                                <div
                                    tabIndex="0"
                                    onDragStart={preventDefaultDrag}
                                    onClick={handleOpenNoShowfloorDialog}
                                    className="show-info-dialog-button"
                                    onKeyDown={this.handleEnterKey}
                                >
                                    <div className="door-text-wrapper showfloor">
                                        <HoverHand className="d-none"/>
                                        <div>
                                            {event.brandingData.exhibitorsDoorText && (
                                                <p
                                                    style={{
                                                        color: event.brandingData.lobbyDoorsTextColor
                                                            ? event.brandingData.lobbyDoorsTextColor
                                                            : colors.doorColor,
                                                    }}
                                                >
                                                    {event.brandingData.exhibitorsDoorText}
                                                </p>
                                            )}
                                            <span className="d-none">
                                                {translation?.demoMessages?.clickToOpen ||
                                                defaultTranslation?.demoMessages?.clickToOpen}
                                            </span>
                                        </div>
                                        <div className="bottom-wrapper">
                                            <p>
                                                {`${
                                                    (translation?.showfloor.totalStands ||
                                                        defaultTranslation?.showfloor.totalStands)
                                                }: ${boothsLength}`}
                                            </p>
                                            <span>
                                                {`${
                                                    (translation?.showfloor.showfloorPlural ||
                                                        defaultTranslation?.showfloor.showfloorPlural)
                                                }: ${event.showfloors.length}`}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <ShowFloorIcon fill={colors.greyVariant} />
                                    <p onDragStart={preventDefaultDrag} onClick={handleOpenNoShowfloorDialog}>
                                        {event.brandingData.exhibitorsDoorText
                                            ? event.brandingData.exhibitorsDoorText
                                            : translation?.showfloor.showfloorsTitle}
                                    </p>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {!hasAccessToShowfloor ? (
                                <>
                                    {lobby ? (
                                        <div onClick={handleOpenNoShowfloorAccess} className="show-info-dialog-button">
                                            <div className="door-text-wrapper showfloor">
                                                <HoverHand className="d-none"/>
                                                <div>
                                                    {event.brandingData.exhibitorsDoorText && (
                                                        <p
                                                            style={{
                                                                color: event.brandingData.lobbyDoorsTextColor
                                                                    ? event.brandingData.lobbyDoorsTextColor
                                                                    : colors.doorColor,
                                                            }}
                                                        >
                                                            {event.brandingData.exhibitorsDoorText}
                                                        </p>
                                                    )}
                                                    <span className="d-none">
                                                        {translation?.demoMessages?.clickToOpen ||
                                                        defaultTranslation?.demoMessages?.clickToOpen}
                                                    </span>
                                                </div>
                                                <div className="bottom-wrapper">
                                                    <p>
                                                        {`${
                                                            (translation?.showfloor.totalStands ||
                                                                defaultTranslation?.showfloor.totalStands)
                                                        }: ${boothsLength}`}
                                                    </p>
                                                    <span>
                                                        {`${
                                                            (translation?.showfloor.showfloorPlural ||
                                                                defaultTranslation?.showfloor.showfloorPlural)
                                                        }: ${event.showfloors.length}`}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {/* <ShowFloorIcon fill={colors.greyVariant} />
                                            <p onDragStart={preventDefaultDrag} onClick={handleOpenNoShowfloorAccess}>
                                                {event.brandingData.exhibitorsDoorText
                                                    ? event.brandingData.exhibitorsDoorText
                                                    : translation?.showfloor.showfloorsTitle}
                                            </p> */}
                                        </>
                                    )}
                                </>
                            ) : (
                                <Link
                                    tabIndex="0"
                                    className={`${activeShowfloor ? 'active' : ''}`}
                                    to={{pathname: `/event/${eventSlug}/showfloor/`}}
                                >
                                    <div className="door-text-wrapper showfloor">
                                        <HoverHand className="d-none"/>
                                        <div>
                                            {event.brandingData.exhibitorsDoorText && (
                                                <p
                                                    style={{
                                                        color: event.brandingData.lobbyDoorsTextColor
                                                            ? event.brandingData.lobbyDoorsTextColor
                                                            : colors.doorColor,
                                                    }}
                                                >
                                                    {event.brandingData.exhibitorsDoorText}
                                                </p>
                                            )}
                                            <span className="d-none">
                                                {translation?.demoMessages?.clickToOpen ||
                                                defaultTranslation?.demoMessages?.clickToOpen}
                                            </span>
                                        </div>
                                        <div className="bottom-wrapper">
                                            <p>
                                                {`${
                                                    (translation?.showfloor.totalStands ||
                                                        defaultTranslation?.showfloor.totalStands)
                                                }: ${boothsLength}`}
                                            </p>
                                            <span>
                                                {`${
                                                    (translation?.showfloor.showfloorPlural ||
                                                        defaultTranslation?.showfloor.showfloorPlural)
                                                }: ${event.showfloors.length}`}
                                            </span>
                                        </div>
                                    </div>
                                    {!lobby && (
                                        <>
                                            <ShowFloorIcon fill={colors.greyVariant} />
                                            <p>
                                                {event.brandingData.exhibitorsDoorText
                                                    ? event.brandingData.exhibitorsDoorText
                                                    : translation?.showfloor.showfloorsTitle}
                                            </p>
                                        </>
                                    )}
                                </Link>
                            )}
                        </>
                    )}
                </div>
                {showNoShowfloorDialog && (
                    <InformDialog
                        open={showNoShowfloorDialog}
                        onClose={closeDialog}
                        dialogClasses={'closed-doors'}
                        dialogTitle={event.brandingData.closedShowfloorTitle}
                        dialogDescription={event.brandingData.closedShowfloorText}
                        dialogButtonLabel={`${translation?.closedShowfloorDialog.button}`}
                    />
                )}
                {showNoShowfloorAccessDialog && (
                    <InformDialog
                        open={showNoShowfloorAccessDialog}
                        onClose={closeDialog}
                        dialogTitle={`${translation?.closedShowfloorDialog.title}`}
                        dialogDescription={`${translation?.closedShowfloorDialog.contentPackage}`}
                        dialogButtonLabel={`${translation?.closedShowfloorDialog.button}`}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventSlug: state.event.eventSlug,
    };
};

export default withRouter(connect(mapStateToProps)(ShowfloorLink));
