import React from 'react';
import {preventDefaultDrag} from '../../Utils/utils';
import Slider from 'react-slick';
import {cloneDeep} from 'lodash';

class AuditoriumBannerNoTimeSlot extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.timeSlot !== prevProps.timeSlot) {
            this.sortLogos();
        }
    }
    sortLogos = () => {
        const {stageBanners} = this.props;
        const sorted = cloneDeep(stageBanners).sort(function (a, b) {
            return a.position - b.position;
        });
        return sorted;
    };
    resetOrderOfBanners = () => {
        return this.setState({
            slidesToShow: 1,
            slidesToScroll: 1,
        });
    };
    render() {
        const {filesUrl, lobbyBanner, stageBanners, classes} = this.props;

        let sliderSettings = {
            dots: false,
            arrows: false,
            autoplay: true,
            draggable: false,
            pauseOnHover: false,
            swipe: false,
            infinite: true,
            speed: 1300,
            slidesToShow: 1,
            slidesToScroll: 1,
            // if we use fade, we need to force ".slick-slide {float: left}" on RTL languages (see lobby.scss line 232)
            fade: true,
            autoplaySpeed: 13000,
        };

        return (
            <>
                <div
                    onDragStart={preventDefaultDrag}
                    className={`${classes} ${stageBanners?.length > 0 || lobbyBanner ? 'show-border' : ''}`}
                    id="bannerAuditorium"
                >
                    {stageBanners?.length > 0 ? (
                        <Slider {...sliderSettings}>
                            {stageBanners &&
                                this.sortLogos().map((stageLogo) => {
                                    return (
                                        <div onDragStart={preventDefaultDrag} key={stageLogo._id}>
                                            <img
                                                draggable="false"
                                                onDragStart={preventDefaultDrag}
                                                src={filesUrl + stageLogo.image}
                                                alt="Logo"
                                            />
                                        </div>
                                    );
                                })}
                        </Slider>
                    ) : lobbyBanner ? (
                        <img
                            draggable="false"
                            src={filesUrl + lobbyBanner}
                            alt="Logo"
                            className="auditorium-banner-image"
                        />
                    ) : null}
                </div>
            </>
        );
    }
}

export default AuditoriumBannerNoTimeSlot;
