import {useState, useEffect, useRef, useLayoutEffect} from 'react';
import NotificationList from './NotificationList';
import './AccountManageNotificationsStyles.scss';
import {ReactComponent as SaveIcon} from '../../../Images/svg/save.svg';
import {Button} from '@material-ui/core';
import {useSelector} from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Confirm from '../../../Dialogs/Confirm';
import axios from '../../../store/axios-instance';
import {isMobile} from 'react-device-detect';

const AccountManageNotifications = () => {
    const user = useSelector((state) => state.user.data);

    const platformLanguage = useSelector((state) => state.languages?.platformLanguage);
    const translation = useSelector((state) => state.languages?.translations[state.languages?.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [openConfirmUnsavedChanges, setOpenConfirmUnsavedChanges] = useState(false);
    const [message, setMessage] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const [carrier, setCarrier] = useState({});
    const [platformNotifications, setPlatformNotifications] = useState([]);
    const [emailNotifications, setEmailNotifications] = useState([]);

    //
    const isFirstLoad = useRef(true);
    const wrapperRef = useRef(null);

    const handleChangeNotification = (notificationsName) => (e) => {
        switch (notificationsName) {
            case 'platformNotifications':
                setPlatformNotifications((prev) =>
                    prev.map((notify) =>
                        notify.shortName === e.target.value ? {...notify, value: e.target.checked} : notify
                    )
                );
                break;
            case 'emailNotifications':
                setEmailNotifications((prev) =>
                    prev.map((notify) =>
                        notify.shortName === e.target.value ? {...notify, value: e.target.checked} : notify
                    )
                );
                break;
            default:
        }
    };

    const isThereAnyChange = () => {
        let mergedList = [];

        mergedList = mergedList.concat(platformNotifications, emailNotifications);
        mergedList = mergedList.reduce((acc, cur) => ({...acc, [cur.shortName]: cur.value}), {});
        delete mergedList.platformPrivacyPolicy;

        let carrierList = carrier;
        delete carrierList._id;

        mergedList = {
            newsletterIsSubscribed: mergedList.newsletterIsSubscribed,
            upcomingSessionsEmailNotifications: mergedList.upcomingSessionsEmailNotifications,
            upcomingSessionsSmartNotifications: mergedList.upcomingSessionsSmartNotifications,
        };

        let carrierObj = Object.values(carrierList).toString();
        let mergedObj = Object.values(mergedList).toString();

        setButtonDisabled(carrierObj === mergedObj ? true : false);
        if (!isMobile) {
            if (carrierObj !== mergedObj) {
                document.querySelector('.account-navigation').style.cssText = 'pointer-events: none;';
                document.querySelector('.menu').style.cssText = 'pointer-events: none;';
            } else {
                document.querySelector('.menu').style.cssText = '';
                document.querySelector('.account-navigation').style.cssText = '';
            }
        }
    };

    const updateNotifications = async () => {
        let mergedList = [];

        mergedList = mergedList.concat(platformNotifications, emailNotifications);
        mergedList = mergedList.reduce((acc, cur) => ({...acc, [cur.shortName]: cur.value}), {});

        delete mergedList.platformPrivacyPolicy;

        const {data} = await axios.put(
            `/user-notification-settings/${user._id}`,
            {userNotificationSettings: mergedList},
            {}
        );
        if (data.success) {
            getNotifications();
            setButtonDisabled(true);
            setSnackbar(true);
            setMessage('Successfully updated.');
            setOpenConfirmUnsavedChanges(false);
        } else {
            setSnackbar(true);
            setMessage('Error. Something went wrong.');
        }
    };

    const getNotifications = async () => {
        const {data} = await axios.get(`/user-notification-settings/${user._id}`);
        if (data && data.success) {
            let objectValues = Object.values(data?.data);
            let firstElVal = objectValues[0];
            objectValues = objectValues.filter((item) => !firstElVal.includes(item));

            setPlatformNotifications([
                {
                    name:
                        translation?.notificationsTextsUserEnd?.platformNotifications?.upComingSession ||
                        defaultTranslation?.notificationsTextsUserEnd?.platformNotifications?.upComingSession,
                    shortName: 'upcomingSessionsSmartNotifications',
                    value: objectValues[2],
                },
            ]);
            setEmailNotifications([
                {
                    name:
                        translation?.notificationsTextsUserEnd?.emailNotifications?.upComingSession ||
                        defaultTranslation?.notificationsTextsUserEnd?.emailNotifications?.upComingSession,
                    shortName: 'upcomingSessionsEmailNotifications',
                    value: objectValues[1],
                },
                {
                    name:
                        translation?.notificationsTextsUserEnd?.emailNotifications?.emailCampaigns ||
                        defaultTranslation?.notificationsTextsUserEnd?.emailNotifications?.emailCampaigns,
                    shortName: 'newsletterIsSubscribed',
                    value: objectValues[0],
                },
                {
                    name:
                        translation?.notificationsTextsUserEnd?.emailNotifications?.privacyPolicy ||
                        defaultTranslation?.notificationsTextsUserEnd?.emailNotifications?.privacyPolicy,
                    shortName: 'platformPrivacyPolicy',
                    value: true,
                },
            ]);
            setCarrier(data?.data);
            setOpenConfirmUnsavedChanges(false);
        }
    };

    const handleCloseDialog = () => {
        getNotifications();
    };

    const handleCloseSnackbar = () => {
        setSnackbar(false);
    };

    const handleClickOutside = (e) => {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
            if (buttonDisabled === false) {
                setOpenConfirmUnsavedChanges(true);
            } else {
                setOpenConfirmUnsavedChanges(false);
            }
        } else {
            return;
        }
    };

    useLayoutEffect(() => {
        if (isFirstLoad.current) {
            isFirstLoad.current = false;
            return;
        } else {
            window.addEventListener('click', (e) => handleClickOutside(e));
            isThereAnyChange();
        }
    });

    useEffect(() => {
        getNotifications();
    }, []);

    return (
        <div className="manage-notifications-container">
            {!isMobile && (
                <h3>
                    {translation?.notificationsTextsUserEnd?.headline ||
                        defaultTranslation?.notificationsTextsUserEnd?.headline}
                </h3>
            )}

            <div className="notifications-container" ref={wrapperRef}>
                {!isMobile && (
                    <h4 className={`${platformLanguage === 'ar' ? 'text-align-right' : ''}`}>
                        {translation?.notificationsTextsUserEnd?.subHeadline ||
                            defaultTranslation?.notificationsTextsUserEnd?.subHeadline}
                    </h4>
                )}
                <NotificationList
                    key={`${
                        translation?.notificationsTextsUserEnd?.headlinePlatformNotifications ||
                        defaultTranslation?.notificationsTextsUserEnd?.headlinePlatformNotifications
                    }`}
                    title={`${
                        translation?.notificationsTextsUserEnd?.headlinePlatformNotifications ||
                        defaultTranslation?.notificationsTextsUserEnd?.headlinePlatformNotifications
                    }`}
                    notificationsName="platformNotifications"
                    handleChangeNotification={handleChangeNotification}
                    notificationList={platformNotifications}
                />
                <NotificationList
                    key={`${
                        translation?.notificationsTextsUserEnd?.headlineEmailotifications ||
                        defaultTranslation?.notificationsTextsUserEnd?.headlineEmailotifications
                    }`}
                    title={`${
                        translation?.notificationsTextsUserEnd?.headlineEmailotifications ||
                        defaultTranslation?.notificationsTextsUserEnd?.headlineEmailotifications
                    }`}
                    notificationsName="emailNotifications"
                    handleChangeNotification={handleChangeNotification}
                    notificationList={emailNotifications}
                />

                {!isMobile ? (
                    <Button
                        type="submit"
                        variant="outlined"
                        classes={{
                            root: `restyled-save-button button-save ${platformLanguage === 'ar' ? 'ar-helper' : ''}`,
                            label: 'dialog-btn-uppercase',
                        }}
                        disabled={buttonDisabled}
                        onClick={updateNotifications}
                    >
                        <SaveIcon />
                        {translation?.generalText.save}
                    </Button>
                ) : (
                    <div className="mobile-button-set-notifications">
                        <Button
                            type="submit"
                            variant="outlined"
                            classes={{
                                root: `restyled-save-button button-save mobile-helper mr-32 ${
                                    platformLanguage === 'ar' ? 'ar-helper' : ''
                                }`,
                                label: 'dialog-btn-uppercase',
                            }}
                            disabled={buttonDisabled}
                            onClick={handleCloseDialog}
                        >
                            {translation?.generalText.cancel}
                        </Button>
                        <Button
                            type="submit"
                            variant="outlined"
                            classes={{
                                root: `restyled-save-button button-save mobile-helper ${
                                    platformLanguage === 'ar' ? 'ar-helper' : ''
                                }`,
                                label: 'dialog-btn-uppercase',
                            }}
                            disabled={buttonDisabled}
                            onClick={updateNotifications}
                        >
                            {translation?.generalText.save}
                        </Button>
                    </div>
                )}
                <Snackbar
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    key={message}
                    open={snackbar}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={5000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={
                        <span draggable="false" id="message-id">
                            {message}
                        </span>
                    }
                />
                <Confirm
                    open={openConfirmUnsavedChanges}
                    closeConfirm={() => handleCloseDialog()}
                    dialogTitle={'Unsaved changes'}
                    dialogDescription={'You have unsaved changes. Please save them to continue.'}
                    dialogConfirmButtonLabel={'SAVE'}
                    handleConfirm={() => updateNotifications()}
                />
            </div>
        </div>
    );
};

export default AccountManageNotifications;
