import {
    SpeechTranslationConfig,
    TranslationRecognizer,
    AudioConfig,
    CancellationReason,
} from 'microsoft-cognitiveservices-speech-sdk';
import {limitedInterpretationTargetLanguages} from '../../../Utils/constants/data';

const defaultTargetLocales = limitedInterpretationTargetLanguages.map(({locale}) => locale);

const createSpeechTranslationConfig = (sourceLocale, targetLocales) => {
    const speechTranslationConfig = SpeechTranslationConfig.fromSubscription(
        '07a4c22b309940209ad438c4b7bcfce7',
        'southeastasia'
    );

    speechTranslationConfig.speechRecognitionLanguage = sourceLocale;
    targetLocales.forEach((targetLocale) => speechTranslationConfig.addTargetLanguage(targetLocale));

    return speechTranslationConfig;
};

export const getRecognizerControls = ({
    mediaStream,
    sourceLocale,
    targetLocales = defaultTargetLocales,
    onRecognized = () => {},
    onRecognizing = () => {},
}) => {
    const speechTranslationConfig = createSpeechTranslationConfig(sourceLocale, targetLocales);
    const audioConfig = AudioConfig.fromStreamInput(mediaStream);
    const translator = new TranslationRecognizer(speechTranslationConfig, audioConfig);

    const start = () => {
        try {
            translator.recognizing = (s, e) => {
                onRecognizing(e.result.translations);
            };

            translator.recognized = (s, e) => {
               
                onRecognized(e.result.translations);
            };

            translator.canceled = (s, e) => {
     
                translator.stopContinuousRecognitionAsync();
            };

            translator.sessionStopped = (s, e) => {
                console.log('\n    Session stopped event.', s, e);
            };

            translator.sessionStarted = (s, e) => {
                console.log('\n    Session started event.', s, e);
            };
            translator.startContinuousRecognitionAsync();
        } catch (err) {
            console.error(err);
        }
    };

    const stop = () => {
        console.log('stop recognition');
        translator.dispose(false);
    };

    return [start, stop];
};
