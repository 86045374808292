import React from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import LockIcon from "@material-ui/icons/Lock";
import colors from "../../CSS/_variables.module.scss";
import { ReactComponent as Arrow } from "../../Images/svg/arrow_all_stages.svg";
import { ReactComponent as CloseSlidersArrow } from "../../Images/svg/hide_arrow.svg";
import { ReactComponent as OpenSlidersArrow } from "../../Images/svg/arrow_up.svg";
import { preventDefaultDrag } from "../../Utils/utils";

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			onDragStart={preventDefaultDrag}
			className={className}
			style={{ ...style }}
			onClick={onClick}
		>
			<Arrow fill={colors.primary} />
		</div>
	);
}

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			onDragStart={preventDefaultDrag}
			className={className}
			style={{ ...style }}
			onClick={onClick}
		>
			<Arrow fill={colors.primary} />
		</div>
	);
}

class AuditoriumsSlider extends React.Component {
	state = {
		sliderContainerStyles: null,
		showAuditoriumsSlider: false,
		slidesToShow: 1,
		sliderPadding: {
			padding: "32px 80px 40px",
		},
		sliderHeaderPadding: {
			padding: "12px 18px 0",
		},
		finishedTransition: false,
	};

	componentDidMount() {
		this.calculateSliderMaxWidth();
		window.addEventListener("resize", this.calculateSliderMaxWidth);
		this.slider = React.createRef();

		this.timeoutId = setTimeout(() => {
			this.setState({
				showAuditoriumsSlider: true,
			});
		}, 500);

		this.timeoutId2 = setTimeout(() => {
			this.setState({
				finishedTransition: true,
			});
		}, 1500);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.activeAuditoriumIndex !== this.props.activeAuditoriumIndex) {
			this.slider.current.slickGoTo(this.props.activeAuditoriumIndex);
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.calculateSliderMaxWidth);
		clearTimeout(this.timeoutId);
		clearTimeout(this.timeoutId2);
		clearTimeout(this.auditoriumSliderTimeoutId);
	}

	calculateSliderMaxWidth = () => {
		let width = 0;
		let slidesToShow = 1;
		let sliderPadding = {
			padding: "32px 80px 40px",
		};
		let sliderHeaderPadding = {
			padding: "12px 18px 0",
		};

		const visibleAuditoriumCount = this.props.auditoriums.filter(
			(auditorium) => auditorium.visible,
		).length;

		if (window.innerWidth < 1251) {
			slidesToShow = visibleAuditoriumCount < 3 ? visibleAuditoriumCount : 2;
			width =
				(visibleAuditoriumCount < 3 ? visibleAuditoriumCount : 2) * 192 +
				(visibleAuditoriumCount < 3 ? 64 : 211);
			sliderPadding = {
				padding: visibleAuditoriumCount < 3 ? "0 16px 20px" : "12px 95px 20px",
			};
			sliderHeaderPadding = {
				padding: visibleAuditoriumCount < 3 ? "12px 18px 0" : "12px 95px 0",
			};
		} else if (window.innerWidth < 1441) {
			slidesToShow = visibleAuditoriumCount < 4 ? visibleAuditoriumCount : 3;
			width =
				(visibleAuditoriumCount < 4 ? visibleAuditoriumCount : 3) * 192 +
				(visibleAuditoriumCount < 4 ? 64 : 210);
			sliderPadding = {
				padding:
					visibleAuditoriumCount < 4 ? "12px 16px 20px" : "12px 89px 20px",
			};
			sliderHeaderPadding = {
				padding: visibleAuditoriumCount < 4 ? "12px 18px 0" : "12px 95px 0",
			};
		} else {
			slidesToShow = visibleAuditoriumCount < 5 ? visibleAuditoriumCount : 4;
			width =
				(visibleAuditoriumCount < 5 ? visibleAuditoriumCount : 4) * 192 +
				(visibleAuditoriumCount < 5 ? 64 : 192);
			sliderPadding = {
				padding:
					visibleAuditoriumCount < 5 ? "12px 16px 20px" : "12px 80px 40px",
			};
			sliderHeaderPadding = {
				padding: visibleAuditoriumCount < 5 ? "12px 18px 0" : "12px 95px 0",
			};
		}

		this.setState({
			sliderContainerStyles: {
				width: width,
			},
			slidesToShow: slidesToShow,
			sliderPadding: sliderPadding,
			sliderHeaderPadding: sliderHeaderPadding,
		});
	};

	showAuditoriumsSlider = () => {
		this.setState(
			{
				showAuditoriumsSlider: !this.state.showAuditoriumsSlider,
			},
			() => {
				if (this.slider.current) {
					this.slider.current.slickGoTo(this.props.activeAuditoriumIndex, true);
				}
				this.auditoriumSliderTimeoutId = setTimeout(() => {
					this.setState({
						finishedTransition: !this.state.finishedTransition,
					});
				}, 1000);
			},
		);
	};

	hideAuditoriumsSlider = () => {
		this.setState({
			showAuditoriumsSlider: false,
			finishedTransition: false,
		});
	};

	getAuditoriumSliderElement = (auditorium, index) => {
		const { eventId, event, eventRoles, resourcesAccess } = this.props;
		let hasAccessToCurrentAuditorium = true;

		if (
			event.hasAccessManagement &&
			eventRoles.isParticipant &&
			resourcesAccess
		) {
			hasAccessToCurrentAuditorium = resourcesAccess.auditoriums.includes(
				auditorium._id,
			);
		} else if (
			(event.exhibitorHasAccessManagement ||
				event.scholarHasAccessManagement) &&
			eventRoles.isExhibitor &&
			resourcesAccess
		) {
			hasAccessToCurrentAuditorium = resourcesAccess.auditoriums.includes(
				auditorium._id,
			);
		}
		return (
			<div
				className={
					"single-auditorium-container " +
					(hasAccessToCurrentAuditorium ? "" : "restricted")
				}
				key={auditorium._id}
			>
				<div
					className={
						"auditorium-details-container " +
						(this.props.activeAuditoriumIndex === index ? "active" : "")
					}
					onClick={this.props.onSetActiveAuditoriumIndex(index)}
				>
					{!hasAccessToCurrentAuditorium && <LockIcon color={"disabled"} />}
					{auditorium.image ? (
						<img
							draggable="false"
							src={`${process.env.REACT_APP_EVENT_FOLDER}${eventId}/${auditorium.image}`}
							alt="logo"
						/>
					) : null}
					<div
						onDragStart={preventDefaultDrag}
						className={`image-filter ${auditorium.image ? "" : "no-image"}`}
					/>
					<div
						className="auditorium-wrapper-content"
						onDragStart={preventDefaultDrag}
					>
						<p className="auditorium-name">{auditorium.name}</p>
						{auditorium?.description && (
							<span
								dangerouslySetInnerHTML={{
									__html: auditorium?.description,
								}}
								className="auditorium-description"
							/>
						)}
					</div>
				</div>
			</div>
		);
	};

	render() {
		const {
			showAuditoriumsSlider,
			sliderContainerStyles,
			sliderPadding,
			sliderHeaderPadding,
			finishedTransition,
		} = this.state;
		const { translation, defaultTranslation } = this.props;
		let sliderSettings = {
			dots: false,
			arrows: true,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			infinite: true,
			speed: 500,
			slidesToShow: this.state.slidesToShow,
			slidesToScroll: 1,
			className: "center",
			focusOnSelect: true,
		};

		return (
			<div onDragStart={preventDefaultDrag} className="auditoriums-popup">
				<div
					onDragStart={preventDefaultDrag}
					className="popup-wrapper"
					style={sliderContainerStyles}
				>
					<div
						className={`toggle-auditoriums-button show ${
							showAuditoriumsSlider ? "hide" : ""
						} ${finishedTransition ? "is-finished" : ""}`}
					>
						<p
							onDragStart={preventDefaultDrag}
							onClick={this.showAuditoriumsSlider}
						>
							<OpenSlidersArrow fill={colors.secondary} />
							<span>
								{translation?.auditorium.allStages ||
									defaultTranslation?.auditorium.allStages}
							</span>
						</p>
						<div>
							<div
								onDragStart={preventDefaultDrag}
								className={`slider-container has-slider ${
									showAuditoriumsSlider ? "opened" : ""
								}`}
							>
								<div
									onDragStart={preventDefaultDrag}
									className="slider-header"
									style={sliderHeaderPadding}
								>
									<p>
										{translation?.auditorium.allStages ||
											defaultTranslation?.auditorium.allStages}
									</p>
									<div
										className={`hide-button close ${
											showAuditoriumsSlider ? "show" : ""
										}`}
										onClick={this.showAuditoriumsSlider}
									>
										<CloseSlidersArrow fill={colors.white} />
										<p>
											{translation?.auditorium.hide ||
												defaultTranslation?.auditorium.hide}
										</p>
									</div>
								</div>
								<div onDragStart={preventDefaultDrag} style={sliderPadding}>
									<Slider {...sliderSettings} ref={this.slider}>
										{this.props.auditoriums.map((auditorium, index) => {
											if (!auditorium?.visible) return null;
											return (
												<div
													onDragStart={preventDefaultDrag}
													key={auditorium._id}
												>
													{this.getAuditoriumSliderElement(auditorium, index)}
												</div>
											);
										})}
									</Slider>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		event: state.event.data,
		eventId: state.event.eventId,
		eventRoles: state.user.eventRoles,
		resourcesAccess: state.user.resourcesAccess,
		languages: state.languages,
		translation: state.languages.translations[state.languages.platformLanguage],
		defaultTranslation: state.languages.translations["en"],
	};
};

export default connect(mapStateToProps)(AuditoriumsSlider);
