import React from 'react';
import '../CSS/platformHomepage.scss';
import 'react-tabs/style/react-tabs.css';
import {connect} from 'react-redux';
import * as actions from '../store/actions';
import {preventDefaultDrag} from '../Utils/utils';
import RegisterPlatform from './RegisterPlatform';

class HomepagePlatform extends React.Component {
    componentDidMount() {
        this.props.onSetPlatformMenu();
        this.props.seeLogInForm();
    }

    render() {
        return (
            <div onDragStart={preventDefaultDrag} className="platform-homepage">
                <RegisterPlatform />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSetPlatformMenu: () => dispatch(actions.setPlatformMenu()),
        seeLogInForm: () => dispatch(actions.seeLogInForm()),
    };
};

export default connect(null, mapDispatchToProps)(HomepagePlatform);
