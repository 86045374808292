import React, {Component} from 'react';

export default class EmptyWall extends Component {
    render() {
        return (
            <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path
                        d="M53.7153 51.007H52.2889L51.7834 50.5195C53.95 47.9917 55.0695 44.5431 54.4556 40.8778C53.607 35.8583 49.4181 31.85 44.3625 31.2361C36.725 30.2972 30.2973 36.725 31.2362 44.3625C31.85 49.4181 35.8584 53.607 40.8778 54.4556C44.5431 55.0695 47.9917 53.95 50.5195 51.7833L51.007 52.2889V53.7153L58.6806 61.3889C59.4209 62.1292 60.6306 62.1292 61.3709 61.3889C62.1111 60.6486 62.1111 59.4389 61.3709 58.6986L53.7153 51.007ZM42.882 51.007C38.3862 51.007 34.757 47.3778 34.757 42.882C34.757 38.3861 38.3862 34.757 42.882 34.757C47.3778 34.757 51.007 38.3861 51.007 42.882C51.007 47.3778 47.3778 51.007 42.882 51.007Z"
                        fill={this.props.fillPrimary}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M57.7761 36.5441C57.0358 36.6498 56.3589 36.1307 56.1603 35.4096C54.9806 31.1245 51.2749 28.3837 47.1421 27.3258C46.4175 27.1403 45.9171 26.4485 46.04 25.7107C46.163 24.973 46.8619 24.4697 47.5884 24.6473C52.5597 25.8624 57.4489 29.2696 58.86 35.0231C59.0381 35.7494 58.5165 36.4383 57.7761 36.5441Z"
                        fill={this.props.fillPrimary}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M27.0815 49.7917C27.8093 49.6195 28.5312 50.0747 28.7833 50.7788C30.3166 55.0608 34.0479 57.6536 38.396 58.098C39.14 58.1741 39.7483 58.7727 39.7483 59.5206C39.7483 60.2685 39.1406 60.881 38.3954 60.8174C32.9045 60.349 28.0109 57.0286 26.1339 51.4061C25.8971 50.6967 26.3537 49.964 27.0815 49.7917Z"
                        fill={this.props.fillPrimary}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21 34.5483L20.9774 29.5988C20.9774 28.8235 20.4539 28.174 19.7573 28.0175H11.0086V28L10.544 28.001C9.6926 28.001 9 28.7264 9 29.6182V35.3834C9 36.2751 9.6926 37.0003 10.544 37.0003H12.1245V39.7273C12.1245 39.8399 12.1907 39.9411 12.2912 39.9817C12.3216 39.994 12.3534 40 12.3849 40C12.4568 40 12.5273 39.9686 12.5776 39.9108L15.1043 36.9997L19.4333 36.9812C20.2848 36.9812 20.9774 36.2558 20.9774 35.3643V35.3335L21 34.5483ZM20.4566 35.364C20.4566 35.955 19.9976 36.4355 19.4323 36.4355L14.9876 36.4546C14.9147 36.4548 14.845 36.4873 14.796 36.5438L12.6453 39.0218V36.7273C12.6453 36.5767 12.5289 36.4546 12.3849 36.4546H10.544C9.9798 36.4546 9.52075 35.9738 9.52075 35.3831V29.6179C9.52075 29.0269 9.9798 28.5462 10.5446 28.5462L12.9057 28.541L19.4333 28.5268C19.9976 28.5268 20.4566 29.0076 20.4566 29.5985V34.6275V35.364Z"
                        fill={this.props.fillPrimary}
                        stroke={this.props.strokeFill}
                        strokeWidth="1.3"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M24.0283 24.0657V18.732C24.0283 17.8922 24.6827 17.1885 25.5534 17.019H36.4892V17L37.07 17.0011C38.1342 17.0011 39 17.787 39 18.753V24.9987C39 25.9647 38.1342 26.7503 37.07 26.7503H35.0943V29.7046C35.0943 29.8266 35.0117 29.9362 34.886 29.9802C34.848 29.9935 34.8082 30 34.7689 30C34.679 30 34.5908 29.966 34.528 29.9034L31.3696 26.7497L25.9583 26.7296C24.8941 26.7296 24.0283 25.9438 24.0283 24.978V24.9446V24.0657ZM24.6792 24.9777C24.6792 25.6179 25.253 26.1384 25.9596 26.1384L31.5155 26.1591C31.6066 26.1594 31.6938 26.1946 31.755 26.2557L34.4434 28.9403V26.4546C34.4434 26.2915 34.5889 26.1591 34.7689 26.1591H37.07C37.7752 26.1591 38.3491 25.6383 38.3491 24.9984V18.7527C38.3491 18.1125 37.7752 17.5917 37.0693 17.5917L34.1179 17.5861L25.9583 17.5707C25.253 17.5707 24.6792 18.0915 24.6792 18.7317V24.1797V24.9777Z"
                        fill={this.props.fillPrimary}
                        stroke={this.props.strokeFill}
                        strokeWidth="1.2"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.9642 13.3268V5.53143C21.9642 4.30393 21.1353 3.2755 20.0324 3.02778H6.18033V3L5.44473 3.00162C4.09662 3.00162 3 4.15016 3 5.56209V14.6904C3 16.1023 4.09662 17.2504 5.44473 17.2504H7.94717V21.5682C7.94717 21.7465 8.05189 21.9067 8.21102 21.9711C8.25925 21.9905 8.30955 22 8.35944 22C8.47322 22 8.58494 21.9503 8.66451 21.8588L12.6651 17.2495L19.5194 17.2202C20.8675 17.2202 21.9642 16.0716 21.9642 14.6602V14.6114V13.3268ZM21.1396 14.6597C21.1396 15.5954 20.4128 16.3562 19.5178 16.3562L12.4804 16.3864C12.365 16.3868 12.2545 16.4382 12.177 16.5276L8.7717 20.4512V16.8182C8.7717 16.5799 8.58742 16.3864 8.35944 16.3864H5.44473C4.55135 16.3864 3.82453 15.6252 3.82453 14.6899V5.56165C3.82453 4.62598 4.55135 3.86475 5.44555 3.86475L9.18396 3.85655L19.5194 3.8341C20.4128 3.8341 21.1396 4.59533 21.1396 5.531V13.4935V14.6597Z"
                        fill={this.props.fillPrimary}
                        stroke={this.props.strokeFill}
                    />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="65" height="65" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        );
    }
}
