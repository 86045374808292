import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import NotFound from '../../Pages/NotFound';
import EventPrivateComponent from './EventPrivateComponent';
import NotApprovedAccount from '../../Components/EventManagement/NotApprovedAccount';
import Spinner from '../../SmallLayoutComponents/FullPageSpinner';
import OrganizerNavigation from '../../SmallLayoutComponents/OrganizerNavigation';
import {isMobile} from 'react-device-detect';
import { handleModularCoOrganizerMenu } from '../../Utils/utils';

const OrganizerRoute = ({component: Component, loggedIn, user, event, loadingUser, hasAccess, ...rest}) => (
    <Route
        {...rest}
        render={(props) => {
            const eventSlug = props.match.params.eventSlug;
            
            if (!loggedIn && loadingUser) {
                return <Spinner />;
            } else if (loggedIn && user) {
                let modularCoOrganizerLink = false;
                const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === eventSlug);
                const userIsOrganizer = userRolesInEvent && userRolesInEvent.roles.includes('organizer');
                const userIsCoOrganizer = userRolesInEvent && userRolesInEvent.roles.includes('coOrganizer');
                const isActive = userRolesInEvent?.accessRights?.isActive;
                if (userRolesInEvent?.accessRights) {
                    modularCoOrganizerLink = handleModularCoOrganizerMenu(userRolesInEvent?.accessRights, event);
                }

                if (userIsOrganizer) {
                    if (user.organizerStatus !== 'approved') {
                        return (
                            <EventPrivateComponent {...props}>
                                <OrganizerNavigation />
                                <NotApprovedAccount {...props} />
                            </EventPrivateComponent>
                        );
                    } else {
                        if (isMobile) {
                            return (
                                <Redirect
                                    to={{
                                        pathname: `/event/${eventSlug}/lobby`,
                                    }}
                                />
                            );
                        } else {
                            return (
                                <EventPrivateComponent {...props}>
                                    <OrganizerNavigation />
                                    <Component />
                                </EventPrivateComponent>
                            );
                        }
                    }
                } else if (userIsCoOrganizer && isActive) {
                    if (hasAccess !== undefined && !hasAccess) {
                        return (
                            <Redirect
                                to={{
                                    pathname: `/event/${eventSlug}/${
                                        modularCoOrganizerLink !== undefined ? modularCoOrganizerLink : 'lobby'
                                    }`,
                                }}
                            />
                        );
                    } else {
                        if (isMobile) {
                            return (
                                <Redirect
                                    to={{
                                        pathname: `/event/${eventSlug}/lobby`,
                                    }}
                                />
                            );
                        } else {
                            return (
                                <EventPrivateComponent {...props}>
                                    <OrganizerNavigation />
                                    <Component />
                                </EventPrivateComponent>
                            );
                        }
                    }
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: `/event/${eventSlug}/lobby`,
                            }}
                        />
                    );
                }
            } else if (!loggedIn && !loadingUser) {
                return (
                    <Route path='/' component={() => {
                        window.location.href = `${process.env.REACT_APP_AIFG_WP_LINK}`;
                        return null;
                    }}/>
                );
            } else {
                return <NotFound />;
            }
        }}
    />
);

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        event: state.event.data,
        loggedIn: state.user.loggedIn,
        loadingUser: state.user.loading,
        loadingEvent: state.event.loading,
        eventSlug: state.event.eventSlug,
    };
};

export default connect(mapStateToProps)(OrganizerRoute);
