import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import {SelectValidator} from 'react-material-ui-form-validator';
import {ValidatorComponent} from 'react-material-ui-form-validator';
import {countries} from '../../Utils/utils';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import './SelectCountryStyles.scss';

class SelectCountry extends ValidatorComponent {
    handleChange = (e) => {
        this.props.handleChange(e.target.name, e.target.value);
    };

    renderValidatorComponent() {
        const {field} = this.props;
        return (
            <FormControl className="setting-input select-validator" variant="outlined" fullWidth>
                <InputLabel id="select-country">{field.value ? null : field.label}</InputLabel>
                <SelectValidator
                    onChange={this.handleChange}
                    name={field.name}
                    value={field.value ? field.value : ''}
                    validators={field.validators}
                    errorMessages={field.errorMessages}
                    className="field-container text-align-left"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                >
                    {countries.map((country) => {
                        return (
                            <MenuItem key={country.code} value={country.name}>
                                {country.name}
                            </MenuItem>
                        );
                    })}
                </SelectValidator>
            </FormControl>
        );
    }
}

export default SelectCountry;
