import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import colors from '../../../CSS/_variables.module.scss';

const iconStyles = {
    selectIcon: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        color: colors.dark,
    },
};

const CustomExpandMore = withStyles(iconStyles)(({className, classes, ...rest}) => {
    return <ExpandMoreIcon fontSize="large" {...rest} className={clsx(className, classes.selectIcon)} />;
});

export default CustomExpandMore;
