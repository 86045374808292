import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import cloneDeep from 'lodash/cloneDeep';
import {PureComponent} from 'react';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {ReactComponent as UploadProfileImg} from '../../Images/svg/upload.svg';
import {isWebview} from '../../Utils/is-webview';
import {preventDefaultDrag} from '../../Utils/utils';
import * as actions from '../../store/actions';
import axios from '../../store/axios-instance';
import RegisterEventEmailCodeValidation from './EmailCodeValidation/EmailCodeValidation';
class RegisterEventForm extends PureComponent {
    state = {
        user: {
            fields: [
                {
                    name: 'first',
                    type: 'text',
                    value: '',
                    label: `${
                        this.props.languages.translations[this.props.languages.eventLanguage].register.inputFirstName
                    }`,
                    multiline: 0,
                    validators: ['required'],
                    errorMessages: [
                        `${this.props.languages.translations[this.props.languages.eventLanguage].errors.required}`,
                    ],
                },
                {
                    name: 'last',
                    type: 'text',
                    value: '',
                    label: `${
                        this.props.languages.translations[this.props.languages.eventLanguage].register.inputLastName
                    }`,
                    multiline: 0,
                    validators: ['required'],
                    errorMessages: [
                        `${this.props.languages.translations[this.props.languages.eventLanguage].errors.required}`,
                    ],
                },
                {
                    name: 'email',
                    type: 'email',
                    value: '',
                    label: `${this.props.languages.translations[this.props.languages.eventLanguage].login.inputEmail}`,
                    multiline: 0,
                    validators: ['required', 'isEmail'],
                    errorMessages: [
                        `${this.props.languages.translations[this.props.languages.eventLanguage].errors.required}`,
                        `${this.props.languages.translations[this.props.languages.eventLanguage].errors.emailNotValid}`,
                    ],
                },
                {
                    name: 'password',
                    type: 'password',
                    value: '',
                    label: `${
                        this.props.languages.translations[this.props.languages.eventLanguage].login.inputPassword
                    }`,
                    multiline: 0,
                    validators: ['required', 'minStringLength:8'],
                    errorMessages: [
                        `${this.props.languages.translations[this.props.languages.eventLanguage].errors.required}`,
                        `${
                            this.props.languages.translations[this.props.languages.eventLanguage].errors
                                .passwordMinLength
                        }`,
                    ],
                },
                {
                    name: 'passwordConfirmation',
                    type: 'password',
                    value: '',
                    label: `${
                        this.props.languages.translations[this.props.languages.eventLanguage].register
                            .inputConfirmPassword
                    }`,
                    multiline: 0,
                    validators: ['isPasswordMatch', 'required', 'minStringLength:8'],
                    errorMessages: [
                        `${
                            this.props.languages.translations[this.props.languages.eventLanguage].errors
                                .passwordMismatch
                        }`,
                        `${this.props.languages.translations[this.props.languages.eventLanguage].errors.required}`,
                        `${
                            this.props.languages.translations[this.props.languages.eventLanguage].errors
                                .passwordMinLength
                        }`,
                    ],
                },
            ],
            agreeToPrivacyPolicy: false,
            file: '',
            imagePreviewUrl: '',
        },
        from: '/',
        disabled: true,
        imgError: false,
        imgErrorText: '',
        displayAgreeToPrivacyPolicyMessage: false,
        password: false,
        passwordConfirmation: false,
        showEmailValidationSection: false,
        emailExistsError: false,
        emailExistsErrorMessage: '',
        displayUserCreatedScreen: false,
        creatingCode: false,
    };

    componentDidMount() {
        this.addNoCommasValidation();
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            let currentPassword = this.state.user.fields.find((field) => field.name === 'password');
            let currentPasswordValue = currentPassword.value;
            if (value !== currentPasswordValue) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('noCommasAllowed', (value) => {
            let rule = new RegExp('^[^,]+$', 'i');
            let match = rule.test(value);

            if (value === undefined || value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });

        document.addEventListener(
            'invalid',
            (function () {
                return function (e) {
                    //prevent the browser from showing default error bubble / hint
                    e.preventDefault();
                    // optionally fire off some custom validation handler
                    // myValidation();
                };
            })(),
            true
        );
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.eventLanguage !== this.props.languages.eventLanguage) {
            this.setUpdatedLanguageFields();
        }
    }

    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule('isPasswordMatch');
        ValidatorForm.removeValidationRule('noCommasAllowed');
        this.props.onClearError();
        this.props.showSocialButtons();
        document.removeEventListener(
            'invalid',
            (function () {
                return function (e) {
                    //prevent the browser from showing default error bubble / hint
                    e.preventDefault();
                    // optionally fire off some custom validation handler
                    // myValidation();
                };
            })(),
            true
        );
    }

    addNoCommasValidation = () => {
        const updatedUser = cloneDeep(this.state.user);
        const {translation, defaultTranslation} = this.props;
        updatedUser.fields.forEach((field) => {
            if (field.type === 'text') {
                field.validators.push('noCommasAllowed');
                const errorMessage = translation.errors.noCommasAllowed || defaultTranslation.errors.noCommasAllowed;
                field.errorMessages.push(errorMessage);
            }
        });
        this.setState({
            user: updatedUser,
        });
    };

    setUpdatedLanguageFields = () => {
        let updatedFields = [...this.state.user.fields];
        const {translation} = this.props;

        updatedFields[0].label = translation.register.inputFirstName;
        updatedFields[1].label = translation.register.inputLastName;
        updatedFields[2].label = translation.register.inputEmail;
        updatedFields[3].label = translation.register.inputPassword;
        updatedFields[4].label = translation.register.inputConfirmPassword;

        updatedFields[0].errorMessages = [`${translation.errors.required}`];
        updatedFields[1].errorMessages = [`${translation.errors.required}`];
        updatedFields[2].errorMessages = [`${translation.errors.required}`, `${translation.errors.emailNotValid}`];
        updatedFields[3].errorMessages = [`${translation.errors.required}`, `${translation.errors.passwordMinLength}`];
        updatedFields[4].errorMessages = [
            `${translation.errors.passwordMismatch}`,
            `${translation.errors.required}`,
            `${translation.errors.passwordMinLength}`,
        ];

        this.setState({user: {...this.state.user, fields: updatedFields}});
    };

    handleCheckbox = (name) => (event) => {
        this.setState({
            user: {
                ...this.state.user,
                [name]: event.target.checked,
            },
            displayAgreeToPrivacyPolicyMessage: !event.target.checked,
        });
    };

    handleFieldChange = (index) => (e) => {
        let updatedFields = [...this.state.user.fields];
        updatedFields[index].value = e.target.value;
        this.setState({
            user: {...this.state.user, fields: updatedFields},
            emailExistsError: false,
            emailExistsErrorMessage: '',
        });
    };

    toggleShowPassword = (fieldName) => () => {
        this.setState((prevState) => ({
            [fieldName]: !prevState[fieldName],
        }));
    };

    handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        let isValid = true;

        isValid = file.size < 2 * 1024 * 1024 && isValid;
        let {translation} = this.props;
        if (!isValid) {
            this.setState({imgErrorText: translation.errors.fileTooLarge});
        }

        isValid =
            (file.type === 'image/png' ||
                file.type === 'image/jpg' ||
                file.type === 'image/jpeg' ||
                file.type === 'image/gif') &&
            isValid;
        if (
            file.type !== 'image/png' &&
            file.type !== 'image/jpg' &&
            file.type !== 'image/jpeg' &&
            file.type !== 'image/gif'
        ) {
            this.setState({imgErrorText: `${translation.errors.imageFileNotSupported} jpeg, jpg, jfif, gif or png.`});
        }

        reader.onloadend = () => {
            if (isValid) {
                this.setState({
                    user: {
                        ...this.state.user,
                        file: file,
                        imagePreviewUrl: reader.result,
                    },
                    imgError: false,
                    imgErrorText: '',
                });
            } else {
                this.setState({imgError: true});
            }
        };
        reader.readAsDataURL(file);
    };

    handleRemoveImage = () => {
        this.setState({
            user: {
                ...this.state.user,
                file: '',
                imagePreviewUrl: '',
            },
            imgError: false,
            imgErrorText: '',
        });
    };

    _handleSeeLogInTab = () => {
        this.props.seeLogInForm();
    };

    handleRegister = (validationCode = '') => {
        const formData = new FormData();
        this.state.user.fields.forEach((field) => {
            let fieldValue = field.value;
            if (typeof field.value === 'string') {
                fieldValue = field.value?.trim();
            }
            formData.append(field.name, fieldValue);
        });

        let eventLang = this.props.languages.eventLanguage;
        formData.append('consent', this.state.user.agreeToPrivacyPolicy);
        formData.append('language', eventLang || 'en');
        formData.append('code', validationCode);

        if (this.state.user.file) {
            formData.append('avatar', this.state.user.file, this.state.user.file.name, this.state.user.file.type);
        }

        this.props
            .onRegisterUser(formData)
            .then(() => {
                this.setState({
                    displayUserCreatedScreen: true,
                });
            })
            .catch(() => {});
    };

    onFormSubmit = () => {
        const hasEmailValidation = JSON.parse(process.env.REACT_APP_ENABLE_EMAIL_VALIDATION);

        let isValid = true;
        isValid = isValid && this.state.user.agreeToPrivacyPolicy;
        isValid = isValid && !this.state.imgError;

        if (isValid) {
            if (hasEmailValidation) {
                this.createRegistrationCode();
            } else {
                this.handleRegister();
            }
        } else {
            if (!this.state.user.agreeToPrivacyPolicy) {
                this.setState({displayAgreeToPrivacyPolicyMessage: true});
            }
        }
    };

    createRegistrationCode = async () => {
        const email = this.state.user.fields.find((field) => field.name === 'email')?.value?.trim();
        const first = this.state.user.fields.find((field) => field.name === 'first')?.value?.trim();
        const last = this.state.user.fields.find((field) => field.name === 'last')?.value?.trim();
        const language = this.props.languages.eventLanguage;

        const formData = {
            email: email,
            language: language,
            first: first,
            last: last,
        };

        this.setState({
            creatingCode: true,
        });

        axios({method: 'post', url: '/users/request-otp', data: formData})
            .then((response) => {
                if (response.data.success) {
                    // after we get the success message we display the validate code section
                    this.setState({
                        showEmailValidationSection: true,
                        creatingCode: false,
                    });
                    // call the function we received from registerEvent so that we can hide the social login buttons
                    this.props.hideSocialButtons();
                }
            })
            .catch((error) => {
                const errorMessage = error.response.data.error;

                if (errorMessage === 'Email already exists') {
                    this.setState({
                        emailExistsError: true,
                        emailExistsErrorMessage: errorMessage,
                        creatingCode: false,
                    });
                }
            });
    };

    render() {
        const {password, passwordConfirmation, showEmailValidationSection, emailExistsError, emailExistsErrorMessage} =
            this.state;
        const {fields, agreeToPrivacyPolicy, imagePreviewUrl, file} = this.state.user;
        const {translation, defaultTranslation, checkIsRegistrationClosed, error} = this.props;
        const userEmail = this.state.user.fields.find((field) => field.name === 'email')?.value;

        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = (
                <img
                    draggable="false"
                    onDragStart={preventDefaultDrag}
                    src={imagePreviewUrl}
                    alt="test"
                    className="avatar"
                />
            );
        } else {
            $imagePreview = null;
        }

        if (showEmailValidationSection) {
            return (
                <RegisterEventEmailCodeValidation
                    userEmail={userEmail}
                    createRegistrationCode={this.createRegistrationCode}
                    handleRegister={this.handleRegister}
                    displayUserCreatedScreen={this.state.displayUserCreatedScreen}
                />
            );
        }

        return (
            <ValidatorForm ref="form" onSubmit={this.onFormSubmit}>
                {fields.map((field, index) => {
                    return (
                        <div onDragStart={preventDefaultDrag} className="field-wrapper" key={field.name}>
                            <TextValidator
                                label={field.label}
                                type={
                                    (field.name === 'password' && password) ||
                                    (field.name === 'passwordConfirmation' && passwordConfirmation)
                                        ? 'text'
                                        : field.type
                                }
                                name={field.name}
                                index={index}
                                value={field.name === 'email' ? field.value.trim() : field.value}
                                variant="filled"
                                onChange={this.handleFieldChange(index)}
                                validators={field.validators}
                                errorMessages={field.errorMessages}
                                multiline={field.multiline > 0}
                                rows={field.multiline}
                                fullWidth={true}
                                margin="normal"
                                className="field-container"
                            />
                            {field.name === 'password' && (
                                <div
                                    onDragStart={preventDefaultDrag}
                                    className="show-pass"
                                    onClick={this.toggleShowPassword(field.name)}
                                >
                                    {password ? <Visibility /> : <VisibilityOff />}
                                </div>
                            )}
                            {field.name === 'passwordConfirmation' && (
                                <div
                                    onDragStart={preventDefaultDrag}
                                    className="show-pass"
                                    onClick={this.toggleShowPassword(field.name)}
                                >
                                    {passwordConfirmation ? <Visibility /> : <VisibilityOff />}
                                </div>
                            )}
                        </div>
                    );
                })}
                {!isWebview() ? (
                    <label
                        htmlFor="file"
                        className={'file ' + (this.state.imgErrorText || this.props.error ? 'error-label' : '')}
                    >
                        <div onDragStart={preventDefaultDrag} className="upload-image">
                            <UploadProfileImg />
                            <p>
                                {file
                                    ? translation?.register.editProfilePicture ||
                                      defaultTranslation?.register.editProfilePicture
                                    : translation?.register.uploadProfilePicture ||
                                      defaultTranslation?.register.uploadProfilePicture}
                            </p>
                        </div>
                    </label>
                ) : null}
                {this.state.imgErrorText && (
                    <p onDragStart={preventDefaultDrag} className="error-text">
                        {this.state.imgErrorText}
                    </p>
                )}

                <TextValidator onChange={this.handleImageChange} name="file" type="file" id="file" className="d-none" />
                {file && (
                    <div
                        onDragStart={preventDefaultDrag}
                        className="imgPreview"
                        data-empty={$imagePreview ? 'true' : 'false'}
                    >
                        <div>
                            <span>{file.name}</span>
                            <button type="button" onClick={this.handleRemoveImage}>
                                {translation?.generalText.remove}
                            </button>
                        </div>
                        {$imagePreview}
                    </div>
                )}

                <div onDragStart={preventDefaultDrag} className="agree-terms">
                    <FormControlLabel
                        control={
                            <Checkbox
                                className="dynamic-checkbox"
                                checked={agreeToPrivacyPolicy}
                                onChange={this.handleCheckbox('agreeToPrivacyPolicy')}
                                value="agreeToPrivacyPolicy"
                            />
                        }
                        label={
                            <span onDragStart={preventDefaultDrag} draggable="false" className="agree-terms-message">
                                {translation?.register.gdprTextFirst}
                                <Link
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to={{
                                        pathname: '/terms-and-conditions/',
                                        search: `?lang=${this.props.languages.eventLanguage}`,
                                    }}
                                >
                                    <span>{' ' + translation?.register.gdprTextTerms}</span>
                                </Link>
                                {' ' + translation?.register.gdprTextSecond}
                                <Link
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to={{
                                        pathname: '/privacy-policy/',
                                        search: `?lang=${this.props.languages.eventLanguage}`,
                                    }}
                                >
                                    <span>{' ' + translation?.register.gdprTextPrivacy}</span>
                                </Link>
                                .
                            </span>
                        }
                    />
                    {this.state.displayAgreeToPrivacyPolicyMessage && (
                        <p onDragStart={preventDefaultDrag} className="error-text">
                            {translation?.register.gdprTextAgree}
                        </p>
                    )}
                </div>
                {(error || emailExistsError) && (
                    <>
                        {error?.includes('E11000 duplicate key error') || emailExistsError ? (
                            <p onDragStart={preventDefaultDrag} className="error-text">
                                <span>
                                    {translation?.register.errorAlreadyHaveMOAccount ||
                                        defaultTranslation?.register.errorAlreadyHaveMOAccount}
                                </span>
                                <span>
                                    {' ' +
                                        (translation?.generalText.please || defaultTranslation?.generalText.please) +
                                        ' '}
                                    <span
                                        draggable="false"
                                        className="error-clickable"
                                        onClick={this._handleSeeLogInTab}
                                    >
                                        {translation?.login.loginTitleLowerCase ||
                                            defaultTranslation?.login.loginTitleLowerCase}
                                    </span>
                                </span>
                            </p>
                        ) : (
                            <p onDragStart={preventDefaultDrag} className="error-text">
                                {error ? error : emailExistsErrorMessage}
                            </p>
                        )}
                    </>
                )}
                <button
                    className="register-button"
                    disabled={checkIsRegistrationClosed || this.state.creatingCode}
                    type="submit"
                >
                    {checkIsRegistrationClosed
                        ? translation?.register.registerNotAllowed || defaultTranslation?.register.registerNotAllowed
                        : translation?.register.registerButton}
                </button>
            </ValidatorForm>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.user.error,
        eventSlug: state.event.eventSlug,
        languages: state.languages,
        translation: state.languages.translations[state.languages.eventLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRegisterUser: (user) => dispatch(actions.registerUser(user)),
        onClearError: () => dispatch(actions.clearError()),
        seeLogInForm: () => dispatch(actions.seeLogInForm()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterEventForm));
