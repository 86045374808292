import React from "react";
import * as actions from "../../store/actions/index";
import BoothFullPreview from "../../SmallLayoutComponents/BoothFullPreview";
import colors from "../../CSS/_variables.module.scss";
import { ReactComponent as Arrow } from "../../Images/svg/showfloor-arrow-image.svg";
import {
	getPrivateChatId,
	checkBoothOwnerOrRepresentative,
	preventDefaultDrag,
} from "../../Utils/utils";
import BoothDocuments from "../../Dialogs/ExhibitorBooth/BoothDocuments";
import BoothLinks from "../../Dialogs/ExhibitorBooth/BoothLinks";
import VideoDialog from "../../Dialogs/VideoDialog";
import { connect } from "react-redux";
import { getVideoIdFromYoutubeUrl } from "../../Utils/utils";
import { connectToBooth, disconnectFromBooth } from "../../Api/socketApi";
import axios from "../../store/axios-instance";
import PlayIcon from "../../Images/svg/play.svg";
import FacebookLogo from "../../Images/svg/ri_facebook.svg";
import LinkedinLogo from "../../Images/svg/ri_linkedin.svg";
import TwitterLogo from "../../Images/svg/ri_twitter.svg";
import GoogleScholarLogo from "../../Images/svg/ri_gscholar.svg";
import ResearchGateLogo from "../../Images/svg/ri_rgate.svg";
import GenericLinkLogo from "../../Images/svg/ri_global.svg";
import NoVideoImage from "../../assets/no-video.png";
import Slider from "react-slick/lib";
import NotificationPresentationStarts from "../../Components/NotificationPresentationStarts";
import PdfPreview from "../Showfloor/Booth/PdfPreview";
import moment from "moment";

class ExhibitorBooth extends React.Component {
	initialShow = {
		links: false,
		documents: false,
		video: false,
	};

	state = {
		exhibitor: null,
		exhibitorVideoId: "",
		show: { ...this.initialShow },
		socialLinks: [
			{ name: "Facebook", label: "Facebook", link: "", image: FacebookLogo },
			{ name: "LinkedIn", label: "LinkedIn", link: "", image: LinkedinLogo },
			{ name: "Twitter", label: "Twitter", link: "", image: TwitterLogo },
			{
				name: "GoogleScholar",
				label: "Google Scholar",
				link: "",
				image: GoogleScholarLogo,
			},
			{
				name: "ResearchGate",
				label: "Research Gate",
				link: "",
				image: ResearchGateLogo,
			},
		],
		connectTimestamp: null,
	};

	componentDidMount() {
		// emit socket event to let know that we have a visitor on booth
		if (this.props.activeExhibitorId) {
			this.setState({ connectTimestamp: moment.utc() });
			connectToBooth(this.props.activeExhibitorId, this.props.user._id);
		}

		// this component is also used for the exhibitor-booth-preview
		// so if we already have the data, set up the booth
		const { exhibitor } = this.props;
		if (exhibitor) {
			this.handleSetUpBoothData();
		}

		this.setupBeforeUnloadListener();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.loadingExhibitor === true &&
			this.props.loadingExhibitor === false
		) {
			// we got the exhibitor
			// we can now set up the booth Data
			this.handleSetUpBoothData();
		}
		if (prevProps.activeExhibitorId !== this.props.activeExhibitorId) {
			const { connectTimestamp } = this.state;
			let connectTimestampOrNow = connectTimestamp;
			if (!connectTimestampOrNow) {
				connectTimestampOrNow = moment.utc();
			}

			if (prevProps.activeExhibitorId) {
				const isRepresentative = checkBoothOwnerOrRepresentative(
					this.props.exhibitor,
					this.props.user?._id,
				);
				let trackBoothStatistics = !isRepresentative;

				const { event, user } = this.props;
				const { owner } = event;
				if (owner._id === user._id) {
					trackBoothStatistics = false;
					let findEventRoles = user.eventRoles.find(
						(el) => el?.event?._id === event?._id,
					);
					if (findEventRoles) {
						if (findEventRoles?.roles?.includes("participant")) {
							trackBoothStatistics = true;
						}
					}
				}

				disconnectFromBooth(
					prevProps.activeExhibitorId,
					this.props.user._id,
					connectTimestampOrNow,
					moment.utc(),
					trackBoothStatistics,
				);
			}
			if (this.props.activeExhibitorId) {
				this.setState({ connectTimestamp: moment.utc() });
				connectToBooth(this.props.activeExhibitorId, this.props.user._id);
			}
		}
	}

	componentWillUnmount() {
		const { connectTimestamp } = this.state;
		let connectTimestampOrNow = connectTimestamp;
		if (!connectTimestampOrNow) {
			connectTimestampOrNow = moment.utc();
		}

		// emit socket event to let know that a visitor has left the booth
		if (this.props.activeExhibitorId) {
			const isRepresentative = checkBoothOwnerOrRepresentative(
				this.props.exhibitor,
				this.props.user?._id,
			);
			let trackBoothStatistics = !isRepresentative;

			const { event, user } = this.props;
			const { owner } = event;
			if (owner._id === user._id) {
				trackBoothStatistics = false;
				let findEventRoles = user.eventRoles.find(
					(el) => el?.event?._id === event?._id,
				);
				if (findEventRoles) {
					if (findEventRoles?.roles?.includes("participant")) {
						trackBoothStatistics = true;
					}
				}
			}

			disconnectFromBooth(
				this.props.activeExhibitorId,
				this.props.user._id,
				connectTimestampOrNow,
				moment.utc(),
				trackBoothStatistics,
			);
		}
	}

	setupBeforeUnloadListener = () => {
		window.addEventListener("beforeunload", (ev) => {
			const { allEventUsersObject } = this.props;
			ev.preventDefault();
			const participant = allEventUsersObject[this.props.user._id];
			const { connectTimestamp } = this.state;
			let connectTimestampOrNow = connectTimestamp;
			if (!connectTimestampOrNow) {
				connectTimestampOrNow = moment.utc();
			}

			if (participant && this.state.exhibitor._id) {
				const isRepresentative = checkBoothOwnerOrRepresentative(
					this.props.exhibitor,
					this.props.user._id,
				);
				let trackBoothStatistics = !isRepresentative;

				const { event, user } = this.props;
				const { owner } = event;
				if (owner._id === user._id) {
					trackBoothStatistics = false;
					let findEventRoles = user.eventRoles.find(
						(el) => el?.event?._id === event?._id,
					);
					if (findEventRoles) {
						if (findEventRoles?.roles?.includes("participant")) {
							trackBoothStatistics = true;
						}
					}
				}

				disconnectFromBooth(
					this.state.exhibitor._id,
					this.props.user._id,
					connectTimestampOrNow,
					moment.utc(),
					trackBoothStatistics,
				);
			}
		});
	};

	handleSetUpBoothData = () => {
		const { exhibitor } = this.props;
		// get the exhibitor data from the event
		const boothData = exhibitor.booth;

		// set the active Wall to the booth Wall of this exhibitor
		// set up the array of users that can moderate posts and comments
		// set up the array of users that we want to highlight the posts for
		// in the booth wall, we need to highlight the representatives posts

		let highlightUsers = [];
		let canModerateChat = [];

		if (exhibitor.user) {
			canModerateChat.push(exhibitor.user);
		}

		// created an array of "mainPositions" to easily loop through them and add the userId's to highlightUsers and moderators arrays
		const mainRepresentativePositions = [
			"deskRepresentativeLeft",
			"deskRepresentativeRight",
			"presentingRepresentative",
		];
		mainRepresentativePositions.forEach((mainRepresentativePosition) => {
			const mainRepresentativeUserData =
				boothData[mainRepresentativePosition].user;
			if (mainRepresentativeUserData) {
				highlightUsers.push(mainRepresentativeUserData._id);
				canModerateChat.push(mainRepresentativeUserData._id);
			}
		});

		boothData.medalionRepresentatives.forEach((representative) => {
			highlightUsers.push(representative.user._id);
			canModerateChat.push(representative.user._id);
		});

		const wallData = {
			wallId: exhibitor.booth.boothWall,
			highlightUsers: highlightUsers,
			canModerateChat: canModerateChat,
		};

		this.props.setActiveWall(wallData);

		// set Up social Links
		let updatedSocialLinks = [...this.state.socialLinks];
		updatedSocialLinks.forEach((socialLink, socialLinkIndex) => {
			let currentSocialLink = exhibitor.booth.socialMediaLinks.find(
				(link) => link.platform === socialLink.name,
			);
			if (currentSocialLink) {
				updatedSocialLinks[socialLinkIndex].link = currentSocialLink.link;
			} else {
				updatedSocialLinks[socialLinkIndex].link = "";
			}
		});
		let exhibitorVideoId = getVideoIdFromYoutubeUrl(exhibitor.booth.video);

		// save in state the exhibitor data (including booth data)
		this.setState({
			exhibitor: exhibitor,
			exhibitorVideoId: exhibitorVideoId,
			socialLinks: updatedSocialLinks,
		});
	};

	showLinks = () => {
		this.setState({ show: { ...this.initialShow, links: true } });
	};

	showDocuments = () => {
		this.setState({ show: { ...this.initialShow, documents: true } });
	};

	showVideo = () => {
		this.setState({ show: { ...this.initialShow, video: true } });
	};

	closeDialog = () => {
		this.setState({ show: { ...this.initialShow } });
	};

	handleClickOnLink = (linkId, linkName) => (e) => {
		const actionType = "boothLinks";
		const isRepresentative = checkBoothOwnerOrRepresentative(
			this.props.exhibitor,
			this.props.user._id,
		);
		let trackBoothStatistics = !isRepresentative;

		const { event, user } = this.props;
		const { owner } = event;
		if (owner._id === user._id) {
			trackBoothStatistics = false;
			let findEventRoles = user.eventRoles.find(
				(el) => el?.event?._id === event?._id,
			);
			if (findEventRoles) {
				if (findEventRoles?.roles?.includes("participant")) {
					trackBoothStatistics = true;
				}
			}
		}

		if (linkId && linkName && trackBoothStatistics) {
			const data = {
				userId: this.props.user._id,
				linkId: linkId,
				linkName: linkName,
				actionType: actionType,
				timestamp: moment.utc(),
			};
			axios({
				method: "post",
				url: "/exhibitor/" + this.state.exhibitor._id + "/booth-statistics",
				data: data,
			})
				.then((res) => {})
				.catch((err) => {});
		}
	};

	handleClickOnDocument = (documentId, documentName) => (e) => {
		const actionType = "boothDocuments";
		const isRepresentative = checkBoothOwnerOrRepresentative(
			this.props.exhibitor,
			this.props.user._id,
		);
		let trackBoothStatistics = !isRepresentative;

		const { event, user } = this.props;
		const { owner } = event;
		if (owner._id === user._id) {
			trackBoothStatistics = false;
			let findEventRoles = user.eventRoles.find(
				(el) => el?.event?._id === event?._id,
			);
			if (findEventRoles) {
				if (findEventRoles?.roles?.includes("participant")) {
					trackBoothStatistics = true;
				}
			}
		}

		if (documentId && documentName && trackBoothStatistics) {
			const data = {
				userId: this.props.user._id,
				documentId: documentId,
				documentName: documentName,
				actionType: actionType,
				timestamp: moment.utc(),
			};
			axios({
				method: "post",
				url: "/exhibitor/" + this.state.exhibitor._id + "/booth-statistics",
				data: data,
			})
				.then((res) => {})
				.catch((err) => {});
		}
	};

	handleVideoExit = (connectTimestamp) => {
		const actionType = "boothVideos";
		const isRepresentative = checkBoothOwnerOrRepresentative(
			this.props.exhibitor,
			this.props.user._id,
		);
		let trackBoothStatistics = !isRepresentative;

		const { event, user } = this.props;
		const { owner } = event;
		if (owner._id === user._id) {
			trackBoothStatistics = false;
			let findEventRoles = user.eventRoles.find(
				(el) => el?.event?._id === event?._id,
			);
			if (findEventRoles) {
				if (findEventRoles?.roles?.includes("participant")) {
					trackBoothStatistics = true;
				}
			}
		}

		if (connectTimestamp && trackBoothStatistics) {
			const data = {
				userId: this.props.user._id,
				connectTimestamp: connectTimestamp,
				disconnectTimestamp: moment.utc(),
				actionType: actionType,
			};
			axios({
				method: "post",
				url: "/exhibitor/" + this.state.exhibitor._id + "/booth-statistics",
				data: data,
			})
				.then((res) => {})
				.catch((err) => {});
		}
	};

	handleOpenChatWithRepresentative = (userId) => (e) => {
		const privateChatId = getPrivateChatId(
			this.props.user.privateChats,
			userId,
		);

		// do not open chat with myself
		if (this.props.user._id !== userId) {
			if (privateChatId) {
				this.props.onOpenPrivateChat(privateChatId);
			} else {
				if (userId !== this.props.user._id && !this.props.loading) {
					if (userId !== this.props.user._id) {
						this.props.onCreatePrivateChat(userId);
					}
				}
			}

			// booth statistics
			const actionType = "boothRepresentatives";
			const isRepresentative = checkBoothOwnerOrRepresentative(
				this.props.exhibitor,
				this.props.user._id,
			);
			let trackBoothStatistics = !isRepresentative;

			const { event, user } = this.props;
			const { owner } = event;
			if (owner._id === user._id) {
				trackBoothStatistics = false;
				let findEventRoles = user.eventRoles.find(
					(el) => el?.event?._id === event?._id,
				);
				if (findEventRoles) {
					if (findEventRoles?.roles?.includes("participant")) {
						trackBoothStatistics = true;
					}
				}
			}

			if (userId && trackBoothStatistics) {
				const { allEventUsersObject } = this.props;
				let userData = allEventUsersObject[userId];
				let fullName = "";
				if (userData) {
					fullName = `${userData?.first} ${userData?.last}`;
				}
				if (fullName !== "") {
					const data = {
						userId: this.props.user._id,
						representativeId: userId,
						representativeName: fullName,
						actionType: actionType,
						timestamp: moment.utc(),
					};
					axios({
						method: "post",
						url: "/exhibitor/" + this.state.exhibitor._id + "/booth-statistics",
						data: data,
					})
						.then((res) => {})
						.catch((err) => {});
				}
			}
		}
	};

	checkExhibitorHasLinks = () => {
		const { socialLinks } = this.state;
		const { exhibitor } = this.props;
		if (exhibitor?.booth.links.length > 0) {
			return true;
		}

		const visibleSocialLinks = socialLinks.filter(
			(socialLink) => socialLink.link !== "",
		);
		return visibleSocialLinks.length > 0;
	};

	getSliderSocialLinksSettings = () => {
		return {
			dots: false,
			arrows: false,
			autoplay: true,
			draggable: false,
			pauseOnHover: false,
			swipe: false,
			infinite: true,
			speed: 750,
			slidesToShow: 1,
			slidesToScroll: 1,
		};
	};

	render() {
		const { exhibitorVideoId, socialLinks } = this.state;
		const { showfloorData, exhibitor } = this.props;
		let sliderSettingsBooth = this.getSliderSocialLinksSettings();
		const hasLinks = this.checkExhibitorHasLinks();

		return (
			<>
				{exhibitor ? (
					<div>
						<div
							onDragStart={preventDefaultDrag}
							className="single-booth-participant-view"
						>
							{this.props.exhibitors.length > 1 &&
								this.props.hasAccessToShowfloor && (
									<React.Fragment>
										<div
											onDragStart={preventDefaultDrag}
											className="button previous"
											onClick={this.props.previousExhibitor}
										>
											<div
												onDragStart={preventDefaultDrag}
												className="button-container"
											>
												<div
													onDragStart={preventDefaultDrag}
													className="details"
												>
													<Arrow stroke={colors.primary} />
												</div>
												{showfloorData?.type === "exhibitors" && (
													<div
														onDragStart={preventDefaultDrag}
														className="logo-container"
													>
														{this.props.previousExhibitorHasLogo ? (
															<img
																draggable="false"
																onDragStart={preventDefaultDrag}
																src={this.props.previousExhibitorLogo}
																alt="Next"
															/>
														) : (
															<p>{this.props.previousExhibitorCompany}</p>
														)}
													</div>
												)}
											</div>
										</div>
										<div
											onDragStart={preventDefaultDrag}
											className="button next"
											onClick={this.props.nextExhibitor}
										>
											<div
												onDragStart={preventDefaultDrag}
												className="button-container"
											>
												<div
													onDragStart={preventDefaultDrag}
													className="details"
												>
													<Arrow stroke={colors.primary} />
												</div>
												{showfloorData?.type === "exhibitors" && (
													<div
														onDragStart={preventDefaultDrag}
														className="logo-container"
													>
														{this.props.nextExhibitorHasLogo ? (
															<img
																draggable="false"
																onDragStart={preventDefaultDrag}
																src={this.props.nextExhibitorLogo}
																alt="Next"
															/>
														) : (
															<p>{this.props.nextExhibitorCompany}</p>
														)}
													</div>
												)}
											</div>
										</div>
									</React.Fragment>
								)}

							<BoothFullPreview exhibitor={exhibitor} />
							<div onDragStart={preventDefaultDrag} className="centerOfPage">
								<div
									onDragStart={preventDefaultDrag}
									className="relativeCenter"
								>
									{hasLinks && (
										<div
											className={`clickable-element ${exhibitor?.type}`}
											id="show-links"
											onClick={this.showLinks}
										>
											<Slider {...sliderSettingsBooth}>
												{socialLinks.map((socialLink) => {
													if (!socialLink.link) {
														return null;
													}
													return (
														<div
															onDragStart={preventDefaultDrag}
															key={socialLink.name}
														>
															<img
																draggable="false"
																onDragStart={preventDefaultDrag}
																className="social-logo-image"
																src={socialLink.image}
																alt={socialLink.label}
															/>
														</div>
													);
												})}
												{exhibitor.booth.links.length > 0 && (
													<div>
														<img
															draggable="false"
															onDragStart={preventDefaultDrag}
															className="social-logo-image"
															src={GenericLinkLogo}
															alt="Social Logo"
														/>
													</div>
												)}
											</Slider>
										</div>
									)}

									{exhibitor.booth.documents.length > 0 && (
										<div
											className="clickable-element"
											id="show-documents"
											onClick={this.showDocuments}
										/>
									)}
									{exhibitor.booth.video ? (
										<div
											onDragStart={preventDefaultDrag}
											className={`clickable-element ${exhibitor?.type}`}
											id="show-video"
										>
											<div
												className="image-preview-video"
												style={{
													backgroundImage: `url('${
														"https://img.youtube.com/vi/" +
														exhibitorVideoId +
														"/0.jpg"
													}')`,
												}}
												onClick={this.showVideo}
											>
												<div
													className="play-button"
													style={{ backgroundImage: `url('${PlayIcon}')` }}
												/>
											</div>
										</div>
									) : (
										<>
											{exhibitor.booth.videoImage ? (
												<div
													onDragStart={preventDefaultDrag}
													className={`clickable-element no-hover ${exhibitor?.type}`}
													id="show-video"
												>
													<div
														onDragStart={preventDefaultDrag}
														className="image-preview-video no-video"
													>
														<img
															draggable="false"
															onDragStart={preventDefaultDrag}
															src={
																exhibitor.filesUrl + exhibitor.booth.videoImage
															}
															alt="Preview Video"
														/>
													</div>
												</div>
											) : (
												<>
													{exhibitor?.type === "scholar" ? null : (
														<div
															className={`clickable-element no-hover ${exhibitor?.type}`}
															id="show-video"
														>
															<div
																onDragStart={preventDefaultDrag}
																className="image-preview-video no-video"
															>
																<img
																	draggable="false"
																	onDragStart={preventDefaultDrag}
																	src={NoVideoImage}
																	alt="Preview Default"
																/>
															</div>
														</div>
													)}
												</>
											)}
										</>
									)}
									{exhibitor.booth.deskRepresentativeLeft.user && (
										<div
											className={
												"clickable-element desk-representative left " +
												(exhibitor.booth.deskRepresentativeLeft.user._id ===
												this.props.user._id
													? "no-hover"
													: "")
											}
											onClick={this.handleOpenChatWithRepresentative(
												exhibitor.booth.deskRepresentativeLeft.user._id,
											)}
										>
											<img
												draggable="false"
												onDragStart={preventDefaultDrag}
												src={
													exhibitor.booth.deskRepresentativeLeft.deskAvatarUrl
												}
												alt="avatar"
											/>
											{exhibitor.booth.deskRepresentativeLeft.user._id !==
												this.props.user._id && (
												<div
													onDragStart={preventDefaultDrag}
													className="relativeCenter"
												>
													<div
														onDragStart={preventDefaultDrag}
														className="hovering-container"
													/>
												</div>
											)}
										</div>
									)}
									{exhibitor.booth.deskRepresentativeRight.user && (
										<div
											className={
												"clickable-element desk-representative right " +
												(exhibitor.booth.deskRepresentativeRight.user._id ===
												this.props.user._id
													? "no-hover"
													: "")
											}
											onClick={this.handleOpenChatWithRepresentative(
												exhibitor.booth.deskRepresentativeRight.user._id,
											)}
										>
											<img
												draggable="false"
												onDragStart={preventDefaultDrag}
												src={
													exhibitor.booth.deskRepresentativeRight.deskAvatarUrl
												}
												alt="avatar"
											/>
											{exhibitor.booth.deskRepresentativeRight.user._id !==
												this.props.user._id && (
												<div
													onDragStart={preventDefaultDrag}
													className="relativeCenter"
												>
													<div
														onDragStart={preventDefaultDrag}
														className="hovering-container"
													/>
												</div>
											)}
										</div>
									)}
									{exhibitor.booth.presentingRepresentative.user && (
										<div
											className={
												"clickable-element presenting-representative " +
												(exhibitor.booth.presentingRepresentative.user._id ===
												this.props.user._id
													? "no-hover"
													: "")
											}
											onClick={this.handleOpenChatWithRepresentative(
												exhibitor.booth.presentingRepresentative.user._id,
											)}
										>
											<img
												draggable="false"
												onDragStart={preventDefaultDrag}
												src={
													exhibitor.booth.presentingRepresentative.deskAvatarUrl
												}
												alt="avatar"
											/>
											{exhibitor.booth.presentingRepresentative.user._id !==
												this.props.user._id && (
												<div
													onDragStart={preventDefaultDrag}
													className="relativeCenter"
												>
													<div
														onDragStart={preventDefaultDrag}
														className="hovering-container"
													/>
												</div>
											)}
										</div>
									)}
									<div
										onDragStart={preventDefaultDrag}
										className={`clickable-element medalion-representatives ${exhibitor?.type}`}
									>
										{exhibitor.booth.medalionRepresentatives.map(
											(representative) => {
												let sameUser =
													this.props.user._id === representative.user._id;
												return (
													<div
														className="single-medalion-representative"
														key={representative._id}
														onClick={this.handleOpenChatWithRepresentative(
															representative.user._id,
														)}
													>
														<div
															className={
																"image-container " +
																(sameUser ? "no-hover-effect" : "")
															}
															style={{
																backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${representative.user.avatarSmall}')`,
															}}
														/>
													</div>
												);
											},
										)}
									</div>
									{exhibitor?.type === "company" && (
										<div
											onDragStart={preventDefaultDrag}
											id="logo"
											className="clickable-element no-hover"
										>
											{exhibitor.booth.logo.length < 1 ? null : (
												<img
													draggable="false"
													onDragStart={preventDefaultDrag}
													src={exhibitor.filesUrl + exhibitor.booth.logo}
													alt="Exhib Logo"
												/>
											)}
										</div>
									)}
									<PdfPreview />
								</div>
							</div>
							<BoothLinks
								opened={this.state.show.links}
								closeDialog={this.closeDialog}
								links={exhibitor.booth.links}
								socialLinks={exhibitor.booth.socialMediaLinks}
								onClickOnLink={this.handleClickOnLink}
							/>
							<BoothDocuments
								opened={this.state.show.documents}
								closeDialog={this.closeDialog}
								documents={exhibitor.booth.documents}
								filesUrl={exhibitor.filesUrl}
								onClickOnDocument={this.handleClickOnDocument}
							/>
							{this.state.show.video && (
								<VideoDialog
									opened={this.state.show.video}
									closeDialog={this.closeDialog}
									videoUrl={exhibitor.booth.video}
									onVideoExit={this.handleVideoExit}
								/>
							)}
						</div>
					</div>
				) : null}
				<NotificationPresentationStarts />
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		event: state.event.data,
		allEventUsersObject: state.eventUsers.allEventUsersObject,
		user: state.user.data,
		loading: state.user.loading,
		activeExhibitorId: state.exhibitors.activeExhibitorId,
		exhibitor: state.exhibitor.data,
		loadingExhibitor: state.exhibitor.loading,
		exhibitors: state.exhibitors.approvedExhibitors,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setActiveWall: (wallData) => dispatch(actions.setActiveWall(wallData)),
		onOpenPrivateChat: (privateChatId) =>
			dispatch(actions.openPrivateChat(privateChatId)),
		onCreatePrivateChat: (targetUserId) =>
			dispatch(actions.createPrivateChat(targetUserId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitorBooth);
