import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import ThumbnailImage from "./ThumbnailImage/ThumbnailImage";
import {Switch} from "@material-ui/core";
import {connect} from "react-redux";
import * as actions from "../../store/actions";

class SessionSettings extends Component {

    onToggleCanRequestMic = (e) => {
        const {liveSession} = this.props;
        const sessionId = liveSession._id;
        const canRequireMic = e.target.checked;

        this.props.handleToggleCanRequestMic(sessionId, canRequireMic)
    }

    render() {
        const {translation, defaultTranslation, liveSession, liveSessionUser} = this.props;
        const sessionHasRecords = liveSession.records.length > 0;
        return (
            <>
                {liveSessionUser?.role === "organizer" &&
                    <ThumbnailImage
                        translation={translation}
                        defaultTranslation={defaultTranslation}
                    />
                }
                <div className={'module-link_block'}>
                    <span>Request Microphone</span>
                    <Switch
                        className={`can-request-microphone-switch`}
                        checked={liveSession?.canRequestMic}
                        disabled={sessionHasRecords && !liveSession.isActive}
                        onClick={this.onToggleCanRequestMic}
                        color="secondary"
                    />
                </div>
            </>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        liveSession: state.liveSession.liveSession,
        liveSessionUser: state.liveSession.liveSessionUser,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleToggleCanRequestMic: (sessionId, canRequestMic) => dispatch(actions.toggleCanRequestMic(sessionId, canRequestMic)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SessionSettings));