import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions";
import Spinner from "../../../../SmallLayoutComponents/Spinner";
import {
	getTimeslotEndTimestamp,
	getTimeslotStartTimestamp,
	getUtcTimestamp,
	preventDefaultDrag,
} from "../../../../Utils/utils";
import { ReactComponent as EmptyIcon } from "../../../../Images/svg/empty-sessions.svg";
import SingleSmartSuggestion from "../SmartSuggestions/SingleSmartSuggestion";
import { ReactComponent as GetAppIcon } from "../../../../Images/svg/download.svg";
import colors from "../../../../CSS/_variables.module.scss";
import ProgrammeSearch from "./ProgrammeSearch";
import SelectTimezone from "Components/SelectTimezone";
import moment from "moment";
import { cloneDeep } from "lodash";

class Programme extends PureComponent {
	scrollContainer = React.createRef();

	state = {
		expanded: null,
	};

	toggleExpandedSession = (id) => (e) => {
		e.preventDefault();
		this.setState((prevState) => ({
			expanded: prevState.expanded === id ? null : id,
		}));
	};

	addSessionToAgenda = (sessionId) => {
		this.props.addSessionAgenda(sessionId);
		this.createExternalCalendarData(sessionId);
	};

	createExternalCalendarData = (sessionId) => {
		const { myProgramme } = this.props;
		const timeslotData = myProgramme.find(
			(programme) => programme._id === sessionId,
		);
		const eventCalendarData = {
			title: timeslotData.title,
			description: timeslotData.description,
			location: window.location.origin,
			start: timeslotData.startTimestamp,
			end: timeslotData.endTimestamp,
		};
		const calendarEventType = "session";
		this.props.showAddToExternalCalendarDialog(
			eventCalendarData,
			calendarEventType,
		);
	};

	calculateTimezoneDifference = () => {
		const { event, user } = this.props;

		const userTimezoneValue = user?.timezoneValue;
		let timezoneDifference = 0;
		if (userTimezoneValue || userTimezoneValue === 0) {
			timezoneDifference = userTimezoneValue - event?.timezoneValue;
		}
		return timezoneDifference;
	};

	recalculateTimeslotByTimezone = (timeslot, timezoneDifference) => {
		const { event } = this.props;

		let startTimestampWithoutLocalTimezone = moment
			.utc(timeslot.startTimestamp)
			.format("l LT");
		let endTimestampWithoutLocalTimezone = moment
			.utc(timeslot.endTimestamp)
			.format("l LT");
		let timezoneValueToBeAdded =
			Number(event?.timezoneValue) + timezoneDifference;

		timeslot.startTimestamp = moment(startTimestampWithoutLocalTimezone).add(
			timezoneValueToBeAdded,
			"hours",
		);
		timeslot.endTimestamp = moment(endTimestampWithoutLocalTimezone).add(
			timezoneValueToBeAdded,
			"hours",
		);

		timeslot.start = moment(timeslot.startTimestamp).format("HH:mm");
		timeslot.end = moment(timeslot.endTimestamp).format("HH:mm");

		return timeslot;
	};

	checkIfTimeslotIsLive = (timeslot, eventTimezoneValue) => {
		let currentTime = new Date();
		let currentTimeStamp = getUtcTimestamp(currentTime);

		let timeslotDate = timeslot.startTimestamp.split("T")[0];

		let timeSlotStartTimestamp = getTimeslotStartTimestamp(
			timeslotDate,
			timeslot,
			eventTimezoneValue,
		);
		let timeSlotEndTimestamp = getTimeslotEndTimestamp(
			timeslotDate,
			timeslot,
			eventTimezoneValue,
		);

		return (
			currentTimeStamp >= timeSlotStartTimestamp &&
			currentTimeStamp < timeSlotEndTimestamp
		);
	};

	render() {
		const { expanded } = this.state;
		const {
			event,
			eventId,
			myProgrammeLoading,
			myProgramme,
			programmeSearch,
			isLargeScreen,
			languages,
			translation,
			defaultTranslation,
		} = this.props;

		const _myProgramme = myProgramme.filter(
			(programme) => programme.programVisible ?? true,
		);

		const displaySearchResults = (timeslotsResults) => {
			let timezoneDifference = this.calculateTimezoneDifference();

			// if timezone difference is 0
			// we set the api call response as the timeslots
			// if(timezoneDifference === 0) {
			//     return setTimeslots(timeslotsResults)
			// }

			// update timeslot properties (recalculate based on timezone difference)
			let formattedTimeslots = [];

			// biome-ignore lint/complexity/noForEach: <explanation>
			_myProgramme.forEach((timeslotResult) => {
				let newTimeslot = cloneDeep(timeslotResult);
				newTimeslot = this.recalculateTimeslotByTimezone(
					newTimeslot,
					timezoneDifference,
				);
				newTimeslot.isLive = this.checkIfTimeslotIsLive(
					timeslotResult,
					event.timezoneValue,
				);
				formattedTimeslots.push(newTimeslot);
			});

			return formattedTimeslots;
		};

		const getSearchTitle = () => {
			const { programSearchInterval } = event;

			switch (programSearchInterval) {
				case "Past":
					return (
						translation?.program?.searchWrapperTitlePast ||
						defaultTranslation?.program?.searchWrapperTitlePast
					);
				case "Upcoming":
					return (
						translation?.program?.searchWrapperTitleUpcoming ||
						defaultTranslation?.program?.searchWrapperTitleUpcoming
					);
				case "Interval":
					return (
						translation?.program?.searchWrapperTitleInterval ||
						defaultTranslation?.program?.searchWrapperTitleInterval
					);
				default:
					return (
						translation?.program?.searchWrapperTitle ||
						defaultTranslation?.program?.searchWrapperTitle
					);
			}
		};

		return (
			<div className="programme">
				{myProgrammeLoading && <Spinner />}
				<div className="programme-wrapper">
					<div className="scroll-left-container" ref={this.scrollContainer}>
						<div>
							<ProgrammeSearch
								scrollContainerRef={this.scrollContainer}
								isProgramEmpty={myProgramme?.length === 0}
							/>
							<div
								onDragStart={preventDefaultDrag}
								className="programme-wrapper-title"
							>
								<p>{getSearchTitle()}</p>
								{event.brandingData.programFile && (
									<DownloadProgramButton
										eventId={event._id}
										programFile={event.brandingData.programFile}
										translation={translation}
										defaultTranslation={defaultTranslation}
									/>
								)}
								<SelectTimezone />
							</div>
							{_myProgramme?.length > 0 ? (
								<>
									{displaySearchResults()?.map((session) => {
										return (
											<SingleSmartSuggestion
												key={session._id}
												suggestion={session}
												isAddedToAgenda={session.isAddedToAgenda}
												event={event}
												eventId={eventId}
												expanded={expanded}
												toggleDescription={this.toggleExpandedSession(
													session._id,
												)}
												isLargeScreen={isLargeScreen}
												languages={languages}
												translation={translation}
												defaultTranslation={defaultTranslation}
												addSessionAgenda={this.addSessionToAgenda}
												removeSessionAgenda={this.props.removeSessionAgenda}
												userTimezone={this.props.user.timezoneValue}
											/>
										);
									})}
								</>
							) : (
								<div className="empty-data">
									<div>
										<EmptyIcon />
										<p>
											{programmeSearch
												? translation?.program?.emptySearch ||
												  defaultTranslation?.program?.emptySearch
												: translation?.program?.emptyProgramme ||
												  defaultTranslation?.program?.emptyProgramme}
										</p>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		event: state.event.data,
		eventId: state.event.eventId,
		myProgramme: state.event.myProgramme,
		myProgrammeLoading: state.event.myProgrammeLoading,
		user: state.user.data,
		programmeSearch: state.event.programmeSearch,
		isLargeScreen: state.layout.isLargeScreen,
		languages: state.languages,
		translation: state.languages.translations[state.languages.platformLanguage],
		defaultTranslation: state.languages.translations["en"],
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addSessionAgenda: (sessionId) =>
			dispatch(actions.addSessionToAgenda(sessionId)),
		removeSessionAgenda: (sessionId) =>
			dispatch(actions.removeSessionFromAgenda(sessionId)),
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Programme),
);

const DownloadProgramButton = ({
	eventId,
	programFile,
	className,
	translation,
	defaultTranslation,
}) => {
	return (
		<div className={`download-program ${className}`}>
			<a
				href={`${process.env.REACT_APP_EVENT_FOLDER}${eventId}/${programFile}`}
				target="_blank"
				rel="noreferrer"
				className="download-program-link"
			>
				<GetAppIcon tabIndex="0" className="button" fill={colors.secondary} />
				<span>
					{translation?.generalText.download ||
						defaultTranslation?.generalText.download}
				</span>
			</a>
		</div>
	);
};
