import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {preventDefaultDrag} from '../../Utils/utils';
import ChangePasswordAccount from './ChangePasswordAccount';
import DeleteAccount from './DeleteAccount';
import UnregisterEvent from './UnregisterEvent';
import * as actions from '../../store/actions';
import axios from '../../store/axios-instance';
import {ReactComponent as UploadImage} from '../../Images/svg/upload-cloud.svg';
import CloseIcon from '@material-ui/icons/Close';
import EditUserInfoForm from './EditUserInfoForm';
import EditParticipantInfoForm from './EditParticipantInfoForm';
import EditExhibitorInfoForm from './EditExhibitorInfoForm';
import EnableParticipantProfile from './EnableParticipantProfile';
import EditScholarInfoForm from './EditScholarInfoForm';

class AccountDetails extends React.Component {
    state = {
        imgErrorText: '',
    };

    handleImageChange = (e) => {
        const {languages} = this.props;
        e.preventDefault();
        let file = e.target.files[0];

        let isValid = true;

        if (file) {
            isValid = file.size < 2 * 1024 * 1024 && isValid;
            if (!isValid) {
                this.setState({
                    imgErrorText: languages.translations[languages.platformLanguage]?.errors.fileTooLarge,
                });
            }

            isValid = (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/gif') && isValid;
            if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg' && file.type !== 'image/gif') {
                this.setState({
                    imgErrorText: `${
                        languages.translations[languages.platformLanguage]?.errors.imageFileNotSupported
                    } jpeg, jpg, jfif, gif or png.`,
                });
            }
        }

        if (isValid) {
            this.setState({imgErrorText: ''});
            const formData = new FormData();

            formData.append('avatar', file, file.name, file.type);
            axios({method: 'post', url: '/users/me/avatar', data: formData})
                .then((response) => {
                    this.props.onRefreshUserData();
                })
                .catch((error) => {
                    this.props.onRefreshUserData();
                });
        }

        e.target.value = '';
    };

    handleRemoveUserAvatar = () => {
        axios({method: 'delete', url: '/users/me/avatar'})
            .then((response) => {
                this.props.onRefreshUserData();
            })
            .catch((error) => {});
    };

    render() {
        const {imgErrorText} = this.state;
        const {
            user,
            event,
            eventRoles,
            participantData,
            exhibitorData,
            accessPackageData,
            exhibitorAccessPackageData,
            translation,
            defaultTranslation
        } = this.props;

        return (
            <div className="scroll-left-container">
                <div>
                    <div onDragStart={preventDefaultDrag} className="edit-section-container onvent-profile">
                        <div onDragStart={preventDefaultDrag} className="edit-section-header">
                            <h3>
                                {translation?.myAccountPage.accountDetails ||
                                defaultTranslation?.myAccountPage.accountDetails}
                            </h3>
                            <div>
                                <ChangePasswordAccount translation={translation} />
                                <DeleteAccount translation={translation} defaultTranslation={defaultTranslation} />
                            </div>
                        </div>
                        <div className="edit-user-personal-info-container">
                            <div onDragStart={preventDefaultDrag} className="image-container">
                                <div
                                    className="avatar"
                                    style={{
                                        backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${user.avatarBig}')`,
                                    }}
                                />
                                <div onDragStart={preventDefaultDrag} className="upload-new-image">
                                    {user.avatarBig !== 'default_avatar.png' && (
                                        <button
                                            onClick={this.handleRemoveUserAvatar}
                                            disabled={user.avatarBig === 'default_avatar.png'}
                                        >
                                            <CloseIcon/>
                                            {translation?.myAccountPage.removeImage}
                                        </button>
                                    )}
                                    <div onDragStart={preventDefaultDrag} className="upload-container">
                                        <label htmlFor="file" className="select-image" />
                                        <input
                                            onChange={this.handleImageChange}
                                            name="file"
                                            type="file"
                                            id="file"
                                            className="d-none"
                                        />
                                        <span>
                                            <UploadImage/>
                                            {translation?.myAccountPage.uploadImage}
                                        </span>
                                    </div>
                                    {imgErrorText && (
                                        <span onDragStart={preventDefaultDrag} draggable="false" className="image-error">
                                        {imgErrorText}
                                    </span>
                                    )}
                                </div>
                            </div>
                            <EditUserInfoForm/>
                        </div>
                    </div>

                    {(participantData || exhibitorData) && (
                        <div onDragStart={preventDefaultDrag} className="edit-section-container event-profile">
                            <div className="your-info-section">
                                <div onDragStart={preventDefaultDrag} className="edit-section-header">
                                    <h3>
                                        {translation?.myAccountPage.yourInformation ||
                                        defaultTranslation?.myAccountPage.yourInformation}
                                    </h3>
                                    <div>
                                        <UnregisterEvent />
                                    </div>
                                </div>
                                {!eventRoles.isExhibitor && participantData && (
                                    <div className="edit-participant-info-container">
                                        <EditParticipantInfoForm
                                            participant={participantData}
                                        />
                                        {event.hasAccessManagement && accessPackageData && (
                                            <div>
                                                <p>
                                                    <span>{translation?.myAccountPage.eventPackage}: </span>
                                                    {accessPackageData.name}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {eventRoles.isExhibitor && exhibitorData && (
                                    <div className="edit-participant-info-container">
                                        {exhibitorData?.type === 'scholar'
                                        ?
                                            <EditScholarInfoForm
                                                exhibitor={exhibitorData}
                                            />
                                        :
                                            <EditExhibitorInfoForm
                                                exhibitor={exhibitorData}
                                            />
                                        }
                                        {(event.exhibitorHasAccessManagement || event.scholarHasAccessManagement) &&
                                        exhibitorAccessPackageData && (
                                            <div>
                                                <p>
                                                    <span>{translation?.myAccountPage.eventPackage}: </span>
                                                    {exhibitorAccessPackageData.name}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {/* an organizer can become participant when : event is published and organizer doesn't already have participant profile */}
                    {!participantData && eventRoles.isOrganizer && event.status === 'public' && (
                        <div onDragStart={preventDefaultDrag} className="edit-section-container event-profile">
                            <div>
                                <div onDragStart={preventDefaultDrag} className="edit-section-header">
                                    <h3>{translation?.myAccountPage.enableParticipantProfile}</h3>
                                </div>
                                <div className="edit-participant-info-container">
                                    <EnableParticipantProfile/>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        eventRoles: state.user.eventRoles,
        event: state.event.data,
        eventSlug: state.event.eventSlug,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRefreshUserData: () => dispatch(actions.refreshUserData()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountDetails));