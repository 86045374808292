import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions';
import './PosterDetails.scss';
import {ReactComponent as ButtonArrow} from '../FeaturedExhibitorsSlider/arrow_image.svg';
import {preventDefaultDrag} from '../../../Utils/utils';

class PosterDetails extends React.Component {
    state = {
        sliderContainerStyles: {
            maxWidth: ((window.innerHeight - 315) * 132.7) / 100,
        },
        showAllExhibitorsOverlay: false,
    };

    componentDidMount() {
        this.calculateSliderMaxWidth();
        window.addEventListener('resize', this.calculateSliderMaxWidth);
        // const posterDetailsSelector = document.getElementById('posterDetails');
        // posterDetailsSelector.addEventListener('scroll', this.handleScrollPosterDetails)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calculateSliderMaxWidth);
        // const posterDetailsSelector = document.getElementById('posterDetails');
        // window.removeEventListener('scroll', this.handleScrollPosterDetails);
    }

    handleScrollPosterDetails = (e) => {
        if (e.deltaY > 0) {
            // user is scrolling down and he wants to see the full abstract
            this.expandPosterDetails();
        } else {
            // user is scrolling up and want to close the abstract
            this.minimizePosterDetails();
        }
    };

    expandPosterDetails = () => {
        const posterDetailsSelector = document.getElementById('posterDetails');
        posterDetailsSelector.classList.add('expanded');
    };

    minimizePosterDetails = () => {
        const drawerContainer = document.querySelector('.poster-details').getBoundingClientRect();
        const scrollerWrapper = document.querySelector('.poster-details > div').getBoundingClientRect();
        if (Math.floor(drawerContainer.top) === Math.floor(scrollerWrapper.top)) {
            const posterDetailsSelector = document.getElementById('posterDetails');
            posterDetailsSelector.classList.remove('expanded');
        }
    };

    calculateSliderMaxWidth = () => {
        let realHeight = window.innerHeight - 315;

        if (window.innerHeight < 700) {
            realHeight = window.innerHeight - 210;
        }

        let sliderContainerStyles = {
            maxWidth: (realHeight * 165) / 100,
        };

        if (window.innerHeight < 700) {
            sliderContainerStyles = {
                maxWidth: (realHeight * 165) / 100,
            };
        }
        this.setState({sliderContainerStyles: sliderContainerStyles});
    };

    getCurrentShowfloor = () => {
        const {event, activeShowfloorId} = this.props;
        const showfloor = event.showfloors.find((showfloor) => showfloor._id === activeShowfloorId);
        return showfloor;
    };

    getAllExhibitButtonName = () => {
        const {event, translation, defaultTranslation} = this.props;
        let buttonName = '';
        if (event?.showfloors?.length > 1) {
            buttonName =
                translation?.showfloor?.showShowfloorsButton || defaultTranslation?.showfloor?.showShowfloorsButton;
        } else {
            const {activeShowfloorId} = this.props;
            const showfloor = event.showfloors.find((showfloor) => showfloor._id === activeShowfloorId);
            if (showfloor?.type === 'posters') {
                buttonName =
                    translation?.showfloor?.showShowfloorsPosterButton ||
                    defaultTranslation?.showfloor?.showShowfloorsPosterButton;
            } else {
                buttonName =
                    translation?.showfloor?.showShowfloorsExhibitorButton ||
                    defaultTranslation?.showfloor?.showShowfloorsExhibitorButton;
            }
        }
        return buttonName;
    };

    renderAuthors = (exhibitor) => {
        const {translation, defaultTranslation} = this.props;
        if (!exhibitor) {
            return null;
        }
        const authorsLength = exhibitor.booth.authors.length;
        if (authorsLength === 0) {
            return (
                <span>
                    {translation?.showfloor.authorsNotAvailable || defaultTranslation?.showfloor.authorsNotAvailable}
                </span>
            );
        }
        return (
            <>
                {exhibitor.booth.authors.map((author, authorIndex) => {
                    return (
                        <span onDragStart={preventDefaultDrag} draggable="false" key={author._id}>
                            {author.name} {author?.affiliation ? `(${author?.affiliation})` : ''}
                            {authorIndex !== authorsLength - 1 ? ', ' : ''}
                        </span>
                    );
                })}
            </>
        );
    };

    render() {
        const {noExhibitors, exhibitorData, translation, defaultTranslation, isBoothPreview, exhibitors} = this.props;
        const showfloor = this.getCurrentShowfloor();
        const allExhibitButtonName = this.getAllExhibitButtonName();

        return (
            <div
                className={`poster-details-container exhibitors-slider ${noExhibitors ? 'no-exhibitors' : ''}`}
                id={'posterDetails'}
                onWheel={this.handleScrollPosterDetails}
            >
                <div onDragStart={preventDefaultDrag} className="poster-details-inner-container">
                    {!isBoothPreview && (
                        <div
                            onDragStart={preventDefaultDrag}
                            className="showfloor-menu-button"
                            onClick={this.props.showExhibitorsOverlay}
                        >
                            <div>
                                {exhibitors?.length > 0 && (
                                    <div className="exhibitors-number">
                                        <span>{exhibitors?.length}</span>
                                    </div>
                                )}
                                <ButtonArrow />
                                <div onDragStart={preventDefaultDrag} className="text-container">
                                    <p onDragStart={preventDefaultDrag} className="text">
                                        {allExhibitButtonName}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                    <div onDragStart={preventDefaultDrag} className="title-container">
                        <div onDragStart={preventDefaultDrag} className="">
                            <p onDragStart={preventDefaultDrag} className="title">
                                {showfloor?.name}
                            </p>
                        </div>
                    </div>
                    <div
                        onDragStart={preventDefaultDrag}
                        className={`poster-details scroll-left-container ${isBoothPreview ? 'booth-preview' : ''}`}
                    >
                        <div>
                            <div onDragStart={preventDefaultDrag} className="left-column">
                                <div onDragStart={preventDefaultDrag} className="section">
                                    <p onDragStart={preventDefaultDrag} className="section-subtitle">
                                        {translation?.showfloor.posterBooth?.study ||
                                            defaultTranslation?.showfloor.posterBooth?.study}
                                    </p>
                                    <p
                                        className={`poster-title ${
                                            exhibitorData?.booth.studyTitle.length === 0 ? 'empty' : ''
                                        }`}
                                    >
                                        {exhibitorData?.booth.studyTitle
                                            ? exhibitorData?.booth.studyTitle
                                            : translation?.showfloor.titleNotAvailable ||
                                              defaultTranslation?.showfloor.titleNotAvailable}
                                    </p>
                                </div>
                                <div onDragStart={preventDefaultDrag} className="section">
                                    <p onDragStart={preventDefaultDrag} className="section-subtitle">
                                        {translation?.showfloor.posterBooth?.authors ||
                                            defaultTranslation?.showfloor.posterBooth?.authors}
                                    </p>
                                    <p onDragStart={preventDefaultDrag} className="poster-authors">
                                        {this.renderAuthors(exhibitorData)}
                                    </p>
                                </div>
                            </div>
                            <div onDragStart={preventDefaultDrag} className="right-column">
                                <div onDragStart={preventDefaultDrag} className="section">
                                    <p onDragStart={preventDefaultDrag} className="section-subtitle">
                                        {translation?.showfloor.posterBooth?.highlights ||
                                            defaultTranslation?.showfloor.posterBooth?.highlights}
                                    </p>
                                    <p onDragStart={preventDefaultDrag} className="poster-highlights">
                                        {exhibitorData?.booth.highlights
                                            ? exhibitorData?.booth.highlights
                                            : translation?.showfloor.abstractNotAvailable ||
                                              defaultTranslation?.showfloor.abstractNotAvailable}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div onDragStart={preventDefaultDrag} className="scroll-info-container">
                    <span>
                        {translation?.showfloor.posterBooth?.scrollInfoText ||
                            defaultTranslation?.showfloor.posterBooth?.scrollInfoText}
                    </span>
                    <div className="icon-scroll"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        user: state.user.data,
        exhibitors: state.exhibitors.approvedExhibitors,
        exhibitorData: state.exhibitor.data,
        activeExhibitorId: state.exhibitors.activeExhibitorId,
        languages: state.languages,
        activeShowfloorId: state.showfloors.activeShowfloorId,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetUser: () => dispatch(actions.refreshUserData()),
        onSetActiveExhibitorId: (exhibitorId) => dispatch(actions.setActiveExhibitorId(exhibitorId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PosterDetails);
