import Grid from '@material-ui/core/Grid';
import React from 'react';
import { connect } from 'react-redux';
import FacebookLoginIcon from '../Images/svg/facebook-login.svg';
import GoogleLoginIcon from '../Images/svg/google-login.svg';
import LinkedinLoginIcon from '../Images/svg/linkedin-login.svg';
import { isWebview } from '../Utils/is-webview';
import { preventDefaultDrag } from '../Utils/utils';
class SocialNetworkButtons extends React.Component {
	render() {
		const {
			eventId,
			registerAsExhibitor,
			registerAsScholar,
			registerAsParticipant,
			preselectedAccessPackageId,
			translation,
			logInTab,
			defaultTranslation,
		} = this.props;
		const externalId = localStorage.getItem('externalId');
		if (isWebview()) {
			// return <div className='social-buttons-container' style={{marginBottom: '20px'}}></div>
			return (
				<div className="social-buttons-container">
					<div style={{ height: '25px' }}></div>
					<Grid item xs={12}>
						<a
							href={`${process.env.REACT_APP_REQUESTS_API}/auth/facebook?eventId=${eventId}&registerAsExhibitor=${registerAsExhibitor}&registerAsScholar=${registerAsScholar}&registerAsParticipant=${registerAsParticipant}&packageId=${preselectedAccessPackageId}&externalId=${externalId}`}
						>
							<button className="social-btn facebook-button">
								<img
									draggable="false"
									src={FacebookLoginIcon}
									width="13"
									height="26"
									alt="Facebook logo"
								/>
								{logInTab ? (
									<p>
										{translation?.socialButtons.loginFacebookButton ||
											defaultTranslation?.socialButtons.loginFacebookButton}
									</p>
								) : (
									<p>
										`$
										{translation?.login.loginButton ||
											defaultTranslation?.login.loginButton}{' '}
										$
										{translation?.socialButtons.facebookButton ||
											defaultTranslation?.socialButtons.facebookButton}
										`
									</p>
								)}
							</button>
						</a>
						<a
							href={`${process.env.REACT_APP_REQUESTS_API}/auth/linkedin?eventId=${eventId}&registerAsExhibitor=${registerAsExhibitor}&registerAsScholar=${registerAsScholar}&registerAsParticipant=${registerAsParticipant}&packageId=${preselectedAccessPackageId}&externalId=${externalId}`}
						>
							<button className="social-btn linkedin-button">
								<img
									draggable="false"
									src={LinkedinLoginIcon}
									width="13"
									height="26"
									alt="Linkedin logo"
								/>
								{logInTab ? (
									<p>
										{translation?.socialButtons.loginLinkedinButton ||
											defaultTranslation?.socialButtons.loginLinkedinButton}
									</p>
								) : (
									<p>
										`$
										{translation?.login.loginButton ||
											defaultTranslation?.login.loginButton}{' '}
										$
										{translation?.socialButtons.linkedinButton ||
											defaultTranslation?.socialButtons.linkedinButton}
										`
									</p>
								)}
							</button>
						</a>
					</Grid>
					<div style={{ height: '25px' }}></div>
				</div>
			);
		}
		return (
			<div
				onDragStart={preventDefaultDrag}
				className="social-buttons-container"
			>
				{logInTab ? (
					<p onDragStart={preventDefaultDrag} className="social-title">
						{translation?.socialButtons.socialButtonsLogInTitle ||
							defaultTranslation?.socialButtons.socialButtonsLogInTitle}
					</p>
				) : (
					<p onDragStart={preventDefaultDrag} className="social-title">
						{translation?.socialButtons.socialButtonsSignUpTitle ||
							defaultTranslation?.socialButtons.socialButtonsSignUpTitle}
					</p>
				)}
				<Grid item xs={12}>
					<a
						href={`${process.env.REACT_APP_REQUESTS_API}/auth/linkedin?eventId=${eventId}&registerAsExhibitor=${registerAsExhibitor}&registerAsScholar=${registerAsScholar}&registerAsParticipant=${registerAsParticipant}&packageId=${preselectedAccessPackageId}&externalId=${externalId}`}
					>
						<button className="social-btn linkedin-button">
							<img
								draggable="false"
								onDragStart={preventDefaultDrag}
								src={LinkedinLoginIcon}
								width="13"
								height="26"
								alt="Linkedin logo"
							/>
							{logInTab ? (
								<p>
									{translation?.socialButtons.loginLinkedinButton ||
										defaultTranslation?.socialButtons.loginLinkedinButton}
								</p>
							) : (
								<p>
									`$
									{translation?.login.loginButton ||
										defaultTranslation?.login.loginButton}{' '}
									$
									{translation?.socialButtons.linkedinButton ||
										defaultTranslation?.socialButtons.linkedinButton}
									`
								</p>
							)}
						</button>
					</a>
					<a
						href={`${process.env.REACT_APP_REQUESTS_API}/auth/facebook?eventId=${eventId}&registerAsExhibitor=${registerAsExhibitor}&registerAsScholar=${registerAsScholar}&registerAsParticipant=${registerAsParticipant}&packageId=${preselectedAccessPackageId}&externalId=${externalId}`}
					>
						<button className="social-btn facebook-button">
							<img
								draggable="false"
								onDragStart={preventDefaultDrag}
								src={FacebookLoginIcon}
								width="13"
								height="26"
								alt="Facebook logo"
							/>
							{logInTab ? (
								<p>
									{translation?.socialButtons.loginFacebookButton ||
										defaultTranslation?.socialButtons.loginFacebookButton}
								</p>
							) : (
								<p>
									`$
									{translation?.login.loginButton ||
										defaultTranslation?.login.loginButton}{' '}
									$
									{translation?.socialButtons.facebookButton ||
										defaultTranslation?.socialButtons.facebookButton}
									`
								</p>
							)}
						</button>
					</a>

					<a
						href={`${process.env.REACT_APP_REQUESTS_API}/auth/google?eventId=${eventId}&registerAsExhibitor=${registerAsExhibitor}&registerAsScholar=${registerAsScholar}&registerAsParticipant=${registerAsParticipant}&packageId=${preselectedAccessPackageId}&externalId=${externalId}`}
					>
						<button className="social-btn google-button">
							<img
								draggable="false"
								onDragStart={preventDefaultDrag}
								src={GoogleLoginIcon}
								width="26"
								height="26"
								alt="Google logo"
							/>
							{logInTab ? (
								<p>
									{translation?.socialButtons.loginGoogleButton ||
										defaultTranslation?.socialButtons.loginGoogleButton}
								</p>
							) : (
								<p>
									`$
									{translation?.login.loginButton ||
										defaultTranslation?.login.loginButton}{' '}
									$
									{translation?.socialButtons.googleButton ||
										defaultTranslation?.socialButtons.googleButton}
									`
								</p>
							)}
						</button>
					</a>
				</Grid>
				{logInTab ? (
					<p onDragStart={preventDefaultDrag} className="or-text">
						{translation?.login.orLogInWithEmail ||
							defaultTranslation?.login.orLogInWithEmail}
					</p>
				) : (
					<p onDragStart={preventDefaultDrag} className="or-text">
						{translation?.register.orFillInTheForm ||
							defaultTranslation?.register.orFillInTheForm}
					</p>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		eventId: state.event.eventId,
		preselectedAccessPackageId: state.user.preselectedAccessPackageId,
		languages: state.languages,
		translation: state.languages.translations[state.languages.eventLanguage],
		defaultTranslation: state.languages.translations['en'],
	};
};

export default connect(mapStateToProps)(SocialNetworkButtons);
