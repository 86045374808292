import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import EventLanguage from "../../../Components/EventMenuDropdowns/EventLanguage";
import "../../../CSS/myAccount.scss";
import "../../../CSS/myAccountMobile.scss";
import variables from "../../../CSS/_variables.module.scss";
import ConfirmDialog from "../../../Dialogs/Confirm";
import ChangePasswordMobile from "../../../Dialogs/MyAccount/MyAccountMobile/ChangePasswordMobile";
import CreateOrganizerParticipantProfileMobile from "../../../Dialogs/MyAccount/MyAccountMobile/CreateOrganizerParticipantProfile";
import DeleteMyAccount from "../../../Dialogs/MyAccount/DeleteMyAccount";
import AccountManageNotifications from "../Notifications/AccountManageNotifications";
import EditExhibitorContactPersonInformationMobile from "../../../Dialogs/MyAccount/MyAccountMobile/EditExhibitorContactPersonInformation";
import EditParticipantInformationMobile from "../../../Dialogs/MyAccount/MyAccountMobile/EditParticipantInformation";
import EditScholarContactPersonInformation from "../../../Dialogs/MyAccount/MyAccountMobile/EditScholarInformation";
import EditSmartMatchingAnswersMobile from "../../../Dialogs/MyAccount/MyAccountMobile/EditSmartMatchingAnswers";
import EditUserInformationMobile from "../../../Dialogs/MyAccount/MyAccountMobile/EditUserInformation";
import { ReactComponent as ArrowRightIcon } from "../../../Images/svg/arrow_right.svg";
import { ReactComponent as EditIcon } from "../../../Images/svg/edit-image.svg";
import * as actions from "../../../store/actions";
import axios from "../../../store/axios-instance";
import { preventDefaultDrag, sendMessageToFlutter } from "../../../Utils/utils";
import { ReactComponent as Cancel } from "./cancel-mobile.svg";

import MyEventsMobile from "../../../Dialogs/MyAccount/MyAccountMobile/MyEventsMobile";
import QrCodeDialog from "../../../Dialogs/MyAccount/MyAccountMobile/QRCodeConsentDialog";
import isEqual from "lodash/isEqual";
import { EXHIBITOR_TYPES } from "../../../Utils/constants/shared";
import cloneDeep from "lodash/cloneDeep";
import { isWebview } from "Utils/is-webview";
import QRCodeConsentDialog from "../../../Dialogs/MyAccount/MyAccountMobile/QRCodeConsentDialog";
import QRCodeValueDialog from "Dialogs/MyAccount/QRCodeValueDialog";

class MyAccountMobile extends React.Component {
	state = {
		editUserDialog: false,
		editParticipantDialog: false,
		smartMatchingDialog: false,
		editExhibitorContactPersonDialog: false,
		editScholarContactPersonDialog: false,
		createParticipantProfileDialog: false,
		changeUserPasswordDialog: false,
		openManageNotifications: false,
		showQrCodeModal: false,
		deleteMyAccountDialog: false,
		unregisterFromEventDialog: false,
		changePasswordServerMessage: "",
		participantData: null,
		exhibitorData: null,
		imgErrorText: "",
		canUnregisterEvent: true,
		registrationType: "",
		predefinedRegistrationFields: [],
		openConfirmUnsavedChanges: false,
		openMyEventsDialog: false,
		chatPreference: "",
	};

	componentDidMount() {
		this.props.onSetEventProtectedMenu();
		this.getRegistrationType();

		this.setRegistrationFieldsData();

		this.props.closeTopNavigation();
		this._handleSeeOnventProfile();

		document.body.classList.add("sticky-menu");
	}

	componentWillUnmount() {
		document.body.classList.remove("sticky-menu");
	}

	componentDidUpdate(prevProps) {
		const { event, eventRoles, eventUsers } = this.props;
		// force a state update if the user changed something in a participant or exhibitor
		if (
			!isEqual(prevProps.eventUsers.allEventUsers, eventUsers.allEventUsers) ||
			(prevProps.eventRoles.isExhibitor === false &&
				eventRoles.isExhibitor === true)
		) {
			this.setRegistrationFieldsData();
		}
		if (prevProps.event._id !== event._id) {
			this.setRegistrationFieldsData();
		}
		if (prevProps.userEventProfile !== this.props.userEventProfile) {
			this.setRegistrationFieldsData();
		}
	}

	setRegistrationFieldsData = () => {
		const { eventRoles, eventId, registrationFields } = this.props;
		// in getEvent we don't receive the

		if (!registrationFields.fetched) {
			this.props.getEventRegistrationFields(eventId).then(() => {
				if (eventRoles.isExhibitor) {
					this.setExhibitorData();
				} else {
					this.setParticipantData();
				}
			});
		} else {
			if (eventRoles.isExhibitor) {
				this.setExhibitorData();
			} else {
				this.setParticipantData();
			}
		}
	};

	_handleSeeOnventProfile = () => this.props.seeOnventProfile();
	_handleSeeEventProfile = () => this.props.seeEventProfile();
	handleOpenEditUserDialog = () => this.setState({ editUserDialog: true });
	handleOpenEditParticipantDialog = () =>
		this.setState({ editParticipantDialog: true });
	handleOpenSmartMatchingDialog = () =>
		this.setState({ smartMatchingDialog: true });
	handleOpenEditExhibitorContactPersonDialog = () =>
		this.setState({ editExhibitorContactPersonDialog: true });
	handleOpenEditScholarContactPersonDialog = () =>
		this.setState({ editScholarContactPersonDialog: true });
	handleOpenCreateEventProfileDialog = () =>
		this.setState({ createParticipantProfileDialog: true });
	handleChangePassword = () =>
		this.setState({ changeUserPasswordDialog: true });
	handleDeleteUser = () => this.setState({ deleteMyAccountDialog: true });
	handleUnregisterFromEvent = () =>
		this.setState({
			unregisterFromEventDialog: true,
		});
	handleCloseDialogUnregisterFromEvent = () =>
		this.setState({
			unregisterFromEventDialog: false,
		});

	setParticipantData = () => {
		const { userEventProfile, registrationFields } = this.props;
		let participantData = cloneDeep(userEventProfile);

		this.setState({
			participantData: participantData,
			chatPreference: participantData?.chatPreference,
		});

		// get participant predefined fields
		const { participantPredefinedRegistrationFields } =
			registrationFields?.data;
		let predefinedRegistrationFields = [];
		Object.keys(participantPredefinedRegistrationFields).map((key) => {
			const participantPredefinedField =
				participantPredefinedRegistrationFields[key];
			if (participantPredefinedField.isEnabled) {
				let fieldLabel = participantPredefinedField.label;
				predefinedRegistrationFields.push({ label: fieldLabel, value: key });
			}
			return null;
		});
		this.setState({
			predefinedRegistrationFields: predefinedRegistrationFields,
		});
	};

	setExhibitorData = () => {
		const { userEventProfile, registrationFields } = this.props;
		let exhibitorData = cloneDeep(userEventProfile);
		this.setState({
			exhibitorData: exhibitorData,
			chatPreference: exhibitorData?.chatPreference,
		});

		if (exhibitorData?.type === "scholar") {
			// get scholar predefined fields
			const { scholarPredefinedRegistrationFields } = registrationFields?.data;
			let predefinedRegistrationFields = [];
			Object.keys(scholarPredefinedRegistrationFields).map((key) => {
				const scholarPredefinedField = scholarPredefinedRegistrationFields[key];
				if (scholarPredefinedField.isEnabled) {
					let fieldLabel = scholarPredefinedField.label;
					predefinedRegistrationFields.push({ label: fieldLabel, value: key });
				}
				return null;
			});
			this.setState({
				predefinedRegistrationFields: predefinedRegistrationFields,
			});
		} else {
			// get exhibitor predefined fields
			const { exhibitorPredefinedRegistrationFields } =
				registrationFields?.data;
			let predefinedRegistrationFields = [];
			Object.keys(exhibitorPredefinedRegistrationFields).map((key) => {
				const exhibitorPredefinedField =
					exhibitorPredefinedRegistrationFields[key];
				if (exhibitorPredefinedField.isEnabled) {
					let fieldLabel = exhibitorPredefinedField.label;
					predefinedRegistrationFields.push({ label: fieldLabel, value: key });
				}
				return null;
			});
			this.setState({
				predefinedRegistrationFields: predefinedRegistrationFields,
			});
		}
	};

	handleOpenEditEventProfileDialog = () => {
		const { eventRoles } = this.props;
		const { exhibitorData, participantData } = this.state;
		if (
			eventRoles.isExhibitor &&
			exhibitorData &&
			exhibitorData.type === EXHIBITOR_TYPES.scholar
		) {
			this.handleOpenEditScholarContactPersonDialog();
		} else if (
			eventRoles.isExhibitor &&
			exhibitorData &&
			exhibitorData.type === EXHIBITOR_TYPES.company
		) {
			this.handleOpenEditExhibitorContactPersonDialog();
		} else if (!eventRoles.isExhibitor && participantData) {
			this.handleOpenEditParticipantDialog();
		}
	};

	handleCloseDialog = (serverMessage) => {
		let message = "";
		if (serverMessage) {
			message = serverMessage;
		}
		this.setState({
			editUserDialog: false,
			deleteMyAccountDialog: false,
			editParticipantDialog: false,
			smartMatchingDialog: false,
			editExhibitorContactPersonDialog: false,
			editScholarContactPersonDialog: false,
			createParticipantProfileDialog: false,
			changeUserPasswordDialog: false,
			unregisterFromEventDialog: false,
			changePasswordServerMessage: message,
			openMyEventsDialog: false,
		});
	};

	handleRemoveUserAvatar = () => {
		axios({ method: "delete", url: "/users/me/avatar" })
			.then((response) => {
				this.props.onRefreshUserData();
			})
			.catch((error) => {});
	};

	unregisterFromEvent = () => {
		const { user, eventSlug, eventId, history } = this.props;
		axios({
			method: "delete",
			url: `/event/v2/${eventId}/unregister-user/${user._id}`,
		})
			.then(() => {
				const userRolesInEvent = user.eventRoles.find(
					(role) => role.event._id === eventId,
				);
				const userIsOrganizer =
					userRolesInEvent && userRolesInEvent.roles.includes("organizer");
				if (userIsOrganizer) {
					this.props.onGetUserEventProfile(eventId, user._id);
					this.props.onRefreshUserData().then(() =>
						history.push({
							pathname: `/event/${eventSlug}/lobby`,
							state: { isUnregisteredOrganizer: true },
						}),
					);
				} else {
					this.props.onRefreshUserData().then(() => {
						window.location.href = `${process.env.REACT_APP_AIFG_WP_LINK}`;
					});
				}
			})
			.catch(() => {});
	};

	handleImageChange = (e) => {
		const { languages } = this.props;
		e.preventDefault();
		let file = e.target.files[0];

		let isValid = true;

		isValid = file.size < 2 * 1024 * 1024 && isValid;
		if (!isValid) {
			this.setState({
				imgErrorText:
					languages.translations[languages.platformLanguage]?.errors
						.fileTooLarge,
			});
		}
		isValid =
			(file.type === "image/png" ||
				file.type === "image/jpg" ||
				file.type === "image/jpeg" ||
				file.type === "image/gif") &&
			isValid;
		if (
			file.type !== "image/png" &&
			file.type !== "image/jpg" &&
			file.type !== "image/jpeg" &&
			file.type !== "image/gif"
		) {
			this.setState({
				imgErrorText: `${
					languages.translations[languages.platformLanguage]?.errors
						.imageFileNotSupported
				} jpeg, jpg, jfif, gif or png.`,
			});
		}

		if (isValid) {
			this.setState({ imgErrorText: "" });
			const formData = new FormData();

			formData.append("avatar", file, file.name, file.type);
			axios({ method: "post", url: "/users/me/avatar", data: formData })
				.then((response) => {
					this.props.onRefreshUserData();
				})
				.catch((error) => {
					this.props.onRefreshUserData();
				});
		}
	};

	getRegistrationType = () => {
		axios({ method: "get", url: `/users/get-registration-type` })
			.then((response) => {
				this.setState({ registrationType: response.data.registrationType });
			})
			.catch((error) => {});
	};

	handleRemoveMatchingData = () => {
		const { eventId, user, onRemoveUserMatchingData } = this.props;
		onRemoveUserMatchingData(eventId)
			.then(() =>
				this.setState({ smartMatchingDialog: false }, () =>
					this.props.onGetUserEventProfile(eventId, user._id),
				),
			)
			.catch(() => {});
	};

	getSDGTitle = (sdgs) => {
		const { event } = this.props;
		let sdgsTitles = [];
		sdgs?.forEach((sdg) => {
			event?.sdgs?.forEach((sdgItem, sdgItemIndex) => {
				if (sdgItem._id === sdg) {
					sdgsTitles.push({
						sdgItem,
						sdgItemIndex,
					});
				}
			});
		});
		if (sdgsTitles?.length > 0) {
			return sdgsTitles?.map((sdgTitle, sdgIndex) => {
				return (
					<span key={sdgTitle.sdgItem._id}>
						{`Goal ${sdgTitle.sdgItemIndex + 1}: ${sdgTitle.sdgItem.title}`}
						{sdgIndex !== sdgsTitles.length - 1 ? ", " : ""}
					</span>
				);
			});
		} else {
			return "-";
		}
	};

	handleOpenMyEvents = () => {
		this.setState({
			openMyEventsDialog: true,
		});
	};

	handleOpenManageNotifications = () => {
		this.setState({
			openManageNotifications: !this.state.openManageNotifications,
		});
	};

	openQrCodeModal = () => {
		this.setState({
			showQrCodeModal: true,
		});
	};
	closeQrCodeModal = () => {
		this.setState({
			showQrCodeModal: false,
		});
	};

	handleCloseMyEvents = () => {
		this.setState({
			openMyEventsDialog: false,
		});
	};

	getChatPreferenceValues = () => {
		const { chatPreference } = this.state;
		if (chatPreference === "everybody") {
			return "Everybody";
		} else if (chatPreference === "contacts") {
			return "Only Contacts";
		} else if (chatPreference === "matches") {
			return "Only High Matches (if Smart Matching is enabled for this event)";
		} else if (chatPreference === "contactsOrMatches") {
			return "Only Contacts or High Matches";
		} else if (chatPreference === "nobody") {
			return "Nobody";
		}
	};

	handleUpdateUserInformation = () => {
		const { eventId, onGetUsers, onRefreshUserData, onUpdateUserInformation } =
			this.props;
		onUpdateUserInformation({ qrCodeconsent: true })
			.then(() => {
				onRefreshUserData().then(() => onGetUsers(eventId));
				this.closeQrCodeModal();
			})
			.catch(() => {});
	};

	render() {
		const {
			registrationType,
			changePasswordServerMessage,
			createParticipantProfileDialog,
			editScholarContactPersonDialog,
			editExhibitorContactPersonDialog,
			editParticipantDialog,
			smartMatchingDialog,
			changeUserPasswordDialog,
			deleteMyAccountDialog,
			editUserDialog,
			imgErrorText,
			unregisterFromEventDialog,
			canUnregisterEvent,
			participantData,
			exhibitorData,
			predefinedRegistrationFields,
			openMyEventsDialog,
			chatPreference,
			showQrCodeModal,
		} = this.state;
		const {
			user,
			event,
			eventId,
			eventRoles,
			userEventProfile,
			eventSlug,
			profileTabs,
			registrationFields,
			languages,
			defaultTranslation,
			translation,
		} = this.props;
		let accessPackageData = null;
		let exhibitorAccessPackageData = null;
		if (event.hasAccessManagement) {
			const userRolesInEvent = user.eventRoles.find(
				(role) => role.event.slug === event.slug,
			);
			const userEventAccessPackageId = userRolesInEvent.accessPackage;
			accessPackageData = event.accessPackages.find(
				(accessPackage) => accessPackage._id === userEventAccessPackageId,
			);
		}
		if (
			event.exhibitorHasAccessManagement ||
			event.scholarHasAccessManagement
		) {
			const userRolesInEvent = user.eventRoles.find(
				(role) => role.event.slug === event.slug,
			);
			const userEventAccessPackageId = userRolesInEvent.exhibitorAccessPackage;
			exhibitorAccessPackageData = event.exhibitorAccessPackages.find(
				(accessPackage) => accessPackage._id === userEventAccessPackageId,
			);
		}

		if (!registrationFields.fetched) {
			return <p>Loading</p>;
		}

		// do not display fake emails
		let userEmail = user.email;
		if (registrationType !== "" && registrationType !== "email") {
			if (
				(userEmail.startsWith("fb") ||
					userEmail.startsWith("go") ||
					userEmail.startsWith("tw") ||
					userEmail.startsWith("li")) &&
				(userEmail.endsWith(variables.emailEndsWith1) ||
					userEmail.endsWith(variables.emailEndsWith2))
			) {
				userEmail = "-";
			}
		}

		const hasMatchingEnabled = event?.enableMatching || event?.hasMatching;
		const matchingFormSeen = userEventProfile?.matchingFormSeen;

		return (
			<div
				onDragStart={preventDefaultDrag}
				id="my-account-mobile"
				className={`my-account-page event-mobile-page ${
					editParticipantDialog || smartMatchingDialog ? "hide-overflow" : ""
				}`}
			>
				<div onDragStart={preventDefaultDrag} className="tabs-container">
					<div>
						<div
							className={`tab ${profileTabs.seeOnventProfile ? "active" : ""}`}
							onClick={this._handleSeeOnventProfile}
						>
							<h3>
								{process.env.REACT_APP_PLATFORM_NAME}{" "}
								{translation?.myAccountPage
									.platformProfileSectionTitleTextSecond ||
									defaultTranslation?.myAccountPage
										.platformProfileSectionTitleTextSecond}
							</h3>
						</div>
						{(exhibitorData || participantData) && (
							<div
								className={`tab ${profileTabs.seeEventProfile ? "active" : ""}`}
								onClick={this._handleSeeEventProfile}
							>
								<h3>{translation?.myAccountPage.eventProfileSectionTitle}</h3>
							</div>
						)}
					</div>
				</div>
				{profileTabs.seeOnventProfile ? (
					<div
						onDragStart={preventDefaultDrag}
						className="edit-section-container onvent-profile"
					>
						<div>
							<div onDragStart={preventDefaultDrag} className="profile-list">
								<div
									onDragStart={preventDefaultDrag}
									className="profile-list-item"
								>
									<div
										className="avatar"
										style={{
											backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${user.avatarBig}')`,
										}}
									/>
									<div
										onDragStart={preventDefaultDrag}
										className="user-details"
									>
										<h3>
											{user.first} {user.last}
										</h3>
										<p className="word-breaker">
											<span>
												{translation?.login.inputEmail ||
													defaultTranslation?.login.inputEmail}
												:{" "}
											</span>
											{userEmail}
										</p>
									</div>
									<div>
										<button onClick={this.handleOpenEditUserDialog}>
											<EditIcon fill={variables.primary} />
										</button>
									</div>
								</div>
								{(registrationType === "" || registrationType === "email") && (
									<div
										onDragStart={preventDefaultDrag}
										onClick={this.handleChangePassword}
										className="profile-list-item"
									>
										<h4>{translation?.myAccountPage.changePasswordButton}</h4>
										<ArrowRightIcon
											width="24"
											height="24"
											fill={variables.greyVariant}
											className="arabic-rotate"
										/>
									</div>
								)}
								<div
									onDragStart={preventDefaultDrag}
									onClick={this.handleDeleteUser}
									className="profile-list-item"
								>
									<h4>{translation?.myAccountPage.deleteAccountButton}</h4>
									<ArrowRightIcon
										width="24"
										height="24"
										fill={variables.greyVariant}
										className="arabic-rotate"
									/>
								</div>
								{!participantData &&
									eventRoles.isOrganizer &&
									event.status === "public" && (
										<div
											onClick={this.handleOpenCreateEventProfileDialog}
											className="profile-list-item"
										>
											<h4>
												{translation?.myAccountPage.enableParticipantProfile}
											</h4>
											<ArrowRightIcon
												width="24"
												height="24"
												fill={variables.greyVariant}
												className="arabic-rotate"
											/>
										</div>
									)}
								<div
									onDragStart={preventDefaultDrag}
									className="profile-list-item"
								>
									<EventLanguage translation={translation} />
								</div>
								<div
									onDragStart={preventDefaultDrag}
									className="profile-list-item"
									onClick={this.handleOpenMyEvents}
								>
									<h4>
										{translation?.myEventsDropdown.title ||
											defaultTranslation?.myEventsDropdown.title}
									</h4>
									<ArrowRightIcon
										width="24"
										height="24"
										fill={variables.greyVariant}
										className="arabic-rotate"
									/>
								</div>
								<div
									onDragStart={preventDefaultDrag}
									className="profile-list-item"
									onClick={this.handleOpenManageNotifications}
								>
									<h4>MANAGE NOTIFICATIONS</h4>
									<ArrowRightIcon
										width="24"
										height="24"
										fill={variables.greyVariant}
										className="arabic-rotate"
									/>
								</div>
								{/* 
                                {isWebview() ? <div
                                    className="profile-list-item"
                                    onClick={this.openQrCodeModal}
                                >
                                    <h4>Show QR Code</h4>
                                    <ArrowRightIcon
                                        width="24"
                                        height="24"
                                        fill={variables.greyVariant}
                                        className="arabic-rotate"
                                    />
                                </div> : null}

                                {isWebview() ? <div
                                    onDragStart={preventDefaultDrag}
                                    className="profile-list-item"
                                    onClick={() => sendMessageToFlutter(localStorage.getItem('token'))}
                                >
                                    <h4>USER BADGES</h4>
                                    <ArrowRightIcon
                                        width="24"
                                        height="24"
                                        fill={variables.greyVariant}
                                        className="arabic-rotate"
                                    />
                                </div> : null} */}
							</div>
							<div
								onDragStart={preventDefaultDrag}
								className="user-profile-information"
							>
								<div>
									{changePasswordServerMessage ? (
										<p
											onDragStart={preventDefaultDrag}
											className="server-message"
										>
											{changePasswordServerMessage
												? changePasswordServerMessage
												: ""}
										</p>
									) : null}
								</div>
							</div>
							<div onDragStart={preventDefaultDrag} className="gdpr-links">
								<p>
									{translation?.myAccountPage.reviewPlatformConditions ||
										defaultTranslation?.myAccountPage.reviewPlatformConditions}
									:
								</p>
								<ul>
									<li>
										<Link
											to={{ pathname: "/privacy-policy" }}
											target={"_blank"}
										>
											{translation?.myAccountPage.privacyPolicyLink}
										</Link>
									</li>
									<li>
										<Link to={{ pathname: "/cookies" }} target={"_blank"}>
											{translation?.myAccountPage.cookiesLink}
										</Link>
									</li>
									<li>
										<Link
											to={{ pathname: "/terms-and-conditions" }}
											target={"_blank"}
										>
											{translation?.myAccountPage.termsAndConditionsLink}
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				) : null}

				{profileTabs.seeEventProfile && (
					<div
						onDragStart={preventDefaultDrag}
						className="edit-section-container event-profile"
					>
						{!eventRoles.isExhibitor && participantData && (
							<div
								onDragStart={preventDefaultDrag}
								className="edit-participant-info-container"
							>
								<button onClick={this.handleOpenEditEventProfileDialog}>
									<EditIcon fill={variables.primary} />
								</button>
								<ul>
									<li className="chat-preference-field">
										<span>
											Who should we allow to contact you in this event via the
											Neural Network chat?:{" "}
										</span>
										<span>
											{chatPreference.length
												? this.getChatPreferenceValues()
												: "-"}
										</span>
									</li>
									{predefinedRegistrationFields.map((field) => {
										return (
											<li
												key={field.value}
												className={field.value === "sdgs" ? "sdg-field" : ""}
											>
												{field.value === "sdgs" ? (
													<>
														<span>{field.label}: </span>
														{this.getSDGTitle(
															participantData[`${field.value}`],
														)}
													</>
												) : (
													<p>
														<span>{field.label}: </span>
														{participantData[`${field.value}`]
															? participantData[`${field.value}`]
															: "-"}
													</p>
												)}
											</li>
										);
									})}
									{participantData.customFields.map((field) => {
										let value = "";
										if (field.value) {
											value = field.value;
										} else if (field.values) {
											value = field.values.filter((el) => el.length).join(", ");
										}
										return (
											<li key={field._id}>
												<p>
													<span>{field.label}: </span>
													{value.length ? value : "-"}
												</p>
											</li>
										);
									})}
									{event.hasAccessManagement && accessPackageData && (
										<li>
											<p>
												<span>{translation?.myAccountPage.eventPackage}: </span>
												{accessPackageData.name}
											</p>
										</li>
									)}
								</ul>
							</div>
						)}
						{eventRoles.isExhibitor && exhibitorData && (
							<div
								onDragStart={preventDefaultDrag}
								className="edit-participant-info-container"
							>
								<button onClick={this.handleOpenEditEventProfileDialog}>
									<EditIcon fill={variables.primary} />
								</button>
								<ul>
									<li className="chat-preference-field">
										<span>
											Who should we allow to contact you in this event via the
											Neural Network chat?:{" "}
										</span>
										<span>
											{chatPreference.length
												? this.getChatPreferenceValues()
												: "-"}
										</span>
									</li>
									{predefinedRegistrationFields.map((field) => {
										return (
											<li
												key={field.value}
												className={field.value === "sdgs" ? "sdg-field" : ""}
											>
												{field.value === "sdgs" ? (
													<>
														<span>{field.label}: </span>
														{this.getSDGTitle(exhibitorData[`${field.value}`])}
													</>
												) : (
													<p>
														<span>{field.label}: </span>
														{exhibitorData[`${field.value}`]
															? exhibitorData[`${field.value}`]
															: "-"}
													</p>
												)}
											</li>
										);
									})}
									{exhibitorData.customFields.map((field) => {
										let value = "";
										if (field.value) {
											value = field.value;
										} else if (field.values) {
											value = field.values.filter((el) => el.length).join(", ");
										}
										return (
											<li key={field._id}>
												<p>
													<span>{field.label}: </span>
													{value.length ? value : "-"}
												</p>
											</li>
										);
									})}
									{(event.exhibitorHasAccessManagement ||
										event.scholarHasAccessManagement) &&
										exhibitorAccessPackageData && (
											<li>
												<p>
													<span>
														{translation?.myAccountPage.eventPackage}:{" "}
													</span>
													{exhibitorAccessPackageData.name}
												</p>
											</li>
										)}
								</ul>
							</div>
						)}
						{hasMatchingEnabled && matchingFormSeen && (
							<div
								onClick={this.handleOpenSmartMatchingDialog}
								className="smart-matching-button"
							>
								<h4>
									{translation?.matching?.myAccountMatchingSectionTitle ||
										defaultTranslation?.matching?.myAccountMatchingSectionTitle}
								</h4>
								<ArrowRightIcon
									width="24"
									height="24"
									fill={variables.greyVariant}
								/>
							</div>
						)}
						{canUnregisterEvent && (
							<div
								onDragStart={preventDefaultDrag}
								onClick={this.handleUnregisterFromEvent}
								className="change-password-btn"
							>
								<h4>{translation?.myAccountPage.unregisterFromEventButton}</h4>
								<ArrowRightIcon
									width="24"
									height="24"
									fill={variables.greyVariant}
								/>
							</div>
						)}
						<div onDragStart={preventDefaultDrag} className="gdpr-links">
							<p>
								{translation?.myAccountPage.reviewEventConditions ||
									defaultTranslation?.myAccountPage.reviewEventConditions}
								:
							</p>
							<ul>
								<li>
									<Link
										to={{
											pathname: `/event/${eventSlug}/event-privacy-policy`,
											search: `?lang=${languages.platformLanguage}`,
										}}
										target={"_blank"}
									>
										{translation?.myAccountPage.privacyPolicyLink}
									</Link>
								</li>
								<li>
									<Link
										to={{
											pathname: `/event/${eventSlug}/event-terms-and-conditions`,
											search: `?lang=${languages.platformLanguage}`,
										}}
										target={"_blank"}
									>
										{translation?.myAccountPage.termsAndConditionsLink}
									</Link>
								</li>
							</ul>
						</div>
					</div>
				)}

				{editUserDialog && (
					<EditUserInformationMobile
						opened={editUserDialog}
						closeDialog={this.handleCloseDialog}
						handleImageChange={this.handleImageChange}
						handleRemoveUserAvatar={this.handleRemoveUserAvatar}
						imgErrorText={imgErrorText}
					/>
				)}

				{deleteMyAccountDialog && (
					<DeleteMyAccount
						opened={deleteMyAccountDialog}
						closeDialog={this.handleCloseDialog}
						mobile
					/>
				)}

				{changeUserPasswordDialog && (
					<ChangePasswordMobile
						opened={changeUserPasswordDialog}
						closeDialog={this.handleCloseDialog}
					/>
				)}
				{this.state.openManageNotifications && (
					<>
						<div className="mobile-headline-manage-notifications">
							<Cancel
								className="cancel-notifications"
								onClick={this.handleOpenManageNotifications}
							/>
							MANAGE NOTIFICATIONS
						</div>
						<AccountManageNotifications />
					</>
				)}

				{editParticipantDialog && (
					<EditParticipantInformationMobile
						opened={editParticipantDialog}
						closeDialog={this.handleCloseDialog}
						participant={participantData}
					/>
				)}

				{smartMatchingDialog && (
					<EditSmartMatchingAnswersMobile
						opened={smartMatchingDialog}
						closeDialog={this.handleCloseDialog}
						handleRemoveMatchingData={this.handleRemoveMatchingData}
					/>
				)}

				{editExhibitorContactPersonDialog && (
					<EditExhibitorContactPersonInformationMobile
						opened={editExhibitorContactPersonDialog}
						closeDialog={this.handleCloseDialog}
						exhibitor={exhibitorData}
					/>
				)}

				{editScholarContactPersonDialog && (
					<EditScholarContactPersonInformation
						opened={editScholarContactPersonDialog}
						closeDialog={this.handleCloseDialog}
						exhibitor={exhibitorData}
					/>
				)}

				{createParticipantProfileDialog && (
					<CreateOrganizerParticipantProfileMobile
						opened={createParticipantProfileDialog}
						closeDialog={this.handleCloseDialog}
					/>
				)}

				{unregisterFromEventDialog && (
					<ConfirmDialog
						open={unregisterFromEventDialog}
						closeConfirm={this.handleCloseDialogUnregisterFromEvent}
						dialogTitle={`${translation?.myAccountPage.unregisterCurrentEventButton}`}
						dialogDescription={`${translation?.myAccountPage.unregisterCurrentEventConfirmation} ${event.name}`}
						handleConfirm={this.unregisterFromEvent}
						dialogCancelButtonLabel={`${translation?.generalText.cancel}`}
						dialogConfirmButtonLabel={`${translation?.generalText.confirm}`}
						mobile
					/>
				)}

				{openMyEventsDialog && (
					<MyEventsMobile
						open={openMyEventsDialog}
						closeDialog={this.handleCloseMyEvents}
					/>
				)}
				{showQrCodeModal && !user.qrCodeConsentTimestamp && (
					<QRCodeConsentDialog
						handleUpdateUserInformation={this.handleUpdateUserInformation}
						user={user}
						eventId={eventId}
						open={showQrCodeModal}
						closeDialog={this.closeQrCodeModal}
					/>
				)}
				{showQrCodeModal && user.qrCodeConsentTimestamp && (
					<QRCodeValueDialog
						user={user}
						eventId={eventId}
						open={showQrCodeModal}
						closeDialog={this.closeQrCodeModal}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user.data,
		eventRoles: state.user.eventRoles,
		event: state.event.data,
		eventId: state.event.eventId,
		eventSlug: state.event.eventSlug,
		eventUsers: state.eventUsers,
		profileTabs: state.user.profileTabs,
		languages: state.languages,
		defaultTranslation: state.languages.translations["en"],
		translation: state.languages.translations[state.languages.platformLanguage],
		registrationFields: state.event.registrationFields,
		userEventProfile: state.userEventProfile.data,
		triggerReloadUserProfile: state.userEventProfile.triggerReloadUserProfile,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onGetUsers: (eventId) => dispatch(actions.getUsers(eventId)),
		onUpdateUserInformation: (data) =>
			dispatch(actions.updateUserInformation(data)),
		onRefreshUserData: () => dispatch(actions.refreshUserData()),
		closeTopNavigation: () => dispatch(actions.topNavClose()),
		onSetEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
		seeOnventProfile: () => dispatch(actions.topNavOnventProfile()),
		seeEventProfile: () => dispatch(actions.topNavEventProfile()),
		onSetPlatformLanguage: (languageCode) =>
			dispatch(actions.setPlatformLanguage(languageCode)),
		onRemoveUserMatchingData: (eventId) =>
			dispatch(actions.removeUserMatchingData(eventId)),
		getEventRegistrationFields: (eventId) =>
			dispatch(actions.getEventRegistrationFields(eventId)),
		onGetUserEventProfile: (eventId, userId) =>
			dispatch(actions.getUserEventProfile(eventId, userId)),
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(MyAccountMobile),
);
