import moment from "moment";
import timeStrings from "./Translations/time";
import { languagesArray } from "./Translations/availableLanguages";
import cloneDeep from "lodash/cloneDeep";
import { isWebview } from "./is-webview";

// const getLastDayInAuditoriumIndex = (event) => {
//     const eventStartDate = moment(new Date(event.eventStartDate)).startOf('day');
//     const eventEndDate = moment(new Date(event.eventEndDate)).startOf('day');
//     const numberOfEventDays = eventEndDate.diff(eventStartDate, 'days');
//     return numberOfEventDays;
// };

export const checkIfEventHasEnded = (event, timezone) => {
	let now = new Date();
	let now_utc = getUtcTimestamp(now);

	if (event !== null) {
		// create an array with all the timeslots end hours, from all auditoriums
		let eventTimeslotsEndHours = [];
		event.auditoriums.forEach((auditorium) => {
			auditorium.dailyProgram.forEach((auditoriumDayProgram) => {
				let auditoriumDayProgramDate = new Date(auditoriumDayProgram.date);
				let auditoriumDayProgramYear =
					auditoriumDayProgramDate.getUTCFullYear();
				let auditoriumDayProgramMonth = auditoriumDayProgramDate.getUTCMonth();
				let auditoriumDayProgramDay = auditoriumDayProgramDate.getUTCDate();

				auditoriumDayProgram.program.forEach((timeslot) => {
					let timeslotEndsAt = timeslot.end;
					timeslotEndsAt = timeslotEndsAt.split(":");
					let timeslotEndsAtHour = timeslotEndsAt[0];
					let timeslotEndsAtMinute = timeslotEndsAt[1];
					// get the UTC timeStamp of the last video of the event
					eventTimeslotsEndHours.push(
						new Date(
							auditoriumDayProgramYear,
							auditoriumDayProgramMonth,
							auditoriumDayProgramDay,
							timeslotEndsAtHour,
							timeslotEndsAtMinute,
						),
					);
				});
			});
		});

		// we sort the array so that the first item in the array is the
		// latest hour from all auditoriums
		if (eventTimeslotsEndHours.length >= 2) {
			eventTimeslotsEndHours.sort((a, b) => {
				return b.getTime() - a.getTime();
			});
		}

		timezone = timezone === undefined ? 0 : timezone;

		// if the organizer didn't add any timeslots to the program, we consider that the event didn't end
		if (eventTimeslotsEndHours.length === 0) {
			return false;
		} else {
			eventTimeslotsEndHours =
				eventTimeslotsEndHours[0].getTime() - timezone * 60 * 60 * 1000;
			return now_utc > eventTimeslotsEndHours;
		}
	}
};

export const checkIfAuditoriumProgramHasEnded = (
	event,
	timezone,
	auditoriumIndex,
) => {
	let now = new Date();
	let now_utc = getUtcTimestamp(now);

	if (event !== null) {
		// create an array with all the timeslots, for all days, of the current auditorium
		let auditoriumTimeslots = [];
		cloneDeep(event.auditoriums[auditoriumIndex].dailyProgram).forEach(
			(dayProgram) => {
				const currentProgramDay = dayProgram.date;
				dayProgram.program.forEach((timeslot) => {
					timeslot.date = currentProgramDay;
					auditoriumTimeslots.push(timeslot);
				});
			},
		);

		// if there are no timslots, we always send the "auditoriumHasEnded" = true
		let auditoriumProgramLength = auditoriumTimeslots.length;
		if (auditoriumProgramLength === 0) {
			return true;
		}

		// if there are timeslots added
		const auditoriumProgramLastVideoIndex = auditoriumProgramLength - 1;
		const auditoriumProgramLastVideo =
			auditoriumTimeslots[auditoriumProgramLastVideoIndex];

		// get the year, month, day, hour, minute when of the program last video
		const auditoriumProgramLastVideoFullDate = new Date(
			auditoriumProgramLastVideo.date,
		);
		const auditoriumProgramLastVideoYear =
			auditoriumProgramLastVideoFullDate.getUTCFullYear();
		const auditoriumProgramLastVideoMonth =
			auditoriumProgramLastVideoFullDate.getUTCMonth();
		const auditoriumProgramLastVideoDay =
			auditoriumProgramLastVideoFullDate.getUTCDate();

		let auditoriumProgramLastVideoEndsAt = auditoriumProgramLastVideo.end;
		auditoriumProgramLastVideoEndsAt =
			auditoriumProgramLastVideoEndsAt.split(":");
		let auditoriumProgramLastVideoEndHour = auditoriumProgramLastVideoEndsAt[0];
		let auditoriumProgramLastVideoEndMinute =
			auditoriumProgramLastVideoEndsAt[1];

		// get the UTC timeStamp of the last video of the event
		let auditoriumProgramEnd = new Date(
			auditoriumProgramLastVideoYear,
			auditoriumProgramLastVideoMonth,
			auditoriumProgramLastVideoDay,
			auditoriumProgramLastVideoEndHour,
			auditoriumProgramLastVideoEndMinute,
		);

		timezone = timezone === undefined ? 0 : timezone;
		auditoriumProgramEnd =
			auditoriumProgramEnd.getTime() - timezone * 60 * 60 * 1000;

		return now_utc > auditoriumProgramEnd;
	}
};

/* export const checkIfEventHasLiveTimeslot = (event, timezone) => {
    let currentTime = moment().format('YYYY-MM-DD');
    let currentTimeStamp = getUtcTimestamp(new Date());

    if (event !== null) {
        let liveTimeslot = [];
        event.auditoriums.forEach((auditorium) => {
            auditorium.dailyProgram.forEach((auditoriumDayProgram) => {
                if (auditoriumDayProgram.date === currentTime) {
                    auditoriumDayProgram.program.forEach((timeSlot) => {
                        let timeSlotStartTimestamp = getTimeslotStartTimestamp(
                            auditoriumDayProgram.date,
                            timeSlot,
                            event.timezoneValue
                        );
                        let timeSlotEndTimestamp = getTimeslotEndTimestamp(auditoriumDayProgram.date, timeSlot, event.timezoneValue);
                        if (currentTimeStamp >= timeSlotStartTimestamp && currentTimeStamp < timeSlotEndTimestamp) {
                            liveTimeslot.push(timeSlot)
                        }
                    });
                }
            });
        });

        return liveTimeslot;

    }
}; */

export const checkIfEventHasLiveTimeslot = (event, timezone) => {
	const currentTime = moment().format("YYYY-MM-DD");
	const currentTimeStamp = getUtcTimestamp(new Date());

	if (event !== null) {
		const liveTimeslot = [];
		for (const auditoriumDayProgram of event.auditoriums[0].dailyProgram) {
			if (auditoriumDayProgram.date === currentTime) {
				for (const timeSlot of auditoriumDayProgram.program) {
					const timeSlotStartTimestamp = getTimeslotStartTimestamp(
						auditoriumDayProgram.date,
						timeSlot,
						event.timezoneValue,
					);
					const timeSlotEndTimestamp = getTimeslotEndTimestamp(
						auditoriumDayProgram.date,
						timeSlot,
						event.timezoneValue,
					);
					if (
						currentTimeStamp >= timeSlotStartTimestamp &&
						currentTimeStamp < timeSlotEndTimestamp
					) {
						liveTimeslot.push(timeSlot);
					}
				}
			}
		}
		return liveTimeslot;
	}
};

export const checkIfEventHasUpcomingTimeslot = (event) => {
	let currentTimeStamp = moment();

	if (event !== null) {
		let upComingTimeslots = [];
		event.auditoriums[0].dailyProgram.forEach((auditoriumDayProgram) => {
			auditoriumDayProgram.program.forEach((timeSlot) => {
				let timeSlotStartTimestamp = moment(timeSlot.startTimestamp);
				if (timeSlotStartTimestamp > currentTimeStamp) {
					upComingTimeslots.push(timeSlot);
				}
			});
		});

		if (upComingTimeslots?.length > 0) {
			upComingTimeslots.sort((x, y) => {
				let xx = new Date(x.startTimestamp);
				let yy = new Date(y.startTimestamp);
				return xx - yy;
			});
		}

		return upComingTimeslots;
	}
};

export const numberOfTotalVideos = (event) => {
	if (event !== null) {
		let videos = [];
		event.auditoriums.forEach((auditorium) => {
			auditorium.dailyProgram.forEach((auditoriumDayProgram) => {
				if (auditoriumDayProgram.program.length > 0) {
					auditoriumDayProgram.program.forEach((timeSlot) => {
						if (timeSlot.onDemandAvailable) {
							videos.push(timeSlot);
						}
					});
				}
			});
		});

		return videos;
	}
};

export const getTimeslotStartTimestamp = (date, timeslot, timezone) => {
	let programDate = new Date(date);

	const programYear = programDate.getUTCFullYear();
	const programMonth = programDate.getUTCMonth();
	const programDay = programDate.getUTCDate();

	let timeSlotStart = timeslot.start.split(":");
	let timeSlotStartHour = timeSlotStart[0];
	let timeSlotStartMinute = timeSlotStart[1];

	let timeSlotStartTimestamp = new Date(
		programYear,
		programMonth,
		programDay,
		timeSlotStartHour,
		timeSlotStartMinute,
	);

	timezone = timezone === undefined ? 0 : timezone;
	timeSlotStartTimestamp =
		timeSlotStartTimestamp.getTime() - timezone * 60 * 60 * 1000;

	return timeSlotStartTimestamp;
};

export const getTimeslotEndTimestamp = (date, timeslot, timezone) => {
	let programDate = new Date(date);

	const programYear = programDate.getUTCFullYear();
	const programMonth = programDate.getUTCMonth();
	const programDay = programDate.getUTCDate();

	let timeSlotEnd = timeslot.end.split(":");
	let timeSlotEndHour = timeSlotEnd[0];
	let timeSlotEndMinute = timeSlotEnd[1];

	let timeSlotEndTimestamp = new Date(
		programYear,
		programMonth,
		programDay,
		timeSlotEndHour,
		timeSlotEndMinute,
	);

	timezone = timezone === undefined ? 0 : timezone;
	timeSlotEndTimestamp =
		timeSlotEndTimestamp.getTime() - timezone * 60 * 60 * 1000;

	return timeSlotEndTimestamp;
};

// we expect to have as parameters the logged in user privateChats and the targetUserId with which we want to open a private chat
export const getPrivateChatId = (privateChats, targetUserId) => {
	let privateChat = privateChats.find(
		(privateChat) =>
			privateChat.chat.user_1?._id === targetUserId ||
			privateChat.chat.user_2?._id === targetUserId,
	);

	// if we don't find a private chat we return false;
	// else we return the private chat ID
	return privateChat === undefined ? false : privateChat.chat._id;
};

export const getUserIdFromParticipantId = (
	eventParticipantsArray,
	participantId,
) => {
	const participant = eventParticipantsArray.find((participant) => {
		return participant._id === participantId;
	});
	return participant ? participant.user : null;
};

export const getUserIdFromExhibitorId = (eventExhibitorArray, exhibitorId) => {
	const exhibitor = eventExhibitorArray.find((exhibitor) => {
		return exhibitor._id === exhibitorId;
	});
	return exhibitor ? exhibitor.user._id : null;
};

export const checkUserIsParticipant = (eventParticipantsArray, userId) => {
	const participantIndex = eventParticipantsArray.findIndex((participant) => {
		return participant.user._id === userId;
	});
	return participantIndex !== -1;
};

export const getParticipantId = (eventParticipantsArray, userId) => {
	const participant = eventParticipantsArray.find((participant) => {
		return participant.user._id === userId;
	});
	return participant ? participant._id : null;
};

export const getParticipantData = (eventParticipantsArray, userId) => {
	const participant = eventParticipantsArray.find((participant) => {
		return participant.user === userId;
	});
	return participant ? participant : null;
};

export const checkUserIsExhibitor = (eventExhibitorsArray, userId) => {
	const exhibitorIndex = eventExhibitorsArray.findIndex((exhibitor) => {
		return exhibitor.user._id === userId;
	});
	return exhibitorIndex !== -1;
};

export const getExhibitorData = (eventExhibitorsArray, userId) => {
	const exhibitor = eventExhibitorsArray.find((exhibitor) => {
		// the or statement exhibitor.user?._id === userId
		// was added because in the socket event "updated-exhibitor"
		// the user property is an object
		// in the get event users the user is a string that contains the id
		return exhibitor.user === userId || exhibitor.user?._id === userId;
	});
	return exhibitor ? exhibitor : null;
};

export const checkUserIsOrganizer = (eventOwnerId, userId) => {
	return eventOwnerId === userId;
};

export const checkUserIsExhibitorRepresentative = (
	eventExhibitorsArray,
	userId,
) => {
	const exhibitorIndex = eventExhibitorsArray.findIndex((exhibitor) => {
		const booth = exhibitor.booth;
		const isMedalionRepresentative = booth.medalionRepresentatives.find(
			(representative) => representative.user === userId,
		);
		const isLeftDeskRepresentative =
			booth.deskRepresentativeLeft.user === userId;
		const isRightDeskRepresentative =
			booth.deskRepresentativeRight.user === userId;

		return (
			isMedalionRepresentative ||
			isLeftDeskRepresentative ||
			isRightDeskRepresentative
		);
	});
	return exhibitorIndex !== -1;
};

export const getTimeAgoString = (fromTime, language = "en") => {
	let timeAgoString = "";

	let messageTime = new Date(fromTime);
	messageTime = messageTime.getTime();

	let currentTime = new Date();
	currentTime = currentTime.getTime();

	let timeAgoSeconds = Math.abs((currentTime - messageTime) / 1000);
	timeAgoSeconds = Math.floor(timeAgoSeconds);

	if (timeAgoSeconds < 60) {
		timeAgoString = timeStrings[`${language}`].justNow;
	} else if (timeAgoSeconds < 60 * 60) {
		let minutes = Math.floor(timeAgoSeconds / 60);
		timeAgoString =
			minutes +
			" " +
			(minutes > 1
				? timeStrings[`${language}`].minutes
				: timeStrings[`${language}`].minute);
	} else if (timeAgoSeconds < 60 * 60 * 24) {
		let hours = Math.floor(timeAgoSeconds / (60 * 60));
		timeAgoString =
			hours +
			" " +
			(hours > 1
				? timeStrings[`${language}`].hours
				: timeStrings[`${language}`].hour);
	} else if (timeAgoSeconds < 60 * 60 * 24 * 30) {
		let days = Math.floor(timeAgoSeconds / (60 * 60 * 24));
		timeAgoString =
			days +
			" " +
			(days > 1
				? timeStrings[`${language}`].days
				: timeStrings[`${language}`].day);
	} else if (timeAgoSeconds < 60 * 60 * 24 * 30 * 12) {
		let months = Math.floor(timeAgoSeconds / (60 * 60 * 24 * 30));
		timeAgoString =
			months +
			" " +
			(months > 1
				? timeStrings[`${language}`].months
				: timeStrings[`${language}`].month);
	} else {
		let years = Math.floor(timeAgoSeconds / (60 * 60 * 24 * 30 * 12));
		timeAgoString =
			years +
			" " +
			(years > 1 ? timeStrings["en"].years : timeStrings["en"].year);
	}
	return timeAgoString;
};

export const getNotReadChatMessages = (privateChats, groupChats) => {
	// {unread} is destructuring, we have the property unread on each chat

	let unreadPrivateChatMessages = privateChats.reduce(
		(sum, { unread }) => sum + unread,
		0,
	);

	let unreadGroupChatMessages = groupChats.reduce(
		(sum, { unread }) => sum + unread,
		0,
	);

	let totalMessagesUnread = unreadPrivateChatMessages + unreadGroupChatMessages;
	return totalMessagesUnread;
};

export const getSortedNotifications = (notifications) => {
	let orderedNotifications = notifications.slice(0).reverse();
	let filteredNotifications = orderedNotifications.filter(
		(notification) =>
			notification.type !== "newPrivateChatMessage" &&
			notification.type !== "newGroupChatMessage",
	);
	return filteredNotifications;
};

export const getUtcTimestamp = (date) => {
	const year = date.getUTCFullYear();
	const month = date.getUTCMonth();
	const day = date.getUTCDate();
	const hour = date.getUTCHours();
	const minute = date.getUTCMinutes();
	const seconds = date.getUTCSeconds();

	let timeStamp = new Date(year, month, day, hour, minute, seconds);
	timeStamp = timeStamp.getTime();
	return timeStamp;
};

export const getVideoIdFromYoutubeUrl = (url) => {
	let ID = "";
	url = url
		.replace(/(>|<)/gi, "")
		.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
	if (url[2] !== undefined) {
		ID = url[2].split(/[^0-9a-z_-]/i);
		ID = ID[0];
	} else {
		ID = url;
	}
	return ID;
};

export const urlifyString = (text) => {
	const decodedString = text
		.replace(/&#x2F;/g, "/")
		.replace(/&#x5C;/g, "\\")
		.replace(/&amp;/g, "&")
		.replace(/&gt;/g, ">")
		.replace(/&lt;/g, "<")
		.replace(/&quot;/g, '"')
		.replace(/&#x27;/g, `'`)
		.replace(/&apos;/g, `'`)
		.replace(/&#96;/g, "`");
	const urlRegex = /(https?:\/\/[^\s]+)/g;

	let parts = decodedString.split(urlRegex);
	for (let i = 1; i < parts.length; i += 2) {
		parts[i] = (
			<a
				rel="noopener noreferrer"
				key={"link" + i}
				href={parts[i]}
				target="_blank"
			>
				{parts[i]}
			</a>
		);
	}
	return parts;
};

export const linkify = (stringURL) => {
	// eslint-disable-next-line no-useless-escape
	const urlPattern =
		/\b(?:https?|ftp):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim;
	// www. sans http:// or https://
	// eslint-disable-next-line no-useless-escape
	const pseudoUrlPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
	// Email addresses
	const emailAddressPattern = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim;
	return stringURL
		.replace(
			urlPattern,
			'<a target="_blank" rel="noopener noreferrer" href="$&">$&</a>',
		)
		.replace(
			pseudoUrlPattern,
			'$1<a target="_blank" rel="noopener noreferrer" href="http://$2">$2</a>',
		)
		.replace(
			emailAddressPattern,
			'<a target="_blank" rel="noopener noreferrer" href="mailto:$&">$&</a>',
		);
};

export const sortExhibitors = (exhibitors) => {
	// we first sort and display the exhibitors of type company
	let updatedExhibitors = [];
	const companyExhibitors = exhibitors.filter(
		(exhibitor) => exhibitor.type === "company",
	);
	const scholarExhibitors = exhibitors.filter(
		(exhibitor) => exhibitor.type === "scholar",
	);

	companyExhibitors.sort(function (x, y) {
		return x.company.toLowerCase() < y.company.toLowerCase() ? -1 : 1;
	});
	scholarExhibitors.sort(function (x, y) {
		return x?.user?.last?.toLowerCase() < y?.user?.last?.toLowerCase() ? -1 : 1;
	});
	updatedExhibitors = companyExhibitors.concat(scholarExhibitors);
	return updatedExhibitors;
};

export const getEventDaysArray = (eventStartDate, eventEndDate) => {
	// had a bug with this imlementation where it duplicated a day: 28.03
	// const oneDay = 24 * 60 * 60 * 1000;
	// const startDate = new Date(eventStartDate);
	// const endDate = new Date(eventEndDate);
	// const numberOfEventDays = Math.round(Math.abs((endDate - startDate) / oneDay)) + 1;
	//
	// let eventDays = [];
	// for (let i = 0; i < numberOfEventDays; i++) {
	//     let newDate = new Date(eventStartDate);
	//     newDate.setDate(startDate.getDate() + i);
	//     let formatedDate = newDate.toISOString().slice(0, 10);
	//     eventDays.push(formatedDate);
	// }

	// it looks like moment implementation is OK
	let eventDays = [];
	while (moment(eventStartDate) <= moment(eventEndDate)) {
		eventDays.push(eventStartDate);
		eventStartDate = moment(eventStartDate).add(1, "days").format("YYYY-MM-DD");
	}
	return eventDays;
};

export const getFormatedDate = (dateToFormat, fullMonth) => {
	// let date = new Date(dateToFormat);
	let date = moment(dateToFormat).startOf("day");
	let monthsArray = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	if (fullMonth) {
		monthsArray = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];
	}
	// const d = date.getDate();
	// const m = monthsArray[date.getMonth()];
	// const y = date.getFullYear();
	const d = date.date();
	const m = monthsArray[date.month()];
	const y = date.year();

	let formatedDate = (d <= 9 ? "0" + d : d) + " " + m + " " + y;

	return formatedDate;
};

export const getFormattedMonth = (date, translation) => {
	let months = [
		translation?.time.januaryShort,
		translation?.time.februaryShort,
		translation?.time.marchShort,
		translation?.time.aprilShort,
		translation?.time.mayShort,
		translation?.time.juneShort,
		translation?.time.julyShort,
		translation?.time.augustShort,
		translation?.time.septemberShort,
		translation?.time.octoberShort,
		translation?.time.novemberShort,
		translation?.time.decemberShort,
	];
	const currentDate = new Date(date);
	const m = months[currentDate.getMonth()];
	return m;
};

export const getTranslatedMonths = (platformLanguage, monthsTranslations) => {
	let months;

	if (platformLanguage === "ua") {
		// for on-demand months should be inclined on UA
		months = [
			"січня",
			"лютого",
			"березня",
			"квітня",
			"травня",
			"червня",
			"липня",
			"серпня",
			"вересня",
			"жовтня",
			"листопада",
			"грудня",
		];
	} else {
		months = [
			monthsTranslations.january,
			monthsTranslations.february,
			monthsTranslations.march,
			monthsTranslations.april,
			monthsTranslations.may,
			monthsTranslations.june,
			monthsTranslations.july,
			monthsTranslations.august,
			monthsTranslations.september,
			monthsTranslations.october,
			monthsTranslations.november,
			monthsTranslations.december,
		];
	}

	return months;
};

export const checkPlatformLanguage = (platformLanguage, languageArray) => {
	return languageArray.indexOf(platformLanguage) !== -1;
};

/**
 *
 * @param currentDay: date to format
 * @param languages: is the entire object from the redux store
 * @returns {string}
 */
export const getProgramDayString = (currentDay, languages) => {
	const { translations, platformLanguage } = languages;

	const monthsTranslations = translations[platformLanguage]?.time;

	let months = getTranslatedMonths(platformLanguage, monthsTranslations);

	let currentDate = moment(currentDay).startOf("day");
	let day = currentDate.date();
	let month = months[currentDate.month()];
	let year = currentDate.year();

	return `${day} ${month} ${year}`;
};

export const timezonesList = [
	{ label: "(GMT-12:00) International Date Line West", value: -12 },
	{ label: "(GMT-11:00) Midway Island, Samoa", value: -11 },
	{ label: "(GMT-10:00) Hawaii", value: -10 },
	{ label: "(GMT-08:00) Pacific Time (US & Canada)", value: -8 },
	{ label: "(GMT-09:00) Alaska", value: -9 },
	{ label: "(GMT-08:00) Tijuana, Baja California", value: -8 },
	{ label: "(GMT-07:00) Arizona", value: -7 },
	{ label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan", value: -7 },
	{ label: "(GMT-07:00) Mountain Time (US & Canada)", value: -7 },
	{ label: "(GMT-06:00) Central America", value: -6 },
	{ label: "(GMT-06:00) Central Time (US & Canada)", value: -6 },
	{ label: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco", value: -5 },
	{ label: "(GMT-05:00) Eastern Time (US & Canada)", value: -5 },
	{ label: "(GMT-05:00) Indiana (East)", value: -5 },
	{ label: "(GMT-04:00) Atlantic Time (Canada)", value: -4 },
	{ label: "(GMT-04:00) Caracas, La Paz", value: -4 },
	{ label: "(GMT-04:00) Manaus", value: -4 },
	{ label: "(GMT-04:00) Santiago", value: -4 },
	{ label: "(GMT-03:30) Newfoundland", value: -3.5 },
	{ label: "(GMT-03:00) Brasilia", value: -3 },
	{ label: "(GMT-03:00) Buenos Aires, Georgetown", value: -3 },
	{ label: "(GMT-03:00) Greenland", value: -3 },
	{ label: "(GMT-03:00) Montevideo", value: -3 },
	{ label: "(GMT-02:00) Mid-Atlantic", value: -2 },
	{ label: "(GMT-01:00) Cape Verde Is.", value: -1 },
	{ label: "(GMT-01:00) Azores", value: -1 },
	{ label: "(GMT+00:00) Casablanca, Monrovia, Reykjavik", value: 0 },
	{
		label:
			"(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
		value: 0,
	},
	{
		label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
		value: 1,
	},
	{
		label: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
		value: 1,
	},
	{ label: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris", value: 1 },
	{ label: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb", value: 1 },
	{ label: "(GMT+01:00) West Central Africa", value: 1 },
	{ label: "(GMT+02:00) Amman", value: 2 },
	{ label: "(GMT+02:00) Athens, Bucharest, Istanbul", value: 2 },
	{ label: "(GMT+02:00) Beirut", value: 2 },
	{ label: "(GMT+02:00) Cairo", value: 2 },
	{ label: "(GMT+02:00) Harare, Pretoria", value: 2 },
	{
		label: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
		value: 2,
	},
	{ label: "(GMT+02:00) Jerusalem", value: 2 },
	{ label: "(GMT+03:00) Minsk", value: 3 },
	{ label: "(GMT+02:00) Windhoek", value: 2 },
	{ label: "(GMT+03:00) Kuwait, Riyadh, Baghdad", value: 3 },
	{ label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd", value: 3 },
	{ label: "(GMT+03:00) Nairobi", value: 3 },
	{ label: "(GMT+03:00) Tbilisi", value: 3 },
	{ label: "(GMT+03:30) Tehran", value: 3.5 },
	{ label: "(GMT+04:00) Abu Dhabi, Muscat", value: 4 },
	{ label: "(GMT+04:00) Baku", value: 4 },
	{ label: "(GMT+04:00) Yerevan", value: 4 },
	{ label: "(GMT+04:30) Kabul", value: 4.5 },
	{ label: "(GMT+05:00) Yekaterinburg", value: 5 },
	{ label: "(GMT+05:00) Islamabad, Karachi, Tashkent", value: 5 },
	{ label: "(GMT+05:30) Sri Jayawardenapura", value: 5.5 },
	{ label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi", value: 5.5 },
	{ label: "(GMT+05:45) Kathmandu", value: 5.75 },
	{ label: "(GMT+06:00) Almaty, Novosibirsk", value: 6 },
	{ label: "(GMT+06:00) Astana, Dhaka", value: 6 },
	{ label: "(GMT+06:30) Yangon (Rangoon)", value: 6.5 },
	{ label: "(GMT+07:00) Bangkok, Hanoi, Jakarta", value: 7 },
	{ label: "(GMT+07:00) Krasnoyarsk", value: 7 },
	{ label: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi", value: 8 },
	{ label: "(GMT+08:00) Kuala Lumpur, Singapore", value: 8 },
	{ label: "(GMT+08:00) Irkutsk, Ulaan Bataar", value: 8 },
	{ label: "(GMT+08:00) Perth", value: 8 },
	{ label: "(GMT+08:00) Taipei", value: 8 },
	{ label: "(GMT+09:00) Osaka, Sapporo, Tokyo", value: 9 },
	{ label: "(GMT+09:00) Seoul", value: 9 },
	{ label: "(GMT+09:00) Yakutsk", value: 9 },
	{ label: "(GMT+09:30) Adelaide", value: 9.5 },
	{ label: "(GMT+09:30) Darwin", value: 9.5 },
	{ label: "(GMT+10:00) Brisbane", value: 10 },
	{ label: "(GMT+10:00) Canberra, Melbourne, Sydney", value: 10 },
	{ label: "(GMT+10:00) Hobart", value: 10 },
	{ label: "(GMT+10:00) Guam, Port Moresby", value: 10 },
	{ label: "(GMT+10:00) Vladivostok", value: 10 },
	{ label: "(GMT+11:00) Magadan, Solomon Is., New Caledonia", value: 11 },
	{ label: "(GMT+12:00) Auckland, Wellington", value: 12 },
	{ label: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.", value: 12 },
	{ label: "(GMT+13:00) Nuku'alofa", value: 13 },
];

export const timezonesListDST = [
	{ label: "(GMT-12:00) International Date Line West", value: -12 },
	{ label: "(GMT-11:00) Midway Island, Samoa", value: -11 },
	{ label: "(GMT-10:00) Hawaii", value: -10 },
	{ label: "(GMT-08:00) Alaska", value: -8 }, // +1
	{ label: "(GMT-07:00) Arizona", value: -7 },
	{ label: "(GMT-07:00) Pacific Time (US & Canada)", value: -7 }, // +1
	{ label: "(GMT-07:00) Tijuana, Baja California", value: -7 }, // +1
	{ label: "(GMT-06:00) Chihuahua, La Paz, Mazatlan", value: -6 }, // +1
	{ label: "(GMT-06:00) Mountain Time (US & Canada)", value: -6 }, // +1
	{ label: "(GMT-05:00) Central America", value: -5 }, // +1
	{ label: "(GMT-05:00) Central Time (US & Canada)", value: -5 }, // +1
	{ label: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco", value: -5 },
	{ label: "(GMT-05:00) Eastern Time (US & Canada)", value: -5 },
	{ label: "(GMT-04:00) Caracas, La Paz", value: -4 },
	{ label: "(GMT-04:00) Indiana (East)", value: -4 }, // +1
	{ label: "(GMT-04:00) Manaus", value: -4 },
	{ label: "(GMT-03:00) Atlantic Time (Canada)", value: -3 }, // +1
	{ label: "(GMT-03:00) Brasilia", value: -3 },
	{ label: "(GMT-03:00) Buenos Aires, Georgetown", value: -3 },
	{ label: "(GMT-03:00) Montevideo", value: -3 },
	{ label: "(GMT-03:00) Santiago", value: -3 }, // +1
	{ label: "(GMT-02:30) Newfoundland", value: -2.5 }, // +1
	{ label: "(GMT-02:00) Greenland", value: -2 }, // +1
	{ label: "(GMT-02:00) Mid-Atlantic", value: -2 },
	{ label: "(GMT-01:00) Cape Verde Is.", value: -1 },
	{ label: "(GMT-00:00) Azores", value: 0 }, // +1
	{ label: "(GMT+00:00) Casablanca, Monrovia, Reykjavik", value: 0 },
	{
		label:
			"(GMT+01:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
		value: 1,
	}, // +1
	{ label: "(GMT+01:00) West Central Africa", value: 1 },
	{
		label: "(GMT+02:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
		value: 2,
	}, // +1
	{
		label: "(GMT+02:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
		value: 2,
	}, // +1
	{ label: "(GMT+02:00) Brussels, Copenhagen, Madrid, Paris", value: 2 }, // +1
	{ label: "(GMT+02:00) Cairo", value: 2 },
	{ label: "(GMT+02:00) Harare, Pretoria", value: 2 },
	{ label: "(GMT+02:00) Sarajevo, Skopje, Warsaw, Zagreb", value: 2 }, // +1
	{ label: "(GMT+02:00) Windhoek", value: 2 },
	{ label: "(GMT+03:00) Amman", value: 3 }, // +1
	{ label: "(GMT+03:00) Athens, Bucharest, Istanbul", value: 3 }, // +1
	{ label: "(GMT+03:00) Beirut", value: 3 }, // +1
	{
		label: "(GMT+03:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
		value: 3,
	}, // +1
	{ label: "(GMT+03:00) Jerusalem", value: 3 }, // +1
	{ label: "(GMT+03:00) Kuwait, Riyadh, Baghdad", value: 3 },
	{ label: "(GMT+03:00) Minsk", value: 3 },
	{ label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd", value: 3 },
	{ label: "(GMT+03:00) Nairobi", value: 3 },
	{ label: "(GMT+03:00) Tbilisi", value: 3 },
	{ label: "(GMT+04:00) Abu Dhabi, Muscat", value: 4 },
	{ label: "(GMT+04:00) Baku", value: 4 },
	{ label: "(GMT+04:00) Yerevan", value: 4 },
	{ label: "(GMT+04:30) Kabul", value: 4.5 },
	{ label: "(GMT+04:30) Tehran", value: 4.5 }, // +1
	{ label: "(GMT+05:00) Yekaterinburg", value: 5 },
	{ label: "(GMT+05:00) Islamabad, Karachi, Tashkent", value: 5 },
	{ label: "(GMT+05:30) Sri Jayawardenapura", value: 5.5 },
	{ label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi", value: 5.5 },
	{ label: "(GMT+05:45) Kathmandu", value: 5.75 },
	{ label: "(GMT+06:00) Almaty, Novosibirsk", value: 6 },
	{ label: "(GMT+06:00) Astana, Dhaka", value: 6 },
	{ label: "(GMT+06:30) Yangon (Rangoon)", value: 6.5 },
	{ label: "(GMT+07:00) Bangkok, Hanoi, Jakarta", value: 7 },
	{ label: "(GMT+07:00) Krasnoyarsk", value: 7 },
	{ label: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi", value: 8 },
	{ label: "(GMT+08:00) Kuala Lumpur, Singapore", value: 8 },
	{ label: "(GMT+08:00) Irkutsk, Ulaan Bataar", value: 8 },
	{ label: "(GMT+08:00) Perth", value: 8 },
	{ label: "(GMT+08:00) Taipei", value: 8 },
	{ label: "(GMT+09:00) Osaka, Sapporo, Tokyo", value: 9 },
	{ label: "(GMT+09:00) Seoul", value: 9 },
	{ label: "(GMT+09:00) Yakutsk", value: 9 },
	{ label: "(GMT+09:30) Darwin", value: 9.5 },
	{ label: "(GMT+10:00) Brisbane", value: 10 },
	{ label: "(GMT+10:00) Guam, Port Moresby", value: 10 },
	{ label: "(GMT+10:00) Vladivostok", value: 10 },
	{ label: "(GMT+10:30) Adelaide", value: 10.5 }, // +1
	{ label: "(GMT+11:00) Canberra, Melbourne, Sydney", value: 11 }, // +1
	{ label: "(GMT+11:00) Hobart", value: 11 }, // +1
	{ label: "(GMT+11:00) Magadan, Solomon Is., New Caledonia", value: 11 },
	{ label: "(GMT+12:00) Auckland, Wellington", value: 12 },
	{ label: "(GMT+13:00) Fiji, Kamchatka, Marshall Is.", value: 13 }, // +1
	{ label: "(GMT+13:00) Nuku'alofa", value: 13 },
];

export const countries = [
	{ name: "Afghanistan", code: "AF" },
	{ name: "Albania", code: "AL" },
	{ name: "Algeria", code: "DZ" },
	{ name: "Andorra", code: "AD" },
	{ name: "Angola", code: "AO" },
	{ name: "Antigua and Barbuda", code: "AG" },
	{ name: "Argentina", code: "AR" },
	{ name: "Armenia", code: "AM" },
	{ name: "Australia", code: "AU" },
	{ name: "Austria", code: "AT" },
	{ name: "Azerbaijan", code: "AZ" },
	{ name: "Bahamas", code: "BS" },
	{ name: "Bahrain", code: "BH" },
	{ name: "Bangladesh", code: "BD" },
	{ name: "Barbados", code: "BB" },
	{ name: "Belarus", code: "BY" },
	{ name: "Belgium", code: "BE" },
	{ name: "Belize", code: "BZ" },
	{ name: "Benin", code: "BJ" },
	{ name: "Bhutan", code: "BT" },
	{ name: "Bolivia", code: "BO" },
	{ name: "Bosnia and Herzegovina", code: "BA" },
	{ name: "Botswana", code: "BW" },
	{ name: "Brazil", code: "BR" },
	{ name: "Brunei Darussalam", code: "BN" },
	{ name: "Bulgaria", code: "BG" },
	{ name: "Burkina Faso", code: "BF" },
	{ name: "Burundi", code: "BI" },
	{ name: "Cabo Verde", code: "CV" },
	{ name: "Cambodia", code: "KH" },
	{ name: "Cameroon", code: "CM" },
	{ name: "Canada", code: "CA" },
	{ name: "Central African Republic", code: "CF" },
	{ name: "Chad", code: "TD" },
	{ name: "Chile", code: "CL" },
	{ name: "China", code: "CN" },
	{ name: "Colombia", code: "CO" },
	{ name: "Comoros", code: "KM" },
	{ name: "Congo", code: "CG" },
	{ name: "Costa Rica", code: "CR" },
	{ name: "Côte D'Ivoire", code: "CI" },
	{ name: "Croatia", code: "HR" },
	{ name: "Cuba", code: "CU" },
	{ name: "Cyprus", code: "CY" },
	{ name: "Czech Republic", code: "CZ" },
	{ name: "DPR of Korea (North)", code: "KP" },
	{ name: "Democratic Republic of the Congo", code: "CD" },
	{ name: "Denmark", code: "DK" },
	{ name: "Djibouti", code: "DJ" },
	{ name: "Dominica", code: "DM" },
	{ name: "Dominican Republic", code: "DO" },
	{ name: "Ecuador", code: "EC" },
	{ name: "Egypt", code: "EG" },
	{ name: "El Salvador", code: "SV" },
	{ name: "Equatorial Guinea", code: "GQ" },
	{ name: "Eritrea", code: "ER" },
	{ name: "Estonia", code: "EE" },
	{ name: "Eswatini", code: "SZ" },
	{ name: "Ethiopia", code: "ET" },
	{ name: "Fiji", code: "FJ" },
	{ name: "Finland", code: "FI" },
	{ name: "France", code: "FR" },
	{ name: "Gabon", code: "GA" },
	{ name: "Gambia", code: "GM" },
	{ name: "Georgia", code: "GE" },
	{ name: "Germany", code: "DE" },
	{ name: "Ghana", code: "GH" },
	{ name: "Greece", code: "GR" },
	{ name: "Grenada", code: "GD" },
	{ name: "Guatemala", code: "GT" },
	{ name: "Guinea", code: "GN" },
	{ name: "Guinea Bissau", code: "GW" },
	{ name: "Guyana", code: "GY" },
	{ name: "Haiti", code: "HT" },
	{ name: "Honduras", code: "HN" },
	{ name: "Hungary", code: "HU" },
	{ name: "Iceland", code: "IS" },
	{ name: "India", code: "IN" },
	{ name: "Indonesia", code: "ID" },
	{ name: "Iran", code: "IR" },
	{ name: "Iraq", code: "IQ" },
	{ name: "Ireland", code: "IE" },
	{ name: "Israel", code: "IL" },
	{ name: "Italy", code: "IT" },
	{ name: "Jamaica", code: "JM" },
	{ name: "Japan", code: "JP" },
	{ name: "Jordan", code: "JO" },
	{ name: "Kazakhstan", code: "KZ" },
	{ name: "Kenya", code: "KE" },
	{ name: "Kiribati", code: "KI" },
	{ name: "Korea (South)", code: "KR" },
	{ name: "Kuwait", code: "KW" },
	{ name: "Kyrgyzstan", code: "KG" },
	{ name: "Laos", code: "LA" },
	{ name: "Latvia", code: "LV" },
	{ name: "Lebanon", code: "LB" },
	{ name: "Lesotho", code: "LS" },
	{ name: "Liberia", code: "LR" },
	{ name: "Libya", code: "LY" },
	{ name: "Liechtenstein", code: "LI" },
	{ name: "Lithuania", code: "LT" },
	{ name: "Luxembourg", code: "LU" },
	{ name: "Madagascar", code: "MG" },
	{ name: "Malawi", code: "MW" },
	{ name: "Malaysia", code: "MY" },
	{ name: "Maldives", code: "MV" },
	{ name: "Mali", code: "ML" },
	{ name: "Malta", code: "MT" },
	{ name: "Marshall Islands", code: "MH" },
	{ name: "Mauritania", code: "MR" },
	{ name: "Mauritius", code: "MU" },
	{ name: "Mexico", code: "MX" },
	{ name: "Micronesia", code: "FM" },
	{ name: "Moldova", code: "MD" },
	{ name: "Monaco", code: "MC" },
	{ name: "Mongolia", code: "MN" },
	{ name: "Montenegro", code: "ME" },
	{ name: "Morocco", code: "MA" },
	{ name: "Mozambique", code: "MZ" },
	{ name: "Myanmar", code: "MM" },
	{ name: "Namibia", code: "NA" },
	{ name: "Nauru", code: "NR" },
	{ name: "Nepal", code: "NP" },
	{ name: "Netherlands", code: "NL" },
	{ name: "New Zealand", code: "NZ" },
	{ name: "Nicaragua", code: "NI" },
	{ name: "Niger", code: "NE" },
	{ name: "Nigeria", code: "NG" },
	{ name: "North Macedonia", code: "MK" },
	{ name: "Norway", code: "NO" },
	{ name: "Oman", code: "OM" },
	{ name: "Pakistan", code: "PK" },
	{ name: "Palau", code: "PW" },
	{ name: "Palestine", code: "PS" },
	{ name: "Panama", code: "PA" },
	{ name: "Papua New Guinea", code: "PG" },
	{ name: "Paraguay", code: "PY" },
	{ name: "Peru", code: "PE" },
	{ name: "Philippines", code: "PH" },
	{ name: "Poland", code: "PL" },
	{ name: "Portugal", code: "PT" },
	{ name: "Qatar", code: "QA" },
	{ name: "Romania", code: "RO" },
	{ name: "Russia", code: "RU" },
	{ name: "Rwanda", code: "RW" },
	{ name: "Sahrawi Republic", code: "EH" },
	{ name: "Saint Kitts and Nevis", code: "KN" },
	{ name: "Saint Lucia", code: "LC" },
	{ name: "Saint Vincent and the Grenadines", code: "VC" },
	{ name: "Samoa", code: "WS" },
	{ name: "San Marino", code: "SM" },
	{ name: "Sao Tome and Principe", code: "ST" },
	{ name: "Saudi Arabia", code: "SA" },
	{ name: "Senegal", code: "SN" },
	{ name: "Serbia", code: "RS" },
	{ name: "Seychelles", code: "SC" },
	{ name: "Sierra Leone", code: "SL" },
	{ name: "Singapore", code: "SG" },
	{ name: "Slovakia", code: "SK" },
	{ name: "Slovenia", code: "SI" },
	{ name: "Solomon Islands", code: "SB" },
	{ name: "Somalia", code: "SO" },
	{ name: "South Africa", code: "ZA" },
	{ name: "South Sudan", code: "SS" },
	{ name: "Spain", code: "ES" },
	{ name: "Sri Lanka", code: "LK" },
	{ name: "Sudan", code: "SD" },
	{ name: "Suriname", code: "SR" },
	{ name: "Sweden", code: "SE" },
	{ name: "Switzerland", code: "CH" },
	{ name: "Syria", code: "SY" },
	{ name: "Tajikistan", code: "TJ" },
	{ name: "Tanzania", code: "TZ" },
	{ name: "Thailand", code: "TH" },
	{ name: "Timor-Leste", code: "TL" },
	{ name: "Togo", code: "TG" },
	{ name: "Tonga", code: "TO" },
	{ name: "Trinidad and Tobago", code: "TT" },
	{ name: "Tunisia", code: "TN" },
	{ name: "Turkey", code: "TR" },
	{ name: "Turkmenistan", code: "TM" },
	{ name: "Tuvalu", code: "TV" },
	{ name: "Uganda", code: "UG" },
	{ name: "Ukraine", code: "UA" },
	{ name: "United Arab Emirates", code: "AE" },
	{ name: "United Kingdom", code: "GB" },
	{ name: "United States of America", code: "US" },
	{ name: "Uruguay", code: "UY" },
	{ name: "Uzbekistan", code: "UZ" },
	{ name: "Vanuatu", code: "VU" },
	{ name: "Vatican City State", code: "VA" },
	{ name: "Venezuela", code: "VE" },
	{ name: "Viet Nam", code: "VN" },
	{ name: "Yemen", code: "YE" },
	{ name: "Zambia", code: "ZM" },
	{ name: "Zimbabwe", code: "ZW" },
];

export const getDayWithSuffix = (i) => {
	let j = i % 10,
		k = i % 100;
	if (j === 1 && k !== 11) {
		return i + "st";
	}
	if (j === 2 && k !== 12) {
		return i + "nd";
	}
	if (j === 3 && k !== 13) {
		return i + "rd";
	}
	return i + "th";
};

export const stripeCurrencies = [
	{ currencyCode: "usd", currencyLabel: "USD", minimumAmmount: 1 },
	{ currencyCode: "eur", currencyLabel: "EUR", minimumAmmount: 1 },
	{ currencyCode: "gbp", currencyLabel: "GBP", minimumAmmount: 1 },
	{ currencyCode: "nok", currencyLabel: "NOK", minimumAmmount: 10 },
	{ currencyCode: "sek", currencyLabel: "SEK", minimumAmmount: 10 },
	{ currencyCode: "dkk", currencyLabel: "DKK", minimumAmmount: 10 },
];

export const getCurrencySymbol = (currencyCode) => {
	let eventCurrency = "";
	switch (currencyCode) {
		case "eur":
			eventCurrency = "€";
			break;
		case "usd":
			eventCurrency = "$";
			break;
		case "gbp":
			eventCurrency = "£";
			break;
		case "nok":
			eventCurrency = "NOK";
			break;
		case "sek":
			eventCurrency = "SEK";
			break;
		case "dkk":
			eventCurrency = "DKK";
			break;
		default:
			eventCurrency = currencyCode;
	}
	return eventCurrency;
};

export const getLanguageName = (languageCode) => {
	const currentLanguage = languagesArray.find(
		(language) => language.value === languageCode,
	);
	return currentLanguage?.label;
};

export const getUserDataExcelFields = (event, registrationFields) => {
	let excelFields = [
		{ label: "Name", value: "fullName" },
		{ label: "Email", value: "email" },
		{ label: "Role", value: "role" },
		{ label: "Registration Date", value: "registrationDate" },
		{ label: "Registration Type", value: "registrationType" },
		{ label: "Timeslots", value: "timeslots" },
	];

	const { hasAccessManagement, exhibitorHasAccessManagement, closedEvent } =
		event;

	const {
		exhibitorPredefinedRegistrationFields,
		participantPredefinedRegistrationFields,
		exhibitorRegistrationFields,
		participantRegistrationFields,
	} = registrationFields;

	if (hasAccessManagement || exhibitorHasAccessManagement) {
		excelFields.push({ label: "Access Package", value: "accessPackage" });
	}
	if (closedEvent) {
		excelFields.push({ label: "Code", value: "invitationCode" });
	}

	// for each predefined field that "isEnabled", push it to Excel
	// the predefined fields are the same for Exhibitor or Participant
	// if one is enabled on one of them (participant / exhibitor), we push it to Excel
	Object.keys(exhibitorPredefinedRegistrationFields).map((key) => {
		const exhibitorPredefinedField = exhibitorPredefinedRegistrationFields[key];
		const participantPredefinedField =
			participantPredefinedRegistrationFields[key];
		if (
			exhibitorPredefinedField.isEnabled ||
			participantPredefinedField.isEnabled
		) {
			let fieldLabel = key.charAt(0).toUpperCase() + key.slice(1);
			if (event.scholarExhibitorsType && fieldLabel === "Company") {
				fieldLabel = "Company / Affiliation";
			}
			if (key !== "sdgs") {
				excelFields.push({ label: fieldLabel, value: key });
			}
		}

		return null;
	});

	// custom registration fields
	let customFields = [];
	exhibitorRegistrationFields.map((el) => {
		customFields.push(el.label);
		return null;
	});
	participantRegistrationFields.map((el) => {
		if (!customFields.includes(el.label)) {
			customFields.push(el.label);
		}
		return null;
	});

	customFields.map((el) => {
		let label = el;
		let value = el.toLowerCase();
		let obj = {};
		obj["label"] = label;
		obj["value"] = value;
		excelFields.push(obj);
		return null;
	});

	const data = {
		excelFields: excelFields,
		customFields: customFields,
	};

	return data;
};

export const checkTimeslotHasResources = (timeSlot) => {
	let timeslotHasResources = false;
	if (timeSlot) {
		if (
			("links" in timeSlot && timeSlot.links.length) ||
			("documents" in timeSlot && timeSlot.documents.length) ||
			("description" in timeSlot && timeSlot.description.length)
		) {
			timeslotHasResources = true;
		}
	}
	return timeslotHasResources;
};

export const getFormatedTimeFromSecondsToHMS = (totalSeconds) => {
	if (totalSeconds) {
		let hms = "";
		let hours = Math.floor(totalSeconds / 3600);
		hours = hours < 10 ? `0${hours}` : hours;

		totalSeconds %= 3600;
		let minutes = Math.floor(totalSeconds / 60);
		minutes = minutes < 10 ? `0${minutes}` : minutes;

		let seconds = Math.floor(totalSeconds % 60);
		seconds = seconds < 10 ? `0${seconds}` : seconds;
		hms = `${hours}:${minutes}:${seconds}`;
		return hms;
	} else {
		return "00:00:00";
	}
};

export const getBackgroundImageUrl = (eventInfo, location) => {
	let backgroundImage;

	let smallBackgroundImage =
		eventInfo.brandingData.filesUrl +
		(eventInfo.brandingData[location].resize1080
			? eventInfo.brandingData[location].resize1080
			: eventInfo.brandingData.location);
	let mediumBackgroundImage =
		eventInfo.brandingData.filesUrl +
		(eventInfo.brandingData[location].resize2160
			? eventInfo.brandingData[location].resize2160
			: eventInfo.brandingData[location]);
	let originalBackgroundImage =
		eventInfo.brandingData.filesUrl +
		(eventInfo.brandingData[location].original
			? eventInfo.brandingData[location].original
			: eventInfo.brandingData[location]);

	if (window.innerHeight < 1081) {
		backgroundImage = smallBackgroundImage;
	} else if (window.innerHeight < 2161) {
		backgroundImage = mediumBackgroundImage;
	} else {
		backgroundImage = originalBackgroundImage;
	}

	return backgroundImage;
};

export const getHPBackgroundImageURL = (eventInfo, location) => {
	let backgroundImage;

	let smallBackgroundImage =
		eventInfo.brandingData.filesUrl +
		(eventInfo.brandingData[location].resize768
			? eventInfo.brandingData[location].resize768
			: eventInfo.brandingData.location);
	let mediumBackgroundImage =
		eventInfo.brandingData.filesUrl +
		(eventInfo.brandingData[location].resize900
			? eventInfo.brandingData[location].resize900
			: eventInfo.brandingData[location]);
	let originalBackgroundImage =
		eventInfo.brandingData.filesUrl +
		(eventInfo.brandingData[location].resize1080
			? eventInfo.brandingData[location].resize1080
			: eventInfo.brandingData[location]);

	if (window.innerWidth <= 1024) {
		if (window.innerWidth <= 767) {
			backgroundImage = smallBackgroundImage;
		} else {
			backgroundImage = mediumBackgroundImage;
		}
	} else {
		backgroundImage = originalBackgroundImage;
	}

	return backgroundImage;
};

export const getUserEventRolesString = (user) => {
	let rolesString = "";
	if (user.isCoOrganizer) {
		rolesString += "Co-Organizer, ";
	}
	if (user.isParticipant) {
		rolesString += "Participant, ";
	}
	if (user.isExhibitor) {
		rolesString += "Exhibitor, ";
	}
	if (user.isExhibitorRepresentative) {
		rolesString += "Exhibitor Representative, ";
	}
	if (user.isOrganizerRepresentative) {
		rolesString += "Receptionist, ";
	}
	if (user.isScholar) {
		rolesString += "Poster / Study, ";
	}
	if (user.isScholarRepresentative) {
		rolesString += "Poster / Study Representative, ";
	}
	if (user.isSpeaker) {
		rolesString += "Speaker, ";
	}
	if (user?.user?.eventRoles.roles.includes("organizer")) {
		rolesString += "Organizer, ";
	}

	// we will remove the last 2 strings from the rolesString:  ", "
	rolesString = rolesString.slice(0, -2);
	return rolesString;
};

export const checkBoothOwnerOrRepresentative = (exhibitor, userId) => {
	if (!exhibitor) return false;
	if (!userId) return false;

	if (userId === exhibitor.user) {
		return true;
	}
	const boothData = exhibitor.booth;
	if (boothData["deskRepresentativeLeft"]?.user) {
		if (userId === boothData["deskRepresentativeLeft"]?.user._id) {
			return true;
		}
	}
	if (boothData["deskRepresentativeRight"]?.user) {
		if (userId === boothData["deskRepresentativeRight"]?.user._id) {
			return true;
		}
	}
	if (boothData["presentingRepresentative"]?.user) {
		if (userId === boothData["presentingRepresentative"]?.user._id) {
			return true;
		}
	}
	if (boothData["medalionRepresentatives"]?.length > 0) {
		const isMedalionRepresentative = boothData["medalionRepresentatives"].some(
			(representative) => {
				return userId === representative.user._id;
			},
		);
		return isMedalionRepresentative;
	}

	return false;
};

export const preventDefaultDrag = (e) => {
	e.preventDefault();
};

export const stopPropagation = (e) => {
	e.stopPropagation();
};

export const DEFAULT_MEDIA_KINDS = ["audio", "video"];

export const copyToClipboard = (value) => {
	const el = document.createElement("textarea");
	el.value = value;
	el.setAttribute("readonly", "");
	el.style.position = "absolute";
	el.style.left = "-9999px";
	document.body.appendChild(el);
	el.select();
	document.execCommand("copy");
	document.body.removeChild(el);
};

export const calculatePxByScreenHeight = () => {
	let screenHeight = window.innerHeight;
	return Math.round((screenHeight * 23) / 1000);
};

export const handleModularCoOrganizerMenu = (accessRights, eventProps) => {
	if (accessRights.setup) return "dashboard/settings";
	if (accessRights.branding) return "dashboard/branding";
	if (accessRights.auditoriums) return "dashboard/auditoriums";
	if (accessRights.program) return "dashboard/program";
	if (accessRights.showfloors) return "dashboard/showfloor";
	if (accessRights.users) return "dashboard/participants";
	if (accessRights.statistics) return "dashboard/statistics";
	if (accessRights.newsletters) return "dashboard/newsletters";
	if (accessRights.archive) return "dashboard/archived-events";
	if (accessRights.gdpr) return "dashboard/event-gdpr";
	if (
		accessRights.accessManagement &&
		(eventProps.hasAccessManagement ||
			eventProps.exhibitorHasAccessManagement ||
			eventProps.scholarHasAccessManagement ||
			eventProps.closedEvent)
	)
		return "dashboard/event-payment";
	if (accessRights.sessions && eventProps.sessionsStreaming)
		return "dashboard/event-sessions";
};
export let UUID = () => {
	let s4 = () => {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	};
	//return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
	return (
		s4() +
		s4() +
		"-" +
		s4() +
		"-" +
		s4() +
		"-" +
		s4() +
		"-" +
		s4() +
		s4() +
		s4()
	);
};

export const isUUID = (str, version = "all") => {
	const uuid = {
		3: /^[0-9A-F]{8}-[0-9A-F]{4}-3[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
		4: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
		5: /^[0-9A-F]{8}-[0-9A-F]{4}-5[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
		all: /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
	};
	return uuid[version] && uuid[version].test(str);
};

export const sortFn = (a, b) => {
	if (isNaN(a)) {
		return -1;
	} else if (isNaN(b)) {
		return 1;
	}
	return a === b ? 0 : a < b ? -1 : 1;
};

export const sortABC = (a, b) => {
	if (a.first.toLowerCase() === b.first.toLowerCase()) {
		return a.last.toLowerCase() < b.last.toLowerCase() ? -1 : 1;
	}
	return a.first.toLowerCase() < b.first.toLowerCase() ? -1 : 1;
};

export const sortByIsFeatured = (a, b) => {
	return a === b ? 0 : a ? -1 : 1;
};

export const getCookie = (cname) => {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
};

export const getDay = (eventDate) => {
	return moment(eventDate).format("DD");
};

export const getEventDate = (eventStart, eventEnd, languages) => {
	let months = [
		languages.translations[languages.platformLanguage]?.time.january,
		languages.translations[languages.platformLanguage]?.time.february,
		languages.translations[languages.platformLanguage]?.time.march,
		languages.translations[languages.platformLanguage]?.time.april,
		languages.translations[languages.platformLanguage]?.time.may,
		languages.translations[languages.platformLanguage]?.time.june,
		languages.translations[languages.platformLanguage]?.time.july,
		languages.translations[languages.platformLanguage]?.time.august,
		languages.translations[languages.platformLanguage]?.time.september,
		languages.translations[languages.platformLanguage]?.time.october,
		languages.translations[languages.platformLanguage]?.time.november,
		languages.translations[languages.platformLanguage]?.time.december,
	];

	const momentDateStart = moment(eventStart);
	const momentDateEnd = moment(eventEnd);

	let eventStartDay = getDay(eventStart);
	let eventStartMonth = months[momentDateStart.month()];
	let eventStartYear = momentDateStart.year();

	let eventEndDay = getDay(eventEnd);
	let eventEndMonth = months[momentDateEnd.month()];
	let eventEndYear = momentDateEnd.year();

	let singleDayStart =
		eventStartDay + " " + eventStartMonth + " " + eventStartYear;
	let singleDayEnd = eventEndDay + " " + eventEndMonth + " " + eventEndYear;
	let intervalDays = eventStartDay + " - " + singleDayEnd;
	let intervalMonths =
		eventStartDay + " " + eventStartMonth + " - " + singleDayEnd;
	if (eventStartYear === eventEndYear) {
		if (eventStartMonth === eventEndMonth) {
			if (eventStartDay === eventEndDay) {
				return singleDayStart;
			} else {
				return intervalDays;
			}
		} else {
			return intervalMonths;
		}
	} else {
		return singleDayStart + " - " + singleDayEnd;
	}
};

export const getAuditoriumDate = (eventStart, eventEnd, languages) => {
	let months = [
		languages.translations[languages.platformLanguage]?.time.january,
		languages.translations[languages.platformLanguage]?.time.february,
		languages.translations[languages.platformLanguage]?.time.march,
		languages.translations[languages.platformLanguage]?.time.april,
		languages.translations[languages.platformLanguage]?.time.may,
		languages.translations[languages.platformLanguage]?.time.june,
		languages.translations[languages.platformLanguage]?.time.july,
		languages.translations[languages.platformLanguage]?.time.august,
		languages.translations[languages.platformLanguage]?.time.september,
		languages.translations[languages.platformLanguage]?.time.october,
		languages.translations[languages.platformLanguage]?.time.november,
		languages.translations[languages.platformLanguage]?.time.december,
	];

	const momentDateStart = moment(eventStart);
	const momentDateEnd = moment(eventEnd);

	let eventStartMonth = months[momentDateStart.month()];
	let eventStartYear = momentDateStart.year();

	let eventEndMonth = months[momentDateEnd.month()];
	let eventEndYear = momentDateEnd.year();

	let singleDayStart = eventStartMonth + " " + eventStartYear;
	let singleDayEnd = eventEndMonth + " " + eventEndYear;
	let intervalMonths = eventStartMonth + " - " + singleDayEnd;
	if (eventStartYear === eventEndYear) {
		if (eventStartMonth === eventEndMonth) {
			return singleDayStart;
		} else {
			return intervalMonths;
		}
	} else {
		return singleDayStart + " - " + singleDayEnd;
	}
};

export const formatDecimalHoursToHoursAndMinutes = (hours) => {
	//
	const absHours = Math.abs(+hours);

	// if we have a whole number, without decimals, return it
	if (Number.isInteger(absHours)) {
		return absHours;
	} else {
		let n = new Date(0, 0);
		n.setMinutes(absHours * 60);
		const result = n.toTimeString().slice(0, 5);
		return result;
	}
};

export const getDayLabelString = (currentDay, languages) => {
	const today = moment(new Date()).startOf("day");
	const day = moment(currentDay).startOf("day");
	const diferenceInDays = day.diff(today, "days");
	const daysTranslationsArray =
		languages.translations[languages.platformLanguage];
	let daysOfTheWeek = [
		daysTranslationsArray?.time.monday,
		daysTranslationsArray?.time.tuesday,
		daysTranslationsArray?.time.wednesday,
		daysTranslationsArray?.time.thursday,
		daysTranslationsArray?.time.friday,
		daysTranslationsArray?.time.saturday,
		daysTranslationsArray?.time.sunday,
	];

	let currentDayString = "";
	if (diferenceInDays === -1) {
		currentDayString = daysTranslationsArray?.time.yesterday;
	} else if (diferenceInDays === 0) {
		currentDayString = daysTranslationsArray?.time.today;
	} else if (diferenceInDays === 1) {
		currentDayString = daysTranslationsArray?.time.tomorrow;
	} else {
		// currentDayString = day.format('dddd');
		currentDayString = daysOfTheWeek[day.isoWeekday() - 1];
	}
	return currentDayString;
};

export const stripHTML = (htmlString) => {
	if (!htmlString) return "";

	let doc = new DOMParser().parseFromString(htmlString, "text/html");
	return doc.body.textContent || "";
};

export const hasOnlyEmptySpaces = (value = "") => {
	if (value.length <= 1) {
		return value !== " ";
	}
	return value.trim().length !== 0;
};

export const minCharactersAndOptional = (value = "", minLength) => {
	if (!value) {
		return true;
	}
	return value.length >= minLength;
};

export const checkEventRoles = (eventRoles) => {
	return (
		eventRoles.isOrganizer ||
		eventRoles.isCoOrganizer ||
		eventRoles.isExhibitor ||
		eventRoles.isExhibitorRep
	);
};

export const checkIfEventLanguagesIncludeUserLanguage = (
	platformLanguage,
	eventLanguages,
) => {
	return eventLanguages.some(
		(eventLanguage) => eventLanguage.language === platformLanguage,
	);
};

// removes from a string the following characters: \ / : * ? " < > |
export const cleanProhibitedCharactersInFileName = (initialFileName) => {
	const cleanFileName = initialFileName?.replace(/[\/\\:*?<>|"]/g, "");
	return cleanFileName ?? "_";
};

/**
 * Emits message to Flutter webview
 * messageHandler object is injected from Flutter aplication during web page load
 *
 * @param {String} message
 */
export function sendMessageToFlutter(message) {
	if (!isWebview()) return;
	//NOTE: This is not a web native feature, messageHandler doesn't exist in browser.
	// do not remove eslint rule!
	/*eslint no-undef: "warn"*/
	messageHandler.postMessage(message);
}
