import React from 'react';

import {ReactComponent as TooltipArrow} from '../../../../Images/svg/tooltip-arrow.svg';
import {preventDefaultDrag} from '../../../../Utils/utils';

const StartVideoChatTooltip = ({newMessage, translation}) => {
    if (newMessage) return null;

    return (
        <div onDragStart={preventDefaultDrag} className="tooltip-container">
            <div onDragStart={preventDefaultDrag} className="tooltip">
                <p>{translation?.chatsDropdown.newVideoCallTooltipText}</p>
                <div onDragStart={preventDefaultDrag} className="triangle">
                    <TooltipArrow />
                </div>
            </div>
        </div>
    );
};

export default StartVideoChatTooltip;
