import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import './InviteToStage.scss';
import {ReactComponent as MicIcon} from '../../../Images/svg/mic.svg';
import {ReactComponent as ApproveIcon} from '../../../Images/svg/checked.svg';
import colors from '../../../CSS/_variables.module.scss';
import {ReactComponent as CancelIcon} from '../../../Images/svg/close-red.svg';
import Confirm from '../../../Dialogs/Confirm';

class InviteToStage extends React.Component {
    state={
        openRemoveRole: false,
        userData: null
    };

    handleInviteToStage = () => {
        const {liveSession, user} = this.props;
        const userName = `${user.first} ${user.last}`;

        const userData = {
            name: userName,
            role: 'speaker',
            isGuest: true,
            isReady: false
        };

        this.props.onInviteToStage(liveSession._id, user.user, userData);
    };

    handleCancelInvitation = () => {
        const {liveSession, user} = this.props;
        const liveSessionUser = liveSession.users.find(sessionUser => sessionUser.user === user.user);

        if(liveSessionUser){
            this.setState({
                openRemoveRole: false,
                userData: null,
            }, () => {
                this.props.onRemoveSessionUser(liveSession._id, liveSessionUser.userId)
            });
        }else{
            console.warn(`didn't find the user in session.users`)
        }
    };

    checkUserInvited = () => {
        const {liveSession, user} = this.props;
        const isInvited = liveSession.users.some(sessionUser => sessionUser.user === user.user);
        return isInvited;
    }

    handleAcceptUser = () => {
        const {liveSession} = this.props;
        const liveSessionUser = this.getCurrentLiveSessionUser();

        this.props.changeLiveSessionUserPermission(liveSession._id, 'approve', liveSessionUser.userId);
    }

    getCurrentLiveSessionUser = () => {
        const {liveSession, user} = this.props;
        const liveSessionUser = liveSession.users.find(sessionUser => sessionUser?.user === user?.user);
        return liveSessionUser
    };

    handleOpenRemovePopup = (user) => () => {
        this.setState({
            openRemoveRole: true,
            userData: user,
        });
    };

    closeRemoveRolePopup = () => {
        this.setState({
            openRemoveRole: false,
            userData: null,
        });
    };

    render() {
        const {openRemoveRole, userData} = this.state;
        const {translation, defaultTranslation, liveSession, user} = this.props;
        const isUserInvited = this.checkUserInvited();
        const liveSessionUser = this.getCurrentLiveSessionUser();

        return (
            <>
                <div className={'invite-to-stage'}>
                    {isUserInvited
                        ?<>
                            {liveSessionUser.status === "new"
                                ? <button
                                    type={'button'}
                                    className={'accept-request-button'}
                                    onClick={this.handleAcceptUser}
                                >
                                    <ApproveIcon fill={colors.primary}/>
                                    <span>{translation?.generalText.approve || defaultTranslation?.generalText.approve}</span>
                                </button>
                                :<span className={'invitation-status'}>
                                    {liveSessionUser.isReady
                                        ? translation?.sessions.isReady || defaultTranslation?.sessions.isReady
                                        : <>
                                        {liveSessionUser.invitationAccepted
                                            ? translation?.sessions.gettingReady || defaultTranslation?.sessions.gettingReady
                                            : translation?.sessions.requestPending || defaultTranslation?.sessions.requestPending
                                        }
                                        </>
                                    }
                                </span>
                            }
                            <button
                                type={'button'}
                                className={'cancel-invitation-button'}
                                onClick={this.handleOpenRemovePopup(user)}
                            >
                                {liveSessionUser.status === "new"
                                    ?
                                    <>
                                        <CancelIcon fill={colors.greyDark}/>
                                        <span>{translation?.generalText.decline || defaultTranslation?.generalText.decline}</span>
                                    </>
                                    :
                                    <>
                                        <CancelIcon fill={colors.greyDark}/>
                                        <span>{translation?.generalText.cancel || defaultTranslation?.generalText.cancel}</span>
                                    </>
                                }
                            </button>
                        </>
                        : <button
                            type={'button'}
                            className={'invite-to-stage-button'}
                            onClick={this.handleInviteToStage}
                            disabled={!user.isOnline || !liveSession.isActive}
                        >
                            <MicIcon fill={colors.secondary}/>
                            <span>{translation?.sessions.inviteToSpeak || defaultTranslation?.sessions.inviteToSpeak}</span>
                        </button>

                    }
                </div>
                {openRemoveRole &&
                <Confirm
                    open={openRemoveRole}
                    closeConfirm={this.closeRemoveRolePopup}
                    dialogTitle={(translation?.generalText.remove || defaultTranslation?.generalText.remove) + ` ${userData?.first + ' ' + userData.last}`}
                    dialogDescription={(translation?.sessions.removeRoleDescription || defaultTranslation?.sessions.removeRoleDescription) + ` ${userData?.first + ' ' + userData.last}?`}
                    dialogConfirmButtonLabel={translation?.generalText.remove || defaultTranslation?.generalText.remove}
                    dialogCancelButtonLabel={translation?.generalText.cancel || defaultTranslation?.generalText.cancel}
                    handleConfirm={this.handleCancelInvitation}/>
                }
            </>
        );
    }
}



const mapStateToProps = (state) => {
    return {
        liveSession: state.liveSession.liveSession,
        liveSessionUser: state.liveSession.liveSessionUser,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInviteToStage: (sessionId, userId, userData) => dispatch(actions.inviteToStage(sessionId, userId, userData)),
        onRemoveSessionUser: (sessionId, userId) => dispatch(actions.removeSessionUser(sessionId, userId)),
        changeLiveSessionUserPermission: (sessionId, permissionType, userId) => dispatch(actions.changeLiveSessionUserPermission(sessionId, permissionType, userId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteToStage);
