import React, {Component} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {Switch} from "@material-ui/core";
import {startCapture} from "../../Utils/videoConference";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {ReactComponent as ExpandArrow} from '../../Images/icons/sessionUserAccordionArrow.svg';
import ColoredScrollbars from '../../SmallLayoutComponents/ColoredScrollbars';
import Confirm from '../../Dialogs/Confirm';

class SessionUsers extends Component {

    state={
        activeTab: 'speakers',
        openRemoveRole: false,
        user: null,
    };

    setActiveTab = (tab) => e => {
        this.setState({
            activeTab: tab
        })
    };

    handleAcceptUser = (userId) => e => {
        const {liveSession} = this.props;
        this.props.changeLiveSessionUserPermission(liveSession._id, 'approve', userId);
    };

    handleKickUser = (user) => e => {
        const userId = user.userId;
        const {liveSession} = this.props;

        this.setState({
            openRemoveRole: false,
        });

        if (user.isGuest){
            this.props.onRemoveSessionUser(liveSession._id, userId)
        } else {
            this.props.changeLiveSessionUserPermission(liveSession._id, 'kick', userId);
        }
    };

    getActiveUserKinds = () => {
        const {liveSessionUser} = this.props;

        const kinds = [];
        if(liveSessionUser.audio){kinds.push('audio')};
        if(liveSessionUser.video){kinds.push('video')};

        return kinds;
    };

    startSelfCapture = async () => {
        const {liveSessionUser} = this.props;

        // capure + stream my webcam video data
        const kinds = this.getActiveUserKinds();
        const selfSessionCapture = await startCapture(liveSessionUser.userId, kinds);
        this.setState({
            userCapture: selfSessionCapture
        });
        return selfSessionCapture;
    };

    toggleAuthorization = (userId, authorizationType) => e => {
        const {liveSession} = this.props;

        const authorizationData = {
            permissionType: authorizationType,
            permissionValue: e.target.checked
        };
        this.props.changeLiveSessionUserAuthorization(liveSession._id, userId, authorizationData)
    };

    // not used anymore
    renderKickedUserRow = user => {
        return(
            <div className="row single-user" key={user.userId}>
                <div className="name">
                    <div className="live-session-avatar">
                        {user.name.charAt(0)}
                    </div>
                    {user.name}
                </div>
                <div className="status">
                    {user.status === 'new' ? "Denied access" : "Kicked"}
                </div>
            </div>
        )
    };

    checkCanModerate = () => {
        const {liveSessionUser} = this.props;
        const canModerate = (liveSessionUser.role === "organizer" || liveSessionUser.role === "moderator");
        return canModerate
    };

    renderUnapprovedUsers = (userType) => {
        const {liveSession} = this.props;
        const isModerator = this.checkCanModerate();
        const unapprovedSpeakers = liveSession?.users.filter(user => user.role === userType && user.status === 'new' && !user.kicked && !user.isGuest) || [];

        if(isModerator && unapprovedSpeakers.length > 0){
            return (
                <span className={'unapproved-users'}>{unapprovedSpeakers.length}</span>
            )
        }
    };

    handleRemoveRolePopup = (user) => () => {
        this.setState({
            openRemoveRole: true,
            user: user,
        });
    };

    closeRemoveRole = () => {
        this.setState({
            openRemoveRole: false,
            user: null,
        });
    };

    sessionHasSpeakers = () => {
        const {liveSession} = this.props;
        return liveSession.users.filter(user=>user.role==='speaker' && !user.kicked && user.invitationAccepted).length !== 0
    }

    render() {
        const {activeTab, openRemoveRole, user} = this.state;
        const {liveSession, liveSessionUser, translation, defaultTranslation} = this.props;
        const isModerator = this.checkCanModerate();
        return (
            <div className="module live-session-users">
                <div className="module-container">
                    <div className="module-header">
                        <p
                            className={`tab ${activeTab === 'speakers' ? '-active' : ''}`}
                            onClick={this.setActiveTab('speakers')}
                        >
                            {translation?.sessions.speakers || defaultTranslation?.sessions.speakers}
                            {this.renderUnapprovedUsers('speaker') }
                        </p>
                        <p
                            className={`tab ${activeTab === 'moderators' ? '-active' : ''}`}
                            onClick={this.setActiveTab('moderators')}
                        >
                            {translation?.sessions.moderators || defaultTranslation?.sessions.moderators}
                            {this.renderUnapprovedUsers('moderator')}
                        </p>
                    </div>

                        <div className="live-session-users-table">
                            <ColoredScrollbars>
                                <div className="module-body">
                                    {!this.sessionHasSpeakers() && activeTab === 'speakers' &&
                                        <div className={`no-users`}>
                                            {translation?.sessions.noSpeakersJoined || defaultTranslation?.sessions.noSpeakersJoined}
                                        </div>
                                    }

                                    {liveSession.users.map(user => {
                                        const isMyself = liveSessionUser.userId === user.userId;
                                        const sessionUserIsModerator = (user.role === "organizer" || user.role === "moderator");
                                        const hideUserDetails = liveSessionUser.role === "speaker" || isMyself;

                                        // if the user is moderator and we are on the speakers tab, don't display him
                                        if(sessionUserIsModerator && activeTab !== 'moderators'){
                                            return null
                                        }

                                        // if the user is moderator, unaproved and we are a speaker, don't display it
                                        if(sessionUserIsModerator && user.status !== "approved" && liveSessionUser.role === "speaker"){
                                            return null
                                        }

                                        // if the user is speakers and we are on the moderators tab, don't display him
                                        if(user.role === "speaker" && activeTab !== 'speakers'){
                                            return null
                                        }

                                        if(user.kicked) {
                                            return null
                                        }

                                        if(user.status === "new" && user.isGuest) {
                                            return null
                                        }

                                        // don't display users that didn't accept the invitation
                                        if(!user.invitationAccepted) {
                                            return null
                                        }

                                        return (
                                            <Accordion key={user.userId}
                                                       square={true}
                                                       defaultExpanded={user.status === "new"}
                                            >
                                                <AccordionSummary
                                                    expandIcon={isModerator && !isMyself && <ExpandArrow />}
                                                    className={`${hideUserDetails ? 'no-user-details' : ''}`}
                                                >
                                                    <span>{user.name} {user.isGuest && (<>
                                                        {`(${(translation?.sessions.fromAuditorium || defaultTranslation?.sessions.fromAuditorium)})`}
                                                    </>)}</span>
                                                </AccordionSummary>
                                                {isModerator && !isMyself &&
                                                    <AccordionDetails>
                                                        {user.status === "approved" && isModerator && !isMyself &&
                                                        <>
                                                            <div className="switches-container">
                                                                <div className="single-action-container">
                                                                    <span className="action-label">{translation?.sessions.mic || defaultTranslation?.sessions.mic}</span>
                                                                    <Switch className={`${user.audio ? 'is-sharing' : ''}`}
                                                                            checked={user.allowAudio}
                                                                            disabled={sessionUserIsModerator && liveSessionUser.role !== "organizer"}
                                                                            onClick={this.toggleAuthorization(user.userId, 'allowAudio')}
                                                                    />
                                                                </div>
                                                                <div className="single-action-container">
                                                                    <span className="action-label">{translation?.sessions.video || defaultTranslation?.sessions.video}</span>
                                                                    <Switch className={`${user.video ? 'is-sharing' : ''}`}
                                                                            checked={user.allowVideo}
                                                                            disabled={sessionUserIsModerator && liveSessionUser.role !== "organizer"}
                                                                            onClick={this.toggleAuthorization(user.userId, 'allowVideo')}
                                                                    />
                                                                </div>
                                                                <div className="single-action-container">
                                                                    <span className="action-label">{translation?.sessions.screen || defaultTranslation?.sessions.screen}</span>
                                                                    <Switch
                                                                        className={`${user.screenSharing ? 'is-sharing' : ''}`}
                                                                        checked={user.allowScreenSharing}
                                                                        disabled={sessionUserIsModerator && liveSessionUser.role !== "organizer"}
                                                                        onClick={this.toggleAuthorization(user.userId, 'allowScreenSharing')}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {((sessionUserIsModerator && liveSessionUser.role === 'organizer') || !sessionUserIsModerator) &&
                                                            <div className="single-action-container">
                                                                <button type={'button'}
                                                                        className={`button kick`}
                                                                        onClick={this.handleRemoveRolePopup(user)}
                                                                >
                                                                    <span className="action-label">
                                                                        {translation?.generalText.remove || defaultTranslation?.generalText.remove}
                                                                    </span>
                                                                    <CloseIcon className="button_icon"/>
                                                                </button>
                                                            </div>
                                                            }
                                                        </>

                                                        }
                                                        {isModerator && user.status === "new" &&
                                                        <div className="permission-actions-container">
                                                            <button type={'button'}
                                                                    className={`button admit`}
                                                                    onClick={this.handleAcceptUser(user.userId)}
                                                            >
                                                                <CheckIcon className="button_icon"/>
                                                                <span>{translation?.generalText.approve || defaultTranslation?.generalText.approve}</span>
                                                            </button>
                                                            <button type={'button'}
                                                                    className={`button decline`}
                                                                    onClick={this.handleRemoveRolePopup(user)}
                                                            >
                                                                <CloseIcon className="button_icon"/>
                                                                <span>{translation?.generalText.decline || defaultTranslation?.generalText.decline}</span>
                                                            </button>
                                                        </div>
                                                        }
                                                    </AccordionDetails>
                                                }
                                            </Accordion>
                                        )
                                    })}
                                </div>
                            </ColoredScrollbars>
                        </div>
                </div>
                {openRemoveRole &&
                    <Confirm
                        open={openRemoveRole}
                        closeConfirm={this.closeRemoveRole}
                        dialogTitle={(translation?.generalText.remove || defaultTranslation?.generalText.remove) + ` ${user?.name}`}
                        dialogDescription={(translation?.sessions.removeRoleDescription || defaultTranslation?.sessions.removeRoleDescription) + ` ${user?.name}?`}
                        dialogConfirmButtonLabel={translation?.generalText.remove || defaultTranslation?.generalText.remove}
                        dialogCancelButtonLabel={translation?.generalText.cancel || defaultTranslation?.generalText.cancel}
                        handleConfirm={this.handleKickUser(user)}/>
                }
            </div>

        )
    }
}



const mapStateToProps = (state) => {
    return {
        liveSession: state.liveSession.liveSession,
        userData: state.user.data,
        eventRoles: state.user.eventRoles,
        liveSessionUser: state.liveSession.liveSessionUser,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRemoveSessionUser: (sessionId, userId) => dispatch(actions.removeSessionUser(sessionId, userId)),
        changeLiveSessionUserPermission: (sessionId, permissionType, userId) => dispatch(actions.changeLiveSessionUserPermission(sessionId, permissionType, userId)),
        changeLiveSessionUserAuthorization: (sessionId, userId, authorization) => dispatch(actions.changeLiveSessionUserAuthorization(sessionId, userId, authorization)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionUsers)


