import React, {PureComponent} from 'react';
import {preventDefaultDrag} from '../Utils/utils';

class RegistrationPlatformDescription extends PureComponent {
    render() {
        const {isOnTabRegister} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} className={`left-form ${isOnTabRegister ? 'sign-up' : ''}`}>
                <div>
                    <div
                        onDragStart={preventDefaultDrag}
                        className={`form-header left-log-in-description ${isOnTabRegister ? 'is-sign-up' : ''}`}
                    >
                        <h1 className="form-title banner-text">
                            <div>
                                {isOnTabRegister ? <span>Sign up</span> : <span>Log in to the Neural Network</span>}
                            </div>
                        </h1>
                        {isOnTabRegister && (
                            <div onDragStart={preventDefaultDrag} draggable="false" className="steps-text">
                                <div>
                                    <div className="active">
                                        1
                                    </div>
                                    <div>
                                        2
                                    </div>
                                </div>
                                <span>
                                    steps
                                </span>
                            </div>
                        )}
                    </div>
                    <p>Welcome to the AI for Good Neural Network! Register now to join the AI-powered community networking and content platform aimed at accelerating innovation and collaboration to achieve the Sustainable Development Goals. Watch live and past AI for Good sessions, use the AI smart-matching to help you build connections with innovators and experts, visit virtual exhibits and poster booths, and more.</p>
                    <div className="read-more-platform">
                        <a href={`${process.env.REACT_APP_AIFG_WP_LINK}/neural-network/`}>
                            Read more
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default RegistrationPlatformDescription;
