import React from 'react';
import Wall from '../HOC/Wall/Wall';
import Networking from '../HOC/Networking';
import { connect } from 'react-redux';
import * as actions from '../store/actions/index';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import LockIcon from '@material-ui/icons/Lock';
import '../CSS/networkAndLiveWall.scss';
import SideMenuMobile from './SideMenu/SideMenuMobile';
import { preventDefaultDrag } from '../Utils/utils';
import axios from '../store/axios-instance';

class SideNav extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.event.hideLobbyNetworking !== this.props.event.hideLobbyNetworking) {
            if (this.props.event.hideLobbyNetworking && this.props.sideNavigation.seeNetworking) {
                this.handleSeeLiveWall();
            }
        }
    }

    handleSeeNetworking = () => this.props.seeNetworking();
    handleCloseSidebar = () => this.props.closeSidebar();
    handleSeeInfoTab = () => this.props.seeInfoTab();

    handleSeeLiveWall = () => {
        const wallData = {
            wallId: this.props.event.liveWallId,
            highlightUsers: [],
        };

        this.props.setActiveWall(wallData);
        this.props.seeLiveWall();
    };

    handleSeeVideoWall = () => {
        const wallData = {
            wallId: this.props.sideNavigationDetails.currentTimeSlotVideoWallId,
            highlightUsers: this.props.sideNavigationDetails.currentTimeSlotVideoWallSpeakers,
        };
        this.props.setActiveWall(wallData);

        this.props.seeVideoWall();
    };

    refreshPage = () => {
        axios
            .delete(`users/${this.props.user?._id}/remove-user-from-redis`)
            .then((data) => {
                window.location.reload(true);
            })
            .catch((error) => {
                window.location.reload(true);
            });
    };

    handleEnterKey = (tab) => (e) => {
        if (e.key === 'Enter') {
            if (tab === 'wall') return this.handleSeeLiveWall();
            if (tab === 'networking') return this.props.seeNetworking();
        }
    };

    render() {
        const {
            event,
            eventRoles,
            resourcesAccess,
            sideNavigation,
            sideNavigationDetails,
            isLargeScreen,
            translation,
            defaultTranslation,
            openMatchingDialog,
        } = this.props;

        let hasAccessToNetworking = true;
        if (event.hasAccessManagement && eventRoles.isParticipant && resourcesAccess && !resourcesAccess.networking) {
            hasAccessToNetworking = false;
        } else if (
            (event.exhibitorHasAccessManagement || event.scholarHasAccessManagement) &&
            eventRoles.isExhibitor &&
            resourcesAccess &&
            !resourcesAccess.networking
        ) {
            hasAccessToNetworking = false;
        }


        if (!isLargeScreen) {
            return (
                <SideMenuMobile
                    openMatchingDialog={openMatchingDialog}
                    handleSeeNetworkingTab={this.handleSeeNetworking}
                    handleSeeLiveWall={this.handleSeeLiveWall}
                    handleSeeVideoWall={this.handleSeeVideoWall}
                    handleSeeInfoTab={this.handleSeeInfoTab}
                />
            );
        }

        return (
            <>
                <div
                    className={
                        'wall-and-networking-container ' + (this.props.sideNavigation.isOpened ? 'open' : 'close')
                    }
                >
                    {sideNavigation.isOpened && (
                        <div>
                            {this.props.serverDown ? (
                                <div onDragStart={preventDefaultDrag} className="server-down-info-container event-menu">
                                    <p>
                                        {translation?.eventMenu.connectionProblemTextFirst}
                                        <span
                                            onDragStart={preventDefaultDrag}
                                            draggable="false"
                                            onClick={this.refreshPage}
                                        >
                                            {translation?.eventMenu.connectionProblemButton}
                                        </span>
                                        {translation?.eventMenu.connectionProblemTextSecond}
                                    </p>
                                </div>
                            ) : (
                                <div onDragStart={preventDefaultDrag} className="tabs-container">
                                    {sideNavigationDetails.hasVideoWall ? (
                                        <>
                                            {!sideNavigation.seeNetworking && !event.hideLobbyNetworking && (
                                                <div
                                                    tabIndex="0"
                                                    className="networking-tab button-tab tab "
                                                    onClick={this.handleSeeNetworking}
                                                    onKeyDown={this.handleEnterKey('networking')}
                                                >
                                                    {translation?.sideMenu.matchMaking ||
                                                        defaultTranslation?.sideMenu.matchMaking}
                                                </div>
                                            )}
                                            {!sideNavigation.seeLiveWall && (
                                                <div
                                                    tabIndex="0"
                                                    className="live-wall-tab button-tab tab"
                                                    onClick={this.handleSeeLiveWall}
                                                    onKeyDown={this.handleEnterKey('wall')}
                                                >
                                                    {translation?.sideMenu.liveWall}
                                                </div>
                                            )}
                                            {!sideNavigation.seeVideoWall && (
                                                <div
                                                    className="video-wall-tab button-tab tab"
                                                    onClick={this.handleSeeVideoWall}
                                                >
                                                    {translation?.sideMenu.videoWall}
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {!event.hideLobbyNetworking && (
                                                <div
                                                    tabIndex="0"
                                                    className={
                                                        'networking-tab button-tab tab ' +
                                                        (sideNavigation.seeNetworking ? 'active' : '')
                                                    }
                                                    onClick={this.handleSeeNetworking}
                                                    onKeyDown={this.handleEnterKey('networking')}
                                                >
                                                    {!hasAccessToNetworking ? (
                                                        <Tooltip title={translation?.sideMenu.networkingTooltip}>
                                                            <div
                                                                onDragStart={preventDefaultDrag}
                                                                className="restricted-network"
                                                            >
                                                                <LockIcon fontSize="small" />{' '}
                                                                {translation?.sideMenu.matchMaking ||
                                                                    defaultTranslation?.sideMenu.matchMaking}
                                                            </div>
                                                        </Tooltip>
                                                    ) : (
                                                        <>
                                                            {translation?.sideMenu.matchMaking ||
                                                                defaultTranslation?.sideMenu.matchMaking}
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                            <div
                                                tabIndex="0"
                                                className={
                                                    'live-wall-tab button-tab tab ' +
                                                    (sideNavigation.seeLiveWall ? 'active' : '')
                                                }
                                                onClick={this.handleSeeLiveWall}
                                                onKeyDown={this.handleEnterKey('wall')}
                                            >
                                                {translation?.sideMenu.liveWall}
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}

                            <div>
                                {sideNavigation.seeLiveWall ? <Wall isLiveWall /> : null}
                                {sideNavigation.seeNetworking && <Networking openMatchingDialog={openMatchingDialog} />}
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        user: state.user.data,
        eventMatching: state.organizer.eventMatching,
        sideNavigation: state.user.sideNavigation,
        sideNavigationDetails: state.event.sideNavigationDetails,
        eventRoles: state.user.eventRoles,
        serverDown: state.user.serverDown,
        resourcesAccess: state.user.resourcesAccess,
        isLargeScreen: state.layout.isLargeScreen,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        seeNetworking: () => dispatch(actions.sideNavSeeNetworking()),
        seeLiveWall: () => dispatch(actions.sideNavSeeLiveWall()),
        seeVideoWall: () => dispatch(actions.sideNavSeeVideoWall()),
        closeSidebar: () => dispatch(actions.sideNavClose()),
        setActiveWall: (wallData) => dispatch(actions.setActiveWall(wallData)),
        seeInfoTab: () => dispatch(actions.sideNavSeeInfoTab()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
