import React from 'react';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions';
import Button from '@material-ui/core/Button';
import {preventDefaultDrag} from 'Utils/utils';
import {isWebview} from 'Utils/is-webview';

class EditUserInformation extends React.Component {
    state = {
        user: {
            firstName: '',
            lastName: '',
        },
        disabled: true,
    };

    componentDidMount() {
        this.setState({
            user: {
                firstName: this.props.user.first,
                lastName: this.props.user.last,
            },
        });
        ValidatorForm.addValidationRule('noCommasAllowed', (value) => {
            let rule = new RegExp('^[^,]+$', 'i');
            let match = rule.test(value);

            if (value === undefined || value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
    }
    componentWillUnmount() {
        ValidatorForm.removeValidationRule('noCommasAllowed');
    }

    handleChange = (e) => {
        this.setState(
            {
                user: {
                    ...this.state.user,
                    [e.target.name]: e.target.value,
                },
            },
            () => {
                this.refs.form.isFormValid().then((isValid) => {
                    this.setState({disabled: !isValid});
                });
            }
        );
    };

    handleUpdateUserInformation = () => {
        const {eventId, onGetUsers, closeDialog, onRefreshUserData, onUpdateUserInformation} = this.props;
        const {user} = this.state;
        onUpdateUserInformation({first: user.firstName, last: user.lastName})
            .then(() => {
                onRefreshUserData().then(() => onGetUsers(eventId));
                closeDialog();
            })
            .catch(() => {});
    };

    render() {
        const {opened, user, handleImageChange, handleRemoveUserAvatar, imgErrorText, translation, defaultTranslation} =
            this.props;
        let {firstName, lastName} = this.state.user;
        return (
            <div
                onDragStart={preventDefaultDrag}
                className={`edit-user-information small-padding-top ${opened ? 'opened' : ''}`}
            >
                <div>
                    <div onDragStart={preventDefaultDrag} className="page-title">
                        <p>{translation?.myAccountPage.editUserDialogTitle}</p>
                    </div>
                    <div onDragStart={preventDefaultDrag} className="content-wrapper">
                        <div onDragStart={preventDefaultDrag} className="form-wrapper">
                            <div>
                                <div onDragStart={preventDefaultDrag} className="image-container">
                                    <div onDragStart={preventDefaultDrag} className="avatar">
                                        <img
                                            draggable="false"
                                            src={`${process.env.REACT_APP_AVATAR_FOLDER}${user.avatarBig}`}
                                            alt="Profile"
                                        />
                                    </div>
                                    {isWebview() ? null : (
                                        <div onDragStart={preventDefaultDrag} className="upload-new-image">
                                            <div onDragStart={preventDefaultDrag} className="upload-container">
                                                <label htmlFor="file" className="select-image"></label>
                                                <input
                                                    onChange={handleImageChange}
                                                    name="file"
                                                    type="file"
                                                    id="file"
                                                    className="d-none"
                                                />
                                                <span>{translation?.myAccountPage.uploadImage}</span>
                                            </div>
                                            <button
                                                className="remove-image"
                                                onClick={handleRemoveUserAvatar}
                                                disabled={user.avatarBig === 'default_avatar.png'}
                                            >
                                                <span>{translation?.myAccountPage.removeImage}</span>
                                            </button>
                                            <span
                                                onDragStart={preventDefaultDrag}
                                                draggable="false"
                                                className="image-error"
                                            >
                                                {imgErrorText}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <ValidatorForm ref="form" onSubmit={this.handleUpdateUserInformation}>
                                <div onDragStart={preventDefaultDrag} className="validator-input">
                                    <TextValidator
                                        label={translation?.myAccountPage.firstName}
                                        onChange={this.handleChange}
                                        name="firstName"
                                        value={firstName}
                                        variant="outlined"
                                        fullWidth
                                        required
                                        validators={['required', 'noCommasAllowed']}
                                        errorMessages={[
                                            `${translation?.errors.required}`,
                                            `${
                                                translation?.errors.noCommasAllowed ||
                                                defaultTranslation.errors.noCommasAllowed
                                            }`,
                                        ]}
                                    />
                                    <TextValidator
                                        label={translation?.myAccountPage.lastName}
                                        onChange={this.handleChange}
                                        name="lastName"
                                        value={lastName}
                                        variant="outlined"
                                        fullWidth
                                        required
                                        validators={['required', 'noCommasAllowed']}
                                        errorMessages={[
                                            `${translation?.errors.required}`,
                                            `${
                                                translation?.errors.noCommasAllowed ||
                                                defaultTranslation.errors.noCommasAllowed
                                            }`,
                                        ]}
                                    />
                                </div>
                            </ValidatorForm>
                        </div>
                    </div>
                    <div onDragStart={preventDefaultDrag} className="buttons-actions">
                        <Button
                            type="button"
                            classes={{label: 'dialog-btn-uppercase'}}
                            onClick={() => this.props.closeDialog('')}
                        >
                            {translation?.generalText.close}
                        </Button>
                        <Button
                            type="submit"
                            classes={{label: 'dialog-btn-uppercase'}}
                            color="secondary"
                            disabled={this.state.disabled}
                            onClick={this.handleUpdateUserInformation}
                        >
                            {translation?.generalText.update}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        eventId: state.event.eventId,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetUsers: (eventId) => dispatch(actions.getUsers(eventId)),
        onUpdateUserInformation: (data) => dispatch(actions.updateUserInformation(data)),
        onRefreshUserData: () => dispatch(actions.refreshUserData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserInformation);
