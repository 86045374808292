import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { ReactComponent as ArrowDown } from "../../../../Images/svg/arrow_down.svg";

export default function CustomSelectField({
	field,
	handleChange,
	isEmpty,
	defaultValue = "All (default)",
	optionalStyles,
}) {
	const handleChangeOption = (e) => {
		handleChange(e.target.name, e.target.value);
	};

	const Placeholder = ({ children }) => {
		return <div className="select-placeholder">{children}</div>;
	};

	return (
		<FormControl
			className="setting-input select-validator"
			style={optionalStyles}
		>
			<Select
				onChange={handleChangeOption}
				name={field.name}
				value={field.value ? field.value : ""}
				className="field-container"
				fullWidth
				MenuProps={{
					id: `search-filters-dropdown${isEmpty ? "-empty" : ""}`,
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "left",
					},
					transformOrigin: {
						vertical: "top",
						horizontal: "left",
					},
					getContentAnchorEl: null,
				}}
				displayEmpty
				renderValue={
					field.value !== ""
						? undefined
						: () => <Placeholder>{field.label}</Placeholder>
				}
				IconComponent={() => (
					<ArrowDown className="select-icon MuiSvgIcon-root MuiSelect-icon" />
					// <ArrowDown className="select-icon" />
				)}
			>
				<MenuItem value="">{defaultValue}</MenuItem>
				{field.options.map((option) => {
					if (field.name === "sdg") {
						return (
							<MenuItem key={option.id} value={option.id}>
								<span>{option.title}</span>
							</MenuItem>
						);
					}
					return (
						<MenuItem key={option} value={option}>
							<span>{option}</span>
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
}
