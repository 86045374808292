import React, {PureComponent} from 'react';
import * as actions from '../../store/actions';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {ReactComponent as OpenLinkIcon} from '../../Images/svg/open-link.svg';
import {ReactComponent as EmptyIcon} from '../../Images/svg/no_events.svg';
import {getEventDate, preventDefaultDrag} from '../../Utils/utils';

class AccountEvents extends PureComponent{
    handleKeyDown = (eventSlug, eventId) => (e) => {
        if (e.key === 'Enter') {
            this.redirectToEvent(eventSlug, eventId)();
        }
    };

    redirectToEvent = (eventSlug, eventId) => (e) => {
        if (eventSlug !== this.props.eventSlug) {
            const {user, isLargeScreen} = this.props;
            const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === eventSlug);

            // all the users that are on mobile, are redirected to lobby
            if (isLargeScreen) {
                if (userRolesInEvent.roles.includes('organizer')) {
                    this.props.history.push(`/event/${eventSlug}/dashboard/settings`);
                } else if (userRolesInEvent.roles.includes('exhibitor')) {
                    this.props.history.push(`/event/${eventSlug}/exhibitor-dashboard`);
                } else if (userRolesInEvent.roles.includes('exhibitorRep')) {
                    // this.props.history.push(`/event/${eventSlug}/exhibitor-booth-view`);
                    this.props.history.push(`/event/${eventSlug}/lobby`);
                } else {
                    this.props.history.push(`/event/${eventSlug}/lobby`);
                }
            } else {
                this.props.history.push(`/event/${eventSlug}/lobby`);
            }

            this.props.setEventSlug(eventSlug);
            this.props.setEventId(eventId);
            this.props.onCloseTopNav();
            // we should use this action when we want to do any kind of logic or
            // update / reset data in the redux store
            this.props.userNavigatedToOtherEvent()
        }
    };

    getEventsList = () => {
        const {user, event, languages} = this.props;
        let events = user.eventRoles.slice(0).filter(eventItem => eventItem.event._id !== event._id).sort((x, y) => {
            if (x.event.name < y.event.name) { return -1; }
            if (x.event.name > y.event.name) { return 1; }
            return 0;
        });
        return events?.map((eventRole) => {
            // don't display events where the organizer is "not approved",
            // unless the current user is actually the organizer of the event
            if (
                eventRole.event.owner &&
                eventRole.event.owner.organizerStatus !== 'approved' &&
                !eventRole.roles.includes('organizer')
            ) {
                return null;
            }
            return (
                <div
                    className="table-row"
                    key={eventRole.event._id}
                >
                    <div className="name">
                        {eventRole.event.name}
                    </div>
                    <div className="date">
                        {getEventDate(eventRole.event.eventStartDate, eventRole.event.eventEndDate, languages)}
                    </div>
                    <div
                        tabIndex="0"
                        className="open"
                        onKeyDown={this.handleKeyDown(eventRole.event.slug, eventRole.event._id)}
                        onClick={this.redirectToEvent(eventRole.event.slug, eventRole.event._id)}
                    >
                        <OpenLinkIcon/>
                    </div>
                </div>
            );
        });
    };

    render() {
        const {user, event, translation, defaultTranslation} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} className="scroll-left-container">
                <div className="my-events">
                    <div>
                        <h3>
                            {translation?.myEventsDropdown.myEventsList ||
                            defaultTranslation?.myEventsDropdown.myEventsList}
                        </h3>
                        <p>
                            {translation?.myEventsDropdown.yourCurrentEvent ||
                            defaultTranslation?.myEventsDropdown.yourCurrentEvent}
                            {': ' + event?.title}
                        </p>
                        <div className="events-table">
                            <div className="events-header">
                                <div className="name">
                                    {translation?.myEventsDropdown.eventName ||
                                    defaultTranslation?.myEventsDropdown.eventName}
                                </div>
                                <div className="date">
                                    {translation?.myEventsDropdown.eventDate ||
                                    defaultTranslation?.myEventsDropdown.eventDate}
                                </div>
                                <div className="open">
                                    {translation?.myEventsDropdown.open ||
                                    defaultTranslation?.myEventsDropdown.open}
                                </div>
                            </div>
                            <div className="events-list-wrapper">
                                {user?.eventRoles?.length > 1
                                ?
                                    this.getEventsList()
                                :
                                    <div className="table-row empty-row">
                                        <div>
                                            <EmptyIcon/>
                                            <p>
                                                {translation?.myEventsDropdown.noEvents ||
                                                defaultTranslation?.myEventsDropdown.noEvents}
                                            </p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        eventRoles: state.user.eventRoles,
        event: state.event.data,
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setEventId: (eventId) => dispatch(actions.setEventId(eventId)),
        setEventSlug: (eventSlug) => dispatch(actions.setEventSlug(eventSlug)),
        onCloseTopNav: () => dispatch(actions.topNavClose()),
        userNavigatedToOtherEvent: () => dispatch(actions.userNavigatedToOtherEvent()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountEvents));