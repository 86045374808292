import React, {PureComponent} from 'react';

class LiveStatus extends PureComponent {
    render() {
        const {translation, defaultTranslation} = this.props;
        return (
            <div className="status-button live-button">
                <span>
                    {translation?.auditorium.liveButton ||
                    defaultTranslation?.auditorium.liveButton}
                </span>
            </div>
        );
    }
}

export default LiveStatus;