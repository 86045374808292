import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import colors from "../../CSS/_variables.module.scss";
import { ReactComponent as Arrow } from "../../Images/svg/showfloor-arrow-image.svg";
import { preventDefaultDrag } from "../../Utils/utils";

class AuditoriumNavigation extends React.Component {
	getPreviousAuditoriumIndex = () => {
		const { auditoriums, auditoriumRoomIndex } = this.props;
		let previousAuditoriumIndex =
			(auditoriumRoomIndex - 1 + auditoriums.length) % auditoriums.length;

		while (!auditoriums[previousAuditoriumIndex].visible) {
			previousAuditoriumIndex =
				(previousAuditoriumIndex - 1 + auditoriums.length) % auditoriums.length;
			if (previousAuditoriumIndex === auditoriumRoomIndex) {
				break; // Prevent infinite loop if no visible auditorium is found
			}
		}

		return previousAuditoriumIndex;
	};

	getNextAuditoriumIndex = () => {
		const { auditoriums, auditoriumRoomIndex } = this.props;
		let nextAuditoriumIndex = (auditoriumRoomIndex + 1) % auditoriums.length;

		while (!auditoriums[nextAuditoriumIndex].visible) {
			nextAuditoriumIndex = (nextAuditoriumIndex + 1) % auditoriums.length;
			if (nextAuditoriumIndex === auditoriumRoomIndex) {
				break; // Prevent infinite loop if no visible auditorium is found
			}
		}

		return nextAuditoriumIndex;
	};

	render() {
		const { timeSlot, setActiveAuditoriumIndex, auditoriums, videoPlayer } =
			this.props;
		return (
			<div
				className={
					"auditoriums-navigation " +
					(timeSlot?.video ? "has-booth-wall" : "no-timeslot") +
					(videoPlayer ? " active-on-player" : "")
				}
			>
				<div
					onDragStart={preventDefaultDrag}
					className="button previous"
					onClick={setActiveAuditoriumIndex(this.getPreviousAuditoriumIndex())}
				>
					<div onDragStart={preventDefaultDrag} className="button-container">
						<div onDragStart={preventDefaultDrag} className="details">
							<Arrow stroke={colors.primary} />
						</div>
						<div onDragStart={preventDefaultDrag} className="auditorium-name">
							<p>{auditoriums[this.getPreviousAuditoriumIndex()].name}</p>
						</div>
					</div>
				</div>
				<div
					onDragStart={preventDefaultDrag}
					className="button next"
					onClick={setActiveAuditoriumIndex(this.getNextAuditoriumIndex())}
				>
					<div onDragStart={preventDefaultDrag} className="button-container">
						<div onDragStart={preventDefaultDrag} className="details">
							<Arrow stroke={colors.primary} />
						</div>
						<div onDragStart={preventDefaultDrag} className="auditorium-name">
							<p>{auditoriums[this.getNextAuditoriumIndex()].name}</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		languages: state.languages,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(AuditoriumNavigation),
);
