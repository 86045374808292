import React from 'react';
import {AutoSizer, List, CellMeasurer, CellMeasurerCache} from 'react-virtualized';
import {Scrollbars} from 'react-custom-scrollbars';
import {preventDefaultDrag} from '../../Utils/utils';
import 'react-virtualized/styles.css';
import SingleOnDemandVideo from './SingleOnDemandVideo';

class OnDemandList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.cache = new CellMeasurerCache({
            // Define a CellMeasurerCache --> Put the height and width you think are the best
            defaultHeight: 100,
            fixedWidth: true,
        });
    }

    componentDidMount = () => {
        this.list.Grid._scrollingContainer = this.scroll.view;
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.videos !== this.props.videos) {
            this.resizeList();
        }
    };

    resizeList = () => {
        this.cache.clearAll();
        if (this.list) {
            this.list.recomputeRowHeights();
            this.list.scrollToPosition();
        }
    };

    handleScroll = (e) => {
        this.list.Grid._onScroll(e);
    };

    renderThumb = ({style, ...props}) => {
        const thumbStyle = {
            backgroundColor: `#777081`,
            left: 0,
            border: `1px solid #FFFFFF`,
            boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.25)`,
            borderRadius: `8px`
        };
        return (
            <div
                onDragStart={preventDefaultDrag}
                className="grey-scroll"
                style={{...style, ...thumbStyle}}
                {...props}
            />
        );
    };

    renderView = ({style, ...props}) => {
        let viewStyle = {};
        if (this.props.isRtlLanguage) {
            viewStyle = {...viewStyle, marginLeft: style.marginRight, marginRight: 0};
        }
        return <div onDragStart={preventDefaultDrag} className="box" style={{...style, ...viewStyle}} {...props} />;
    };

    rowRenderer = ({
       key, // Unique key within array of rows
       index, // Index of row within collection
       parent,
       style, // Style object to be applied to row (to position it)
    }) => {
        const video = this.props.videos.videos[index];
        return (
            <CellMeasurer cache={this.cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
                <div
                    className="Row"
                    key={key}
                    style={{
                        ...style,
                    }}
                >
                    <SingleOnDemandVideo
                        key={key}
                        timeslot={video}
                        auditoriumDetails={this.props.videos.auditoriumDetails}
                        getProgramDayString={this.props.getProgramDayString}
                    />
                </div>
            </CellMeasurer>
        );
    };

    render() {
        const {videos} = this.props;
        return (
            <AutoSizer>
                {({height, width}) => (
                    <Scrollbars
                        ref={(node) => (this.scroll = node)}
                        onScroll={this.handleScroll}
                        style={{height, width}}
                        className="scrollbar"
                        renderThumbVertical={this.renderThumb}
                        renderView={this.renderView}
                    >
                        <List
                            tabIndex={-1}
                            width={width}
                            height={height}
                            rowGetter={({index}) => videos.videos[index]}
                            rowCount={videos.videos.length}
                            rowHeight={this.cache.rowHeight}
                            rowRenderer={this.rowRenderer}
                            headerHeight={20}
                            style={{overflowX: 'visible', overflowY: 'visible'}}
                            ref={(node) => (this.list = node)}
                        />
                    </Scrollbars>
                )}
            </AutoSizer>
        );
    }
}
export default OnDemandList;
