import React, {Component, createRef} from 'react';
import './SessionSelfPreview.scss';
import {connect} from "react-redux";
import UserVideoStream from "../../LiveSession/UserVideoStream";
import MenuItem from "@material-ui/core/MenuItem";
import Select from '@material-ui/core/Select';
import * as actions from "../../../store/actions";
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import {ReactComponent as MenuDownIcon} from '../../../Images/icons/arrow_down.svg';
import MicVolume from "./MicVolume";


class SessionSelfPreview extends Component {

    micVolumeRef = createRef();

    state = {
        mediaDevices: [],
        audioInputDevices: [],
        videoInputDevices: [],
        audioContext: null,
        selfStream: null
    }

    componentDidMount() {
        const {liveSessionUser} = this.props;

        this.askForMediaPermission();

        if(liveSessionUser.audio){
            this.startAudioProcesing();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // user started audio
        if(!prevProps.liveSessionUser.audio && this.props.liveSessionUser.audio){
            this.startAudioProcesing();
        }

        // user muted himself
        if(prevProps.liveSessionUser.audio && !this.props.liveSessionUser.audio){
            this.stopAudioProcesing();
        }

        // user stopped his stream
        if(prevProps.userMediaStreamCapture && !this.props.userMediaStreamCapture){
            this.stopAudioProcesing();
        }
    }

    componentWillUnmount() {
        // clear audioContext
        this.stopAudioProcesing();
        if(this.state.selfStream){
            this.state.selfStream.getTracks().forEach(track=>track.stop())
        }
    }

    askForMediaPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({audio: true, video: true});
            if(stream){
                this.readMediaDevices();
                this.setState({
                    selfStream: stream
                })
            }
        } catch (e) {
            if(e.message === 'Permission denied'){
                alert(`Blocked by the browser. You can update the media access from the browser settings`)
            }
        }
    }

    readMediaDevices = () => {
        const reactThis = this;

        navigator.mediaDevices.enumerateDevices()
            .then(function(devices) {
                reactThis.setMediaDevices(devices);

            })
            .catch(function(err) {

            });
    }




    setMediaDevices = (devices) => {
        //const {userMediaStreamSelectedAudioDeviceId, userMediaStreamSelectedVideoDeviceId} = this.props;
        const audioInputDevices = devices.filter(device => device.kind === "audioinput");
        const videoInputDevices = devices.filter(device => device.kind === "videoinput");

        this.setState({
            mediaDevices: devices,
            audioInputDevices: audioInputDevices,
            videoInputDevices: videoInputDevices,
        })

        // if(!userMediaStreamSelectedAudioDeviceId){
        //     this.props.setUserMediaStreamAudioDeviceId(audioInputDevices[0]?.deviceId)
        // }
        // if(!userMediaStreamSelectedVideoDeviceId){
        //     this.props.setUserMediaStreamVideoDeviceId(videoInputDevices[0]?.deviceId)
        // }
    }

    handleChangeAudioInput = e => {
        const audioDeviceId = e.target.value;
        this.props.setUserMediaStreamAudioDeviceId(audioDeviceId);
    }

    handleChangeVideoInput = e => {
        const videoDeviceId = e.target.value;
        this.stopSelfCapture();
        this.props.setUserMediaStreamVideoDeviceId(videoDeviceId);
    }

    stopSelfCapture = async () => {
        if(this.props.userMediaStreamCapture){
            const selfSessionCaptureClosed = await this.props.userMediaStreamCapture.close();
            this.props.setUserMediaStreamCapture(null);
        }
        return true
    }

    startAudioProcesing = () => {
        const {userMediaStreamCapture} = this.props;

        const reactThis = this;

        const audioContext = new AudioContext();
        this.setState({
            audioContext: audioContext
        })
        const mediaStreamSource = audioContext.createMediaStreamSource(userMediaStreamCapture?.mediaStream);
        const processor = audioContext.createScriptProcessor(2048, 1, 1);

        mediaStreamSource.connect(audioContext.destination);
        mediaStreamSource.connect(processor);
        processor.connect(audioContext.destination);

        processor.onaudioprocess = function (e) {
            const inputData = e.inputBuffer.getChannelData(0);
            const inputDataLength = inputData.length;
            let total = 0;

            for (let i = 0; i < inputDataLength; i++) {
                total += Math.abs(inputData[i++]);
            }

            const rms = Math.sqrt(total / inputDataLength);
            reactThis.updateMeter(rms * 100);
        }

    }

    updateMeter = (pct) => {
        const meter = document.getElementById('meter');
        if(meter){
            meter.style.width = pct + '%';
        }
    }

    stopAudioProcesing = () => {
        const {audioContext} = this.state;
        if(audioContext){
            audioContext.close().then(()=>{
                // reset the width of the audio context to be 0
                this.updateMeter(0);
                this.setState({
                    audioContext: null
                })
            })
        }
    }

    render() {
        const {liveSessionUser, userMediaStreamSelectedAudioDeviceId, userMediaStreamSelectedVideoDeviceId, translation, defaultTranslation} = this.props;
        const {audioInputDevices, videoInputDevices} = this.state;
        if(!liveSessionUser){
            return null
        }
        return (
            <div className="session-self-preview-container">

                <div className="self-preview">
                    {!liveSessionUser.video && <VideocamOffIcon className={'cam-icon'}/>}
                    <UserVideoStream key={liveSessionUser._id}
                                     activeUser={liveSessionUser}
                    />
                </div>

                <div className="audio input-devices">
                    <span className={'setting-name'}>
                        {translation?.auditorium.microphone || defaultTranslation?.auditorium.microphone}
                    </span>
                    <MicVolume />
                    <Select
                        classes={{select: 'select-input-device'}}
                        IconComponent={MenuDownIcon}
                        onChange={this.handleChangeAudioInput}
                        value={userMediaStreamSelectedAudioDeviceId || audioInputDevices[0]?.deviceId || 'default'}
                        variant={"standard"}
                    >
                        {audioInputDevices.map(audioDevice => (
                            <MenuItem key={audioDevice.deviceId} value={audioDevice.deviceId}>
                                <span>
                                    {audioDevice.label}
                                </span>
                            </MenuItem>
                        ))}
                    </Select>
                </div>


                <div className="video input-devices">
                    <span className={'setting-name'}>
                        {translation?.auditorium.camera || defaultTranslation?.auditorium.camera}
                    </span>
                    <Select
                        classes={{select: 'select-input-device'}}
                        IconComponent={MenuDownIcon}
                        onChange={this.handleChangeVideoInput}
                        value={userMediaStreamSelectedVideoDeviceId || videoInputDevices[0]?.deviceId || 'default'}
                    >
                        {videoInputDevices.map(videoDevice => (
                            <MenuItem key={videoDevice.deviceId} value={videoDevice.deviceId}>
                                <span>
                                    {videoDevice.label}: {videoDevice.deviceId}
                                </span>
                            </MenuItem>
                        ))}

                    </Select>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        liveSession: state.liveSession.liveSession,
        liveSessionUser: state.liveSession.liveSessionUser,
        userMediaStreamCapture: state.userMediaStream.capture,
        userMediaStreamSelectedAudioDeviceId: state.userMediaStream.selectedAudioDeviceId,
        userMediaStreamSelectedVideoDeviceId: state.userMediaStream.selectedVideoDeviceId,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserMediaStreamCapture: (capture) => dispatch(actions.setUserMediaStreamCapture(capture)),
        setUserMediaStreamAudioDeviceId: (deviceId) => dispatch(actions.setUserMediaStreamAudioDeviceId(deviceId)),
        setUserMediaStreamVideoDeviceId: (deviceId) => dispatch(actions.setUserMediaStreamVideoDeviceId(deviceId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionSelfPreview);