import React from 'react';
import {connect} from 'react-redux';
import {
    getTimeslotEndTimestamp,
    getTimeslotStartTimestamp,
    getUtcTimestamp,
    preventDefaultDrag,
} from '../../Utils/utils';
import cloneDeep from 'lodash/cloneDeep';

class AuditoriumSlotTitle extends React.Component {
    timerId = null;
    programEndsTimerId = null;

    state = {
        totalSecondsTillNextProgram: null,
        auditoriumProgramTimeslots: [],
        currentProgramTimeSlotIndex: -1,
        totalSecondsTillCurrentProgramEnds: null,
    };

    componentDidMount() {
        this.setAuditoriumProgram();
    }

    componentWillUnmount() {
        this.clearCountdown();
        this.clearProgramEndsCountdown();
    }

    componentDidUpdate(prevProps) {
        // when the auditorium changes, set the new Program
        if (prevProps.auditoriumRoomIndex !== this.props.auditoriumRoomIndex) {
            this.setState(
                {
                    currentProgramTimeSlotIndex: -1,
                },
                () => {
                    this.setAuditoriumProgram();
                }
            );
        }
    }

    getCurrentAuditorium = () => {
        const {auditoriumRoomIndex, event, building} = this.props;

        let currentAuditorium = null;
        if (event.hasVillage) {
            currentAuditorium = building.auditoriums[auditoriumRoomIndex];
        } else {
            currentAuditorium = event.auditoriums[auditoriumRoomIndex];
        }
        return cloneDeep(currentAuditorium);
    };

    setAuditoriumProgram = () => {
        // this function will run at componentDidMount or when the user navigates through auditoriums
        // create an array with all PROGRAM timeslots of the current auditorium
        // make sure to also add to each timeslot the day when it runs

        const currentAuditorium = this.getCurrentAuditorium();

        let auditoriumProgramTimeslots = [];
        currentAuditorium.displayProgram.forEach((dayProgram) => {
            const currentProgramDay = dayProgram.date;
            dayProgram.program.forEach((timeslot) => {
                timeslot.date = currentProgramDay;
                auditoriumProgramTimeslots.push(timeslot);
            });
        });

        // after we have set up the timeslots for the auditorium, we trigger the loadCurrentTimeslot
        // so that we make sure we change the current timeslot properly
        this.setState(
            {
                auditoriumProgramTimeslots: auditoriumProgramTimeslots,
            },
            () => {
                this.loadCurrentProgramTimeSlot();
            }
        );
    };

    loadCurrentProgramTimeSlot = () => {
        let currentTime = new Date();
        let currentTimeStamp = getUtcTimestamp(currentTime);

        const {auditoriumProgramTimeslots} = this.state;

        // first we check if we have a current running video
        // save the index in the program
        let currentProgramTimeSlotIndex = auditoriumProgramTimeslots.findIndex((timeSlot) => {
            let timeSlotStartTimestamp = getTimeslotStartTimestamp(
                timeSlot.date,
                timeSlot,
                this.props.event.timezoneValue
            );
            let timeSlotEndTimestamp = getTimeslotEndTimestamp(timeSlot.date, timeSlot, this.props.event.timezoneValue);

            if (currentTimeStamp >= timeSlotStartTimestamp && currentTimeStamp < timeSlotEndTimestamp) {
                return timeSlot;
            }
            return null;
        });

        // search for next program timeslot
        // set how many seconds are untill the next slot starts
        // start the timer
        auditoriumProgramTimeslots.some((timeSlot) => {
            let timeSlotStartTimestamp = getTimeslotStartTimestamp(
                timeSlot.date,
                timeSlot,
                this.props.event.timezoneValue
            );

            if (currentTimeStamp < timeSlotStartTimestamp) {
                let nextVideoWillStartInNextSeconds = timeSlotStartTimestamp - currentTimeStamp;
                nextVideoWillStartInNextSeconds = nextVideoWillStartInNextSeconds / 1000;

                this.setState(
                    {
                        totalSecondsTillNextProgram: nextVideoWillStartInNextSeconds,
                    },
                    () => {
                        this.startCountdown();
                    }
                );
                return timeSlot;
            }
            return null;
        });

        // we have to set up a timer for when the program ends
        // so that we can trigger again the loadCurrentProgramTimeSlot
        // to hide the title
        if (currentProgramTimeSlotIndex !== -1) {
            const timeSlot = auditoriumProgramTimeslots[currentProgramTimeSlotIndex];
            let timeSlotEndTimestamp = getTimeslotEndTimestamp(timeSlot.date, timeSlot, this.props.event.timezoneValue);
            let programWillEndInNextSeconds = timeSlotEndTimestamp - currentTimeStamp;

            programWillEndInNextSeconds = Math.floor(programWillEndInNextSeconds / 1000);
            this.setState(
                {
                    totalSecondsTillCurrentProgramEnds: programWillEndInNextSeconds,
                },
                () => {
                    this.startProgramEndsCountdown();
                }
            );
        }

        this.setState({currentProgramTimeSlotIndex: currentProgramTimeSlotIndex});
    };

    startCountdown = () => {
        this.timerId = setInterval(this.countdown, 1000);
    };

    countdown = () => {
        if (this.state.totalSecondsTillNextProgram !== 0) {
            this.setState({
                totalSecondsTillNextProgram: this.state.totalSecondsTillNextProgram - 1,
            });
        } else {
            // if the current program timeslot has ended
            // we clear the countdown
            // reset totalSecondsTillNextProgram
            // run again the loadCurrentProgramTimeSlot to display the new title
            // and set up a new timer for the next program slot
            this.clearCountdown();
            this.setState(
                {
                    totalSecondsTillNextProgram: null,
                },
                () => {
                    this.loadCurrentProgramTimeSlot();
                }
            );
        }
    };

    clearCountdown = () => {
        if (this.timerId) {
            clearInterval(this.timerId);
        }
    };

    startProgramEndsCountdown = () => {
        this.programEndsTimerId = setInterval(this.programEndCountdown, 1000);
    };

    programEndCountdown = () => {
        if (this.state.totalSecondsTillCurrentProgramEnds !== 0) {
            this.setState({
                totalSecondsTillCurrentProgramEnds: this.state.totalSecondsTillCurrentProgramEnds - 1,
            });
        } else {
            // if the current program timeslot has ended
            // we clear the countdown
            // reset totalSecondsTillNextProgram
            // run again the loadCurrentProgramTimeSlot to display the new title
            // and set up a new timer for the next program slot
            this.clearProgramEndsCountdown();
            this.setState(
                {
                    totalSecondsTillCurrentProgramEnds: null,
                },
                () => {
                    this.loadCurrentProgramTimeSlot();
                }
            );
        }
    };

    clearProgramEndsCountdown = () => {
        if (this.programEndsTimerId) {
            clearInterval(this.programEndsTimerId);
        }
    };

    render() {
        const {event, auditoriumRoomIndex, translation, defaultTranslation} = this.props;

        const currentAuditorium = event.auditoriums[auditoriumRoomIndex];
        const {auditoriumProgramTimeslots, currentProgramTimeSlotIndex} = this.state;

        return (
            <div
                onDragStart={preventDefaultDrag}
                className={`slot-title ${auditoriumProgramTimeslots[currentProgramTimeSlotIndex] ? '' : 'd-none'}`}
            >
                {currentProgramTimeSlotIndex !== -1 && (
                    <>
                        <div>
                            <p onDragStart={preventDefaultDrag} className={'title '}>
                                <span onDragStart={preventDefaultDrag} draggable="false" className="timeslot-title">
                                    {auditoriumProgramTimeslots[currentProgramTimeSlotIndex]?.speakers ? (
                                        <span>
                                            {auditoriumProgramTimeslots[currentProgramTimeSlotIndex]?.speakers}{' '}
                                            <span>
                                                {translation?.programDropdown.with ||
                                                    defaultTranslation?.programDropdown.with}
                                            </span>{' '}
                                        </span>
                                    ) : null}
                                    {auditoriumProgramTimeslots[currentProgramTimeSlotIndex]?.title}
                                </span>
                            </p>
                        </div>
                        <p onDragStart={preventDefaultDrag} className="subtitle">
                            {currentAuditorium.name}: {auditoriumProgramTimeslots[currentProgramTimeSlotIndex]?.start} -{' '}
                            {auditoriumProgramTimeslots[currentProgramTimeSlotIndex]?.end}
                            {event.showTimezone &&
                                ' (GMT' + (event.timezoneValue >= 0 ? '+' : '') + event.timezoneValue + ')'}
                        </p>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        building: state.building.data,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps)(AuditoriumSlotTitle);
