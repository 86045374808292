import axios from '../axios-instance';
import * as actionTypes from './actionTypes';

export const getAudiences = (eventId) => {
    return (dispatch) => {
        dispatch(getAudiencesStart());

        return axios({method: 'get', url: `/event/${eventId}/audiences`})
            .then((res) => {
                let audiences = res.data.audiences;
                dispatch(getAudiencesSuccess(audiences));
            })
            .catch((err) => {
                dispatch(getAudiencesFail(err));
            });
    };
};

export const getAudiencesStart = () => {
    return {
        type: actionTypes.GET_AUDIENCES_START,
    };
};
export const getAudiencesSuccess = (users) => {
    return {
        type: actionTypes.GET_AUDIENCES_SUCCESS,
        payload: users,
    };
};
export const getAudiencesFail = () => {
    return {
        type: actionTypes.GET_AUDIENCES_FAIL,
    };
};

export const addAudience = (eventId, data) => {
    return (dispatch) => {
        dispatch(addAudienceStart());

        return axios.post(`/event/v2/${eventId}/audience`)
            .then((res) => {
                let audience = res.data.data[0];
                dispatch(addAudienceSuccess(audience));
                return audience;
            })
            .catch((err) => {
                dispatch(addAudienceFail(err));
            });
    };
};

export const addAudienceStart = () => {
    return {
        type: actionTypes.ADD_AUDIENCE_START,
    };
};
export const addAudienceSuccess = (audience) => {
    return {
        type: actionTypes.ADD_AUDIENCE_SUCCESS,
        payload: audience,
    };
};
export const addAudienceFail = () => {
    return {
        type: actionTypes.ADD_AUDIENCE_FAIL,
    };
};

export const updateAudience = (eventId, audienceId, data) => {
    return (dispatch) => {
        dispatch(updateAudienceStart());

        return axios({method: 'put', url: `/event/${eventId}/audience/${audienceId}`, data: data})
            .then((res) => {
                let audience = res.data.audience;
                dispatch(updateAudienceSuccess(audience));
            })
            .catch((err) => {
                dispatch(updateAudienceFail(err));
            });
    };
};

export const updateAudienceStart = () => {
    return {
        type: actionTypes.UPDATE_AUDIENCE_START,
    };
};
export const updateAudienceSuccess = (audience) => {
    return {
        type: actionTypes.UPDATE_AUDIENCE_SUCCESS,
        payload: audience,
    };
};
export const updateAudienceFail = () => {
    return {
        type: actionTypes.UPDATE_AUDIENCE_FAIL,
    };
};

export const addUsersToAudience = (eventId, audienceId, data) => {
    return (dispatch) => {
        return axios.put(`/event/${eventId}/audience/${audienceId}/add-to-audience`, data)
            .then((res) => {
            })
            .catch((err) => {
                throw err;
            });
    };
};

export const removeUsersFromAudience = (eventId, audienceId, data) => {
    return (dispatch) => {
        return axios.put(`/event/${eventId}/audience/${audienceId}/remove-from-audience`, data)
            .then((res) => {
            })
            .catch((err) => {
                throw err;
            });
    };
};

export const deleteAudience = (eventId, audienceId) => {
    return (dispatch) => {
        dispatch(deleteAudienceStart());

        return axios({method: 'delete', url: `/event/${eventId}/audience/${audienceId}`})
            .then(() => {
                dispatch(deleteAudienceSuccess(audienceId));
            })
            .catch((err) => {
                dispatch(deleteAudienceFail(err));
            });
    };
};

export const deleteAudienceStart = () => {
    return {
        type: actionTypes.DELETE_AUDIENCE_START,
    };
};
export const deleteAudienceSuccess = (audienceId) => {
    return {
        type: actionTypes.DELETE_AUDIENCE_SUCCESS,
        payload: audienceId,
    };
};
export const deleteAudienceFail = () => {
    return {
        type: actionTypes.DELETE_AUDIENCE_FAIL,
    };
};

export const getCampaigns = (eventId) => {
    return (dispatch) => {
        dispatch(getCampaignsStart());

        return axios({method: 'get', url: `/event/${eventId}/campaigns`})
            .then((res) => {
                let campaigns = res.data;
                dispatch(getCampaignsSuccess(campaigns));
            })
            .catch((err) => {
                dispatch(getCampaignsFail(err));
            });
    };
};

export const getCampaignsStart = () => {
    return {
        type: actionTypes.GET_CAMPAIGNS_START,
    };
};
export const getCampaignsSuccess = (users) => {
    return {
        type: actionTypes.GET_CAMPAIGNS_SUCCESS,
        payload: users,
    };
};
export const getCampaignsFail = () => {
    return {
        type: actionTypes.GET_CAMPAIGNS_FAIL,
    };
};

export const addCampaign = (eventId, data) => {
    return (dispatch) => {
        dispatch(addCampaignStart());

        return axios({method: 'post', url: `/event/${eventId}/campaign`, data: data})
            .then((res) => {
                let campaign = res.data.campaign;
                dispatch(addCampaignSuccess(campaign));
            })
            .catch((err) => {
                dispatch(addCampaignFail(err));
            });
    };
};

export const addCampaignStart = () => {
    return {
        type: actionTypes.ADD_CAMPAIGN_START,
    };
};
export const addCampaignSuccess = (campaign) => {
    return {
        type: actionTypes.ADD_CAMPAIGN_SUCCESS,
        payload: campaign,
    };
};
export const addCampaignFail = () => {
    return {
        type: actionTypes.ADD_CAMPAIGN_FAIL,
    };
};

export const duplicateCampaign = (eventId, campaignId) => {
    return (dispatch) => {
        dispatch(addCampaignStart());

        return axios({method: 'post', url: `/event/${eventId}/campaign/${campaignId}/duplicate`})
            .then((res) => {
                let campaign = res.data.campaign;
                dispatch(addCampaignSuccess(campaign));
            })
            .catch((err) => {
                dispatch(addCampaignFail(err));
            });
    };
};

export const updateCampaign = (eventId, campaignId, data) => {
    return (dispatch) => {
        dispatch(updateCampaignStart());

        return axios({method: 'put', url: `/event/${eventId}/campaign/${campaignId}`, data: data})
            .then((res) => {
                let campaign = res.data.campaign;
                dispatch(updateCampaignSuccess(campaign));
            })
            .catch((err) => {
                dispatch(updateCampaignFail(err));
            });
    };
};

export const updateCampaignStart = () => {
    return {
        type: actionTypes.UPDATE_CAMPAIGN_START,
    };
};
export const updateCampaignSuccess = (campaign) => {
    return {
        type: actionTypes.UPDATE_CAMPAIGN_SUCCESS,
        payload: campaign,
    };
};
export const updateCampaignFail = () => {
    return {
        type: actionTypes.UPDATE_CAMPAIGN_FAIL,
    };
};

export const deleteCampaign = (eventId, campaignId) => {
    return (dispatch) => {
        dispatch(deleteCampaignStart());

        return axios({method: 'delete', url: `/event/${eventId}/campaign/${campaignId}`})
            .then(() => {
                dispatch(deleteCampaignSuccess(campaignId));
            })
            .catch((err) => {
                dispatch(deleteCampaignFail(err));
            });
    };
};

export const deleteCampaignStart = () => {
    return {
        type: actionTypes.DELETE_CAMPAIGN_START,
    };
};
export const deleteCampaignSuccess = (campaignId) => {
    return {
        type: actionTypes.DELETE_CAMPAIGN_SUCCESS,
        payload: campaignId,
    };
};
export const deleteCampaignFail = () => {
    return {
        type: actionTypes.DELETE_CAMPAIGN_FAIL,
    };
};

export const addCampaignToEmailQueue = (eventId, campaignId) => {
    return (dispatch) => {
        dispatch(addCampaignToEmailQueueStart(campaignId));

        return axios({method: 'post', url: `/event/${eventId}/campaign/${campaignId}/add-to-queue`})
            .then(() => {})
            .catch((err) => {});
    };
};

export const addCampaignToEmailQueueStart = (campaignId) => {
    return {
        type: actionTypes.ADD_CAMPAIGN_TO_QUEUE_START,
        payload: campaignId,
    };
};
