import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import ReactExport from 'react-export-excel';
import axios from '../../../store/axios-instance';
import Spinner from '../../../SmallLayoutComponents/Spinner';
import * as actions from '../../../store/actions';
import MasterAdminNavigation from '../MasterAdminNavigation';
import AdminAccordionNavigation from '../AdminAccordionNavigation';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class AdminStatistics extends React.Component {
    state = {
        adminStatisticsOverall: [],
        adminStatisticsArchived: [],
        loadingAdminStatistics: false,
        errorAdminStatistics: '',
        tabsList: [
            {name: 'organizersList', label: 'Organizers list', link: '/dashboard/organizers'},
            {name: 'statistics', label: 'Statistics', link: '/dashboard/organizers/statistics'},
            {name: 'allEvents', label: 'All events', link: '/dashboard/organizers/all-events'},
            {name: 'eventsToday', label: 'Events today', link: '/dashboard/organizers/events-today'},
        ],
        selectedTab: 'statistics',
    };

    componentDidMount() {
        this.props.onSetAdminMenu();
        this.getAdminStatistics();
    }

    getAdminStatistics = () => {
        this.setState({
            loadingAdminStatistics: true,
        });
        axios({method: 'get', url: `/master-admin/platform-statistics`})
            .then((response) => {
                let report = response.data.report;
                let adminStatisticsOverallValues = [
                    {name: 'Number of events', value: report.events},
                    {name: 'Number of published events', value: report.publishedEvents},
                    {name: 'Number of Events by Approved Organizers', value: report.eventsApprovedOrg},
                    {name: 'Number of finished events', value: report.finishedEvents},
                    {name: 'Number of upcoming events', value: report.upcomingEvents},
                    {name: 'Number of archived events', value: report.archivedEvents},
                    {name: 'Event Days', value: report.eventDays},
                    {name: 'Number of all Organizers', value: (report.organizersCount + report.disabledOrganizersCount)},
                    {name: 'Number of approved Organizers', value: report.organizersCount},
                    {name: 'Number of suspended Organizers', value: report.disabledOrganizersCount},
                    {name: 'Number of Users', value: report.usersCount},
                    {name: 'Number of Participants', value: report.participantsCount},
                    {name: 'Number of Speakers', value: report.speakers},
                    {name: 'Number of Exhibitors', value: report.exhibitorsCount},
                    {name: 'Number of Exhibitor Representatives', value: report.exhibitorsRepresentatives},
                    {name: 'Number of Poster/Study', value: report.postersCount},
                    {name: 'Number of Poster/Study Representatives', value: report.postersRepresentatives},
                    {name: 'Event Representatives', value: report.eventRepresentatives},
                    {name: 'Event Representative Chats', value: report.eventRepresentativeChats},
                    {name: 'Number of Auditoriums', value: report.auditoriums},
                    {name: 'Number of prerecorded Auditorium Timeslots', value: report.prerecordedPresentations},
                    {name: 'Number of live Auditorium Timeslots', value: report.livePresentations},
                    {name: 'Number of video Resources (links and documents)', value: report.videoResources},
                    {name: 'Number of listed Exhibitor Booths', value: report.exhibitorsListed},
                    {name: 'Number of Exhibitor Booth visitors (before 21.04.2021)', value: report.exhibitorsBoothVisitors},
                    {name: 'Number of Exhibitor Document views (before 21.04.2021)', value: report.exhibitorsDocumentClicks},
                    {name: 'Number of Exhibitor Representative chats', value: report.exhibitorsRepresentativesChats},
                    {name: 'Number of Exhibitor Video views (before 21.04.2021)', value: report.exhibitorsVideoViews},
                    {name: 'Number of Exhibitor Link clicks (before 21.04.2021)', value: report.exhibitorsLinkClicks},
                    {name: 'Enhanced Exhibitor Booth Visitors', value: report.exhibitorsBoothVisitorsEnhanced},
                    {name: 'Enhanced Exhibitor Document Clicks', value: report.exhibitorsDocumentClicksEnhanced},
                    {name: 'Enhanced Exhibitor Link Clicks', value: report.exhibitorsLinkClicksEnhanced},
                    {name: 'Enhanced Exhibitor Video Views', value: report.exhibitorsVideoViewsEnhanced},
                    {name: 'Number of listed Poster/Study Booths', value: report.postersListed},
                    {name: 'Number of Poster/Study Booth visitors (before 21.04.2021)', value: report.postersBoothVisitors},
                    {name: 'Number of Poster/Study Representative chats', value: report.postersRepresentativesChats},
                    {name: 'Number of Poster/Study Document views (before 21.04.2021)', value: report.postersDocumentClicks},
                    {name: 'Number of Poster/Study Video views (before 21.04.2021)', value: report.postersVideoViews},
                    {name: 'Number of Poster/Study Link clicks (before 21.04.2021)', value: report.postersLinkClicks},
                    {name: 'Enhanced Poster Booth Visitors', value: report.postersBoothVisitorsEnhanced},
                    {name: 'Enhanced Poster Document Clicks', value: report.postersDocumentClicksEnhanced},
                    {name: 'Enhanced Poster Link Clicks', value: report.postersLinkClicksEnhanced},
                    {name: 'Enhanced Poster Video Views', value: report.postersVideoViewsEnhanced},
                    {name: 'Number of Live Wall posts', value: report.liveWalls.posts},
                    {name: 'Number of Live Wall pinned posts', value: report.liveWalls.pinnedPosts},
                    {name: 'Number of Live Wall comments', value: report.liveWalls.comments},
                    {name: 'Number of Live Wall likes', value: report.liveWalls.likes},
                    {name: 'Number of Booth Wall posts', value: report.boothWalls.posts},
                    {name: 'Number of Booth Wall pinned posts', value: report.boothWalls.pinnedPosts},
                    {name: 'Number of Booth Wall comments', value: report.boothWalls.comments},
                    {name: 'Number of Booth Wall likes', value: report.boothWalls.likes},
                    {name: 'Number of Video Wall posts', value: report.videoWalls.posts},
                    {name: 'Number of Video Wall pinned posts', value: report.videoWalls.pinnedPosts},
                    {name: 'Number of Video Wall comments', value: report.videoWalls.comments},
                    {name: 'Number of Video Wall likes', value: report.videoWalls.likes},
                    {name: 'Number of Email campaigns created', value: report.emailCampaigns},
                    {name: 'Number of Email campaigns sent', value: report.emailCampaignsSent},
                    {name: 'Number of Email campaign recipients', value: report.emailRecipients},
                    {name: 'Number of Custom email audiences', value: report.customAudiences},
                    {name: 'Number of custom Lobby renders', value: report.customLobbyUploads},
                    {name: 'Number of custom Auditorium renders', value: report.customAuditoriumUploads},
                    {
                        name: 'Number of custom Booth renders',
                        value: report.exhibitorsCusomtBoothUploads + report.postersCusomtBoothUploads,
                    },
                    {name: 'Number of 1 to 1 chats', value: report.chats},
                    {name: 'Number of messages in 1 to 1 chats', value: report.messages},
                    {name: 'Number of group chats', value: report.groupChats},
                    {name: 'Number of messages in group chats', value: report.groupChatMessages},
                    {name: 'Number of Contact requests sent', value: report.contactReqSent},
                    {name: 'Number of Contact requests accepted', value: report.contactReqAccepted},
                    {name: 'Number of Contact requests rejected', value: report.contactReqRejected},
                    {name: 'Number of Contact requests pending', value: report.contactReqPending},
                    {name: 'Number of Completed Meetings', value: report.meetingsCompleted},
                    {name: 'Number of Upcoming Meetings', value: report.meetingsUpcomming},
                    {name: 'Number of Pending Meetings', value: report.meetingsPending},
                    {name: 'Number of Canceled Meetings', value: report.meetingsCanceled},
                ];

                let adminStatisticsArchivedValues = [
                    {name: 'Archived Events', value: report.archivedEvents},
                    {name: 'Archived Events Days', value: report.archivedEventsDays},
                    {name: 'Archived Events Speakers', value: report.archivedEventsSpeakers},
                    {name: 'Archived Events Videos', value: report.archivedEventsVideos},
                    {name: 'Exhibitors Count', value: report.archivedUsageStatistics.exhibitorsCount},
                    {name: 'Participants Count', value: report.archivedUsageStatistics.participantsCount},
                    {name: 'Representatives Chats Count', value: report.archivedUsageStatistics.repChatsCount},
                    {name: 'Total Booth Visitors', value: report.archivedUsageStatistics.totalBoothVisitors},
                    {name: 'Total Document Views', value: report.archivedUsageStatistics.totalDocumentViews},
                    {name: 'Total Link Clicks', value: report.archivedUsageStatistics.totalLinkClicks},
                    {name: 'Total Video Views', value: report.archivedUsageStatistics.totalVideoViews},
                    {
                        name: 'Booth Walls Top Post Comments Count',
                        value: report.archivedWallsStatistics.boothWallsTopPostCommentsCount,
                    },
                    {
                        name: 'Booth Walls Top Post Likes Count',
                        value: report.archivedWallsStatistics.boothWallsTopPostLikesCount,
                    },
                    {
                        name: 'Booth Walls Total Comments Count',
                        value: report.archivedWallsStatistics.boothWallsTotalCommentsCount,
                    },
                    {
                        name: 'Booth Walls Total Likes Count',
                        value: report.archivedWallsStatistics.boothWallsTotalLikesCount,
                    },
                    {
                        name: 'Booth Walls Total Posts Count',
                        value: report.archivedWallsStatistics.boothWallsTotalPostsCount,
                    },
                    {
                        name: 'Live Wall Top Post Comments Count',
                        value: report.archivedWallsStatistics.liveWallTopPostCommentsCount,
                    },
                    {
                        name: 'Live Wall Top Post Likes Count',
                        value: report.archivedWallsStatistics.liveWallTopPostLikesCount,
                    },
                    {
                        name: 'Live Wall Total Comments Count',
                        value: report.archivedWallsStatistics.liveWallTotalCommentsCount,
                    },
                    {
                        name: 'Live Wall Total Likes Count',
                        value: report.archivedWallsStatistics.liveWallTotalLikesCount,
                    },
                    {
                        name: 'Live Wall Total Posts Count',
                        value: report.archivedWallsStatistics.liveWallTotalPostsCount,
                    },
                    {
                        name: 'Video Walls Top Post Comments Count',
                        value: report.archivedWallsStatistics.videoWallsTopPostCommentsCount,
                    },
                    {
                        name: 'Video Walls Top Post Likes Count',
                        value: report.archivedWallsStatistics.videoWallsTopPostLikesCount,
                    },
                    {
                        name: 'Video Walls Total Comments Count',
                        value: report.archivedWallsStatistics.videoWallsTotalCommentsCount,
                    },
                    {
                        name: 'Video Walls Total Likes Count',
                        value: report.archivedWallsStatistics.videoWallsTotalLikesCount,
                    },
                    {
                        name: 'Video Walls Total Posts Count',
                        value: report.archivedWallsStatistics.videoWallsTotalPostsCount,
                    },
                ];

                this.setState({
                    loadingAdminStatistics: false,
                    errorAdminStatistics: '',
                    adminStatisticsOverall: adminStatisticsOverallValues,
                    adminStatisticsArchived: adminStatisticsArchivedValues,
                });
            })
            .catch((error) => {
                this.setState({loadingAdminStatistics: false, errorAdminStatistics: 'Unable to get statistics'});
            });
    };

    refreshAdminStatistics = () => {
        this.getAdminStatistics();
    };

    displayAdminStatistics = (statistics) => {
        return statistics?.map((statistic, statisticIndex) => {
            return (
                <li key={'adminStatistic' + statisticIndex}>
                    <span onDragStart={(e) => e.preventDefault()} draggable="false" className="title">
                        {statistic.name}
                    </span>
                    <span onDragStart={(e) => e.preventDefault()} draggable="false" className="counter">
                        {statistic.value}
                    </span>
                </li>
            );
        });
    };

    render() {
        const {
            tabsList,
            selectedTab,
            adminStatisticsOverall,
            adminStatisticsArchived,
            loadingAdminStatistics,
        } = this.state;

        return (
            <div onDragStart={(e) => e.preventDefault()} className="superadmin-dashboard">
                <MasterAdminNavigation />
                <section className="top-section">
                    <div onDragStart={(e) => e.preventDefault()} className="admin-dashboard">
                        <div className="admin-sidebar">
                            <AdminAccordionNavigation tabsList={tabsList} selectedTab={selectedTab} />
                        </div>
                        <div className="admin-content-container">
                            <div
                                className={`admin-statistics-container ${
                                    loadingAdminStatistics ? 'loading-section' : ''
                                }`}
                            >
                                {loadingAdminStatistics && <Spinner />}
                                <div className="admin-download-header">
                                    <h1>Overall</h1>
                                    <div onDragStart={(e) => e.preventDefault()} className="d-flex">
                                        <ExcelFile
                                            filename={`${process.env.REACT_APP_PLATFORM_NAME}-Overall-Statistics`}
                                            element={
                                                <div
                                                    onDragStart={(e) => e.preventDefault()}
                                                    className="add-button-container"
                                                >
                                                    <GetAppIcon />
                                                    <span>DOWNLOAD</span>
                                                </div>
                                            }
                                        >
                                            <ExcelSheet data={adminStatisticsOverall} name="Overall Statistics">
                                                <ExcelColumn label="Statistic name" value="name" />
                                                <ExcelColumn label="Value" value="value" />
                                            </ExcelSheet>
                                            <ExcelSheet data={adminStatisticsArchived} name="Archived Events">
                                                <ExcelColumn label="Statistic name" value="name" />
                                                <ExcelColumn label="Value" value="value" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                        <div
                                            onDragStart={(e) => e.preventDefault()}
                                            className="add-button-container"
                                            onClick={this.refreshAdminStatistics}
                                        >
                                            <RefreshIcon />
                                            <span>REFRESH</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-statistics">
                                    <ul>
                                        <li className="header-statistics">
                                            <span
                                                onDragStart={(e) => e.preventDefault()}
                                                draggable="false"
                                                className="title"
                                            >
                                                Data type
                                            </span>
                                            <span
                                                onDragStart={(e) => e.preventDefault()}
                                                draggable="false"
                                                className="counter"
                                            >
                                                # OF USERS
                                            </span>
                                        </li>
                                        {this.displayAdminStatistics(adminStatisticsOverall)}
                                    </ul>
                                </div>
                                <h2>Archived events</h2>
                                <div className="table-statistics">
                                    <ul>
                                        <li className="header-statistics">
                                            <span
                                                onDragStart={(e) => e.preventDefault()}
                                                draggable="false"
                                                className="title"
                                            >
                                                Data type
                                            </span>
                                            <span
                                                onDragStart={(e) => e.preventDefault()}
                                                draggable="false"
                                                className="counter"
                                            >
                                                # OF USERS
                                            </span>
                                        </li>
                                        {this.displayAdminStatistics(adminStatisticsArchived)}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSetAdminMenu: () => dispatch(actions.setAdminMenu()),
    };
};

export default withRouter(connect(null, mapDispatchToProps)(AdminStatistics));
