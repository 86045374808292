export const getLobbyBackgroundUploaded = (brandingData, option) => {
    let EventLobbyImage = brandingData.filesUrl;
    let heightResolution = window.screen.height * window.devicePixelRatio;
    if (option === 'v2') {
        // International
        switch (true) {
            case heightResolution < 769:
                EventLobbyImage = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/international_lobby_768.jpg`;
                break;
            case heightResolution < 901:
                EventLobbyImage = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/international_lobby_900.jpg`;
                break;
            case heightResolution < 1081:
                EventLobbyImage = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/international_lobby_1080.jpg`;
                break;
            case heightResolution < 1201:
                EventLobbyImage = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/international_lobby_1200.jpg`;
                break;
            case heightResolution < 1441:
                EventLobbyImage = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/international_lobby_1440.jpg`;
                break;
            case heightResolution < 2161:
                EventLobbyImage = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/international_lobby_2160.jpg`;
                break;
            default:
                EventLobbyImage = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/international_lobby.jpg`;
        }
    } else if (option === 'v3') {
        // Academic
        switch (true) {
            case heightResolution < 769:
                EventLobbyImage = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/academic_lobby_768.jpg`;
                break;
            case heightResolution < 901:
                EventLobbyImage = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/academic_lobby_900.jpg`;
                break;
            case heightResolution < 1081:
                EventLobbyImage = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/academic_lobby_1080.jpg`;
                break;
            case heightResolution < 1201:
                EventLobbyImage = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/academic_lobby_1200.jpg`;
                break;
            case heightResolution < 1441:
                EventLobbyImage = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/academic_lobby_1440.jpg`;
                break;
            case heightResolution < 2161:
                EventLobbyImage = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/academic_lobby_2160.jpg`;
                break;
            default:
                EventLobbyImage = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/academic_lobby.jpg`;
        }
    } else if (option === 'v4') {
        // Green
        switch (true) {
            case heightResolution < 769:
                EventLobbyImage = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/green_lobby_768.jpg`;
                break;
            case heightResolution < 901:
                EventLobbyImage = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/green_lobby_900.jpg`;
                break;
            case heightResolution < 1081:
                EventLobbyImage = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/green_lobby_1080.jpg`;
                break;
            case heightResolution < 1201:
                EventLobbyImage = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/green_lobby_1200.jpg`;
                break;
            case heightResolution < 1441:
                EventLobbyImage = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/green_lobby_1440.jpg`;
                break;
            case heightResolution < 2161:
                EventLobbyImage = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/green_lobby_2160.jpg`;
                break;
            default:
                EventLobbyImage = `${process.env.REACT_APP_RENDERS_FOLDER}lobby/green_lobby.jpg`;
        }
    } else {
        // Custom uploaded
        switch (true) {
            case heightResolution < 769:
                EventLobbyImage += brandingData.lobbyRender.resize768;
                break;
            case heightResolution < 901:
                EventLobbyImage += brandingData.lobbyRender.resize900;
                break;
            case heightResolution < 1081:
                EventLobbyImage += brandingData.lobbyRender.resize1080;
                break;
            case heightResolution < 1201:
                EventLobbyImage += brandingData.lobbyRender.resize1200;
                break;
            case heightResolution < 1441:
                EventLobbyImage += brandingData.lobbyRender.resize1440;
                break;
            case heightResolution < 2161:
                EventLobbyImage += brandingData.lobbyRender.resize2160;
                break;
            default:
                EventLobbyImage += brandingData.lobbyRender.original;
        }
    }

    return EventLobbyImage;
};

export const getVillageBackgroundUploaded = (brandingData) => {
    let EventVillageImage = brandingData.filesUrl;
    let heightResolution = window.screen.height * window.devicePixelRatio;
    switch (true) {
        case heightResolution < 769:
            EventVillageImage += brandingData.villageRender.resize768;
            break;
        case heightResolution < 901:
            EventVillageImage += brandingData.villageRender.resize900;
            break;
        case heightResolution < 1081:
            EventVillageImage += brandingData.villageRender.resize1080;
            break;
        case heightResolution < 1201:
            EventVillageImage += brandingData.villageRender.resize1200;
            break;
        case heightResolution < 1441:
            EventVillageImage += brandingData.villageRender.resize1440;
            break;
        case heightResolution < 2161:
            EventVillageImage += brandingData.villageRender.resize2160;
            break;
        default:
            EventVillageImage += brandingData.villageRender.original;
    }
    return EventVillageImage;
};

export const getBuildingLobbyBackgroundUploaded = (filesUrl, buildingData) => {
    let BuildingLobbyImage = filesUrl;
    let heightResolution = window.screen.height * window.devicePixelRatio;
    switch (true) {
        case heightResolution < 769:
            BuildingLobbyImage += buildingData.lobbyRender.resize768;
            break;
        case heightResolution < 901:
            BuildingLobbyImage += buildingData.lobbyRender.resize900;
            break;
        case heightResolution < 1081:
            BuildingLobbyImage += buildingData.lobbyRender.resize1080;
            break;
        case heightResolution < 1201:
            BuildingLobbyImage += buildingData.lobbyRender.resize1200;
            break;
        case heightResolution < 1441:
            BuildingLobbyImage += buildingData.lobbyRender.resize1440;
            break;
        case heightResolution < 2161:
            BuildingLobbyImage += buildingData.lobbyRender.resize2160;
            break;
        default:
            BuildingLobbyImage += buildingData.lobbyRender.original;
    }
    return BuildingLobbyImage;
};
