import Button from '@material-ui/core/Button';
import React from 'react';
import {connect} from 'react-redux';
import {NavLink, withRouter} from 'react-router-dom';
import {handleUserStatusUpdate} from '../../Api/socketApi';
import Spinner from '../../SmallLayoutComponents/Spinner';
import * as actions from '../../store/actions';
import {preventDefaultDrag} from '../../Utils/utils';

class OrganizerNavigation extends React.Component {
    state = {
        links: [
            {path: `/dashboard/organizers`, label: 'Organizers', isVisible: true},
            {path: `/dashboard/gdpr-platform`, label: 'GDPR - Platform', isVisible: true},
            {path: `/dashboard/gdpr-event`, label: 'GDPR - Event', isVisible: true},
            {path: `/dashboard/legal`, label: 'Legal', isVisible: true},
        ],
    };

    componentDidMount() {
        this.props.onGetOnlineUsers();

        // after fetching the getUsers api we initialize the following socket which
        // returns the user id & status everytime a user gets ONLINE || goes OFFLINE
        // and add / remove the userId from the onlineUsers array
        // ASIDE FROM THE EventMenu.js ->ADDED THIS HERE ALSO IN ORDER
        // TO MAKE THE "Online now: " COUNT REAL-TIME
        handleUserStatusUpdate(({id, status}) => this.props.onSetOnlineUsers({user: {id, status}}));
    }

    render() {
        const {links} = this.state;
        const {onlineUsers, loading} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} className="organizer-navigation-container">
                <div onDragStart={preventDefaultDrag} className="dashboard-navigation">
                    {links
                        .filter((link) => link.isVisible)
                        .map((link) => {
                            return (
                                <NavLink to={{pathname: link.path}} activeClassName="active" key={link.label}>
                                    <Button variant="contained">{link.label}</Button>
                                </NavLink>
                            );
                        })}
                </div>
                <div className="online-now-wrapper">
                    {loading && <Spinner />}
                    <span>{`Online now: ${onlineUsers}`}</span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        onlineUsers: state.onlineUsers.onlineUsersNumber,
        loading: state.eventUsers.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetOnlineUsers: () => dispatch(actions.getOnlineUsers()),
        onSetOnlineUsers: (user) => dispatch(actions.setOnlineUsers(user)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizerNavigation));
