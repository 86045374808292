import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utilityStateFunction';
import {languagesArray} from '../../Utils/Translations/availableLanguages';

const initialState = {
    loading: false,
    error: false,
    errorMessage: '',
    organizers: [],
    brandingTranslation: '',
    availableLanguages: languagesArray,
    brandingLanguage: 'en',
    allEvents: [],
    downloadData: [],
    eventsToday: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ADMIN_BRANDING_LANGUAGE:
            return updateObject(state, {
                brandingLanguage: action.payload,
            });

        case actionTypes.GET_ORGANIZERS_START:
            return updateObject(state, {
                loading: true,
                error: false,
                errorMessage: '',
            });
        case actionTypes.GET_ORGANIZERS_SUCCESS:
            return updateObject(state, {
                loading: false,
                error: false,
                errorMessage: '',
                organizers: action.payload,
            });
        case actionTypes.GET_ORGANIZERS_FAIL:
            return updateObject(state, {
                loading: false,
                error: true,
                errorMessage: action.payload,
            });

        case actionTypes.APPROVE_ORGANIZER_START:
            return updateObject(state, {
                loading: true,
                error: false,
                errorMessage: '',
            });
        case actionTypes.APPROVE_ORGANIZER_SUCCESS:
            return updateObject(state, {
                loading: false,
                error: false,
                errorMessage: '',
            });
        case actionTypes.APPROVE_ORGANIZER_FAIL:
            return updateObject(state, {
                loading: false,
                error: true,
                errorMessage: action.payload,
            });

        case actionTypes.DISABLE_ORGANIZER_START:
            return updateObject(state, {
                loading: true,
                error: false,
                errorMessage: '',
            });
        case actionTypes.DISABLE_ORGANIZER_SUCCESS:
            return updateObject(state, {
                loading: false,
                error: false,
                errorMessage: '',
            });
        case actionTypes.DISABLE_ORGANIZER_FAIL:
            return updateObject(state, {
                loading: false,
                error: true,
                errorMessage: action.payload,
            });

        case actionTypes.GET_BRANDING_TRANSLATION_START:
            return updateObject(state, {
                loading: true,
                error: false,
                errorMessage: '',
            });
        case actionTypes.GET_BRANDING_TRANSLATION_SUCCESS:
            return updateObject(state, {
                loading: false,
                error: false,
                errorMessage: '',
                brandingTranslation: action.payload,
            });
        case actionTypes.GET_BRANDING_TRANSLATION_FAIL:
            return updateObject(state, {
                loading: false,
                error: true,
                errorMessage: action.payload,
            });

        case actionTypes.UPDATE_BRANDING_TRANSLATION_START:
            return updateObject(state, {
                loading: true,
                error: false,
                errorMessage: '',
            });
        case actionTypes.UPDATE_BRANDING_TRANSLATION_SUCCESS:
            return updateObject(state, {
                loading: false,
                error: false,
                errorMessage: '',
            });
        case actionTypes.UPDATE_BRANDING_TRANSLATION_FAIL:
            return updateObject(state, {
                loading: false,
                error: true,
                errorMessage: action.payload,
            });
        case actionTypes.GET_ADMIN_ALL_EVENTS_START:
            return updateObject(state, {
                loading: true,
                error: false,
                errorMessage: '',
            });
        case actionTypes.GET_ADMIN_ALL_EVENTS_SUCCESS:
            return updateObject(state, {
                loading: false,
                error: false,
                errorMessage: '',
                allEvents: action.payload,
            });
        case actionTypes.GET_ADMIN_ALL_EVENTS_FAIL:
            return updateObject(state, {
                loading: false,
                error: true,
                errorMessage: action.payload,
            });
        case actionTypes.SET_ADMIN_ALL_EVENTS_EXCEL_START:
            return updateObject(state, {
                loading: true,
                error: false,
                errorMessage: '',
            });
        case actionTypes.SET_ADMIN_ALL_EVENTS_EXCEL_SUCCESS:
            return updateObject(state, {
                loading: false,
                error: false,
                errorMessage: '',
                downloadData: action.payload,
            });
        case actionTypes.SET_ADMIN_ALL_EVENTS_EXCEL_FAIL:
            return updateObject(state, {
                loading: false,
                error: true,
                errorMessage: action.payload,
            });
        case actionTypes.GET_EVENTS_TODAY_START:
            return updateObject(state, {
                loading: true,
                error: false,
                errorMessage: '',
            });
        case actionTypes.GET_EVENTS_TODAY_SUCCESS:
            return updateObject(state, {
                loading: false,
                error: false,
                errorMessage: '',
                eventsToday: action.payload,
            });
        case actionTypes.GET_EVENTS_TODAY_FAIL:
            return updateObject(state, {
                loading: false,
                error: true,
                errorMessage: action.payload,
            });

        default:
            return state;
    }
};

export default reducer;
