import moment from 'moment/moment';
import React, {Fragment} from 'react';
import connect from 'react-redux/es/connect/connect';
import {withRouter} from 'react-router-dom';
import {getProgramDayString, getTranslatedMonths, preventDefaultDrag} from '../../Utils/utils';
import * as actions from '../../store/actions';
import OnDemandVideo from './OnDemandVideo';
import './OnDemandVideos.scss';

class OnDemandVideosAuditorium extends React.Component {
    getDayString = (lastDateToFormat, currentDateToFormat) => {
        const {translations, platformLanguage} = this.props.languages;

        const monthsTranslations = translations[platformLanguage]?.time;

        let months = getTranslatedMonths(platformLanguage, monthsTranslations);

        let lastDate = moment(lastDateToFormat).startOf('day');
        let lastDay = lastDate.date();
        //let lastMonth = months[lastDate.month()];
        let lastYear = lastDate.year();

        let currentDate = moment(currentDateToFormat).startOf('day');
        let currentDay = currentDate.date();
        let currentMonth = months[currentDate.month()];
        let currentYear = currentDate.year();
        // const formatedDate = `${lastDay} ${currentMonth === lastMonth ? ' ' : lastMonth}
        // ${currentYear === lastYear ? ' ' : lastYear} - ${currentDay} ${currentMonth} ${currentYear}`;
        const formatedDate = `${lastDay} ${currentMonth} 
        ${lastYear} - ${currentDay} ${currentMonth} ${currentYear}`;
        return formatedDate;
    };

    getProgramDayString = (currentDay) => {
        return getProgramDayString(currentDay, this.props.languages);
    };

    checkTimeslotHasVideo = (timeslot) => {
        const hasYoutubeVideo = timeslot.video;
        const hasUploadedVideo = timeslot.session?.link && timeslot.session.type === 'recorded';
        const hasRecordedStreams = timeslot.session?.records?.length;
        return hasYoutubeVideo || hasUploadedVideo || hasRecordedStreams;
    };

    render() {
        const {auditorium} = this.props;

        let hasVideoTimeslots = false;
        auditorium.dailyProgram.forEach((dayProgram) => {
            dayProgram.program.forEach((timeslot) => {
                const timeslotHasVideo = this.checkTimeslotHasVideo(timeslot);
                if (timeslotHasVideo) {
                    hasVideoTimeslots = true;
                }
            });
        });

        if (!hasVideoTimeslots) {
            return null;
        }

        return (
            <div onDragStart={preventDefaultDrag} className="single-auditorium-archive-container">
                <h4 className="auditorium-name">
                    <span>{auditorium.name}</span>
                    <span onDragStart={preventDefaultDrag} draggable="false" className="break">
                        |
                    </span>
                    {auditorium.dailyProgram.length > 1 ? (
                        <span onDragStart={preventDefaultDrag} draggable="false" className="dates">
                            {this.getDayString(
                                auditorium.dailyProgram[0].date,
                                auditorium.dailyProgram[auditorium.dailyProgram.length - 1].date
                            )}
                        </span>
                    ) : (
                        <span onDragStart={preventDefaultDrag} draggable="false" className="dates">
                            {this.getProgramDayString(auditorium.dailyProgram[0].date)}
                        </span>
                    )}
                </h4>
                <div onDragStart={preventDefaultDrag} className="on-demand-videos-list">
                    {auditorium.dailyProgram.map((dayProgram) => {
                        return (
                            <Fragment key={dayProgram._id}>
                                {dayProgram.program.map((timeslot) => {
                                    const timeslotHasVideo = this.checkTimeslotHasVideo(timeslot);
                                    if (!timeslotHasVideo) {
                                        return null;
                                    }
                                    return (
                                        <OnDemandVideo
                                            key={timeslot._id}
                                            timeslot={timeslot}
                                            auditorium={auditorium}
                                            getProgramDayString={this.getProgramDayString}
                                            dayProgram={dayProgram}
                                        />
                                    );
                                })}
                            </Fragment>
                        );
                    })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        event: state.event.data,
        languages: state.languages,
        onDemandVideos: state.onDemandVideos,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onGetOnDemandVideos: (eventId) => dispatch(actions.getOnDemandVideos(eventId)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnDemandVideosAuditorium));
