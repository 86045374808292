import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {withRouter} from 'react-router-dom';
import './OnDemandVideo.scss';
import {Link} from 'react-router-dom';
import {ReactComponent as PlayIcon} from '../../Images/svg/play.svg';
import {getVideoIdFromYoutubeUrl, preventDefaultDrag} from '../../Utils/utils';
import TimeslotSpeakers from './TimeslotSpeakers';
import callAxios from 'axios';
import Spinner from '../../SmallLayoutComponents/Spinner';

class OnDemandVideo extends React.Component {
    state = {
        thumbnailVimeoImage: null,
        spinner: false
    };

    componentDidMount() {
        this.setThumbnailImage();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.timeslot !== this.props.timeslot) {
            this.setThumbnailImage();
        }
    }

    setThumbnailImage = () => {
        const {timeslot} = this.props;
        if (timeslot.streamingEngine === 'Vimeo') {
            if (timeslot?.video) {
                this.setState({
                    spinner: true
                });
                callAxios.get(`https://vimeo.com/api/oembed.json?url=${timeslot?.video}`)
                    .then(res => {
                        this.setState({
                            thumbnailVimeoImage: `${res.data.thumbnail_url}.jpg`,
                            spinner: false
                        });
                    })
            }

        }
    };

    render() {
        const {spinner, thumbnailVimeoImage} = this.state;
        const {timeslot, auditorium, dayProgram} = this.props;
        const videoId = getVideoIdFromYoutubeUrl(timeslot.video);
        const timeslotURL = `/event/${this.props.event.slug}/auditorium-archive/${auditorium._id}/timeslot/${timeslot._id}`;
        let imageURL = '';
        if (timeslot?.session?.thumbnail) {
            imageURL = `${process.env.REACT_APP_SESSION_FOLDER}${timeslot?.session.thumbnail}`;
        } else if(timeslot.streamingEngine === 'Vimeo') {
            imageURL = thumbnailVimeoImage;
        } else {
            imageURL = `https://img.youtube.com/vi/${videoId}/0.jpg`;
        }

        return (
            <div onDragStart={preventDefaultDrag} className="on-demand-video">
                <div onDragStart={preventDefaultDrag} className="video-preview-container">
                    <Link to={{pathname: timeslotURL}}>
                        <div onDragStart={preventDefaultDrag} className="clickable-element">
                            <div onDragStart={preventDefaultDrag} className="image-preview-video">
                                {spinner && <Spinner/>}
                                <img
                                    draggable="false"
                                    onDragStart={preventDefaultDrag}
                                    src={imageURL}
                                    alt="preview video"
                                />
                                <div onDragStart={preventDefaultDrag} className="overlay" />
                                <div onDragStart={preventDefaultDrag} className="play-button">
                                    <PlayIcon />
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div onDragStart={preventDefaultDrag} className="video-details-container">
                    <TimeslotSpeakers speakers={timeslot.speakersExtended} />
                    <Link to={{pathname: timeslotURL}} className="title">
                        {timeslot.title}
                    </Link>
                    <div onDragStart={preventDefaultDrag} className="program-date-hash">
                        <span onDragStart={preventDefaultDrag} draggable="false" className="date">
                            {this.props.getProgramDayString(dayProgram.date)}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
    };
};

export default withRouter(connect(mapStateToProps)(OnDemandVideo));
