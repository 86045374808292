import React, {PureComponent} from 'react';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {ReactComponent as UsernameIcon} from '../Images/svg/account-input.svg';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import WhiteCheckbox from '../SmallLayoutComponents/WhiteCheckbox';
import * as actions from '../store/actions';
import {connect} from 'react-redux';
import RecoverPassword from '../Dialogs/RecoverPassword';
import {preventDefaultDrag} from '../Utils/utils';

class LoginPlatformForm extends PureComponent {
    state = {
        email: '',
        password: '',
        disabled: true,
        showRecoverPasswordDialog: false,
        recoverPasswordServerMessage: '',
        rememberMe: false,
        showPassword: false,
    };

    componentDidMount() {
        document.addEventListener(
            'invalid',
            (function () {
                return function (e) {
                    //prevent the browser from showing default error bubble / hint
                    e.preventDefault();
                    // optionally fire off some custom validation handler
                    // myValidation();
                };
            })(),
            true
        );
    }

    componentWillUnmount() {
        document.removeEventListener(
            'invalid',
            (function () {
                return function (e) {
                    //prevent the browser from showing default error bubble / hint
                    e.preventDefault();
                    // optionally fire off some custom validation handler
                    // myValidation();
                };
            })(),
            true
        );
    }

    handleRecoverPassword = () => this.setState({showRecoverPasswordDialog: true});

    handleCloseDialog = (message) =>
        this.setState({showRecoverPasswordDialog: false, recoverPasswordServerMessage: message});

    toggleShowPassword = () =>
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));

    handleChangeCheckbox = () =>
        this.setState((prevState) => ({
            rememberMe: !prevState.rememberMe,
        }));

    handleLogin = (e) => {
        e.preventDefault();

        let loginUserData = {
            email: this.state.email,
            password: this.state.password,
        };

        this.props.onLoginUser(loginUserData);
    };

    handleChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value,
            },
            () => {
                this.refs.form.isFormValid().then((isValid) => {
                    this.setState({disabled: !isValid});
                });
            }
        );
    };

    render() {
        const {email, password, showPassword, rememberMe, disabled, showRecoverPasswordDialog} = this.state;
        const {error} = this.props;

        return (
            <ValidatorForm ref="form" onSubmit={this.handleLogin}>
                <div onDragStart={preventDefaultDrag} className="email-wrapper">
                    <TextValidator
                        className="field-container"
                        label="Email"
                        onChange={this.handleChange}
                        name="email"
                        value={email.trim()}
                        type="email"
                        variant="filled"
                        fullWidth
                        validators={['required', 'isEmail']}
                        errorMessages={['Field is required', 'Email not valid']}
                    />
                    <UsernameIcon />
                </div>
                <div onDragStart={preventDefaultDrag} className="password-wrapper">
                    <TextValidator
                        className="field-container"
                        label="Password"
                        onChange={this.handleChange}
                        name="password"
                        value={password}
                        variant="filled"
                        type={`${showPassword ? 'text' : 'password'}`}
                        fullWidth
                        validators={['required', 'minStringLength:8']}
                        errorMessages={['Field is required', 'Password must have min 8 characters']}
                    />
                    <div onDragStart={preventDefaultDrag} className="show-pass" onClick={this.toggleShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                    </div>
                </div>
                <div onDragStart={preventDefaultDrag} className="recover-wrapper">
                    <div>
                        <FormControlLabel
                            control={<WhiteCheckbox checked={rememberMe} onChange={this.handleChangeCheckbox} />}
                            label={`Remember\n me`}
                        />
                    </div>
                    <div>
                        <button onClick={this.handleRecoverPassword} type="button">
                            {`Forgot\n password?`}
                        </button>
                    </div>
                    {this.state.recoverPasswordServerMessage && (
                        <p onDragStart={preventDefaultDrag} className="server-message">
                            {this.state.recoverPasswordServerMessage}
                        </p>
                    )}
                </div>
                {error && error === 'Not registered' ? (
                    <p>We couldn’t find your Neural Network account</p>
                ) : error && error === 'Unable to login' ? (
                    <p>Incorrect email or password</p>
                ) : <p>{error}</p>}
                <button
                    className={'login-submit-button ' + (disabled ? 'disabled' : '') + (error ? 'has-error' : '')}
                    type="submit"
                >
                    Log in
                </button>
                {showRecoverPasswordDialog ? (
                    <RecoverPassword opened={showRecoverPasswordDialog} closeDialog={this.handleCloseDialog} />
                ) : null}
            </ValidatorForm>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.user.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLoginUser: (user) => dispatch(actions.loginUser(user)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPlatformForm);
