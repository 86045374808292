import React, {PureComponent} from 'react';
import {withRouter} from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as GetAppIcon} from '../../Images/svg/download-cloud.svg';
import axios from '../../store/axios-instance';
import ReactExport from 'react-export-excel';
import moment from 'moment';
import {cleanProhibitedCharactersInFileName, preventDefaultDrag} from '../../Utils/utils';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class DownloadPosts extends PureComponent {
    state = {
        canDownload: false,
        actionDownload: false,
        excelFields: [
            {label: 'Post', value: 'post'},
            {label: 'Timestamp', value: 'timestamp'},
            {label: 'User', value: 'user'},
            {label: 'User Email', value: 'userEmail'},
            {label: 'User Roles', value: 'userRoles'},
            {label: 'Comments', value: 'commentsNo'},
            {label: 'Likes', value: 'likes'},
            {label: 'Comment', value: 'comment'},
            {label: 'Comment - Timestamp', value: 'commentTimestamp'},
            {label: 'Comment - User', value: 'commentUser'},
            {label: 'Comment - User Email', value: 'commentUserEmail'},
            {label: 'Comment - User Roles', value: 'commentUserRoles'},
        ],
        excelData: [],
        excelName: '',
    };

    componentDidMount() {
        this.checkCanDownloadPosts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {sideNavigationDetails} = this.props;
        if (prevProps.sideNavigationDetails.activeWallId !== sideNavigationDetails.activeWallId) {
            this.checkCanDownloadPosts();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.downloadPostsTimeoutId);
        clearTimeout(this.downloadPostsTimeoutId2);
    }

    checkCanDownloadPosts = () => {
        const {event, currentUser} = this.props;
        if (event?.owner?._id === currentUser?._id) {
            this.setState({
                canDownload: true,
            });
        }
    };

    handleDownloadPosts = () => {
        const {event, eventId, sideNavigationDetails, eventWallId, downloadType} = this.props;
        let wallId = sideNavigationDetails.activeWallId;
        if (eventWallId) {
            wallId = eventWallId;
        }

        if (downloadType) {
            let apiUrl = '';
            let excelName = '';
            if (downloadType === 'booths') {
                apiUrl = '/fetch-booth-wall-posts-report';
                excelName = `All Booth Wall Posts - ${event?.name}`;
            } else if (downloadType === 'videos') {
                apiUrl = '/fetch-video-wall-posts-report';
                excelName = `All Video Wall Posts - ${event?.name}`;
            }

            axios({method: 'get', url: '/event/' + eventId + apiUrl}).then((res) => {
                const {walls} = res.data;
                let arr = [];
                walls.forEach((wall) => {
                    let sheet = [];
                    wall?.posts.forEach((post) => {
                        let el = {};
                        el.post = post?.text
                            .replaceAll(/&#x2F;/g, '/')
                            .replaceAll(/&#x5C;/g, '\\')
                            .replaceAll(/&amp;/g, '&')
                            .replaceAll(/&gt;/g, '>')
                            .replaceAll(/&lt;/g, '<')
                            .replaceAll(/&quot;/g, '"')
                            .replaceAll(/&#x27;/g, `'`)
                            .replaceAll(/&apos;/g, `'`)
                            .replaceAll(/&#96;/g, '`');
                        let datePost = moment(post?.createdAt);
                        el.timestamp = moment(datePost).format('YYYY-MM-DD HH:mm:ss');
                        el.user = `${post?.user?.first} ${post?.user?.last}`;
                        el.userEmail = post?.user?.email;
                        let userEventRoles = post.user.eventRoles.find((eventRole) => eventRole.event === wall.event);
                        let userEventRolesString = userEventRoles?.roles.join(', ');
                        el.userRoles = this.getUserRolesString(userEventRolesString, userEventRoles);
                        el.commentsNo = post?.comments?.length;
                        el.likes = post?.likes.length;
                        sheet.push(el);
                        post.comments.forEach((comment) => {
                            let el = {};
                            el.comment = comment?.text
                                .replaceAll(/&#x2F;/g, '/')
                                .replaceAll(/&#x5C;/g, '\\')
                                .replaceAll(/&amp;/g, '&')
                                .replaceAll(/&gt;/g, '>')
                                .replaceAll(/&lt;/g, '<')
                                .replaceAll(/&quot;/g, '"')
                                .replaceAll(/&#x27;/g, `'`)
                                .replaceAll(/&apos;/g, `'`)
                                .replaceAll(/&#96;/g, '`');

                            let dateComment = moment(comment?.createdAt);
                            el.commentTimestamp = moment(dateComment).format('YYYY-MM-DD HH:mm:ss');
                            el.commentUser = `${comment?.user?.first} ${comment?.user?.last}`;
                            el.commentUserEmail = comment?.user?.email;
                            let commentUserEventRoles = comment.user.eventRoles.find(
                                (eventRole) => eventRole.event === wall.event
                            );
                            let commentUserEventRolesString = commentUserEventRoles?.roles.join(', ');
                            el.commentUserRoles = this.getUserRolesString(
                                commentUserEventRolesString,
                                commentUserEventRoles
                            );
                            sheet.push(el);
                        });
                    });

                    sheet.name =
                        wall?.auditorium?.name ||
                        wall?.exhibitor?.company ||
                        `${wall?.exhibitor?.user?.first} ${wall?.exhibitor?.user?.last}` ||
                        '';
                    arr.push(sheet);
                });

                this.setState({
                    excelName: excelName,
                    excelData: arr,
                    multiTabs: true,
                    actionDownload: true,
                });
                // bug re-download
                this.downloadPostsTimeoutId = setTimeout(() => {
                    this.setState({
                        actionDownload: false,
                    });
                }, 1000);
            });
        } else {
            axios({method: 'get', url: `/event/${eventId}/live-wall/${wallId}/download-posts`}).then((res) => {
                const {wall} = res.data;
                let arr = [];
                let excelName = '';
                switch (wall?.type) {
                    case 'videoWall':
                        let timeslotName = '';
                        wall?.auditorium?.dailyProgram.forEach((day) => {
                            let timeslot = day.program.find((program) => program.videoWall === wallId);
                            if (timeslot) {
                                timeslotName = timeslot.title;
                            }
                        });
                        excelName = `${timeslotName || wall?.auditorium?.name} (Video Wall Posts) - ${event?.name}`;
                        break;
                    case 'boothWall':
                        let boothName = '';
                        if (wall.exhibitor.company) {
                            boothName = wall.exhibitor.company;
                        } else {
                            boothName = `${wall.exhibitor.user.first} ${wall.exhibitor.user.last}`;
                        }
                        excelName = `${boothName} (Booth Wall Posts) - ${event?.name}`;
                        break;
                    default:
                        excelName = `All Live Wall Posts - ${event?.name}`;
                }

                wall?.posts.forEach((post) => {
                    let el = {};
                    el.post = post?.text
                        .replaceAll(/&#x2F;/g, '/')
                        .replaceAll(/&#x5C;/g, '\\')
                        .replaceAll(/&amp;/g, '&')
                        .replaceAll(/&gt;/g, '>')
                        .replaceAll(/&lt;/g, '<')
                        .replaceAll(/&quot;/g, '"')
                        .replaceAll(/&#x27;/g, `'`)
                        .replaceAll(/&apos;/g, `'`)
                        .replaceAll(/&#96;/g, '`');
                    let datePost = moment(post?.createdAt);
                    el.timestamp = moment(datePost).format('YYYY-MM-DD HH:mm:ss');
                    el.user = `${post?.user?.first} ${post?.user?.last}`;
                    el.userEmail = post?.user?.email;
                    let userEventRoles = post.user.eventRoles.find((eventRole) => eventRole.event === wall.event);
                    let userEventRolesString = userEventRoles?.roles.join(', ');
                    el.userRoles = this.getUserRolesString(userEventRolesString, userEventRoles);
                    el.commentsNo = post?.comments?.length;
                    el.likes = post?.likes.length;
                    arr.push(el);
                    post.comments.forEach((comment) => {
                        let el = {};
                        el.comment = comment?.text
                            .replaceAll(/&#x2F;/g, '/')
                            .replaceAll(/&#x5C;/g, '\\')
                            .replaceAll(/&amp;/g, '&')
                            .replaceAll(/&gt;/g, '>')
                            .replaceAll(/&lt;/g, '<')
                            .replaceAll(/&quot;/g, '"')
                            .replaceAll(/&#x27;/g, `'`)
                            .replaceAll(/&apos;/g, `'`)
                            .replaceAll(/&#96;/g, '`');
                        let dateComment = moment(comment?.createdAt);
                        el.commentTimestamp = moment(dateComment).format('YYYY-MM-DD HH:mm:ss');
                        el.commentUser = `${comment?.user?.first} ${comment?.user?.last}`;
                        el.commentUserEmail = comment?.user?.email;
                        let commentUserEventRoles = comment.user.eventRoles.find(
                            (eventRole) => eventRole.event === wall.event
                        );
                        let commentUserEventRolesString = commentUserEventRoles?.roles.join(', ');
                        el.commentUserRoles = this.getUserRolesString(
                            commentUserEventRolesString,
                            commentUserEventRoles
                        );
                        arr.push(el);
                    });
                });

                excelName = cleanProhibitedCharactersInFileName(excelName);
                this.setState({
                    excelName: excelName,
                    excelData: arr,
                    actionDownload: true,
                });

                // bug re-download
                this.downloadPostsTimeoutId2 = setTimeout(() => {
                    this.setState({
                        actionDownload: false,
                    });
                }, 1000);
            }).catch(() => {});
        }
    };

    getUserRolesString = (rolesArray, eventRoles) => {
        let updatedRolesArray = rolesArray;
        updatedRolesArray = updatedRolesArray.replace('organizerRep', 'Receptionist');
        updatedRolesArray = updatedRolesArray.replace('organizer', 'Organizer');
        updatedRolesArray = updatedRolesArray.replace('coOrganizer', 'Co-Organizer');
        updatedRolesArray = updatedRolesArray.replace('participant', 'Participant');
        updatedRolesArray = updatedRolesArray.replace('speaker', 'Speaker');
        if (updatedRolesArray.includes('exhibitorRep')) {
            if (eventRoles?.exhibitorRepresentative[0]?.type === 'scholar') {
                updatedRolesArray = updatedRolesArray.replace('exhibitorRep', 'Poster / Study Representative');
            } else {
                updatedRolesArray = updatedRolesArray.replace('exhibitorRep', 'Exhibitor Representative');
            }
        }
        if (updatedRolesArray.includes('exhibitor')) {
            if (eventRoles?.exhibitor.type === 'scholar') {
                updatedRolesArray = updatedRolesArray.replace('exhibitor', 'Poster / Study');
            } else {
                updatedRolesArray = updatedRolesArray.replace('exhibitor', 'Exhibitor');
            }
        }
        return updatedRolesArray;
    };

    handleEnterKey = (e) => {
        if (e.key === 'Enter') return this.handleDownloadPosts();
    };

    render() {
        const {actionDownload, excelFields, excelData, excelName, multiTabs} = this.state;
        if (this.props.eventRoles.isOrganizer || this.props.eventRoles.isCoOrganizer) {
            return (
                <>
                    <span onDragStart={preventDefaultDrag} id="download-info">
                        <GetAppIcon
                            tabIndex="0"
                            className="button"
                            onKeyDown={this.handleEnterKey}
                            onClick={this.handleDownloadPosts}
                            fill={colors.dark}
                        />
                    </span>
                    {actionDownload && excelData && (
                        <ExcelFile filename={excelName} hideElement={true}>
                            {multiTabs ? (
                                excelData.map((sheet, index) => {
                                    return (
                                        <ExcelSheet key={index} data={sheet} name={sheet.name}>
                                            {/*<ExcelColumn label="Post" value="post" />*/}
                                            {/*<ExcelColumn label="Timestamp" value="timestamp" />*/}
                                            {/*<ExcelColumn label="User" value="user" />*/}
                                            {/*<ExcelColumn label="User Email" value="userEmail" />*/}
                                            {/*<ExcelColumn label="User Roles" value="userRoles" />*/}
                                            {/*<ExcelColumn label="Comments" value="commentsNo" />*/}
                                            {/*<ExcelColumn label="Likes" value="likes" />*/}
                                            {/*<ExcelColumn label="Comment" value="comment" />*/}
                                            {/*<ExcelColumn label="Comment Timestamp" value="commentTimestamp" />*/}
                                            {/*<ExcelColumn label="Comment - User" value="commentUser" />*/}
                                            {/*<ExcelColumn label="Comment - User Email" value="commentUserEmail" />*/}
                                            {/*<ExcelColumn label="Comment User Roles" value="commentUserRoles" />*/}
                                            {excelFields.map((field, index) => {
                                                return (
                                                    <ExcelColumn key={index} label={field.label} value={field.value} />
                                                );
                                            })}
                                        </ExcelSheet>
                                    );
                                })
                            ) : (
                                <ExcelSheet data={excelData} name={excelName}>
                                    {excelFields.map((field, index) => {
                                        return <ExcelColumn key={index} label={field.label} value={field.value} />;
                                    })}
                                </ExcelSheet>
                            )}
                        </ExcelFile>
                    )}
                </>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        currentUser: state.user.data,
        sideNavigationDetails: state.event.sideNavigationDetails,
        eventRoles: state.user.eventRoles,
    };
};

export default withRouter(connect(mapStateToProps)(DownloadPosts));
