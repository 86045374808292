import * as actionTypes from '../actions/actionTypes';
import produce from "immer";

const initialState = {
    loading: false,
    data: null,
    exhibitorId: null,
};

const reducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.GET_EXHIBITOR_START:
                draft.loading = true;
                break;

            case actionTypes.GET_EXHIBITOR_SUCCESS:
                draft.loading = false;
                draft.data = action.payload;
                draft.exhibitorId = action.payload._id;
                break;

            case actionTypes.GET_EXHIBITOR_FAIL:
                draft.loading = false;
                break;

            default:
                break;
        }
    })



export default reducer;
