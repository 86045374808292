import React from "react";


const MatchingPercentage = ({matchingLevel}) => {

    const getProgressColorClass = () => {
        if (!matchingLevel) return;
        let level = matchingLevel.toFixed();
        if (level > 0 && level <= 29) return 'low-match'
        if (level <= 79) return 'medium-match'
        return 'high-match'
    }

    const getWidthOfPercentage = () => {
        let level = matchingLevel;
        if (matchingLevel > 100) {
            level = 100;
        }
        return level.toFixed();
    }

    return (
        <div className="matching-progress-container">
            <div className="matching-percentage">{getWidthOfPercentage()}%</div>
            <div className='matching-progress'>
                <span className={`progress ${getProgressColorClass()}`} style={{width: `${getWidthOfPercentage()}%`}}></span>
            </div>
        </div>

    )
}

export default MatchingPercentage;