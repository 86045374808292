import {MenuItem, Chip, Tooltip} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import variables from '../../../CSS/_variables.module.scss';

export const SdgMenuItem = withStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textTransform: 'capitalize',
        fontFamily: variables.fontFamily,
        color: '#2b2b2b',
        fontWeight: 600,
        '& .tooltip-wrapper svg': {
            height: '18px',
            width: '18px',
            position: 'relative',
            top: '3px',
            marginRight: '8px',
            marginBottom: '0',
        },
        '& .tooltip-wrapper .type-info-icon': {
            height: '22px',
            width: '22px',
            position: 'relative',
            top: '4px',
            marginRight: '8px',
            marginBottom: '0',
        },
        '& svg': {
            height: '24px',
            width: '24px',
            position: 'relative',
            top: '3px',
            marginBottom: '6px',
        },
        '& .arrow-wrapper': {
            '&-blue': {
                height: '18px',
                '& svg': {
                    borderRadius: '100px',
                    backgroundColor: '#45B8FE',
                    transform: 'rotate(90deg)',
                },
            },
            '&-yellow': {
                height: '18px',
                '& svg': {
                    borderRadius: '100px',
                    backgroundColor: '#FFC107',
                },
            },
            '&-red': {
                height: '18px',
                '& svg': {
                    borderRadius: '100px',
                    backgroundColor: '#FF601C',
                    transform: 'rotate(-90deg)',
                },
            },
        },
        '&:focus': {
            background: variables.secondaryLight,
        },
    },
})(MenuItem);

export const CustomChip = withStyles({
    root: {
        height: '32px',
        overflow: 'visible',
        marginRight: '8px',
        borderRadius: '6px',
        background: '#F5F5F5',
        border: '1px solid #898989',
        '&:hover': {
            border: `1px solid ${variables.secondary}`,
            '& svg': {
                '& path': {
                    fill: variables.secondary,
                },
            },
        },
        '&:focus': {
            background: variables.darkWhite,
            border: `1px solid ${variables.secondary}`,
            '& svg': {
                '& path': {
                    fill: variables.secondary,
                },
            },
        },
        '&:active': {
            background: variables.secondary,
            '& .MuiChip-label': {
                color: '#FFFFFF',
            },
            '& svg': {
                '& path': {
                    fill: '#FFFFFF !important',
                },
            },
        },
    },
    labelSmall: {
        textTransform: 'none',
        fontFamily: variables.fontFamily,
        fontWeight: 500,
        fontSize: '14px',
        color: '#000000',
        paddingLeft: '8px',
        paddingRight: '9.5px',
    },
})(Chip);

export const CustomTooltip = withStyles({
    popper: {
        top: '1px !important',
        '& :before': {
            background: `linear-gradient(137.73deg, ${variables.gradientMenu1} -15.66%, ${variables.gradientMenu2} 43.77%, ${variables.gradientMenu3} 100.28%)`,
            left: '20px',
        },
    },
})(Tooltip);
