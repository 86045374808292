import {PureComponent} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import CloseTopNavButton from '../../../SmallLayoutComponents/CloseTopNavButton';
import * as actions from '../../../store/actions';
import './AdvancedProgram.scss';
import DialogAddToExternalCalendar from './DialogAddToExternalCalendar';
import MyAgenda from './MyAgenda/MyAgenda';
import Programme from './Programme/Programme';
import SmartSuggestions from './SmartSuggestions/SmartSuggestions';

class AdvancedProgram extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            tabsList: [
                {name: 'programme', label: 'Programme'},
                {name: 'myAgenda', label: 'My calendar'},
                {name: 'smartSuggestions', label: 'Smart suggestions'},
            ],
            selectedTab: 'programme',
            showAddToExternalCalendarDialog: false,
            eventCalendarData: null,
            calendarEventType: null,
        };
    }

    componentDidMount() {
        const {eventId} = this.props;
        if (this.props.topNavigation.isOpenedFromNotifications) {
            this.setState({selectedTab: 'myAgenda'}, () => {
                this.props.getSuggestions(eventId).then(() => {
                    this.props.getMyAgenda(eventId);
                });
            });
        } else {
            this.props.getSuggestions(eventId).then(() => {
                this.props.getMyAgenda(eventId);
            });
        }

        if (!this.handleGetSmartTooltip()) {
            this.props.startTooltipTimeout();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.selectedTab !== 'smartSuggestions') {
            this.props.stopDisplayingSmartTooltip();
        }
    }

    componentWillUnmount() {
        this.props.setProgrammeSearch({});
    }

    selectTab = (tab) => () => {
        this.setState({selectedTab: tab ? tab : false});
    };

    getTabList = () => {
        const {tabsList, selectedTab} = this.state;
        return tabsList.map((tab) => {
            return (
                <div
                    onClick={this.selectTab(tab.name)}
                    className={`${selectedTab === tab.name ? 'active' : ''}`}
                    key={tab.name}
                >
                    <span>{tab.label}</span>
                </div>
            );
        });
    };

    handleGetSmartTooltip = () => {
        const {eventSlug, user} = this.props;
        const smartTooltipData = localStorage.getItem('smartTooltipData');
        if (smartTooltipData !== null) {
            const smartTooltipDataArray = JSON.parse(smartTooltipData);
            let data = smartTooltipDataArray
                ?.filter((tooltipData) => tooltipData.event === eventSlug && tooltipData.userId === user?._id)
                ?.shift();
            if (data !== undefined && data['userId'] === user?._id && data['smartTooltipDisplayed'] !== undefined) {
                return true;
            }
            return false;
        }
        return false;
    };

    showAddToExternalCalendarDialog = (calendarData, calendarEventType) => {
        this.setState({
            showAddToExternalCalendarDialog: true,
            eventCalendarData: calendarData,
            calendarEventType: calendarEventType,
        });
    };

    hideAddToExternalCalendarDialog = () => {
        this.setState({
            showAddToExternalCalendarDialog: false,
        });
    };

    render() {
        const {selectedTab, showAddToExternalCalendarDialog, eventCalendarData, calendarEventType} = this.state;
        const {isLargeScreen, active} = this.props;
        return (
            <div
                id={`${!isLargeScreen ? 'program-mobile' : ''}`}
                className={`auditorium-page dropdown-component ${active ? 'active' : ''}`}
            >
                <CloseTopNavButton hideTooltip={this.props.stopDisplayingSmartTooltip} />
                <div className="advanced-program-container">
                    <div className="tabs-wrapper">{this.getTabList()}</div>
                    {selectedTab === 'smartSuggestions' && (
                        <SmartSuggestions
                            hideTooltip={this.props.stopDisplayingSmartTooltip}
                            stopTooltip={this.props.stopTimeout}
                            showAddToExternalCalendarDialog={this.showAddToExternalCalendarDialog}
                        />
                    )}
                    {selectedTab === 'myAgenda' && (
                        <MyAgenda showAddToExternalCalendarDialog={this.showAddToExternalCalendarDialog} />
                    )}
                    {selectedTab === 'programme' && (
                        <Programme showAddToExternalCalendarDialog={this.showAddToExternalCalendarDialog} />
                    )}
                </div>
                {showAddToExternalCalendarDialog && (
                    <DialogAddToExternalCalendar
                        open={showAddToExternalCalendarDialog}
                        onClose={this.hideAddToExternalCalendarDialog}
                        eventCalendarData={eventCalendarData}
                        calendarEventType={calendarEventType}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        user: state.user.data,
        topNavigation: state.user.topNavigation,
        isLargeScreen: state.layout.isLargeScreen,
        languages: state.languages,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseTopNav: () => dispatch(actions.topNavClose()),
        getSuggestions: (eventId) => dispatch(actions.getProgramSuggestions(eventId)),
        getMyAgenda: (eventId) => dispatch(actions.getMyAgenda(eventId)),
        setProgrammeSearch: (search) => dispatch(actions.setProgrammeSearch(search)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdvancedProgram));
