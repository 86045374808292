import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import './EmailCodeValidation.scss';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AuthCode from 'react-auth-code-input';
import InfoIcon from '@material-ui/icons/Info';
import {ReactComponent as UserCreatedImage} from '../../../Images/svg/matching-loader.svg';
import {ReactComponent as EmailConfirmedSuccess} from '../../../Images/svg/email-confirmed-success-arrow.svg';


class EmailCodeValidation extends PureComponent {
    state = {
        codeExpiresTimerId: null, // timerId
        secondsUntilCodeExpires: 900, // number of seconds for 15 minutes
        resendButtonTimerId: null, // timerId
        secondsUntilDisplayResendButton: 30, // number of seconds
    };

    componentDidMount() {
        this.startTimers();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.secondsUntilCodeExpires === 0){
            this.stopCodeExpiresTimer();
        }
        if(this.state.secondsUntilDisplayResendButton === 0){
            this.stopResendButtonTimer();
        }
    }

    componentWillUnmount() {
        this.stopTimers();
    }

    startTimers = () => {
        this.startCodeExpiresTimer();
        this.startResendButtonTimer();
    };

    stopTimers = () => {
        this.stopCodeExpiresTimer();
        this.stopResendButtonTimer();
    };

    startCodeExpiresTimer = () => {
        const codeExpiresTimerId = setInterval(this.tickCodeExpires, 1000);
        this.setState({
            codeExpiresTimerId: codeExpiresTimerId
        });
    };

    tickCodeExpires = () => {
        this.setState({
            secondsUntilCodeExpires: this.state.secondsUntilCodeExpires - 1
        })
    };

    stopCodeExpiresTimer = () => {
        if(this.state.codeExpiresTimerId){
            clearInterval(this.state.codeExpiresTimerId)
            this.setState({
                codeExpiresTimerId: null
            })
        }
    };

    startResendButtonTimer = () => {
        const resendButtonTimerId = setInterval(this.tickDisplayResendButton, 1000);
        this.setState({
            resendButtonTimerId: resendButtonTimerId
        })
    };

    tickDisplayResendButton = () => {
        this.setState({
            secondsUntilDisplayResendButton: this.state.secondsUntilDisplayResendButton - 1
        })
    };

    stopResendButtonTimer = () => {
        if(this.state.resendButtonTimerId){
            clearInterval(this.state.resendButtonTimerId)
            this.setState({
                resendButtonTimerId: null
            })
        }
    };

    formatTime = (secs) => {
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        return `${minutes < 10 ? '0' : ''}${minutes === '0' ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds === '0' ? '0' : ''}${seconds}`;
    };

    resendVerificationCode = () => {
        // make api call to resend the code
        this.props.createRegistrationCode();
        // if success, reset both the timers
        this.stopTimers();
        this.props.onRegisterUserFail('');
        this.setState({
            secondsUntilCodeExpires: 900,
            secondsUntilDisplayResendButton: 30
        }, this.startTimers);
        const codeInputButtons = document.getElementsByClassName('single-digit-input');
        if (codeInputButtons) {
            for (let i=0; i<codeInputButtons?.length; i++){
                codeInputButtons[i].value = '';
            }
        }
        codeInputButtons[0]?.focus();
    };

    handleInputChange = code => {
        if(code.length === 6){
            this.props.handleRegister(code);
        } else{
            this.props.onRegisterUserFail('')
        }
    };

    render() {
        const {translation, defaultTranslation, userEmail, displayUserCreatedScreen} = this.props;
        const {secondsUntilCodeExpires, secondsUntilDisplayResendButton} = this.state;
        const codeExpired = secondsUntilCodeExpires === 0;
        const displayResendButton = codeExpired || secondsUntilDisplayResendButton === 0;

        return (
            <div className={`email-code-confirmation ${this.props.error ? 'has-error' : ''}`}>
                <div className="confirmation-container">
                    {displayUserCreatedScreen &&
                        <span className={'confirmation-container-text'}>
                            {
                                translation?.emailCodeVerification?.congrats ||
                                defaultTranslation?.emailCodeVerification?.congrats
                            }
                        </span>
                    }
                    {displayUserCreatedScreen
                        ?<span className={'confirmation-container-text'}>
                            {
                                translation?.emailCodeVerification?.emailConfirmed ||
                                defaultTranslation?.emailCodeVerification?.emailConfirmed
                            }
                        </span>
                        :<span className={'confirmation-container-text'}>
                        {
                            translation?.emailCodeVerification?.codeSent ||
                            defaultTranslation?.emailCodeVerification?.codeSent
                        }
                    </span>
                    }

                    <div className="email-container">
                        <div className="spacer"/>
                        <div className="content-container">
                            <MailOutlineIcon/>
                            <span>{userEmail}</span>
                        </div>
                        <div className="spacer"/>
                    </div>
                </div>

                {displayUserCreatedScreen
                    ?<div className="user-created-confirmation-container">
                        <EmailConfirmedSuccess className={'check-icon'}/>
                        <UserCreatedImage />
                    </div>
                    :<>
                        {!codeExpired &&
                        <div className="code-container">
                            <p className={'enter-code-label'}>
                            <span>
                                {
                                    translation?.emailCodeVerification?.continueRegistration ||
                                    defaultTranslation?.emailCodeVerification?.continueRegistration
                                }
                            </span>
                                <span className={'enter-code-text'}>
                                {
                                    translation?.emailCodeVerification?.enterCodeBelow ||
                                    defaultTranslation?.emailCodeVerification?.enterCodeBelow
                                }
                            </span>
                            </p>
                            <div className="code-input-container">
                                <AuthCode
                                    characters={6}
                                    onChange={this.handleInputChange}
                                    containerClassName='code-input'
                                    inputClassName='single-digit-input'
                                    allowedCharacters={"^[0-9]"}
                                />
                                {/* error when trying to register user/ probably code invalid*/}
                                {this.props.error && <div className={'error-container'}>
                                    <div className="triangle"/>
                                    <InfoIcon />
                                    {this.props.error === 'Entered code is invalid.'
                                        ?<p>
                                    <span>
                                        {
                                            translation?.emailCodeVerification?.codeError ||
                                            defaultTranslation?.emailCodeVerification?.codeError
                                        }
                                    </span>
                                            <span>
                                        {
                                            translation?.emailCodeVerification?.codeErrorFix ||
                                            defaultTranslation?.emailCodeVerification?.codeErrorFix
                                        }
                                    </span>
                                        </p>
                                        : <p>{this.props.error}</p>
                                    }
                                </div>}
                            </div>

                            <div className="expiration-timer">
                            <span>
                                {
                                    translation?.emailCodeVerification?.codeWillExpire ||
                                    defaultTranslation?.emailCodeVerification?.codeWillExpire
                                } {this.formatTime(secondsUntilCodeExpires)} minutes
                            </span>
                            </div>
                        </div>
                        }

                        <div className={`resend-container ${codeExpired ? 'expired' : ''}`}>
                            {codeExpired
                                ? <span className={'resend-text'}>
                            {
                                translation?.emailCodeVerification?.codeExpired ||
                                defaultTranslation?.emailCodeVerification?.codeExpired
                            }
                        </span>
                                : <span className={'resend-text'}>
                            {
                                translation?.emailCodeVerification?.codeNotReceived ||
                                defaultTranslation?.emailCodeVerification?.codeNotReceived
                            }
                        </span>
                            }
                            <div className="resend-button-container">
                                {displayResendButton
                                    ?<button type={'button'} onClick={this.resendVerificationCode}>
                                        {codeExpired
                                            ?<>
                                                {
                                                    translation?.emailCodeVerification?.resendCodeLabel ||
                                                    defaultTranslation?.emailCodeVerification?.resendCodeLabel
                                                }
                                            </>
                                            :<>
                                                {
                                                    translation?.emailCodeVerification?.resendLabel ||
                                                    defaultTranslation?.emailCodeVerification?.resendLabel
                                                }
                                            </>
                                        }
                                    </button>
                                    :<span>
                                {
                                    translation?.emailCodeVerification?.resendTimer ||
                                    defaultTranslation?.emailCodeVerification?.resendTimer
                                } {secondsUntilDisplayResendButton < 10 ? '0' : ''}{secondsUntilDisplayResendButton} sec
                            </span>
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.user.error,
        eventSlug: state.event.eventSlug,
        languages: state.languages,
        translation: state.languages.translations[state.languages.eventLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRegisterUser: (user) => dispatch(actions.registerUser(user)),
        onRegisterUserFail: (message) => dispatch(actions.registerUserFail(message)),
        onClearError: () => dispatch(actions.clearError()),
        seeLogInForm: () => dispatch(actions.seeLogInForm()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailCodeValidation);
