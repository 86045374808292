import axios from '../axios-instance';
import {
  GET_EVENT_FLOORS_REQUEST,
  GET_EVENT_FLOORS_SUCCESS,
  GET_EVENT_FLOORS_FAIL,
  ADD_EVENT_FLOOR_REQUEST,
  ADD_EVENT_FLOOR_SUCCESS,
  ADD_EVENT_FLOOR_FAIL,
  DELETE_EVENT_FLOOR_REQUEST,
  DELETE_EVENT_FLOOR_SUCCESS,
  DELETE_EVENT_FLOOR_FAIL,
  UPDATE_EVENT_FLOOR_REQUEST,
  UPDATE_EVENT_FLOOR_SUCCESS,
  UPDATE_EVENT_FLOOR_FAIL,
  UPDATE_EVENT_FLOOR_ORDER_REQUEST,
  UPDATE_EVENT_FLOOR_ORDER_SUCCESS,
  UPDATE_EVENT_FLOOR_ORDER_FAIL,
  UPDATE_EVENT_FLOOR_STATUS_REQUEST,
  UPDATE_EVENT_FLOOR_STATUS_SUCCESS,
  UPDATE_EVENT_FLOOR_STATUS_FAIL
} from '../actions/actionTypes';

//get all floors
export const listFloors = (eventId, hidden) => async (dispatch) => {
  try {
    dispatch({ type: GET_EVENT_FLOORS_REQUEST });
    //POSTMAN url - below
    //{{url}}/v1/event/:eventId/floors?filter={"hidden": false}
    const urlWithFilter = `/event/${eventId}/floors?filter={"hidden": ${hidden}}`;
    const urlWithoutFilter = `/event/${eventId}/floors`;

    const { data } = await axios.get(hidden === false ? urlWithFilter : urlWithoutFilter);
    dispatch({
      type: GET_EVENT_FLOORS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_EVENT_FLOORS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

//add a new floor
export const addNewFloor = (eventId, floor) => async (dispatch) => {
  try {
    dispatch({ type: ADD_EVENT_FLOOR_REQUEST });
    //POSTMAN url - below
    //{{url}}/v1/event/:eventId/floor
    const { data } = await axios.post(`/event/${eventId}/floor`, floor, {});
    dispatch({
      type: ADD_EVENT_FLOOR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_EVENT_FLOOR_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

//update a floor
export const updateFloor = (eventId, floorId, floor) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_EVENT_FLOOR_REQUEST });
    //POSTMAN url - below
    ///event/:eventId/floor/:floorId
    const { data } = await axios.patch(`/event/${eventId}/floor/${floorId}`, floor, {});
    dispatch({
      type: UPDATE_EVENT_FLOOR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_EVENT_FLOOR_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const updateFloorOrder = (eventId, floorId, order) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_EVENT_FLOOR_ORDER_REQUEST });

    const { data } = await axios.patch(`/event/${eventId}/floor/${floorId}`, order, {});
    dispatch({
      type: UPDATE_EVENT_FLOOR_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_EVENT_FLOOR_ORDER_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const updateFloorStatus = (eventId, floorId, status) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_EVENT_FLOOR_STATUS_REQUEST });
    //POSTMAN url - below
    ///event/:eventId/speaker/:floorId
    const { data } = await axios.patch(`/event/${eventId}/floor/${floorId}`, status, {});
    dispatch({
      type: UPDATE_EVENT_FLOOR_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_EVENT_FLOOR_STATUS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

//delete a floor
export const deleteFloor = (eventId, floorId) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_EVENT_FLOOR_REQUEST,
    });
    ///event/:eventId/floor/:floorId
    //POSTMAN url - below

    await axios.delete(`/event/${eventId}/floor/${floorId}`);
    dispatch({
      type: DELETE_EVENT_FLOOR_SUCCESS,
      payload: floorId,
    });
  } catch (error) {
    dispatch({
      type: DELETE_EVENT_FLOOR_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};