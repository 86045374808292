import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../store/actions';
import {preventDefaultDrag} from '../Utils/utils';

/**
 * Component that alerts if you click outside of it
 */
class DetectOutsideClick extends Component {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        const {topNavigation, isLargeScreen, showProgramTooltip} = this.props;
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.props.target === 'exhibitorsOverlay') {
                this.props.hideExhibitorsOverlay();
            } else if (this.props.target === 'auditoriumsSlider') {
                this.props.hideAuditoriumsSlider();
            } else if (event.target.closest('.preserveHeaderDropdown')) {
                // we don't want to do nothing here, we want to maintain the top nav opened
            } else if (event.target.closest('.remove-session')) {
                // we don't want to do nothing here, we want to maintain the top nav opened
            } else if (
                document.getElementById('search-filters-dropdown')?.contains(event.target) ||
                document.getElementById('search-filters-dropdown-empty')?.contains(event.target)
            ) {
                // we don't want to do nothing here, we want to maintain the top nav opened
            } else if (isLargeScreen) {
                if (topNavigation.seeProgram && showProgramTooltip) {
                    this.props.stopDisplayingSmartTooltip();
                }
            } else {
                this.props.onCloseTopNavMobile();
            }
        }
    }

    render() {
        return (
            <div onDragStart={preventDefaultDrag} className="detect-wrapper" ref={this.setWrapperRef}>
                {this.props.children}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLargeScreen: state.layout.isLargeScreen,
        topNavigation: state.user.topNavigation,
        showProgramTooltip: state.layout.showProgramTooltip,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseTopNav: () => dispatch(actions.topNavClose()),
        onCloseTopNavMobile: () => dispatch(actions.topNavCloseMobile()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetectOutsideClick);
