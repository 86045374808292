import { Button, IconButton } from "@material-ui/core";
import LeftSideMenu from "Components/LeftSideMenu";
import SideMenu from "Components/SideMenu";
import { preventDefaultDrag } from "Utils/utils";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
	getQuestionnaires,
	requestCloseMatchingBanner,
	setEventProtectedMenu,
	sideNavSeeNetworking,
	toggleMatchingDialog,
} from "store/actions";

import FullscreenButtonIcon from "assets/expand_full_screen.png";
import CloseSmallIcon from "assets/close_small.png";
import { ReactComponent as LogoImage } from "../../Images/svg/logo.svg";
import { ReactComponent as LogoBlack } from "../../Images/svg/myonvent-black.svg";

import "./Metaverse.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import MatchingDialog from "Components/EventManagement/Matching/MatchingDialog";
import MatchingDialogConfirm from "Components/EventManagement/Matching/MatchingDialogConfirm";
import MatchingBanners from "Components/EventManagement/Matching/MatchingBanners";
import { listFloors } from "store/actions/eventFloorsActions";
import FloorPlanSlider from "./FloorPlanSlider";
import FloorPlanSliderMobile from "./FloorPlanSlider.mobile";
import { GET_EVENT_FLOORS_SUCCESS } from "store/actions/actionTypes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const FloorPlan = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const isLargeScreen = useSelector((state) => state.layout.isLargeScreen);
	const eventSlug = useSelector((state) => state.event.eventSlug);
	const eventId = useSelector((state) => state.event.eventId);
	const event = useSelector((state) => state.event.data);
	const userEventProfile = useSelector((state) => state.userEventProfile.data);
	const user = useSelector((state) => state.user.data);
	const { eventFloors, getEventFloorsStatus } = useSelector(
		(state) => state.floors,
	);

	const translation = useSelector(
		(state) => state.languages.translations[state.languages.platformLanguage],
	);
	const defaultTranslation = useSelector(
		(state) => state.languages.translations.en,
	);

	const [openMatchingCloseDialog, setOpenMatchingCloseDialog] = useState(false);
	const [bannerDisableOnFinish, setBannerDisableOnFinish] = useState(false);
	const [alreadyShowed, setAlreadyShowed] = useState(false);
	const [activeEventFloor, setActiveEventFloor] = useState(0);

	useEffect(() => {
		dispatch(listFloors(eventId));
	}, [dispatch, eventId]);

	const filteredEventFloors = eventFloors
		? eventFloors?.data?.filter((floor) => !floor.hidden)
		: [];

	useEffect(() => {
		if (
			getEventFloorsStatus === GET_EVENT_FLOORS_SUCCESS &&
			filteredEventFloors?.length === 0
		) {
			history.push(`/event/${eventSlug}/lobby`);
		}
	}, [getEventFloorsStatus, filteredEventFloors, history, eventSlug]);

	const iframeRef = useRef();

	const toggleFullscreen = () => {
		iframeRef.current.requestFullscreen();
	};

	const openMatchingModal = () => {
		dispatch(getQuestionnaires(eventId));
		dispatch(toggleMatchingDialog(true));
	};

	const getMatchingBannerShowed = useCallback(() => {
		const matchingBannerData = localStorage.getItem("matchingBannerData");

		if (matchingBannerData !== null) {
			const matchingBannerDataArray = JSON.parse(matchingBannerData);
			const data = matchingBannerDataArray
				?.filter((bannerData) => bannerData?.event === eventSlug)
				?.shift();

			if (data !== undefined && data.userId === user?._id) {
				setAlreadyShowed(data.alreadyShowed);
			}
		}
	}, [eventSlug, user?._id]);

	useEffect(() => {
		dispatch(setEventProtectedMenu());
		dispatch(sideNavSeeNetworking());
		getMatchingBannerShowed();
	}, [dispatch, getMatchingBannerShowed]);

	const closeMatchingModal = () => {
		setOpenMatchingCloseDialog(false);
		dispatch(toggleMatchingDialog(false));
	};

	const handleRequestCloseMatchingBanner = () => {
		setBannerDisableOnFinish(true);
		dispatch(requestCloseMatchingBanner(true));
	};

	const handleSetActiveEventFloor = (index) => {
		if (index > filteredEventFloors.length - 1) {
			setActiveEventFloor(0);
			return;
		}

		if (index < 0) {
			setActiveEventFloor(filteredEventFloors.length - 1);
			return;
		}

		setActiveEventFloor(index);
	};

	return (
		<div
			onDragStart={preventDefaultDrag}
			className={`position-background lobby-screen ${
				!isLargeScreen ? "lobby-mobile" : ""
			}`}
			style={{
				display: isLargeScreen ? "flex" : "block",
			}}
		>
			{!userEventProfile?.matchingFormSeen && !alreadyShowed && (
				<MatchingBanners
					bannerDisableOnFinish={bannerDisableOnFinish}
					requestCloseBanner={event?.requestCloseBanner}
					openMatchingModal={openMatchingModal}
				/>
			)}
			{!isLargeScreen && (
				<header id="header-mobile" className="header" style={{ height: 69 }}>
					<div onDragStart={preventDefaultDrag} className="main-menu-container">
						<div onDragStart={preventDefaultDrag} className="actions-container">
							<div
								onDragStart={preventDefaultDrag}
								className="left-header-wrapper"
							>
								<div onDragStart={preventDefaultDrag} id="logo-mobile">
									<Link
										tabIndex="0"
										to={{ pathname: `/event/${eventSlug}/lobby` }}
									>
										<LogoImage />
									</Link>
									<a
										href="https://myonvent.com/"
										target="_blank"
										className="powered-by"
									>
										<span>Powered by</span>
										<LogoBlack />
									</a>
									<Link
										tabIndex="0"
										to={{ pathname: `/event/${eventSlug}/lobby` }}
										style={{ width: "unset" }}
									>
										<Button component="label" style={{ color: "#777081" }}>
											<img
												src={CloseSmallIcon}
												alt="close"
												style={{ marginRight: 8 }}
											/>
											CLOSE
										</Button>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</header>
			)}
			<LeftSideMenu />
			{isLargeScreen && <SideMenu openMatchingDialog={openMatchingModal} />}

			<div
				className={`metaverse-container ${
					!isLargeScreen ? "metaverse-container__mobile" : ""
				}`}
			>
				<CircularProgress
					color="secondary"
					style={{
						position: "absolute",
						bottom: "50%",
						left: "50%",
						zIndex: 0,
					}}
				/>
				{filteredEventFloors?.length > 1 && (
					<>
						<FloorPlanSlider
							auditoriums={filteredEventFloors}
							activeAuditoriumIndex={activeEventFloor}
							onSetActiveAuditoriumIndex={handleSetActiveEventFloor}
						/>
						<FloorPlanSliderMobile
							activeFloorName={
								filteredEventFloors[activeEventFloor]?.name || "undef"
							}
							activeFloorIndex={activeEventFloor}
							setActiveFloorIndex={handleSetActiveEventFloor}
						/>
					</>
				)}
				{filteredEventFloors?.length > 0 && (
					<iframe
						ref={iframeRef}
						title="AI for Good Metaverse"
						src={filteredEventFloors[activeEventFloor].url}
						frameborder="0"
						allowFullScreen
						allowtransparency="true"
					/>
				)}
				{isLargeScreen && (
					<div className="metaverse-fullscreen-button">
						<IconButton
							onClick={toggleFullscreen}
							className="metaverse-fullscreen-button-image"
						>
							<img src={FullscreenButtonIcon} alt="Fullscreen" />
						</IconButton>
					</div>
				)}
			</div>
			{event?.matchingDialog && (
				<MatchingDialog
					noOpenNetworking
					open={event?.matchingDialog}
					close={() => setOpenMatchingCloseDialog(false)}
					closeNoPrompt={closeMatchingModal}
					closeBannerOnFinish={handleRequestCloseMatchingBanner}
				/>
			)}
			{openMatchingCloseDialog && (
				<MatchingDialogConfirm
					hasCloseButton
					open={openMatchingCloseDialog}
					dialogTitle={
						translation?.matching?.closeMatchingTitle ||
						defaultTranslation?.matching?.closeMatchingTitle
					}
					dialogDescription={`${
						translation?.matching?.closeMatchingTextA ||
						defaultTranslation?.matching?.closeMatchingTextA
					} <br><br> ${
						translation?.matching?.closeMatchingTextB ||
						defaultTranslation?.matching?.closeMatchingTextB
					}`}
					cancelBtnLabel={
						translation?.generalText?.cancel ||
						defaultTranslation?.generalText?.cancel
					}
					confirmBtnLabel={
						translation?.generalText?.close ||
						defaultTranslation?.generalText?.close
					}
					closeConfirm={() => setOpenMatchingCloseDialog(false)}
					handleConfirm={closeMatchingModal}
				/>
			)}
		</div>
	);
};

export default FloorPlan;
