import FormControlLabel from '@material-ui/core/FormControlLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { PureComponent } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { ReactComponent as UploadProfileImg } from '../Images/svg/upload.svg';
import WhiteCheckbox from '../SmallLayoutComponents/WhiteCheckbox';
import * as actions from '../store/actions';
import { preventDefaultDrag } from '../Utils/utils';
import axios from "../store/axios-instance";
import RegisterEventEmailCodeValidation from "./Event/EmailCodeValidation/EmailCodeValidation";

class RegisterPlatformForm extends PureComponent {
    state = {
        user: {
            fields: [
                {
                    name: 'first',
                    type: 'text',
                    value: '',
                    label: 'First name',
                    multiline: 0,
                    validators: ['required'],
                    errorMessages: ['Field is required'],
                },
                {
                    name: 'last',
                    type: 'text',
                    value: '',
                    label: 'Last name',
                    multiline: 0,
                    validators: ['required'],
                    errorMessages: ['Field is required'],
                },
                {
                    name: 'email',
                    type: 'text',
                    value: '',
                    label: 'Email',
                    multiline: 0,
                    validators: ['required', 'isEmail'],
                    errorMessages: ['Field is required', 'Email not valid'],
                },
                {
                    name: 'password',
                    type: 'password',
                    value: '',
                    label: 'Password',
                    multiline: 0,
                    validators: ['required', 'minStringLength:8'],
                    errorMessages: ['Field is required', 'Password must have min 8 characters'],
                },
                {
                    name: 'passwordConfirmation',
                    type: 'password',
                    value: '',
                    label: 'Confirm password',
                    multiline: 0,
                    validators: ['isPasswordMatch', 'required', 'minStringLength:8'],
                    errorMessages: ['Password mismatch', 'Field is required', 'Password must have min 8 characters'],
                },
            ],
            agreeToPrivacyPolicy: false,
            file: '',
            imagePreviewUrl: '',
        },
        disabled: true,
        imgError: false,
        imgErrorText: '',
        displayAgreeToPrivacyPolicyMessage: false,
        password: false,
        passwordConfirmation: false,
        showEmailValidationSection: false,
        emailExistsError: false,
        emailExistsErrorMessage: '',
        displayUserCreatedScreen: false,
        creatingCode: false,
    };

    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            let currentPassword = this.state.user.fields.find((field) => field.name === 'password');
            let currentPasswordValue = currentPassword.value;
            if (value !== currentPasswordValue) {
                return false;
            }
            return true;
        });
    }

    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule('isPasswordMatch');
        this.props.onClearError();
        this.props.showSocialButtons();
    }

    handleFieldChange = (index) => (e) => {
        let updatedFields = [...this.state.user.fields];
        updatedFields[index].value = e.target.value;
        this.setState({user: {...this.state.user, fields: updatedFields}}, () => {
            this.refs.form.isFormValid().then((isValid) => {
                this.setState({disabled: !isValid});
            });
        });
    };

    toggleShowPassword = (fieldName) => () => {
        this.setState((prevState) => ({
            [fieldName]: !prevState[fieldName],
        }));
    };

    handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        let isValid = true;

        isValid = file.size < 2 * 1024 * 1024 && isValid;
        if (!isValid) {
            this.setState({imgErrorText: 'File too large. 2Mb max file size.'});
        }

        isValid = (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/gif') && isValid;
        if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg' && file.type !== 'image/gif') {
            this.setState({
                imgErrorText: 'File type not supported. Please use one of the following: jpeg, jpg, jfif, gif or png.',
            });
        }

        reader.onloadend = () => {
            if (isValid) {
                this.setState({
                    user: {
                        ...this.state.user,
                        file: file,
                        imagePreviewUrl: reader.result,
                    },
                    imgError: false,
                    imgErrorText: '',
                });
            } else {
                this.setState({imgError: true});
            }
        };
        reader.readAsDataURL(file);
    };

    handleRemoveImage = () => {
        this.setState({
            user: {
                ...this.state.user,
                file: '',
                imagePreviewUrl: '',
            },
            imgError: false,
            imgErrorText: '',
        });
    };

    handleCheckbox = (name) => (event) => {
        this.setState({
            user: {
                ...this.state.user,
                [name]: event.target.checked,
            },
            displayAgreeToPrivacyPolicyMessage: !event.target.checked,
        });
    };

    _handleSeeLogInTab = () => {
        this.props.seeLogInForm();
    };

    handleRegister = (validationCode = "") => {
        const formData = new FormData();
        this.state.user.fields.forEach((field) => {
            formData.append(field.name, field.value);
        });
        formData.append('code', validationCode);
        formData.append('consent', this.state.user.agreeToPrivacyPolicy);

        if (this.state.user.file) {
            formData.append('avatar', this.state.user.file, this.state.user.file.name, this.state.user.file.type);
        }
        this.props.onRegisterUser(formData)
            .then((response)=>{
                this.setState({
                    displayUserCreatedScreen: true
                })
            })
            .catch((error)=>{
            });
    };

    onFormSubmit = () => {
        const hasEmailValidation = JSON.parse(process.env.REACT_APP_ENABLE_EMAIL_VALIDATION);
        let isValid = true;

        isValid = isValid && this.state.user.agreeToPrivacyPolicy;
        isValid = isValid && !this.state.imgError;

        if (isValid) {
            if(hasEmailValidation){
                // only make the api call for creating a validation code if the user completed the register form correctly

                // api call to backend to create validation code
                this.createRegistrationCode();
            }else{
                this.handleRegister();
            }
        } else {
            if (!this.state.user.agreeToPrivacyPolicy) {
                this.setState({displayAgreeToPrivacyPolicyMessage: true});
            }
        }
    };

    createRegistrationCode = async () => {
        const email = this.state.user.fields.find(field => field.name === 'email')?.value?.trim();
        const first = this.state.user.fields.find(field => field.name === 'first')?.value?.trim();
        const last = this.state.user.fields.find(field => field.name === 'last')?.value?.trim();
        const language = this.props.languages.eventLanguage;

        const formData = {
            email: email,
            language: language,
            first: first,
            last: last
        };

        this.setState({
            creatingCode: true
        });

        axios({method: 'post', url: '/users/request-otp', data: formData})
            .then((response)=>{
                if(response.data.success){
                    // after we get the success message we display the validate code section
                    this.setState({
                        showEmailValidationSection: true,
                        creatingCode: false
                    });
                    // call the function we received from registerEvent so that we can hide the social login buttons
                    this.props.hideSocialButtons()
                }
            })
            .catch((error) => {
                const errorMessage = error.response.data.error;

                if(errorMessage === 'Email already exists'){
                    this.setState({
                        emailExistsError: true,
                        emailExistsErrorMessage: errorMessage,
                        creatingCode: false
                    })
                }
            })
    };

    render() {
        let {fields, agreeToPrivacyPolicy, imagePreviewUrl, file} = this.state.user;
        const {password, passwordConfirmation, showEmailValidationSection, emailExistsError, emailExistsErrorMessage} = this.state;
        const {translation, defaultTranslation, error} = this.props;
        const userEmail = fields.find(field => field.name === 'email')?.value;

        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = (
                <img
                    draggable="false"
                    onDragStart={preventDefaultDrag}
                    src={imagePreviewUrl}
                    alt="test"
                    className="avatar"
                />
            );
        } else {
            $imagePreview = null;
        }

        if(showEmailValidationSection){
            return <RegisterEventEmailCodeValidation
                userEmail={userEmail}
                createRegistrationCode={this.createRegistrationCode}
                handleRegister={this.handleRegister}
                displayUserCreatedScreen={this.state.displayUserCreatedScreen}
            />
        }

        return (
            <ValidatorForm ref="form" onSubmit={this.onFormSubmit}>
                {fields.map((field, index) => {
                    return (
                        <div onDragStart={preventDefaultDrag} key={index} className="field-wrapper">
                            <TextValidator
                                key={field.name}
                                label={field.label}
                                type={
                                    (field.name === 'password' && password) ||
                                    (field.name === 'passwordConfirmation' && passwordConfirmation)
                                        ? 'text'
                                        : field.type
                                }
                                name={field.name}
                                index={index}
                                value={field.name === 'email' ? field.value.trim() : field.value}
                                variant="filled"
                                onChange={this.handleFieldChange(index)}
                                validators={field.validators}
                                errorMessages={field.errorMessages}
                                multiline={field.multiline > 0}
                                rows={field.multiline}
                                fullWidth={true}
                                margin="normal"
                                className="field-container"
                            />
                            {field.name === 'password' && (
                                <div
                                    onDragStart={preventDefaultDrag}
                                    className="show-pass"
                                    onClick={this.toggleShowPassword(field.name)}
                                >
                                    {password ? <Visibility /> : <VisibilityOff />}
                                </div>
                            )}
                            {field.name === 'passwordConfirmation' && (
                                <div
                                    onDragStart={preventDefaultDrag}
                                    className="show-pass"
                                    onClick={this.toggleShowPassword(field.name)}
                                >
                                    {passwordConfirmation ? <Visibility /> : <VisibilityOff />}
                                </div>
                            )}
                        </div>
                    );
                })}
                <label
                    htmlFor="file"
                    className={'file ' + (this.state.imgErrorText || this.props.error ? 'error-label' : '')}
                >
                    <div onDragStart={preventDefaultDrag} className="upload-image">
                        <UploadProfileImg />
                        <p>{file ? 'Edit' : 'Upload'} Profile Picture</p>
                    </div>
                </label>
                {this.state.imgErrorText && (
                    <p onDragStart={preventDefaultDrag} className="error-text">
                        {this.state.imgErrorText}
                    </p>
                )}

                <TextValidator onChange={this.handleImageChange} name="file" type="file" id="file" className="d-none" />
                {file && (
                    <div
                        onDragStart={preventDefaultDrag}
                        className="imgPreview"
                        data-empty={$imagePreview ? 'true' : 'false'}
                    >
                        <div>
                            <span>{file.name}</span>
                            <button type="button" onClick={this.handleRemoveImage}>
                                Remove
                            </button>
                        </div>
                        {$imagePreview}
                    </div>
                )}
                <div onDragStart={preventDefaultDrag} className="agree-terms">
                    <FormControlLabel
                        control={
                            <WhiteCheckbox
                                className="dynamic-checkbox"
                                checked={agreeToPrivacyPolicy}
                                onChange={this.handleCheckbox('agreeToPrivacyPolicy')}
                                value="agreeToPrivacyPolicy"
                            />
                        }
                        label={
                            <span onDragStart={preventDefaultDrag} draggable="false" className="agree-terms-message">
                                By proceeding, you agree to our
                                <Link
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to={{pathname: '/terms-and-conditions/'}}
                                >
                                    <span> Terms of Use </span>
                                </Link>
                                and confirm you have read our
                                <Link target="_blank" rel="noopener noreferrer" to={{pathname: '/privacy-policy/'}}>
                                    <span> Privacy Policy</span>
                                </Link>
                                .
                            </span>
                        }
                    />
                    {this.state.displayAgreeToPrivacyPolicyMessage && (
                        <p onDragStart={preventDefaultDrag} className="error-text">
                            Please agree to privacy policy
                        </p>
                    )}
                </div>
                {(error || emailExistsError) && (
                    <>
                        {(error?.includes('E11000 duplicate key error') || emailExistsError) ? (
                            <p onDragStart={preventDefaultDrag} className="error-text">
                                <span>
                                    {translation?.register.errorAlreadyHaveMOAccount ||
                                    defaultTranslation?.register.errorAlreadyHaveMOAccount}
                                </span>
                                <span>
                                    {' ' +
                                    (translation?.generalText.please || defaultTranslation?.generalText.please) +
                                    ' '}
                                    <span
                                        draggable="false"
                                        className="error-clickable"
                                        onClick={this._handleSeeLogInTab}
                                    >
                                        {translation?.login.loginTitleLowerCase ||
                                        defaultTranslation?.login.loginTitleLowerCase}
                                    </span>
                                </span>
                            </p>
                        ) : (
                            <p onDragStart={preventDefaultDrag} className="error-text">
                                {error ? error : emailExistsErrorMessage}
                            </p>
                        )}
                    </>
                )}
                <button
                    className={
                        'register-button ' +
                        (this.state.disabled || !this.state.user.agreeToPrivacyPolicy || this.state.creatingCode ? 'disabled' : '')
                    }
                    type="submit"
                    disabled={this.state.creatingCode}
                >
                    Sign up
                </button>
            </ValidatorForm>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.user.error,
        languages: state.languages,
        translation: state.languages.translations[state.languages.eventLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRegisterUser: (user) => dispatch(actions.registerUser(user)),
        onClearError: () => dispatch(actions.clearError()),
        seeLogInForm: () => dispatch(actions.seeLogInForm()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterPlatformForm));
